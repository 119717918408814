import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const createFilterGrouping = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/fillter-group`,
      formdata,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getFilterGrouping = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/fillter-group`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const deleteFilterGroup = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(
      `${apiURL}admin/fillter-group/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getFilterById = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/fillter-group/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const updateFilterGrouping = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.put(
      `${apiURL}admin/fillter-group`,
      formdata,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getAttributeText = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-product-attribute-text/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const createFilterItem = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/filter`, formdata, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getFilterItemById = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const resFilterItem = await axios.get(
      `${apiURL}admin/filter/${id}`,
      _config
    );
    return resFilterItem;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const updateFilterItem = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.put(`${apiURL}admin/filter`, formdata, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getFilterItems = (pageNumber) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/filter?page=${pageNumber ? pageNumber : 1}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const deleteFilterGroupingItems = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(
      `${apiURL}admin/filter-items/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const deleteFilterItem = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(`${apiURL}admin/filter/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const createBulkFilterItem = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/identifierUpdate`,
      formdata,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const createBulkFilterDetailItem = (formdata) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/filterDataUpdate`,
      formdata,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
