import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";
import UpdateButton from "./UpdateButton";
import { updatePricingStrategy } from "../../../../services/vendor/vendor.service";

const ManagePricingStrategy = ({
  selectedItem,
  isRender,
  setIsRender,
  setSelectedItem,
}) => {
  const [loading, setLoading] = useState(false);
  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await updatePricingStrategy({
        id,
        pricingType: selectedItem?.pricingType,
        showCoupon: selectedItem?.showCoupon,
      });
      if (res?.data?.message) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div
      style={{ background: "#fafafa" }}
      className="  d-flex flex-column gap-10"
    >
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Break Map</span>
        <span>
          <ToggleSwitch
            checked={selectedItem?.pricingType === "break"}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: e.target.name,
              });
            }}
            Name={"break"}
          />
        </span>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Smart MAP</span>
        <span>
          <ToggleSwitch
            checked={selectedItem?.pricingType === "smart"}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: e.target.name,
              });
            }}
            Name={"smart"}
          />
        </span>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Flash Coupon</span>
        <span>
          <ToggleSwitch
            checked={selectedItem?.pricingType === "coupon"}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: e.target.name,
              });
            }}
            Name={"coupon"}
          />
        </span>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Standard Coupon</span>
        <span>
          <ToggleSwitch
            checked={selectedItem?.pricingType === "standardCoupon"}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: e.target.name,
              });
            }}
            Name={"standardCoupon"}
          />
        </span>
      </div>
      <hr />{" "}
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <div>
          Quotes| Show Coupon
          <br />
          <p className=" text-muted">Coupon visible on marketing channels</p>
        </div>
        <span>
          <ToggleSwitch
            checked={
              selectedItem?.pricingType === "quote" && selectedItem?.showCoupon
            }
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: "quote",
                showCoupon: true,
              });
            }}
            Name={"showCoupon"}
          />
        </span>
      </div>
      <hr />{" "}
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <div>
          Quotes| Hide Coupon
          <br />
          <p className=" text-muted">Coupon visible on marketing channels</p>
        </div>
        <span>
          <ToggleSwitch
            checked={
              selectedItem?.pricingType === "quote" && !selectedItem?.showCoupon
            }
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                pricingType: "quote",
                showCoupon: false,
              });
            }}
            Name={"hideCoupon"}
          />
        </span>
      </div>
      <hr />
      <UpdateButton
        position="fixed"
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManagePricingStrategy;
