import axios from "axios";
import { apiURL } from "../../config/default";

export const filterProducts = async (searchQuery) => {
  try {
    const res = axios.post(`${apiURL}admin/search-product`, { ...searchQuery });
    return res;
  } catch (err) {
    return err;
  }
};

export const searchProductSuggestions = async (query) => {
  try {
    const res = axios.post(`${apiURL}admin/search-suggestions`, query);
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadBulkPricing = async (data) => {
  try {
    const res = axios.post(`${apiURL}admin/add-bulk-pricing`, { data });
    return res;
  } catch (err) {
    return err;
  }
};

