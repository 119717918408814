import * as React from "react";

import Drawer from "@mui/material/Drawer";

const closeStyle = {
  fontSize: "2.3rem",
  display: "inline",
  width: "fit-content",
  padding: "5px",
  cursor: "pointer",
  background: "transparent",
  border: "none",
};
export default function HeaderModal({
  header,
  showHeaderBgClr,
  toggleHeaderBgColor,
  handleHeaderBackgroundColor,
  LogoInpStyling,
  open,
  styles,
  closeDrawer,
  handleLogoChange,
  handleLogoStyling,
  handleNavLinkChange,
  handleAddLink,
  handleRemoveLink,
}) {
  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <button onClick={closeDrawer} style={closeStyle}>
        &times;
      </button>
      <div className="mx-auto w-100">
        <div className="text-center">
          <h5>Header Styling</h5>
          <div className="border p-2 m-2 rounderd">
            <div>
              <b>Background Color</b>
              <div>
                <label htmlFor="transparent" className="c-pointer">
                  Transparent
                </label>
                <input
                  type="checkbox"
                  name="transparent"
                  className="m-2"
                  checked={showHeaderBgClr}
                  onChange={toggleHeaderBgColor}
                  id="transparent"
                />
              </div>

              <div>
                {!showHeaderBgClr && (
                  <input
                    type="color"
                    name="background"
                    onChange={handleHeaderBackgroundColor}
                    id="background"
                    style={LogoInpStyling}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="border p-2 m-2 rounderd">
            <h6 className="text-secondary text-left pl-2">Logo Styling</h6>
            <div className="d-flex align-items-center">
              <div style={{ maxWidth: "150px" }}>
                <span>Height</span>
                <input
                  type="number"
                  name="height"
                  id="height"
                  value={header.logo.height}
                  onChange={handleLogoStyling}
                  className={styles.inputNumber}
                  style={LogoInpStyling}
                />
              </div>
              <div>
                <label
                  htmlFor="logo"
                  className="btn btn-primary color-white c-pointer"
                >
                  Change Logo
                </label>
                <input
                  type="file"
                  onChange={handleLogoChange}
                  name="logo"
                  id="logo"
                  className="d-none"
                />
              </div>
              <div style={{ maxWidth: "150px" }}>
                <span>Width</span>
                <input
                  type="number"
                  name="width"
                  id="width"
                  value={header.logo.width}
                  onChange={handleLogoStyling}
                  className={styles.inputNumber}
                  style={LogoInpStyling}
                />
              </div>
            </div>
          </div>
          <h5 className="mt-3">Navbar Links Styling</h5>

          <div className="border p-2 m-2 rounderd">
            <button onClick={handleAddLink} className="btn btn-info">
              <span className="icon-plus"></span>
            </button>
            <div className={`${styles.linksContainer}`}>
              {header?.navbar.links.map((link, i) => (
                <div
                  className="mx-1 d-flex flex-column justify-content-between border p-2"
                  style={{ minWidth: "100px" }}
                >
                  <div className="text-left text-secondary position-relative pt-4">
                    {i === 0 && (
                      <span
                        className="position-absolute"
                        style={{ top: "0px" }}
                      >
                        Text
                      </span>
                    )}
                    <div className="position-relative">
                      <span
                        onClick={() => handleRemoveLink(i)}
                        title="Remove"
                        className={`position-absolute icon-minus ${styles.removeIcon}`}
                      ></span>
                      <input
                        type="text"
                        name="text"
                        defaultValue={link.text}
                        placeholder="Text"
                        onBlur={(e) => handleNavLinkChange(e, i)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="text-left text-secondary">
                    {i === 0 && <span>Links</span>}
                    <input
                      type="text"
                      name="slug"
                      defaultValue={link.slug}
                      placeholder="Link"
                      onBlur={(e) => handleNavLinkChange(e, i)}
                      className="form-control "
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
