import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import {
  createFilter,
  deleteVendor,
} from "../../../../services/vendor/vendor.service";
const ProductActions = ({ selectedItem, isRender, setIsRender }) => {
  const [DeleteVendorOpen, setDeleteVendorOpen] = useState(false);

  const handleDelete = (id) => {
    const res = deleteVendor(id);
    if (res) {
      setDeleteVendorOpen(false);
      setIsRender(!isRender);
    }
  };
  const handleCreateFilter = async (id) => {
    const res = await createFilter(id);
    if (res) {
      setDeleteVendorOpen(false);
      setIsRender(!isRender);
    }
  };
  return (
    <div className="  d-flex flex-column gap-10">
      <div className=" d-flex justify-content-center align-center">
        <Link to={`/admin/products?brand=${selectedItem?._id}`}>
          View Product
        </Link>{" "}
      </div>
      <hr />
      <div className=" d-flex justify-content-center align-center">
        <Link to={`/admin/sale-analysis?brand=${selectedItem?._id}`}>
          View Order
        </Link>
      </div>
      <hr />
      <div className=" d-flex justify-content-center align-center">
        <p
          className=" underlined text-danger c-pointer"
          onClick={() => setDeleteVendorOpen(true)}
        >
          Delete Vendor
        </p>
      </div>
      <hr />
      <div className=" d-flex justify-content-center align-center">
        <p
          className=" underlined  c-pointer"
          onClick={() => handleCreateFilter(selectedItem?._id)}
        >
          Create Filter
        </p>
      </div>
      <hr />
      {DeleteVendorOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title={
            selectedItem?.product === 0
              ? "Are you sure?"
              : "First make sure your vendor dont have products."
          }
          onConfirm={() =>
            selectedItem?.product === 0 && handleDelete(selectedItem?._id)
          }
          onCancel={() => setDeleteVendorOpen(false)}
          focusCancelBtn
        >
          Are you sure you want to delete {selectedItem?.fullName}?
        </SweetAlert>
      )}
    </div>
  );
};

export default ProductActions;
