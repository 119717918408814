/* eslint-disable array-callback-return */
import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { retreive, edit } from "../../../actions/coupon";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { subCategories } from "../../../actions/subCategory";
import { brands } from "../../../actions/brand";
import { products } from "../../../actions/product";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";
import DateTimePicker from "react-datetime-picker";
import { withRouter } from "../../../utils/withRouter";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      pricePercentage: "",
      code: "",
      from: new Date(),
      to: new Date(),
      brand: [],
      category: [],
      product: [],
      subCategories: [],
      brands: [],
      products: [],
      productData: [],
      categoryData: [],
    };
  }

  onFromDateChange = (date) => this.setState({ from: date });
  onToDateChange = (date) => this.setState({ to: date });

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const subCat = await this.props.subCategories();
    await this.categoryList(subCat);

    const brnd = await this.props.brands();
    await this.setState({ brands: brnd });

    const product = await this.props.products();
    await this.prodcutsList(product);

    const res = await this.props.retreive(id);
    const subcCategoriesData = [];

    res.category &&
      res.category.forEach((element) => {
        this.state.subCategories.map((item, i) => {
          if (item.value === element) {
            subcCategoriesData.push({ value: item.value, label: item.label });
          }
        });
      });

    const productData = [];

    res.product &&
      res.product.forEach((element) => {
        this.state.products.map((item, i) => {
          if (item.value === element) {
            productData.push({ value: item.value, label: item.label });
          }
        });
      });
    this.setState({
      loading: false,
      title: res.title,
      pricePercentage: res.pricePercentage,
      code: res.code,
      from: new Date(res.from),
      to: new Date(res.to),
      brand: res.brand,
      category: res.category,
      productData: productData,
      product: res.product,
      categoryData: subcCategoriesData,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { title, pricePercentage, code, from, to, brand, product, category } =
      this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);

    const res = await this.props.edit({
      id,
      title,
      pricePercentage,
      code,
      from,
      to,
      brand,
      product,
      category,
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };
  prodcutsList = (products) => {
    var productData = [];
    console.log(products?.products);
    products.products.forEach((element) => {
      let product = [];
      product["value"] = element._id;
      product["label"] = element.title;
      productData.push(product);
    });
    this.setState({ products: productData });
  };
  categoryList = (categories) => {
    var categoryData = [];
    categories.forEach((element) => {
      let categroy = [];
      categroy["value"] = element._id;
      categroy["label"] = element.name;
      categoryData.push(categroy);
    });
    this.setState({ subCategories: categoryData });
  };
  _categoryJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.categoryData && this.state.categoryData}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push(item.value);
          });

        data.length > 0
          ? this.setState({
              category: data,
            })
          : this.setState({
              category: null,
            });
      }}
      options={this.state.subCategories && this.state.subCategories}
    />
  );
  _productJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.productData && this.state.productData}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push(item.value);
          });

        data.length > 0
          ? this.setState({
              product: data,
            })
          : this.setState({
              product: null,
            });
      }}
      options={this.state.products && this.state.products}
    />
  );

  render() {
    const { title, pricePercentage, code, from, to } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Edit Coupon{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/coupons">Coupons</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Coupon
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit coupon</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="row">
                    <div className="form-group col-6">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        Title
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        name="title"
                        value={title && title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-6">
                      <label style={{ fontWeight: "bold" }} htmlFor="brand">
                        Select Brand
                      </label>

                      <select
                        className="form-control"
                        id="brand"
                        onChange={(e) => {
                          this.setState({ brand: e.target.value });
                        }}
                      >
                        <option>choose</option>
                        {console.log("this is brand", this.state.brands)}
                        {this.state.brands &&
                          this.state.brands?.brands?.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label style={{ fontWeight: "bold" }} htmlFor="brand">
                        Select Category
                      </label>

                      {!this.state.loading && this._categoryJsx()}
                    </div>
                    <div className="form-group col-6">
                      <label style={{ fontWeight: "bold" }} htmlFor="brand">
                        Select Product
                      </label>

                      {!this.state.loading && this._productJsx()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="col-sm-3 col-form-label"
                        >
                          Price Percentage
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            id="pricePercentage"
                            placeholder="Price Percentage"
                            name="pricePercentage"
                            value={pricePercentage && pricePercentage}
                            onChange={(e) =>
                              this.setState({
                                pricePercentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="col-sm-3 col-form-label"
                        >
                          Coupon Code
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="code"
                            placeholder="Coupon code"
                            name="code"
                            value={code && code}
                            onChange={(e) =>
                              this.setState({ code: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="col-sm-3 col-form-label"
                        >
                          Valid From
                        </label>
                        <div className="col-sm-9">
                          <DateTimePicker
                            value={from && from}
                            onChange={this.onFromDateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="col-sm-3 col-form-label"
                        >
                          Valid To
                        </label>
                        <div className="col-sm-9">
                          <DateTimePicker
                            value={to && to}
                            onChange={this.onToDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/coupons"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};
subCategories.prototype = {
  subCategories: PropTypes.func.isRequired,
};

products.prototype = {
  products: PropTypes.func.isRequired,
};

brands.prototype = {
  brands: PropTypes.func.isRequired,
};
export default withRouter(connect(null, {
  retreive,
  edit,
  subCategories,
  products,
  brands,
})(Create));
