import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@mui/material/Snackbar";
import { PropTypes } from "prop-types";

import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../../actions/showSnackBar";

const AvailableAttributes = (props) => {
  const { attribute } = props;

  const dispatch = useDispatch()
  const [copyText, setcopyText] = useState(null);
  const [copied, setcopied] = useState(false);
  useEffect(() => {}, [copyText, attribute]);

  return (
    <div className="card cardRaduis ">
      <div className="card-header">
        <p className="h4"> Available Attribute</p>
      </div>
      <div className="card-body cardBodyConatiner">
        {attribute?.map((atr, index) => (
          <div key={index} className="itemContainer rounded-4 my-2 ">
            {atr === "upc" ||
              atr === "manufacturer" ||
              atr === "title" ||
              atr === "bullets" ||
              atr === "modelNumber" ? (
              <p className="h6">{`{${atr}}`}</p>
            ) : (
              <p className="h6">{`[{${atr}}]`}</p>
            )}
            {/* <p className="h6">{`[{${atr}}]`}</p> */}
            <CopyToClipboard
              className="copyIcon"
              text={
                atr === "upc" ||
                  atr === "manufacturer" ||
                  atr === "title" ||
                  atr === "modelNumber" ||
                  atr === "bullets"
                  ? `{${atr}}`
                  : `[{${atr}}]`
              }
              onCopy={() => {
                setcopyText(atr);
                dispatch(showSnackBar("copied", "success", true))
                setcopied(true);
                setTimeout(() => {
                  setcopied(false);
                }, 500);
              }}
            >
              <MdContentCopy fontSize="large" />
            </CopyToClipboard>
          </div>
        ))}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copied}
        message={`"${copyText}"    copied to clipboard`}
      />
    </div>
  );
};

AvailableAttributes.propTypes = {
  attribute: PropTypes.array.isRequired,
};

export default AvailableAttributes;
