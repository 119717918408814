import React from "react";
import styles from "./pricing-side-componet.module.css";
import { IoIosArrowDown } from "react-icons/io";
import InfoItem from "./info-item";
import SmallCard from "./small-card";

const PriceSummaryDetails = ({ itemData, onClick }) => {
  const sortedArray = itemData?.scrapDetails
    ?.slice() // Create a copy to avoid mutating the original array
    ?.sort((a, b) => a.itemTotal - b.itemTotal);

  const sundialIndex = sortedArray?.findIndex(
    (item) => item?.brandName === "Sundial Home"
  );

  return (
    <div>
      <div className={styles.pricingSummary}>
        <h3 className={styles.pricingSummaryHeading}>
          Seller Pricing Summary:
        </h3>
        <button
          onClick={onClick}
          style={{
            border: "none",
            marginLeft: "0",
            textDecoration: "underline",
            fontWeight: "bold",
            backgroundColor: "white",
          }}
        >
          Expand all
          <span style={{ marginLeft: ".3rem" }}>
            <IoIosArrowDown />
          </span>{" "}
        </button>
      </div>

      {sortedArray
        ?.filter((item) => item?.brandName === "Sundial Home")
        ?.map((item, index) => (
          <div key={index} className={styles.sundialContainer}>
            <div className={styles.sundialHome}>
              <h3 className={styles.sundialHomeTitle}>{item?.brandName}</h3>
              <p>Rank: {sundialIndex !== -1 ? sundialIndex + 1 : "N/A"}</p>
            </div>
            <InfoItem crawlType="Sundial DKP:" CrawlDescription="" />
          </div>
        ))}

      {itemData?.scrapDetails
        ?.sort((a, b) => a.itemTotal - b.itemTotal)
        ?.map((item, i) => (
          <SmallCard
            title={item?.brandName}
            rank={i + 1}
            price={`$${item?.itemTotal}`}
            backgroundColor={"#FEE9EB"}
          />
        ))}
      {/* Dummy Data */}
      {/* <SmallCard
        title="Ebay"
        rank="3"
        price="$2,000.00"
        backgroundColor={"#FEE9EB"}
      />
      <SmallCard
        title="Burke Decor"
        rank="3"
        price="$2,718.75"
        backgroundColor={"#E7F6EE"}
      />
      <SmallCard
        title="American Furniture Outlet"
        rank="3"
        price="$2,737.15"
        backgroundColor={"#E7F6EE"}
      />
      <SmallCard
        title="1StopBedromm"
        rank="3"
        price="$2,727.17"
        backgroundColor={"#E7F6EE"}
      /> */}
    </div>
  );
};

export default PriceSummaryDetails;
