// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".review-summary_reviewCount__2QGjN {\n  background-color: #222020;\n  text-align: center;\n  color: #ffffff;\n  padding: 0.5rem 1.6rem;\n  border-radius: 0.3rem;\n}\n\n/* manage review section */\n.review-summary_menuItem__1WH4f {\n  padding: 1rem !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/reviews/review-summary/review-summary.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA,0BAA0B;AAC1B;EACE,wBAAwB;AAC1B","sourcesContent":[".reviewCount {\n  background-color: #222020;\n  text-align: center;\n  color: #ffffff;\n  padding: 0.5rem 1.6rem;\n  border-radius: 0.3rem;\n}\n\n/* manage review section */\n.menuItem {\n  padding: 1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewCount": "review-summary_reviewCount__2QGjN",
	"menuItem": "review-summary_menuItem__1WH4f"
};
export default ___CSS_LOADER_EXPORT___;
