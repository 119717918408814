import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";

const TicketSummary = ({ selectedItem }) => {
  const navigate = useNavigate();
  return (
    <div className="ticket-summary">
      <h3>Ticket Details:</h3>

      <div>
        <h5 className=" font-weight-700 m-0  ">Ticket Date:</h5>
        <p>{moment(selectedItem?.createdAt).format("lll")}</p>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Customer:</h5>
        <p>{selectedItem?.openedBy?.fullName}</p>
        <p>{selectedItem?.openedBy?.email}</p>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Ticket Type:</h5>
        <p>{selectedItem?.reason}</p>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Ticket Summary:</h5>
        <p>{selectedItem?.description}</p>
      </div>

      <div>
        <h5 className=" font-weight-700 m-0 ">Item:</h5>
        <p
          className=" underlined c-pointer"
          onClick={() =>
            navigate(
              `/admin/products?modelNumber=${selectedItem?.orderItemId?.modelNumber}`
            )
          }
        >
          {selectedItem?.orderItemId?.title}
        </p>
        <div
          className=" border-grey border-radius-10  overflow-hidden mt-3 "
          style={{ width: "70px", height: "70px" }}
        >
          <img
            className="w-100 h-100 object-fit-contain"
            src={selectedItem?.orderItemId?.mainImage}
            alt="product"
          />
        </div>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Quantity:</h5>
        <p>{selectedItem?.quantity}</p>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Order Details:</h5>
        <p
          className=" underlined c-pointer"
          onClick={() =>
            navigate(
              `/admin/sale-analysis?orderNumber=${selectedItem?.orderItemId?.itemOrderNumber}`
            )
          }
        >
          {selectedItem?.orderItemId?.itemOrderNumber}
        </p>
      </div>
      <div>
        <h5 className=" font-weight-700 m-0 ">Order Date:</h5>
        <p>{moment(selectedItem?.orderItemId?.createdAt).format("l")}</p>
      </div>

      {/* Shipping Date */}
      <div>
        <h5 className=" font-weight-700 m-0 ">Shipping Date:</h5>
        <p>{moment(selectedItem?.orderItemId?.shippingInfo?.estimateShipDate).format("M/D/YY LT")}</p>
      </div>

      {/* Delivery Date */}
      <div>
        <h5 className=" font-weight-700 m-0 ">Delivery Date:</h5>
        <p>{moment(selectedItem?.orderItemId?.shippingInfo?.finalDeliveryDate).format("M/D/YY LT")}</p>
      </div>
    </div>
  );
};

export default TicketSummary;
