/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";

import { getLabelBuffer } from "../../../services/download/download.service";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { edit, retreive, submitHistory } from "../../../actions/order";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import { Button, ListGroup } from "react-bootstrap";
import fileDownload from "js-file-download";
import { withRouter } from "../../../utils/withRouter";
import moment from "moment";

const statusCodes = [
  "Missing Orders",
  "Canceled",
  "Canceled Reversal",
  "Chargeback",
  "Complete",
  "Denied",
  "Expired",
  "Failed",
  "Pending",
  "Processed",
  "Processing",
  "Refunded",
  "Reversed",
  "Shipped",
  "Voided",
];

class ViewOrder extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showLabelDialog: false,
      labelData: [],
      orderEmail: "",
      returnEmail: "",
      orderStatus: "",
      id: "",
      order: undefined,
      history: [],
      historyInput: {
        status: "",
        comment: "",
        notifyUser: false,
      },
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.pathname.split("/")?.[3];
    // console.log("id:", this.props.location.pathname.split("/")?.[3]);
    const _res = await this.props.retreive(id);
    if (this._isMounted) {
      this.setState({
        loading: false,
        order: _res.orderItem,
        id: _res.orderItem._id,
        history: [],
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { orderStatus, id } = this.state;

    this.setState({ loading: true });

    const res = await this.props.edit({
      orderStatus,
      id,
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      const _res = await this.props.retreive(id);

      if (this._isMounted) {
        this.setState({
          loading: false,
          order: _res.orderItem,
          id: _res.orderItem._id,
        });
      }

      toast(`${res.data}`, { containerId: "B" });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevData = this.state.historyInput;

    this.setState({ historyInput: { ...prevData, [name]: value } });
  };

  _submitHistory = async () => {
    const { id, historyInput } = this.state;

    this.setState({ loading: true });
    await submitHistory(id, historyInput);
    // const history = await getHistory(id);
    this.setState({
      historyInput: { status: "", comment: "", notifyUser: false },
      history: [],
      loading: false,
    });
  };

  calculateSubtotal = () => {
    let { order: { items = [] } = {} } = this.state;

    let total = items.reduce((acc, ITEM) => {
      let { cost, quantity } = ITEM;
      return cost * quantity + acc;
    }, 0);

    return total;
  };
  calculateTotalValue = () => {
    let totalQuantity = this.state.order?.quantity;
    let totalPriceUnit = this.state.order?.cost * totalQuantity;
    let totalTax = this.state.order?.itemTax;
    let totalShipping = this.state.order?.shippingFee;

    let total =
      parseFloat(totalPriceUnit + totalTax + totalShipping).toFixed(2) || 0;

    return total;
  };

  showLabel = async (id) => {
    this.setState({ showLabelDialog: true });
    // this.state.order?.shippingDetail?.map(elem){
    // const label = this.state.order?.shippingDetail?.;}

    // this.setState({ labelData: label });
  };
  handleDownloadLabels = async () => {
    let labels = this.state.order.shippingInfo?.labels;
    // eslint-disable-next-line array-callback-return
    let lab = labels?.map((elem) => {
      setTimeout(async () => {
        // var link = document.createElement('a');
        // link.href = elem.image;
        // link.download = 'file.pdf';
        // link.dispatchEvent(new MouseEvent('click'));\

        await downloadLabels(elem?.image, `${elem.trackingNumber}.pdf`);
      }, 1000);
    });

    const downloadLabels = async (url, filename) => {
      const res = await getLabelBuffer({ pdfLink: url });

      const base64Response = await fetch(
        `data:pdf;base64,${res.data.pdfBase64}`
      );
      const blob = await base64Response.blob();
      fileDownload(blob, filename);
      // await axios.get(url, {
      //   responseType: 'blob',
      // })
      // .then((res) => {
      // })
    };
  };
  render() {
    const {
      order,
      historyInput: { status, comment, notifyUser },
      history,
      loading,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <div>
            <h1 className="page-title d-inline" style={{ fontSize: 30 }}>
              Orders
            </h1>
            <nav
              aria-label="breadcrumb d-inline-block"
              style={{ display: "inline-block" }}
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/orders">Orders</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <Button size="sm" className="mr-1">
                    <svg
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 16 16"
                      class="bi bi-printer"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z" />
                      <path
                        fill-rule="evenodd"
                        d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"
                      />
                      <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    </svg>
                  </Button>
                );
              }}
              content={() => this.componentRef}
            />

            {/* <Button size="sm" className="mr-1">
              <svg
                width="1.3em"
                height="1.3em"
                viewBox="0 0 16 16"
                class="bi bi-truck"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                />
              </svg>
            </Button>
            <Button size="sm" className="mr-1">
              <svg
                width="1.3em"
                height="1.3em"
                viewBox="0 0 16 16"
                class="bi bi-pencil-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                />
              </svg>
            </Button> */}
            <Button
              size="sm"
              className="mr-1"
              variant="light"
              onClick={() => this.props.history.goBack()}
            >
              <svg
                width="1.3em"
                height="1.3em"
                viewBox="0 0 16 16"
                class="bi bi-arrow-90deg-left"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                />
              </svg>
            </Button>
          </div>
        </div>

        <div ref={(el) => (this.componentRef = el)}>
          <div className="row">
            <div className="col-4 grid-margin">
              <div className="card p-0 ">
                <div className="card-body p-0">
                  <h4
                    className="card-title m-0"
                    style={{
                      color: "#4c4d5a",
                      border: "1px solid #dcdcdc",
                      borderBottom: "0",
                      background: "#f6f6f6",
                      textShadow: "0 -1px 0 rgba(50,50,50,0)",
                      padding: 12,
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-cart2"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                        />
                      </svg>
                      <h4 className="m-0 p-0 ml-2 text-black">
                        {" "}
                        Order Details
                      </h4>
                    </div>
                  </h4>
                  <ListGroup className="">
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-cart2"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.brandId?.fullName}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-calendar3"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                          />
                        </svg>
                        <div className="ml-2">
                          {moment(this.state.order?.createdAt).format("l")}
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-credit-card-2- front-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.orderID?.paymentMethod}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1.3em"
                          height="1.3em"
                          viewBox="0 0 16 16"
                          class="bi bi-truck"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.shippingRate?.companyName}
                        </p>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
            <div className="col-4 grid-margin">
              <div className="card p-0">
                <div className="card-body p-0">
                  <h4
                    className="card-title m-0"
                    style={{
                      color: "#4c4d5a",
                      border: "1px solid #dcdcdc",
                      borderBottom: "0",
                      background: "#f6f6f6",
                      textShadow: "0 -1px 0 rgba(50,50,50,0)",
                      padding: 12,
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-person-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                      <h4 className="m-0 p-0 ml-2 text-black">
                        Customer Details
                      </h4>
                    </div>
                  </h4>

                  <ListGroup className="">
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-person-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.orderID?.user?.fullName}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-people-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">Customer Group (N/A)</p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-envelope-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
                          />
                        </svg>
                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.orderID?.user?.email || "NA"}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex align-items-center ">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-telephone-fill"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                          />
                        </svg>

                        <p className="m-0 p-0 ml-2">
                          {this.state.order?.orderID?.user?.phone || "NA"}
                        </p>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
            <div className="col-4 grid-margin">
              <div className="card p-0">
                <div className="card-body p-0">
                  <h4
                    className="card-title m-0"
                    style={{
                      color: "#4c4d5a",
                      border: "1px solid #dcdcdc",
                      borderBottom: "0",
                      background: "#f6f6f6",
                      textShadow: "0 -1px 0 rgba(50,50,50,0)",
                      padding: 12,
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-gear-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"
                        />
                      </svg>
                      <h4 className="m-0 p-0 ml-2 text-black">Options</h4>
                    </div>
                  </h4>
                  <ListGroup className="">
                    <ListGroup.Item>
                      Cashback: {this.state.order?.cashbackReceivedPerItem || 0}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card p-0">
                <div className="card-body p-0">
                  <h4
                    className="card-title m-0"
                    style={{
                      color: "#4c4d5a",
                      border: "1px solid #dcdcdc",
                      borderBottom: "0",
                      background: "#f6f6f6",
                      textShadow: "0 -1px 0 rgba(50,50,50,0)",
                      padding: 12,
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-cart2"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                        />
                      </svg>
                      <p className="m-0 p-0 ml-2">
                        Order (#{this.state.order?.itemOrderNumber})
                      </p>
                    </div>
                  </h4>
                  <div className="row p-3">
                    <div className="col-6 ">
                      <div className="card p-0">
                        <div
                          className="card-body p-0"
                          style={{ border: "1px solid #dcdcdc" }}
                        >
                          <h4
                            className="card-title m-0"
                            style={{
                              color: "#4c4d5a",
                              borderBottom: "1px solid #dcdcdc",
                              background: "#f6f6f6",
                              textShadow: "0 -1px 0 rgba(50,50,50,0)",
                              padding: 12,
                            }}
                          >
                            <div className="d-flex align-items-center ">
                              <h4 className="m-0 p-0 ml-2 text-black">
                                Payment address
                              </h4>
                            </div>
                          </h4>
                          <div className="p-3">
                            <p>
                              Name:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail
                                  ?.firstName}{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail
                                  ?.lastName}
                            </p>
                            <p>
                              Phone:{" "}
                              {this.state.order?.orderID?.billingDetail
                                ?.phoneNumber || "NA"}
                            </p>
                            <p>
                              Address:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail
                                  ?.address}
                            </p>
                            <p>
                              Suite:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail
                                  ?.addressTwo}
                            </p>
                            <p>
                              City:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail?.city}
                            </p>
                            <p>
                              State:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                order?.orderID?.billingDetail?.state}
                            </p>
                            <p>
                              Zip Code:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail?.zip}
                            </p>

                            <p>
                              Country:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                order?.orderID?.billingDetail?.country}
                            </p>
                            <p>
                              Company:{" "}
                              {this.state.order?.orderID?.paymentMethod ===
                                "Authorize" &&
                                this.state.order?.orderID?.billingDetail
                                  ?.company}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 ">
                      <div className="card p-0">
                        <div
                          className="card-body p-0"
                          style={{ border: "1px solid #dcdcdc" }}
                        >
                          <h4
                            className="card-title m-0"
                            style={{
                              color: "#4c4d5a",
                              borderBottom: "1px solid #dcdcdc",
                              background: "#f6f6f6",
                              textShadow: "0 -1px 0 rgba(50,50,50,0)",
                              padding: 12,
                            }}
                          >
                            <div className="d-flex align-items-center ">
                              <h4 className="m-0 p-0 ml-2 text-black">
                                Shipping address
                              </h4>
                            </div>
                          </h4>
                          <div className="p-3">
                            <p>
                              Name:{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.firstName
                              }{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.lastName
                              }
                            </p>
                            <p>
                              Phone:{" "}
                              {this.state.order?.orderID?.shippingDetail
                                ?.phoneNumber || "NA"}
                            </p>
                            <p>
                              Address:{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.address
                              }
                            </p>
                            <p>
                              Suite:{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.addressTwo
                              }
                            </p>

                            <p>
                              City:{" "}
                              {this.state.order?.orderID?.shippingDetail?.city}
                            </p>

                            <p>
                              State:{" "}
                              {this.state.order?.orderID?.shippingDetail?.state}
                            </p>
                            <p>
                              Zip Code:{" "}
                              {this.state.order?.orderID?.shippingDetail?.zip}
                            </p>

                            <p>
                              Country:{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.country
                              }
                            </p>
                            <p>
                              Company:{" "}
                              {
                                this.state.order?.orderID?.shippingDetail
                                  ?.company
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-3">
                    <div className="col-12 ">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Product{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Model{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Quantity{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Unit Price{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Cost{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              View Label{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {" "}
                              Total{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <a
                                href={`https://sundialhome.com/product/${this.state.order?.productSlug}`}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {this.state.order?.title}{" "}
                              </a>
                              <br />
                              <br />
                              <br /> Delivery Date:{" "}
                              {moment(this.state.order?.createdAt).format("l")}
                            </td>
                            <td>
                              <Link
                                className="navbar-brand brand-logo-mini"
                                to={`/admin/products?modelNumber=${this.state.order?.modelNumber}`}
                              >
                                {this.state.order?.modelNumber}
                              </Link>
                            </td>
                            <td>{this.state.order?.quantity}</td>
                            <td>
                              $
                              {parseFloat(this.state.order?.itemPrice).toFixed(
                                2
                              )}
                            </td>
                            <td>
                              ${parseFloat(this.state.order?.cost).toFixed(2)}
                            </td>
                            <td>
                              {/* <button
                                className="btn btn-primary m-2"
                                disabled={
                                  this.state.order?.status !== "Available"
                                }
                                onClick={() => this.showLabel(order._id)}
                              >
                                Label
                              </button>

                              {this.state.order?.status !== "Available" && (
                                <LabelModal
                                  open={this.state.showLabelDialog}
                                  onClose={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      showLabelDialog: false,
                                    });
                                  }}
                                  label={this.state.labelData}
                                />
                              )} */}
                              {this.state.order?.shippingInfo?.labels?.[0]
                                ?.image ? (
                                <>
                                  <button
                                    onClick={this.handleDownloadLabels}
                                    className="btn btn-primary btn-sm"
                                  >
                                    <p>
                                      Download Shipping Label
                                      {`${
                                        order?.shippingInfo?.labels?.length ===
                                        1
                                          ? ""
                                          : "s"
                                      } (${
                                        order?.shippingInfo?.labels?.length
                                      })`}
                                    </p>
                                    {}
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-primary m-2"
                                  disabled
                                >
                                  Label
                                </button>
                              )}
                              <br /> <br />
                              <a
                                download
                                // eslint-disable-next-line react/jsx-no-target-blank
                                target="_blank"
                                className="btn btn-primary btn-sm"
                                href={this.state.order?.orderInvoice}
                              >
                                <p className="white-space-nowrap">
                                  Download Purchase Order Invoice
                                </p>
                              </a>
                            </td>
                            <td>
                              $
                              {Number(
                                this.state.order?.cost *
                                  this.state.order?.quantity
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="6" className="text-right">
                              Paid
                            </td>
                            <td class="text-right">
                              -$
                              {parseFloat(this.state.order?.creditUsed).toFixed(
                                2
                              ) || 0}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="6" className="text-right">
                              Tax
                            </td>
                            <td class="text-right">
                              $
                              {parseFloat(this.state.order?.itemTax).toFixed(
                                2
                              ) || 0}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="6" className="text-right">
                              Shipping Rate
                            </td>
                            <td class="text-right">
                              ${this.state.order?.shippingFee}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="6" className="text-right">
                              Total
                            </td>
                            <td class="text-right">
                              ${this.calculateTotalValue()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { edit, retreive })(ViewOrder));
