import React from "react";
// import { FaPencil } from "react-icons/fa";

const DataRow = ({ name, value }) => {
  return (
    <div className="py-1 px-3 d-flex justify-content-between align-center">
      <h6 className=" text-muted  font-weight-bold">{name}</h6>
      <p>{value}</p>
      <div
        style={{ borderRadius: "68px", padding: "10px 8px" }}
        className=" border-grey c-pointer"
      >
        {/* <FaPencil /> */}
      </div>
    </div>
  );
};

export default DataRow;
