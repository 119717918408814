import React, { useEffect, useState } from "react";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import PriceHistoryChart from "./components/pricing-chart";
import { AiOutlineArrowRight } from "react-icons/ai";
import style from "./dynamic-price-history.module.css";
import axios from "axios";
import { apiURL } from "../../../../config/default";
import moment from "moment";
import { useNavigate } from "react-router";

const DynamicPriceHistory = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const productId = query.get("id");
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [productGraphDetail, setProductGraphDetail] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [selectedValue, setSelectedValue] = useState({
    selectedDay: 30,
    selectedMonth: "",
    selectedYear: "",
    seletedDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    selectedStartDate: moment().format("YYYY-MM-DD"),
    selectedEndDate: moment().format("YYYY-MM-DD"),
  });

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(
        `${apiURL}admin/get-dynamic-price-history-by-product-id/${productId}`
      );
      setProductGraphDetail(response?.data?.dynamicPriceHistory);
      setProductDetails(response?.data?.product);
    } catch (error) {
      console.log("error values are ....", error);
    } finally {
      setLoading(false);
    }
  };

  const setEndDate = (userProvidedDays = 30) => {
    // Get the current date
    const currentDate = moment();
    // Calculate the end date by adding user-provided days
    const selectedEndDate = currentDate
      .add(parseInt(userProvidedDays), "days")
      .format("YYYY-MM-DD");

    setSelectedValue((prev) => ({
      ...prev,
      selectedEndDate,
    }));
  };

  useEffect(() => {
    if (productId) {
      fetchProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchResult = async () => {
    setDisable(true);
    setLoading(true);

    // Determine startDate and endDate based on selectedDay
    const { selectedDay, selectedMonth, selectedYear } = selectedValue;
    let startDate, endDate;

    if (!selectedDay) {
      startDate = endDate = "";
    } else {
      startDate = moment().subtract(selectedDay, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    // Update state if necessary
    if (!selectedDay) {
      setSelectedValue((prev) => ({
        ...prev,
        seletedDate: "",
        selectedEndDate: "",
      }));
    }

    try {
      const response = await axios.get(
        `${apiURL}admin/get-dynamic-price-history-by-product-id/${productId}?month=${selectedMonth}&year=${selectedYear}&startDate=${startDate}&endDate=${endDate}`
      );
      setProductGraphDetail(response?.data?.dynamicPriceHistory);
      setProductDetails(response?.data?.product);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setDisable(false);
    }
  };

  const monthOptions = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const daysOptions = [
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
  ];

  const getFirstValueIfMultiple = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      return `$${arr[0]?.sundialPrice}`;
    }
    return "";
  };

  const handleDaysChange = (value) => {
    setSelectedValue({
      ...selectedValue,
      selectedDay: value,
    });
    setEndDate(value);
  };

  return (
    <div>
      {/* Display Card */}
      <div className="d-flex justify-content-between">
        <div className={style.mainCard}>
          <div className="d-flex gap-25">
            <div style={{ width: "6.5rem", aspectRatio: 1 }}>
              <img
                width="100%"
                height="100%"
                src={productDetails?.mainImage}
                alt="img"
              />
            </div>
            <div>
              <h3 style={{ fontSize: "1rem", marginBottom: "0" }}>
                Coulter 42" Round Dining Table
              </h3>
              <p>SKU: {productDetails?.sku}</p>
              <p>DKP: {getFirstValueIfMultiple(productGraphDetail)}</p>
              <p>
                Last update:{" "}
                {moment(productDetails?.updatedAt).format("DD MMM YYYY")}
              </p>
            </div>
          </div>
          <div className={style.cardButtons}>
            <div className={style.cardButton}>
              <a
                style={{
                  color: "black",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
                href={`https://www.sundialhome.com/product/${productDetails?.slug}`}
                target="_blank"
                rel="noreferrer"
              >
                <span>View on Site</span>
                <AiOutlineArrowRight fontWeight={"bold"} />
              </a>
            </div>

            <div
              className={style.cardButton}
              onClick={() =>
                navigate(`/admin/products?sku=${productDetails?.sku ?? ""}`)
              }
            >
              <p>View in Admin</p>
              <p>
                <AiOutlineArrowRight fontWeight={"bold"} />
              </p>
            </div>
          </div>
        </div>
        <div className={style.showCardLineButton}>
          <div className={style.hightGraphLine}>
            Sundial Home
            <p className={style.line1}></p>
          </div>
          <div className={style.hightGraphLine}>
            Lowest Competitor
            <p className={style.line2}></p>
          </div>
        </div>
      </div>

      {/* card design end */}

      <div className="d-flex gap-8">
        <SelectSearchFilter
          title={"Select Time Period"}
          width={"250px"}
          placeholder="Select ..."
          value={
            daysOptions?.find(
              (item) => item?.value === selectedValue.selectedDay
            )?.label
          }
          options={daysOptions}
          onChange={(value) => handleDaysChange(value)}
        />

        <SelectSearchFilter
          title={"Month"}
          width={"250px"}
          placeholder="Select Month..."
          value={
            monthOptions?.find(
              (item) => item?.value === selectedValue.selectedMonth
            )?.label
          }
          options={monthOptions}
          onChange={(value) =>
            setSelectedValue({
              ...selectedValue,
              selectedMonth: value,
            })
          }
        />

        <SelectSearchFilter
          title={"Year"}
          width={"250px"}
          placeholder="Select Year..."
          value={selectedValue?.selectedYear}
          options={[
            { value: "2024", label: 2024 },
            { value: "2023", label: 2023 },
            { value: "2022", label: 2022 },
            { value: "2021", label: 2021 },
            { value: "2020", label: 2020 },
            { value: "2019", label: 2019 },
            { value: "2018", label: 2018 },
            { value: "2017", label: 2017 },
            { value: "2016", label: 2016 },
            { value: "2015", label: 2015 },
          ]}
          onChange={(value) =>
            setSelectedValue({
              ...selectedValue,
              selectedYear: value,
            })
          }
        />
        <button
          type="button"
          className="btn btn-success"
          style={{ backgroundColor: "#16AA56" }}
          onClick={handleSearchResult}
          disabled={disable}
        >
          Search
        </button>
      </div>

      <div style={{ marginTop: "10rem" }}>
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status"></div>
          </div>
        )}
        {productGraphDetail?.length > 0 && (
          <PriceHistoryChart
            data={productGraphDetail}
            title={`Time Period: Last ${selectedValue?.selectedDay} days`}
          />
        )}
      </div>
      {/* <div style={{ margin: "4rem 0 1rem" }}>
        <PricingChartOneMonth title="Time Period: Last 30 days" />
      </div> */}
    </div>
  );
};

export default DynamicPriceHistory;
