/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';

import TopBarProgress from 'react-topbar-progress-indicator';
import { Link, useLocation } from 'react-router-dom';
import { getCategoryName } from '../../../actions/childCategory';
import { getStores } from '../../../actions/store';
import { connect } from 'react-redux';
import axios from 'axios';
import { apiURL } from '../../../config/default';
import { ToastContainer, toast } from 'react-toastify';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-block',
    float: 'right',
    paddingRight: 10,
  },
  searchInput: {
    position: 'relative',
    verticalAlign: 'top',
    backgroundColor: 'transparent',
    borderColor: 'green',
    color: 'black',
    marginLeft: 4,
    paddingLeft: 10,
  },
};

function FiltersCategory({ getCategoryName, getStores }) {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      const id = location.search.split('=')[1];
      const mainCat = await getSecondLevelCategory(id);
      const { data: stores } = await getStores();
      setstores(stores);
      setcategories(mainCat);
      setfilteredcategories(mainCat);
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSecondLevelCategory = async (id) => {
    const data = await axios.get(`${apiURL}get-subcategory-by-parent/${id}`);
    return data.data;
  };
  const _onChangeHandler = (e) => {
    setfilteredcategories(
      categories.filter((category) =>
        category.name.toUpperCase().includes(e.target.value.toUpperCase())
      )
    );
    setsearch(e.target.value);
  };

  const [loading, setloading] = useState(true);
  const [categories, setcategories] = useState([]);
  const [filteredcategories, setfilteredcategories] = useState([]);
  const [search, setsearch] = useState('');
  const [show, setShow] = useState(false);
  const [selectedChildFilter, setselectedChildFilter] = useState(null);
  const [stores, setstores] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [CatId, setCatId] = useState(null);

  const handleShow = (index) => {
    setselectedChildFilter(index);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleAutoFilters = async (storeid, categoryId) => {
    setloading(true);

    const { data: res } = await axios.get(
      `${apiURL}create-filter-brand/${CatId._id}/${storeid}`
    );
    if (res)
      toast.success('Successful', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    setloading(false);
  };

  const handleAutoFiltersCat = async (id) => {
    setloading(true);
    const { data: res } = await axios.get(
      `${apiURL}filters/create-filter/${id}`
    );
    if (res)
      toast.success('Successful', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    setloading(false);
  };

  return (
    <>
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer />

        <div className="page-header">
          <h3 className="page-title"> Select Filters</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Categories
              </li>
            </ol>
          </nav>
        </div>
        <div className="bg-white mb-5">
          <h4 class="card-title pt-4 pl-4">Categories List</h4>
          <span className="twitter-typeahead pb-1" style={styles.wrapper}>
            <input
              className="typeahead form-control tt-input"
              type="text"
              placeholder="Search..."
              autoComplete="off"
              spellCheck="false"
              dir="auto"
              value={search}
              style={styles.searchInput}
              onChange={_onChangeHandler}
            />
          </span>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th style={{ cursor: "pointer", width: 10 }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredcategories.map((category, i) => (
                <tr key={category._id}>
                  <td>{i + 1}</td>
                  <td>{category.name}</td>

                  <th style={{ cursor: "pointer", width: 10 }}>
                    <Button
                      className="mr-2"
                      variant="outline-primary"
                      onClick={() => {
                        setshowModal(true);
                        setCatId(category);
                      }}
                      // onClick={() => handleAutoFilters(category._id)}
                      size="sm"
                    >
                      Auto Settings
                    </Button>
                    <Link to={`/admin/create-filter/?id=${category._id}`}>
                      Customize Filters
                    </Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create Auto Settings for: {CatId?.name}
            <Button
              className="mr-2 "
              variant="outline-primary"
              // onClick={() => setshowModal(true)}
              onClick={() => handleAutoFiltersCat(CatId?._id)}
              size="sm"
            >
              Auto Settings
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Auto Filters Brand</h3>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th style={{ cursor: "pointer", width: 10 }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {stores.map((store, i) => (
                <tr key={store._id}>
                  <td>{i + 1}</td>
                  <td>{store.fullName}</td>

                  <th style={{ cursor: "pointer", width: 10 }}>
                    <Button
                      className="mr-2"
                      variant="outline-primary"
                      // onClick={() => setshowModal(true)}
                      onClick={() => handleAutoFilters(store._id)}
                      size="sm"
                    >
                      Auto Settings
                    </Button>
                    {/* <Link to={`/admin/create-filter/?id=${category._id}`}>
                      Customize Filters
                    </Link> */}
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default connect(null, {
  getCategoryName,
  getStores,
})(FiltersCategory);
