import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getVenderInventory } from "../../../services/vendor/vendor.service";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import OrderStatusButton from "../order/components/OrderStatusButton";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";
import { BiSolidCircle } from "react-icons/bi";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";
import ManageVendorInventory from "./components/ManageVendorInventory";

const VendorInventory = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // local state
  const [loading, setLoading] = useState(false);
  const [InnderLoader, setInnderLoader] = useState(false);
  const [isRender, setIsRender] = useState(false);

  const [Data, setData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [selectedItem, setSelectedItem] = useState({});

  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
    sort: "",
  });

  const fetchData = useCallback(
    async (currentPage, SearchQuery) => {
      setLoading(true);
      const res = await getVenderInventory(currentPage, SearchQuery);
      if (res) {
        setData(res?.data);
        setpagination({
          ...res?.pagination,
        });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(pagination.currentPage, SearchFillter.SearchQuery);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, SearchFillter.SearchQuery]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };
  const handleVendorStatus = (item) => {
    setEdit({
      open: true,
      edit: "vendorStatus",
      title: ` ${item.fullName} | Vendor Status`,
    });
    setSelectedItem({ ...item });
  };

  return (
    <>
      <div className=" d-flex  justify-content-end align-center flex-wrap gap-10">
        <SearchFilter
          type="text"
          placeholder={"Search Vendor"}
          value={SearchFillter?.SearchQuery}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchQuery: e.target.value,
            })
          }
        />
      </div>
      <div className=" my-5">
        <DashboardPagesLayout
          pageTitle="Vendors Inventory"
          subTitle="items"
          count={pagination?.count}
        >
          <div className="process-orders-table">
            <table className="m-0">
              <thead>
                <tr>
                  <th> No #</th>
                  <th>Name</th>
                  <th>Last update</th>
                  <th>Last Inventory</th>
                  <th>Last Price Update</th>
                  <th>
                    Minderest Export ({pagination?.numberOfExportedProduct})
                  </th>
                  <th> Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={7} />}

                {!loading &&
                  Data?.map((item, index) => (
                    <tr key={index}>
                      {console.log(item)}
                      <td>{index + 1}</td>
                      <td className="font-weight-bold">
                        {!item?.disabled ? (
                          <a
                            href={
                              process.env.REACT_APP_ENVIROMENT === "DEV"
                                ? `https://qasundial.sundialhome.com/brand/${item?.slug}/1`
                                : `https://www.sundialhome.com/brand/${item?.slug}/1`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.fullName}
                          </a>
                        ) : (
                          item?.fullName
                        )}
                      </td>

                      <td>{moment(item?.lastUpdate).format("l")}</td>
                      <td>
                        {item?.lastInventory
                          ? moment(item?.lastInventory).format("l")
                          : "Not Available"}
                      </td>
                      <td>{moment(item?.lastPriceUpdate).format("l")}</td>
                      <td>
                        <div
                          className=" c-pointer"
                          onClick={() => handleVendorStatus(item)}
                        >
                          <BiSolidCircle
                            className={
                              item?.exportInFile ? "text-success" : "text-muted"
                            }
                          />

                          <span className=" ml-1">
                            {item?.exportInFile ? "on" : "off"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <a
                          href={
                            process.env.REACT_APP_ENVIROMENT === "DEV"
                              ? `https://qasundial.sundialhome.com/brand/${item?.slug}/1`
                              : `https://www.sundialhome.com/brand/${item?.slug}/1`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OrderStatusButton width={190} status={"+add"}>
                            Go To Brand
                          </OrderStatusButton>
                        </a>
                      </td>
                    </tr>
                  ))}
                {!Data?.length && !loading && (
                  <tr>
                    <td colSpan={19} className=" text-center">
                      "No record Found"
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span className=" font-weight-bold" style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
            <EditTabelDrawer Edit={Edit} setEdit={setEdit}>
              {InnderLoader ? (
                <div className=" d-flex align-center justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {Edit.edit === "vendorStatus" && (
                    <ManageVendorInventory
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )}
                </>
              )}
            </EditTabelDrawer>
          </div>
        </DashboardPagesLayout>
      </div>
    </>
  );
};

export default VendorInventory;
