import React from "react";

const ReturnPolicyRow = ({ name, value, subTitle }) => {
  return (
    <div className=" d-flex justify-content-start align-center gap-25 px-3 py-3">
      <div style={{ width: "150px" }} className=" font-weight-normal">
        <span>{name}</span>
        <br />
        <span>{subTitle}</span>
      </div>
      <p className=" font-weight-normal"> {value} days</p>
    </div>
  );
};

export default ReturnPolicyRow;
