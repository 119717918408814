import React, { useEffect, useState } from 'react';
import { UseFetch } from '../inspiration/useFetch';
import PopupModal from './Modal';
import axios from 'axios';
import { apiURL } from '../../../../config/default';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
const Inspirations = (props) => {

  const navigate = useNavigate()
  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [editableItem, setEditableItem] = useState({});
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [deleteMsg, setDeleteMsg] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await UseFetch('admin/category-tags');
      setTableData(res?.data.tagCategory);
    };

    fetchData();
  }, []);

  const handleEdit = (item) => {
    navigate(`/admin/inspiration?id=${item._id}`);
  };
  const handleDelete = (item) => {
    setOpen(true);
    setDeleteMsg(true);
    setItemId(item._id);
  };

  const deleteItem = async () => {
    const res = await axios.delete(
      `${apiURL}admin/delete-category-tag/${itemId}`
    );
    return res;
  };

  const deleteOrCancelItem = async (msg) => {
    if (msg === "delete") {
      const teletedItem = await deleteItem();
      const filtered = tableData.filter((item) => item._id !== itemId);
      if (teletedItem.status === 200) {
        setTableData(filtered);
        toast("Deleted", {
          type: toast.TYPE.INFO,
          autoClose: 5000,
        });
      }
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteMsg(false);
  };

  return (
    <div className="container-scroller">
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="mt-4 page-header">
        <h3 className="page-title">Inspirations</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Inspirations
            </li>
          </ol>
        </nav>
      </div>
      <hr />
      <div className="row bg-white p-4">
        <div className="col-md-12 table-responsive">
          <table class="table">
            <thead class="bg-primary">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, i) => (
                <tr key={item.id}>
                  <th scope="row">{i + 1}</th>
                  <td className="mt-1">{item.name}</td>
                  <td className="mt-1">{item.type}</td>
                  <td className="mt-1">{item.status ? "ON" : "OFF"}</td>
                  <td
                    onClick={() => handleDelete(item)}
                    className="btn btn-danger c-pointer mt-1"
                  >
                    Delete
                  </td>
                  <td
                    onClick={() => handleEdit(item)}
                    className="btn btn-info c-pointer ml-2 mt-1"
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <PopupModal open={open} onClose={handleClose}>
        {deleteMsg ? (
          <div>
            <h5>Are you sure you want to delete this item </h5>
            <div className="d-flex align-items-center justify-content-between">
              <button
                onClick={() => deleteOrCancelItem("delete")}
                className="btn btn-primary"
              >
                Yes
              </button>
              <button
                onClick={() => deleteOrCancelItem("cancel")}
                className="btn btn-warning"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Exercitationem nam ad nesciunt!
          </div>
        )}
      </PopupModal>
    </div>
  );
};

export default Inspirations;
