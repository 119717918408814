/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import { setButtonBackgroundColor } from "./utils";
import { apiURL } from "../../../../config/default";
import axios from "axios";
import TableLoader from "../../../../components/TableSkelton/TableLoader";
import PriceCard from "../../../../components/price-card/price-card";
import PriceCardDrawer from "../../../../components/price-card/price-card-components/price-card-drawer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getAllStores } from "../../../../actions/store";
import { createSearchParams } from "react-router-dom";
import PricingSidebar from "./components/pricing-side";

const DynamicPricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("modelNumber");
  const brandId = queryParams.get("brand");
  const [selectedItemBrandId, setSelectedItemBrandId] = useState("");
  const [productInfo, setProductInfo] = useState({});
  const [crawlers, setCrawlwers] = useState([]);
  const [pagePagination, setPagination] = useState({
    currentPage: 1,
  });

  const [loading, setLoading] = useState(false);
  const [Brand, setBrand] = useState([]);
  const [dynamicPricing, setDynamicPricing] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [SearchFillter, setSearchFillter] = useState({
    title: name ?? "",
    brandId: brandId ?? "",
    discountId: "",
    errorCode: "",
    crawlStage: "",
    errorMarked: "",
    crawlType: "",
  });

  const handleRefund = (itemId) => {
    setEdit({
      open: true,
      edit: "pricing",
      title: "Pricing",
    });
    const filterArray = crawlers.filter((value) => value._id === itemId);
    setProductInfo(...filterArray);
  };

  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/pricing",
      search: `?${createSearchParams({
        page: value,
      })}`,
    };
    navigate(options, { replace: true });
    setPagination({ ...pagePagination, currentPage: value });
  };

  const fetchBrandDataFromRedux = async () => {
    try {
      const res = await dispatch(getAllStores());
      if (res) {
        const brand = res?.data?.map((item) => {
          return {
            id: item?._id,
            value: item?.fullName,
            label: item?.fullName,
          };
        });
        setBrand(brand);
      }
    } catch (error) {
      console.log("error is : ", error);
    }
  };

  useEffect(() => {
    fetchBrandDataFromRedux();
  }, []);

  useEffect(() => {
    // Define a timer variable
    let timer;

    // Function to fetch products
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiURL}admin/crawlers?name=${SearchFillter.title}&discountId=${SearchFillter.discountId}&page=${pagePagination.currentPage}&brandId=${SearchFillter.brandId}&linkFound=${SearchFillter.errorCode}&crawlStage=${SearchFillter.crawlStage}&crawlerType=${SearchFillter.crawlType}`
        );
        const { crawlers, pagination } = response.data;

        setCrawlwers(crawlers);
        setPagination(pagination);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const debounceSearch = () => {
      clearTimeout(timer);
      timer = setTimeout(fetchProducts, 1000);
    };

    debounceSearch();
    return () => clearTimeout(timer);
  }, [
    SearchFillter.title,
    SearchFillter.brandId,
    SearchFillter.errorCode,
    SearchFillter.discountId,
    SearchFillter.crawlStage,
    SearchFillter.crawlType,
    pagePagination.currentPage,
  ]);

  const handleDiscountButton = (brandId) => {
    setDynamicPricing({
      open: true,
      edit: "pricing",
      title: "Pricing",
    });
    setSelectedItemBrandId(brandId);
    const filterArray = crawlers.filter((value) => value._id === brandId);
    setProductInfo(...filterArray);
  };

  return (
    <>
      <div className="d-flex justify-content-end align-center gap-8 flex-wrap">
        <SearchFilter
          type="text"
          placeholder={"Search Title, SKU, Model"}
          value={SearchFillter?.title}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              title: e.target.value,
            })
          }
        />

        <SearchFilter
          width={"250px"}
          type="text"
          placeholder={"Search Discount ID"}
          value={SearchFillter?.discountId}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              discountId: e.target.value,
            })
          }
        />

        <SelectSearchFilter
          title={"Brand"}
          placeholder="Select"
          width={"200px"}
          value={
            SearchFillter?.brandId
              ? Brand.find((item) => item?.id === SearchFillter?.brandId).value
              : ""
          }
          options={[...Brand]}
          onChange={(value) => {
            if (value === "") {
              setSearchFillter({
                ...SearchFillter,
                brandId: "",
              });
            } else {
              const selectedBrand = Brand.find((item) => item.value === value);
              setSearchFillter({
                ...SearchFillter,
                brandId: selectedBrand ? selectedBrand.id : "",
              });
            }
          }}
        />

        <SelectSearchFilter
          title={"Error Codes"}
          width={"250px"}
          placeholder="Select"
          value={
            `${SearchFillter?.errorCode}`.charAt(0).toUpperCase() +
            `${SearchFillter?.errorCode}`.slice(1)
          }
          options={[
            { value: "True", label: "True" },
            { value: "False", label: "False" },
          ]}
          onChange={(value) => {
            if (value === "") {
              setSearchFillter({
                ...SearchFillter,
                errorCode: "",
              });
            } else {
              const newValue = value === "True";
              setSearchFillter({
                ...SearchFillter,
                errorCode: newValue,
              });
            }
          }}
        />

        <SelectSearchFilter
          title={"Crawl Stage"}
          width={"250px"}
          placeholder="Select Crawl Stage ..."
          value={SearchFillter?.crawlStage}
          options={[
            { value: "Link Validation 1", label: "Link Validation 1" },
            { value: "GC1", label: "GC1" },
            { value: "GC3", label: "GC3" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              crawlStage: value,
            })
          }
        />

        <SelectSearchFilter
          title={"Marked as Error"}
          width={"250px"}
          placeholder="Select Crawl Stage ..."
          value={SearchFillter?.errorMarked}
          options={[
            { value: "Link Validation", label: "Link Validation" },
            { value: "Google Crawl", label: "Google Crawl" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              errorMarked: value,
            })
          }
        />

        <SelectSearchFilter
          title={"Crawl Type"}
          width={"250px"}
          placeholder="Select Crawl Stage ..."
          value={SearchFillter?.errorMarked}
          options={[
            { value: "discovery", label: "discovery" },
            { value: "price", label: "price" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              crawlType: value,
            })
          }
        />
      </div>
      {loading ? (
        // <TopBarProgress />
        <TableLoader rowsNum={20} cellNum={57} />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Dynamic Pricing"
              subTitle={`items`}
              count={pagePagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Raw Title</th>
                      <th>Brand</th>
                      <th>SKU</th>
                      <th>Inventory</th>
                      <th>Seller Diversity</th>
                      <th>Seller Diversity Change</th>
                      <th>Crawl Type</th>
                      <th>Crawl Stage</th>
                      <th>Error Codes</th>
                      <th>Batch No</th>
                      <th>Valid URL Link</th>
                      <th>Marked As Error</th>
                      <th>Crawl Page Status</th>
                      <th>Dynamic Pricing</th>
                      <th>Price Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    {crawlers?.length ? (
                      <>
                        {crawlers?.map((item, i) => (
                          <tr key={item?._id}>
                            <td>
                              <img
                                src={item?.productId?.mainImage}
                                alt={item?.name}
                                style={{
                                  width: "50px",
                                  height: "40px",
                                }}
                              />
                            </td>
                            <td> {item?.name} </td>
                            <td>{item?.productId?.manufacturer}</td>
                            <td>{item?.productId?.sku}</td>
                            <td>{item?.productId?.quantity}</td>
                            <td>{item?.productId?.modelNumber}</td>

                            <td>1</td>
                            <td>{item?.crawlReport?.crawlerType}</td>
                            <td>{item?.crawlReport?.crawlStage}</td>
                            <td style={{ color: "red", fontWeight: 500 }}>{`${
                              item?.crawlReport?.linkFound
                                ? ""
                                : "Link Found: false"
                            }`}</td>

                            <td>{item?.batchNo}</td>
                            <td>None</td>
                            {/* <td>{`${item?.crawlReport?.sundialExists}`}</td> */}
                            <td><a 
                                href={item?.crawlReport?.validUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Link
                              </a></td>
                            <td>
                              <CustomeTableBtn
                                color={`${setButtonBackgroundColor(
                                  item?.crawlReport?.productMatchingStatus
                                )}`}
                                width={"full"}
                                className="font-size-sm"
                                onClick={() => handleRefund(item._id)}
                              >
                                {item?.crawlReport?.productMatchingStatus}
                              </CustomeTableBtn>
                            </td>

                            <td>
                              <button
                                onClick={() => handleDiscountButton(item?._id)}
                                style={{
                                  border: "1px solid black",
                                  padding: ".4rem 1.2rem",
                                  backgroundColor: "transparent",
                                  color: "black",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  borderRadius: ".2rem",
                                }}
                              >
                                Pricing
                                {/* $2452.69
                                <span
                                  style={{
                                    marginLeft: "8px",
                                    color: "black",
                                    fontWeight: "normal",
                                  }}
                                >
                                  7.59%
                                </span> */}
                              </button>
                            </td>
                            <td>1</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {/* Side bar Drawer */}
                <PriceCardDrawer Edit={Edit} setEdit={setEdit} shippingId="22">
                  <PriceCard productInfo={productInfo} />
                </PriceCardDrawer>

                <PriceCardDrawer
                  Edit={dynamicPricing}
                  setEdit={setDynamicPricing}
                  shippingId="23"
                >
                  <PricingSidebar
                    selectedProductInfo={productInfo}
                    selectedItemBrandId={selectedItemBrandId}
                  />
                </PriceCardDrawer>
                <div className="d-flex justify-content-center align-center p-2">
                  <div className="mx-auto">
                    <Pagination
                      count={pagePagination.totalPages}
                      page={pagePagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {crawlers?.length} of {pagePagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicPricing;
