import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUsersDetails } from "../../../actions/user";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../components/alert/alert.component";
import { setUserCashback } from "../../../services/vendor/vendor.service";
import { withRouter } from "../../../utils/withRouter";

const checkIconStyle = {
  position: "absolute",
  top: "5px",
  right: "5px",
  background: "green",
  borderRadius: "50%",
  overflow: "hidden",
  color: "#fff",
  fontSize: "1.4rem",
  cursor: "pointer",
};
const cashBackInputStyle = {
  // background: "transparent",
  position: "relative",
  zIndex: 1,
  width: "82px",
};
class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orders: [],
      userData: [],
      cashbackHistory: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = window.location.toString().split("user-details/")[1];
    const res = await this.props.getUsersDetails(id);
    // console.log("ansab", res.data.cashbackHistory);
    this.setState({
      loading: false,
      userData: res.data.user,
      orders: res.data.orders,
      cashbackHistory: res.data.cashbackHistory,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _approveSeller = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.updateSeller({ userId: id });

    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate("/admin/sellers");
      }, 3000);
    }
  };

  _rejectSeller = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.deleteSeller({ userId: id });
    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate("/admin/sellers");
      }, 3000);
    }
  };
  getCashBackPercentageValue = (number) => {
    const isGreaterThanZero = number > 0;
    const isLessThanZero = number < 0;
    const isZero = number < 1;

    switch (true) {
      case isGreaterThanZero:
        return number;
      case isLessThanZero:
        return <span>Off</span>;
      case isZero:
        return <span>0</span>;
      default:
        return number;
    }
  };
  handleCashbackChange = (e, index) => {
    const { value } = e.target;
    // console.log("handleCashbackChange..", value);

    if (value < 0) {
      return;
    } else {
      let tempState = this.state?.userData;
      // tempState.push(this.state.userData);

      // debugger;
      // console.log("tempState...", tempState);
      tempState.cashBack = value;
      // console.log("tempState...after...", tempState);

      this.setState({
        userData: tempState,
      });
    }
  };

  render() {
    const { userData, orders, cashbackHistory } = this.state;
    const date = new Date(userData.createdAt).toLocaleString();
    // console.log("userData...", userData, "orders.....", orders);
    // console.log("cashbackHistory...", this.cashbackHistory);

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            View User{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/users">Users</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User Detail
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body table-responsive p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  User Detail
                </h4>
                <div
                  className="p-2 "
                  style={{ borderBottom: "2px solid rgb(220, 220, 220) " }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          Name
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          Email{" "}
                        </th>

                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          Joined At{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          No of Orders{" "}
                        </th>

                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          Cashback
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userData.fullName}</td>
                        <td>{userData.email}</td>
                        <td>{date}</td>
                        <td>{userData.numberOfOrders}</td>
                        <td
                          className="text-center position-relative"
                          style={{ width: "250px" }}
                        >
                          <input
                            style={{
                              ...cashBackInputStyle,
                              width: "75px",
                              // color:
                              //   userData?.cashBack <= 0 ? "transparent" : "",
                            }}
                            className="h5 text-center border-0 border-bottom border-secondary"
                            type="number"
                            onChange={(e) => this.handleCashbackChange(e)}
                            value={userData?.cashBack}
                          />

                          {/* <div
                            style={{
                              position: "absolute",
                              top: "47%",
                              left: "41%",
                              transform: "translate(-50%, -50%)",
                            }}
                            className="bg-white h5 px-1"
                          >
                            {isNaN(
                              this.getCashBackPercentageValue(
                                userData?.cashBack
                              )
                            ) &&
                              this.getCashBackPercentageValue(
                                userData?.cashBack
                              )}
                          </div> */}
                          <i
                            title="Save"
                            onClick={() =>
                              setUserCashback(
                                userData?._id,
                                +userData?.cashBack
                              )
                            }
                            className="icon icon-check"
                            style={checkIconStyle}
                          ></i>
                        </td>
                        {/* <td>
                          <input type="number" value={userData.cashback} />
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* =====order list ===== */}
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                    marginTop: "2rem",
                  }}
                >
                  Order Details
                </h4>
                <div className="p-2 ">
                  <table
                    className="table table-bordered"
                    style={{
                      overflow: "scroll",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          Image{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Title
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Status
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Cost
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Order #
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Shipping Fee
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Model Number
                        </th>{" "}
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Quantity
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Tax
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((order, index) => {
                        return (
                          <tr key={order._id}>
                            <td>
                              <img
                                src={order.mainImage}
                                alt="order pic"
                                style={{ width: "60px", height: "60px" }}
                              />
                            </td>
                            <td style={{ width: "200px" }}>{order.title}</td>
                            <td>{order.status}</td>
                            <td>{order.cost}</td>
                            <td>{order.itemOrderNumber}</td>
                            <td>{order.shippingFee}</td>
                            <td>{order.modelNumber}</td>
                            <td>{order.quantity}</td>

                            <td>{order.itemTax}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* ===== cash back==== */}
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                    marginTop: "2rem",
                  }}
                >
                  CashBack history
                </h4>
                <div className="p-2 ">
                  <table
                    className="table table-bordered"
                    style={{
                      overflow: "scroll",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Earn CashBack
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          CashBack Used
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Status
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          CreatedAt
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashbackHistory?.map((cashback) => {
                        return (
                          <tr key={cashback._id}>
                            <td className="text-success">
                              {cashback.cashBack}
                            </td>
                            <td className="text-danger">
                              {cashback.usedCashBack}
                            </td>
                            <td>{cashback.status}</td>
                            <td>
                              {new Date(cashback.createdAt).toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Alert />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

getUsersDetails.propTypes = {
  getUsersDetails: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { getUsersDetails })(Edit));
