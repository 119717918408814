import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ChildrenModal({ show, handleClose, children }) {
  return (
    <Modal show={show} onHide={handleClose} centered style={{ margin: 0 }}>
      <Modal.Dialog style={{ margin: 0 }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center justify-content-center ">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-plus-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fill-rule="evenodd"
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                />
              </svg>
              <p className="m-0 p-0 ml-2">Sub Category</p>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th style={{ cursor: 'pointer', width: 10 }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {children.map((category, i) => (
                <tr key={category._id}>
                  <td>{i + 1}</td>
                  <td>{category.name}</td>

                  <th style={{ cursor: 'pointer', width: 10 }}>
                    <Link to={`/admin/create-filter/?id=${category._id}`}>
                      <Button
                        className="ml-2"
                        variant="outline-primary"
                        size="sm"
                      >
                        Customize Filters
                      </Button>
                    </Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
}
