import React, { useState } from "react";
import style from "./create-clone.module.css";

const CreateClone = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [cloneState, setCloneState] = useState({
    decorValue: "",
    discountValue: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCloneState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className={style.cloneHeading}>
        <h2 className={style.cloneTitle}>Create Clone</h2>
      </div>
      <div className={style.cloneContainer}>
        <div className={style.discountDetail}>
          <p>Enter Discount Details:</p>
          <p>[Seller Name]_[Discount Description]_[Discount Code]</p>
        </div>
        <div className={style.inputBox}>
          <div className={style.inputContainer}>
            <input
              className={style.decorInput}
              type="text"
              name="decorValue"
              value={cloneState.decorValue}
              onChange={handleChange}
              placeholder="Dynamic Home Decor"
            />
          </div>
          <div className={style.inputContainer}>
            <input
              className={style.decorInput}
              type="text"
              name="discountValue"
              value={cloneState.discountValue}
              onChange={handleChange}
              placeholder="Apply Dynamic Discount"
            />
          </div>
        </div>
        <p className={style.discountType}>Select Discount Type:</p>
        <div className={style.checkBoxes}>
          {/* Discount type options */}
          <div className={style.checkBoxesInput}>
            <div className={style.inputRadio}>
              <input
                // className={style.inputRadio}
                type="radio"
                id="percent"
                name="options"
                value="Percent Off(%)"
                checked={selectedOption === "Percent Off(%)"}
                onChange={handleOptionChange}
              />
              <label htmlFor="percent">Percent Off(%)</label>
            </div>
            <div className={style.inputRadio}>
              <input
                type="radio"
                id="dollar"
                name="options"
                value="Dollar Off($)"
                checked={selectedOption === "Dollar Off($)"}
                onChange={handleOptionChange}
              />
              <label htmlFor="dollar">Dollar Off($)</label>
            </div>
          </div>

          <div className={style.discountEnterValue}>
            <p>DiscounValue:</p>
            <p>If percent enter as whole number (for 25% off enter 25)</p>
            <p>If dollar value enter full amount (for $100 off enter 100)</p>
          </div>
          <div className={style.inputValue}>
            <input
              style={{ marginBottom: ".5rem" }}
              className={style.decorInput}
              type="text"
              name="decorValue"
              value={cloneState.decorValue}
              onChange={handleChange}
              placeholder="Enter Value"
            />
          </div>
        </div>
      </div>
      <div className={style.linkButtonDiv}>
        <button className={style.linkButton}>Create Discount ID Clone</button>
      </div>
    </>
  );
};

export default CreateClone;
