import React from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";

const VendorCard = ({
  children,
  title,
  btnShow,
  addNewAddress,
  handeNewContact,
  totgleShow,
  user,
  setuser,
  submitBtn,
  handleSubmit,
  Loading2,
  hanldeClose,
  closeBtn,
}) => {
  return (
    <div className="card font-weight-bold mb-4">
      <div
        style={{ background: "#fafafa !importan " }}
        className="card-header  d-flex justify-content-between align-center"
      >
        {title}
        {btnShow && (
          <div className=" d-flex justify-content-between align-center gap-10">
            <button
              className=" btn btn-success"
              onClick={() => btnShow && addNewAddress()}
            >
              {hanldeClose ? "Close" : "+Add New Address"}
            </button>
            <button
              className=" btn btn-success"
              onClick={() => btnShow && handeNewContact()}
            >
              +Add New Contact
            </button>
          </div>
        )}
        {totgleShow && (
          <ToggleSwitch
            checked={
              user?.shippingAndReturns?.policyType === "vendor" ? true : false
            }
            onChange={(e) => {
              setuser({
                ...user,
                shippingAndReturns: {
                  ...user?.shippingAndReturns,
                  policyType: e.target.checked === true ? "vendor" : "sundial",
                },
              });
            }}
            Name={"policyType"}
          />
        )}
        {submitBtn && (
          <button
            className=" btn btn-success"
            onClick={() => submitBtn && handleSubmit()}
          >
            {Loading2 ? "Loading...." : "Update"}
          </button>
        )}
        {closeBtn && (
          <button
            className=" btn btn-danger"
            onClick={() => closeBtn && hanldeClose()}
          >
            close
          </button>
        )}
      </div>
      <div className=" bg-white">{children}</div>
    </div>
  );
};

export default VendorCard;
