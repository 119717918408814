import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const RatingStars = ({ totalStars, handleClick, styles }) => {
  return (
    <Box
      style={{
        cursor: "pointer",
        padding: ".3rem 1rem .1rem",
        backgroundColor: "#FFF8E6",
        border: "2px solid #FFB706",
        borderRadius: ".3rem",
        ...styles,
      }}
      onClick={handleClick}
    >
      <Rating
        value={totalStars ?? 0}
        readOnly
        emptyIcon={<StarIcon style={{ display: "none" }} />}
      />
    </Box>
  );
};

export default RatingStars;
