/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styles from "./imgBanner.module.css";
import { Button, Modal, Form } from "react-bootstrap";

function ImgBanner({
  handleRemoveSection,
  handleChangeText,
  index,
  show,
  handleFileChange,
  handleClose,
  selectedIndex,
  section,
  handleShow,
  mainIndex,
}) {
  const [edit, setEdit] = useState({
    title: [false, false],
    desc: [false, false],
  });
  const handleEdit = (name, index) => {
    setEdit({
      ...edit,
      [name]: edit[name].map((value, i) => (i === index ? !value : value)),
    });
    console.log(edit);
  };
  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        <div className="row">
          {section.values.map((value, _index) => (
            <>
              <div key={_index} className={`col-md-6 ${styles.bannerCol}`}>
                <div className="d-block h-100">
                  <div
                    className={`h-100 ${styles.sales1Content} m-0 m-sm-2 m-md-2 d-flex flex-column justify-content-between`}
                  >
                    <div className="program-heading justify-content-center  d-flex align-items-center">
                      {!edit.title[_index] ? (
                        <h2 className="text-center text-black">
                          {value.title || (
                            <span style={{ opacity: "0.5" }}>Title</span>
                          )}
                        </h2>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center px-2">
                          <input
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEdit("title", _index);
                                handleChangeText(e, index, _index);
                              }
                            }}
                            name="title"
                            defaultValue={value.title || ""}
                            onBlur={(e) => {
                              handleEdit("title", _index);
                              handleChangeText(e, index, _index);
                            }}
                            className={styles.titleInput}
                            autoFocus={true}
                            placeholder="Title"
                          />
                        </div>
                      )}
                      <i
                        onClick={() => handleEdit("title", _index)}
                        className="icon-pencil text-white p-2"
                        title="Edit"
                      ></i>
                    </div>
                    {!edit.desc[_index] ? (
                      <div className="d-flex justify-content-center align-items-center program-quote ">
                        <h6 className="text-black text-cetner">
                          {value.desc || (
                            <span style={{ opacity: 0.5 }}>Description</span>
                          )}
                        </h6>
                        <i
                          onClick={() => handleEdit("desc", _index)}
                          className="icon-pencil text-white p-2"
                          title="Edit"
                        ></i>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center px-2">
                        <input
                          type="text"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEdit("desc", _index);
                              handleChangeText(e, index, _index);
                            }
                          }}
                          name="desc"
                          defaultValue={value.desc || ""}
                          onBlur={(e) => {
                            handleEdit("desc", _index);
                            handleChangeText(e, index, _index);
                          }}
                          className={styles.descInput}
                          autoFocus={true}
                          placeholder="Description"
                        />
                      </div>
                    )}
                    <h5 className="text-center">
                      <a href="" className="d-block text-center text-black" >
                        Learn More
                      </a>
                    </h5>
                    <div className="f1  d-flex align-items-cetner position-relative bg-less-img">
                      <div className={`${styles.cardImg} position-absolute`}>
                        <i
                          className="icon-pencil"
                          onClick={() => handleShow(_index, index)}
                        ></i>
                      </div>
                      <img
                        src={value.url}
                        width="100%"
                        className="border-radius-8"
                        alt="Discover all of todays best saving"
                      />
                    </div>
                  </div>
                </div>{" "}
              </div>
              <Modal
                show={show && _index === selectedIndex && mainIndex === index}
                onHide={handleClose}
              >
                <Modal.Header closeButton centered>
                  <Modal.Title>Change Banner Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {value?.url && (
                    <div className="d-flex justify-content-center mb-3">
                      <img src={value?.url} alt="preview" width="400px" />
                    </div>
                  )}
                  <Form.Group controlId="formBasicimage" className="m-2">
                    <Form.Label>Upload File </Form.Label>
                    <Form.File
                      id={index}
                      label="Add image"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleFileChange(e, index, _index);
                      }}
                      custom
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasiclink" className="m-2">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="paste link"
                      name="link"
                      defaultValue={value?.link ?? ""}
                      onBlur={(e) => handleChangeText(e, index, _index)}
                    />
                    <Form.Text className="text-muted">
                      This Link will redirect to product page or category page
                    </Form.Text>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={`${styles.actionBtn} btn-danger`}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ))}
        </div>
      </div>
      <div className={`bg-white ${styles.h93}`}>
        <i
          onClick={() => handleRemoveSection(index)}
          className={`${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
          title="Remove"
          style={{ height: "fit-content" }}
        ></i>
      </div>
    </div>
  );
}

export default ImgBanner;
