import React from 'react'
import { useDispatch } from "react-redux";
import { detailUpdate, seoTilteAndDescGenerate } from '../../../../../actions/seo';
import { useParams } from 'react-router-dom'

import { useState } from 'react';
import CircularProgress from "@mui/material/CircularProgress"

const Header = (props) => {
    const { title, btn, data, seoId } = props
    const { categoryId, brandId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [loading1, set1Loading] = useState(false)




    return (
        <nav className="navbar navbar-light bg-light py-4">
            <span className="navbar-brand mb-0 h3">{title}</span>

            {btn && (
                <>
                    <div className=' d-flex d-flex justify-content-space-between '>
                        <button
                            type="button"
                            className="btn btn-success d-flex justify-content-center align-center "
                            onClick={() => {
                                set1Loading(true);
                                dispatch(seoTilteAndDescGenerate(seoId));
                                setTimeout(() => {
                                    set1Loading(false);
                                }, 2000);
                            }}
                        >
                            Generate Seo Title & Desc
                            {loading1 && <CircularProgress size={15} />}
                        </button>
                        <button
                            type="button"
                            className="btn btn-success d-flex justify-content-center align-center mx-5"
                            onClick={() => {
                                setLoading(true);
                                dispatch(detailUpdate(brandId, categoryId, data));
                                setTimeout(() => {
                                    setLoading(false);
                                }, 2000);
                            }}
                        >
                            Update{loading && <CircularProgress size={15} />}
                        </button>
                    </div>
                </>
            )}
        </nav>
    );
}

export default Header
