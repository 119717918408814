import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getSellers,
  updateSeller,
  deleteSeller,
} from "../../../actions/seller";

import TopBarProgress from "react-topbar-progress-indicator";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class Seller extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sellers: null,
      sellersClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 10,
      deleteId: null,
    };
  }

  async componentDidMount() {
    const res = await this.props.getSellers();
    this.setState({
      sellers: res.data,
      sellersClone: res.data,
      loading: false,
    });
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { sellers, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(sellers.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteSeller = async (id) => {
    this.setState({ loading: true });
    const data = {
      userId: id,
    };
    const _res = await this.props.deleteSeller(data);
    if (_res) {
      toast(`Seller deleted`, { containerId: "B" });
    }
    const res = await this.props.getSellers();
    this.setState({ loading: false, deleteConfirm: false, sellers: res.data });
  };

  _approveSeller = async (id) => {
    this.setState({ loading: true });
    const res = await this.props.updateSeller({ userId: id });
    if (res) {
      toast(`${res}`, { containerId: "B" });
    }
    const _res = await this.props.getSellers();
    this.setState({ loading: false, deleteConfirm: false, sellers: _res.data });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const filteredArr = [];
      this.state.sellers.forEach((element) => {
        if (
          element.email.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      this.setState({ sellers: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ sellers: this.state.sellersClone });
    }
  };

  render() {
    const { loading, sellers, currentPage, dataPerPage } = this.state;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData =
      sellers && sellers.slice(indexOfFirstData, indexOfLastData);

    const renderTodos =
      currentData &&
      // eslint-disable-next-line array-callback-return
      currentData.map((item, index) => {
        if (item && item.becomeVendor) {
          return (
            <tr key={index}>
              <td> {index + 1} </td>
              <td> {item.fullName} </td>
              <td>{item.email}</td>
              <td> {item.userName} </td>
              <td>
                {" "}
                <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>{" "}
              </td>
              <td>
                <Link to={`/admin/vendor/edit-vendor/${item._id}`}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mr-4"
                    style={{ width: 78 }}
                  >
                    Edit
                  </button>
                </Link>

                <button
                  type="button"
                  className="btn btn-success btn-sm mr-4"
                  style={{ width: 86 }}
                  onClick={() => {
                    this._approveSeller(item._id);
                  }}
                  disabled={item.isVendor ? true : false}
                >
                  Approve
                </button>

                <button
                  className="btn btn-danger btn-sm"
                  type="button"
                  onClick={() => {
                    this.setState({ deleteConfirm: true, deleteId: item._id });
                  }}
                >
                  Delete
                </button>

                {this.state.deleteConfirm && (
                  <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => {
                      this._deleteSeller(this.state.deleteId);
                    }}
                    onCancel={this._onCancel}
                    focusCancelBtn
                  >
                    Are you sure you want to delete?
                  </SweetAlert>
                )}
              </td>
            </tr>
          );
        }
      });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(sellers && sellers.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });

    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {sellers && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View Sellers </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/sellers">Sellers</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Sellers List</h4>
                    <span
                      className="twitter-typeahead"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      <input
                        className="typeahead form-control tt-input"
                        type="text"
                        placeholder="Search..."
                        autoComplete="off"
                        spellCheck="false"
                        dir="auto"
                        style={{
                          position: "relative",
                          verticalAlign: "top",
                          backgroundColor: "transparent",
                          borderColor: "green",
                          color: "black",
                        }}
                        onKeyDown={this._handleKeyDown}
                        onChange={this._onChangeHandler}
                      />
                    </span>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Owner Name </th>
                          <th> Email </th>
                          <th> Username</th>
                          <th> Created At </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sellers && sellers.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No seller found</td>
                          </tr>
                        ) : (
                          renderTodos
                        )}
                      </tbody>
                    </table>
                    <div
                      style={{
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {sellers.length > 0 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.previous}
                              >
                                Previous
                              </button>
                            </li>

                            {renderPageNumbers}
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.next}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

getSellers.propTypes = {
  getSellers: PropTypes.func.isRequired,
};

updateSeller.propTypes = {
  updateSeller: PropTypes.func.isRequired,
};

deleteSeller.propTypes = {
  deleteSeller: PropTypes.func.isRequired,
};

export default connect(null, { getSellers, updateSeller, deleteSeller })(
  Seller
);
