import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../components/alert/alert.component";

import { create } from "../../../actions/homeAds";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      adUrl: "",
      adUrl1: "",
      adUrl2: "",
      adUrl3: "",
      images: [],
      ad1: "",
      ad2: "",
      ad3: "",
      ad4: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      adUrl,
      adUrl1,
      adUrl2,
      adUrl3,
      ad1,
      ad2,
      ad3,
      ad4,
      // eslint-disable-next-line no-unused-vars
      images,
    } = this.state;

    let formData = new FormData();
    console.log(ad1);
    formData.append("adUrl1", adUrl);
    formData.append("adUrl2", adUrl1);
    formData.append("adUrl3", adUrl2);
    formData.append("adUrl4", adUrl3);
    formData.append("ad1", ad1);
    formData.append("ad2", ad2);
    formData.append("ad3", ad3);
    formData.append("ad4", ad4);



    this.setState({ loading: true });

    const res = await this.props.create(formData);

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      toast(`${res}`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();
    console.log(e.target.files[0]);
    var name = e.target.name;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        [name]: file,
      });
    };
    reader.readAsDataURL(file);
    console.log(this.state.ad1);
  }

  render() {
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create HomeAds </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/banners">HomeAds</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create HomeAds
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add a new HomeAd</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">HomeAd One Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="url"
                      placeholder="Url"
                      name="adUrl"
                      value={this.state.adUrl}
                      onChange={(e) => this.setState({ adUrl: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Ad one </label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        name="ad1"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">HomeAd Two Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="url"
                      placeholder="Url"
                      name="adUrl"
                      value={this.state.adUrl1}
                      onChange={(e) =>
                        this.setState({ adUrl1: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ad Two </label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        name="ad2"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">HomeAd Three Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="url"
                      placeholder="Url"
                      name="adUrl2"
                      value={this.state.adUrl2}
                      onChange={(e) =>
                        this.setState({ adUrl2: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ad Three </label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        name="ad3"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">HomeAd Four Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="url"
                      placeholder="Url"
                      name="adUrl3"
                      value={this.state.adUrl3}
                      onChange={(e) =>
                        this.setState({ adUrl3: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ad Four </label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        name="ad4"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/banners"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
