import React, { useState } from "react";
import { GrEdit } from "react-icons/gr";
import { MdArrowDropDown } from "react-icons/md";

const CostUpdateWrapper = ({ title, status, children }) => {
  const [Open, setOpen] = useState(false);
  return (
    <>
      <div
        className=" d-flex justify-content-between align-center px-3"
        onClick={() => setOpen(!Open)}
      >
        <div>
          <p className=" font-weight-bold">{title}</p>
        </div>
        <div className=" d-flex justify-content-between align-center">
          {status && (
            <>
              {status === "Pending" ? (
                <>
                  <p
                    className=" font-weight-bold c-pointer"
                    style={{ color: "#ffb706" }}
                  >
                    {status}
                  </p>
                  <MdArrowDropDown className="h2 mb-0 c-pointer" />
                </>
              ) : (
                <span
                  style={{
                    border: "1px solid #17a858",
                    padding: "10px",
                    borderRadius: "200px",
                    color: "#17a858",
                    cursor: "pointer",
                    background: "#d1eede",
                  }}
                >
                  <GrEdit
                    style={{
                      fontSize: "20px",
                      fill: "#17a858",
                    }}
                  />
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <hr />
      {Open && children}
    </>
  );
};

export default CostUpdateWrapper;
