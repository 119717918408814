/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React from 'react';
import { Link } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getChildSubCategories } from "../../../services/categories/child-subcategories";

import { subCategories } from "../../../actions/subCategory";
import { retreive, edit } from "../../../actions/childSubCategory";
import ImageUploader from "react-images-upload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFilterGrouping } from "../../../actions/filters";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";

import { Modal, Button } from "react-bootstrap";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { withRouter } from '../../../utils/withRouter';

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subCategoryId: null,
      name: "",
      displayName: "",
      slug: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      subCategories: null,
      show: true,
      filterOptions: [],
      image: "",
      newFilterData: [],
      showModal: false,
      isbase64: false,
      banner: "",
      isBannerBase64: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const id = this.props.location.search.substr(4);

    // const _res = await this.props.subCategories();
    const _res = await getChildSubCategories();

    this.setState({ subCategories: _res?.data });

    const res = await this.props.retreive(id);
    const res1 = await this.props.getFilterGrouping();
    let tempArr = [];
    res1.data.map((item) => {
      tempArr.push({
        value: item._id,
        label: item.title,
        isFixed: item.status,
      });
    });
    this.setState({ newFilterData: tempArr });

    const metaTagData = [];

    res.metaTag &&
      res.metaTag.forEach((element) => {
        metaTagData.push({
          value: element,
          label: element,
        });
      });

    const metaKeywordData = [];

    res.metaKeyword &&
      res.metaKeyword.forEach((_element) => {
        metaKeywordData.push({
          value: _element,
          label: _element,
        });
      });

    const seoKeywordData = [];

    res.seoKeyword &&
      res.seoKeyword.forEach((element) => {
        seoKeywordData.push({
          value: element,
          label: element,
        });
      });
    const filterOptionsData = [];
    this.state.newFilterData.map((item) => {
      res.filter.map((item1) => {
        if (item.value === item1) {
          filterOptionsData.push({
            value: item.value,
            label: item.label,
            isFixed: item.isFixed,
          });
        }
      });
    });
    await this.setState({
      loading: false,
      slug: res.slug,
      categoryId: res._id,
      displayName: res.displayName,
      subCategoryId: res.categoryId,
      name: res.name,
      description: res.description,
      metaTag: metaTagData,
      metaDescription: res.metaDescription,
      metaKeyword: metaKeywordData,
      seoKeyword: seoKeywordData,
      show: res.show,
      image: res.image,
      filterOptions: filterOptionsData,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  _filterJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.filterOptions}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({
              value: item.value,
              label: item.title,
            });
          });

        data.length > 0
          ? this.setState({
              filterOptions: data,
            })
          : this.setState({
              filterOptions: null,
            });
      }}
      options={this.state.newFilterData}
    />
  );
  _submitHandler = async () => {
    const {
      subCategoryId,
      categoryId,
      name,
      displayName,
      slug,
      description,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      show,
      filterOptions,
      image,
      isbase64,
      banner,
      isBannerBase64,
    } = this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);

    const metaTagData = [];

    metaTag.forEach((element) => {
      metaTagData.push(element.value);
    });

    const metaKeywordData = [];

    metaKeyword.forEach((_element) => {
      metaKeywordData.push(_element.value);
    });

    const seoKeywordData = [];

    seoKeyword.forEach((element) => {
      seoKeywordData.push(element.value);
    });
    const filterOptionData = [];
    filterOptions.forEach((element) => {
      filterOptionData.push(element.value);
    });
    const dataToSend = {
      name: name,
      slug: slug,
      displayName,
      _id: id,
      categoryId: subCategoryId,
      image: image,
      metaTag: metaTagData,
      metaDescription: metaDescription,
      metaKeyword: metaKeywordData,
      seoKeyword: seoKeywordData,
      description: description,
      show: show,
      isbase64,
      banner,
      isBannerBase64,
    };

    this.setState({ loading: true });
    const res = await this.props.edit(dataToSend);

    this.setState({ loading: false });

    if (res) {
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _metaTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaTag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaTag: data,
            })
          : this.setState({
              metaTag: null,
            });
      }}
    />
  );

  _metaKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: null,
            });
      }}
    />
  );

  _seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: null,
            });
      }}
    />
  );
  _handleImageChange = async (e) => {
    // e.preventDefault();
    const base64Image = await this.toBase64(e[0]);
    this.setState({
      image: base64Image,
      isbase64: true,
    });
  };

  _handleImageBannerChange = async (e) => {
    // e.preventDefault();

    const base64Image = await this.toBase64(e[0]);
    this.setState({
      banner: base64Image,
      isBannerBase64: true,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader?.result);
        reader.onerror = (error) => reject(error);
      }
    });

  open = () => this.setState({ showModal: true });

  handleClose = () => this.setState({ showModal: false });

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      filterOptions: arrayMove(this.state.filterOptions, oldIndex, newIndex),
    });
  };
  getCategoriesTree = () => {
    return (
      Array.isArray(this.state.subCategories) &&
      this.state.subCategories?.map((item, index) => (
        <option key={item?._id ?? index} value={item._id}>
          {`${item?.category?.maincategory?.name ?? ""} >  ${
            item?.category?.name ?? ""
          } > ${item?.name ?? ""}`}
        </option>
      ))
    );
  };

  render() {
    const {
      name,
      slug,
      displayName,
      description,
      metaDescription,
      subCategoryId,
      show,
      banner,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    const SortableItem = SortableElement(({ item }) => (
      <div className="__item">
        <div className="__inner-item">
          <p>{item.label}</p>
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div className="__container">
        {items.map((item, index) => (
          <SortableItem key={`${item.value}`} index={index} item={item} />
        ))}
      </div>
    ));

    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Edit Main Category </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/child-categories">Child Categories</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Main Category
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4
                  className="card-title d-flex align-item-center justify-content-statr"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(246, 246, 246)",
                    height: "40px",
                    paddingTop: "10px",
                    paddingLeft: "5px",
                  }}
                >
                  Edit
                </h4>
                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Name">Display Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="displayName"
                      value={displayName}
                      onChange={(e) =>
                        this.setState({ displayName: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      placeholder="Slug"
                      name="slug"
                      value={slug}
                      onChange={(e) => this.setState({ slug: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="category">Select parent category </label>

                    {this.state.subCategories && (
                      <select
                        className="form-control form-control-sm"
                        id="category"
                        onChange={(e) => {
                          this.setState({
                            subCategoryId: e.target.value,
                          });
                        }}
                        defaultValue={subCategoryId && subCategoryId}
                      >
                        <option>choose</option>
                        {this.getCategoriesTree()}
                      </select>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Image upload</label>

                    <div className="input-group col-xs-12">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={(e) => this._handleImageChange(e)}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                        withPreview={true}
                      />
                    </div>
                    <div className="input-group col-xs-12">
                      <img
                        src={this.state.image && this.state.image}
                        alt=""
                        width="100"
                        height="50"
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Banner upload</label>

                    <div className="input-group col-xs-12">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={(e) => this._handleImageBannerChange(e)}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                        withPreview={true}
                      />
                    </div>
                    <div className="input-group col-xs-12">
                      <img
                        src={banner && banner}
                        alt=""
                        width="100"
                        height="50"
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  </div>

                  {/* <div className="form-group">
                          <label htmlFor="Meta Tags">Filters</label>
                          {!this.state.loading && (
                            <>
                              <CreatableSelect
                                isMulti
                                value={
                                  this.state.filterOptions
                                    ? this.state.filterOptions
                                    : []
                                }
                                onChange={(e) => {
                                  const data = [];
                                  e &&
                                    e.map((item, i) => {
                                      return data.push({
                                        value: item.value,
                                        label: item.label,
                                      });
                                    });

                                  data.length > 0
                                    ? this.setState({
                                        filterOptions: data,
                                      })
                                    : this.setState({
                                        filterOptions: [],
                                      });
                                }}
                                options={this.state.newFilterData}
                              />
                              {this.state.filterOptions.length > 1 && (
                                <Button
                                  variant="secondary"
                                  onClick={this.open}
                                  className="mt-2"
                                >
                                  Sort Filters
                                </Button>
                              )}
                            </>
                          )}
                        </div> */}
                  <div className="form-group">
                    <label htmlFor="Meta Tags">Meta Title</label>

                    {!this.state.loading && this._metaTagJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Meta Keywords</label>

                    {!this.state.loading && this._metaKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="meta description">Meta Description</label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      value={metaDescription}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seo keywords">Seo Keywords</label>

                    {!this.state.loading && this._seoKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Seo Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={6}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label className="col-sm-3 col-form-label">
                      Show in Header
                    </label>
                    <div className="col-sm-12">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="1"
                            defaultValue={show}
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                show: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="membershipRadios2"
                            defaultValue={show}
                            onChange={() => {
                              this.setState({
                                show: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                  <Link to={"/admin/child-categories"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Sort</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SortableList
              items={this.state.filterOptions}
              onSortEnd={this.onSortEnd}
              axis="xy"
              helperClass="__SortableHelper"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};

getFilterGrouping.propTypes = {
  getFilterGrouping: PropTypes.func.isRequired,
};
export default withRouter(connect(null, {
  retreive,
  edit,
  getFilterGrouping,
})(Edit));
