import moment from "moment";
import React, { Fragment, useState } from "react";
import { MdLabel } from "react-icons/md";
import { priorityNotesUpdate } from "../../../../actions/saleAnalysis";
import { useDispatch } from "react-redux";
const ShowPriorityNotes = ({ title, notes, SelectItem }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [ReplyOpen, setReplyOpen] = useState({
    index: null,
    open: false,
  });

  const [Notes, setNotes] = useState(notes ?? []);
  const [message, setmessage] = useState("");

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    const newReply = {
      subject: Notes[index].subject,
      message: message,
      date: new Date(),
      read: false,
    };
    const newNotes = [...Notes];
    newNotes[index].reply.push(newReply);
    // send  Notes data  to api
    setloading(true);
    try {
      const res = await dispatch(
        priorityNotesUpdate(SelectItem._id, {
          replyTo: newNotes[index]._id,
          reply: newNotes[index].reply,
        })
      );
      if (res) {
        setNotes((pre) => {
          setmessage("");
          setReplyOpen({
            mainIndex: null,
            index: null,
            open: false,
          });
          return newNotes;
        });
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handlReadChange = async (mainIndex, index) => {
    const newNotes = [...Notes];
    if (mainIndex !== undefined && index !== undefined) {
      newNotes[mainIndex].reply[index].read = true;

      try {
        const res = await dispatch(
          priorityNotesUpdate(SelectItem._id, {
            replyTo: newNotes[index]._id,
            reply: newNotes[index].reply,
          })
        );
        if (res) {
          setloading(false);
          setNotes(newNotes);
        }
      } catch (error) {
        setloading(false);
      }
    } else {
      newNotes[mainIndex].read = true;
      try {
        const res = await dispatch(
          priorityNotesUpdate(SelectItem._id, {
            replyTo: newNotes[mainIndex]._id,
            read: newNotes[mainIndex].read,
          })
        );
        if (res) {
          setNotes(newNotes);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
      }
    }
  };

  return (
    <>
      <div className="px-3 py-3">
        <h4 className=" font-weight-bold">{title}</h4>
        {!Notes?.length && <p>N/A</p>}
        <div className="notes-container py-2">
          {Notes &&
            Notes.map((note, i) => (
              <Fragment key={i}>
                {note[0]}
                <div className=" d-flex justify-content-start ">
                  <div>
                    <MdLabel
                      onClick={() => handlReadChange(i)}
                      style={
                        note?.read
                          ? {
                              fontSize: "35px",
                              color: "#17a858",
                            }
                          : {
                              fontSize: "35px",
                              color: "#ffb706",
                            }
                      }
                    />
                  </div>
                  <div className=" pt-1 pl-2">
                    <h6>Subject:{note.subject}</h6>
                    <p>Date:{moment(note.date).format("ll")}</p>
                    <p>Text:{note?.message}</p>
                    <p
                      className="notes-reply-btn"
                      onClick={() =>
                        setReplyOpen({
                          mainIndex: i,
                          index: null,
                          open: !ReplyOpen.open,
                        })
                      }
                    >
                      Reply
                    </p>
                  </div>
                </div>
                {ReplyOpen.open &&
                  ReplyOpen.index === null &&
                  ReplyOpen.mainIndex === i && (
                    <div className="mt-2">
                      <form
                        onSubmit={(e) => handleSubmit(e, i)}
                        className=" d-flex flex-column"
                      >
                        <textarea
                          className=" p-3 bg-grey border-grey"
                          placeholder="Reply message"
                          cols="30"
                          rows="5"
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                        />
                        <button
                          type="submit"
                          className="update-Order-button text-white  mt-3 "
                        >
                          {loading ? "Loading" : "Send Notes"}
                        </button>
                      </form>
                    </div>
                  )}
                {note.reply &&
                  note.reply.map((rep, subIndex) => (
                    <div key={subIndex}>
                      <div className=" d-flex justify-content-start ">
                        <div>
                          <MdLabel
                            onClick={() => handlReadChange(i, subIndex)}
                            style={
                              rep?.read
                                ? {
                                    fontSize: "35px",
                                    color: "#17a858",
                                  }
                                : {
                                    fontSize: "35px",
                                    color: "#ffb706",
                                  }
                            }
                          />
                        </div>
                        <div className=" pt-1 pl-2">
                          <h6>Subject:{rep.subject}</h6>
                          <p>Date:{moment(rep.date).format("ll")}</p>
                          <p>Text:{rep.message}</p>
                          <p
                            className="notes-reply-btn"
                            onClick={() =>
                              setReplyOpen({
                                mainIndex: i,
                                index: subIndex,
                                open: !ReplyOpen.open,
                              })
                            }
                          >
                            Reply
                          </p>
                        </div>
                      </div>

                      {ReplyOpen.open &&
                        ReplyOpen.index === subIndex &&
                        ReplyOpen.mainIndex === i && (
                          <div className="mt-2">
                            <form
                              onSubmit={(e) => handleSubmit(e, i)}
                              className=" d-flex flex-column"
                            >
                              <textarea
                                className=" p-3 bg-grey border-grey"
                                placeholder="Reply message"
                                cols="30"
                                rows="5"
                                value={message}
                                onChange={(e) => setmessage(e.target.value)}
                              />
                              <button
                                type="submit"
                                className="update-Order-button text-white  mt-3 "
                              >
                                {loading ? "Loading" : "Send Notes"}
                              </button>
                            </form>
                          </div>
                        )}
                    </div>
                  ))}
              </Fragment>
            ))}
        </div>
      </div>
    </>
  );
};

export default ShowPriorityNotes;
