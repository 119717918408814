import React from "react";
import style from "./rating-details.module.css"

const RatingDetails = ({ ratingType, ratingValue , styles }) => {
  return (
    <div className={style.ratingContainer} style={{...styles}}>
      <h4 className={style.ratingType}>{ratingType}</h4>
      <p className={style.ratingValue}>{ratingValue}</p>
    </div>
  );
};

export default RatingDetails;
