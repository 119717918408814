import React, { useEffect } from "react";
import "../process-orders/styles.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { RiErrorWarningFill } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { ImAttachment } from "react-icons/im";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";
import { getAllPendingOrders } from "../../../../actions/order";
import EditTabelDrawer from "../../../../components/Edit-tabel-drawer/EditTabelDrawer";
import OrderDetails from "../components/OrderDetails";
import UpdateOrderComp from "../components/UpdateOrderComp";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import AddNewNotes from "../components/AddNewNotes";
import ShowNotes from "../components/ShowNotes";
import OrderStatusButton from "../components/OrderStatusButton";

import { Calculation } from "../../../../utils/Calculation";
import ShippingUpdateComp from "../components/ShippingUpdateComp";
import { getAllCompany } from "../../../../actions/shipping";
import CostBreakDown from "../sale-analysis/components/CostBreakDown";
import CostsUpdateComp from "../sale-analysis/components/CostsUpdateComp";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import DateRangeFilter from "../../../../components/CustomeFilter/DateRangeFilter";
import { MMDDYYFormat } from "../../../../utils/dateFormate";

const PendingOrders = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get("orderNumber");

  // Local State
  const [loading, setloading] = useState(true);
  const [InnderLoader, setInnderLoader] = useState(false);
  const [ShippingComapny, setShippingComapny] = useState([]);

  const [SelectItem, setSelectItem] = useState({});
  const [AllOrders, setAllOrders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Brand, setBrand] = useState(JSON.parse(localStorage.getItem("Brand")));

  const [IsMount, setIsMount] = useState(false);

  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });

  const initialSearchFilter = {
    OrderStatus: "pending",
    TrackingDetails: "",
    SearchByDate: "",
    SearchQuery: orderNumber ?? "",
    sort: "",
    claims: "",
    refunds: "",
    deliverytype: "",
    brand: "",
    shipandtrack: "",
    trackingStatus: "",
  };

  const [SearchFillter, setSearchFillter] = useState(initialSearchFilter);

  const resetFilters = () => {
    setSearchFillter(initialSearchFilter);
  };

  const setDateToEmpty = (inputDate) => {
    return inputDate === "mm/dd/yy,mm/dd/yy" ? "" : inputDate;
  };

  // Api calls for get Order
  const getOrders = useCallback(
    async (
      pageNumber,
      searchQuery,
      status,
      date,
      tracking,
      sort,
      claims,
      deliverytype,
      pickupDate,
      deliveryDate,
      deliveryEndDate,
      brand,
      trackingStatus,
      shipandtrack
    ) => {
      setInnderLoader(true);
      try {
        date = setDateToEmpty(date);
        const res = await dispatch(
          getAllPendingOrders(
            pageNumber,
            searchQuery,
            status,
            date,
            tracking,
            sort,
            claims,
            deliverytype,
            pickupDate,
            deliveryDate,
            deliveryEndDate,
            brand,
            shipandtrack,
            trackingStatus
          )
        );
        if (res) {
          // set to Seleted item when update because we set new Seleted item
          if (!SelectItem) {
            setSelectItem({});
          } else {
            const newSelected = res?.orders?.find(
              (order) => order._id === SelectItem._id
            );
            setSelectItem(newSelected);
          }
          setAllOrders(res?.orders);
          setpagination({
            ...res.pagination,
          });
          setloading(false);
          setInnderLoader(false);
        }
      } catch (error) {
        setloading(false);
        setInnderLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllOrders, SelectItem]
  );
  // company list
  const getAllComapnyList = useCallback(async () => {
    const res = await dispatch(getAllCompany());
    setShippingComapny(res);
  }, [dispatch]);
  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getOrders(
          pagination.currentPage,
          SearchFillter.SearchQuery,
          SearchFillter.OrderStatus,
          SearchFillter.SearchByDate,
          "",
          SearchFillter.sort,
          SearchFillter.claims,
          SearchFillter.deliverytype,
          "",
          "",
          SearchFillter.TrackingDetails,
          SearchFillter.brand,
          SearchFillter.shipandtrack
        );
        getAllComapnyList();
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    SearchFillter.OrderStatus,
    SearchFillter.SearchByDate,
    SearchFillter.TrackingDetails,
    SearchFillter.sort,
    SearchFillter.claims,
    SearchFillter.refunds,
    SearchFillter.brand,
    SearchFillter.deliverytype,
    SearchFillter.shipandtrack,
    IsMount,
    Edit.open,
  ]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/pending-orders",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  const handleStatusOpen = (item) => {
    setEdit({
      open: true,
      edit: "orderStatus",
      title: "Order Status",
    });
    setSelectItem({ ...item });
  };
  const handleNotes = (item) => {
    setEdit({
      open: true,
      edit: "Notes",
      title: "Notes",
    });
    setSelectItem({ ...item });
  };

  const handleShipping = (item) => {
    setEdit({
      open: true,
      edit: "shipping",
      title: "Shipping & Tracking",
    });
    setSelectItem({ ...item });
  };

  const handleCosts = (item) => {
    setEdit({
      open: true,
      edit: "editCosts",
      title: "Edit Costs",
    });
    setSelectItem({ ...item });
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex  justify-content-end align-center flex-wrap gap-10">
            <SearchFilter
              width={"250px"}
              type="text"
              placeholder={"Search Order #, Tracking #"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />

            <DateRangeFilter
              title={"Order Date"}
              placeholder={"Select"}
              value={SearchFillter?.SearchByDate}
              startDate={SearchFillter?.SearchByDate.split(",")[0]}
              endDate={SearchFillter?.SearchByDate.split(",")[1]}
              onChange={(Date) => {
                setSearchFillter({
                  ...SearchFillter,
                  SearchByDate: `${MMDDYYFormat(Date[0])},${MMDDYYFormat(
                    Date[1]
                  )}`,
                });
              }}
            />

            <SelectSearchFilter
              title={"Brand"}
              placeholder="Select"
              width={"200px"}
              value={
                SearchFillter?.brand
                  ? Brand.find((item) => SearchFillter?.brand === item?.id)
                      .value
                  : ""
              }
              options={Brand}
              onChange={(value) => {
                setSearchFillter({
                  ...SearchFillter,
                  brand:
                    value !== ""
                      ? Brand.find((item) => item.value === value)?.id
                      : "",
                });
              }}
            />

            <SelectSearchFilter
              title={"Delivery Type"}
              placeholder="Select"
              value={SearchFillter?.deliverytype}
              options={[
                { value: "standard", label: "Standard" },
                { value: "WG", label: "White Glove" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, deliverytype: value })
              }
            />
            <SelectSearchFilter
              title={"Shipping & Tracking"}
              placeholder="Select"
              value={SearchFillter?.shipandtrack}
              options={[
                { value: "notProvided", label: "+Add" },
                { value: "provided", label: "included" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, shipandtrack: value })
              }
            />

            <SelectSearchFilter
              title={"Claim"}
              placeholder="Select"
              value={SearchFillter?.claims}
              options={[
                { value: "", label: "+Add" },
                { value: "Cancel", label: "Pending" },
                { value: "Accept", label: "Accepted" },
                { value: "Reject", label: "Rejected" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, claims: value })
              }
            />

            <SelectSearchFilter
              title={"Sort"}
              placeholder="Select"
              value={SearchFillter?.sort}
              options={[
                { value: "desc", label: "Newest First" },
                { value: "asc", label: "Oldest First" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, sort: value })
              }
            />
            <button
              onClick={resetFilters}
              className="reset-filter-button search-filter "
            >
              Reset Filters
            </button>
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Pending Orders"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="process-orders-table">
                <table className="m-0">
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Order Date</th>
                      <th>Order Status</th>
                      <th>Brand</th>
                      <th>Qty</th>

                      <th>
                        Shipping &<br /> Tracking
                      </th>
                      <th>
                        Ship
                        <br /> Type
                      </th>
                      <th>Carrier</th>
                      <th>
                        Order
                        <br /> Total
                      </th>

                      <th>Profit</th>
                      <th>COGS</th>
                      <th>CC</th>
                      <th>Tax</th>
                      <th>
                        Cashback <br /> Used
                      </th>

                      <th>
                        Ship
                        <br /> Cost
                      </th>
                      <th>
                        DS <br /> Fees
                      </th>
                      <th>
                        Shipping
                        <br /> Party
                      </th>

                      <th>Margin</th>
                      <th>Fraud</th>
                      <th>Edit Costs</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllOrders?.length ? (
                      <>
                        {AllOrders?.map((order) => (
                          <tr key={order?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/view-order/${order?._id}`)
                              }
                            >
                              {order?.itemOrderNumber}
                            </td>
                            <td>{moment(order?.createdAt).format("L")}</td>
                            <td>
                              <span
                                onClick={() => {
                                  order?.backorderStatus?.status !== "cancel" &&
                                    handleStatusOpen(order);
                                }}
                              >
                                <OrderStatusButton
                                  width={250}
                                  status={order.status}
                                >
                                  {order.status}{" "}
                                  {order.status === "Backordered"
                                    ? `(${
                                        order?.backorderStatus?.status
                                          ? order?.backorderStatus?.status
                                          : "Pending"
                                      })`
                                    : null}
                                </OrderStatusButton>
                              </span>
                            </td>
                            <td>{order?.brandId?.fullName.split(" ")[0]}</td>
                            <td>{order?.quantity}</td>
                            <td>
                              <span
                                onClick={() => {
                                  order?.status !== "Backordered" &&
                                    handleShipping(order);
                                }}
                              >
                                <OrderStatusButton
                                  width={140}
                                  status={
                                    order?.shippingInfo?.labels.length &&
                                    order?.shippingInfo?.labels?.[0].default
                                      ? "Processing"
                                      : "In Transit"
                                  }
                                >
                                  {order?.shippingInfo?.labels.length &&
                                  order?.shippingInfo?.labels?.[0].default
                                    ? "Included"
                                    : "+Add"}
                                </OrderStatusButton>
                              </span>
                            </td>
                            <td>
                              <span
                                style={
                                  order?.orShipping?.type === "WG" ||
                                  order?.shipType === "WG"
                                    ? {}
                                    : { color: "#878787" }
                                }
                              >
                                {`${
                                  order?.shippingInfo?.shippingType
                                    ? order?.shippingInfo?.shippingType
                                    : order?.orShipping?.type
                                    ? order?.orShipping?.type
                                    : order?.shipType
                                }`}
                              </span>
                            </td>
                            <td>
                              {order?.shippingInfo?.labels[0]?.companyName ||
                                order?.orShipping?.companyName ||
                                order?.companyName ||
                                "N/A"}
                            </td>
                            <td>${Calculation(order, "OrderTotal")}</td>

                            <td>${Calculation(order, "profit")} </td>

                            <td
                              style={
                                order?.orCogs?.type === "promo"
                                  ? { color: "#17a858" }
                                  : order?.costType === "promo"
                                  ? { color: "#17a858" }
                                  : {}
                              }
                            >
                              ${Calculation(order, "COGS")}{" "}
                            </td>
                            <td>${Calculation(order, "CC")} </td>
                            <td>${Calculation(order, "Tax")} </td>
                            <td>${order?.cashbackUsedPerItem}</td>

                            <td>${Calculation(order, "shippingTotal")}</td>
                            <td>${Calculation(order, "dsFee")}</td>
                            <td>
                              {order.isSundialShipping
                                ? "Sundial"
                                : order?.brandId?.fullName.split(" ")[0]}
                            </td>
                            <td>{Calculation(order, "profitPercentage")}%</td>
                            <td>
                              {order?.orderID?.warnings?.length ? (
                                <button
                                  className={`${
                                    order.fraudVerify
                                      ? "fraud-mark-as-ok"
                                      : "fraud-active-btn"
                                  }`}
                                >
                                  {order.fraudVerify ? (
                                    "Ok"
                                  ) : (
                                    <RiErrorWarningFill
                                      style={{
                                        fontSize: "22px",
                                        color: "#fb2a3d",
                                      }}
                                    />
                                  )}
                                </button>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {/* Edit Button  */}
                              <div
                                onClick={() => {
                                  order?.backorderStatus?.status !== "cancel" &&
                                    handleCosts(order);
                                }}
                              >
                                {console.log(order)}
                                <OrderStatusButton
                                  width={130}
                                  status={
                                    order?.orCogs?.status &&
                                    order?.orDsFees?.status &&
                                    order?.orShipping?.status
                                      ? "Delivered"
                                      : "+add"
                                  }
                                >
                                  <span>
                                    {[
                                      order?.orCogs,
                                      order?.orShipping,
                                      order?.orDsFees,
                                    ].reduce(
                                      (acc, obj) =>
                                        obj.status ? acc + 1 : acc,
                                      0
                                    )}
                                    /3
                                  </span>
                                  <span>
                                    <GrEdit />
                                  </span>

                                  <span className="ml-2">Edit</span>
                                </OrderStatusButton>
                              </div>
                            </td>
                            <td>
                              <div onClick={() => handleNotes(order)}>
                                <OrderStatusButton
                                  width={90}
                                  status={
                                    order?.notes?.length ? "In Transit" : "+add"
                                  }
                                >
                                  <span>
                                    <ImAttachment
                                      style={{
                                        fontSize: "20px",
                                      }}
                                    />
                                  </span>
                                  <span className="ml-2">
                                    {order?.notes?.length}
                                  </span>
                                </OrderStatusButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={19} className=" text-center">
                            {" "}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <EditTabelDrawer
                  Edit={Edit}
                  setEdit={setEdit}
                  shippingId={SelectItem?.itemOrderNumber}
                >
                  {InnderLoader ? (
                    <div className=" d-flex align-center justify-content-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {/* Error Line code */}
                      {Edit.edit === "editCosts" && (
                        <>
                          <CostsUpdateComp
                            SelectItem={SelectItem}
                            IsMount={IsMount}
                            setIsMount={setIsMount}
                          />
                          <CostBreakDown SelectItem={SelectItem} />
                          <ShowNotes
                            title="Cost Notes"
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Costs"
                            )}
                          />
                        </>
                      )}
                      {Edit.edit === "orderStatus" && (
                        <>
                          <UpdateOrderComp
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                          <hr />
                          <ShowNotes
                            title="Order Status Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Order Status"
                            )}
                          />
                          <hr />
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                        </>
                      )}
                      {Edit.edit === "shipping" && (
                        <>
                          <ShippingUpdateComp
                            page="pending"
                            setIsMount={setIsMount}
                            IsMount={IsMount}
                            SelectItem={SelectItem}
                            ShippingComapny={ShippingComapny}
                            CurrentStatus={initialSearchFilter.OrderStatus}
                          />
                          <hr />
                          <ShowNotes
                            title="Shipping & Tracking Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes?.filter(
                              (message) =>
                                message.subject === "Shipping & Tracking"
                            )}
                          />
                          <hr />
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                        </>
                      )}
                      {Edit.edit === "Notes" && (
                        <>
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                          <ShowNotes
                            title="All Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes}
                          />
                        </>
                      )}

                      <OrderDetails
                        IsMount={IsMount}
                        setIsMount={setIsMount}
                        setEdit={setEdit}
                        SelectItem={SelectItem}
                      />
                    </>
                  )}
                </EditTabelDrawer>
              </div>
              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {AllOrders?.length} of {pagination?.count} Items
                </span>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default PendingOrders;
