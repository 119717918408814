import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";

export const getInspirationTag = (page) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/tags?page=${page}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteInspirationTag = (itemId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/delete-tag/${itemId}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
