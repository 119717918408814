import { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
// import numeral from "numeral";

import {
  // Avatar,
  Box,
  Card,
  Checkbox,
  // Slide,
  Divider,
  Tooltip,
  IconButton,
  Link,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  // Button,
  Typography,
  // Dialog,
  useMediaQuery,
  useTheme,
  //   Zoom,
  styled,
} from "@mui/material";

// import CloseIcon from "@mui/icons-material/Close";

// import { useTranslation } from "react-i18next";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
// import { useSnackbar } from "notistack";
// import Text from "src/components/Text";
// import LocalFireDepartmentTwoToneIcon from "@mui/icons-material/LocalFireDepartmentTwoTone";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
// import BulkActions from "./BulkActions";
// import Label from "./Label";

// const DialogWrapper = styled(Dialog)(
//   () => `
//       .MuiDialog-paper {
//         overflow: visible;
//       }
// `
// );

const ImgWrapper = styled("img")(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: auto;
`
);

// const AvatarError = styled(Avatar)(
//   ({ theme }) => `
//       background-color: ${theme.colors.error.lighter};
//       color: ${theme.colors.error.main};
//       width: ${theme.spacing(12)};
//       height: ${theme.spacing(12)};

//       .MuiSvgIcon-root {
//         font-size: ${theme.typography.pxToRem(45)};
//       }
// `
// );

// const ButtonError = styled(Button)(
//   ({ theme }) => `
//      background: ${theme.colors.error.main};
//      color: ${theme.palette.error.contrastText};

//      &:hover {
//         background: ${theme.colors.error.dark};
//      }
//     `
// );

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const applyFilters = (products, query) => {
  return products.filter((product) => {
    let matches = true;

    if (query) {
      const properties = ["name"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (products, page, limit) => {
  return products.slice(page * limit, page * limit + limit);
};

const TablesComp = () => {
  const [products, setProducts] = useState([
    {
      id: "1",
      name: "Amazon Dot Echo 3",
      price: 99,
      sale_price: 79,
      stock: 56,
      rating: 4.8,
      images: [
        "/static/images/placeholders/products/1.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 34,
      summary:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
      description:
        "\n    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>\n\n    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>\n    \n    <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>\n    ",
      categories: ["Electronics", "IOT Devices"],
    },
    {
      id: "2",
      name: "Autodesk 3D Printer PRO",
      price: 399,
      sale_price: 0,
      stock: 99,
      rating: 4.9,
      images: [
        "/static/images/placeholders/products/2.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 75,
      summary:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit",
      description:
        "\n    <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>\n\n<p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>\n\n<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>\n    ",
      categories: ["3D Printers"],
    },
    {
      id: "3",
      name: "Apple iPhone 12 PRO",
      price: 899,
      sale_price: 749,
      stock: 68,
      rating: 5,
      images: [
        "/static/images/placeholders/products/3.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 75,
      summary:
        "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis",
      description:
        "\n    <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p>\n\n<p>Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.</p>\n\n<p>Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>\n    ",
      categories: ["Phones", "Apple"],
    },
    {
      id: "4",
      name: "GoPro Action Camera 3",
      price: 299,
      sale_price: 289,
      stock: 35,
      rating: 4.5,
      images: [
        "/static/images/placeholders/products/4.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 19,
      summary:
        "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus",
      description:
        "\n    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>\n\n<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>\n\n<p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>\n    ",
      categories: ["Video Cameras"],
    },
    {
      id: "5",
      name: "Apple Watch 42mm Gen. 4",
      price: 199,
      sale_price: 0,
      stock: 99,
      rating: 4.9,
      images: [
        "/static/images/placeholders/products/5.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 98,
      summary:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum",
      description:
        "\n    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>\n\n<p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p>\n\n<p>Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>\n    ",
      categories: ["Apple", "Watches"],
    },
    {
      id: "6",
      name: "Sonos Pro WIFI Speaker",
      price: 1199,
      sale_price: 1099,
      stock: 83,
      rating: 4.6,
      images: [
        "/static/images/placeholders/products/6.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 185,
      summary:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
      description:
        "\n    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>\n\n<p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>\n\n<p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>\n    ",
      categories: ["Speakers"],
    },
    {
      id: "7",
      name: "Automatic Coffee Machine",
      price: 499,
      sale_price: 0,
      stock: 99,
      rating: 4.4,
      images: [
        "/static/images/placeholders/products/7.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 26,
      summary:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque",
      description:
        "\n    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>\n\n<p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>\n\n<p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>\n    ",
      categories: ["Electronics", "Coffee Machines"],
    },
    {
      id: "8",
      name: 'Apple Macbook PRO 16" 2021',
      price: 1999,
      sale_price: 0,
      stock: 38,
      rating: 4.9,
      images: [
        "/static/images/placeholders/products/8.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 9,
      summary:
        "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur",
      description:
        "\n    <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>\n\n<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>\n\n<p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p>\n    ",
      categories: ["Apple", "Laptops", "Computers"],
    },
    {
      id: "9",
      name: "Apple TV Gen. 5",
      price: 129,
      sale_price: 99,
      stock: 4,
      rating: 4.3,
      images: [
        "/static/images/placeholders/products/9.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 73,
      summary:
        "Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores",
      description:
        "\n    <p>Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>\n\n<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>\n\n<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>\n    ",
      categories: ["Apple", "TV", "WIFI"],
    },
    {
      id: "10",
      name: "Apple iPad PRO 2022",
      price: 999,
      sale_price: 849,
      stock: 2,
      rating: 4.1,
      images: [
        "/static/images/placeholders/products/10.png",
        "/static/images/placeholders/fitness/1.jpg",
        "/static/images/placeholders/fitness/2.jpg",
        "/static/images/placeholders/fitness/3.jpg",
        "/static/images/placeholders/fitness/4.jpg",
      ],
      orders: 94,
      summary:
        "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit",
      description:
        "\n    <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>\n\n<p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>\n\n<p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>\n    ",
      categories: ["Apple", "Tablets", "Electronics"],
    },
  ]);

  console.log(products);
  const [selectedItems, setSelectedProducts] = useState([]);
  //   const { t } = useTranslation();
  //   const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const location = useLocation();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllProducts = (event) => {
    setSelectedProducts(
      event.target.checked ? products.map((product) => product.id) : []
    );
  };

  const handleSelectOneProduct = (event, productId) => {
    if (!selectedItems.includes(productId)) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((id) => id !== productId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredProducts = applyFilters(products, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeProducts =
    selectedItems.length > 0 && selectedItems.length < products.length;
  const selectedAllProducts = selectedItems.length === products.length;
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    // enqueueSnackbar(t("You successfully deleted the product"), {
    //   variant: "success",
    //   anchorOrigin: {
    //     vertical: "top",
    //     horizontal: "right",
    //   },
    //   TransitionComponent: Zoom,
    // });
  };

  return (
    <>
      <Card>
        <Box display="flex" alignItems="center">
          {selectedBulkActions && (
            <Box flex={1} p={2}>
              {/* <BulkActions /> */}
            </Box>
          )}
          {!selectedBulkActions && (
            <Box
              flex={1}
              p={2}
              display={{ xs: "block", md: "flex" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  mb: { xs: 2, md: 0 },
                }}
              >
                <TextField
                  size="small"
                  fullWidth={mobile}
                  onChange={handleQueryChange}
                  value={query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={"Search by product name..."}
                />
              </Box>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          )}
        </Box>
        <Divider />

        {paginatedProducts.length === 0 ? (
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {"We couldn't find any products matching your search criteria"}
          </Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllProducts}
                        indeterminate={selectedSomeProducts}
                        onChange={handleSelectAllProducts}
                      />
                    </TableCell>
                    <TableCell>{"Product name"}</TableCell>
                    <TableCell>{"Price"}</TableCell>
                    <TableCell align="center">{"Stock"}</TableCell>
                    <TableCell align="center">{"Rating"}</TableCell>
                    <TableCell align="center">{"Orders"}</TableCell>
                    <TableCell>{"Categories"}</TableCell>
                    <TableCell align="center">{"Actions"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProducts.map((product) => {
                    const isProductSelected = selectedItems.includes(
                      product.id
                    );
                    return (
                      <TableRow
                        hover
                        key={product.id}
                        selected={isProductSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isProductSelected}
                            onChange={(event) =>
                              handleSelectOneProduct(event, product.id)
                            }
                            value={isProductSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <ImgWrapper src={product.images[0]} />
                            <Box
                              pl={1}
                              sx={{
                                width: 250,
                              }}
                            >
                              <Link
                                component={RouterLink}
                                to={`/${
                                  location.pathname.split("/")[1]
                                }/management/commerce/products/single/${
                                  product.id
                                }`}
                                variant="h5"
                              >
                                {product.name}
                              </Link>
                              <Typography variant="subtitle2" noWrap>
                                {product.summary}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              textDecorationLine:
                                product.sale_price !== 0 ? "line-through" : "",
                            }}
                          >
                            {/* ${numeral(product.price).format(`0,0.00`)} */}
                          </Typography>
                          {product.sale_price !== 0 && (
                            <Typography>
                              {/* <Text color="error">
                                ${numeral(product.sale_price).format(`0,0.00`)}
                              </Text> */}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Label color="success">
                            <b>{product.stock}</b>
                          </Label> */}
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" alignItems="center">
                            {/* <Text color="warning">
                              <LocalFireDepartmentTwoToneIcon fontSize="small" />
                            </Text> */}
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 0.5,
                              }}
                            >
                              {product.rating}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">{product.orders}</TableCell>
                        <TableCell>
                          {product.categories.map((value) => {
                            return (
                              <span key={value}>
                                <Link href="#">{value}</Link>,{" "}
                              </span>
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={"View"} arrow>
                              <IconButton
                                component={RouterLink}
                                to={`/${
                                  location.pathname.split("/")[1]
                                }/management/commerce/products/single/${
                                  product.id
                                }`}
                                color="primary"
                              >
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete"} arrow>
                              <IconButton
                                onClick={handleConfirmDelete}
                                color="primary"
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
      {/* <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6,
            }}
            variant="h3"
          >
            {"Do you really want to delete this product"}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6,
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {"You won't be able to revert after deletion"}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1,
              }}
              onClick={closeConfirmDelete}
            >
              {"Cancel"}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3,
              }}
              variant="contained"
            >
              {"Delete"}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper> */}
    </>
  );
};

TablesComp.propTypes = {
  products: PropTypes.array.isRequired,
};

TablesComp.defaultProps = {
  products: [],
};

export default TablesComp;
