import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { deleteChildCategory } from "../../../actions/childCategory";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { changeStatus } from "../../../actions/subCategory";
import { childSubCategories } from "../../../actions/childSubCategory";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";

const ChildSubCategories = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  // Api calls for get Order
  const getCategories = useCallback(
    async (pageNo, search) => {
      const res = await dispatch(childSubCategories(pageNo, search));
      console.log(res);
      setData(res?.subCategories);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );
  console.log(SearchFillter.SearchQuery);

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getCategories(pagination.currentPage, SearchFillter.SearchQuery);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    isMount,
    getCategories,
  ]);
  console.log(isMount);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/child-subcategories",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const deleteCategory = async (id) => {
    setloading(true);
    const res = await dispatch(deleteChildCategory(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };

  // categories Status chnage
  const handleStatus = async (id, status) => {
    try {
      const res = await changeStatus(id, status);
      if (res) {
        setisMount(!isMount);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex  justify-content-end align-center">
            <SearchFilter
              type="text"
              placeholder={"Search Child Sub Category"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Child Sub categories"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Sn#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Image</th>
                      <th>Parent</th>
                      <th>Created At</th>
                      <th> Count </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/view-order/${item?._id}`)
                              }
                            >
                              {i + 1}
                            </td>
                            <td>{item.name}</td>
                            <td>
                              {item.status ? (
                                <span className="text-success">Enable</span>
                              ) : (
                                <span className="text-danger">Disable</span>
                              )}
                            </td>

                            <td>
                              <img
                                src={item.image}
                                height="50px"
                                width="50px"
                                alt="item"
                              />
                            </td>
                            <td>
                              {item.subcategory &&
                                item.subcategory.length > 0 &&
                                item.subcategory[0].category.length > 0 &&
                                item.subcategory[0].category[0].maincategory
                                  .length > 0 &&
                                item.subcategory[0].category[0].maincategory[0]
                                  .name + " ->"}
                              <br />
                              {item.subcategory &&
                                item.subcategory.length > 0 &&
                                item.subcategory[0].category.length > 0 &&
                                item.subcategory[0].category[0].name + " ->"}
                              <br />
                              {item.subcategory &&
                                item.subcategory.length > 0 &&
                                item.subcategory[0].name}
                            </td>

                            <td>{moment(item?.createdAt).format("l")}</td>
                            <td>{item.count}</td>
                            <td>
                              <CustomeTableBtn
                                color="secondary"
                                width={"100px"}
                                className={"mr-2"}
                                onClick={() =>
                                  navigate(
                                    `/admin/edit-child-subcategory/?id=${item._id}`
                                  )
                                }
                              >
                                Edit
                              </CustomeTableBtn>

                              <CustomeTableBtn
                                color="danger"
                                width={"100px"}
                                onClick={() =>
                                  setdeleteConfirm({
                                    open: true,
                                    id: item?._id,
                                  })
                                }
                              >
                                Delete
                              </CustomeTableBtn>
                              {item?.status ? (
                                <AiOutlineEye
                                  onClick={() => handleStatus(item._id, false)}
                                  className=" ml-2 h2"
                                />
                              ) : (
                                <AiOutlineEyeInvisible
                                  onClick={() => handleStatus(item._id, true)}
                                  className=" ml-2 h2"
                                />
                              )}

                              {deleteConfirm.open && (
                                <SweetAlert
                                  warning
                                  showCancel
                                  confirmBtnText="Yes, delete it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={(e) => {
                                    deleteCategory(deleteConfirm.id);
                                  }}
                                  onCancel={() =>
                                    setdeleteConfirm({
                                      open: false,
                                      id: null,
                                    })
                                  }
                                  focusCancelBtn
                                >
                                  Are you sure you want to delete?
                                </SweetAlert>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {Data?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ChildSubCategories;
