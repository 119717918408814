import moment from "moment";
export const showDate = (date) => {
  return moment(date).format("LL");
};

export const toISOStringFromat = (date) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 10);
  }
  return "mm/dd/yy";
};

export const MMDDYYFormat = (date) => {
  if (date) {
    const formattedDate = new Date(date);
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(formattedDate.getDate()).padStart(2, "0");
    const year = String(formattedDate.getFullYear()).slice(-2); // Get last two digits of the year

    return `${month}/${day}/${year}`;
  }

  return "mm/dd/yy";
};
export const toISOStringFromat1 = (date) => {
  if (date) {
    const localDate = new Date(date);
    localDate.setMinutes(
      localDate.getMinutes() - localDate.getTimezoneOffset()
    );
    return (
      ("0" + (localDate.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + localDate.getDate()).slice(-2) +
      "/" +
      localDate.getFullYear().toString().slice(-2)
    );
  }
  return "mm/dd/yy";
};
// export const toISOStringFromat1 = (date) => {
//   if (date) {
//     const localDate = new Date(date);
//     const gmt8Offset = 8 * 60;

//     // Adjust the date for GMT-8
//     const offsetDifference = localDate.getTimezoneOffset() + gmt8Offset;
//     localDate.setMinutes(localDate.getMinutes() - offsetDifference);

//     return (
//       ("0" + (localDate.getMonth() + 1)).slice(-2) +
//       "/" +
//       ("0" + localDate.getDate()).slice(-2) +
//       "/" +
//       localDate.getFullYear().toString().slice(-2)
//     );
//   }
//   return "mm/dd/yy";
// };
