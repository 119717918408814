import React, { useEffect, useState } from "react";
import propsTypes from "prop-types"
import { useParams } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../../actions/showSnackBar";



const SuggestionKeywordCard = (props) => {
  const { keyword, setsearchKeyword, searchKeyword } = props
  const dispatch = useDispatch()

  const { categoryName } = useParams()


  const [copyText, setcopyText] = useState(null);
  const [copied, setcopied] = useState(false);

  useEffect(() => {
  }, [keyword, copyText])

  return (
    <div className="card cardRaduis my-4">
      <div className="card-header card-header-container ">
        <div>
          <p className="h4"> Suggestion for Keyword:</p>

          <p className="h6 my-2"> {categoryName}</p>
        </div>
        <input
          type="text"
          placeholder="Search keyword"
          className="keywordSearch"
          value={searchKeyword}
          onChange={(e) => setsearchKeyword(e.target.value)}
        />
      </div>
      {keyword.length <= 0 && <><p>No Keyword find</p></>}
      {keyword.length > 0 &&
        (
        <div className="card-body keywordBodyConatiner">
          {keyword.map((tag) => (
            <div key={tag._id} className="tagContainer">
            <div className="tagTitleContainer">
                <CopyToClipboard
                  className="copyIcon"
                  text={tag.keyword}
                  onCopy={() => {
                    setcopyText(tag.keyword);
                    dispatch(showSnackBar("copied", "success", true))
                    setcopied(true);
                    setTimeout(() => {
                      setcopied(false);
                    }, 1000);
                  }}
                >
                  <p>{tag.keyword}</p>
                </CopyToClipboard>


            </div>

            <div className="line"></div>

              <p className="amsCount"> ams:{tag.ams}</p>
          </div>
        ))}
      </div>
        )
      }
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copied}
        message={`"${copyText}"    copied to clipboard`}
      />

    </div>
  );
};

SuggestionKeywordCard.propsTypes = {
  keyword: propsTypes.array.isRequired,
  setsearchKeyword: propsTypes.func.isRequired,
  searchKeyword: propsTypes.string.isRequired
}

export default SuggestionKeywordCard;
