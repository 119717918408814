import { Box, Modal, TextField } from "@mui/material";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const AddLink = ({ name, setaddLinks }) => {
  const [Open, setOpen] = useState(false);
  const [value, setvalue] = useState({
    title: "",
    link: "",
  });
  const [error, seterror] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (value.link !== "" || value.title !== "") {
      const items = JSON.parse(localStorage.getItem("addLink")) || [];
      items.push(value);
      localStorage.setItem("addLink", JSON.stringify(items));
      setaddLinks((old) => [...old, value]);
      setOpen(false);
    } else {
      seterror(true);
      setTimeout(() => {
        seterror(false);
      }, 2000);
    }
  };
  return (
    <>
      <div className="link-card c-pointer" onClick={() => setOpen(!Open)}>
        <div>
          <h4 className=" font-weight-bold">{name}</h4>
        </div>
      </div>
      <Modal
        open={Open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {error && <p className=" text-danger">please fill all feild</p>}
          <TextField
            className=" w-100"
            required
            id="outlined-required"
            label="Title"
            placeholder="Please Enter Title"
            value={value.title}
            onChange={(e) => setvalue({ ...value, title: e.target.value })}
          />
          <TextField
            className=" w-100 my-4"
            required
            id="outlined-required"
            label="Link"
            placeholder="Enter Link"
            value={value.link}
            onChange={(e) => setvalue({ ...value, link: e.target.value })}
          />
          <div className="model-btn-container">
            <button
              type="button"
              className="btn btn-danger w-100 "
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-success w-100"
            >
              Add
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddLink;
