/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { retreive, edit } from "../../../actions/product";
import { usersRetreive } from "../../../actions/user";
import { subCategories } from "../../../actions/subCategory";
import { brands } from "../../../actions/brand";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      image: "",
      title: "",
      brand: "",
      modelNumber: "",
      price: 0,
      weightAndDimension: [{ name: "", value: "" }],
      specification: [{ name: "", value: "" }],
      tag: [],
      attribute: [{ color: "", size: "", price: 0, image: "" }],
      images: [],
      model: "",
      sku: "",
      quantity: 0,
      outOfStock: 0,
      requireShipping: 0,
      status: 0,
      manufacturer: "",
      category: "",
      vendor: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      brands: [],
      subCategories: [],
      usersRetreive: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.retreive(id);

    const mainCat = await this.props.subCategories();
    this.setState({ subCategories: mainCat });

    const ven = await this.props.usersRetreive();
    this.setState({ usersRetreive: ven.data });

    const brnd = await this.props.brands();
    console.log(brnd);
    this.setState({ brands: brnd });

    const metaTagDataConvert = [];

    res.metaTagTitle &&
      res.metaTagTitle.forEach((element) => {
        metaTagDataConvert.push({ value: element, label: element });
      });

    const metaKeywordDataConvert = [];

    res.metaTagKeyword &&
      res.metaTagKeyword.forEach((_element) => {
        metaKeywordDataConvert.push({ value: _element, label: _element });
      });

    const seoKeywordDataConvert = [];

    res.seoKeyword &&
      res.seoKeyword.forEach((element) => {
        seoKeywordDataConvert.push({ value: element, label: element });
      });
    const tagData = [];

    res.tag &&
      res.tag.tag.forEach((element) => {
        tagData.push({ value: element, label: element });
      });

    this.setState({
      loading: false,
      metaTag: metaTagDataConvert,
      title: res.title,
      brand: res.brand,
      modelNumber: res.modelNumber,
      price: res.price,
      weightAndDimension: this.json2array(
        res.weightAndDimension.weightAndDimension
      ),
      specification: this.json2array(res.specification.specification),
      tag: tagData,
      attribute: this.json2array(res.attribute.attribute),
      images: res.images,
      model: res.model,
      sku: res.sku,
      quantity: res.quantity,
      outOfStock: res.outOfStock,
      requireShipping: res.requireShipping,
      status: res.status,
      manufacturer: res.manufacturer,
      category: res.vendor,
      vendor: res.vendor,
      description: res.description,
      metaDescription: res.metaTagDescription,
      metaKeyword: metaKeywordDataConvert,
      seoKeyword: seoKeywordDataConvert,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  json2array(json) {
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function (key) {
      result.push(JSON.parse(json[key]));
    });
    return result;
  }
  _submitHandler = async () => {
    const {
      title,
      brand,
      modelNumber,
      description,
      price,
      weightAndDimension,
      specification,
      tag,
      attribute,
      images,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      status,
      manufacturer,
      category,
      vendor,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
    } = this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);
    const metaTagData = [];

    metaTag.forEach((element) => {
      metaTagData.push(element.value);
    });
    const productTag = [];

    tag.forEach((element) => {
      productTag.push(element.value);
    });

    const metaKeywordData = [];

    metaKeyword.forEach((_element) => {
      metaKeywordData.push(_element.value);
    });

    const seoKeywordData = [];

    seoKeyword.forEach((element) => {
      seoKeywordData.push(element.value);
    });

    let formData = new FormData();
    formData.append("productId", id);
    for (var i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    formData.append("title", title);
    formData.append("brand", brand);
    // formData.append("attribute", attribute);
    for (let i = 0; i < attribute.length; i++) {
      formData.append("attribute", JSON.stringify(attribute[i]));
    }
    formData.append("modelNumber", modelNumber);
    formData.append("model", model);
    formData.append("price", price);
    // formData.append("specification", specification);
    for (let i = 0; i < specification.length; i++) {
      formData.append("specification", JSON.stringify(specification[i]));
    }
    formData.append("tag", productTag);
    // formData.append("weightAndDimension", weightAndDimension);
    for (let i = 0; i < weightAndDimension.length; i++) {
      formData.append(
        "weightAndDimension",
        JSON.stringify(weightAndDimension[i])
      );
    }
    formData.append("sku", sku);
    formData.append("description", description);
    formData.append("quantity", quantity);
    formData.append("outOfStock", outOfStock);
    formData.append("requireShipping", requireShipping);
    formData.append("status", status);
    formData.append("manufacturer", manufacturer);
    formData.append("category", category);
    formData.append("vendor", vendor);
    formData.append("metaTagTitle", metaTagData);
    formData.append("metaTagDescription", metaDescription);
    formData.append("metaTagKeyword", metaKeywordData);
    formData.append("seoKeyword", seoKeywordData);

    const res = await this.props.edit(formData);

    this.setState({ loading: false });

    if (res) {
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let file = Array.from(e.target.files);

    file.forEach((element, index) => {
      let reader = new FileReader();
      let f = e.target.files[index];

      reader.onloadend = () => {
        this.setState({ images: [...this.state.images, f] });
      };

      reader.readAsDataURL(f);
    });
  }

  // weight and dimensions
  handleWeightAndDimensionNameChange = (idx) => (evt) => {
    const newWeightAndDimension = this.state.weightAndDimension.map(
      (weightAndDimension, sidx) => {
        if (idx !== sidx) return weightAndDimension;
        return { ...weightAndDimension, name: evt.target.value };
      }
    );

    this.setState({ weightAndDimension: newWeightAndDimension });
  };
  handleWeightAndDimensionValueChange = (idx) => (evt) => {
    const newWeightAndDimension = this.state.weightAndDimension.map(
      (weightAndDimension, sidx) => {
        if (idx !== sidx) return weightAndDimension;
        return { ...weightAndDimension, value: evt.target.value };
      }
    );

    this.setState({ weightAndDimension: newWeightAndDimension });
  };

  handleAddWeightAndDimension = () => {
    this.setState({
      weightAndDimension: this.state.weightAndDimension.concat([
        { name: "", value: "" },
      ]),
    });
  };

  handleRemoveWeightAndDimension = (idx) => () => {
    this.setState({
      weightAndDimension: this.state.weightAndDimension.filter(
        (s, sidx) => idx !== sidx
      ),
    });
  };

  //specifications
  handleSpecificationNameChange = (idx) => (evt) => {
    const newSpecification = this.state.specification.map(
      (specification, sidx) => {
        if (idx !== sidx) return specification;
        return { ...specification, name: evt.target.value };
      }
    );

    this.setState({ specification: newSpecification });
  };
  handleSpecificationValueChange = (idx) => (evt) => {
    const newSpecification = this.state.specification.map(
      (specification, sidx) => {
        if (idx !== sidx) return specification;
        return { ...specification, value: evt.target.value };
      }
    );

    this.setState({ specification: newSpecification });
  };

  handleAddSpecification = () => {
    this.setState({
      specification: this.state.specification.concat([{ name: "", value: "" }]),
    });
  };

  handleRemoveSpecification = (idx) => () => {
    this.setState({
      specification: this.state.specification.filter((s, sidx) => idx !== sidx),
    });
  };

  //attribues
  handleAttributeColorChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, color: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };

  handleAttributeSizeChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, size: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };
  handleAttributePriceChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, price: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };
  handleAttributeImageChange = (idx) => {
    setTimeout(() => {
      const newAttribute = this.state.attribute.map((attribute, sidx) => {
        if (idx !== sidx) return attribute;
        var len = this.state.images.length - 1;
        return { ...attribute, image: len };
      });
      this.setState({ attribute: newAttribute });
    }, 1000);
  };
  handleAddAttribute = () => {
    this.setState({
      attribute: this.state.attribute.concat([
        { color: "", size: "", price: "", image: "" },
      ]),
    });
  };

  handleRemoveAttribute = (idx) => () => {
    this.setState({
      attribute: this.state.attribute.filter((s, sidx) => idx !== sidx),
    });
  };
  _metaTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaTag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaTag: data,
            })
          : this.setState({
              metaTag: null,
            });
      }}
    />
  );

  _metaKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: null,
            });
      }}
    />
  );

  _seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: null,
            });
      }}
    />
  );
  _productTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.tag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              tag: data,
            })
          : this.setState({
              tag: null,
            });
      }}
    />
  );
  render() {
    const {
      title,
      brand,
      modelNumber,
      price,
      weightAndDimension,
      specification,
      tag,
      attribute,
      images,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      dimensionAndWeight,
      status,
      manufacturer,
      category,
      vendor,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
      description,
    } = this.state;
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Edit Product </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Product
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit: {title}</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Images</label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        multiple
                        className="form-control"
                        accept="image/x-png,image/jpeg,image/jpg|video/*"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={4}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="brand">Select Brand</label>
                    <select
                      className="form-control form-control-sm"
                      id="brand"
                      value={brand}
                      onChange={(e) => {
                        this.setState({ brand: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.brands &&
                        this.state.brands.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="modelNumber">Model Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="modelNumber"
                      placeholder="Model Number"
                      name="modelNumber"
                      value={modelNumber}
                      onChange={(e) =>
                        this.setState({ modelNumber: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input
                      type="number"
                      step="0.10"
                      min="0"
                      className="form-control"
                      id="price"
                      placeholder="Price"
                      name="price"
                      value={price}
                      onChange={(e) => this.setState({ price: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Specifications</label>
                    {this.state.specification.map((specification, idx) => (
                      <div className="row" style={{ marginBottom: 5 }}>
                        <div className="col-11">
                          <div className="row" style={{ marginLeft: 0 }}>
                            <input
                              type="text"
                              placeholder="Name"
                              value={specification.name}
                              className="form-control col-5"
                              style={{ marginRight: 10 }}
                              onChange={this.handleSpecificationNameChange(idx)}
                            />
                            <input
                              type="text"
                              placeholder="Value"
                              value={specification.value}
                              className="form-control col-5"
                              onChange={this.handleSpecificationValueChange(
                                idx
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <button
                            type="button"
                            onClick={this.handleRemoveSpecification(idx)}
                            className="btn btn-danger"
                          >
                            x
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={this.handleAddSpecification}
                      className="small btn btn-primary"
                    >
                      Add New Specification
                    </button>
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Weight & Dimension</label>
                    {this.state.weightAndDimension.map(
                      (weightAndDimension, idx) => (
                        <div className="row" style={{ marginBottom: 5 }}>
                          <div className="col-11">
                            <div className="row" style={{ marginLeft: 0 }}>
                              <input
                                type="text"
                                placeholder="Name"
                                value={weightAndDimension.name}
                                className="form-control col-5"
                                style={{ marginRight: 10 }}
                                onChange={this.handleWeightAndDimensionNameChange(
                                  idx
                                )}
                              />
                              <input
                                type="text"
                                placeholder="Value"
                                value={weightAndDimension.value}
                                className="form-control col-5"
                                onChange={this.handleWeightAndDimensionValueChange(
                                  idx
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-1">
                            <button
                              type="button"
                              onClick={this.handleRemoveWeightAndDimension(idx)}
                              className="btn btn-danger"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    <button
                      type="button"
                      onClick={this.handleAddWeightAndDimension}
                      className="small btn btn-primary"
                    >
                      Add Weight And Dimension
                    </button>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Product Tags</label>

                    {!this.state.loading && this._productTagJsx()}
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Attribute</label>
                    {this.state.attribute.map((attribute, idx) => (
                      <div className="row" style={{ marginBottom: 5 }}>
                        <div className="col-11">
                          <div className="row" style={{ marginLeft: 0 }}>
                            <input
                              type="text"
                              placeholder="Color"
                              value={attribute.color}
                              className="form-control col-2"
                              style={{ marginRight: 10 }}
                              onChange={this.handleAttributeColorChange(idx)}
                            />
                            <input
                              type="text"
                              placeholder="Size"
                              value={attribute.size}
                              className="form-control col-2"
                              style={{ marginRight: 10 }}
                              onChange={this.handleAttributeSizeChange(idx)}
                            />
                            <input
                              type="number"
                              placeholder="Price"
                              step="0.1"
                              min="0"
                              value={attribute.price}
                              className="form-control col-2"
                              style={{ marginRight: 10 }}
                              onChange={this.handleAttributePriceChange(idx)}
                            />
                            <input
                              type="file"
                              placeholder="Image"
                              className="form-control col-2"
                              style={{ marginRight: 10 }}
                              accept="image/x-png,image/jpeg,image/jpg|video/*"
                              onChange={(e) => {
                                this._handleImageChange(e);
                                this.handleAttributeImageChange(idx);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <button
                            type="button"
                            onClick={this.handleRemoveAttribute(idx)}
                            className="btn btn-danger"
                          >
                            x
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={this.handleAddAttribute}
                      className="small btn btn-primary"
                    >
                      Add Attribute
                    </button>
                  </div>
                  <div className="form-group">
                    <label htmlFor="model">Model </label>
                    <input
                      type="text"
                      className="form-control"
                      id="model"
                      placeholder="Model"
                      name="model"
                      value={model}
                      onChange={(e) => this.setState({ model: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sku">SKU </label>
                    <input
                      type="text"
                      className="form-control"
                      id="sku"
                      placeholder="SKU"
                      name="sku"
                      value={sku}
                      onChange={(e) => this.setState({ sku: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="quantity">Quantity </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      id="quantity"
                      placeholder="Quantity"
                      name="quantity"
                      value={quantity}
                      onChange={(e) =>
                        this.setState({ quantity: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label htmlFor="outOfStock">Out Of Stock</label>

                    <select
                      name="outOfStock"
                      value={outOfStock}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ outOfStock: e.target.value });
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="requireShipping">Required Shipping</label>
                    <select
                      name="requireShipping"
                      value={requireShipping}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          requireShipping: e.target.value,
                        });
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>

                    <select
                      name="status"
                      value={status}
                      onChange={(e) => {
                        this.setState({ status: e.target.value });
                      }}
                      className="form-control"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="brand">Select Menufacturer</label>
                    <select
                      className="form-control form-control-sm"
                      id="brand"
                      value={manufacturer}
                      onChange={(e) => {
                        this.setState({ manufacturer: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.usersRetreive &&
                        this.state.usersRetreive.map((item, index) =>
                          item.isVendor ? (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="brand">Select Vendor</label>
                    <select
                      className="form-control form-control-sm"
                      id="vendor"
                      value={vendor}
                      onChange={(e) => {
                        this.setState({ vendor: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.usersRetreive &&
                        this.state.usersRetreive.map((item, index) =>
                          item.isVendor ? (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="brand">Select Category</label>
                    <select
                      className="form-control form-control-sm"
                      id="category"
                      value={category}
                      onChange={(e) => {
                        this.setState({ category: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.subCategories &&
                        this.state.subCategories.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Tags">Meta Tags</label>

                    {!this.state.loading && this._metaTagJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Meta Keywords</label>

                    {!this.state.loading && this._metaKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="meta description">Meta Description</label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      value={metaDescription}
                      rows={4}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seo keywords">Seo Keywords</label>

                    {!this.state.loading && this._seoKeywordJsx()}
                  </div>

                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                  <Link to={"/admin/products"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};
brands.propTypes = {
  brands: PropTypes.func.isRequired,
};

subCategories.propTypes = {
  subCategories: PropTypes.func.isRequired,
};
usersRetreive.propTypes = {
  usersRetreive: PropTypes.func.isRequired,
};
export default withRouter(connect(null, {
  retreive,
  edit,
  brands,
  subCategories,
  usersRetreive,
})(Edit));
