import React from "react";
import styles from "./exploreBrandSection.module.css";

function ProductCard({ product }, handleShow) {
  return (
    <div
      className={`align-items-center w-100 h-100 d-flex flex-wraped justify-content-center ${styles.exploreCardsParent}`}
    >
      <div className={`f1 ${styles.ml8}`}>
        <div className={`item d-md-block ${styles.testerHer} ${styles.pCard}`}>
          <p className="d-flex flex-column border explore-card-height bg-white border-radius-8 m-0">
            <div className="card-slide-img pt-0 explore-card-height">
              <div className="p-2">
                <img src={product.mainImage} width="100%" alt="test" />
              </div>
            </div>
            <div
              className="p-3 card-slide-content"
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              <p>{product.title}</p>
              <div className="slide-view-link">
                <div
                  className={`d-flex justify-content-between align-items-end ${styles.brandCardViewLink}`}
                >
                  View Product
                </div>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
