import React from "react";
import style from "./price-card.module.css";
import {
  changeDataFormat,
  setButtonBackgroundColor,
} from "../../pages/admin/product/DynamicPricing/utils";
import {
  CrawlType,
  InfoItemComponent,
} from "./price-card-components/info-item-component";
import CustomeTableBtn from "../custome-table-button/CustomeTableBtn";
const PriceCard = ({ productInfo }) => {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.cardTitle}>
          <h3 className={style.primaryHeading}>Product Matching Status</h3>
          <CustomeTableBtn
            color={`${setButtonBackgroundColor(
              productInfo?.crawlReport?.productMatchingStatus
            )}`}
            width={"200"}
            className="font-size-sm"
          >
            {productInfo?.crawlReport?.productMatchingStatus}
          </CustomeTableBtn>
        </div>

        <div className={style.productContainer}>
          <img
            src={productInfo?.productId?.mainImage}
            alt="user-img"
            className={style.productImage}
          />
          <span className={style.productDescription}>
            Coulter 42" dining table
          </span>
        </div>

        <div className={style.infoContainer}>
          <InfoItemComponent
            label="Manufacturer"
            value={`${productInfo?.productId?.manufacturer}`}
          />
          <InfoItemComponent
            label="SKU"
            value={`${productInfo?.productId?.sku}`}
          />
          <InfoItemComponent
            label="Model"
            value={`${productInfo?.productId?.modelNumber}`}
          />
          <InfoItemComponent
            label="Inventory"
            value={productInfo?.productId?.quantity}
          />
        </div>

        <CrawlType
          crawlType="Crawl Type"
          CrawlDescription={productInfo?.crawlReport?.crawlerType}
        />

        <CrawlType
          crawlType="Crawl Stage"
          CrawlDescription={productInfo?.crawlReport?.crawlStage}
        />

        <CrawlType
          crawlType="Error Codes"
          CrawlDescription={productInfo.errorCodes}
        />
        <CrawlType
          crawlType="Status Date:"
          CrawlDescription={changeDataFormat(
            `${productInfo?.crawlReport?.crawlerDateTime}`
          )}
        />

        <CrawlType
          crawlType="Link Found: "
          CrawlDescription={`${productInfo?.crawlReport?.linkFound}`}
        />

        <CrawlType
          crawlType="Sundial Exists: "
          CrawlDescription={`${productInfo?.crawlReport?.sundialExists}`}
        />

        <CrawlType crawlType="Crawl Page Link:" CrawlDescription="" />

        {/* <div className={style.crawlBox}>{productInfo?.validUrl}</div> */}

        <div className={style.crawlBox}>
          <a className={style.crawlBoxLink}
            href={productInfo?.crawlReport?.validUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {productInfo?.crawlReport?.validUrl}
          </a>
        </div>
        <p className={style.linkTitle}>Enter Valid Link</p>
        <input
          placeholder="Enter Url For Link"
          className={style.urlInputField}
        />

        <button className={style.linkButton}>Update link & submit crawl</button>

        <div class={style.checkboxContainer}>
          <input
            type="checkbox"
            id="labourCheckbox"
            name="labourCheckbox"
            className={style.inputCheckBox}
          />
          <label for="labourCheckbox">
            <span class={style.checkboxIcon}></span>
            <p className={style.checkBoxLabel}> Mark As Error</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
