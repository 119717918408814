import React from "react";
import "./styles.css";

const getStatusColor = (ColorName) => {
  switch (ColorName) {
    case "error":
      return {
        background: "#e8f7fd",
        color: "#19aeee",
        borderColor: "#19aeee",
      };
    case "info":
      return {
        background: "#e8f7fd",
        color: "#19aeee",
        borderColor: "#19aeee",
      };
    case "success":
      return {
        background: "#e7f6ee",
        color: "#17a858",
        borderColor: "#17a858",
      };
    case "danger":
      return {
        background: "#ffe9eb",
        color: "#fb2a3d",
        borderColor: "#fb2a3d",
      };
    case "purpal":
      return {
        background: "#efe9ec",
        color: "#652a47",
        borderColor: "#652a47",
      };
    case "darkGreen":
      return {
        background: "#ccdedc",
        color: "#005a52",
        borderColor: "#005a52",
      };
    case "secondary":
      return {
        background: "#ffffff",
        color: "#7d7d7d",
        borderColor: "#7d7d7d",
      };
    case "warning":
      return {
        background: "#fff8e6",
        color: "#ffb706",
        borderColor: "#ffb706",
      };
    case "dark-purpal":
      return {
        background: "#e7dbfc",
        color: "#6224d6",
        borderColor: "#6224d6",
      };
    case "normal-green":
      return {
        background: "#17A858",
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
      };
    case "dark-gray":
      return {
        background: "#222020",
        color: "#FFFFFF",
        borderColor: "#222020",
      };
    // Review Buttons Color
    case "not-sent":
      return {
        background: "#19AEEE",
        color: "#FFFFFF",
        borderColor: "#19AEEE",
      };
    case "review-pending":
      return {
        background: "#CDCDCD",
        color: "#8C8C8C",
        borderColor: "#8C8C8C",
      };
    case "review-recieved":
      return {
        background: "#E7F6EE",
        color: "#17A858",
        borderColor: "#17A858",
      };
    default:
      return {};
  }
};

// status props some like bootstrape color
const CustomeTableBtn = ({
  color,
  children,
  onClick,
  width,
  className,
  styles,
  disabled
}) => {
  return (
    <button
      className={`${className ?? ""}`}
      onClick={() => onClick()}
      style={{ ...getStatusColor(color), width: `${width}px`, ...styles }}
      id="custome-table-btn"
      disabled={disabled ?? false}
    >
      {children}
    </button>
  );
};

export default CustomeTableBtn;
