import moment from "moment";
import {
  MMDDYYFormat,
  toISOStringFromat1,
} from "../../../../utils/dateFormate";
import { getTotalCubicUnits } from "../../../../utils/Formula";
import SwitchShippingParty from "./SwitchShippingParty";

const TrackingDetailsCard = ({ SelectItem, ShippingComapny }) => {
  return (
    <div
      style={{
        border: "2px solid #7d7d7d",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <h6>Shipping & Tracking Summmary:</h6>
      <div className="mt-3">
        <h6>Ship Type:</h6>
        <p>
          {SelectItem?.shippingInfo?.shippingType ||
            SelectItem?.orShipping?.type ||
            SelectItem?.shipType}
        </p>
      </div>
      <div className="mt-3">
        <h6>Carrier:</h6>
        <p>
          {SelectItem?.shippingInfo?.labels[0]?.companyName ||
            SelectItem?.orShipping?.companyName ||
            SelectItem?.companyName}
        </p>
      </div>
      <div className="mt-3">
        <h6>Est. Shipping Date:</h6>
        <p>
          {/* {moment(SelectItem?.shippingInfo?.pickupDate).format("l")} */}
          {MMDDYYFormat(SelectItem?.shippingInfo?.pickupDate)}__
          {/* {MMDDYYFormat(SelectItem?.shippingInfo?.pickupEndDate)} */}
          {moment(SelectItem?.shippingInfo?.pickupEndDate).format("l")}
        </p>
      </div>
      <div className="mt-3">
        <h6>Est. Delivery Date:</h6>
        <p>
          {/* {moment(
            toISOStringFromat1(SelectItem?.shippingInfo?.deliveryDate)
          ).format("l")} */}
          {MMDDYYFormat(SelectItem?.shippingInfo?.deliveryDate)}
          __
          {MMDDYYFormat(SelectItem?.shippingInfo?.deliveryEndDate)}
          {/* {moment(
            toISOStringFromat1(SelectItem?.shippingInfo?.deliveryEndDate)
          ).format("l")} */}
        </p>
      </div>
      {/* {console.log(SelectItem?.shippingInfo)} */}
      <div className="mt-3">
        <h6>
          Tracking Number:{" "}
          <a
            href={
              ShippingComapny.find(
                (item) =>
                  item?.name === SelectItem?.shippingInfo?.labels[0].companyName
              )?.link
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Track
          </a>{" "}
        </h6>

        {SelectItem?.shippingInfo?.labels?.map((item, i) => (
          <p key={i}>{item?.trackingNumber}</p>
        ))}
      </div>
      <div className="mt-3">
        <h6>Box ID:</h6>
        <p>{SelectItem?.boxId}</p>
      </div>
      <div className="mt-3">
        <h6>Cubic Feet:</h6>
        <p>{getTotalCubicUnits(SelectItem?.dimensions)}</p>
      </div>
      <SwitchShippingParty SelectItem={SelectItem} />
    </div>
  );
};

export default TrackingDetailsCard;
