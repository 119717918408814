import React, { useEffect, useState } from "react";
import TrackingDetailsCard from "./TrackingDetailsCard";
import { useDispatch } from "react-redux";
import { UpdateShipping } from "../../../../actions/order";
import ActionButton from "../../../../components/CustomeButton/ActionButton";
import { toISOStringFromat } from "../../../../utils/dateFormate";
import RecommendedShipping from "./RecommendedShipping";
import SaleAnalysisSuggestedShipping from "./SaleAnalysisSuggestedShipping";
import moment from "moment";
import CustomeSelect from "../../../../components/custome-select/CustomeSelect";
import DateRangePicker from "../../../../components/DatePicker/DateRangePicker";
import { Checkbox } from "@mui/material";
import SwitchShippingParty from "./SwitchShippingParty";

const ShippingUpdateComp = ({
  page,
  SelectItem,
  ShippingComapny,
  setIsMount,
  IsMount,
  CurrentStatus,
}) => {
  const dispatch = useDispatch();
  const [openConfirmBtn, setopenConfirmBtn] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState(
    SelectItem?.shippingInfo.labels.length
      ? SelectItem?.shippingInfo.labels
      : []
  );
  const [pickupDate, setpickupDate] = useState(
    SelectItem?.shippingInfo.pickupDate ?? ""
  );
  const [pickupEndDate, setpickupEndDate] = useState(
    SelectItem?.shippingInfo.pickupEndDate ?? ""
  );
  const [pickupDeliveryDate, setpickupDeliveryDate] = useState(
    SelectItem?.shippingInfo.pickupDeliveryDate ?? ""
  );
  const [deliveryEndDate, setDeliveryEndDate] = useState(
    SelectItem?.shippingInfo.deliveryEndDate ?? ""
  );
  const [deliveryDate, setdeliveryDate] = useState(
    SelectItem?.shippingInfo.deliveryDate ?? ""
  );
  const [ShippingType, setShippingType] = useState(
    (SelectItem?.shippingInfo?.shippingType ||
      SelectItem?.orShipping?.type ||
      SelectItem?.shipType) ??
      ""
  );
  const [AddNew, setAddNew] = useState(false);

  const [loading, setloading] = useState(false);
  const [tempTrackNum, settempTrackNum] = useState("TBD");
  const [Carrier, setCarrier] = useState(
    (SelectItem?.shippingInfo?.labels[0]?.companyName ||
      SelectItem?.orShipping?.companyName ||
      SelectItem?.companyName) ??
      ""
  );

  const [sendMail, setSendMail] = useState(
    SelectItem?.status !== "pending" ? true : false
  );
  const [editIndex, seteditIndex] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [trackingValidationError, setTrackingValidationError] = useState(null);

  const handleChangeComapny = (value) => {
    setCarrier(value);
    const newArr = [...trackingNumbers];
    const shippingAdd = newArr.map((item) => {
      return {
        ...item,
        companyName: value,
      };
    });
    setTrackingNumbers(shippingAdd);
  };

  const handleUpdateTracking = async () => {
    const isValid = validateShippingDetails();
    if (isValid) {
      setloading(true);
      setopenConfirmBtn(true);
      try {
        const res = await dispatch(
          UpdateShipping({
            orderItemId: SelectItem?._id,
            pickupDate: pickupDate ? moment(pickupDate).format("l") : null,
            pickupEndDate: pickupEndDate
              ? moment(pickupEndDate).format("l")
              : null,
            deliveryEndDate: deliveryEndDate
              ? moment(deliveryEndDate).format("l")
              : null,
            shippingType: ShippingType,
            deliveryDate: deliveryDate ? moment(deliveryDate).format("l") : "",
            labels: trackingNumbers,
            sendMail: sendMail,
          })
        );

        if (res) {
          setloading(false);
          setIsMount(!IsMount);
        }
      } catch (error) {
        setloading(false);
      }
    }
  };
  const removeTracking = (indexToRemove) => {
    setTrackingNumbers((currentTrackingNumbers) =>
      currentTrackingNumbers.filter((_, index) => index !== indexToRemove)
    );
  };
  // validation for customer select box
  const validateShippingDetails = () => {
    let isValid = true;
    let errorMessage = "";
    let trackingError = "";
    if (!ShippingType) {
      isValid = false;
      errorMessage += "Shipping Type is required.\n";
    }
    if (!Carrier) {
      isValid = false;
      errorMessage += "Carrier is required.\n";
    }
    if (!pickupDate) {
      isValid = false;
      errorMessage += "Pickup date is required.\n";
    }
    // if (
    //   SelectItem?.trackingStatus === "Preparing To Ship" ||
    //   SelectItem?.trackingStatus === "In Transit"
    // ) {
    //   if (!deliveryDate) {
    //     isValid = false;
    //     errorMessage += "Delivery date is required.\n";
    //   }
    // }
    if (trackingNumbers.length === 0) {
      isValid = false;
      trackingError = "At least one tracking number is required.";
    }
    setValidationError(errorMessage.trim());
    setTrackingValidationError(trackingError.trim());
    return isValid;
  };

  useEffect(() => {
    validateShippingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ShippingType, Carrier]);
  // add tracking number
  const addTracking = (num, index) => {
    const trimmedNum = num.trim();
    if (!trimmedNum) {
      console.error("Tracking number cannot be empty");
      return;
    }
    if (index === 0 || index > 0) {
      const newValue = [...trackingNumbers];
      newValue[index].trackingNumber = num;
      setTrackingNumbers(newValue);
      settempTrackNum("");
      setAddNew(false);
      seteditIndex(null);
    } else {
      const newValue = [...trackingNumbers];
      newValue.push({
        companyName: Carrier,
        trackingNumber: num,
        default: true,
      });
      setTrackingNumbers(newValue);
      settempTrackNum("");
      setAddNew(false);
    }
  };
  // handleEdit tracking
  const handleEdit = (value, index) => {
    seteditIndex(index);
    settempTrackNum(value);
    setAddNew(true);
  };

  return (
    <>
      <div>
        {SelectItem?.orShipping?.companyName || SelectItem?.orShipping?.type ? (
          page === "pending" && (
            <>
              <div className="px-3">
                <h4>Shipping Details</h4>
                <SaleAnalysisSuggestedShipping SelectItem={SelectItem} />
              </div>
              {SelectItem?.shippingInfo?.labels.length ? <></> : <hr />}
            </>
          )
        ) : (
          <>
            <div className="px-3">
              <h4>Shipping Details</h4>
              <RecommendedShipping SelectItem={SelectItem} />
            </div>
            {SelectItem?.shippingInfo?.labels.length ? <></> : <hr />}
          </>
        )}
      </div>
      <div className="mt-4">
        {SelectItem?.shippingInfo?.labels.length ? (
          <>
            <div className="px-3">
              <TrackingDetailsCard
                ShippingComapny={ShippingComapny}
                SelectItem={SelectItem}
              />
            </div>
            <hr />
          </>
        ) : (
          <>
            <div className="flex justify-between px-3 mb-2">
              <SwitchShippingParty SelectItem={SelectItem} />
            </div>
          </>
        )}
      </div>

      <div className="px-3">
        <div className="system-genrated-shipping">
          <h6> Shipping & Tracking Details</h6>
          <h6 className="mt-5"> Shipping Details:</h6>

          {validationError && (
            <p className="text-danger mt-2">{validationError}</p>
          )}

          <div className="mt-3">
            <CustomeSelect
              className={"border-grey"}
              title={"Shipping Type"}
              value={ShippingType}
              placeholder="Select"
              options={[
                { label: "Select", value: "" },
                { label: "Standard", value: "standard" },
                { label: "White Glove", value: "WG" },
              ]}
              onChange={(value) => {
                setShippingType(value);
              }}
            />
          </div>
          <div className="mt-3">
            <CustomeSelect
              className={"border-grey"}
              title={"Carrier"}
              value={
                SelectItem?.shippingInfo?.labels[0]?.companyName ||
                SelectItem?.orShipping?.companyName ||
                SelectItem?.companyName
              }
              placeholder="Select"
              options={ShippingComapny.map((element) => {
                return {
                  value: element.name,
                  label: element.name,
                };
              })}
              onChange={(value) => handleChangeComapny(value)}
            />
          </div>
          <div>
            <DateRangePicker
              startDate={pickupDate}
              endDate={pickupEndDate}
              onChange={(date) => {
                setpickupDate(date[0]);
                setpickupEndDate(date[1]);
              }}
              TBD={pickupDate !== "" ? false : true}
              title={"Est. Shipping Date:"}
            />
            <DateRangePicker
              startDate={deliveryDate}
              endDate={deliveryEndDate}
              onChange={(date) => {
                setdeliveryDate(date[0]);
                setDeliveryEndDate(date[1]);
              }}
              TBD={deliveryDate !== "" ? false : true}
              title={"Est. Delivery Date:"}
            />

            {/* <div
              className="d-flex justify-content-between align-center add-button mt-2"
              style={
                deliveryDate === ""
                  ? {
                      background: "#fdefec",
                      borderColor: "#ff5e38",
                      color: "#ff5e38",
                    }
                  : {}
              }
            >
              <p style={{ whiteSpace: "nowrap", color: "#898989" }}>
                Estimated Delivery Date:{deliveryDate === "" ? "TBD" : ""}
              </p>
              <input
                type="date"
                className=" border-0 bg-transparent"
                name="deliveryDate"
                value={toISOStringFromat(deliveryDate)}
                onChange={(e) => setdeliveryDate(new Date(e.target.value))}
              />
            </div> */}
          </div>

          <div className=" mt-5">
            <h6>Tracking Details:</h6>
          </div>
          {trackingValidationError && (
            <p className="text-danger mt-2">{trackingValidationError}</p>
          )}
          <div
            className=" border-radius-10 px-2 py-4 mt-4"
            style={
              !trackingNumbers.length
                ? {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    background: "#fdefec",
                    borderColor: "#ff5e38",
                    color: "#ff5e38",
                  }
                : {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    background: "#fff",
                    borderColor: "#cdcdcd",
                  }
            }
          >
            <h6>
              Tracking Number: {trackingNumbers.length === 0 ? "TBD " : ""}
            </h6>
            {/* {console.log(trackingNumbers)} */}
            {trackingNumbers?.map((num, i) => (
              <div className="d-flex flex-column mt-2" key={i}>
                <div className="d-flex align-center">
                  <p style={{ flexBasis: "50%" }}>{num?.trackingNumber}</p>
                  <span
                    onClick={() => handleEdit(num?.trackingNumber, i)}
                    className="underlined c-pointer font-weight-bold"
                    style={{ color: "#007bff" }}
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => removeTracking(i)}
                    className="underlined c-pointer font-weight-bold text-danger"
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </span>
                </div>
              </div>
            ))}
            <div className=" mt-3">
              {AddNew && (
                <div className=" d-flex justify-content-between align-center add-button mt-2">
                  <input
                    style={{ flex: 1 }}
                    className=" border-0  bg-transparent"
                    type="text"
                    required
                    placeholder="Enter Tracking Number"
                    value={tempTrackNum}
                    onChange={(e) => settempTrackNum(e.target.value)}
                  />
                  <div className="d-flex flex-column gap-5 align-center">
                    <button
                      onClick={() => addTracking(tempTrackNum, editIndex)}
                      type="submit"
                      className=" border-0 bg-transparent font-weight-bold underlined c-pointer"
                    >
                      Save
                    </button>
                    {/* <span
                      onClick={() => setAddNew(false)}
                      className=" text-danger underlined c-pointer"
                    >
                      Remove
                    </span> */}
                  </div>
                </div>
              )}

              <div className="my-3">
                <span
                  onClick={() => setAddNew(true)}
                  style={{
                    textDecoration: "underline",
                    cursor: "cell",
                    textUnderlineOffset: "5px",
                    color: "#023047",
                  }}
                >
                  +Add Tracking Number
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-center">
            <Checkbox
              checked={sendMail}
              onChange={(e) => setSendMail(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ padding: "5px" }}
            />
            <span className=" text-black">Email Send</span>
          </div>
          <div className=" mt-4 ">
            {!openConfirmBtn && (
              <button
                onClick={() => setopenConfirmBtn(true)}
                className="update-Order-button bg-success"
                disabled={AddNew}
              >
                {loading ? "Loading...." : "Update Shipping And Tracking "}
              </button>
            )}

            {openConfirmBtn && (
              <>
                <button
                  onClick={() => handleUpdateTracking()}
                  className=" w-100 border-0 "
                  type="submit"
                >
                  <ActionButton
                    className="mt-2"
                    text={loading ? "Loading..." : "Confirm"}
                    type="success"
                  />
                </button>
                <span onClick={() => setopenConfirmBtn(false)}>
                  <ActionButton className="mt-2" text="Cancel" type="error" />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingUpdateComp;
