import React, { Component } from "react";

import { bulkUsers } from "../../../actions/user";
import * as XLSX from 'xlsx'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
const handleForce = (data, fileInfo) => console.log(data, fileInfo);
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

class UploadFile extends Component {
  state = {
    loading: false,
    images: [],
    data: [],
  };
  handleFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });
      console.log("xlsx", data);
      this.setState({
        buttonChange: "",
        data: data,
        cols: make_cols(ws["!ref"]),
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  _submitHandler = async () => {
    const { data } = this.state;

    this.setState({ loading: true });
    let formData = [];

    data.forEach((element, index) => {
      console.log(element);
      let obj = {};
      let fullName = element.full_name;
      let phone = element.phone_number;

      obj.firstName = fullName?.substr(0, fullName.indexOf(" "))
        ? fullName.substr(0, fullName.indexOf(" "))
        : fullName.substr(fullName.indexOf(" ") + 1);
      obj.lastName = fullName.substr(fullName.indexOf(" ") + 1);
      obj.slug =
        fullName.replace(/\s+/g, "-").toLowerCase() +
        Math.floor(Math.random() * 999 + 1);
      obj.fullName = fullName;
      obj.email = element.email;
      obj.phone = phone
        ? phone.toString().replace(/[^A-Z0-9]+/gi, "")
        : "123123123";
      obj.verified = true;
      obj.reference = "facebook";
      formData.push(obj);
    });
    var i,
      j,
      temparray,
      chunk = 3;
    // let test = formData.length / chunk;
    for (i = 0, j = formData.length; i < j; i += chunk) {
      temparray = formData.slice(i, i + chunk);
      console.log(temparray);
      await this.props.bulkUsers(temparray);
    }
    // await this.props.bulkUsers(formData);
    data.length && toast(`Users Added Successfully`, { containerId: "B" });
    this.setState({ loading: false });
  };
  render() {
    return (
      <>
        {this.state.loading && <TopBarProgress />}
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Upload File
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {/* <Link to="/admin/paid-users">Customer Management</Link> */}
                Customer Management
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Upload File
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Upload File
                </h4>
                <div className="p-2">
                  <form className="forms-sample">
                    <div className="form-group">
                      <div className="col-md-4">
                        <label style={{ fontWeight: "bold" }}>
                          CSV File upload
                        </label>
                      </div>

                      <div className="input-group col-md-8 col-xs-12">
                        <DragDropFile handleFile={this.handleFile}>
                          <div className="row">
                            <div className="col-xs-12">
                              <DataInput handleFile={this.handleFile} />
                            </div>
                          </div>
                        </DragDropFile>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className={`btn btn-primary mr-2 ${this.state.buttonChange}`}
                    >
                      Submit
                    </button>
                    <Link to={"/admin/users"}>
                    <button
                      className="btn btn-light"

                    >
                      Cancel
                    </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group" style={{ marginLeft: "12px" }}>
          <label htmlFor="file" style={{ fontWeight: "bold" }}>
            {" "}
          </label>
          <input
            type="file"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

bulkUsers.propTypes = {
  bulkUsers: PropTypes.func.isRequired,
};
export default connect(null, { bulkUsers })(UploadFile);
