import React from "react";
import "./styles.css";
const SearchFilter = ({
  onChange,
  placeholder,
  type,
  name,
  className,
  value,
  width,
}) => {
  return (
    <input
      style={{ width: width }}
      className={`search-filter ${className}`}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      value={value}
    />
  );
};

export default SearchFilter;
