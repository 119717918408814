import React, { useState } from "react";
import UpdateButton from "./UpdateButton";
import { updatePricing } from "../../../../services/vendor/vendor.service";

const ManageGlobalMargin = ({ selectedItem, isRender, setIsRender }) => {
  const [loading, setLoading] = useState(false);
  console.log(selectedItem);
  const [formData, setFormData] = useState({
    mapPercentage: selectedItem?.mapPercentage,
    nonMapPercentage: selectedItem?.nonMapPercentage,
  });

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await updatePricing({
        id,
        ...formData,
      });
      if (res?.data?.message) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className=" d-flex flex-column">
      <div className=" d-flex  justify-content-between align-center px-3">
        <div>Global MAP Margins</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-50"
            type="number"
            name="mapPercentage"
            value={formData?.mapPercentage}
            onChange={(e) =>
              setFormData({
                ...formData,
                [e.target.name]: Number(e.target.value),
              })
            }
          />
          %
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between align-center px-3">
        <div>
          Global Non MAP <br /> Margins
        </div>
        <div>
          <input
            className=" p-2 border-radius-10 border-grey  w-50"
            type="number"
            name="nonMapPercentage"
            value={formData?.nonMapPercentage}
            onChange={(e) =>
              setFormData({
                ...formData,
                [e.target.name]: Number(e.target.value),
              })
            }
          />
          %
        </div>
      </div>
      <hr />
      <UpdateButton
        position="fixed"
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManageGlobalMargin;
