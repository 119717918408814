import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import styles from './fullColBanner.module.css';

function FullColBanner({
  handleRemoveSection,
  index,
  show,
  mainIndex,
  handleChangeText,
  handleClose,
  handleFileChange,
  handleShow,
  section,
  selectedIndex,
  handleParent,
}) {
  const [edit, setEdit] = useState({
    title: [false, false, false, false],
    desc: [false, false, false, false],
  });
  const [heading, setHeading] = useState(false);
  const [indexToChange, setindexToChange] = useState(null);

  const handleHeading = () => {
    setHeading(!heading);
  };

  const handleEdit = (name, index) => {
    console.log(edit);
    setEdit({
      ...edit,
      [name]: edit[name].map((value, i) => (i === index ? !value : value)),
    });
  }; 
  const columns = section?.values?.length ?? 1;
  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        {/* title for banners */}
        {!heading ? (
          <div className="d-flex justify-content-center align-items-center px-2">
            <h3 className="text-center" style={{ opacity: "0.5" }}>
              {section?.heading || "add heading"}
            </h3>
            <i
              onClick={() => handleHeading()}
              className={`${styles.EditPencil} icon-pencil`}
            ></i>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center px-2">
            <input
              type="text"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleHeading();
                  handleParent(index, "heading", e.target.value);
                }
              }}
              name="heading"
              defaultValue={section?.heading ?? ""}
              onBlur={(e) => {
                handleHeading();
                handleParent(index, "heading", e.target.value);
              }}
              className={styles.titleInput}
              autoFocus={true}
              placeholder="heading"
            />
          </div>
        )}

        <div className="row h-100">
          {section.values.map((value, _index) => (
            <div key={_index} className={`col-md-${12 / columns} px-0`}>
              <div className={styles.bannerCard}>
                <img
                  src={value?.url}
                  alt={value.title ?? "Image"}
                  width="100%"
                  height="100%"
                  style={{ maxHeight: "300px" }}
                  className="border-radius-8 object-fit-contain"
                />
                {value?.showDesc && (
                  <>
                    <div className="bg-white underline-hover f1 pb-3">
                      {!edit.title[_index] ? (
                        <div className="d-flex justify-content-center align-items-center px-2">
                          <h2
                            onClick={() => handleEdit("title", _index)}
                            className={`${styles.title} text-dark`}
                          >
                            {value.title || (
                              <span style={{ opacity: "0.5" }}>Title</span>
                            )}
                          </h2>
                          <i
                            onClick={() => handleEdit("title", _index)}
                            className={`${styles.EditPencil} icon-pencil`}
                          ></i>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center px-2">
                          <input
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEdit("title", _index);
                                handleChangeText(e, index, _index);
                              }
                            }}
                            name="title"
                            defaultValue={value.title || ""}
                            onBlur={(e) => {
                              handleEdit("title", _index);
                              handleChangeText(e, index, _index);
                            }}
                            className={styles.titleInput}
                            autoFocus={true}
                            placeholder="Title"
                          />
                        </div>
                      )}

                      <div className="mt-3">
                        {!edit.desc[_index] ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <p
                              onClick={() => handleEdit("desc", _index)}
                              className={styles.bannerDesc}
                            >
                              {value.desc || (
                                <span style={{ opacity: "0.5" }}>
                                  Description
                                </span>
                              )}
                            </p>
                            <i
                              onClick={() => handleEdit("desc", _index)}
                              className={`${styles.EditPencil} icon-pencil`}
                            ></i>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              type="text"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleEdit("desc", _index);
                                  handleChangeText(e, index, _index);
                                }
                              }}
                              name="desc"
                              defaultValue={value.desc || ""}
                              onBlur={(e) => {
                                handleEdit("desc", _index);
                                handleChangeText(e, index, _index);
                              }}
                              className={styles.descInput}
                              placeholder="Description"
                              autoFocus={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div
                  style={{ position: "absolute", top: "5px", left: "5px" }}
                  className={`bg-white d-flex align-items-center ${styles.h92}`}
                >
                  <i
                    className={`${styles.actionBtn} ${styles.hoverPrimary} p-2  icon-pencil`}
                    onClick={() => handleShow(_index, index)}
                  ></i>
                  <i
                    className={`${styles.actionBtn} ${styles.hoverWarning} p-2  icon-close`}
                    onClick={() => handleRemoveSection(index, _index)}
                  ></i>
                  <i
                    className={`${styles.actionBtn} ${styles.hoverWarning} p-2  icon-arrow-down`}
                    onClick={() =>
                      handleChangeText(
                        {
                          target: {
                            name: "showDesc",
                            value: !value?.showDesc,
                          },
                        },
                        index,
                        _index
                      )
                    }
                  ></i>
                </div>
                <Modal
                  show={show && _index === selectedIndex && mainIndex === index}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton centered>
                    <Modal.Title>Add Banner</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {value?.url && (
                      <div className="d-flex flex-column justify-content-center mb-3">
                        <>
                          <label>Main Image</label>
                          <img src={value?.url} alt="preview" width="400px" />
                        </>
                        <>
                          <label>Tablet Image</label>
                          <img
                            src={value?.mediumUrl || value?.url}
                            alt="preview"
                            width="400px"
                          />
                        </>
                        <>
                          <label>Mobile Image</label>
                          <img
                            src={
                              value?.smallUrl || value?.mediumUrl || value?.url
                            }
                            alt="preview"
                            width="400px"
                          />
                        </>
                      </div>
                    )}
                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>
                        Upload File
                        <span className="ml-2 dimension__text">
                          ( 1424 × 950 px)
                        </span>
                      </Form.Label>
                      <Form.File
                        id={index}
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index);
                        }}
                        custom
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>Upload Medium Screen Image </Form.Label>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index, "mediumUrl");
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Medium Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>Upload Small Screen Image </Form.Label>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index, "smallUrl");
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Small Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasiclink" className="m-2">
                      <Form.Label>Alt</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type Image Alt"
                        defaultValue={value.alt || ""}
                        name="alt"
                        onChange={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        This Link will redirect to product page or category page
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasiclink" className="m-2">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="paste link"
                        name="link"
                        defaultValue={value?.link ?? ""}
                        onBlur={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        This Link will redirect to product page or category page
                      </Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className={`${styles.actionBtn} btn-danger`}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.h93}>
        <i
          onClick={() => handleRemoveSection(index)}
          className={`${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
          title="Remove"
          style={{ height: "fit-content" }}
        ></i>
        <i
          onClick={() => {
            section.values.length < 8 && handleShow(-1, index);
            setindexToChange(section.values.length);
          }}
          className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-plus`}
          title="Add"
          style={{ height: "fit-content" }}
        ></i>
      </div>
      <Modal
        show={show && mainIndex === index && selectedIndex === -1}
        onHide={handleClose}
      >
        <Modal.Header closeButton centered>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {section.values?.[section?.values?.length - 1]?.url && (
            <div className="d-flex justify-content-center mb-3">
              <img
                src={section.values?.[section?.values?.length - 1]?.url}
                alt="preview"
                width="400px"
              />
            </div>
          )} */}
          <div className="d-flex flex-column justify-content-center mb-3">
            <>
              <label>Main Image</label>
              <img
                src={section.values[indexToChange]?.url}
                alt="no url found"
                width="400px"
              />
            </>
            <>
              <label>Tablet Image</label>
              <img
                src={
                  section.values[indexToChange]?.mediumUrl ||
                  section.values[indexToChange]?.url
                }
                alt="no url found"
                width="400px"
              />
            </>
            <>
              <label>Mobile Image</label>
              <img
                src={
                  section.values[indexToChange]?.smallUrl ||
                  section.values[indexToChange]?.mediumUrl ||
                  section.values[indexToChange]?.url
                }
                alt="no url found"
                width="400px"
              />
            </>
          </div>
          <Form.Group controlId="formBasicimage" className="m-2">
            <Form.Label>Upload Image </Form.Label>
            <Form.File
              id={index}
              label="Add image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e, index, indexToChange);
              }}
              custom
            />
          </Form.Group>

          <Form.Group controlId="formBasicimage" className="m-2">
            <Form.Label>Upload Medium Image </Form.Label>
            <Form.File
              id={index}
              label="Add image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e, index, indexToChange, "mediumUrl");
              }}
              custom
            />
          </Form.Group>

          <Form.Group controlId="formBasicimage" className="m-2">
            <Form.Label>Upload Small Image </Form.Label>
            <Form.File
              id={index}
              label="Add image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e, index, indexToChange, "smallUrl");
              }}
              custom
            />
          </Form.Group>

          <Form.Group controlId="formBasiclink" className="m-2">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="paste link"
              name="link"
              onBlur={(e) => handleChangeText(e, index, indexToChange)}
            />
            <Form.Text className="text-muted">
              This Link will redirect to product page or category page
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`${styles.actionBtn} btn-danger`}
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FullColBanner;
