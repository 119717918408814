import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckBoxComponent from './Components/CheckBoxComponent';
import { apiURL } from '../../../config/default';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';


function AutoSettings() {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      await getSettings();
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettings = async () => {
    const { data: settings } = await axios.get(
      `${apiURL}filters/get-filter/${location.search.split('=')[1]}`
    );
    if (settings.length !== 0) {
      const data = settings[0];
      if (!data.values)
        data.values = [
          {
            productAttribute: [],
            attributeId: '',
            title: '',
            showImage: false,
            isShow: true,
            imageURL: '',
            description: '',
          },
        ];
      else {
        data.values = data.values.map((val) => ({ ...val, isbase64: false }));
      }

      setstate({ ...data, isbase64: false });
      setshowInGlobal(!!data.global);
    }
  };

  const [state, setstate] = useState({
    title: '',
    showImage: false,
    imageURL: '',
    checkBoxes: false,
    isRange: false,
    filter: location.search.split('=')[1],
    status: true,
    values: [
      {
        productAttribute: [],
        attributeId: '',
        title: '',
        showImage: false,
        isShow: true,
        imageURL: '',
        description: '',
      },
    ],
  });

  const [loading, setloading] = useState(true);
  const [showInGlobal, setshowInGlobal] = useState(false);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          productAttribute: [],
          attributeId: '',
          title: '',
          showImage: false,
          isShow: true,
          imageURL: '',
          description: '',
        },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };
    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === '') return alert('title is required');

    let dataToSend = { ...state };

    setloading(true);

    try {
      await axios.put(`${apiURL}filters/edit-filter`, {
        ...dataToSend,
      });
      toast.success('Update Successful', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error('Something went wrong 😟', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setloading(false);
    }
  };

  const handleShowInGlobal = async (e) => {
    setshowInGlobal(e);
    await axios.post(
      `${apiURL}filters/update-global/${location.search.split('=')[1]}`,
      { global: e }
    );
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);

    if (state._id) setstate({ ...state, imageURL: image, isbase64: true });
    else setstate({ ...state, imageURL: image });
  };

  const renderToggleButton = (key, title) => (
    <>
      <label>{title}</label>
      <div
        className="connection-all"
        onClick={() => setstate({ ...state, [key]: !state[key] })}
      >
        <span className={state[key] ? 'allcenter' : 'allLeft '}>ON</span>

        <span className={state[key] ? 'editRight' : 'editcenter '}>OFF</span>
      </div>
    </>
  );

  return (
    <div className="container-scroller">
      <ToastContainer />
      {loading && <TopBarProgress />}

      <div className="page-header">
        <h3 className="page-title"> Custom Settings </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/filter-category">Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              <div className="row mb-3 mt-3">
                <div className="col-md-3  mt-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Title"
                    name="title"
                    value={state.title}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="col-md-3  mt-3">
                  <label>Include in Global</label>
                  <div
                    className="connection-all"
                    onClick={() => handleShowInGlobal(!showInGlobal)}
                  >
                    <span className={showInGlobal ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span
                      className={showInGlobal ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>

                <div className="col-md-3  mt-3">
                  {renderToggleButton("showImage", "Show Image")}
                </div>
                {state.showImage && (
                  <div className="col-md-3  mt-3">
                    <label>Image URL</label>
                    <input
                      type="file"
                      name="imageURL"
                      onChange={(e) => _handleImageChange(e)}
                      className="review-input border-radius-8"
                    />
                  </div>
                )}
                <div className="col-md-3  mt-3">
                  {renderToggleButton("status", "Show Auto Filters")}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row my-2">
              {state.checkBoxes &&
                state.values.map((value, index) => (
                  <CheckBoxComponent
                    title={value.title}
                    showImage={value.showImage}
                    showThisFilter={value.isShow}
                    state={state}
                    value={value}
                    description={value.description ?? ""}
                    handleRemoveField={handleRemoveField}
                    index={index}
                    setstate={setstate}
                    handleGetAttributes={() => {}}
                    attributes={[]}
                    SecondLevelAttribute={() => {}}
                    convertImgToBase64={convertImgToBase64}
                    productAttribute={""}
                    isAuto={true}
                  />
                ))}
              {state.checkBoxes && (
                <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Button
                      className="p-3"
                      variant="outline-primary"
                      size="sm"
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          className="ml-2 mt-3"
          variant="outline-primary"
          size="sm"
          type="submit"
          disabled={loading}
        >
          {loading ? "loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
}

export default AutoSettings;
