/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './viewTicket.css';
import axios from 'axios';
import { apiURL } from '../../../config/default';
import setAuthHeader from '../../../utils/setAuthHeader';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";

const ViewTicket = () => {
  // hooks
  const navigate = useNavigate();
  const { ticketID } = useParams();

  const [messages, setmessages] = useState([]);
  const [loading, setloading] = useState(false);
  const [ticket, setticket] = useState({});

  console.log(ticket);

  // const [id, setId] = useState(props.location?.search.substr(4));
  const [show, setshow] = useState(false);

  const [values, setvalues] = useState({ message: "", images: [] });
  const [amount, setamount] = useState(null);
  const [resMessage, setresMessage] = useState("");

  useEffect(() => {
    setloading(true);
    (async () => {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthHeader(token);
      }
      const { data: res } = await axios.get(`${apiURL}ticket/${ticketID}`);
      console.log(res, "response");
      // setmessages(res.messages);
      setticket(res?.ticketDetail);
      // setticket(res.ticketDetail);
      setloading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     setAuthHeader(token);
  //   }

  //   const userId = JSON.parse(localStorage.getItem("user"))._id;
  //   const data = {
  //     ticketId: ticket._id,
  //     user: userId,
  //     userType: "Agent",
  //     message: values.message,
  //     images: values.images,
  //   };
  //   setloading(true);
  //   const { data: res } = await axios.post(`${apiURL}ticket/${ticketID}`, data);
  //   const { data: res1 } = await axios.get(`${apiURL}ticket/${ticketID}`);
  //   setmessages(res1.messages);
  //   setticket(res1?.ticketDetail);

  //   // setticket(res1.ticketDetail);
  //   setvalues({ message: "", images: [] });
  //   setloading(false);
  // };

  const handleClose = () => setshow(false);

  // const handleRefund = async () => {
  //   setloading(true);
  //   const userId = JSON.parse(localStorage.getItem("user"))._id;
  //   const data = {
  //     ticketId: ticket._id,
  //     userId: userId,
  //     productId: ticket.productId._id,
  //     orderId: ticket.orderId,
  //     amount: Number(amount),
  //   };

  //   const { data: res } = await axios.post(`${apiURL}payment/refund`, data);
  //   const data1 = {
  //     ticketId: ticket._id,
  //     user: userId,
  //     userType: "Agent",
  //     message: "Refund is successful",
  //     images: values.images,
  //   };
  //   await axios.post(`${apiURL}ticket/${ticketID}`, data1);

  //   setloading(false);
  //   if (res) {
  //     setresMessage("Refund is successful");
  //     setTimeout(() => {
  //       navigate(0);
  //     }, 2000);
  //   }
  // };

  const handleMarkResolve = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    const userId = JSON.parse(localStorage.getItem("user"))._id;
    const data = {
      ticketId: ticket._id,
      user: userId,
      userType: "Agent",
      message:
        "You issue is resolved by admin, if you are happy with the result mark this ticket as resolved otherwise just simply send message to reopen the ticket",
      images: values.images,
      resolveByAgent: true,
    };
    setloading(true);
    const { data: res } = await axios.post(`${apiURL}ticket/${ticketID}`, data);
    const { data: res1 } = await axios.get(`${apiURL}ticket/${ticketID}`);
    setmessages(res1.messages);

    setticket(res1.ticketDetail);
    setvalues({ message: "", images: [] });
    setloading(false);
  };

  return (
    <>
      <div className="container-scroller">
        <div>
          {/* <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          /> */}
          <div className="container padding-bottom-3x mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="padding-top-2x mt-2 hidden-lg-up" />
                <div className="table-responsive margin-bottom-2x">
                  <table className="table margin-bottom-none">
                    <thead>
                      <tr>
                        <th>Date Submitted</th>
                        <th>Last Updated</th>
                        <th>Type</th>

                        <th>Status</th>
                        <th>Price</th>
                        <th>Refund</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12/12/2020</td>
                        <td>12/12/2020</td>
                        <td>{ticket?.reason}</td>
                        <td>
                          <span className="text-warning">{ticket?.status}</span>
                        </td>
                        <td>${ticket?.productId?.price}</td>
                        <td>
                          {!ticket?.refunded ? (
                            <span
                              className="text-primary"
                              onClick={() => setshow(true)}
                            >
                              Create Refund
                            </span>
                          ) : (
                            "Already refunded"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Messages*/}
                {loading && messages?.length === 0
                  ? "loading"
                  : messages?.map((message, i) => (
                      <div
                        key={i}
                        className={`comment  ${
                          message?.userType === "Customer"
                            ? "pl-0 padding-right-68"
                            : " "
                        }`}
                      >
                        <div
                          className={`comment-author-ava ${
                            message?.userType === "Customer"
                              ? "user-comment"
                              : " "
                          }`}
                        >
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar6.png"
                            alt="Avatar"
                          />
                        </div>
                        <div
                          className={`comment-body  ${
                            message?.userType === "Customer"
                              ? "comment-arrow-right bg-primary"
                              : " "
                          }`}
                        >
                          <p
                            className={`comment-text ${
                              message.userType === "Customer"
                                ? "text-right  text-white  "
                                : ""
                            } `}
                          >
                            {message.message}
                          </p>
                          <div
                            className={`comment-footer ${
                              message.userType === "Customer"
                                ? "text-right text-white "
                                : ""
                            }`}
                          >
                            <span
                              className={`comment-meta ${
                                message.userType === "Customer"
                                  ? " text-white "
                                  : ""
                              }`}
                            >
                              {message.user.fullName}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}

                {/* Reply Form*/}
                <h5 className="mb-30 padding-top-1x">
                  {!ticket.resolveByOpener && "Leave Message"}
                </h5>

                {!ticket.resolveByOpener && (
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-rounded"
                      id="review_text"
                      rows={8}
                      placeholder="Write your message here..."
                      required
                      value={values.message}
                      onChange={(e) =>
                        setvalues({ ...values, message: e.target.value })
                      }
                    />
                  </div>
                )}
                <div className="text-right">
                  {ticket.resolveByAgent && !ticket.resolveByOpener && (
                    <button
                      className="btn   text-black bg-primary"
                      disabled={loading}
                    >
                      Waiting for User to resolve the issue
                    </button>
                  )}
                  {ticket.resolveByAgent && ticket.resolveByOpener && (
                    <button
                      className="btn   text-black bg-primary"
                      disabled={loading}
                    >
                      Issue resolved
                    </button>
                  )}
                  {!ticket.resolveByAgent && !ticket.resolveByOpener && (
                    <button
                      className="btn   text-black bg-primary"
                      disabled={loading}
                      onClick={handleMarkResolve}
                    >
                      Mark as resolve
                    </button>
                  )}
                  {!ticket.resolveByOpener && (
                    <button
                      className="btn   text-black bg-primary"
                      disabled={loading}
                      // onClick={handleSubmit}
                    >
                      Submit Message
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              name="amount"
              value={amount}
              onChange={(e) => {
                if (!ticket.productId) return;
                if (Number(e.target.value) <= ticket?.productId?.price)
                  setamount(e.target.value);
              }}
            />
            <Form.Text className="text-muted">
              Enter the refund amount (max amount: {ticket?.productId?.price})
            </Form.Text>
            <Form.Text className="text-muted">
              <b>{resMessage && resMessage}</b>
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            // onClick={handleRefund}
            disabled={loading || resMessage}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ViewTicket;
