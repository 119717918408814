import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  getMarginOverride,
  updatePricingStrategy,
} from "../../../../services/vendor/vendor.service";
import UpdateButton from "./UpdateButton";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";

const ManageMarginOverride = ({ selectedItem }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const fetchData = useCallback(
    async (id) => {
      setLoading(true);
      const res = await getMarginOverride(id);
      if (res) {
        setLoading(false);
        setData(res);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  console.log(Data);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe && selectedItem?._id) {
      fetchData(selectedItem?._id);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?._id]);

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await updatePricingStrategy({
        id,
        override: Data,
      });
      console.log(res);
      if (res?.data?.message) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const tempArr = [...Data];
    tempArr[index][name] = value;
    setData(tempArr);
  };

  const handleIsCustome = (e, index) => {
    const { checked } = e.target;
    const tempArr = [...Data];
    tempArr[index]["isCustom"] = checked;
    setData(tempArr);
  };

  return (
    <>
      {loading ? (
        <div className=" my-2 mx-auto">Laoding.....</div>
      ) : (
        <div style={{ marginTop: "-16px" }} className="  d-flex flex-column ">
          {Data?.map((item, index) => (
            <div
              key={item._id}
              style={
                index === Data.length - 2
                  ? { paddingBottom: "140px !important" }
                  : {}
              }
              className=" border-grey px-3 py-2 d-flex justify-content-between align-center  "
            >
              <div className="d-flex justify-content-between align-center">
                <div style={{ width: "120px" }}>
                  <span>{item?.categoryName}</span>

                  <ToggleSwitch
                    checked={item?.isCustom}
                    onChange={(e) => {
                      handleIsCustome(e, index);
                    }}
                    Name={"isCustom" + index}
                  />
                </div>
                <div className=" d-flex flex-column gap-3">
                  <div
                    style={{ width: "160px" }}
                    className=" d-flex border-grey  border-radius-20 overflow-hidden "
                  >
                    <div className=" py-1 w-75 px-4 bg-dark-grey">MAP</div>
                    <input
                      className=" border-0 w-100  text-center  "
                      type="number"
                      name="mapPercentage"
                      value={item?.mapPercentage}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                  <div
                    style={{ width: "160px" }}
                    className=" d-flex border-grey  border-radius-20 overflow-hidden "
                  >
                    <div className=" w-100 px-2 py-1 w-75  bg-dark-grey">
                      N-MAP
                    </div>
                    <input
                      className=" border-0 w-100 text-center  "
                      type="number"
                      value={item?.nonMapPercentage}
                      name="nonMapPercentage"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                </div>
              </div>
              {console.log(item)}
              <div
                onClick={() =>
                  navigate(
                    `/admin/products?brand=${item?.brandId}&categoryId=${
                      item?.categoryId
                    }&CategoryName=${
                      item?.categoryName
                    }&brandName=${selectedItem?.fullName
                      .split(" " && "&")
                      .join(" ")}`
                  )
                }
                className=" c-pointer d-flex  border-left-2  justify-content-around align-center gap-5"
              >
                <div>
                  <div className=" font-weight-bold">Edit by SKU</div>
                  <div>Edits:23/59</div>
                </div>
                <IoIosArrowForward size={24} />
              </div>
            </div>
          ))}
          <UpdateButton
            brand={selectedItem?.fullName}
            loading={loading}
            onClick={() => handleUpdate(selectedItem?._id)}
          />
        </div>
      )}
    </>
  );
};

export default ManageMarginOverride;
