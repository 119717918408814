import moment from "moment";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateMarkFraud } from "../../../../actions/saleAnalysis";
import { Calculation } from "../../../../utils/Calculation";
import { genrateOrderInvice } from "../../../../actions/order";
import { getTotalCubicUnits } from "../../../../utils/Formula";

const OrderDetails = ({ SelectItem, setEdit, IsMount, setIsMount }) => {
  const dispatch = useDispatch();
  const [MarkAsOk, setMarkAsOk] = useState(false);

  const updateMarkAsok = async (e) => {
    setMarkAsOk(e.target.checked);
    const res = await dispatch(UpdateMarkFraud(SelectItem?._id));
    if (res) {
      setEdit({
        open: false,
        edit: "",
        title: "",
      });
    }
  };

  const handleGenrateInvoice = async (id) => {
    try {
      const res = dispatch(genrateOrderInvice(id));
      if (res) {
        setIsMount(!IsMount);
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="order-details py-4">
        <div className="px-3 ">
          <h3 className="font-weight-bold">Order Details</h3>

          <div className="product-details-conatiner mt-4">
            <div
              style={{
                flex: 2,
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <img width={"100%"} src={SelectItem?.mainImage} alt="product" />
            </div>
            <div style={{ flex: 3 }}>
              <h6>{SelectItem?.title}</h6>
              <p>SKU:{SelectItem?.modelNumber}</p>
              <p>Inventory:{SelectItem?.productId?.quantity}</p>
              <p>Qty:{SelectItem?.quantity}</p>
              <p>Unit Price: ${SelectItem?.itemPrice}</p>
            </div>
          </div>
          <div className="order-summary-container my-2">
            <h4 className=" text-left font-weight-bold mb-3">Order Summary</h4>

            <div>
              <div className=" d-flex justify-content-between item-center">
                <span>Order Subtotal:</span>
                <span>${Calculation(SelectItem, "OrderSubTotal")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Cashback Used:</span>
                <span>${SelectItem?.cashbackUsedPerItem}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Discount Applied:</span>
                <span>${SelectItem?.couponDiscount}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Shipping:</span>
                <span>${SelectItem?.shippingFee}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Tax:</span>
                <span>${SelectItem?.itemTax}</span>
              </div>
              <hr style={{ border: "1px solid black" }} />
              <div className=" d-flex justify-content-between item-center font-weight-bold">
                <span>Total</span>
                <span>${Calculation(SelectItem, "OrderTotal")}</span>
              </div>
              {SelectItem?.cashbackReceivedPerItem ? (
                <button className="cashback-earned mt-4">
                  Cashback Earned: $ {SelectItem?.cashbackReceivedPerItem}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="px-3">
          <div className="order-summary-container">
            <h3 className=" text-left font-weight-bold mb-4">
              Shipping & Billing Information
            </h3>

            <div>
              <h5>Shipping Address:</h5>
              <p>
                {" "}
                {SelectItem?.orderID?.shippingDetail?.address},
                {SelectItem?.orderID?.shippingDetail?.addressTwo
                  ? `${SelectItem?.orderID?.shippingDetail?.addressTwo},`
                  : ""}{" "}
                {SelectItem?.orderID?.shippingDetail?.city}{" "}
                {SelectItem?.orderID?.shippingDetail?.state}{" "}
                {SelectItem?.orderID?.shippingDetail?.postCode}{" "}
                {SelectItem?.orderID?.shippingDetail?.country}{" "}
              </p>
            </div>
            <div className=" my-1">
              <h5>Shipping Name:</h5>
              <p>
                {SelectItem?.orderID?.shippingDetail?.firstName}{" "}
                {SelectItem?.orderID?.shippingDetail?.lastName}
              </p>
            </div>
            <div className=" my-1">
              <h5>Shipping Phone:</h5>
              <p>{SelectItem?.orderID?.shippingDetail?.phoneNumber} </p>
            </div>

            {SelectItem?.orderID?.billingDetail ? (
              <div className="mt-4">
                <div>
                  <h5>Billing Address:</h5>
                  <p>
                    {" "}
                    {SelectItem?.orderID?.billingDetail?.address},
                    {SelectItem?.orderID?.billingDetail?.addressTwo
                      ? `${SelectItem?.orderID?.billingDetail?.addressTwo},`
                      : ""}{" "}
                    {SelectItem?.orderID?.billingDetail?.city}{" "}
                    {SelectItem?.orderID?.billingDetail?.state}{" "}
                    {SelectItem?.orderID?.billingDetail?.postCode}{" "}
                    {SelectItem?.orderID?.billingDetail?.country}{" "}
                  </p>
                </div>
                <div className=" my-1">
                  <h5>Billing Name:</h5>
                  <p>
                    {SelectItem?.orderID?.billingDetail?.firstName}{" "}
                    {SelectItem?.orderID?.billingDetail?.lastName}
                  </p>
                </div>
                <div className=" my-1">
                  <h5>Billing Phone:</h5>
                  <p>{SelectItem?.orderID?.billingDetail?.phoneNumber} </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="mt-4">
              <div>
                <h5>Account Email:</h5>
                <p>{SelectItem?.orderID?.user?.email} </p>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ border: "1px solid #cdcdcd" }} />

        {SelectItem?.orderID?.warnings?.length ? (
          <>
            <div className="px-3">
              <div className="px-3">
                <h3 className=" text-left font-weight-bold ">Fraud Alerts:</h3>

                {SelectItem?.orderID?.warnings?.map((item, i) => (
                  <Fragment key={i}>
                    {item !== null ? (
                      <p className=" text-danger">
                        <span>
                          {i + 1}: {item}
                        </span>
                        <br />
                      </p>
                    ) : (
                      <p className=" text-danger">
                        <span>{i + 1}: invalid credit card</span>
                      </p>
                    )}
                  </Fragment>
                ))}
                {!SelectItem?.orderID?.warnings.length && "N/A"}

                <div>
                  {SelectItem?.fraudVerify ? (
                    <h5 className=" my-1">Marked as Ok</h5>
                  ) : (
                    <>
                      <input
                        type="radio"
                        name="marksAsOk"
                        id="marksAsOk"
                        checked={MarkAsOk}
                        onChange={(e) => updateMarkAsok(e)}
                      />
                      <label
                        className=" mx-1 m-0 font-weight-bold h6"
                        htmlFor="marksAsOk"
                      >
                        Mark as ok
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr style={{ border: "1px solid #cdcdcd" }} />
          </>
        ) : (
          <></>
        )}
        <div className="px-3">
          <div className="order-summary-container">
            <h3 className=" text-left font-weight-bold mb-2">
              Provided Shipping & Tracking
            </h3>
            <div>
              <h5>Box ID:</h5>
              {SelectItem?.boxId?.split("-")?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
              <h5 className="mt-3">Total Cube:</h5>

              <p>{getTotalCubicUnits(SelectItem?.dimensions)}</p>

              {/* {console.log(SelectItem?.dimensions)} */}
            </div>

            <div className="my-3">
              <h5>Store Shipping & Tracking</h5>
              <div>
                <h5>Carrier:</h5>
                <p>{SelectItem?.companyName}</p>
              </div>
            </div>
            {SelectItem?.shippingInfo?.labels.length ? (
              <div className="my-3">
                <div>
                  <h4 className=" text-left font-weight-bold mb-2">
                    Recommended Shipping & Tracking
                  </h4>
                  <div>
                    <h5>Carrier:</h5>
                    <p>{SelectItem?.shippingInfo?.labels[0]?.companyName}</p>
                  </div>
                  <div className="mt-1">
                    <h5>Tracking Number: </h5>
                    {SelectItem?.shippingInfo?.labels?.map((item, i) => (
                      <p key={i}>{item?.trackingNumber}</p>
                    ))}
                  </div>
                  <div className="mt-1">
                    <h5>Est. Shipping Date:</h5>
                    <p>
                      {moment(SelectItem?.shippingInfo?.pickupDate).format("l")}
                    </p>
                  </div>
                  <div className="mt-1">
                    <h5>Shipping Type:</h5>
                    <p>
                      {" "}
                      {SelectItem?.shippingInfo?.shippingType
                        ? SelectItem?.shippingInfo?.shippingType
                        : "N/A"}
                    </p>
                  </div>

                  <div className="mt-1">
                    <h5>Insurance Quote Id:</h5>
                    <p>
                      {SelectItem?.shippingInfo?.labels[0]?.insuranceQuoteId
                        ? SelectItem?.shippingInfo?.labels[0]?.insuranceQuoteId
                        : "N/A"}
                    </p>
                  </div>

                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr className=" m-0" />
        <div className=" pb-3  px-3">
          <button
            className="cashback-earned mt-4"
            onClick={() =>
              !SelectItem?.orderInvoice
                ? handleGenrateInvoice(SelectItem?._id)
                : null
            }
          >
            <a
              href={SelectItem?.orderInvoice}
              target="_blank"
              rel="noopener noreferrer"
            >
              {SelectItem?.orderInvoice
                ? "Download Invoice"
                : "Genrate Invoice"}
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
