import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../../config/default";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { vendorProducts, deleteProduct } from "../../../actions/product";
import TopBarProgress from "react-topbar-progress-indicator";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

class Products extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: null,
      productsClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 50,
      totalPages: null,
      deleteId: null,
    };
  }

  async componentDidMount() {
    const brand = window.location.toString().split("vendor-products/")[1];
    const res = await this.props.vendorProducts(brand, this.state.currentPage);
    console.log(res);
    this.setState({
      products: res.products,
      productsClone: res.products,
      loading: false,
      totalPages: res.pagination?.totalPages || 0,
    });
  }

  changeStatus = async (producId, status) => {
    this.setState({ loading: true });
    await axios.post(`${apiURL}admin/change-product-status/${producId}`, {
      status,
    });
    const brand = window.location.toString().split("vendor-products/")[1];
    const res = await this.props.vendorProducts(brand, this.state.currentPage);
    console.log(res);
    this.setState({
      products: res.products,
      productsClone: res.products,
      loading: false,
      totalPages: res.pagination?.totalPages || 0,
    });
  };

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { products, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(products.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };
  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteProduct = async (id) => {
    await this.props.deleteProduct(id);
    const brand = window.location.toString().split("vendor-products/")[1];

    const res = await this.props.vendorProducts(brand, this.state.currentPage);
    this.setState({
      loading: false,
      deleteConfirm: false,
      products: res.products,
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.productsClone.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  handleClick = (event) => {
    this.setState(
      {
        currentPage: Number(event.page),
      },
      async () => {
        this.setState({ loading: true });
        const brand = window.location.toString().split("vendor-products/")[1];
        const res = await this.props.vendorProducts(
          brand,
          this.state.currentPage
        );
        this.setState({
          products: res.products,
          totalPages: res.pagination.totalPages,
          loading: false,
        });
      }
    );
  };

  render() {
    const { loading, products, currentPage, dataPerPage } = this.state;

    // const indexOfLastData = currentPage * dataPerPage;
    // const indexOfFirstData = indexOfLastData - dataPerPage;
    // const currentData =
    //   products && products.slice(indexOfFirstData, indexOfLastData);
    console.log("products.... delete test", products);
    const renderTodos =
      products &&
      products.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              {currentPage === 1
                ? index + 1
                : (currentPage - 1) * 100 + (index + 1)}
            </td>
            <td> {item.title} </td>
            <td> {item.modelNumber} </td>
            {/* <td> {item.status === true ? 'enable' : 'disable'} </td> */}
            <td
              onClick={() => {
                this.changeStatus(item._id, !item.status);
              }}
            >
              {item.status === true ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </td>
            <td>
              <img
                src={item.mainImage && item.mainImage}
                alt={item.modelNumber}
              />
            </td>
            <td>
              <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
            </td>
            <td>
              <Link to={`/admin/edit-product/?id=${item._id}`}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm mr-4"
                  style={{ width: 78 }}
                >
                  Edit
                </button>
              </Link>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={(e) => {
                  console.log(item._id);
                  this.setState({ deleteId: item._id });
                  this.setState({ deleteConfirm: true });
                }}
              >
                Delete
              </button>

              {this.state.deleteConfirm && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  title="Are you sure?"
                  onConfirm={() => {
                    this._deleteProduct(this.state.deleteId);
                  }}
                  onCancel={this._onCancel}
                  focusCancelBtn
                >
                  Are you sure you want to delete?
                </SweetAlert>
              )}
            </td>
          </tr>
        );
      });
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(products && products.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    // eslint-disable-next-line no-unused-vars
    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number, index) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });
    // const { loading, products } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {products && (
          <>
            <div className="page-header">
              <h3 className="page-title" style={{ fontSize: 30 }}>
                {" "}
                View All Products
              </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/products">Products</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div
                  className="card p0"
                  style={{
                    border: "1px solid #dcdcdc",
                  }}
                >
                  <div className="card-body p-0">
                    <h4
                      className="card-title"
                      style={{
                        color: "#4c4d5a",
                        borderBottom: "1px solid #dcdcdc",
                        background: "#f6f6f6",
                        textShadow: "0 -1px 0 rgba(50,50,50,0)",
                        padding: 12,
                      }}
                    >
                      Products List
                    </h4>
                    <span
                      className="twitter-typeahead m-2"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      <i
                        className="icon-magnifier magnifier-icon"
                        style={{
                          position: "absolute",
                          top: "15px",
                          left: "10px",
                        }}
                      ></i>
                      <input
                        className="typeahead form-control tt-input"
                        type="text"
                        placeholder="Search..."
                        autoComplete="off"
                        spellCheck="false"
                        dir="auto"
                        style={{
                          position: "relative",
                          verticalAlign: "top",
                          backgroundColor: "transparent",
                          borderColor: "green",
                          color: "black",
                          padding: "10px 40px",
                        }}
                        onKeyDown={this._handleKeyDown}
                        onChange={this._onChangeHandler}
                      />
                    </span>
                    <div className="p-2">
                      <div className="table-responsive table-bordered">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                #{" "}
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Name{" "}
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Model Number{" "}
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Status{" "}
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Image
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Created At{" "}
                              </th>
                              <th
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {" "}
                                Action{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products && products.length === 0 ? (
                              <tr className="text-center">
                                <td colSpan="6">No main products found</td>
                              </tr>
                            ) : (
                              renderTodos
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.state.totalPages && (
                        // <MemoryRouter
                        //   initialEntries={["/inbox"]}
                        //   initialIndex={0}
                        // >
                        //   <Route>
                        //     {({ location }) => {
                        //       return (
                        <Pagination
                          page={
                            this.state.currentPage && this.state.currentPage
                          }
                          count={this.state.totalPages}
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              onClick={(e) => this.handleClick(item)}
                            />
                          )}
                        />
                        //       );
                        //     }}
                        //   </Route>
                        // </MemoryRouter>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

vendorProducts.propTypes = {
  vendorProducts: PropTypes.func.isRequired,
};

deleteProduct.propTypes = {
  deleteProduct: PropTypes.func.isRequired,
};

export default connect(null, { vendorProducts, deleteProduct })(Products);
