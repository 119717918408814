import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = (formData) => async (dispatch) => {
  // const _config = {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/create-sub-category`,
      formData
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/sub-category/${id}`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const edit = (formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/edit-sub-category`, formData);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const subCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiURL}admin/sub-categories`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const changeStatus = async (id, status) => {
  try {
    const res = await axios.get(`${apiURL}header/change-status`, {
      params: {
        id,
        status,
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getCronJobs = async () => {
  try {
    const res = await axios.get(`${apiURL}admin/setting`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const initCronJob = async (data, id = "") => {
  try {
    const res = await axios.post(`${apiURL}admin/setting/${id}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteSubCategory = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${apiURL}admin/delete-sub-category/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const subCategoryNames = () => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}header/get-all-category`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
