/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./pricing-sidebar.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import InfoItem from "./pricing-side-components/info-item";
import PricingSidebarCard from "./pricing-side-components/pricing-sidebar-card";
import CardInfoItems from "./pricing-side-components/card-info-items";
import PriceSummaryDetails from "./pricing-side-components/price-summary-Details";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import { apiURL } from "../../../../../config/default";

const PricingSidebar = ({ selectedItemBrandId, selectedProductInfo }) => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandCard, setExpandCard] = useState(false);

  const getSingleItem = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiURL}admin/crawlers/${selectedItemBrandId}`
      );
      setItemData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const moveToDynamicPricing = () => {
    navigate(
      `/admin/dynamic-price-history?id=${selectedProductInfo?.productId?._id}`
    );
  };

  useEffect(() => {
    getSingleItem();
  }, [selectedItemBrandId]);

  const sortedArray = itemData?.scrapDetails
    ?.slice() // Create a copy to avoid mutating the original array
    ?.sort((a, b) => a.itemTotal - b.itemTotal);

  const sundialIndex = sortedArray?.findIndex(
    (item) => item?.brandName === "Sundial Home"
  );

  const toggleToExpand = () => {
    setExpandCard(!expandCard);
  };

  return loading ? (
    <div class="spinner-border m-5 m-auto" role="status"></div>
  ) : (
    <div className={styles.mainContainer}>
      <div className={styles.priceTitleBox}>
        <h3 className={styles.priceTitleBoxHeading}>Dynamic Pricing</h3>
        <button className={styles.customButton} onClick={moveToDynamicPricing}>
          Price History
          <span className={styles.arrowIcon}>
            <IoIosArrowForward />
          </span>{" "}
          {/* Arrow icon */}
        </button>
      </div>

      <div className={styles.productContainer}>
        <img
          src={selectedProductInfo?.productId?.mainImage}
          alt={selectedProductInfo?.name}
          className={styles.productImage}
        />
        <p className={styles.productDescription}>{selectedProductInfo?.name}</p>
      </div>

      {/* Product Details */}
      <div className={styles.productDetails}>
        <h3 className={styles.subHeading}>Product Details</h3>
        {/* <InfoItem
          crawlType="Batch Date:"
          CrawlDescription={moment
            .utc(selectedProductInfo?.crawlReport?.crawlerDateTime)
            .format("MMM DD,YYYY")}
        /> */}
        <InfoItem
          crawlType="Batch No:"
          CrawlDescription={selectedProductInfo?.batchNo}
        />
        <InfoItem
          crawlType="Manufacturer:"
          CrawlDescription={selectedProductInfo?.productId?.manufacturer}
        />

        <InfoItem
          crawlType="SKU:"
          CrawlDescription={selectedProductInfo?.productId?.sku}
        />
        <InfoItem crawlType="Model Number:" CrawlDescription="23" />
        <InfoItem
          crawlType="Inventory"
          CrawlDescription={selectedProductInfo?.productId?.quantity}
        />
        <InfoItem
          crawlType="Seller Diversity"
          CrawlDescription={selectedProductInfo?.productId?.modelNumber}
        />
        <InfoItem
          crawlType="Valid Link"
          CrawlDescription={selectedProductInfo?.crawlReport?.validUrl}
          CrawlDescription={selectedProductInfo?.crawlReport?.validUrl}
        />
      </div>

      {/* Break Even Price Overview */}
      <div className={styles.priceSummary}>
        <h3 className={styles.overViewHeading}>Break Even Price Overview</h3>
        <div className={styles.variableCostContainer}>
          <h3 className={styles.variableCost}>Total Variable Cost</h3>
          <p></p>
        </div>

        <InfoItem crawlType="Variable CC Fee" CrawlDescription="" />
        <InfoItem crawlType="Variable Insurance Fee" CrawlDescription="" />

        <div></div>
        <h3 className={styles.fixedCost}>Fixed Cost</h3>

        <InfoItem
          crawlType="Item Cost:"
          CrawlDescription={`${
            itemData?.productId?.cost ? `$${itemData?.productId?.cost}` : ""
          }`}
        />
        <InfoItem
          crawlType="Promo Cost:"
          CrawlDescription={`${
            itemData?.productId?.promoCost
              ? `$${itemData?.productId?.promoCost}`
              : ""
          }`}
        />
        <InfoItem
          crawlType="Promo Period:"
          CrawlDescription={
            (itemData?.productId?.promoCostStartDate
              ? moment
                  .utc(itemData?.productId?.promoCostStartDate)
                  .format("MMM DD,YYYY")
              : "") +
            (itemData?.productId?.promoCostStartDate &&
            itemData?.productId?.promoCostEndDate
              ? " - "
              : "") +
            (itemData?.productId?.promoCostEndDate
              ? moment
                  .utc(itemData?.productId?.promoCostEndDate)
                  .format("MMM DD,YYYY")
              : "")
          }
        />
        <InfoItem crawlType="Shipping Cost:" CrawlDescription="" />
        <InfoItem
          crawlType="DS Fee 1:"
          CrawlDescription={`${
            itemData?.productId?.dropshipFeeOne
              ? `$${itemData?.productId?.dropshipFeeOne}`
              : ""
          }`}
        />
        <InfoItem
          crawlType="DS Fee 2:"
          CrawlDescription={`${
            itemData?.productId?.dropshipFeeTwo
              ? `$${itemData?.productId?.dropshipFeeTwo}`
              : ""
          }`}
        />
        <InfoItem
          crawlType="Quantity:"
          CrawlDescription={`${itemData?.productId?.quantity ?? ""}`}
        />

        <div className={styles.priceOverViewContainer}>
          <h3 className={styles.priceOverTotal}>Total Variable Cost</h3>
          <p className={styles.priceOverViewDesc}></p>
        </div>
      </div>
      <div className={`${styles.card} ${!expandCard ? styles.cardHidden : ""}`}>
        <div className={styles.pricingSummary}>
          <h3 className={styles.pricingSummaryHeading}>Pricing Summary:</h3>
          <button
            onClick={toggleToExpand}
            style={{
              border: "none",
              marginLeft: "0",
              textDecoration: "underline",
              fontWeight: "bold",
              backgroundColor: "white",
            }}
          >
            Collapse all
            <span style={{ marginLeft: ".3rem" }}>
              <IoIosArrowUp />
            </span>{" "}
          </button>
        </div>

        {sortedArray
          ?.filter((item) => item?.brandName === "Sundial Home")
          ?.map((item, index) => (
            <div key={index} className={styles.sundialContainer}>
              <div className={styles.sundialHome}>
                <h3 className={styles.sundialHomeTitle}>{item?.brandName}</h3>
                <p>Rank: {sundialIndex !== -1 ? sundialIndex + 1 : "N/A"}</p>
              </div>
              <InfoItem crawlType="Sundial DKP:" CrawlDescription="" />
              <InfoItem crawlType="Margin:" CrawlDescription="" />
            </div>
          ))}
        {/* <div className={styles.ebayContainer}>
          <PricingSidebarCard
            sectionHeading="Ebay"
            rank="3"
            sellerFinalPrice="$2000.0"
            priceMatch="$2452.69"
            breakEvenPrice="$664.77"
            validSeller="Invalid"
          />
          <CardInfoItems
            itemPrice="$2452.69"
            discountId="7.59%"
            parentDiscountId="$245.88"
            discountValue="7.59%"
            sundialDKP={"N/A"}
            marginDollar={"N/A"}
            marginPrevent={"N/A"}
          />
        </div> */}

        {itemData?.scrapDetails
          ?.sort((a, b) => a.itemTotal - b.itemTotal) // Sort the array based on itemTotal
          .map((item, i) => (
            <div className={styles.ebayContainer} key={i}>
              <PricingSidebarCard
                sectionHeading={`${item.brandName}`}
                rank={i + 1}
                sellerFinalPrice={`$${item?.itemTotal}`}
                priceMatch="$2452.69"
                breakEvenPrice="$664.77"
                validSeller="Invalid"
              />
              <CardInfoItems
                itemPrice="$2452.69"
                discountId="7.59%"
                parentDiscountId="$245.88"
                discountValue="7.59%"
                sundialDKP={"N/A"}
                marginDollar={"N/A"}
                marginPrevent={"N/A"}
              />
            </div>
          ))}

        {/* {itemData?.scrapDetails?.map((item, i) => (
          <div className={styles.pricingContainer}>
            <PricingSidebarCard
              sectionHeading={`${item.brandName}`}
              rank="3"
              sellerFinalPrice="$2000.0"
              priceMatch="$2452.69"
              breakEvenPrice="$664.77"
              validSeller="Invalid"
            />
            <CardInfoItems
              itemPrice="$2452.69"
              discountId="7.59%"
              parentDiscountId="Deco _Apply MERRY25_25% OFF"
              discountValue="7.59%"
              sundialDKP={"N/A"}
              marginDollar={"N/A"}
              marginPrevent={"N/A"}
            />
          </div>
        ))} */}

        {/* <div className={styles.pricingContainer}>
          <PricingSidebarCard
            sectionHeading="Ebay"
            rank="3"
            sellerFinalPrice="$2000.0"
            priceMatch="$2452.69"
            breakEvenPrice="$664.77"
            validSeller="Invalid"
          />
          <CardInfoItems
            itemPrice="$2452.69"
            discountId="7.59%"
            parentDiscountId="$245.88"
            discountValue="7.59%"
            sundialDKP={"N/A"}
            marginDollar={"N/A"}
            marginPrevent={"N/A"}
          />
        </div> */}
      </div>
      <div className={`${styles.card} ${expandCard ? styles.cardHidden : ""}`}>
        <PriceSummaryDetails itemData={itemData} onClick={toggleToExpand} />
      </div>
    </div>
  );
};

export default PricingSidebar;
