/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";
import { toBase64 } from "../../../utils/toBase64";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { retreive, edit } from "../../../actions/product";
import { usersRetreive } from "../../../actions/user";
import { subCategoryNames } from "../../../actions/subCategory";
import { getStores } from "../../../actions/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../../components/alert/alert.component";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import { withRouter } from "../../../utils/withRouter";
import { toISOStringFromat } from "../../../utils/dateFormate";

const checkIconStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "green",
  borderRadius: "50%",
  overflow: "hidden",
  color: "#fff",
  fontSize: "1.8rem",
  cursor: "pointer",
};

const animatedComponents = makeAnimated();
const specOptions = [
  { value: "Shape", label: "Shape", color: "#00B8D9", isFixed: true },
  {
    value: "Item Length (in)",
    label: "Item Length (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Item Color", label: "Item Color", color: "#00B8D9", isFixed: true },
  {
    value: "Material Type",
    label: "Material Type",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Weight (lbs)",
    label: "Item Weight (lbs)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Sales UOM", label: "Sales UOM", color: "#00B8D9", isFixed: true },
  { value: "Finish", label: "Finish", color: "#00B8D9", isFixed: true },
  { value: "Style", label: "Style", color: "#00B8D9", isFixed: true },
  {
    value: "Installation Type",
    label: "Installation Type",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Height (in)",
    label: "Item Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Center to center (in)",
    label: "Item Center to center (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Diameter (in)",
    label: "Item Diameter (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Overall Height Range (in)",
    label: "Item Overall Height Range (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Overall Width Range (in)",
    label: "Item Overall Width Range (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Overall Depth Range (in)",
    label: "Item Overall Depth Range (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Center to Center Range (in)",
    label: "Item Center to Center Range (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Overall Diameter Range (in)",
    label: "Item Overall Diameter Range (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Clearance (in)",
    label: "Clearance (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Foot Width (in)",
    label: "Item Foot Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Minimum Cabinet Opening (in)",
    label: "Minimum Cabinet Opening (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Hinge Overlay",
    label: "Hinge Overlay",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Hinge Degree of Opening",
    label: "Hinge Degree of Opening",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Soft Closing (Yes/No)",
    label: "Soft Closing (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Self Closing (Yes/No)",
    label: "Self Closing (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "With or Without Screws",
    label: "With or Without Screws",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Sink Shape", label: "Sink Shape", color: "#00B8D9", isFixed: true },
  {
    value: "Drain Placement",
    label: "Drain Placement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Coordinating Mirrors for Vanity",
    label: "Coordinating Mirrors for Vanity",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Size", label: "Size", color: "#00B8D9", isFixed: true },
  {
    value: "Collection Name",
    label: "Collection Name",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Product Helpful Tips",
    label: "Product Helpful Tips",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "LED (Yes/No)",
    label: "LED (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade Shape",
    label: "Shade Shape",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade/Glass Materials",
    label: "Shade/Glass Materials",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Switch Type",
    label: "Switch Type",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Voltage", label: "Voltage", color: "#00B8D9", isFixed: true },
  {
    value: "Assembly Required (Yes/No)",
    label: "Assembly Required (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Type 1",
    label: "Bulb Type 1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Dimmable (Yes/No)",
    label: "Dimmable (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Wattage 1",
    label: "Bulb Wattage 1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Proposition 65 Compliance (Yes/No)",
    label: "Proposition 65 Compliance (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Tabletop Thickness (in)",
    label: "Tabletop Thickness (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Upholstery Material",
    label: "Upholstery Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Backing Material",
    label: "Backing Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Construction",
    label: "Item Construction",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Adjustable Height (in)",
    label: "Adjustable Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Arm Color", label: "Arm Color", color: "#00B8D9", isFixed: true },
  {
    value: "Arm Material",
    label: "Arm Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Arms (Yes/No)",
    label: "Arms (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Back Cushion Fill Material",
    label: "Back Cushion Fill Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Back Height (in)",
    label: "Back Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Beveled Glass (Yes/No)",
    label: "Beveled Glass (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Included 1 (Yes/No)",
    label: "Bulb Included 1 (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Cleaning Code",
    label: "Cleaning Code",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Closure Type",
    label: "Closure Type",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Composite Wood Product (Yes/No)",
    label: "Composite Wood Product (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Certified Wet or Damp",
    label: "Certified Wet or Damp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Depth (in)",
    label: "Item Depth (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Distressed Finish (Yes/No)",
    label: "Distressed Finish (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Drawer Count",
    label: "Drawer Count",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Feet Material",
    label: "Feet Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Fill Material",
    label: "Fill Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Footrest (Yes/No)",
    label: "Footrest (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Footrest Distance to Seat (in)",
    label: "Footrest Distance to Seat (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Frame Color",
    label: "Frame Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Frame Material Variety",
    label: "Frame Material Variety",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Gloss Level",
    label: "Gloss Level",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Headboard (Yes/No)",
    label: "Furniture Headboard (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Headrest Included (Yes/No)",
    label: "Headrest Included (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Leg Glides (Yes/No)",
    label: "Leg Glides (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Lumbar Support (Yes/No)",
    label: "Lumbar Support (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Mirror Shape",
    label: "Mirror Shape",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Outdoor Use (Yes/No)",
    label: "Outdoor Use (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Paint Type", label: "Paint Type", color: "#00B8D9", isFixed: true },
  {
    value: "Product has a back (Yes/No)",
    label: "Product has a back (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Reclaimed Wood (Yes/No)",
    label: "Reclaimed Wood (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Recycled Content (Yes/No)",
    label: "Recycled Content (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Removable Cushions (Yes/No)",
    label: "Removable Cushions (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Reverse Side Color",
    label: "Reverse Side Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Reverse Side Pattern",
    label: "Reverse Side Pattern",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Reversible Cushions (Yes/No)",
    label: "Reversible Cushions (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Rub Count", label: "Rub Count", color: "#00B8D9", isFixed: true },
  {
    value: "Seat Cushion Fill Material",
    label: "Seat Cushion Fill Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seat Depth (in)",
    label: "Seat Depth (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seat Height (in)",
    label: "Seat Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seat Material",
    label: "Seat Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seat Material Variety",
    label: "Seat Material Variety",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seat Width (in)",
    label: "Seat Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Seating Capacity",
    label: "Seating Capacity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Stain Resistant (Yes/No)",
    label: "Stain Resistant (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Swivel (Yes/No)",
    label: "Swivel (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Upholstered (Yes/No)",
    label: "Upholstered (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Upholstery Color",
    label: "Upholstery Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Upholstery Fill Material",
    label: "Upholstery Fill Material",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Weight Capacity (lbs)",
    label: "Weight Capacity (lbs)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Wood Finish",
    label: "Wood Finish",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Width (in)",
    label: "Item Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Lamp Base Dimensions (in)",
    label: "Lamp Base Dimensions (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Cord Color", label: "Cord Color", color: "#00B8D9", isFixed: true },
  {
    value: "Cord Length (in)",
    label: "Cord Length (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Mirror Width (in)",
    label: "Mirror Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Mirror Height (in)",
    label: "Mirror Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Build-in Outlet (Yes/No)",
    label: "Build-in Outlet (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Chain Length (in)",
    label: "Chain Length (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Headboard Height (in)",
    label: "Headboard Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Headboard Width (in)",
    label: "Headboard Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade/Glass Height (in)",
    label: "Shade/Glass Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade/Glass Width (in)",
    label: "Shade/Glass Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "ADA Compliant (Yes/No)",
    label: "ADA Compliant (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Eco Friendly (Yes/No)",
    label: "Eco Friendly (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Water Resistant (Yes/No)",
    label: "Water Resistant (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Water Absorption",
    label: "Water Absorption",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Abrasion Resistance (Yes/No)",
    label: "Abrasion Resistance (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Freeze Resistance (Yes/No)",
    label: "Freeze Resistance (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Weather Resistant (Yes/No)",
    label: "Weather Resistant (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Mildew Resistant (Yes/No)",
    label: "Mildew Resistant (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Hypoallergenic (Yes/No)",
    label: "Hypoallergenic (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Moisture Wicking (Yes/No)",
    label: "Moisture Wicking (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Reversible (Yes/No)",
    label: "Item Reversible (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Removable Cover (Yes/No)",
    label: "Removable Cover (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Embroidered (Yes/No)",
    label: "Item Embroidered (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Applique (Yes/No)",
    label: "Item Applique (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Buttons (Yes/No)",
    label: "Item Buttons (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Sequined (Yes/No)",
    label: "Item Sequined (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Tassels Included (Yes/No)",
    label: "Item Tassels Included (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Collection Description",
    label: "Collection Description",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Color Family",
    label: "Color Family",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Pattern",
    label: "Item Pattern",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Flange Color",
    label: "Flange Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Tassel Color",
    label: "Tassel Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Tassel Type",
    label: "Tassel Type",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Piping Color",
    label: "Piping Color",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Technique", label: "Technique", color: "#00B8D9", isFixed: true },
  {
    value: "Finish Group",
    label: "Finish Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Material Type Front",
    label: "Material Type Front",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Material Type Back",
    label: "Material Type Back",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Border",
    label: "Item Border",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Adjustable (Yes/No)",
    label: "Item Adjustable (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Set of", label: "Set of", color: "#00B8D9", isFixed: true },
  {
    value: "Item Size Group",
    label: "Item Size Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Dimensions (L x W x H)",
    label: "Item Dimensions (L x W x H)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Pile Type", label: "Pile Type", color: "#00B8D9", isFixed: true },
  {
    value: "Pile Height (in)",
    label: "Pile Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Thickness (in)",
    label: "Item Thickness (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Fringe (Yes/No)",
    label: "Item Fringe (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Fringe Type",
    label: "Item Fringe Type",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Item Projection (in)",
    label: "Item Projection (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Recommended Screws",
    label: "Recommended Screws",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Indoor Use (Yes/No)",
    label: "Indoor Use (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Sink Back to Front Dimension",
    label: "Sink Back to Front Dimension",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Sink Bowl Depth",
    label: "Sink Bowl Depth",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Gauge", label: "Gauge", color: "#00B8D9", isFixed: true },
  {
    value: "Overflow Drain (Yes/No)",
    label: "Overflow Drain (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Spout Reach Width (in)",
    label: "Spout Reach Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Size Class", label: "Size Class", color: "#00B8D9", isFixed: true },
  {
    value: "Top and Bowl Pre-Installed? (Yes/No)",
    label: "Top and Bowl Pre-Installed? (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Spout Reach Height (in)",
    label: "Spout Reach Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Water Flow Rate (GPM)",
    label: "Water Flow Rate (GPM)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Pullout Sprayer (Yes/No)",
    label: "Pullout Sprayer (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Sensor Activated (Yes/No)",
    label: "Sensor Activated (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Number of Handles",
    label: "Number of Handles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Handle Style",
    label: "Handle Style",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Number of Faucet Holes Required",
    label: "Number of Faucet Holes Required",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Water Flow Controls",
    label: "Water Flow Controls",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Valve Type", label: "Valve Type", color: "#00B8D9", isFixed: true },
  {
    value: "Bulb Count 1",
    label: "Bulb Count 1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Base 1",
    label: "Bulb Base 1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Count 2",
    label: "Bulb Count 2",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Wattage 2",
    label: "Bulb Wattage 2",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Type 2",
    label: "Bulb Type 2",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Base 2",
    label: "Bulb Base 2",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Bulb Included 2 (Yes/No)",
    label: "Bulb Included 2 (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Total Lumens",
    label: "Total Lumens",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Color Temperature",
    label: "Color Temperature",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Color Rendering Index",
    label: "Color Rendering Index",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Backplate/Canopy Dimensions (in)",
    label: "Backplate/Canopy Dimensions (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Extension Rods (in)",
    label: "Extension Rods (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade/Glass Description",
    label: "Shade/Glass Description",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Shade/Glass Finish",
    label: "Shade/Glass Finish",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Harp/Spider",
    label: "Harp/Spider",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Print Process",
    label: "Print Process",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Printed On", label: "Printed On", color: "#00B8D9", isFixed: true },
  {
    value: "Hang Orientation",
    label: "Hang Orientation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Hang Hardware",
    label: "Hang Hardware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Stackable (Yes/No)",
    label: "Furniture Stackable (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Legs (Yes/No)",
    label: "Furniture Legs (Yes/No)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Tempered Glass",
    label: "Tempered Glass",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Headboard Length (in)",
    label: "Headboard Length (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Recommended Cabinet Size",
    label: "Recommended Cabinet Size",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Hinge Crank Amount",
    label: "Hinge Crank Amount",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Arm Height (in)",
    label: "Furniture Arm Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Arm Width (in)",
    label: "Furniture Arm Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Furniture Arm Depth (in)",
    label: "Furniture Arm Depth (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Edge Treatment",
    label: "Edge Treatment",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Nominal Size",
    label: "Nominal Size",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Grout Joint Recommendation (in)",
    label: "Grout Joint Recommendation (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Tile Thickness (in)",
    label: "Tile Thickness (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Case Length (in)",
    label: "Case Length (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Case Width (in)",
    label: "Case Width (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Case Height (in)",
    label: "Case Height (in)",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "Case UPC", label: "Case UPC", color: "#00B8D9", isFixed: true },
  {
    value: "Square Ft. per Piece",
    label: "Square Ft. per Piece",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "LB/PC", label: "LB/PC", color: "#00B8D9", isFixed: true },
  { value: "PC/CT", label: "PC/CT", color: "#00B8D9", isFixed: true },
  { value: "SF/CT", label: "SF/CT", color: "#00B8D9", isFixed: true },
  { value: "LB/CT", label: "LB/CT", color: "#00B8D9", isFixed: true },
  { value: "LF/CT", label: "LF/CT", color: "#00B8D9", isFixed: true },
];
const createOption = (label) => ({
  value: label.toLowerCase().replace(/\W/g, ""),
  label: label.toLowerCase().replace(/\W/g, ""),
  color: "#00B8D9",
  isFixed: true,
});
class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      image: "",
      title: "",
      brand: "",
      minimumQuantity: "",
      modelNumber: "",
      salePrice: 0,
      weightAndDimension: [{ name: "", value: "" }],
      specification: [{ name: "", value: "" }],
      tag: [],
      attribute: [{ color: "", size: "", price: 0, image: "" }],
      images: [],
      model: "",
      sku: "",
      quantity: 0,
      outOfStock: 0,
      slug: "",
      requireShipping: 0,
      status: 0,
      manufacturer: "",
      category: [],
      vendor: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      getStores: [],
      subCategories: [],
      defaultSlectedCategories: [],
      usersRetreive: [],
      upc: "",
      sortOrder: 0,
      weight: 0,
      weigthClass: "",
      lengthClass: "",
      enableSale: false,
      saleDiscount: 0,
      salteEndDate: "",
      width: 0,
      height: 0,
      length: 0,
      options: specOptions,
      cost: 0,
      isMainImageChanged: false,
      overrideEtl: 0,
      product: {},
      additionalPrice: 0,
      promoCostEndDate: "",
      promoCostStartDate: "",
      promoPriceEndDate: "",
      promoPriceStartDate: "",
      promoCost: 0,
      promoPrice: 0,
      specialNote: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.retreive(id);
    const categories = await this.props.subCategoryNames();
    const updatedArrayForReactSelect = categories?.map((cat) => {
      return {
        ...cat,
        value: cat.name,
        label: cat.name,
      };
    });

    this.setState({
      subCategories: updatedArrayForReactSelect.filter((x) => x._id),
      product: res.data,
    });

    const metaTagDataConvert = [];
    const metaKeywordDataConvert = [];

    res.data.metaTagKeyword &&
      res.data.metaTagKeyword.forEach((_element) => {
        metaKeywordDataConvert.push({ value: _element, label: _element });
      });

    const seoKeywordDataConvert = [];

    res.data.seoKeyword &&
      res.data.seoKeyword.forEach((element) => {
        seoKeywordDataConvert.push({ value: element, label: element });
      });
    const tagData = [];
    if (res.data.tag && res.data.tag.tag && Array.isArray(res.data.tag.tag)) {
      res.data.tag &&
        res.data.tag.tag &&
        res.data.tag.tag.forEach((element) => {
          tagData.push({ value: element, label: element });
        });
    } else {
      tagData.push({ value: res?.tag?.tag ?? "", label: res?.tag?.tag ?? "" });
    }

    const filteredArr = this.state.subCategories?.filter((x, i) => {
      return res?.data?.category.includes(x._id);
    });

    this.setState({
      defaultSlectedCategories: filteredArr,
      loading: false,
      metaTag: metaTagDataConvert,
      title: res.data.title,
      brand: res.data.brand,
      slug: res.data.slug,
      minimumQuantity: res.data.minimumQuantity,
      modelNumber: res.data.modelNumber,
      salePrice: res.data.salePrice,
      weightAndDimension: this.json2array(
        res?.weightAndDimension?.weightAndDimension ?? []
      ),
      promoCostEndDate: res?.data?.promoCostEndDate,
      additionalPrice: res?.data?.additionalPrice,
      specialNote: res?.data?.specialNote,
      promoCostStartDate: res?.data?.promoCostStartDate,
      promoPriceEndDate: res?.data?.promoPriceEndDate,
      promoPriceStartDate: res?.data?.promoPriceStartDate,
      promoCost: res?.data?.promoCost,
      promoPrice: res?.data?.promoPrice,
      specification: this.json2array(res?.specification?.specification ?? []),
      tag: tagData,
      cost: res.data.cost,
      images: res.data.productImages ?? [],
      mainImage: res.data.mainImage,
      model: res.data.model,
      sku: res.data.sku,
      quantity: res.data.quantity,
      outOfStock: res.data.outOfStock,
      requireShipping: res.data.requireShipping,
      status: res.data.status,
      overrideEtl: res.data.overrideEtl,
      manufacturer: res.data.manufacturer,
      category: res.data.category,
      vendor: res.data.vendor,
      description: res.data.description,
      metaDescription: res.data.metaTagDescription,
      metaKeyword: metaKeywordDataConvert,
      seoKeyword: seoKeywordDataConvert,
      upc: res.data.upc,
      sortOrder: res.data.sortOrder,
      length: res.data.length,
      weigthClass: res.data.weigthClass,
      lengthClass: res.data.lengthClass,
      enableSale: res.data.enableSale,
      saleDiscount: res.data.saleDiscount,
      weight: res.data.weight,
      width: res.data.width,
      height: res.data.height,
      salteEndDate: res.data.salteEndDate
        ? moment(res.data.salteEndDate).toDate()
        : moment().toDate(),
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  json2array(json) {
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function (key) {
      result.push(JSON.parse(json[key]));
    });
    return result;
  }
  _submitHandler = async () => {
    const {
      title,
      brand,
      slug,
      modelNumber,
      description,
      salePrice,
      weightAndDimension,
      specification,
      tag,
      attribute,
      images,
      minimumQuantity,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      status,
      manufacturer,
      category,
      vendor,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      upc,
      sortOrder,
      weight,
      weigthClass,
      lengthClass,
      enableSale,
      saleDiscount,
      salteEndDate,
      width,
      height,
      length,
      cost,
      overrideEtl,
      mainImage,
      isMainImageChanged,
      additionalPrice,
      specialNote,
    } = this.state;
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const metaTagData = [];

    metaTag.forEach((element) => {
      metaTagData.push(element.value);
    });
    const productTag = [];

    tag.forEach((element) => {
      productTag.push(element.value);
    });

    const metaKeywordData = [];

    metaKeyword.forEach((_element) => {
      metaKeywordData.push(_element.value);
    });

    const seoKeywordData = [];

    seoKeyword.forEach((element) => {
      seoKeywordData.push(element.value);
    });

    const res = await this.props.edit({
      title: title,
      slug: slug,
      salePrice: salePrice,
      cost: cost,
      category: category,
      status: status,
      quantity: quantity,
      overrideEtl: overrideEtl,
      productImages: images,
      isMainImageChanged,
      mainImage,
      additionalPrice,
      specialNote,
    });
    if (res?.status === 200) {
      this.setState({
        isMainImageChanged: false,
      });
    }
    this.setState({ loading: false });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let file = Array.from(e.target.files);

    file.forEach(async (element, index) => {
      // let reader = new FileReader();
      let f = e.target.files[index];

      // reader.onloadend = () => {
      // };
      try {
        const image = await toBase64(f);
        this.setState({ images: [...this.state.images, { image }] });
      } catch (err) {
        alert("something went wrong!");
        console.log(err);
      }
      // const imageUrl = reader.readAsDataURL(f);
    });
  }
  handleChangeMainImage = (index) => {
    const image = this.state.images[index].image;

    this.setState(
      {
        mainImage: image,
      },
      () => this.setState({ isMainImageChanged: true })
    );
  };

  handleCategoryChange = (e) => {
    const ids = e?.map((cat) => cat._id);
    this.setState({
      category: [...(ids ?? [])],
      defaultSlectedCategories: [...(e ?? [])],
    });
  };

  // weight and dimensions
  handleWeightAndDimensionNameChange = (idx) => (evt) => {
    const newWeightAndDimension = this.state.weightAndDimension.map(
      (weightAndDimension, sidx) => {
        if (idx !== sidx) return weightAndDimension;
        return { ...weightAndDimension, name: evt.target.value };
      }
    );

    this.setState({ weightAndDimension: newWeightAndDimension });
  };
  handleWeightAndDimensionValueChange = (idx) => (evt) => {
    const newWeightAndDimension = this.state.weightAndDimension.map(
      (weightAndDimension, sidx) => {
        if (idx !== sidx) return weightAndDimension;
        return { ...weightAndDimension, value: evt.target.value };
      }
    );

    this.setState({ weightAndDimension: newWeightAndDimension });
  };

  handleAddWeightAndDimension = () => {
    this.setState({
      weightAndDimension: this.state.weightAndDimension.concat([
        { name: "", value: "" },
      ]),
    });
  };

  handleRemoveWeightAndDimension = (idx) => () => {
    this.setState({
      weightAndDimension: this.state.weightAndDimension.filter(
        (s, sidx) => idx !== sidx
      ),
    });
  };

  //specifications
  handleSpecificationNameChange = (idx) => (evt) => {
    const newSpecification = this.state.specification.map(
      (specification, sidx) => {
        if (idx !== sidx) return specification;
        return { ...specification, name: evt.target.value };
      }
    );

    this.setState({ specification: newSpecification });
  };
  handleSpecificationValueChange = (idx) => (evt) => {
    const newSpecification = this.state.specification.map(
      (specification, sidx) => {
        if (idx !== sidx) return specification;
        return { ...specification, value: evt.target.value };
      }
    );

    this.setState({ specification: newSpecification });
  };

  handleAddSpecification = () => {
    this.setState({
      specification: this.state.specification.concat([{ name: "", value: "" }]),
    });
  };

  handleRemoveSpecification = (idx) => () => {
    this.setState({
      specification: this.state.specification.filter((s, sidx) => idx !== sidx),
    });
  };

  //attribues
  handleAttributeColorChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, color: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };

  handleAttributeSizeChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, size: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };
  handleAttributePriceChange = (idx) => (evt) => {
    const newAttribute = this.state.attribute.map((attribute, sidx) => {
      if (idx !== sidx) return attribute;
      return { ...attribute, price: evt.target.value };
    });

    this.setState({ attribute: newAttribute });
  };
  handleAttributeImageChange = (idx) => {
    setTimeout(() => {
      const newAttribute = this.state.attribute.map((attribute, sidx) => {
        if (idx !== sidx) return attribute;
        var len = this.state.images.length - 1;
        return { ...attribute, image: len };
      });
      this.setState({ attribute: newAttribute });
    }, 1000);
  };
  handleAddAttribute = () => {
    this.setState({
      attribute: this.state.attribute.concat([
        { color: "", size: "", price: "", image: "" },
      ]),
    });
  };

  handleRemoveAttribute = (idx) => () => {
    this.setState({
      attribute: this.state.attribute.filter((s, sidx) => idx !== sidx),
    });
  };
  _metaTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaTag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaTag: data,
            })
          : this.setState({
              metaTag: null,
            });
      }}
    />
  );
  handleCreate = (e) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(e);
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
    }, 1000);
  };
  _metaKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: null,
            });
      }}
    />
  );

  _seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: null,
            });
      }}
    />
  );
  _productTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.tag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              tag: data,
            })
          : this.setState({
              tag: null,
            });
      }}
    />
  );
  handleChange = (date) => {
    this.setState({
      salteEndDate: date,
    });
  };
  render() {
    const {
      title,
      brand,
      slug,
      modelNumber,
      salePrice,
      weightAndDimension,
      specification,
      tag,
      minimumQuantity,
      attribute,
      images,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      dimensionAndWeight,
      status,
      manufacturer,
      category,
      vendor,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
      description,
      upc,
      sortOrder,
      enableSale,
      saleDiscount,
      salteEndDate,
      width,
      height,
      length,
      weight,
      lengthClass,
      subCategories,
      mainImage,
      weigthClass,
      cost,
      overrideEtl,
      product,
      additionalPrice,
      promoCostEndDate,
      promoCostStartDate,
      promoPriceEndDate,
      promoPriceStartDate,
      promoCost,
      promoPrice,
      specialNote,
    } = this.state;
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    const colourOptions = [];
    const selectedOptions = [];
    subCategories.forEach((element) => {
      var obj = {};
      obj.value = element._id;
      obj.label = element.name;
      obj.color = "#00B8d9";
      obj.isFixed = true;
      colourOptions.push(obj);
      if (category === element._id) {
        var selectObj = {};
        selectObj.value = element._id;
        selectObj.label = element.name;
        selectObj.color = "#00B8d9";
        selectObj.isFixed = true;
        selectedOptions.push(selectObj);
      }
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Edit Product
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Product
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 bg-white grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#E9ECEF",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Edit
                </h4>

                <Alert />

                <form className="forms-sample">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="exampleInputName1"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          style={{ backgroundColor: "#e9ecef" }}
                          placeholder={title}
                          name="title"
                          value={title}
                          onChange={(e) =>
                            this.setState({ title: e.target.value })
                          }
                          required="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="Slug">
                          Slug
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="slug"
                          placeholder={slug}
                          name="slug"
                          value={slug}
                          onChange={(e) =>
                            this.setState({ slug: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="sku">
                          SKU{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="sku"
                          placeholder="SKU"
                          name="sku"
                          value={sku}
                          onChange={(e) =>
                            this.setState({ sku: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="modelNumber"
                        >
                          Model Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="modelNumber"
                          placeholder={modelNumber}
                          name="modelNumber"
                          value={modelNumber}
                          onChange={(e) =>
                            this.setState({ modelNumber: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="description"
                        >
                          Seo Description
                        </label>
                        <textarea
                          className="form-control"
                          placeholder={description}
                          id="description"
                          name="description"
                          rows={4}
                          value={description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                      <div className="mt-3">
                        <div className="form-group">
                          <div className="input-group flex-column col-xs-12">
                            {/* <span>Images</span>
                          <label htmlFor="images" className="d-block">
                            Upload Image
                          </label>
                            <input
                              required
                              type="file"
                              multiple
                              id="images"
                              className="d-none form-control"
                              accept="image/x-png,image/jpeg,image/jpg|video/*"
                              onChange={(e) => this._handleImageChange(e)}
                              // disabled="true"
                            /> */}
                            {/* <div className="p-3 m-2 border border-grey position-relative">
                              <img src={mainImage} width="50px" height="50px" />
                            </div> */}
                            <div className="d-flex">
                              {this.state.images.map((image, index) => (
                                <div
                                  onClick={() =>
                                    this.handleChangeMainImage(index)
                                  }
                                  className="p-3 m-2 border border-grey position-relative"
                                >
                                  {image?.image === mainImage && (
                                    <i
                                      title="Save"
                                      className="icon icon-check"
                                      style={checkIconStyle}
                                    ></i>
                                  )}
                                  <img
                                    src={image?.image}
                                    width="50px"
                                    alt=""
                                    className="border-radius-8"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }} htmlFor="brand">
                            Select Category
                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            value={this.state.defaultSlectedCategories}
                            isMulti
                            onChange={(e) => this.handleCategoryChange(e)}
                            options={this.state.subCategories}
                          />

                          {/* <select 
                            className="form-control form-control-sm"
                            id="category"
                            onChange={(e) => {
                              this.setState({ category: e.target.value });
                            }}
                            value={category}
                          >
        
                            <option>choose</option>
                            {this.state.subCategories &&
                              this.state.subCategories.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="cost">
                          Cost
                        </label>
                        <input
                          type="number"
                          step="0.10"
                          min="0"
                          className="form-control"
                          id="cost"
                          placeholder="Cost"
                          name="cost"
                          value={cost}
                          onChange={(e) =>
                            this.setState({ cost: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Sale Price
                        </label>
                        <input
                          type="number"
                          step="0.10"
                          min="0"
                          className="form-control"
                          id="salePrice"
                          placeholder="Price"
                          name="salePrice"
                          value={salePrice}
                          onChange={(e) =>
                            this.setState({ salePrice: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="quantity"
                        >
                          Quantity{" "}
                        </label>
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          id="quantity"
                          placeholder="Quantity"
                          name="quantity"
                          value={quantity}
                          onChange={(e) =>
                            this.setState({ quantity: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="status">
                          Status
                        </label>

                        <select
                          name="status"
                          value={status}
                          onChange={(e) => {
                            this.setState({ status: e.target.value });
                          }}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="overrideEtl"
                        >
                          Override Etl
                        </label>

                        <select
                          name="overrideEtl"
                          value={overrideEtl}
                          onChange={(e) => {
                            this.setState({ overrideEtl: e.target.value });
                          }}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group ">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="outOfStock"
                        >
                          Out Of Stock
                        </label>

                        <select
                          name="outOfStock"
                          value={outOfStock}
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ outOfStock: e.target.value });
                          }}
                          disabled="true"
                        >
                          <option value="">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          UPC
                        </label>
                        <input
                          type="text"
                          step="1"
                          min="1"
                          className="form-control"
                          id="upc"
                          placeholder="upc"
                          name="upc"
                          value={upc}
                          onChange={(e) =>
                            this.setState({ upc: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group ">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="enableSale"
                        >
                          Enable Sale
                        </label>
                        <select
                          name="enableSale"
                          className="form-control"
                          value={enableSale}
                          onChange={(e) => {
                            this.setState({
                              enableSale: e.target.value,
                            });
                          }}
                          disabled="true"
                        >
                          <option value="">choose</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Sale Discount {"(in percentage %)"}
                        </label>
                        <input
                          type="number"
                          step="1"
                          min="1"
                          className="form-control"
                          id="saleDiscount"
                          placeholder="Sale Discount"
                          name="saleDiscount"
                          value={saleDiscount}
                          onChange={(e) =>
                            this.setState({ saleDiscount: e.target.value })
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="salteEndDate"
                        >
                          Sale End Date
                        </label>

                        <DatePicker
                          className="form-control"
                          selected={this.state.salteEndDate}
                          onChange={this.handleChange}
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Box ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="boxId"
                          placeholder="Box ID"
                          name="boxId"
                          value={product?.shipping?.boxId}
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Shipping Fee (by vendor)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="shippingFee"
                          placeholder="shippingFee"
                          name="shippingFee"
                          value={
                            product?.shippingFee
                              ? product?.shippingFee.toFixed(2)
                              : "0"
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Shipping Pricing (by sundial)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="shippingPricing"
                          placeholder="shippingPricing"
                          name="shippingPricing"
                          value={
                            product?.shippingPricing
                              ? product?.shippingPricing.toFixed(2)
                              : "0"
                          }
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Price Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="priceType"
                          placeholder="priceType"
                          name="priceType"
                          value={product?.priceType}
                          disabled="true"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="dropshipFeeOne"
                        >
                          Drop Ship Fee 1
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="dropshipFeeOne"
                          placeholder="dropshipFeeOne"
                          name="dropshipFeeOne"
                          value={product?.dropshipFeeOne ?? 0}
                          disabled="true"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="dropshipFeeTwo"
                        >
                          Drop Ship Fee 2
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="dropshipFeeTwo"
                          placeholder="dropshipFeeTwo"
                          name="dropshipFeeTwo"
                          value={product?.dropshipFeeTwo ?? 0}
                          disabled="true"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }} htmlFor="price">
                          Product Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=" productType"
                          placeholder=" productType"
                          name=" productType"
                          value={product?.productType}
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="additionalPrice"
                        >
                          Additional Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id=" additionalPrice"
                          placeholder=" Additional Price"
                          name=" additionalPrice"
                          value={additionalPrice}
                          onChange={(e) =>
                            this.setState({
                              // ...this.state,
                              additionalPrice: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoCostStartDate"
                        >
                          Promo Cost Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id=" promoCostStartDate"
                          placeholder=" Promo Cost Start Date"
                          name=" promoCostStartDate"
                          value={toISOStringFromat(promoCostStartDate)}
                          onChange={(e) =>
                            this.setState({
                              // ...this.state,
                              promoCostStartDate: e.target.value,
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoCostEndDate"
                        >
                          Promo Cost End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id=" promoCostEndDate"
                          placeholder=" Promo Cost End Date"
                          name=" promoCostEndDate"
                          value={toISOStringFromat(promoCostEndDate)}
                          onChange={(e) =>
                            this.setState({
                              promoCostEndDate: e.target.value,
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoPriceStartDate"
                        >
                          Promo Price Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id=" promoPriceStartDate"
                          placeholder=" Promo Price Start Date"
                          name=" promoPriceStartDate"
                          value={toISOStringFromat(promoPriceStartDate)}
                          onChange={(e) =>
                            this.setState({
                              promoPriceStartDate: e.target.value,
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoPriceEndDate"
                        >
                          Promo Price End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id=" promoPriceEndDate"
                          name=" promoPriceEndDate"
                          value={toISOStringFromat(promoPriceEndDate)}
                          onChange={(e) =>
                            this.setState({
                              promoPriceEndDate: e.target.value,
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoCost"
                        >
                          Promo Cost
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id=" promoCost"
                          placeholder="Promo Cost"
                          name=" promoCost"
                          value={promoCost}
                          onChange={(e) =>
                            this.setState({
                              promoCost: Number(e.target.value),
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoPrice"
                        >
                          Promo Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id=" promoPrice"
                          placeholder=" Promo Price"
                          name=" promoPrice"
                          value={promoPrice}
                          onChange={(e) =>
                            this.setState({
                              promoPrice: Number(e.target.value),
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "bold" }}
                          htmlFor="promoPrice"
                        >
                          Special Note
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=" specialNote"
                          placeholder="Special Note"
                          name=" specialNote"
                          value={specialNote}
                          onChange={(e) =>
                            this.setState({
                              specialNote: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "550px", margin: "12px 0" }}>
                    <h4> Dimensions</h4>
                    <div className="table-responsive table-bordered">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              style={{
                                margin: "8px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              Height
                            </th>
                            <th
                              style={{
                                margin: "8px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              width
                            </th>
                            <th
                              style={{
                                margin: "8px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              Length
                            </th>
                            <th
                              style={{
                                margin: "8px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              Weight
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {product?.shipping?.dimensions?.map((row, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  margin: "8px",
                                  textAlign: "center",
                                  height: "25px",
                                }}
                              >
                                {row.height}
                              </td>
                              <td
                                style={{
                                  margin: "8px",
                                  textAlign: "center",
                                  height: "25px",
                                }}
                              >
                                {row.width}
                              </td>
                              <td
                                style={{
                                  margin: "8px",
                                  textAlign: "center",
                                  height: "25px",
                                }}
                              >
                                {row.length}
                              </td>
                              <td
                                style={{
                                  margin: "8px",
                                  textAlign: "center",
                                  height: "25px",
                                }}
                              >
                                {row.weight}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                      disabled={this.state.loading}
                    >
                      Update
                    </button>
                    <Link to={"/admin/products"}>
                      <button className="btn btn-light">Cancel</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

subCategoryNames.propTypes = {
  subCategoryNames: PropTypes.func.isRequired,
};
usersRetreive.propTypes = {
  usersRetreive: PropTypes.func.isRequired,
};
export default withRouter(
  connect(null, {
    retreive,
    edit,
    getStores,
    subCategoryNames,
    usersRetreive,
  })(Edit)
);
