import React from "react";
import CostUpdateWrapper from "./CostUpdateWrapper";
import COGsTable from "./COGsTable";
import ShippingCostTabel from "./ShippingCostTabel";
import DsFeesTable from "./DsFeesTable";

const CostsUpdateComp = ({ SelectItem, setIsMount, IsMount }) => {
  return (
    <div className="costs-update-container ">
      <CostUpdateWrapper
        title="COGS"
        status={SelectItem?.orCogs?.status ? "Verified" : "Pending"}
      >
        <COGsTable
          SelectItem={SelectItem}
          IsMount={IsMount}
          setIsMount={setIsMount}
        />
      </CostUpdateWrapper>
      <CostUpdateWrapper
        title="Shipping Cost"
        status={SelectItem?.orShipping?.status ? "Verified" : "Pending"}
      >
        <ShippingCostTabel
          SelectItem={SelectItem}
          IsMount={IsMount}
          setIsMount={setIsMount}
        />
      </CostUpdateWrapper>
      <CostUpdateWrapper
        title="DS Fees"
        status={SelectItem?.orDsFees?.status ? "Verified" : "Pending"}
      >
        <DsFeesTable
          SelectItem={SelectItem}
          IsMount={IsMount}
          setIsMount={setIsMount}
        />
      </CostUpdateWrapper>
    </div>
  );
};

export default CostsUpdateComp;
