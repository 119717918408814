import axios from "axios";
import { apiURL } from "../../config/default";

export const getSecondLevelCategories = async (id) => {
  try {
    const res = await axios.get(`${apiURL}admin/all-second-categories`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
