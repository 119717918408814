// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_mainContainer__3lPIb {\n}\nli {\n  list-style: none;\n}\n.styles_mainContainer__3lPIb li {\n  cursor: pointer;\n}\n.styles_homePMainList__MZqQm {\n  padding: 2px 5px;\n  text-align: center;\n  transition: 0.3s ease-in-out;\n  border-bottom: 1px solid transparent;\n}\n.styles_homePMainList__MZqQm:hover {\n  border-color: turquoise;\n}\ni {\n  height: -moz-fit-content;\n  height: fit-content;\n}\n.styles_iconGrid__YI8BL {\n  font-size: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/WPHomePage/styles.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;EAC5B,oCAAoC;AACtC;AACA;EACE,uBAAuB;AACzB;AACA;EACE,wBAAmB;EAAnB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":[".mainContainer {\n}\nli {\n  list-style: none;\n}\n.mainContainer li {\n  cursor: pointer;\n}\n.homePMainList {\n  padding: 2px 5px;\n  text-align: center;\n  transition: 0.3s ease-in-out;\n  border-bottom: 1px solid transparent;\n}\n.homePMainList:hover {\n  border-color: turquoise;\n}\ni {\n  height: fit-content;\n}\n.iconGrid {\n  font-size: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "styles_mainContainer__3lPIb",
	"homePMainList": "styles_homePMainList__MZqQm",
	"iconGrid": "styles_iconGrid__YI8BL"
};
export default ___CSS_LOADER_EXPORT___;
