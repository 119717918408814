import React, { useEffect, useState } from "react";
import VendorRow from "./VendorRow";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { updateVendor } from "../../../../actions/user";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

const AddWareHouse = ({ isMount, setIsMount, Data, setEdit }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [FormData, setFormData] = useState({
    company: "",
    address: "",
    city: "",
    postalCode: "",
    region: "",
    country: "",
  });

  useEffect(() => {
    return () => {};
  }, [Data, id]);

  const handleAddress = (places) => {
    const address = places.formatted_address.split(",");
    setFormData((pre) => {
      return {
        ...pre,
        address: address[0],
        city: address[1],
        postalCode: address[0].split(" ")[0],
        region: address[2].trim(),
        country: address[3],
      };
    });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const handleSubmit = async (data) => {
    const newData = [...data.wareHouses];
    newData.push(FormData);
    const newVendor = {
      ...data,
      wareHouses: newData,
    };
    const res = await dispatch(updateVendor(newVendor, id));
    if (res) {
      setIsMount(!isMount);
      setEdit({
        open: false,
        edit: "",
        title: "",
      });
      setIsMount(!isMount);
    }
  };

  return (
    <div className="">
      <div>
        <VendorRow name={"Company"}>
          <input
            type="text"
            name="company"
            required
            value={FormData.company}
            onChange={(e) =>
              setFormData({
                ...FormData,
                [e.target.name]: e.target.value,
              })
            }
          />
        </VendorRow>

        <VendorRow name={"Street"}>
          <ReactGoogleAutocomplete
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: { country: "US" },
            }}
            apiKey={"AIzaSyDdgbxnClv98JsdP2Girj12oEziJ3_donE"}
            required
            onPlaceSelected={(place) => {
              handleAddress(place);
            }}
            name="address"
            autoFocus
            onChange={(e) => handleAddressChange(e)}
            value={FormData?.address ?? ""}
          />
        </VendorRow>

        <VendorRow name={"Country"}>
          <input
            value={FormData?.country ?? "USA"}
            onChange={(e) => handleAddressChange(e)}
            type="text"
            name="country"
            id="country"
          />
        </VendorRow>
        <VendorRow name={"Region"}>
          <input
            type="text"
            name="region"
            value={FormData.region ?? ""}
            onChange={(e) => handleAddressChange(e)}
            maxLength="2"
            disabled
          />
        </VendorRow>

        <VendorRow name={"City"}>
          <input disabled type="text" name="city" value={FormData.city ?? ""} />
        </VendorRow>
      </div>
      <div className="px-3">
        <button
          className=" btn btn-success w-100  my-4"
          onClick={() => handleSubmit(Data)}
        >
          Add Address
        </button>
      </div>
    </div>
  );
};

export default AddWareHouse;
