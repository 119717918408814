import React, { useState, useEffect } from "react";
import CustomeSelect from "../../../../components/custome-select/CustomeSelect";
import { swtichShippingType } from "../../../../actions/order";
// ... other necessary imports

const SwitchShippingParty = ({ SelectItem }) => {
  const [open, setOpen] = useState(false);
  const [Carrier, setCarrier] = useState("");
  const [currentShippingParty, setCurrentShippingParty] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleShippingType = async () => {
    if (SelectItem && SelectItem._id) {
      try {
        const isSundialShipping = Carrier === "Sundial";
        const res = await swtichShippingType(SelectItem._id, {
          isSundialShipping,
        });

        if (res) {
          setOpen(false);
          setCurrentShippingParty(Carrier);
        }
      } catch (error) {
        console.error("Error updating shipping type:", error);
      }
    }
  };

  useEffect(() => {
    const initialShippingParty = SelectItem?.isSundialShipping
      ? "Sundial"
      : SelectItem?.brandId?.fullName.split(" ")[0];

    setCurrentShippingParty(initialShippingParty);
  }, [SelectItem]);

  return (
    <>
      <div className="mt-3">
        <h6>
          Shipping Party:{" "}
          <span
            className="font-weight-bold c-pointer"
            style={{ color: "#007bff" }}
            onClick={handleOpen}
          >
            Switch Shipping Party
          </span>
        </h6>
        <p>{currentShippingParty}</p>
      </div>

      {open && (
        <div>
          <div className="mt-3">
            <CustomeSelect
              className={"border-grey"}
              title={"Shipping Party"}
              value={currentShippingParty}
              placeholder="Select"
              options={[
                {
                  value: "Sundial",
                  label: "Sundial",
                },
                {
                  value: `${SelectItem?.brandId?.fullName.split(" ")[0]}`,
                  label: `${SelectItem?.brandId?.fullName.split(" ")[0]}`,
                },
              ]}
              onChange={(value) => setCarrier(value)}
            />
          </div>
          <div className="d-flex justify-content-start align-end gap-20 my-4">
            <button
              onClick={handleClose}
              className="btn-danger w-100 px-2 py-3 border-radius-10"
            >
              Cancel
            </button>
            <button
              className="btn-success w-100 px-2 py-3 border-radius-10"
              onClick={handleShippingType}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SwitchShippingParty;
