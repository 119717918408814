import React, { useState } from "react";
import { BsCalendarWeekFill } from "react-icons/bs";
import ReactDatePicker from "react-datepicker";
import { MMDDYYFormat } from "../../utils/dateFormate";

const DateRangeFilter = ({
  placeholder,
  startDate,
  endDate,
  onChange,
  title,
}) => {
  const [Open, setOpen] = useState(false);
  const [Date, setDate] = useState([startDate ?? null, endDate ?? null]);
  const handleChange = (dates) => {
    const [start, end] = dates;
    setDate([start, end]);
    onChange([start, end]);
  };

  const resetDateRange = () => {
    setDate([null, null]);
    onChange([null, null]);
  };

  return (
    <div
      style={{
        width: "243px",
        borderRadius: Open ? "10px 10px 0px 0px" : "10px",
        border: "1px solid #000000",
        padding: "5px",
      }}
      className=" position-relative border-grey bg-white  "
      onMouseLeave={() => setOpen(false)}
    >
      <div
        onMouseEnter={() => setOpen(true)}
        onClick={() => setOpen(true)}
        className="d-flex justify-content-between align-center gap-10"
      >
        <div>
          <h6 className=" font-weight-bold">{title}</h6>
          {Date[0] || Date[1] ? (
            <p className=" text-muted">
              {MMDDYYFormat(Date[0])}
              {" to "}
              {MMDDYYFormat(Date[1])}
            </p>
          ) : (
            <p className=" text-muted">{placeholder}</p>
          )}
        </div>
        <div>
          <BsCalendarWeekFill />
        </div>
      </div>
      {Open && (
        <div
          className=" position-absolute bg-whit top-0 left-0 bg-white "
          style={{
            zIndex: "999",
            top: "52px",
            left: "0px",
          }}
        >
          <ReactDatePicker
            className=" text-black"
            selected={Date[0]}
            onChange={handleChange}
            startDate={Date[0]}
            endDate={Date[1]}
            selectsRange
            inline
          />
        </div>
      )}
      <button
        onClick={resetDateRange}
        className="reset-button border-0 rounded-circle"
      >
        x
      </button>
    </div>
  );
};

export default DateRangeFilter;
