import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { apiURL } from '../../../../config/default';
import TagableImage from '../../WPHomePage/Components/6XImgTxtBanner/TagableImage/index';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const InspirationTag = (props) => {

  const navigate = useNavigate()
  const [selectOptions, setSelectOptions] = useState([]);
  const [_selectOptions, _setSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState({
    name: '',
    description: '',
    tags: [],
    tagCategory: [],
    imageUrl: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const id = window.location.href.split('?id=')[1];
      let mainData = {};
      if (id) {
        mainData = await axios.get(`${apiURL}admin/tag/${id}`);
        setMainData(mainData.data);
      }

      const res = await axios.get(`${apiURL}admin/category-tags`);
      const tempArr = await res?.data?.tagCategory.map((item) => ({
        ...item,
        value: item.name || 'no name',
        label: item.name || 'no name',
      }));

      let tagCategory = [];

      if (id) {
        res.data.tagCategory.forEach((tag) => {
          tagCategory = [
            ...tagCategory,
            ...tag.values.filter((value) =>
              mainData.data.tagCategory.find((t) => t === value.selectedId)
            ),
          ];
        });
      }
      setMainData({ ...mainData.data, tagCategory });
      setSelectOptions(tempArr);
    };
    fetchData();
  }, []);

  const handleInputChange = async (item) => {
    const tempArr = await item?.values.map((item) => ({
      ...item,
      value: item.name || 'no name',
      label: item.name || 'no name',
    }));
    _setSelectOptions(tempArr);
  };

  const handleAttributes = (item) => {
    setMainData({
      ...mainData,
      tagCategory: [...item],
    });
  };

  // conver image to base 64
  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e, idx) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);
    mainData.imageUrl = await image;
    setMainData({
      ...mainData,
    });
  };

  const publishData = async () => {
    if (
      !mainData.name ||
      !mainData.description ||
      !mainData.tagCategory.length ||
      !mainData.tags.length ||
      !mainData.imageUrl
    ) {
      return toast('Please enter correct values 🙁', {
        type: toast.TYPE.INFO,
        autoClose: 5000,
      });
    }
    setLoading(true);
    let res = null;
    const id = window.location.href.split('?id=')[1];
    if (!id) {
      res = await axios.post(`${apiURL}admin/create-tag`, mainData);
    } else res = await axios.post(`${apiURL}admin/edit-tag`, mainData);

    if (res.status === 200) {
      setLoading(false);
      toast('success', { type: toast.TYPE.INFO, autoClose: 5000 });
      !id && navigate('/admin/inspiration-tags');
    } else {
      toast('Something went wrong 🙁', {
        type: toast.TYPE.INFO,
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="container-scroller">
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="page-header">
        <h3 className="page-title">Inspiration Tags</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/inspiration-tags">Tags</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create Tag
            </li>
          </ol>
        </nav>
      </div>
      <hr />
      {loading && (
        <div
          style={{
            top: 0,
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "12",
            background: "rgba(0,0,0,0.5)",
          }}
          className="d-flex position-fixed justify-content-center align-items-center"
        >
          <CircularProgress disableShrink />
        </div>
      )}
      <div className="row bg-white p-4">
        <div className="col-md-6">
          <h3>
            <button onClick={publishData} className="btn btn-primary">
              Publish
            </button>
          </h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>Name</div>
            <input
              type="text"
              style={{ border: "none", borderBottom: "1px solid #000000" }}
              name="name"
              value={mainData.name}
              onChange={(e) =>
                setMainData({ ...mainData, name: e.target.value })
              }
            />
          </div>
          <div
            className="mt-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>Description</div>
            <textarea
              name="description"
              value={mainData.description}
              onChange={(e) =>
                setMainData({ ...mainData, description: e.target.value })
              }
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="my-2" style={{ width: "200px" }}>
              <h6>Select</h6>
              <Select onChange={handleInputChange} options={selectOptions} />
            </div>
            <div className="my-2" style={{ width: "300px" }}>
              <h6>Select Attributes</h6>

              <Select
                onChange={handleAttributes}
                isMulti
                options={_selectOptions}
                className="basic-multi-select"
                value={mainData.tagCategory.map((tc) => ({
                  ...tc,
                  value: tc.name,
                  label: tc.name,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="my-2">
            <div className="text-center">
              <label className="btn btn-primary" htmlFor="image">
                Upload
              </label>
              <input
                id="image"
                type="file"
                onChange={_handleImageChange}
                className="d-none review-input border-radius-8"
                name="imageURL"
              />
            </div>
          </div>
          {mainData.imageUrl && (
            <TagableImage
              style={{ height: "300px", objectFit: "contain" }}
              image={mainData.imageUrl}
              tags={mainData.tags || []}
              handleChange={(tags) => setMainData({ ...mainData, tags })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InspirationTag;
