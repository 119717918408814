import React, { useState } from 'react';
import ProductCard from './ExploreCard';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from './exploreBrandSection.module.css';
import axios from 'axios';
import { apiURL } from '../../../../../config/default';

function ExploreBrand({
  handleRemoveSection,
  index,
  section,
  handleShow,
  show,
  selectedIndex,
  mainIndex,
  handleClose,
  toggleLoading,
  handleAddValueAuto,
  handleParent,
}) {
  const [url, setUrl] = useState('');
  const [edit, setEdit] = useState({
    title: false,
    desc: false,
  });

  const [showIndex, setshowIndex] = useState(0);

  const handleEdit = (name) => {
    setEdit({
      ...edit,
      [name]: !edit[name],
    });
  };
  const getData = async () => {
    toggleLoading(true);
    const { data: res } = await axios.get(
      `${apiURL}custom-home/home-screen-products?url=${url}`
    );

    const { brand, products } = res;

    const value = [
      { title: brand.fullName, slug: brand.slug, items: products },
    ];

    handleAddValueAuto(value, index);

    toggleLoading(false);
  };
  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        <div className="row">
          <div className={`${styles.exploreBrandCard} col-lg-3`}>
            {!edit.title ? (
              <div className="d-flex align-items-center justify-content-center">
                <h2>{section?.title || 'Explore our Brands'}</h2>
                <i
                  className="icon-pencil"
                  onClick={() => handleEdit('title')}
                  title="edit"
                ></i>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center px-2">
                <input
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEdit('title');
                      handleParent(index, 'title', e.target.value);
                    }
                  }}
                  name="title"
                  defaultValue={section.title || ''}
                  onBlur={(e) => {
                    handleEdit('title');
                    handleParent(index, 'title', e.target.value);
                  }}
                  className={`titleInput ${styles.titleInput}`}
                  autoFocus={true}
                  placeholder={section.title ?? 'Title'}
                />
              </div>
            )}

            {!edit.desc ? (
              <div className="d-flex align-items-center justify-content-center">
                <p className="m-0">
                  {section.desc ??
                    'Find hand-curated collections that fit your style, space, and budget'}
                </p>
                <i
                  className="icon-pencil"
                  onClick={() => handleEdit('desc')}
                  title="edit"
                ></i>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center px-2">
                <input
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEdit('desc');
                      handleParent(index, 'desc', e.target.value);
                    }
                  }}
                  name="desc"
                  defaultValue={section.desc || ''}
                  onBlur={(e) => {
                    handleEdit('desc');
                    handleParent(index, 'desc', e.target.value);
                  }}
                  className="titleInput"
                  autoFocus={true}
                  placeholder={section.desc || 'Description'}
                />
              </div>
            )}

            <div className={styles.brandBtnWrapper}>
              {section.values?.map((value, _index) => (
                <div className="d-flex">
                  <button
                    className={`${styles.brandBtn}`}
                    onMouseEnter={() => setshowIndex(_index)}
                  >
                    {value.title}
                  </button>
                  <div className="text-right mt-3">
                    <span
                      title="Remove Brand"
                      onClick={() => handleRemoveSection(index, _index)}
                    >
                      <i
                        className={`${styles.actionBtn} ${styles.hoverWarning} icon icon-close`}
                      ></i>
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <p>
                <button className={styles.shopNowBtn}>Shop Now</button>
              </p>
            </div>
          </div>
          <div className="col-lg-9">
            <div className={`${styles.productCards} h-100`}>
              {section?.values?.map(
                (value, _index) =>
                  _index === showIndex &&
                  value?.items?.map((product) => (
                    <>
                      <ProductCard product={product} />
                    </>
                  ))
              )}
              <Modal
                show={show && mainIndex === index && selectedIndex === -1}
                onHide={handleClose}
              >
                <Modal.Header closeButton centered>
                  <Modal.Title>Add Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formBasiclink" className="m-2">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="paste link"
                      name="link"
                      defaultValue={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={() => getData()}
                      className="btn btn-primary my-2 mx-auto"
                    >
                      Click me to get data
                    </button>
                    <Form.Text className="text-muted">
                      This Link will redirect to product page or category page
                    </Form.Text>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={`${styles.actionBtn} btn-danger`}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className={`bg-white ${styles.h93}`}>
        <i
          onClick={() => handleRemoveSection(index)}
          className={`${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
          title="Remove"
          style={{ height: 'fit-content' }}
        ></i>
        <i
          onClick={() => handleShow(-1, index)}
          className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-plus`}
          title="Add"
          style={{ height: 'fit-content' }}
        ></i>
      </div>
    </div>
  );
}

export default ExploreBrand;
