import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verifyCogs } from "../../../../../actions/saleAnalysis";

const RefundUpdateComp = ({ SelectItem, setEdit }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [formValue, setformValue] = useState({
    itemPrice: SelectItem?.refunds?.itemPrice ?? "",
    reason: SelectItem?.refunds?.reason ?? "",
    status: SelectItem?.refunds?.status ?? false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const res = await dispatch(
        verifyCogs(SelectItem._id, {
          refunds: {
            ...formValue,
            status: true,
          },
        })
      );

      if (res) {
        setloading(false);
        setEdit({
          open: false,
          edit: "",
          title: "",
        });
      }
    } catch (error) {
      setloading(false);
    }
  };
  return (
    <>
      <div className="my-2 px-3">
        <form action="" onSubmit={handleSubmit}>
          <table className="cogs-table">
            <tbody>
              <tr>
                <th>Variable</th>
                <th>Overide</th>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Refund Amount</span>
                </td>
                <td>
                  <input
                    className=" border-0"
                    type="text"
                    name="itemPrice"
                    placeholder="Enter Amount"
                    required
                    readOnly={SelectItem?.refunds?.itemPrice ? true : false}
                    value={formValue.itemPrice}
                    onChange={(e) =>
                      setformValue({
                        ...formValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span className=" font-weight-bold">Reason</span>
                </td>

                <td>
                  <select
                    name="reason"
                    value={formValue.reason}
                    required
                    // defaultValue=""
                    disabled={SelectItem?.refunds?.reason ? true : false}
                    onChange={(e) =>
                      setformValue({
                        ...formValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="">Refund Reason</option>
                    <option value="Damaged/Defective">Damaged/Defective</option>
                    <option value="Buyers Remote">Buyers Remote</option>
                    <option value="Cancelled Order">Cancelled Order</option>
                    <option value="Exchnage">Exchnage</option>
                    <option value="Wrong item Shipped">
                      Wrong item Shipped
                    </option>
                    <option value="Other">Other</option>
                    <option value="Custome">Custome</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          {!SelectItem?.refunds?.status && (
            <button
              type="submit"
              className="update-Order-button text-white  mt-3 "
            >
              {loading ? "Loading" : "Confirm Refund"}
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default RefundUpdateComp;
