import React, { useState, useEffect } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiURL } from '../../../config/default';
import axios from 'axios';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CheckBoxComponent from './Components/CheckBoxComponent';
// Redux
import { connect } from 'react-redux';
import { getAttribute } from '../../../actions/product';
import RangeComponent from './Components/RangeComponent';


function CustomSettings({ getAttribute }) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await getSettings();
      const { data: res } = await getAttribute();
      setattributes(res);
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettings = async () => {
    const { data: settings } = await axios.get(
      `${apiURL}filters/custom-filter/${location.search.split('=')[1]}`
    );

    const { data: rangeAttr } = await axios.get(
      `${apiURL}admin/attribute/isRange`
    );

    setrangeAttr(rangeAttr.attributes);

    const attr = [];
    const fetched_id = [];
    const values = [];
    if (settings.length !== 0) {
      const data = settings[0];
      if (!data.range)
        data.range = [
          {
            rangeMin: '',
            rangeMax: '',
            text: '',
            isFillable: false,
            showImage: false,
            imageURL: '',
          },
        ];
      if (!data.values || data.values.length === 0)
        data.values = [
          {
            productAttribute: [],
            attributeId: '',
            title: '',
            showImage: false,
            imageURL: '',
            description: '',
          },
        ];
      else {
        data.values = data.values.map((val) => ({ ...val, isbase64: false }));

        for (let index = 0; index < data.values.length; index++) {
          let id = data.values[index].attributeId;
          let category_id = data?.filter?.category;
          if (!fetched_id.includes(id)) {
            fetched_id.push(id);

            const { data: res } = await axios.get(
              `${apiURL}filters/get-attribute-list/${category_id}/${id}`
            );
            attr[index] = res.values || [];
            values.push(res.attributeId);
            let newAttribute = [];
            newAttribute = data.values[index].productAttribute.map((attr_) => ({
              value: attr_?._id,
              label: attr_?.text,
            }));
            data.values[index].productAttribute = newAttribute;
          } else {
            const findIndex = values.findIndex((value) => value === id);
            attr[index] = attr[findIndex];
            let newAttribute = [];
            newAttribute = data.values[index].productAttribute.map((attr_) => ({
              value: attr_?._id,
              label: attr_?.text,
            }));
            data.values[index].productAttribute = newAttribute;
          }
        }
      }
      setSecondLevelAttribute(attr);
      setstate({ ...data, isbase64: false });
    } else
      toast.warn('No Custom Found, Start creating filters', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  };

  const [state, setstate] = useState({
    title: '',
    showImage: false,
    imageURL: '',
    checkBoxes: false,
    isRange: false,
    filter: location.search.split('=')[1],
    range: [
      {
        title: '',
        rangeMin: '',
        rangeMax: '',
        text: '',
        isFillable: false,
        showImage: false,
        imageURL: '',
      },
    ],
    values: [
      {
        productAttribute: [],
        attributeId: '',
        title: '',
        showImage: false,
        imageURL: '',
        description: '',
      },
    ],
  });

  const [loading, setloading] = useState(true);
  const [attributes, setattributes] = useState([]);
  const [SecondLevelAttribute, setSecondLevelAttribute] = useState([[]]);
  const [minMax, setminMax] = useState({});
  const [rangeAttr, setrangeAttr] = useState([]);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          productAttribute: [],
          attributeId: '',
          title: '',
          showImage: false,
          imageURL: '',
          description: '',
        },
      ],
    });
  };

  const handleAddRange = () => {
    setstate({
      ...state,
      range: [
        ...state.range,
        {
          title: '',
          rangeMin: '',
          rangeMax: '',
          text: '',
          isFillable: false,
          showImage: false,
          imageURL: '',
        },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };

    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleRemoveRange = (index) => {
    const newState = { ...state };
    newState.range.splice(index, 1);
    setstate(newState);
  };

  const handleGetAttributes = async (index, id) => {
    const category_id = state.filter.category;
    const { data: res } = await axios.get(
      `${apiURL}filters/get-attribute-list/${category_id}/${id}`
    );

    const attr = [...SecondLevelAttribute];
    attr[index] = res.values || [];
    setSecondLevelAttribute(attr);
  };

  const getRangeMinMax = async (title, index) => {
    if (minMax[title]) return;
    const category = state.filter.category;
    const dataToSend = { category, title };
    const { data: res } = await axios.post(
      `${apiURL}filters/get-dimension-data`,
      {
        ...dataToSend,
      }
    );
    const newState = { ...state };
    newState.range[index] = {
      ...newState.range[index],
      rangeMin: res.min,
      rangeMax: res.max,
    };
    setstate(newState);
    setminMax({ [title]: res });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === '') return alert('title is required');

    let dataToSend = { ...state };

    const values1 = [];

    state.values.forEach((value, index) => {
      let attribute = [];
      value.productAttribute.forEach((attr) => {
        attribute.push(attr.value);
      });
      values1[index] = {
        ...state.values[index],
        productAttribute: attribute,
      };
    });
    dataToSend = { ...dataToSend, values: values1 };
    if (!state.checkBoxes) delete dataToSend.values;
    if (!state.isRange) delete dataToSend.range;
    setloading(true);

    try {
      if (state._id) {
        await axios.put(`${apiURL}filters/custom-filter`, {
          ...dataToSend,
        });
        toast.success('Update Successful', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        await axios.post(`${apiURL}filters/custom-filter`, {
          ...dataToSend,
        });
        toast.success('Creation Successful', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error('Something went wrong 😟', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      await getSettings();
      setloading(false);
    }
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);

    if (state._id) setstate({ ...state, imageURL: image, isbase64: true });
    else setstate({ ...state, imageURL: image });
  };

  const renderToggleButton = (key, title) => (
    <>
      <label>{title}</label>
      <div
        className="connection-all"
        onClick={() => setstate({ ...state, [key]: !state[key] })}
      >
        <span className={state[key] ? 'allcenter' : 'allLeft '}>ON</span>

        <span className={state[key] ? 'editRight' : 'editcenter '}>OFF</span>
      </div>
    </>
  );

  return (
    <div className="container-scroller">
      <ToastContainer />

      {loading && <TopBarProgress />}

      <div className="page-header">
        <h3 className="page-title"> Custom Settings </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link onClick={() => navigate(-1)}>Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              <div className="row mb-3 mt-3">
                <div className="col-md-3  mt-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Title"
                    name="title"
                    value={state.title}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="col-md-3  mt-3">
                  {renderToggleButton("showImage", "Show Image")}
                </div>

                {state.showImage && (
                  <div className="col-md-3  mt-3">
                    <label>Image URL</label>
                    <input
                      type="file"
                      name="imageURL"
                      onChange={(e) => _handleImageChange(e)}
                      className="review-input border-radius-8"
                    />
                  </div>
                )}
                <div className="col-md-3  mt-3">
                  {renderToggleButton("checkBoxes", "Show CheckBoxes")}
                </div>
                <div className="col-md-3  mt-3">
                  {renderToggleButton("isRange", "Is Range")}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row my-2">
                {console.log(state.range)}
                {state.isRange &&
                  state.range.map((range_, index) => (
                    <RangeComponent
                      index={index}
                      state={state}
                      handleRemoveRange={handleRemoveRange}
                      setstate={setstate}
                      range_={range_}
                      title={range_.title}
                      isFillable={range_.isFillable}
                      text={range_.text}
                      rangeMin={range_.rangeMin}
                      rangeMax={range_.rangeMax}
                      getRangeMinMax={getRangeMinMax}
                      minMax={minMax}
                      rangeAttr={rangeAttr}
                      convertImgToBase64={convertImgToBase64}
                      showImage={range_.showImage}
                    />
                  ))}
                {state.isRange && (
                  <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Button
                        className="p-3"
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddRange}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="row my-2">
                {state.checkBoxes &&
                  state.values.map((value, index) => (
                    <CheckBoxComponent
                      title={value.title}
                      showImage={value.showImage}
                      state={state}
                      value={value}
                      handleRemoveField={handleRemoveField}
                      index={index}
                      setstate={setstate}
                      handleGetAttributes={handleGetAttributes}
                      attributes={attributes}
                      SecondLevelAttribute={SecondLevelAttribute}
                      convertImgToBase64={convertImgToBase64}
                      productAttribute={value.productAttribute}
                      attributeId={value.attributeId}
                      description={value.description}
                      isAuto={false}
                    />
                  ))}
                {state.checkBoxes && (
                  <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Button
                        className="p-3"
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddField}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button
          className="ml-2 mt-3"
          variant="outline-primary"
          size="sm"
          type="submit"
          disabled={loading}
        >
          {loading ? "loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
}

export default connect(null, { getAttribute })(CustomSettings);
