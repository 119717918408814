import React, { Component } from "react";
import axios from "axios";
import ImageUploader from "react-images-upload";

export default class Test extends Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles
    });
  }

  upload = async () => {
    var formData = new FormData();

    for (var i = 0; i < this.state.pictures.length; i++) {
      formData.append("images", this.state.pictures[i]);
    }

    await axios.post(`http://localhost:3001/api/admin/test`, formData, {
      headers: { "Content-type": "multipart/form-data" }
    });
  };

  render() {
    return (
      <div>
        <ImageUploader
          style={{ width: 500 }}
          withIcon={true}
          withPreview={true}
          buttonText="Choose images"
          onChange={this.onDrop}
          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
          maxFileSize={5242880000000}
        />
        <button type="button" onClick={this.upload}>
          Upload
        </button>
      </div>
    );
  }
}
