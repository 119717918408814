import React from "react";
import { RxCrossCircled } from "react-icons/rx";

const ImagePreview = ({ image, onClose }) => {
  return (
    <div class="image-preview-overlay">
      <div class="image-preview-container">
        <img src={image} alt="Preview" class="preview-image" />
        <RxCrossCircled className=" close-preview-button" onClick={onClose} />
      </div>
    </div>
  );
};

export default ImagePreview;
