/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../../config/default";

export const setVendorCashback = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/set-vendor-cashback`, {
      ...data,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const setUserCashback = async (id, cashbackPercentage) => {
  // update-user-cashback/:userId
  try {
    const res = await axios.post(`${apiURL}admin/update-user-cashback/${id}`, {
      cashbackPercentage,
    });
  } catch (err) {
    console.log(err);
  }
};
export const enableThisVendor = async (id) => {
  try {
    const res = await axios.post(`${apiURL}admin/set-vendor-enable`, {
      id,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const updateReturnPolicy = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/update-policies`, {
      ...data,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const disableThisVendor = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/disable-vendor`, {
      ...data,
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const setVendorInventory = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/update-for-export`, {
      ...data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updatePricing = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/set-map-non-map-pricing`, {
      ...data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updatePricingStrategy = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/update-vendor-data`, {
      ...data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateGoogleShoping = async (data) => {
  try {
    const res = await axios.post(`${apiURL}admin/update-vendor-data`, {
      ...data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getSearchResult = async (pageNumber, searchValue) => {
  try {
    const res = await axios.get(
      `${apiURL}admin/stores?page=${pageNumber}&search=${searchValue}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const deleteVendor = async (id) => {
  try {
    const res = axios.delete(`${apiURL}admin/delete-vendor/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};
export const getVendorStats = async (id) => {
  try {
    const res = axios.get(`${apiURL}admin/get-vendor-stats/${id}`);
    return (await res).data.data;
  } catch (err) {
    return err;
  }
};

export const getMarginOverride = async (id) => {
  try {
    const res = await axios.get(`${apiURL}admin/get-vendor-margins/${id}`);
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const getAllVenderList = async (pageNumber, searchValue) => {
  try {
    const res = await axios.get(
      `${apiURL}admin/stores?page=${pageNumber}&search=${searchValue}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const createFilter = async (id) => {
  try {
    const res = await axios.get(`${apiURL}filters/create-brand-filter/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getVenderInventory = async (pageNumber, searchValue) => {
  try {
    const res = await axios.get(
      `${apiURL}admin/vendors-inventory?page=${
        pageNumber ? pageNumber : 1
      }&search=${searchValue ? searchValue : ""}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
