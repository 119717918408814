import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AddNewShippingCampany } from "../../../../actions/shipping";
import TopBarProgress from "react-topbar-progress-indicator";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const AddShippingCompany = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setloading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    link: "",
    instructions: "",
    enable: true,
    partner: false,
    show: false,
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.name && formData.link) {
      const res = await dispatch(AddNewShippingCampany(formData));
      if (res) {
        setFormData({
          name: "",
          link: "",
          instructions: "",
          enable: true,
          partner: false,
          show: false,
        });
        navigate("/admin/shipping/companies-lists");
      }
    }
  };
  useEffect(() => {
    setloading(false);
  }, []);

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className="container">
            <h1 className="my-4"> Add Shipping Company </h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="link" className="form-label">
                  Link:
                </label>
                <input
                  type="url"
                  name="link"
                  id="link"
                  className="form-control"
                  value={formData.link}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="instructions" className="form-label">
                  Special Instructions:
                </label>
                <textarea
                  name="instructions"
                  id="instructions"
                  className="form-control"
                  value={formData.instructions}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="show"
                  id="show"
                  className="form-check-input"
                  checked={formData.show}
                  onChange={handleInputChange}
                />
                <label htmlFor="enable" className="form-check-label">
                  {formData.show ? "Show" : "Hide"}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="enable"
                  id="enable"
                  className="form-check-input"
                  checked={formData.enable}
                  onChange={handleInputChange}
                />
                <label htmlFor="enable" className="form-check-label">
                  Enable/Disable
                </label>
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="partner"
                  id="partner"
                  className="form-check-input"
                  checked={formData.partner}
                  onChange={handleInputChange}
                />
                <label htmlFor="partner" className="form-check-label">
                  Sundial Partner
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Add Company
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AddShippingCompany;
