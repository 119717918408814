import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/mainCategory";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      displayName: "",
      slug: "",
      image: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      show: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async _handleImageChange(e) {
    e.preventDefault();

    let file = e.target.files[0];

    const base64 = await this.toBase64(file);

    this.setState({
      image: base64,
    });
  }
  _submitHandler = async () => {
    const {
      name,
      displayName,
      slug,
      image,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      description,
      show,
    } = this.state;
    if (name.length > 1 && displayName.length > 1 && slug.length > 1) {
      const dataTOsend = {
        name,
        displayName,
        slug,
        image,
        metaTag: metaTag,
        metaDescription,
        metaKeyword: metaKeyword,
        seoKeyword: seoKeyword,
        description,
        show,
      };

      this.setState({ loading: true });

      const res = await this.props.create(dataTOsend);

      this.setState({ loading: false });

      if (res) {
        this.setState({
          loading: false,
          name: "",
          displayName: "",
          image: "",
          slug: "",
          description: "",
          metaTag: [],
          metaDescription: "",
          metaKeyword: [],
          seoKeyword: [],
        });
        toast(`${res.data}`, { containerId: "B" });
      }
    } else {
      toast.error(`Fill all the fields`, { containerId: "B" });
    }
  };

  render() {
    const {
      name,
      displayName,
      slug,
      metaDescription,
      description,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Create Main Category{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/main-categories">Main Categories</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Main Category
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Add a new main category
                </h4>

                <Alert />

                <form className="forms-sample m-3">
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Name">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      required="true"
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Name">
                      Display Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="displayName"
                      value={displayName}
                      onChange={(e) =>
                        this.setState({ displayName: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Slug">
                      Slug
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="slug"
                      placeholder="Slug"
                      name="slug"
                      value={slug}
                      onChange={(e) => this.setState({ slug: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>Image upload</label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Meta Tags">
                      Meta Tags
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaTag: data,
                            })
                          : this.setState({
                              metaTag: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="Meta Keywords"
                    >
                      Meta Keywords
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaKeyword: data,
                            })
                          : this.setState({
                              metaKeyword: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="meta description"
                    >
                      Meta Description
                    </label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      value={metaDescription}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="seo keywords"
                    >
                      Seo Keywords
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              seoKeyword: data,
                            })
                          : this.setState({
                              seoKeyword: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="description">
                      Seo Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={6}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label
                      style={{ fontWeight: "bold" }}
                      className="col-sm-3 col-form-label"
                    >
                      Show in Header
                    </label>
                    <div className="col-sm-12">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            required
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="1"
                            defaultValue
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                show: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            required
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="membershipRadios2"
                            defaultValue="0"
                            onChange={() => {
                              this.setState({
                                show: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/main-categories"}>
                  <button
                    className="btn btn-light"
                    type="button"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
