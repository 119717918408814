import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const Loader = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        loading ....
      </div>
    </>
  );
};

const PrivateRoute = ({ children, auth: { isAuth, loading } }) => {
  return loading ? (
    <Loader />
  ) : isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/login" />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
