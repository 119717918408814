import axios from "axios";
import { apiURL } from "../../../../config/default";

export const addNewBlogPage = async (
  blogContent,
  blogDescription,
  blogTags,
  status,
  image
) => {
  const { blogTitle, seoTitle, seoDescription } = blogContent;
  try {
    const response = await axios.post(`${apiURL}admin/blogs`, {
      title: blogTitle,
      description: blogDescription,
      tags: blogTags,
      image: image,
      status: status,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSinglePageById = async (id) => {
  try {
    const response = await axios.delete(`${apiURL}admin/blogs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user data:", error);
    throw error;
  }
};

export const getSinglePageById = async (id) => {
  try {
    const response = await axios.get(`${apiURL}admin/blogs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user data:", error);
    throw error;
  }
};

export const updateBlogPage = async (
  blogContent,
  blogDescription,
  blogTags,
  status,
  isBase64,
  image,
  blogId
) => {
  const { blogTitle, seoTitle, seoDescription } = blogContent;
  try {
    const response = await axios.put(`${apiURL}admin/blogs/${blogId}`, {
      title: blogTitle,
      description: blogDescription,
      tags: blogTags,
      image: image,
      isbase64: isBase64,
      status: status,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
