import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  editSeller,
  updateSeller,
  deleteSeller
} from "../../../actions/seller";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sellerData: [],
      userData: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.editSeller({ userId: id });

    this.setState({
      loading: false,
      userData: res[0],
      sellerData: res[1]
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _approveSeller = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.updateSeller({ userId: id });

    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate.push("/admin/sellers");
      }, 3000);
    }
  };

  _rejectSeller = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.deleteSeller({ userId: id });
    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate.push("/admin/sellers");
      }, 3000);
    }
  };

  render() {
    const { fullName, email, userName } =
      this.state.userData && this.state.userData;

    const sellerName =
      this.state.sellerData &&
      this.state.sellerData.storeInfo &&
      this.state.sellerData.storeInfo[0] &&
      this.state.sellerData.storeInfo[0].storeName;

    const sellerCity =
      this.state.sellerData &&
      this.state.sellerData.storeInfo &&
      this.state.sellerData.storeInfo[0] &&
      this.state.sellerData.storeInfo[0].storeCity;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Seller Approval </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/sellers">Sellers</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Seller Approval
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Seller Approval: {fullName && fullName}
                </h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="title">Owner Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fullName && fullName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={email && email}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userName && userName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Seller Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={sellerName && sellerName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Seller Location</label>
                    <input
                      type="text"
                      className="form-control"
                      value={sellerCity && sellerCity}
                      readOnly
                    />
                  </div>

                  <button
                    onClick={() => {
                      this._approveSeller();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Approve Seller
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this._rejectSeller();
                    }}
                  >
                    Reject Seller
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

editSeller.propTypes = {
  editSeller: PropTypes.func.isRequired
};

updateSeller.propTypes = {
  updateSeller: PropTypes.func.isRequired
};

deleteSeller.propTypes = {
  deleteSeller: PropTypes.func.isRequired
};

export default withRouter(connect(null, { editSeller, updateSeller, deleteSeller })(Edit));
