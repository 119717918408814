export const ConvertImagesIntoBase64 = async (images) => {
  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };
  const promises = [];
  for (let index = 0; index < images.length; index++) {
    promises.push(convertImgToBase64(images[index]?.file));
  }
  const newImages = await Promise.all(promises);
  return newImages;
};

// this function get size in bytes and convert into B, KB and MB Unit
export const fileSizeUnitConverter = (bytes, decimals = 2) => {
  let units = ["B", "KB", "MB"];
  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes?.toFixed(decimals)) + " " + units[i];
};
