/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";
import { alert } from "./alert";

export const AddNewShippingCampany = (data) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/shipping-company`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

export const getAllCompany = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/shipping-company`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSignleComapny = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/shipping-company/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const editCompany = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/shipping-company/${id}`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const DeleteComapny = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(`${apiURL}admin/shipping-company/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
