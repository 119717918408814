import React from "react";
import { Link, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../../actions/auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class Register extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adminSecretKey: "",
      email: "",
      fullName: "",
      userName: "",
      password: "",
      role: "admin"
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      adminSecretKey,
      email,
      fullName,
      userName,
      password,
      role
    } = this.state;

    this.setState({ loading: true });

    const res = await this.props.register({
      adminSecretKey,
      email,
      fullName,
      userName,
      password,
      role
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
        adminSecretKey: "",
        email: "",
        fullName: "",
        userName: "",
        password: "",
        role: "admin"
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const { isAuth } = this.props.auth;

    const {
      loading,
      adminSecretKey,
      email,
      fullName,
      userName,
      password
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });

    return isAuth && !this.props.auth.loading ? (
      <Navigate exact to="/admin/dashboard" />
    ) : (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <img src="/assets/images/logo.svg" alt="logo" />
                  </div>
                  <h4>New here?</h4>
                  <h6 className="font-weight-light">
                    Signing up is easy. It only takes a few steps
                  </h6>

                  <Alert />

                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="userName"
                        placeholder="Username"
                        name="userName"
                        value={userName}
                        onChange={e =>
                          this.setState({ userName: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fullName"
                        name="fullName"
                        placeholder="Full name"
                        value={fullName}
                        onChange={e =>
                          this.setState({ fullName: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="adminSecretKey"
                        name="adminSecretKey"
                        placeholder="Admin secret key"
                        value={adminSecretKey}
                        onChange={e =>
                          this.setState({ adminSecretKey: e.target.value })
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={() => {
                          this._submitHandler();
                        }}
                        type="button"
                      >
                        SIGN UP
                      </button>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      {" "}
                      Already have an account?{" "}
                      <Link to={"/admin/login"} className="text-primary">
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

register.propsTypes = {
  register: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { register })(Register);
