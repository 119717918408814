// Register
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

// Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Auth
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

// Logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Forgot Password
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAIL = "FORGOT_FAIL";

// Alert
export const SHOW_ALERT = "SHOW_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// textEditor
export const GET_TEXT_EDITOR = "GET_TEXT_EDITOR";
export const CAREATE_ATTRIBUTE = "CAREATE_ATTRIBUTE";
export const CAREATE_ATTRIBUTE_GROUP = "CAREATE_ATTRIBUTE_GROUP";
// snackbar
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";

