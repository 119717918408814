import React from "react";
import styles from "./pricing-side-componet.module.css";
import InfoItem from "./info-item";

const PricingSidebarCard = ({
  sectionHeading,
  rank,
  sellerFinalPrice,
  priceMatch,
  breakEvenPrice,
  validSeller,
}) => {
  return (
    <div className={styles.sectionMainContainer}>
      <div className={styles.sectionHeadingContainer}>
        <h3 className={styles.sectionHeading}>{sectionHeading}</h3>
        <p>Rank: {rank}</p>
      </div>
      <div className={styles.sectionHeadingContainer}>
        <h3 className={styles.sectionHeading}>Seller Final Price:</h3>
        <p className={styles.sectionPrice}>{sellerFinalPrice}</p>
      </div>

      <InfoItem crawlType="Price Match:" CrawlDescription={`${priceMatch}`} />
      <InfoItem
        crawlType="Break Even Price:"
        CrawlDescription={`${breakEvenPrice}`}
      />

      <div
        className={styles.sectionHeadingContainer}
        style={{ marginBottom: "1rem" }}
      >
        <p>Valid Seller</p>
        <p className={styles.checkValidSeller}>{validSeller}</p>
      </div>
    </div>
  );
};

export default PricingSidebarCard;
