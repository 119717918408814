import moment from "moment";
import React from "react";

const AnswerCard = ({ item }) => {
  return (
    <div className="d-flex flex-column gap-10 p-3 bg-light-warning border-radius-10 border-grey my-3">
      <div>
        <p style={{ fontSize: "12px" }} className="lead text-muted">
          <span className=" font-weight-bold">Data:</span>
          {moment(item.answerAt).format("ll")}
        </p>
        <h6>Answer: By {item?.answerBy}</h6>
        <p>{item?.answer}</p>
      </div>
    </div>
  );
};

export default AnswerCard;
