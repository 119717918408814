import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTicketbyId } from "../../../../actions/tickets";
import moment from "moment";
import ImagePreview from "./ImagePreview";

const Message = ({ selectedItem, isMount, setmessageArr }) => {
  // Hooks
  const dispatch = useDispatch();

  const [message, setMessage] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setloading] = useState(false);

  const getSingleTicket = useCallback(
    async (id) => {
      setloading(true);
      try {
        const res = await dispatch(getTicketbyId(id));
        if (res) {
          setMessage(res?.messages);
          setmessageArr(res?.messages);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem?._id, isMount]
  );
  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe && selectedItem?._id) {
      getSingleTicket(selectedItem?._id);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?._id, isMount]);

  //preiew images chnage
  const handlePreview = (index, i) => {
    const imageToPreview = message[index]?.images[i];
    setPreviewImage(imageToPreview);
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    link.click();
  };
  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <div className=" d-flex flex-column gap-10 ">
          {!message.length ? "No Chat " : <></>}
          {message.map((item, index) => (
            <div
              key={index}
              className={
                item?.userType === "admin"
                  ? "msg-container-send"
                  : "msg-container-received"
              }
            >
              <h4>
                {item?.user?.fullName}: {moment(item?.createdAt).format("l")}
              </h4>
              <p>{item?.message}</p>
              {message.length === 0 ? (
                <></>
              ) : (
                <div className=" d-flex flex-column gap-8">
                  {item?.images?.map((img, i) => (
                    <div
                      key={i}
                      className="d-flex justify-content-start align-center border-radius-10 border-grey px-3 py-2 gap-10 flex-wrap"
                    >
                      <div
                        className=" border-grey border-radius-10 hidden"
                        style={{ width: "70px", height: "70px" }}
                      >
                        <img
                          className="w-100 h-100 object-fit-contain"
                          key={i}
                          src={img}
                          alt="product"
                        />
                      </div>

                      <div>
                        <h6 className="m-0 font-weight-700">
                          {selectedItem?.ticketId} Attachment({i + 1})
                        </h6>
                        <p
                          className="m-0 c-pointer"
                          onClick={() => handlePreview(index, i)}
                        >
                          Preview
                        </p>
                        <p
                          className="m-0 c-pointer"
                          onClick={() => handleDownload(img)}
                        >
                          Download
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          {previewImage && (
            <ImagePreview
              image={previewImage}
              onClose={() => setPreviewImage(null)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Message;
