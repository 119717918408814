import { Drawer } from "@mui/material";
import { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import CustomeSelect from "./CustomeSelect";
import TicketSummary from "./TicketSummary";
import Message from "./Message";
import SendMessage from "./SendMessage";
import { useDispatch } from "react-redux";
import { updateTicketStatus } from "../../../../actions/tickets";
import { useEffect } from "react";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

const ManageTicket = ({
  open,
  setOpen,
  selectedItem,
  setSelectedItem,
  isRender,
  setIsRender,
  outerLoading,
}) => {
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [isMount, setisMount] = useState(false);
  const [messageArr, setmessageArr] = useState([]);

  const handleExportChat = (message) => {
    debugger;
    setloading(true);
    // const alignmentClass = userType === "admin" ? "align-left" : "align-right";

    const htmlTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Chat Data Export</title>
        <style>
        #chatContainer {
          display: flex;
          flex-direction: column;
        }
        .message {
          border: 1px solid #ccc;
          padding: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }
        .message img {
          max-width: 100px;
          max-height: 100px;
          margin-right: 10px;
        }
        .message .content {
          flex: 1;
        }
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #007bff;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          margin-right: 10px;
        }
        .align-left {
          align-self: flex-start;
        }
        .align-right {
          align-self: flex-end;
        }
        </style>
      </head>
      <body>
        <h1>Chat Messages</h1>
        <div id="chatContainer"></div>
        <script>
          var chatArray = ${JSON.stringify(message)};
          var chatContainer = document.getElementById('chatContainer');
          chatArray.forEach(function(chat) {
            var chatMessage = document.createElement('div');
            chatMessage.classList.add('message', 'align-left');
            var avatar = document.createElement('div');
            avatar.classList.add('avatar');
            avatar.textContent = chat.user.fullName.charAt(0).toUpperCase();
            chatMessage.appendChild(avatar);
            var content = document.createElement('div');
            content.classList.add('content');
            var userName = document.createElement('p');
            userName.textContent = chat.user.fullName;
            content.appendChild(userName);
            var message = document.createElement('p');
            message.textContent = chat.message;
            content.appendChild(message);
            chat.images.forEach(function(imageUrl) {
              var image = document.createElement('img');
              image.src = imageUrl;
              content.appendChild(image);
            });
            chatMessage.appendChild(content);
            chatContainer.appendChild(chatMessage);
          });
        </script>
      </body>
      </html>
    `;

    // Create a Blob with the HTML template
    const blob = new Blob([htmlTemplate], { type: "text/html" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link and click it to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "chat_data.html";
    link.click();

    // Clean up the URL and link
    URL.revokeObjectURL(url);

    setloading(false);
  };
  // close ticket
  const closeTiket = () => {
    setOpen(false);
  };

  // handle submit status
  const handleSubmit = async (status, priority, isEscalated) => {
    setloading(true);
    try {
      const res = await dispatch(
        updateTicketStatus({
          ticketId: selectedItem?._id,
          priority: priority ? priority : undefined,
          isEscalated: isEscalated === null ? undefined : isEscalated,
          status: status ? status : undefined,
        })
      );
      if (res) {
        setloading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {}, [selectedItem?._id]);

  return (
    <Drawer anchor={"right"} open={open} onClose={closeTiket}>
      <div className="ticket-container">
        <div className="d-flex justify-content-between align-center px-3 py-4">
          <div>
            <h3 className="m-0"> Manage Ticket </h3>
            <p>{selectedItem?.ticketId}</p>
          </div>
          <div>
            <RxCrossCircled className="close-icon" onClick={closeTiket} />
          </div>
        </div>
        <hr className="m-0" />

        {outerLoading && <div className="p-4 mx-auto">Loading....</div>}
        {!outerLoading && (
          <>
            <div className=" px-3 py-4">
              <CustomeSelect
                title="Ticket Status:"
                value={capitalizeFirstLetter(selectedItem?.status)}
                background={
                  selectedItem?.status === "open" ? "#e7f6ee" : "#ecf0f4"
                }
                color={selectedItem?.status === "open" ? "#4bb87a" : "#2a2a2a"}
              >
                <div
                  value="open"
                  style={
                    selectedItem?.status === "open"
                      ? { background: "#4bb87a" }
                      : {}
                  }
                  className={`px-4 py-3 c-pointer`}
                  onClick={() => handleSubmit("open", null, null)}
                >
                  Open
                </div>
                <hr className="m-0" />
                <div
                  value="closed"
                  className={`px-4 py-3 c-pointer  ${
                    selectedItem?.status === "closed" ? "bg-grey" : ""
                  }`}
                  onClick={() => handleSubmit("closed", null, null)}
                >
                  Closed
                </div>
                <hr className="m-0" />
                <div
                  value="Cancelled"
                  className={`px-4 py-3 c-pointer  ${
                    selectedItem?.status === "cancelled" ? "bg-grey" : ""
                  }`}
                  onClick={() => handleSubmit("cancelled", null, null)}
                >
                  Cancelled
                </div>
                <hr className="m-0" />
              </CustomeSelect>

              <CustomeSelect
                title="Priority:"
                value={capitalizeFirstLetter(selectedItem?.priority)}
                background="#fff8e6"
                color="#ffc12b"
              >
                <div
                  className={`px-4 py-3 c-pointer  ${
                    selectedItem?.priority === "normal" ? "bg-grey" : ""
                  }`}
                  onClick={() => handleSubmit(null, "normal", null)}
                >
                  Normal
                </div>
                <hr className="m-0" />
                <div
                  className={`px-4 py-3 c-pointer  ${
                    selectedItem?.priority === "high" ? "bg-grey" : ""
                  }`}
                  onClick={() => handleSubmit(null, "high", null)}
                >
                  High
                </div>
                <hr className="m-0" />
              </CustomeSelect>
              <div
                onClick={() =>
                  handleSubmit(null, null, !selectedItem?.isEscalated)
                }
                className="d-flex justify-content-start align-center border-grey px-3 py-1  border-radius-10 py-3 c-pointer "
                style={
                  selectedItem?.isEscalated
                    ? {
                        background: "#fee9eb",
                        color: "#fb2a3d",
                        border: "1px solid #fb2a3d ",
                      }
                    : {
                        background: "#e5e5e5",
                        color: "#000000",
                        border: "1px solid #7d7d7d ",
                      }
                }
              >
                <input
                  type="radio"
                  name="isEscalated"
                  checked={selectedItem?.isEscalated}
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      isEscalated: e.target.checked,
                    })
                  }
                />

                <h4 className=" m-0 underlined ml-2">Escalated Ticket</h4>
              </div>
            </div>
            <hr className="m-0" />
            <div>
              <TicketSummary selectedItem={selectedItem} />
            </div>
            <hr className="m-0" />
            <div className="p-3">
              <h3>Chat</h3>
              <Message
                isMount={isMount}
                selectedItem={selectedItem}
                setmessageArr={setmessageArr}
              />
            </div>

            <hr className="m-0" />
            <div className=" p-3">
              <SendMessage
                setisMount={setisMount}
                isMount={isMount}
                selectedItem={selectedItem}
              />
            </div>
            <hr className="m-0" />
            <div className="p-3 text-center">
              <h3
                className=" underlined c-pointer"
                onClick={() => handleExportChat(messageArr)}
              >
                {loading ? "Loading...." : "Export Chat"}
              </h3>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default ManageTicket;
