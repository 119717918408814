import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert as ReactAlert } from "react-bootstrap";

const Alert = ({ alert }) =>
  alert !== null &&
  alert.length > 0 &&
  alert.map(alert => (
    <ReactAlert style={alert.style} key={alert.id} variant={alert.alertType}>
      {alert.msg}
    </ReactAlert>
  ));

Alert.propTypes = {
  alert: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alert: state.alert
});

export default connect(mapStateToProps)(Alert);
