import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { genrateSeoTitle } from "../../../../../actions/seo";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

// import Snackbar from "material-ui/Snackbar";

const ExampleCard = (props) => {
  // debugger;
  const { isTitle, title } = props;
  const { categoryId, brandId, brandName, categoryName } = useParams();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [skuTitle, setskuTitle] = useState(null);
  const [desHtml, setDesHtml] = useState(null);
  // const Count = JSON.stringify(skuTitle);

  const handleSubmite = async () => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
    setloading(true);
    const res = await dispatch(genrateSeoTitle(brandId, categoryId, title));
    if (res.title) {
      setskuTitle(res?.title);
      if (!isTitle) {
        const html = parse(res?.title);
        setDesHtml(html);
      }
    }
  };

  return (
    <div className="card  cardRaduis my-4">
      <div className="card-header skuCardConatiner ">
        <div>
          <p className="h4"> {`${brandName} | ${categoryName}`}</p>

          <p className="h6 my-2">
            {" "}
            character Count:{skuTitle?.length >= 1 ? skuTitle.length : 0}
          </p>
        </div>
        <div
          className="skuContainer"
          onClick={handleSubmite}
          style={{ cursor: "pointer" }}
        >
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z" />
            </svg>
          )}
          <p className="h6 mt-1 ml-2"> Genrate title with diffrent Sku</p>
        </div>
      </div>
      <div className="card-body ">
        <p className="h6 font-weight-normal">{isTitle ? skuTitle : desHtml}</p>
      </div>
    </div>
  );
};

export default ExampleCard;
