import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateOrderStatus } from "../../../../actions/order";
import { showSnackBar } from "../../../../actions/showSnackBar";
import { toISOStringFromat } from "../../../../utils/dateFormate";

const UpdateOrderComp = ({ SelectItem, setEdit }) => {
  const dispatch = useDispatch();
  const [OrderStatus, setOrderStatus] = useState({
    status: SelectItem?.status ?? "",
    date: SelectItem?.availabilityDate ?? "",
  });

  const [laoding, setlaoding] = useState(false);

  const handleOrderStatus = async (id) => {
    try {
      if (OrderStatus.status !== "" && id !== undefined) {
        setlaoding(true);
        if (OrderStatus.status !== "Backordered") {
          const res = await dispatch(
            UpdateOrderStatus({
              orderItemId: id,
              status: OrderStatus?.status,
            })
          );
          if (res) {
            setEdit({
              open: false,
              edit: "",
              title: "",
            });
            setlaoding(false);
          }
        } else {
          if (OrderStatus?.date) {
            const res = await dispatch(
              UpdateOrderStatus({
                orderItemId: id,
                status: OrderStatus?.status,
                availabilityDate: new Date(OrderStatus?.date),
              })
            );
            if (res) {
              setEdit({
                open: false,
                edit: "",
                title: "",
              });
              setlaoding(false);
            } else {
              setlaoding(false);
            }
          } else {
            setlaoding(false);
            dispatch(showSnackBar("Please select  Date", "error", true));
          }
        }
      } else {
        dispatch(
          showSnackBar(
            "Please select Order Status first and Date",
            "error",
            true
          )
        );
      }
    } catch (error) {
      setlaoding(false);
    }
  };

  const handleAlert = () => {
    dispatch(
      showSnackBar(
        "(Please confirm Shipping & Tracking, Fraud, & sale Analysis before making available)",
        "error",
        true
      )
    );
  };
  return (
    <div className="px-3">
      <div className="status-input-container ">
        <div
          onClick={() =>
            setOrderStatus({ ...OrderStatus, status: "Available" })
          }
          className={
            OrderStatus?.status === "Available"
              ? "status-radio-select c-pointer"
              : "status-radio-button  c-pointer"
          }
        >
          <input
            type="radio"
            name="Available"
            value="Available"
            checked={OrderStatus?.status === "Available"}
            onChange={(e) =>
              setOrderStatus({ ...OrderStatus, status: e.target.value })
            }
          />{" "}
          <span>Available</span>
        </div>
        <div
          onClick={() =>
            setOrderStatus({ ...OrderStatus, status: "Not Available" })
          }
          className={
            OrderStatus?.status === "Not Available"
              ? "status-radio-select  c-pointer"
              : "status-radio-button  c-pointer"
          }
        >
          <input
            type="radio"
            name="Not Available"
            value="Not Available"
            checked={OrderStatus?.status === "Not Available"}
            onChange={(e) =>
              setOrderStatus({ ...OrderStatus, status: e.target.value })
            }
          />{" "}
          <span>Not Available</span>
        </div>
        <div
          onClick={() =>
            setOrderStatus({ ...OrderStatus, status: "Backordered" })
          }
          className={
            OrderStatus?.status === "Backordered"
              ? "status-radio-select d-flex justify-content-between align-center c-pointer"
              : "status-radio-button d-flex justify-content-between  c-pointer "
          }
        >
          <div>
            <input
              type="radio"
              name="Backordered"
              value="Backordered"
              checked={OrderStatus?.status === "Backordered"}
              onChange={(e) =>
                setOrderStatus({ ...OrderStatus, status: e.target.value })
              }
            />{" "}
            <span>Backordered</span>
          </div>

          <input
            className="order-status-date-input"
            type="date"
            name="backordered"
            value={toISOStringFromat(OrderStatus.date)}
            onChange={(e) =>
              setOrderStatus({ ...OrderStatus, date: e.target.value })
            }
          />
        </div>
      </div>
      <div className=" mt-5 pb-4">
        <button
          onClick={() =>
            OrderStatus?.status !== "Available"
              ? handleOrderStatus(SelectItem?._id)
              : SelectItem?.orCogs?.status &&
                SelectItem?.orDsFees?.status &&
                SelectItem?.orShipping?.status
              ? handleOrderStatus(SelectItem?._id)
              : handleAlert()
          }
          className="update-Order-button"
        >
          {laoding ? "Loading...." : "Update Order"}
        </button>
      </div>
    </div>
  );
};

export default UpdateOrderComp;
