// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inspiration_image__1vs6g{\n    display: none;\n}", "",{"version":3,"sources":["webpack://src/pages/admin/inspirationTags/inspiration/inspiration.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".image{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "inspiration_image__1vs6g"
};
export default ___CSS_LOADER_EXPORT___;
