import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { useDispatch } from "react-redux";
import { addKeyword, getKeyword } from "../../../../actions/seo";
import CircularProgress from "@mui/material/CircularProgress";
import "../titleAndDescription/styles.css";
import TopBarProgress from "react-topbar-progress-indicator";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const KeywordList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setloading] = useState(false);
  const [pageloader, setpageloader] = useState(true);
  const [keywordData, setkeywordData] = useState([]);
  const [data, setdata] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [message, setmessage] = useState({
    msg: null,
    state: false,
  });

  // fetch keyword list
  useEffect(() => {
    async function fetchKeyword() {
      const res = await dispatch(getKeyword("", pagination.currentPage));
      setpageloader(false);
      setpagination({
        ...res.pagination,
      });
      setkeywordData(res.keywords);
    }
    fetchKeyword();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage]);

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleForce = (data, fileInfo, originalFile) => {
    let tempArr = [];

    for (let index = 0; index <= data.length; index++) {
      const element = data[index];

      tempArr.push({
        keyword: element?.keyword,
        ams: element?.avg__monthly_searches,
        tag: data[0]?.keyword,
      });
    }
    setdata(tempArr);
  };

  const handleDarkSideForce = () => {};

  // add keyword
  const handleSubmit = () => {
    // array send in chunks chuck size 1000
    function* chunks(arr, n) {
      for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
      }
    }
    const ThousChunks = [...chunks(data, 1000)];
    if (data.length > 0) {
      setloading(true);
      // send to api 1000 entry at one time
      for (let index = 0; index < ThousChunks.length; index++) {
        dispatch(addKeyword(ThousChunks[index]));
      }
    } else {
      setmessage({
        msg: "upload CSV file first",
        state: true,
      });
    }
    setTimeout(() => {
      setloading(false);
      setmessage({
        msg: null,
        state: false,
      });
    }, 5000);
  };
  // pagnination logic
  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };
  return (
    <>
      {pageloader ? (
        <TopBarProgress />
      ) : (
        <>
          <nav className="navbar navbar-light bg-light py-4">
            <span className="navbar-brand mb-0 h3">keyword list</span>
            <div className=" d-flex justify-content-between align-items-center flex-2">
              <div className="d-flex  flex-column">
                <p className="h5 font-weight-bold">upload csv files</p>
                <CSVReader
                  cssClass="csv-reader-input"
                  //   label="upload csv"
                  onFileLoaded={(data, fileInfo, originalFile) =>
                    handleForce(data, fileInfo, originalFile)
                  }
                  onError={handleDarkSideForce}
                  parserOptions={papaparseOptions}
                  inputId="ObiWan"
                  inputName="ObiWan"
                  inputStyle={{ color: "red" }}
                />
              </div>

              <button
                onClick={() => handleSubmit()}
                type="button"
                className="btn btn-success"
              >
                {loading && <CircularProgress size={15} />} Submit
              </button>
            </div>
          </nav>
          {message.state ? (
            <div class="alert alert-primary text-center" role="alert">
              {message.msg}
            </div>
          ) : (
            <></>
          )}

          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Attribute Groups"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th> #</th>
                      <th> keyword</th>
                      <th> avg monthly searches</th>
                      <th> Create At</th>
                      <th> Update At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keywordData.length === 0 ? (
                      <h1 className=" w-100 bg-white">record not found</h1>
                    ) : (
                      <></>
                    )}
                    {keywordData.map((item, index) => (
                      <tr key={index}>
                        <td className=" font-weight-bold">{index + 1}</td>
                        <td>{item.keyword}</td>
                        <td>{item.ams}</td>
                        <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                        <td>{moment(item.updateAt).format("MM/DD/YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {keywordData?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default KeywordList;
