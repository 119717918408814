import React, { useState } from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";
import Pagination from '@mui/material/Pagination';


// Redux

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../components/alert/alert.component";
import axios from "axios";
import { apiURL } from "../../../config/default";
import Moment from "react-moment";
TopBarProgress.config({
  barThickness: 4,
  barColors: {
    0: "rgb(51, 201, 45, .7)",
    ".3": "rgb(255, 77, 107, .7)",
    "1.0": "rgb(200, 125, 255, .7)",
  },
  shadowBlur: 5,
  shadowColor: "rgba(0, 0, 0, .5)",
});

const VendorOrders = function (props) {
  const [allOrders, setAllOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const fetchData = async (pageNumber, id) => {
    try {
      const res = await axios(
        `${apiURL}order/get-vendor-orders/${id}?page=${pageNumber}`
      );
      setAllOrders(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  React.useEffect(() => {
    const link = props.location.pathname.split("/");
    const id = link[link.length - 1];

    fetchData(1, id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginatinChange = (event, value) => {
    setPage(value);
    const link = props.location.pathname.split("/");
    const id = link[link.length - 1];
    fetchData(value, id);
  };

  const renderOrders = allOrders?.orderItems?.map((item, index) => {
    return (
      <tr key={item?._id ?? index}>
        <td> {index + 1} </td>
        <td> {item.title} </td>
        <td>{item.itemOrderNumber}</td>
        <td>
          <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
        </td>
        <td>${item.itemPrice}</td>
        <td>{item.quantity}</td>
        <td>
          <div>
            <img
              src={item?.mainImage}
              width="100px"
              style={{
                borderRadius: "50%",
                maxWidth: "100px",
                objectFit: "contain",
              }}
              alt=""
            />
          </div>
        </td>
        <td>{item.status}</td>

        <td>
          <div className="d-flex align-items-center justify-content-between">
            <Link
              className="d-inline-block"
              to={`view-vendor-order/${item._id}`}
            >
              <button className="btn btn-sm btn-info">View Order</button>
            </Link>{" "}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}

      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          {" "}
          Vendor Orders{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/vendors">Users</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Vendor Orders{" "}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {allOrders?.orderItems?.length} Orders
              </h4>

              <Alert />

              <div className="col-xs-12 table-bordered table-responsive">
                {loading ? (
                  <h3 className="text-center">Loading...</h3>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {" "}
                          #{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Name
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Order Number{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Created Date{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Price{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Quantity{" "}
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Image
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Status
                        </th>
                        <th style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allOrders?.orderItems?.length === 0 ? (
                        <tr className="text-center">
                          <td colSpan="6">No Orders found</td>
                        </tr>
                      ) : (
                        renderOrders
                      )}
                    </tbody>
                  </table>
                )}
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  count={allOrders?.pagination?.totalPages}
                  page={page}
                  onChange={handlePaginatinChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorOrders
