/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
// import CSVReader from "react-csv-reader";
import TopBarProgress from "react-topbar-progress-indicator";
import { getStores } from "../../../actions/store";
import * as XLSX from 'xlsx'
import ProgressBar from "react-bootstrap/ProgressBar";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  bulkProducts,
  bulkProductsUpload,
  etlErrorLogs,
} from "../../../actions/product";
import { subCategoryNames } from "../../../actions/subCategory";
import { childSubCategoryNames } from "../../../actions/childSubCategory";
import { ToastContainer, toast } from "react-toastify";
import { getAttribute } from "../../../actions/product";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import axios from "axios";
import { apiURL } from "../../../config/default";
// eslint-disable-next-line no-unused-vars
const handleForce = (data, fileInfo) => console.log(data, fileInfo);
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class AddBulkProducts extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      handleDarkSideForce: "",
      subCategories: "",
      childCategories: "",
      buttonChange: "disabled",
      count: 0,
      getStores: [],
      brand: "",
      image: "",
      attributes: [],
      attributeNotFound: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
        this.fileData();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  attributeIdGetter = (name) => {
    let nn = name;
    if (
      name.includes("Style") &&
      name !== "Revers Side Style" &&
      name !== "Handle Style"
    ) {
      name = name.substring(0, 5);
    }
    if (name.length < 9 && name.includes("Finish")) {
      name = name.substring(0, 6);
    }
    if (
      name.includes("Material Type") &&
      name !== "Material Type Front" &&
      name !== "Material Type Back"
    ) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    if (name.includes("Item Pattern")) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    var id = this.state.attributes.find(function (o) {
      return o.name === name.trim();
    });
    if (id && id._id) {
      return id._id;
    } else {
      console.log(nn);
      this.setState({
        attributeNotFound: [...this.state.attributeNotFound, name],
      });
    }
  };
  attributeNameGetter = (attributeId) => {
    var attributeName = this.state.attributes.find(function (o) {
      return o._id === attributeId.trim();
    });
    if (attributeName && attributeName.name) {
      return attributeName.name;
    }
  };
  fileData = async () => {
    this.setState({ buttonChange: "disabled" });
    const response = await this.props.getStores();
    const _res = await this.props.subCategoryNames();
    // const __res = await this.props.mainCategories();
    // const ___res = await this.props.childSubCategoryNames();
    const attribute = await this.props.getAttribute();

    if (_res) {
      this.setState({
        subCategories: _res,
        attributes: attribute.data,
        getStores: response.data,
        buttonChange: "",
      });
    }
  };
  handleFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });
      this.setState({
        buttonChange: "",
        data: data,
        cols: make_cols(ws["!ref"]),
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }

  _submitHandler = async () => {
    // this.fileData();
    const {
      data,
      subCategories,
      childCategories,
      mainCategories,
      brand,
      image,
    } = this.state;

    const dataTosend = [...data];
    if (!data.length) {
      toast.error(`Please Upload CSV file`, { containerId: "B" });
      return;
    }

    this.setState({ buttonChange: "disabled", loading: true });
    for (let index1 = 0; index1 < Math.ceil(data.length / 1000); index1++) {
      console.log({ dataTosend, ciel: Math.ceil(dataTosend.length / 1000) });
      const thousandJson = dataTosend.splice(0, 1000);

      try {
        const res = await axios.post(`${apiURL}admin/bulk-cost`, {
          data: thousandJson,
        });

        if (res.status === 200) {
          toast.success(res.data, { containerId: "B" });
          this.setState({ count: this.state.count + 1 });
        }
        console.log(res);
      } catch (err) {
        toast.error(err?.response?.data, { containerId: "B" });
        console.log(err);
      }
    }

    this.setState({ loading: false, buttonChange: "", count: "All", data: [] });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Upload Products{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bulk Products
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Upload Bulk Product Cost
                </h4>

                <Alert />

                <form className="forms-sample p-2">
                  <div className="form-group">
                    <div className="col-md-4">
                      <label style={{ fontWeight: "bold" }}>
                        CSV File upload
                      </label>
                    </div>

                    <div className="input-group col-md-8 col-xs-12">
                      {/*  <div className="input-group col-xs-12">
                           <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                            </div>*/}
                      <DragDropFile handleFile={this.handleFile}>
                        <div className="row">
                          <div className="col-xs-12">
                            <DataInput handleFile={this.handleFile} />
                          </div>
                        </div>
                      </DragDropFile>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {this.state.loading && this.state.loading === true ? (
                        <div className="text-center">
                          <ProgressBar
                            animated
                            now={this.state.count}
                            max={Math.ceil(this.state?.data?.length / 1000)}
                          />
                          <h5>
                            you have added {` ${this.state.count} `} products
                            proceeded
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className={`btn btn-primary mr-2 ${this.state.buttonChange}`}
                  >
                    Submit
                  </button>
                </form>
                {this.state.attributeNotFound &&
                  this.state.attributeNotFound.map((item, indx) => (
                    <p key={indx}>{item} attribute not found</p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div className="form-inline">
        <div className="form-group" style={{ marginLeft: "12px" }}>
          <label style={{ fontWeight: "bold" }} htmlFor="file">
            {" "}
          </label>
          <input
            type="file"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
// childCategories.propTypes = {
//   childCategories: PropTypes.func.isRequired,
// };
subCategoryNames.prototype = {
  subCategoryNames: PropTypes.func.isRequired,
};
// mainCategories.prototype = {
//   mainCategories: PropTypes.func.isRequired,
// };
childSubCategoryNames.prototype = {
  childSubCategoryNames: PropTypes.func.isRequired,
};
bulkProducts.prototype = {
  bulkProducts: PropTypes.func.isRequired,
};
bulkProductsUpload.propTypes = {
  bulkProductsUpload: PropTypes.func.isRequired,
};
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
etlErrorLogs.propTypes = {
  etlErrorLogs: PropTypes.func.isRequired,
};

export default connect(null, {
  subCategoryNames,
  getAttribute,
  childSubCategoryNames,
  bulkProducts,
  getStores,
  bulkProductsUpload,
  etlErrorLogs,
})(AddBulkProducts);
