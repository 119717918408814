/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import styles from "./style.module.css";
import { ConvertImgToBase64 } from "../../../utils/convertImgToBase64";
import { call } from "./apiCall/Call";
import axios from "axios";
import { apiURL } from "../../../config/default";
import EmailHeader from "./components/EmailHeader/EmailHeader";
import HeaderModal from "./components/EmailHeader/Popup";
import BodyModal from "./components/EmailBody/Popup";
import EmailFooter from "./components/EmailFooter/EmailFooter";
import FooterModal from "./components/EmailFooter/Popup";
import { convertToRaw, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const LogoInpStyling = {
  display: "block",
  width: "50%",
  height: "50px",
  margin: "0 auto",
  borderRadius: "8px",
};

const Emails = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState({});
  const [showHeaderBgClr, setShowHeaderBgClr] = useState(true);
  const [desktopView, setDesktopView] = useState(true);
  const [editorState, setEditiorState] = React.useState(
    EditorState.createEmpty()
  );

  const [footerColumns, setFooterColumns] = useState([]);
  const [bodyContent, setBodyContent] = useState();
  const [bodyIndex, setBodyIndex] = useState(null);
  const [activeBodyData, setActiveBodyData] = useState([]);
  const [modals, setModals] = useState({
    headerModal: false,
    bodyModal: false,
    footerModal: false,
  });

  const [header, setHeader] = useState({
    style: {
      backgroundColor: "",
    },
    logo: {
      width: 200,
      height: 60,
      img: "https://ci4.googleusercontent.com/proxy/5hRuqg-S8NGxnFzDd599gokI1WOPiUaI4jvPVxhnAVNjkwlMwtjlidhOyJFJm1tRYcH3NiNM7Q_JS_Wq3f-RdCBoL1anuRo5NcBFQ8ua=s0-d-e1-ft#https://s3.us-west-1.amazonaws.com/sundialhome.com/logo.png",
    },
    navbar: {
      links: [
        {
          text: "",
          slug: "",
        },

        {
          text: "",
          slug: "",
        },
      ],
      style: {
        "font-size": "1rem",
        borderRadius: "8px",
        color: "#000000",
        marginLeft: "6px",
        marginRight: "6px",
        padding: "5px",
        whiteSpace: "nowrap",
      },
    },
  });

  const getEmails = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiURL}admin/get-all-email-templates`);

      setEmails(res.data);
      setLoading(false);

      return res.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchEmail = async () => {
    const res = await call("admin/get-header-footer-email", "get", null);
    const { headerFrontend, footerFrontend } = res.data;

    setHeader(changeStyleObject(JSON.parse(headerFrontend), "react"));
    setFooterColumns(JSON.parse(footerFrontend));
  };

  useEffect(() => {
    getEmails();
    fetchEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { 1: screen } = location.search.split("?s=");
    setDesktopView(screen === "d");
  }, [location.search]);

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const template = emails?.templates.find((email) => email._id === id);
    setLoading(true);

    try {
      const res = await axios.post(`${apiURL}admin/update-email-template`, {
        ...template,
        id: template._id,
      });
      setLoading(false);
      toast.success(res.data.message, { containerId: "B" });
    } catch (error) {
      toast.error(error.data.message, { containerId: "B" });
      setLoading(false);
    }
  };

  const handleChange = (e, index, id) => {
    const { value, name } = e.target;

    const { templates } = emails;

    setEmails({
      templates: templates.map((template) =>
        template._id === id ? { ...template, [name]: value } : template
      ),
    });
  };
  TopBarProgress.config({
    barThickness: 4,
    barColors: {
      0: "rgb(51, 201, 45, .7)",
      ".3": "rgb(255, 77, 107, .7)",
      "1.0": "rgb(200, 125, 255, .7)",
    },
    shadowBlur: 5,
    shadowColor: "rgba(0, 0, 0, .5)",
  });

  const openModal = (modal) => {
    setModals({ ...modals, [modal]: true });
  };
  const closeDrawer = (modal) => {
    setModals({ ...modals, [modal]: false });
  };
  const handleLogoStyling = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "width" && value < 100) return;
    if (value <= 45) return;
    setHeader({
      ...header,
      logo: {
        ...header.logo,
        [name]: value,
      },
    });
  };

  const handleLogoChange = async (e) => {
    e.preventDefault();
    const image = await ConvertImgToBase64(e.target.files[0]);
    setHeader({
      ...header,
      logo: {
        ...header.logo,
        img: image,
      },
    });
  };

  const handleHeaderBackgroundColor = (e) => {
    const value = e.target.value;

    setHeader({
      ...header,
      style: {
        ...header.style,
        backgroundColor: !showHeaderBgClr && value,
      },
    });
  };
  const toggleHeaderBgColor = () => {
    setShowHeaderBgClr(!showHeaderBgClr);
    if (!showHeaderBgClr) {
      setHeader({
        ...header,
        style: {
          ...header.style,
          backgroundColor: "transparent",
        },
      });
    }
  };

  const handleAddLink = () => {
    setHeader({
      ...header,
      navbar: {
        ...header.navbar,
        links: [...header.navbar.links, { text: "", slug: "" }],
      },
    });
  };

  const handleRemoveLink = (index) => {
    setHeader({
      ...header,
      navbar: {
        ...header.navbar,
        links: header.navbar.links.filter((_, i) => i !== index),
      },
    });
  };

  const handleNavLinkChange = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;

    setHeader((header) => ({
      ...header,
      navbar: {
        ...header.navbar,
        links: header.navbar.links.map((item, i) =>
          i === index ? { ...item, [name]: value } : item
        ),
      },
    }));
  };

  // converting style objects
  const changeStyleObject = (obj, conversion = "css") => {
    const convertToCssStyles = (styles) => {
      const keys = Object.keys(styles);

      let cssStyles = {};

      const condition = (letter, acc, i) =>
        conversion === "css"
          ? /[A-Z]/.test(letter)
          : letter === "-" || acc?.[i - 1] === null;

      const handleCase = (letter, acc, i) =>
        conversion === "css"
          ? `-${letter.toLowerCase()}`
          : acc[i - 1] === null
          ? letter.toUpperCase()
          : null;

      keys.forEach((key) => {
        const newKey = key
          .split("")
          .reduce(
            (acc, k, i) =>
              condition(k, acc, i)
                ? [...acc, handleCase(k, acc, i)]
                : [...acc, k],
            []
          )
          .filter((k) => k)
          .join("");

        cssStyles = { ...cssStyles, [newKey]: styles[key] };
      });

      return cssStyles;
    };

    const changeReactStylesToCssStyles = (reactStyles, starting = false) => {
      const keys = Object?.keys(reactStyles);
      keys.forEach((key) => {
        if (key === "style" && starting) {
          const cssStyles = convertToCssStyles(reactStyles.style);
          reactStyles.style = cssStyles;
        }

        if (reactStyles[key].style) {
          const cssStyles = convertToCssStyles(reactStyles[key].style);
          reactStyles[key].style = cssStyles;
        }

        if (Object.keys(reactStyles[key]).length > 1) {
          changeReactStylesToCssStyles(reactStyles[key]);
        }
      });
      return reactStyles;
    };

    let convertedObj = changeReactStylesToCssStyles(obj, true);
    return convertedObj;
  };

  const handleUpdateHeaderFooter = async () => {
    const headerStyle = changeStyleObject(header, "css");
    setLoading(true);
    const res = await call("admin/update-email-header-footer", "post", {
      header: JSON.stringify(headerStyle),
      footer: JSON.stringify(footerColumns),
    });
    toast.success(res?.data?.message, { containerId: "B" });
    setLoading(false);
  }; // body
  const handleEditBody = (e, index) => {
    e.preventDefault();

    setActiveBodyData(emails.templates?.[index]?.additionalData);
    setBodyIndex(index);
    setModals({
      ...modals,
      bodyModal: true,
    });
    const { templates } = emails;

    setEditiorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(templates.find((item, i) => i === index).body)
        )
      )
    );
  };

  const onEditorStateChange = (draftObj) => {
    setEditiorState(draftObj);
    const html = draftToHtml(convertToRaw(draftObj.getCurrentContent()));
    const { templates } = emails;
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "remove") {
          return <></>;
        }
      },
    };

    setEmails({
      ...emails,
      templates: templates.map((item, index) =>
        index === bodyIndex ? { ...item, body: html } : item
      ),
    });
  };

  const handleLinkTextChange = (e, index1, index2) => {
    const { name, value } = e.target;
    const newData = [...activeBodyData];
    newData[index1][index2] = { ...newData[index1][index2], [name]: value };
    setActiveBodyData(newData);

    setEmails({
      templates: emails.templates.map((item1, _index1) =>
        _index1 === bodyIndex
          ? {
              ...item1,
              additionalData: item1.additionalData.map((item2, _index2) =>
                _index2 === index1
                  ? item2.map((x, i) =>
                      i === index2 ? { ...x, [name]: value } : x
                    )
                  : item2
              ),
            }
          : item1
      ),
    });
  };

  const handleRemoveBodySection = (index) => {
    setActiveBodyData(activeBodyData.filter((_, i) => i !== index));

    setEmails({
      templates: emails.templates.map((item, _index) =>
        _index === bodyIndex
          ? {
              ...item,
              additionalData: item.additionalData.filter(
                (_, idx) => idx !== index
              ),
            }
          : item
      ),
    });
  };

  const handleAddColumns = (col) => {
    let arr = col === 1 ? [{}] : [{}, {}];
    setActiveBodyData([...activeBodyData, arr]);

    setEmails({
      templates: emails.templates.map((item1, index1) =>
        index1 === bodyIndex
          ? { ...item1, additionalData: [...item1.additionalData, arr] }
          : item1
      ),
    });
  };

  const handleAddDataToBody = (index1, index2, obj) => {
    setActiveBodyData(
      activeBodyData.map((item1, _index1) =>
        _index1 === index1
          ? item1.map((_item1, _index2) =>
              _index2 === index2 ? { ..._item1, ...obj } : _item1
            )
          : item1
      )
    );

    setEmails({
      templates: emails.templates.map((item1, _index1) =>
        _index1 === bodyIndex
          ? {
              ...item1,
              additionalData: item1.additionalData.map((dItem, dIndex) =>
                dIndex === index1
                  ? dItem?.map((x, i) => (i === index2 ? obj : x))
                  : dItem
              ),
            }
          : item1
      ),
    });
  };

  const toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleImageChange = async (e, index1, index2) => {
    e.preventDefault();

    let file = e.target.files[0];

    const base64 = await toBase64(file);

    const newData = [...activeBodyData];
    newData[index1][index2] = { ...newData[index1][index2], url: base64 };
    setActiveBodyData(newData);

    setActiveBodyData(
      activeBodyData.map((item, index) =>
        index === index1
          ? item.map((x, i) => (x === index2 ? { ...x, url: base64 } : x))
          : item
      )
    );

    setEmails({
      templates: emails.templates.map((item1, _index1) =>
        _index1 === bodyIndex
          ? {
              ...item1,
              additionalData: item1.additionalData.map((_item, _index2) =>
                _index2 === index1
                  ? _item.map((x, i) =>
                      i === index2 ? { ...x, url: base64 } : x
                    )
                  : _item
              ),
            }
          : item1
      ),
    });
  };

  const getContent = (item) => {
    switch (item.type) {
      case "LINK":
        return (
          <div className="d-flex justify-content-center my-2">
            <button className="btn btn-secondary p-2">{item.text}</button>
          </div>
        );
      case "IMAGE":
        return (
          <div className="my-3 text-center d-flex align-items-center">
            <img
              alt=""
              src={item.url}
              width="100%"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </div>
        );
      default:
    }
  };

  const renderSection = (items, index1) => {
    const isArray = Array.isArray(items);
    return (
      isArray && (
        <div className="d-flex border p-2 position-relative">
          {isArray &&
            items?.map((item, index2) => (
              <div
                className="w-100 border d-flex align-items-center justify-content-center"
                style={{ minHeight: "100px" }}
              >
                {getContent(item)}
              </div>
            ))}
        </div>
      )
    );
  };

  const handleAddColumn = () => {
    setFooterColumns([
      ...footerColumns,
      {
        title: "Test title",
        links: [
          {
            text: "",
            slug: "",
          },
        ],
      },
    ]);
  };

  const handleAddFooterLink = (index) => {
    let temp = footerColumns;
    temp[index].links = [...temp[index].links, { text: "", slug: "" }];
    setFooterColumns([...temp]);
  };

  const handleTitleChange = (e, index) => {
    let temp = footerColumns;
    temp[index].title = e.target.value;
    setFooterColumns([...temp]);
  };

  const handleFooterInpChange = (e, parentIndex, childIndex) => {
    const value = e.target.value;
    const name = e.target.name;

    let temp = footerColumns;
    temp[parentIndex].links[childIndex] = {
      ...temp[parentIndex].links[childIndex],
      [name]: value,
    };
    setFooterColumns([...temp]);
  };

  const handleRemoveFooterLink = (parentIndex, childIndex) => {
    let temp = footerColumns;
    let filtered = temp[parentIndex].links.filter(
      (item, index) => index !== childIndex
    );
    temp[parentIndex].links = filtered;
    setFooterColumns([...temp]);
  };

  return (
    <div className="container-scroller" id={styles.emailsContainer}>
      {loading && <TopBarProgress />}

      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="page-header">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h1 className="page-title d-inline" style={{ fontSize: 30 }}>
            Emails
          </h1>
          <div className="d-flex align-items-center">
            <Link to="/admin/emails?s=d" className="active">
              <button className="border-0 mx-3" style={{ fontSize: "4rem" }}>
                <i
                  className={`icon-screen-desktop ${
                    desktopView && "color-primary"
                  }`}
                ></i>
              </button>
            </Link>
            <Link to="/admin/emails?s=m">
              <button className="border-0 mx-3" style={{ fontSize: "4rem" }}>
                <i
                  className={`icon-screen-smartphone ${
                    !desktopView && "color-primary"
                  }`}
                ></i>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="my-3">
        <button
          onClick={() => openModal("headerModal")}
          className="btn btn-primary color-white mx-2"
        >
          Edit Header
        </button>
        <button
          onClick={() => openModal("footerModal")}
          className="btn btn-primary color-white mx-2"
        >
          Edit Footer
        </button>
        <button
          disabled={loading}
          className="btn btn-info color-white mx-2"
          onClick={handleUpdateHeaderFooter}
        >
          Update
        </button>
      </div>

      <div>
        <form>
          <div className="container-fluid">
            <div className="row">
              {emails?.templates?.length ? (
                emails.templates.map((email, index) => (
                  <div className="col-md-6 my-3">
                    <h4>{email.title}</h4>
                    <div className="border border-info rounded p-3 h-100">
                      <div className="my-2">
                        <h6>keywords</h6>
                        <span>
                          Use curly braces {`{}`} before using these keywords
                        </span>
                        {email?.keywords?.map((keyword) => (
                          <span
                            style={{ borderRadius: "4px" }}
                            className="bg-secondary p-1 m-2 d-inline-block"
                          >
                            {`{${keyword}}`}
                          </span>
                        ))}
                      </div>
                      <div
                        className={`bg-white p-2 rounded ${
                          desktopView ? styles.desktopView : styles.mobileView
                        } ${styles.emailContainer}`}
                      >
                        <div className={styles.cameraArea}>
                          {" "}
                          <i>Speaker</i>
                          <b>Camera</b>
                        </div>
                        <div className="my-3">
                          <div>
                            <small className="text-secondary">Subject</small>
                          </div>
                          <input
                            type="text"
                            style={{
                              border: "none",
                              borderBottom: "1px solid #e8ecf1",
                            }}
                            className="form-control"
                            name="subject"
                            value={email.subject}
                            onChange={(e) => handleChange(e, index, email._id)}
                            placeholder="Subject"
                            id="subject"
                          />
                        </div>
                        <div className="my-2 d-flex align-items-center">
                          <div className="float-left">
                            {/* <img src={face12} alt="" /> */}
                            <img
                              className="rounded-circle"
                              src="/assets/images/email-user.png"
                              alt="user"
                            />
                          </div>
                          <div>
                            <strong className="ml-3">
                              admin@sundialhomeproducts.com
                            </strong>
                          </div>
                        </div>
                        <div className="my-5">
                          <EmailHeader header={header} />
                        </div>
                        <div
                          className={`${styles.bodyContainer} my-3 position-relative`}
                        >
                          <p
                            dangerouslySetInnerHTML={{ __html: email.body }}
                          ></p>
                          {email.additionalData?.map((item) =>
                            renderSection(item, index)
                          )}
                          <button
                            className="text-info border-0 bg-secondary rounded px-2 position-absolute"
                            id={styles.editEmailBodyBtn}
                            onClick={(e) => handleEditBody(e, index)}
                          >
                            Edit Body
                          </button>
                        </div>
                        <EmailFooter columns={footerColumns} />
                      </div>
                      <button
                        disabled={loading}
                        onClick={(e) => handleSubmit(e, email?._id)}
                        style={{ height: "fitContent" }}
                        type="button"
                        className="mt-3 btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ))
              ) : !loading ? (
                <h4>No emails found</h4>
              ) : (
                []
              )}
            </div>
          </div>
        </form>
      </div>

      <HeaderModal
        header={header}
        showHeaderBgClr={showHeaderBgClr}
        toggleHeaderBgColor={toggleHeaderBgColor}
        handleHeaderBackgroundColor={handleHeaderBackgroundColor}
        LogoInpStyling={LogoInpStyling}
        open={modals.headerModal}
        closeDrawer={() => closeDrawer("headerModal")}
        styles={styles}
        handleNavLinkChange={handleNavLinkChange}
        handleLogoStyling={handleLogoStyling}
        handleLogoChange={handleLogoChange}
        handleAddLink={handleAddLink}
        handleRemoveLink={handleRemoveLink}
      />
      <BodyModal
        handleImageChange={handleImageChange}
        handleAddDataToBody={handleAddDataToBody}
        handleRemoveBodySection={handleRemoveBodySection}
        handleLinkTextChange={handleLinkTextChange}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        bodyContent={bodyContent}
        handleAddColumns={handleAddColumns}
        open={modals.bodyModal}
        data={activeBodyData}
        closeDrawer={() => closeDrawer("bodyModal")}
      />
      <FooterModal
        handleAddFooterLink={handleAddFooterLink}
        columns={footerColumns}
        handleRemoveFooterLink={handleRemoveFooterLink}
        open={modals.footerModal}
        handleTitleChange={handleTitleChange}
        handleAddColumn={handleAddColumn}
        handleFooterInpChange={handleFooterInpChange}
        closeDrawer={() => closeDrawer("footerModal")}
      />
    </div>
  );
};

export default Emails;
