/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  deleteFilterGroup,
  getFilterGrouping,
  deleteFilterGroupingItems,
} from "../../../actions/filters";

import TopBarProgress from "react-topbar-progress-indicator";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class ViewFilterGrouping extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filters: null,
      productsClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 50,
    };
  }

  async componentDidMount() {
    const res = await this.props.getFilterGrouping();
    this.setState({ filters: res.data, loading: false });
    console.log(this.state.filters);
  }
  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { products, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(products.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };
  _onCancel = () => {
    this.setState({ deleteConfirm: false, deleteFilterConfirm: false });
  };

  _deleteProduct = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteFilterGroup(id);
    const res = await this.props.getFilterGrouping();
    this.setState({ loading: false, deleteConfirm: false, filters: res.data });
  };
  deleteAllFilters = async (id) => {
    const res = await this.props.deleteFilterGroupingItems(id);
    console.log(id);
    this.setState({ loading: false });

    if (res) {
      this.setState({ deleteFilterConfirm: false });
      toast(`${res.data && res.data}`, { containerId: "B" });
    } else {
      toast(`Some Error Occur`, { containerId: "B" });
    }
  };
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.products.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  render() {
    const {
      loading,
      filters,
      currentPage,
      dataPerPage,
      hpmProducts,
    } = this.state;

    // const indexOfLastData = currentPage * dataPerPage;
    // const indexOfFirstData = indexOfLastData - dataPerPage;
    // const currentData =
    //   products && products.slice(indexOfFirstData, indexOfLastData);

    const renderFilters =
      filters &&
      filters.map((item, index) => {
        return (
          <tr key={index}>
            <td> {index + 1} </td>
            <td> {item.title} </td>

            <td className="align-middle text-center">
              {item.status ? (
                <button
                  type="button"
                  className="btn btn-inverse-success disabled btn-fw"
                >
                  ON
                </button>
              ) : (
                <button type="button" className="btn btn-inverse-danger btn-fw">
                  OFF
                </button>
              )}
            </td>
            <td>
              <Moment>{item.createdAt}</Moment>{" "}
            </td>

            <td>
              <Link to={`/admin/edit-filter-groups/?id=${item._id}`}>
              <button
                type="button"
                className="btn btn-primary btn-sm mr-4"
                style={{ width: 78 }}

              >
                Edit
              </button>
              </Link>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ deleteConfirm: true });
                }}
              >
                Delete
              </button>

              <button
                className="btn btn-warning btn-sm"
                type="button"
                onClick={() => {
                  this.setState({
                    deleteFilterConfirm: true,
                    deleteFilter: item._id,
                  });
                }}
              >
                Delete Group Items
              </button>

              {this.state.deleteConfirm && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  title="Are you sure?"
                  onConfirm={() => {
                    this._deleteProduct(item._id);
                  }}
                  onCancel={this._onCancel}
                  focusCancelBtn
                >
                  Are you sure you want to delete?
                </SweetAlert>
              )}
            </td>
          </tr>
        );
      });

    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Group Filters</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Group Filters{" "}
              </li>
            </ol>
          </nav>
        </div>
        <div className="container-fluid bg-white">
          <div className="hyper-heading-row d-flex justify-content-between ml-5 pt-5">
            <div className="row-center">
              <h4>Group Filters</h4>
            </div>
            <Link to={`/admin/create-filter-group`}>
            <button

              className="btn btn-success btn-fw"
            >
              Create New Grouping
            </button>
            </Link>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>

                <th scope="col">Title</th>

                <th className="text-center" scope="col">
                  Status
                </th>
                <th className="text-center" scope="col">
                  Edit
                </th>
                <th className="text-center" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {hpmProducts && hpmProducts.length === 0 ? (
                <tr className="text-center">
                  <td colSpan="6">No main products found</td>
                </tr>
              ) : (
                renderFilters
              )}
              {this.state.deleteFilterConfirm && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  title="Are you sure?"
                  onConfirm={() => {
                    this.deleteAllFilters(this.state.deleteFilter);
                  }}
                  onCancel={this._onCancel}
                  focusCancelBtn
                >
                  Are you sure you want to delete?
                </SweetAlert>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

getFilterGrouping.propTypes = {
  getFilterGrouping: PropTypes.func.isRequired,
};

deleteFilterGroup.propTypes = {
  deleteFilterGroup: PropTypes.func.isRequired,
};
deleteFilterGroupingItems.propTypes = {
  deleteFilterGroupingItems: PropTypes.func.isRequired,
};
export default connect(null, {
  getFilterGrouping,
  deleteFilterGroup,
  deleteFilterGroupingItems,
})(ViewFilterGrouping);
