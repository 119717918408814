import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getHPM, deleteHPM } from "../../../actions/product";

import TopBarProgress from "react-topbar-progress-indicator";
import Sidebar from "../../../components/sidebar/sidebar.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

class ViewHPM extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hpmProducts: null,
      productsClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 50,
    };
  }

  async componentDidMount() {
    const res = await this.props.getHPM();
    this.setState({ hpmProducts: res.data, loading: false });
    console.log(this.state.hpmProducts);
  }
  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { products, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(products.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };
  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteProduct = async (id) => {
    console.log(id);
    this.setState({ loading: true });
    await this.props.deleteHPM(id);

    const res = await this.props.getHPM();
    this.setState({
      loading: false,
      deleteConfirm: false,
      hpmProducts: res.data,
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.products.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  render() {
    const { loading, products, currentPage, dataPerPage, hpmProducts } =
      this.state;

    const indexOfLastData = currentPage * dataPerPage;
    // eslint-disable-next-line no-unused-vars
    const indexOfFirstData = indexOfLastData - dataPerPage;

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(products && products.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    // eslint-disable-next-line no-unused-vars
    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number, index) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });
    // const { loading, products } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="container-fluid page-body-wrapper">
          <Sidebar show={"products"} />
          <div className="w-100">
            <div className="hyper-heading-row d-flex justify-content-between ml-5 mt-5">
              <div className="row-center">
                <h4>HYPER PRODUCT MODELS</h4>
              </div>
            </div>
            <table className="table table-hover table-bordered table-striped ml-5">
              <thead>
                <tr>
                  <th scope="col">No</th>

                  <th scope="col">Name</th>
                  <th className="text-center" scope="col">
                    Comments
                  </th>

                  <th className="text-center" scope="col">
                    Status
                  </th>
                  <th className="text-center" scope="col">
                    Edit
                  </th>
                  <th className="text-center" scope="col">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {hpmProducts && hpmProducts.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan="6">No main products found</td>
                  </tr>
                ) : (
                  <>
                    {hpmProducts &&
                      hpmProducts.map((item, index) => (
                        <tr key={index}>
                          <td> {index + 1} </td>
                          <td> {item.name} </td>
                          <td> {item.comments} </td>
                          <td className="align-middle text-center">
                            {item.status ? (
                              <button className="btn  disabled btn-fw">
                                ON
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-inverse-danger btn-fw"
                              >
                                OFF
                              </button>
                            )}
                          </td>

                          <td>
                            <Link to={`/admin/edit-hpm/?id=${item._id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm mr-4"
                                style={{ width: 78 }}
                              >
                                Edit
                              </button>
                            </Link>
                          </td>

                          <td>
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => {
                                this._deleteProduct(item._id);
                              }}
                            >
                              Delete
                            </button>

                            {this.state.deleteConfirm && (
                              <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                title="Are you sure?"
                                onConfirm={() => {
                                  this._deleteProduct(item._id);
                                }}
                                onCancel={this._onCancel}
                                focusCancelBtn
                              >
                                Are you sure you want to delete?
                              </SweetAlert>
                            )}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

getHPM.propTypes = {
  getHPM: PropTypes.func.isRequired,
};

deleteHPM.propTypes = {
  deleteHPM: PropTypes.func.isRequired,
};

export default connect(null, { getHPM, deleteHPM })(ViewHPM);
