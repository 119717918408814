import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { showSnackBar } from "../../actions/showSnackBar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { open: $open, error: message, severity: type } = state.snackBar;

  const [open, setOpen] = useState($open);

  useEffect(() => {
    setOpen($open);
  }, [$open]);

  const handleClose = (e) => {
    if (e?.target?.type) return;
    setOpen(false);
    dispatch(showSnackBar(null, "success", false));
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type}>
          {typeof message === "string" ? message : null}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
