import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteArticle, getArticles } from "../../../actions/blog";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import styles from "./blog.module.css";
import SideBarDrawer from "./components/sidebarDrawer";
import AddEditTags from "./components/addEditTags/addEditTags";
import { deleteSinglePageById } from "./blogService/blogPagesService";

const Articles = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    title: "",
  });
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [addEditTags, setAddEditTags] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const handleDiscountButton = () => {
    setAddEditTags({
      open: true,
      edit: "blogs",
      title: "blogs",
    });
  };

  const handleAddNewBlog = () => {
    navigate("/admin/blogs/create-blog");
  };

  const deleteAdminBlogPage = async (blogPageId) => {
    deleteSinglePageById(blogPageId);
    setData((prev) => prev.filter((blogPage) => blogPage._id !== blogPageId));
  };

  // Api calls for get Order
  const getData = useCallback(
    async (pageNo) => {
      const res = await dispatch(getArticles(pageNo));
      setData(res?.blogs);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getData(pagination.currentPage);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date, pagination.currentPage, isMount]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/blogs",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const deleteData = async (id) => {
    setloading(true);
    const res = await dispatch(deleteArticle(id));

    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start align-center gap-8">
          <SearchFilter
            type="text"
            placeholder={"Search title ..."}
            value={searchQuery?.title}
            onChange={(e) =>
              setSearchQuery({
                ...searchQuery,
                title: e.target.value,
              })
            }
          />
        </div>
        <div className="d-flex gap-8">
          <button className={styles.blogButtons} onClick={handleDiscountButton}>
            Add/Edit Tags
          </button>
          <button className={styles.blogButtons} onClick={handleAddNewBlog}>
            Add New Blog
          </button>
        </div>
      </div>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Blogs Dashboard"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Thumbnail</th>
                      <th>Title</th>
                      <th>Publish Date</th>
                      <th>Views</th>
                      <th>Word Count</th>
                      <th>Tags</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/edit-blog/?id=${item._id}`)
                              }
                            >
                              <img
                                src={item.image}
                                height="50px"
                                width="50px"
                                alt="item"
                              />
                            </td>
                            <td>{item.title}</td>
                            <td>
                              {item.createdAt}
                            </td>

                            <td>{item.description.substring(0, 25)}</td>

                            <td>{moment(item?.createdAt).format("l")}</td>
                            <td style={{ maxWidth: "15rem" }}>
                              {item.tags.map((tag, index) => (
                                <span key={index}>
                                  {tag.name}
                                  {index !== item.tags.length - 1 ? ", " : ""}
                                </span>
                              ))}
                            </td>
                            <td>{`${item.status}`}</td>
                            <td>
                              <CustomeTableBtn
                                color="secondary"
                                width={"100px"}
                                className={"mr-2"}
                                onClick={() =>
                                  navigate(`/admin/blogs/edit-blog`, {
                                    state: item,
                                  })
                                }
                              >
                                Edit
                              </CustomeTableBtn>

                              <CustomeTableBtn
                                color="danger"
                                width={"100px"}
                                onClick={() => deleteAdminBlogPage(item._id)}
                              >
                                Delete
                              </CustomeTableBtn>

                              {deleteConfirm.open && (
                                <SweetAlert
                                  warning
                                  showCancel
                                  confirmBtnText="Yes, delete it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={(e) => {
                                    deleteData(deleteConfirm.id);
                                  }}
                                  onCancel={() =>
                                    setdeleteConfirm({
                                      open: false,
                                      id: null,
                                    })
                                  }
                                  focusCancelBtn
                                >
                                  Are you sure you want to delete?
                                </SweetAlert>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <SideBarDrawer Edit={addEditTags} setEdit={setAddEditTags}>
                  <AddEditTags />
                </SideBarDrawer>
                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {Data?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default Articles;
