import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { getReviewStatusButtonBackground } from "./utils";
import ReviewSidebar from "./components/review-sidebar";
import RatingStars from "./components/rating-stars/rating-stars";
import RatingCardSidebar from "./components/rating-card-sidebar/rating-card-sidebar";
import CustomerTicket from "./components/customer-ticket/customer-ticket";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";
import SelectSearchFilter from "../../../components/CustomeFilter/SelectSearchFilter";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import PriceCardDrawer from "../../../components/price-card/price-card-components/price-card-drawer";
import axios from "axios";
import { apiURL } from "../../../config/default";
import { getStores } from "../../../actions/store";
import { useDispatch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@material-ui/core";
import styles from "./review-summary/review-summary.module.css";
import moment from "moment";

const ManageReviews = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("rating" ?? "");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reviewData, setReviewData] = useState();
  const [brand, setBrand] = useState([]);
  const navigate = useNavigate();
  const [filterReview, setFilterReview] = useState({});
  const [pagePagination, setPagination] = useState({
    currentPage: 1,
  });
  const [openSelect, setOpenSelect] = useState({
    creditUsed: false,
    itemOrderNumber: false,
    title: false,
    manufacturer: false,
    sku: false,
    quantity: false,
    createdAt: false,
    reviewStatus: false,
    reviewRequestDate: false,
    review: false,
  });

  const [sortValue, setSortValue] = useState({
    creditUsed: "",
    itemOrderNumber: "",
    title: "",
    manufacturer: "",
    sku: "",
    quantity: "",
    createdAt: "",
    reviewStatus: "",
    reviewRequestDate: "",
    review: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    orderNumber: "",
    brandId: "",
    rating: "",
    reviewStatus: "",
  });
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  useEffect(() => {
    const fetchAllStore = async () => {
      try {
        const res = await dispatch(getStores());

        if (res) {
          const brand = res?.data?.data?.map((item) => {
            return {
              id: item?._id,
              value: item?.fullName,
              label: item?.fullName,
            };
          });
          setBrand(brand);
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };
    fetchAllStore();
  }, [dispatch]);

  const getsortObj = () => {
    let sortObject = {};
    for (let key in sortValue) {
      if (sortValue[key]) {
        sortObject[key] = sortValue[key];
      }
    }
    return sortObject;
  };

  const fetchReviews = async () => {
    setLoading(true);
    let sortObject = getsortObj();
    try {
      const response = await axios.get(
        `${apiURL}admin/reviews?search=${
          searchFilter.orderNumber
        }&reviewStatus=${searchFilter.reviewStatus}&page=${
          pagePagination.currentPage
        }&rating=${searchFilter.rating}&brand=${
          searchFilter.brandId
        }&sort=${JSON.stringify(sortObject)}&limit=20`
      );
      setReviewData(response?.data?.reviews);
      setPagination(response?.data?.pagination);
    } catch (error) {
      console.log("error is : ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiURL}admin/send-review-email/${id}`
      );
      const data = response.data?.review;
      setFilterReview({ ...data });
      setReviewData((prev) => {
        return prev.map((item) => {
          if (item._id === id) {
            return { ...item, reviewStatus: data?.reviewStatus };
          } else {
            return item;
          }
        });
      });
    } catch (error) {
      console.log("error is : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paramValue === "Pending Reviews") {
      setSearchFilter((prev) => ({
        ...prev,
        reviewStatus: "pending",
      }));
    } else if (paramValue) {
      setSearchFilter((prev) => ({
        ...prev,
        rating: parseInt(paramValue, 10),
      }));
    }
  }, [paramValue]);

  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      clearTimeout(timer);
      timer = setTimeout(fetchReviews, 1000);
    };

    debounceSearch();
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchFilter.orderNumber,
    searchFilter.brandId,
    searchFilter.rating,
    searchFilter.reviewStatus,
    pagePagination.currentPage,
    sortValue.title,
    sortValue.manufacturer,
    sortValue.creditUsed,
    sortValue.itemOrderNumber,
    sortValue.sku,
    sortValue.quantity,
    sortValue.createdAt,
    sortValue.daysSinceDelivered,
    sortValue.reviewStatus,
    sortValue.reviewRequestDate,
    sortValue.review,
  ]);

  const handleRefund = (editValue, editTitle, id) => {
    setEdit({
      open: true,
      edit: editValue,
      title: editTitle,
    });
    const filteredReview = reviewData.filter((item) => item?._id === id);
    setFilterReview(...filteredReview);
  };

  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/manage-reviews",
      search: `?${createSearchParams({
        page: value,
      })}`,
    };
    navigate(options, { replace: true });
    setPagination({ ...pagePagination, currentPage: value });
  };

  function calculateDaysPassed(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - givenDate;
    const daysPassed = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return daysPassed;
  }

  const handleToggleSelect = (selectId) => {
    setOpenSelect((prevOpen) => ({
      ...prevOpen,
      [selectId]: !prevOpen[selectId],
    }));
  };

  const handleChangevalue = (event, itemKey) => {
    setSortValue((prev) => {
      // Reset the sortValue object
      const resetSortValue = {
        creditUsed: "",
        itemOrderNumber: "",
        title: "",
        manufacturer: "",
        sku: "",
        quantity: "",
        createdAt: "",
        finalDeliveryDate: "",
        reviewStatus: "",
        reviewRequestDate: "",
        review: "",
      };
      // Update with new value
      return {
        ...resetSortValue,
        [itemKey]: event.target.value,
      };
    });
  };

  const handleAddNewValue = (value) => {
    setSearchFilter({
      ...searchFilter,
      rating: value,
    });
  };

  function roundToTwoDecimals(number) {
    return (Math.round((number + Number.EPSILON) * 100) / 100).toFixed(2);
  }

  return (
    <>
      <div className="d-flex justify-content-start align-center gap-8">
        <SearchFilter
          type="text"
          placeholder={"Search Order ..."}
          value={searchFilter?.orderNumber}
          onChange={(e) =>
            setSearchFilter({
              ...searchFilter,
              orderNumber: e.target.value,
            })
          }
        />

        <SelectSearchFilter
          title={"Brand"}
          placeholder="Select"
          width={"200px"}
          value={
            searchFilter?.brandId
              ? brand.find((item) => item.id === searchFilter.brandId)?.value
              : ""
          }
          options={[...brand]}
          onChange={(value) => {
            if (value === "") {
              setSearchFilter({
                ...searchFilter,
                brandId: "",
              });
            } else {
              const selectedBrand = brand.find((item) => item.value === value);
              setSearchFilter({
                ...searchFilter,
                brandId: selectedBrand ? selectedBrand.id : "",
              });
            }
          }}
        />

        <SelectSearchFilter
          title={"Review Status"}
          width={"250px"}
          placeholder="Select"
          value={searchFilter?.reviewStatus}
          options={[
            { value: "not sent", label: "not sent" },
            { value: "pending", label: "pending" },
            { value: "received", label: "received" },
          ]}
          onChange={(value) =>
            setSearchFilter({
              ...searchFilter,
              reviewStatus: value,
            })
          }
        />

        <SelectSearchFilter
          title={"Rating"}
          width={"250px"}
          placeholder="Select"
          value={searchFilter.rating}
          options={[
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
          ]}
          onChange={handleAddNewValue}
        />
      </div>
      {loading ? (
        // <TopBarProgress />
        <TableLoader rowsNum={20} cellNum={57} />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Reviews"
              subTitle={`items`}
              count={pagePagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th style={{ width: "1rem" }}>
                        <div className="d-flex gap-1 justify-content-between">
                          <div>Order Number</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="Order Number"
                              open={openSelect.itemOrderNumber}
                              onClose={() =>
                                handleToggleSelect("itemOrderNumber")
                              }
                              onOpen={() =>
                                handleToggleSelect("itemOrderNumber")
                              }
                              value={sortValue.itemOrderNumber ?? ""}
                              onChange={(e) =>
                                handleChangevalue(e, "itemOrderNumber")
                              }
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex gap-3 justify-content-between">
                          <div>Order Total</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="OrderTotal"
                              open={openSelect.creditUsed}
                              onClose={() => handleToggleSelect("creditUsed")}
                              onOpen={() => handleToggleSelect("creditUsed")}
                              value={sortValue.creditUsed ?? ""}
                              onChange={(e) =>
                                handleChangevalue(e, "creditUsed")
                              }
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>

                      <th>
                        Item
                        <Select
                          style={{
                            width: "1.5rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                          placeholder="sort"
                          open={openSelect.title}
                          onClose={() => handleToggleSelect("title")}
                          onOpen={() => handleToggleSelect("title")}
                          value={sortValue.title ?? ""}
                          onChange={(e) => handleChangevalue(e, "title")}
                        >
                          <MenuItem className={styles.menuItem} value={+1}>
                            Ascending Order (A - Z)
                          </MenuItem>
                          <MenuItem className={styles.menuItem} value={-1}>
                            Descending Order (Z - A)
                          </MenuItem>
                        </Select>
                      </th>
                      <th>
                        Brand
                        <Select
                          style={{
                            width: "1.5rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                          placeholder="sort"
                          open={openSelect.manufacturer}
                          onClose={() => handleToggleSelect("manufacturer")}
                          onOpen={() => handleToggleSelect("manufacturer")}
                          value={sortValue.manufacturer ?? ""}
                          onChange={(e) => handleChangevalue(e, "manufacturer")}
                        >
                          <MenuItem className={styles.menuItem} value={+1}>
                            Ascending Order (A-Z)
                          </MenuItem>
                          <MenuItem className={styles.menuItem} value={-1}>
                            Descending Order (Z-A)
                          </MenuItem>
                        </Select>
                      </th>
                      <th>
                        SKU
                        <Select
                          style={{
                            width: "1.5rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                          placeholder="sku"
                          open={openSelect.sku}
                          onClose={() => handleToggleSelect("sku")}
                          onOpen={() => handleToggleSelect("sku")}
                          value={sortValue.sku ?? ""}
                          onChange={(e) => handleChangevalue(e, "sku")}
                        >
                          <MenuItem className={styles.menuItem} value={+1}>
                            Ascending Order (A - Z)
                          </MenuItem>
                          <MenuItem className={styles.menuItem} value={-1}>
                            Descending Order (Z - A)
                          </MenuItem>
                        </Select>
                      </th>
                      <th>
                        <div className="d-flex gap-4 justify-content-between align-center">
                          <div>Quantity</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="Quantity"
                              open={openSelect.quantity}
                              onClose={() => handleToggleSelect("quantity")}
                              onOpen={() => handleToggleSelect("quantity")}
                              value={sortValue.quantity ?? ""}
                              onChange={(e) => handleChangevalue(e, "quantity")}
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>
                      <th>Customer Account </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          <div>Order Date</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="Order Date"
                              open={openSelect.createdAt}
                              onClose={() => handleToggleSelect("createdAt")}
                              onOpen={() => handleToggleSelect("createdAt")}
                              value={sortValue.createdAt ?? ""}
                              onChange={(e) =>
                                handleChangevalue(e, "createdAt")
                              }
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>
                      <th>Delivered Date</th>
                      <th>Days Since Delivered</th>
                      <th>Customer Tickets</th>
                      <th>
                        Review Status
                        <Select
                          style={{
                            width: "1.5rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                          placeholder="Review Status"
                          open={openSelect.reviewStatus}
                          onClose={() => handleToggleSelect("reviewStatus")}
                          onOpen={() => handleToggleSelect("reviewStatus")}
                          value={sortValue.reviewStatus ?? ""}
                          onChange={(e) => handleChangevalue(e, "reviewStatus")}
                        >
                          <MenuItem className={styles.menuItem} value={+1}>
                            Ascending Order (A - Z)
                          </MenuItem>
                          <MenuItem className={styles.menuItem} value={-1}>
                            Descending Order (Z - A)
                          </MenuItem>
                        </Select>
                      </th>
                      <th>
                        <div className="d-flex gap-2 justify-content-between align-items-center">
                          <div>Review Request Date</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="sku"
                              open={openSelect.reviewRequestDate}
                              onClose={() =>
                                handleToggleSelect("reviewRequestDate")
                              }
                              onOpen={() =>
                                handleToggleSelect("reviewRequestDate")
                              }
                              value={sortValue.reviewRequestDate ?? ""}
                              onChange={(e) =>
                                handleChangevalue(e, "reviewRequestDate")
                              }
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>
                      <th>Review Date</th>
                      <th>
                        <div className="d-flex gap-1 justify-content-center align-items-center">
                          <div>Rating</div>
                          <div>
                            <Select
                              style={{
                                width: "1.5rem",
                                color: "white",
                                cursor: "pointer",
                              }}
                              placeholder="sku"
                              open={openSelect.review}
                              onClose={() => handleToggleSelect("review")}
                              onOpen={() => handleToggleSelect("review")}
                              value={sortValue.review ?? ""}
                              onChange={(e) => handleChangevalue(e, "review")}
                            >
                              <MenuItem className={styles.menuItem} value={+1}>
                                Ascending Order (A - Z)
                              </MenuItem>
                              <MenuItem className={styles.menuItem} value={-1}>
                                Descending Order (Z - A)
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewData?.length ? (
                      <>
                        {reviewData?.map((item, i) => (
                          <>
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={item?.mainImage}
                                  alt="mainImage"
                                  style={{
                                    width: "50px",
                                    height: "40px",
                                  }}
                                />
                              </td>
                              <td>
                                <a
                                  className="text-dark"
                                  href={`https://qaadmin.sundialhome.com/admin/sale-analysis`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item?.itemOrderNumber}
                                </a>{" "}
                              </td>
                              {/* Hello */}
                              <td>${roundToTwoDecimals(item?.creditUsed)}</td>
                              <td>
                                {item?.slug ? (
                                  <a
                                    style={{ color: "black" }}
                                    href={`https://www.sundialhome.com/product/${item?.slug}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.title}
                                  </a>
                                ) : (
                                  item?.title
                                )}
                              </td>
                              <td>{item?.manufacturer}</td>
                              <td>
                                <a
                                  className="text-dark"
                                  href={`https://qaadmin.sundialhome.com/admin/products`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item?.sku}
                                </a>
                              </td>

                              <td>{item?.quantity}</td>
                              <td>
                                <a
                                  className="text-dark"
                                  href={`https://qaadmin.sundialhome.com/admin/users`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item?.orderID?.user?.email}
                                </a>
                              </td>
                              <td>
                                {moment
                                  .utc(item?.createdAt)
                                  .format("DD/MM/YYYY")}
                              </td>

                              <td>
                                {item?.shippingInfo?.finalDeliveryDate
                                  ? moment
                                      .utc(
                                        item?.shippingInfo?.finalDeliveryDate
                                      )
                                      .format("DD/MM/YYYY")
                                  : ""}
                              </td>
                              <td>
                                {item?.shippingInfo?.finalDeliveryDate
                                  ? calculateDaysPassed(
                                      item?.shippingInfo?.finalDeliveryDate
                                    )
                                  : ""}
                              </td>
                              <td>
                                <CustomeTableBtn
                                  styles={{
                                    display:
                                      item?.ticketCount === 0
                                        ? "none"
                                        : "block",
                                    padding: ".5rem",
                                  }}
                                  color={`danger`}
                                  width={"50"}
                                  className="font-size-sm"
                                  onClick={() =>
                                    handleRefund(
                                      "customer-ticket",
                                      "Customer Ticket",
                                      item?._id
                                    )
                                  }
                                >
                                  {item?.ticketCount}
                                </CustomeTableBtn>
                              </td>
                              <td>
                                <CustomeTableBtn
                                  color={`${
                                    getReviewStatusButtonBackground[
                                      item?.reviewStatus ?? "not sent"
                                    ]
                                  }`}
                                  width={"200"}
                                  className="font-size-sm"
                                  onClick={() =>
                                    handleRefund("reviews", "Review", item?._id)
                                  }
                                  styles={{
                                    padding: ".5rem 0",
                                  }}
                                >
                                  {/* {item?.crawlReport?.productMatchingStatus} */}
                                  {item?.reviewStatus ?? "Not Sent"}
                                </CustomeTableBtn>
                              </td>

                              <td>
                                {item?.reviewRequestDate
                                  ? moment(item?.reviewRequestDate).format("l")
                                  : ""}
                              </td>
                              <td>
                                {item?.reviewDate
                                  ? moment(item?.reviewDate).format("l")
                                  : ""}
                              </td>
                              <td>
                                <RatingStars
                                  handleClick={() =>
                                    handleRefund("rating", "Rating", item?._id)
                                  }
                                  styles={{
                                    display: item?.review ? "block" : "none",
                                  }}
                                  totalStars={item?.review}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {/* Side bar Drawer */}
                {Edit.edit === "reviews" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="22"
                  >
                    <ReviewSidebar
                      filterReviewData={{ ...filterReview }}
                      fetchSingleUser={fetchData}
                    />
                  </PriceCardDrawer>
                )}

                {Edit.edit === "rating" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="23"
                  >
                    <RatingCardSidebar filterReviewData={filterReview} />
                  </PriceCardDrawer>
                )}

                {Edit.edit === "customer-ticket" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="24"
                  >
                    <CustomerTicket filterReviewData={filterReview} />
                  </PriceCardDrawer>
                )}

                <div className="d-flex justify-content-center align-center p-2">
                  <div className="mx-auto">
                    <Pagination
                      count={pagePagination.totalPages}
                      page={pagePagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {reviewData?.length} of {pagePagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ManageReviews;
