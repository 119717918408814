import React, { useEffect } from "react";
import "./styles.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";
import { getAllPendingOrders } from "../../../../actions/order";
import { ImAttachment } from "react-icons/im";
import EditTabelDrawer from "../../../../components/Edit-tabel-drawer/EditTabelDrawer";
import OrderDetails from "../components/OrderDetails";
import OrderStatusButton from "../components/OrderStatusButton";
import UpdateOrderTrackingComp from "../components/UpdateOrderTrackingComp";
import ShippingUpdateComp from "../components/ShippingUpdateComp";
import ShowNotes from "../components/ShowNotes";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import AddNewNotes from "../components/AddNewNotes";
import { getAllCompany } from "../../../../actions/shipping";
import { MMDDYYFormat, toISOStringFromat } from "../../../../utils/dateFormate";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import DateRangeFilter from "../../../../components/CustomeFilter/DateRangeFilter";
import SelectMultipleFilter from "../../../../components/CustomeFilter/SelectMultipleFilter";
import CopyToClipboard from "react-copy-to-clipboard";
import { showSnackBar } from "../../../../actions/showSnackBar";
import ShowPriorityNotes from "../components/ShowPriorityNotes";
import AddPriorityNotes from "../components/AddPriorityNotes";
import { updatePriorityWithStatus } from "../../../../actions/saleAnalysis";
import { MenuItem, Select } from "@material-ui/core";
import CustomButtonDialog from "./dialogbox";

const ProcessOrders = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get("orderNumber");

  const [loading, setloading] = useState(true);
  const [InnderLoader, setInnderLoader] = useState(false);

  const [AllOrders, setAllOrders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Brand, setBrand] = useState(JSON.parse(localStorage.getItem("Brand")));
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SelectItem, setSelectItem] = useState({});
  const [IsMount, setIsMount] = useState(false);
  const [ShippingComapny, setShippingComapny] = useState([]);
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [copyToClipBoard, setCopyToClipBoard] = useState("");

  const initialSearchFilter = {
    OrderStatus: "Available",
    TrackingDetails: "",
    SearchByDate: "",
    SearchQuery: orderNumber ?? "",
    sort: "",
    shipandtrack: "",
    claims: "",
    deliverytype: "",
    pickupDate: "",
    deliveryDate: "",
    estShipDateStart: "",
    estShipDateEnd: "",
    deliveryEndDate: "",
    Brand: "",
    trackingStatus: "",
    nextStatus: "",
    priority: "",
  };

  const [SearchFillter, setSearchFillter] = useState(initialSearchFilter);

  const setDateToEmpty = (inputDate) => {
    return inputDate === "mm/dd/yy,mm/dd/yy" ? "" : inputDate;
  };
  // Api calls for get Order
  const getOrders = useCallback(
    async (
      pageNumber,
      searchQuery,
      status,
      date,
      tracking,
      sort,
      claims,
      deliverytype,
      pickupDate,
      deliveryDate,
      deliveryEndDate,
      brand,
      trackingStatus,
      nextStatus,
      shipandtrack,
      priority
    ) => {
      setInnderLoader(true);
      try {
        date = setDateToEmpty(date);
        pickupDate = setDateToEmpty(pickupDate);
        deliveryDate = setDateToEmpty(deliveryDate);
        deliveryEndDate = setDateToEmpty(deliveryEndDate);
        const res = await dispatch(
          getAllPendingOrders(
            pageNumber,
            searchQuery,
            status,
            date,
            tracking,
            sort,
            claims,
            deliverytype,
            pickupDate,
            deliveryDate,
            deliveryEndDate,
            brand,
            trackingStatus,
            nextStatus,
            shipandtrack,
            priority
          )
        );
        if (res) {
          // set to Seleted item when update because we set new Seleted item
          if (!SelectItem) {
            setSelectItem({});
          } else {
            const newSelected = res?.orders?.find(
              (order) => order._id === SelectItem._id
            );
            setSelectItem(newSelected);
          }
          setAllOrders(res?.orders);
          setpagination({
            ...res.pagination,
          });
          setloading(false);
          setInnderLoader(false);
        }
      } catch (error) {
        setloading(false);
        setInnderLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllOrders, SelectItem, orderNumber]
  );

  const resetFilters = () => {
    setSearchFillter(initialSearchFilter);
  };

  // company list
  const getAllComapnyList = useCallback(async () => {
    const res = await dispatch(getAllCompany());
    setShippingComapny(res);
  }, [dispatch]);
  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getOrders(
          pagination.currentPage,
          SearchFillter.SearchQuery,
          SearchFillter.OrderStatus,
          SearchFillter.SearchByDate,
          SearchFillter.TrackingDetails,
          SearchFillter.sort,
          SearchFillter.claims,
          SearchFillter.deliverytype,
          SearchFillter.pickupDate,
          SearchFillter.deliveryDate,
          SearchFillter.deliveryEndDate,
          SearchFillter?.Brand,
          SearchFillter?.trackingStatus,
          SearchFillter?.shipandtrack,
          SearchFillter?.nextStatus,
          SearchFillter.priority
        );
        getAllComapnyList();
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    SearchFillter.OrderStatus,
    SearchFillter.SearchByDate,
    SearchFillter.TrackingDetails,
    SearchFillter.sort,
    SearchFillter.claims,
    SearchFillter.deliverytype,
    SearchFillter.pickupDate,
    SearchFillter.deliveryDate,
    SearchFillter.deliveryEndDate,
    SearchFillter?.estShipDateStart,
    SearchFillter?.estShipDateEnd,
    SearchFillter?.Brand,
    SearchFillter?.trackingStatus,
    SearchFillter?.shipandtrack,
    SearchFillter?.nextStatus,
    SearchFillter?.priority,
    IsMount,
  ]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/process-orders",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  const handelShippingTracking = (item) => {
    if (item?.trackingStatus !== "Delivered") {
      setEdit({
        open: true,
        edit: "EditShippingTracking",
        title: "Edit Shipping & Tracking",
      });
      setSelectItem({ ...item });
    }
  };

  const handleNotes = (item) => {
    setEdit({
      open: true,
      edit: "Notes",
      title: "Notes",
    });
    setSelectItem({ ...item });
  };

  const handleOrderTracking = (item) => {
    setEdit({
      open: true,
      edit: "orderTrackingStatus",
      title: "Edit Order Status",
    });
    setSelectItem({ ...item });
  };

  const handleNextStatus = (status) => {
    switch (status) {
      case "undefined ":
        return <span className=" font-weight-bold">Processing</span>;

      case "Processing":
        return <span className=" font-weight-bold">Preparing To Ship</span>;
      case "Preparing To Ship":
        return <span className=" font-weight-bold">In Transit</span>;
      case "In Transit":
        return <span className=" font-weight-bold">Delivered</span>;
      case "Delivered":
        return <></>;
      case "Backordered Pending":
        return <span className=" font-weight-bold">Customer Action</span>;
      case "Delayed":
        return <span className=" font-weight-bold">Delayed</span>;

      default:
        return "";
    }
  };

  const nextStatusForFilter = (status) => {
    switch (status) {
      case "Processing":
        return "Preparing To Ship";
      case "Preparing To Ship":
        return "In Transit";
      case "In Transit":
        return "Delivered";
      default:
        return "";
    }
  };

  // get  Next Status Date
  const nextStatusDate = (startDate, endDate) => {
    if (startDate && endDate) {
      return moment(endDate).startOf("day").fromNow();
    } else if (startDate && endDate === null) {
      return moment(startDate).startOf("day").fromNow();
    } else {
      return (
        <p className=" font-weight-bold" style={{ color: "#ff5e38" }}>
          Add Est. Ship Date
        </p>
      );
    }
  };

  // get company link
  const getLink = (name) => {
    let link;
    if (name !== "N/A") {
      link = ShippingComapny.find((item) => item.name === name)?.link;
    } else {
      link = "#";
    }
    return link;
  };

  const handleUpdatePeriorityStatus = (id) => {};

  const handleUpdatePeriority = (item) => {
    setEdit({
      open: true,
      edit: "Priority Notes",
      title: "Priority Notes",
    });
    setSelectItem({ ...item });
  };

  const updatePriorityStatus = async (id, priorityStatus) => {
    setloading(true);
    try {
      const response = await dispatch(
        updatePriorityWithStatus(id, priorityStatus)
      );
      console.log("response value is : ", response);
    } catch (error) {
      console.log("error value is : ", error);
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex  justify-content-end align-center gap-10 flex-wrap">
            <SearchFilter
              width={"250px"}
              type="text"
              placeholder={"Search Order #, Tracking #"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
            <DateRangeFilter
              title={"Order Date"}
              placeholder={"Select"}
              startDate={SearchFillter?.SearchByDate.split(",")[0]}
              endDate={SearchFillter?.SearchByDate.split(",")[1]}
              onChange={(Date) => {
                setSearchFillter({
                  ...SearchFillter,
                  SearchByDate: `${MMDDYYFormat(Date[0])},${MMDDYYFormat(
                    Date[1]
                  )}`,
                });
              }}
            />
            <SelectSearchFilter
              title={"Brand"}
              placeholder="Select"
              width={"200px"}
              value={
                SearchFillter?.Brand
                  ? Brand.find((item) => SearchFillter?.Brand === item?.id)
                      .value
                  : ""
              }
              options={Brand}
              onChange={(value) => {
                setSearchFillter({
                  ...SearchFillter,
                  Brand:
                    value !== ""
                      ? Brand.find((item) => item.value === value).id
                      : "",
                });
              }}
            />
            <SelectMultipleFilter
              width={"200px"}
              title={"Order Status"}
              placeholder="All"
              value={[]}
              options={[
                { value: "Pending", label: "Pending" },
                { value: "Processing", label: "Processing" },
                { value: "Preparing To Ship", label: "Preparing To Ship" },
                { value: "In Transit", label: "In Transit" },
                { value: "Delivered", label: "Delivered" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, trackingStatus: value })
              }
            />
            <DateRangeFilter
              title={"Est Ship Date"}
              placeholder={"Select"}
              startDate={SearchFillter?.pickupDate}
              endDate={SearchFillter?.pickupDate}
              onChange={(Date) => {
                setSearchFillter({
                  ...SearchFillter,
                  pickupDate: `${MMDDYYFormat(Date[0])},${MMDDYYFormat(
                    Date[1]
                  )}`,
                });
              }}
            />
            <DateRangeFilter
              title={"Est Delivery Date"}
              placeholder={"Select"}
              startDate={SearchFillter?.estShipDateStart}
              endDate={SearchFillter?.estShipDateEnd}
              onChange={(Date) => {
                setSearchFillter({
                  ...SearchFillter,
                  deliveryDate: `${MMDDYYFormat(Date[0])},${MMDDYYFormat(
                    Date[1]
                  )}`,
                });
              }}
            />
            <SelectSearchFilter
              title={"Shipping & Tracking"}
              value={SearchFillter?.TrackingDetails}
              placeholder="All"
              options={[
                { value: "false", label: "+Add" },
                { value: "true", label: "included" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, TrackingDetails: value })
              }
            />

            {/* Priority Filter */}
            <SelectSearchFilter
              width={"150px"}
              title={"Priority"}
              value={SearchFillter?.priority}
              placeholder="All"
              options={[
                { value: "normal", label: "normal" },
                { value: "high", label: "high" },
                { value: "urgent", label: "urgent" },
                { value: "medium", label: "medium" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, priority: value })
              }
            />

            <SelectSearchFilter
              title={"Delivery Type"}
              placeholder="All"
              value={SearchFillter?.deliverytype}
              options={[
                { value: "standard", label: "Standard" },
                { value: "WG", label: "White Glove" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, deliverytype: value })
              }
            />
            <SelectSearchFilter
              title={"Next Status"}
              placeholder="Select"
              width={"200px"}
              value={SearchFillter?.nextStatus}
              options={[
                { value: "Processing", label: "Processing" },
                { value: "Preparing To Ship", label: "Preparing To Ship" },
                { value: "In Transit", label: "In Transit" },
                { value: "Delivered", label: "Delivered" },
              ]}
              onChange={(value) => {
                setSearchFillter({
                  ...SearchFillter,
                  nextStatus: nextStatusForFilter(value),
                });
              }}
            />
            <SelectSearchFilter
              title={"Carrier"}
              placeholder="Select"
              width={"200px"}
              value={SearchFillter?.TrackingDetails}
              options={ShippingComapny.map((item) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              onChange={(value) => {
                setSearchFillter({
                  ...SearchFillter,
                  TrackingDetails: value,
                });
              }}
            />
            <SelectSearchFilter
              title={"Sort"}
              placeholder="All"
              value={SearchFillter?.sort}
              options={[
                { value: "desc", label: "Newest First" },
                { value: "asc", label: "Oldest First" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, sort: value })
              }
            />
            <button
              onClick={resetFilters}
              className="reset-filter-button search-filter "
            >
              Reset Filters
            </button>
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Process Orders"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="process-orders-table">
                <table className="m-0">
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Order Date</th>
                      <th>Brand</th>
                      <th>Order Status</th>

                      <th>Next Status</th>
                      <th>Next Status Date</th>
                      <th>Est. Ship Date</th>
                      <th>Est. Delivery Date</th>
                      <th>
                        Tracking <br /> Number
                      </th>
                      <th>
                        Delivery <br /> Type
                      </th>
                      <th>UPS Capital</th>
                      <th>Carrier</th>
                      <th>Priority</th>
                      <th>Priority Notes</th>
                      <th>Order Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllOrders?.length ? (
                      <>
                        {AllOrders?.map(
                          (order) =>
                            order?.trackingStatus !== "Delivered" && (
                              <tr key={order?._id}>
                                <td
                                  className="order-item-number"
                                  onClick={() =>
                                    navigate(`/admin/view-order/${order?._id}`)
                                  }
                                >
                                  {order?.itemOrderNumber}
                                </td>
                                <td>{moment(order?.createdAt).format("l")}</td>
                                <td>
                                  {order?.brandId?.fullName.split(" ")[0]}
                                </td>

                                <td>
                                  {order.trackingStatus !== "Cancelled" ? (
                                    <span
                                      onClick={() => {
                                        order.trackingStatus !==
                                          "Backordered" &&
                                          handleOrderTracking(order);
                                      }}
                                    >
                                      <OrderStatusButton
                                        width={250}
                                        status={order.trackingStatus}
                                      >
                                        {order.trackingStatus}{" "}
                                        {order.trackingStatus === "Backordered"
                                          ? `(${
                                              order?.backorderStatus?.status
                                                ? order?.backorderStatus?.status
                                                : "Pending"
                                            })`
                                          : null}
                                      </OrderStatusButton>
                                    </span>
                                  ) : (
                                    <span>
                                      <OrderStatusButton
                                        width={250}
                                        status={order.trackingStatus}
                                      >
                                        {order.trackingStatus}{" "}
                                        {order.trackingStatus === "Backordered"
                                          ? `(${
                                              order?.backorderStatus?.status
                                                ? order?.backorderStatus?.status
                                                : "Pending"
                                            })`
                                          : null}
                                      </OrderStatusButton>
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {handleNextStatus(
                                      order?.trackingStatus === "Backordered"
                                        ? `${order?.trackingStatus} ${
                                            order?.backorderStatus?.status
                                              ? order?.backorderStatus?.status
                                              : "Pending"
                                          } `
                                        : order?.trackingStatus
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {nextStatusDate(
                                    order?.trackingStatus === "In Transit"
                                      ? order?.shippingInfo?.deliveryDate ??
                                          null
                                      : order?.shippingInfo?.pickupDate ?? null,
                                    order?.trackingStatus === "In Transit"
                                      ? order?.shippingInfo?.deliveryEndDate ??
                                          null
                                      : order?.shippingInfo?.pickupEndDate ??
                                          null
                                  )}
                                </td>
                                <td>
                                  {order.trackingStatus !== "Cancelled" ? (
                                    <span
                                      onClick={() => {
                                        order.trackingStatus !==
                                          "Backordered" &&
                                          handelShippingTracking(order);
                                      }}
                                    >
                                      <OrderStatusButton
                                        width={240}
                                        status={
                                          order?.shippingInfo?.pickupDate
                                            ? "+add"
                                            : "Delayed"
                                        }
                                      >
                                        {order?.shippingInfo?.pickupDate
                                          ? moment(
                                              order?.shippingInfo?.pickupDate
                                            ).format("l")
                                          : "TBD"}{" "}
                                        {order?.shippingInfo?.pickupEndDate
                                          ? `___${moment(
                                              order?.shippingInfo?.pickupEndDate
                                            ).format("l")}`
                                          : ""}
                                      </OrderStatusButton>
                                    </span>
                                  ) : (
                                    <span>
                                      <OrderStatusButton
                                        width={240}
                                        status={
                                          order?.shippingInfo?.pickupDate
                                            ? "+add"
                                            : "Delayed"
                                        }
                                      >
                                        {order?.shippingInfo?.pickupDate
                                          ? moment(
                                              order?.shippingInfo?.pickupDate
                                            ).format("l")
                                          : "TBD"}{" "}
                                        {order?.shippingInfo?.pickupEndDate
                                          ? `___${moment(
                                              order?.shippingInfo?.pickupEndDate
                                            ).format("l")}`
                                          : ""}
                                      </OrderStatusButton>
                                    </span>
                                  )}
                                </td>

                                <td>
                                  {order.trackingStatus !== "Cancelled" ? (
                                    <span
                                      onClick={() => {
                                        order.trackingStatus !==
                                          "Backordered" &&
                                          handelShippingTracking(order);
                                      }}
                                    >
                                      <OrderStatusButton
                                        width={240}
                                        status={
                                          order?.shippingInfo?.deliveryDate
                                            ? "+add"
                                            : "Delayed"
                                        }
                                      >
                                        {order?.shippingInfo?.deliveryDate
                                          ? moment(
                                              order?.shippingInfo?.deliveryDate
                                            ).format("l")
                                          : "TBD"}{" "}
                                        {order?.shippingInfo?.deliveryEndDate
                                          ? `___${moment(
                                              order?.shippingInfo
                                                ?.deliveryEndDate
                                            ).format("l")}`
                                          : ""}
                                      </OrderStatusButton>
                                    </span>
                                  ) : (
                                    <span>
                                      <OrderStatusButton
                                        width={240}
                                        status={
                                          order?.shippingInfo?.deliveryDate
                                            ? "+add"
                                            : "Delayed"
                                        }
                                      >
                                        {order?.shippingInfo?.deliveryDate
                                          ? moment(
                                              order?.shippingInfo?.deliveryDate
                                            ).format("l")
                                          : "TBD"}{" "}
                                        {order?.shippingInfo?.deliveryEndDate
                                          ? `___${moment(
                                              order?.shippingInfo
                                                ?.deliveryEndDate
                                            ).format("l")}`
                                          : ""}
                                      </OrderStatusButton>
                                    </span>
                                  )}
                                </td>

                                <td>
                                  {order?.shippingInfo?.labels?.map(
                                    (item, i) => (
                                      <p key={i}>{item?.trackingNumber}</p>
                                    )
                                  )}
                                  {!order?.shippingInfo?.labels.length && (
                                    <OrderStatusButton
                                      width={90}
                                      status={"Delayed"}
                                    >
                                      TBD
                                    </OrderStatusButton>
                                  )}
                                </td>

                                <td>
                                  <span
                                    style={
                                      order?.orShipping?.type === "WG" ||
                                      order?.shipType === "WG"
                                        ? {}
                                        : { color: "#878787" }
                                    }
                                  >
                                    {`${
                                      order?.shippingInfo?.shippingType
                                        ? order?.shippingInfo?.shippingType
                                        : order?.orShipping?.type
                                        ? order?.orShipping?.type
                                        : order?.shipType
                                    }`}
                                  </span>
                                </td>
                                <td>
                                  {`${
                                    order?.shippingInfo?.labels[0]
                                      ?.insuranceAmount === 0 ||
                                    order?.shippingInfo?.labels[0]
                                      ?.insuranceAmount === undefined
                                      ? ""
                                      : `$${order?.shippingInfo?.labels[0]?.insuranceAmount}`
                                  }\n`}
                                  {order?.upsCapital?.status
                                    ? "(" + order?.upsCapital?.status + ")"
                                    : "N/A"}
                                  {}

                                  {order?.upsCapital?.status ===
                                    "CONFIRMED" && (
                                    <CopyToClipboard
                                      className="copyIcon"
                                      text={
                                        order?.shippingInfo?.labels[0]
                                          ?.insuranceQuoteId
                                      }
                                      onCopy={() => {
                                        setCopyToClipBoard(
                                          order?.shippingInfo?.labels[0]
                                            ?.insuranceQuoteId
                                        );
                                        dispatch(
                                          showSnackBar(
                                            "Quote id has been copied",
                                            "success",
                                            true
                                          )
                                        );
                                      }}
                                    >
                                      <p
                                        style={{
                                          backgroundColor: "#000",
                                          color: "#fff",
                                          padding: "2px 5px",
                                        }}
                                      >
                                        Copy Quote Id
                                      </p>
                                    </CopyToClipboard>
                                  )}
                                </td>
                                <td>
                                  <a
                                    href={getLink(
                                      order?.orShipping?.companyName ||
                                        order?.shippingInfo?.labels[0]
                                          ?.companyName ||
                                        order?.companyName ||
                                        "N/A"
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {order?.orShipping?.companyName ||
                                      order?.shippingInfo?.labels[0]
                                        ?.companyName ||
                                      order?.companyName ||
                                      "N/A"}
                                  </a>
                                  {/* <a
                                href={getLink(
                                  order?.shippingInfo?.labels[0]?.companyName
                                    ? order?.shippingInfo?.labels[0]
                                        ?.companyName
                                    : order?.companyName
                                    ? order?.companyName
                                    : "N/A"
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {order?.shippingInfo?.labels[0]?.companyName
                                  ? order?.shippingInfo?.labels[0]?.companyName
                                  : order?.companyName
                                  ? order?.companyName
                                  : "N/A"}
                              </a>{" "} */}
                                </td>
                                <td>
                                  {/* <OrderStatusButton
                                    status={`${order?.priority}`}
                                    styles={{ fontWeight: 500 }}
                                    onClick={() =>
                                      updatePriorityStatus(
                                        order?._id,
                                        order?.priority
                                      )
                                    }
                                  >
                                    {order?.priority}
                                  </OrderStatusButton> */}

                                  <CustomButtonDialog
                                    orderId={order?._id}
                                    priorityValue={order?.priority}
                                    setAllOrders={setAllOrders}
                                  />
                                </td>
                                {/* periority Notes */}
                                <td>
                                  {order.trackingStatus !== "Cancelled" ? (
                                    <div
                                      onClick={() => {
                                        order.trackingStatus !==
                                          "Backordered" &&
                                          handleUpdatePeriority(order);
                                      }}
                                    >
                                      <OrderStatusButton
                                        width={90}
                                        status={
                                          order?.priorityNotes?.length > 0
                                            ? "Delayed"
                                            : "+add"
                                        }
                                      >
                                        <span>
                                          <ImAttachment
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </span>
                                        <span className="ml-2">
                                          {order?.priorityNotes?.length}
                                        </span>
                                      </OrderStatusButton>
                                    </div>
                                  ) : (
                                    <div>
                                      <OrderStatusButton
                                        width={90}
                                        status={
                                          order?.priorityNotes?.length > 0
                                            ? "Delayed"
                                            : "+add"
                                        }
                                      >
                                        <span>
                                          <ImAttachment
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </span>
                                        <span className="ml-2">
                                          {order?.priorityNotes?.length}
                                        </span>
                                      </OrderStatusButton>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {order.trackingStatus !== "Cancelled" ? (
                                    <div
                                      onClick={() => {
                                        order.trackingStatus !==
                                          "Backordered" && handleNotes(order);
                                      }}
                                    >
                                      <OrderStatusButton
                                        width={90}
                                        status={
                                          order?.notes?.length > 0
                                            ? "Delayed"
                                            : "+add"
                                        }
                                      >
                                        <span>
                                          <ImAttachment
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </span>
                                        <span className="ml-2">
                                          {order?.notes?.length}
                                        </span>
                                      </OrderStatusButton>
                                    </div>
                                  ) : (
                                    <div>
                                      <OrderStatusButton
                                        width={90}
                                        status={
                                          order.notes.length > 0
                                            ? "Delayed"
                                            : "+add"
                                        }
                                      >
                                        <span>
                                          <ImAttachment
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </span>
                                        <span className="ml-2">
                                          {order.notes.length}
                                        </span>
                                      </OrderStatusButton>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={11} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <EditTabelDrawer
                  Edit={Edit}
                  setEdit={setEdit}
                  shippingId={SelectItem?.itemOrderNumber}
                >
                  {InnderLoader ? (
                    <div className=" d-flex align-center justify-content-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {Edit.edit === "orderTrackingStatus" && (
                        <>
                          {/* Working in this module */}
                          <UpdateOrderTrackingComp
                            SelectItem={SelectItem}
                            setIsMount={setIsMount}
                            IsMount={IsMount}
                          />
                          <hr />
                          <ShowNotes
                            title="Order Status Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Order Status"
                            )}
                          />
                          <hr />
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                        </>
                      )}
                      {Edit.edit === "EditShippingTracking" && (
                        <>
                          <ShippingUpdateComp
                            setIsMount={setIsMount}
                            IsMount={IsMount}
                            SelectItem={SelectItem}
                            ShippingComapny={ShippingComapny}
                          />
                          <hr />
                          <ShowNotes
                            title="Shipping & Tracking Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes?.filter(
                              (message) =>
                                message.subject === "Shipping & Tracking"
                            )}
                          />
                          <hr />
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                        </>
                      )}

                      {Edit.edit === "Notes" && (
                        <>
                          <AddNewNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                            setAllOrders={setAllOrders}
                          />
                          <ShowNotes
                            title="All Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes}
                          />
                        </>
                      )}
                      <hr className="mb-0" />

                      {/* Priority Notes */}

                      {Edit.edit === "Priority Notes" && (
                        <>
                          <AddPriorityNotes
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                            setAllOrders={setAllOrders}
                          />
                          <ShowPriorityNotes
                            title="Priority Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.priorityNotes}
                          />
                        </>
                      )}
                      <hr className="mb-0" />

                      <OrderDetails
                        IsMount={IsMount}
                        setIsMount={setIsMount}
                        setEdit={setEdit}
                        SelectItem={SelectItem}
                      />
                    </>
                  )}
                </EditTabelDrawer>
              </div>
              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {AllOrders?.length} of {pagination?.count} Items
                </span>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ProcessOrders;
