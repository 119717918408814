import React, { useState } from 'react';
import Tag from './Tag';
import Drawer from '@mui/material/Drawer';
import TagSettings from './TagSettings';

import styles from './tagable.module.css';

function TagableImage({ image, tags, handleChange, ...rest }) {
  const [currentTag, setcurrentTag] = useState({});
  const [open, setopen] = useState(false);
  const [currentTagIndex, setcurrentTagIndex] = useState(undefined);

  function getMousePos(e) {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    return { x, y, width: rect.width, height: rect.height };
  }

  function handleAddTag(e) {
    const { x, y, width, height } = getMousePos(e);
    const xPercentage = (x / width) * 100 - 4;
    const yPercentage = (y / height) * 100 - 4;
    setcurrentTag({ x: xPercentage, y: yPercentage });
    toggleDrawer();
  }

  function handleEdit(tag, i) {
    setcurrentTag({ ...tag });
    setcurrentTagIndex(i);
    toggleDrawer();
  }

  function handleMove(movedTag, i) {
    handleChange(tags.map((tag, _i) => (i === _i ? { ...movedTag } : tag)));
  }

  function handleSave() {
    if (currentTagIndex !== undefined) handleMove(currentTag, currentTagIndex);
    else handleChange([...tags, { ...currentTag }]);
    setcurrentTag({});
    setcurrentTagIndex(undefined);
    setopen(false);
  }

  function deleteTag() {
    handleChange(tags.filter((tag, _i) => _i !== currentTagIndex));
    setcurrentTag({});
    setcurrentTagIndex(undefined);
    setopen(false);
  }

  function toggleDrawer() {
    setopen(!open);
  }

  function handleClose() {
    setcurrentTag({});
    setcurrentTagIndex(undefined);
    setopen(false);
  }

  return (
    <div className={styles.tagableContainer}>
      <img
        id="tagable-image"
        src={image}
        alt=""
        onClick={handleAddTag}
        width="100%"
        height="100%"
        {...rest}
      />
      {tags.map((tag, i) => (
        <Tag
          tag={tag}
          handleMove={handleMove}
          index={i}
          handleEdit={handleEdit}
        />
      ))}
      <Drawer anchor={'right'} open={open} onClose={handleClose}>
        <TagSettings
          handleSave={handleSave}
          handleClose={handleClose}
          setcurrentTag={setcurrentTag}
          currentTag={currentTag}
          deleteTag={deleteTag}
        />
      </Drawer>
    </div>
  );
}

export default TagableImage;
