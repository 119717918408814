/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import DashboardCard from "./component/DashboardCard";
import LinkCard from "./component/LinkCard";
import OrderChart from "./component/OrderChart";
import AddLink from "./component/AddLink";
import { getStats } from "../../../actions/dashboard";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const [addLinks, setaddLinks] = useState(
    JSON.parse(localStorage.getItem("addLink")) || []
  );
  const [DashboardStats, setDashboardStats] = useState([]);
  const [loading, setloading] = useState(true);

  const getDashboardStats = useCallback(
    async () => {
      const res = await dispatch(getStats());
      setDashboardStats(res);
      setloading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DashboardStats]
  );
  useEffect(() => {
    getDashboardStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className="summary-report">
            <h3 className=" font-weight-bold">Summary Report</h3>

            <div className="dashboard-card-container">
              <DashboardCard
                title="New & Pending Orders"
                count={DashboardStats?.pendingOrders}
                link={"/admin/pending-orders"}
              />
              <DashboardCard
                title="Order Processing"
                count={DashboardStats?.order}
                link={"/admin/process-orders"}
              />
              <DashboardCard
                title="Sales Revenue"
                count={DashboardStats?.allOrdersTotal.toLocaleString()}
                link={"/admin/sale-analysis"}
              />
              <DashboardCard
                title="Customers"
                count={DashboardStats?.customer.toLocaleString()}
                link={"/admin/users"}
              />
            </div>
            <div className="dashboard-card-container">
              <DashboardCard
                title="Customer Service Tickets"
                count={DashboardStats?.totalTickets}
                link={"/admin/all-tickets"}
              />
              <DashboardCard
                title="Products"
                count={DashboardStats?.product.toLocaleString()}
                link={"/admin/products"}
              />
              <DashboardCard
                title="Vendor"
                count={DashboardStats?.vendor}
                link={"/admin/vendors"}
              />
            </div>
          </div>
          <div className="quick-link">
            <h3 className=" font-weight-bold mt-5">Quick Links</h3>
            <div className="link-card-container">
              {/* {QuickLink.map((item, index)=>(
              <LinkCard key={index} name={item.title} link={item.link} />

          ))} */}
              {addLinks.map((item, index) => (
                <LinkCard
                  key={index}
                  i={index}
                  name={item.title}
                  link={item.link}
                  setaddLinks={setaddLinks}
                />
              ))}
              <AddLink name="+ Add Link" setaddLinks={setaddLinks} />
            </div>
          </div>

          <div className="stat-container">
            <h3 className=" font-weight-bold ">Statistics</h3>
            <div
              style={{ borderRadius: "50px" }}
              className="chart-container mt-4"
            >
              <OrderChart title="Order" data={DashboardStats?.orders} />
              <OrderChart title="Revenue" data={DashboardStats?.customers} />
              <OrderChart title="Customers" data={DashboardStats?.customers} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
