import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = ({
  novice,
  intermediate,
  advanced,
  expert,
  referralCommission,
  referral,
  spamWords,
  skills
}) => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  const body = JSON.stringify({
    novice,
    intermediate,
    advanced,
    expert,
    referralCommission,
    referral,
    spamWords,
    skills
  });

  try {
    const res = await axios.post(
      `${apiURL}admin/create-user-setting`,
      body,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = () => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/get-user-setting`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
