import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { editStore, updateStore, deleteStore } from "../../../actions/store";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      storeData: [],
      userData: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.editStore({ userId: id });

    this.setState({
      loading: false,
      userData: res[0],
      storeData: res[1]
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _approveStore = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.updateStore({ userId: id });

    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate("/admin/stores");
      }, 3000);
    }
  };

  _rejectStore = async () => {
    this.setState({ loading: true });
    const id = this.props.location.search.substr(4);
    const res = await this.props.deleteStore({ userId: id });
    this.setState({ loading: false });
    if (res) {
      toast(`${res}`, { containerId: "B" });
      setTimeout(() => {
        this.props.navigate("/admin/stores");
      }, 3000);
    }
  };

  render() {
    const { fullName, email, userName } =
      this.state.userData && this.state.userData;

    const storeName =
      this.state.storeData &&
      this.state.storeData.storeInfo &&
      this.state.storeData.storeInfo[0] &&
      this.state.storeData.storeInfo[0].storeName;

    const storeCity =
      this.state.storeData &&
      this.state.storeData.storeInfo &&
      this.state.storeData.storeInfo[0] &&
      this.state.storeData.storeInfo[0].storeCity;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Store Approval </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/stores">Stores</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Store Approval
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Store Approval: {fullName && fullName}
                </h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="title">Owner Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fullName && fullName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={email && email}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userName && userName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Store Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeName && storeName}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="title">Store Location</label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeCity && storeCity}
                      readOnly
                    />
                  </div>

                  <button
                    onClick={() => {
                      this._approveStore();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Approve Store
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this._rejectStore();
                    }}
                  >
                    Reject Store
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

editStore.propTypes = {
  reeditStoretreive: PropTypes.func.isRequired
};

updateStore.propTypes = {
  updateStore: PropTypes.func.isRequired
};

deleteStore.propTypes = {
  deleteStore: PropTypes.func.isRequired
};

export default withRouter(connect(null, { editStore, updateStore, deleteStore })(Edit));
