/* eslint-disable no-unused-vars */
import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/blog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      image: "",
      description: "",
      tags: [],
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      title,
      image,
      description,
      tags,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
    } = this.state;

    this.setState({ loading: true });

    let formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);
    // formData.append("metaTag", metaTag);
    // formData.append("tags", tags);

    for (let i = 0; i < tags.length; i++) {
      formData.append("tags", tags[i]);
    }
    for (let i = 0; i < metaTag.length; i++) {
      formData.append("metaTag", metaTag[i]);
    }
    for (let i = 0; i < seoKeyword.length; i++) {
      formData.append("seoKeyword", seoKeyword[i]);
    }
    for (let i = 0; i < metaKeyword.length; i++) {
      formData.append("metaKeyword", metaKeyword[i]);
    }

    const res = await this.props.create(formData);

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
        title: "",
        image: "",
        description: "",
        tags: [],
        metaTag: [],
        metaDescription: "",
        metaKeyword: [],
        seoKeyword: [],
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const {
      title,
      description,
      tags,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <>
          <div className="page-header">
            <h3 className="page-title" style={{ fontSize: 30 }}>
              {" "}
              Create Article{" "}
            </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">Blog</li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Blog
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div
                className="card p-0"
                style={{
                  border: "1px solid #dcdcdc",
                }}
              >
                <div className="card-body p-0">
                  <h4
                    className="card-title"
                    style={{
                      color: "#4c4d5a",
                      borderBottom: "1px solid #dcdcdc",
                      background: "#f6f6f6",
                      textShadow: "0 -1px 0 rgba(50,50,50,0)",
                      padding: 12,
                    }}
                  >
                    Add a new article
                  </h4>

                  <Alert />

                  <form className="forms-sample p-3">
                    <div className="form-group">
                      <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="exampleInputName1"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        name="title"
                        value={title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label style={{ fontWeight: "bold" }}>Image upload</label>

                      <div className="input-group col-xs-12">
                        <input
                          required
                          type="file"
                          className="form-control"
                          onChange={(e) => this._handleImageChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label style={{ fontWeight: "bold" }} htmlFor="Meta Tags">
                        Post Tags
                      </label>

                      <CreatableSelect
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                tags: data,
                              })
                            : this.setState({
                                tags: [],
                              });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="description"
                      >
                        Description
                      </label>

                      <Editor
                        apiKey="lzrznnnwd57r5ycnxvdah9p73u2co5gxa5raikdqa6o49lbg"
                        initialValue={description}
                        init={{
                          height: 250,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                        }}
                        onChange={(e) => {
                          this.setState({
                            description: e.target.getContent(),
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ fontWeight: "bold" }} htmlFor="Meta Tags">
                        Meta Tags
                      </label>

                      <CreatableSelect
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                metaTag: data,
                              })
                            : this.setState({
                                metaTag: [],
                              });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="Meta Keywords"
                      >
                        Meta Keywords
                      </label>

                      <CreatableSelect
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                metaKeyword: data,
                              })
                            : this.setState({
                                metaKeyword: [],
                              });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="meta description"
                      >
                        Meta Description
                      </label>
                      <textarea
                        className="form-control"
                        id="metaDescription"
                        name="metaDescription"
                        rows={4}
                        value={metaDescription}
                        onChange={(e) =>
                          this.setState({ metaDescription: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="seo keywords"
                      >
                        Seo Keywords
                      </label>

                      <CreatableSelect
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                seoKeyword: data,
                              })
                            : this.setState({
                                seoKeyword: [],
                              });
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
