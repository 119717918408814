import React from "react";
import { Link } from "react-router-dom";
const EmailFooter = ({ columns }) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="p-2 m-3 bg-secondary rounded">
          <span
            className="icon-social-facebook text-white"
            style={{ fontSize: "1.6rem" }}
          ></span>
        </div>
        <div className="p-2 m-3 bg-secondary rounded">
          <span
            className="icon-social-twitter text-white"
            style={{ fontSize: "1.6rem" }}
          ></span>
        </div>
        <div className="p-2 m-3 bg-secondary rounded">
          <span
            className="icon-social-instagram text-white"
            style={{ fontSize: "1.6rem" }}
          ></span>
        </div>
        <div className="p-2 m-3 bg-secondary rounded">
          <span
            className="icon-social-pinterest text-white"
            style={{ fontSize: "1.6rem" }}
          ></span>
        </div>
      </div>
      <div
        className=" justify-content-between"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
      >
        {columns?.map(
          (column) =>
            column?.links?.length > 0 && (
              <div key={column?._id} className="border p-2 m-2">
                <h4 style={{ fontSize: "18px" }}>{column.title}</h4>
                <div>
                  {column?.links?.map((link) => (
                    <Link
                      to={`/${link.slug}`}
                      className="d-block text-dark"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default EmailFooter;
