import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  Button,
} from "@mui/material";
import OrderStatusButton from "../components/OrderStatusButton";
import { updatePriorityWithStatus } from "../../../../actions/saleAnalysis";
import { useDispatch } from "react-redux";

function CustomButtonDialog({ priorityValue, orderId, setAllOrders }) {
  const [selectedValue, setSelectedValue] = useState(priorityValue);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOkay = async () => {
    try {
      const response = await dispatch(
        updatePriorityWithStatus(orderId, selectedValue)
      );
      if (response?.orderItem) {
        setAllOrders((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item?._id === response?.orderItem?._id) {
              return {
                ...item,
                priority: response?.orderItem?.priority,
              };
            }
            return item;
          });
          return updatedData;
        });
      }
    } catch (error) {
      console.log("error value is : ", error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <div>
      <OrderStatusButton onClick={handleClickOpen} status={priorityValue}>
        {priorityValue}
      </OrderStatusButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please select priority status</DialogTitle>
        <DialogContent>
          <div>
            <Radio
              checked={selectedValue === "normal"}
              onChange={handleRadioChange}
              value="normal"
              name="radio-button-demo"
              inputProps={{ "aria-label": "normal" }}
              style={{ marginRight: ".3rem" }}
            />
            Normal
          </div>
          <div>
            <Radio
              checked={selectedValue === "urgent"}
              onChange={handleRadioChange}
              value="urgent"
              name="radio-button-demo"
              inputProps={{ "aria-label": "urgent" }}
              style={{ marginRight: ".3rem" }}
            />
            Urgent
          </div>
          <div>
            <Radio
              checked={selectedValue === "high"}
              onChange={handleRadioChange}
              value="high"
              name="radio-button-demo"
              inputProps={{ "aria-label": "high" }}
              style={{ marginRight: ".3rem" }}
            />
            High
          </div>

          <div>
            <Radio
              checked={selectedValue === "medium"}
              onChange={handleRadioChange}
              value="medium"
              name="radio-button-demo"
              inputProps={{ "aria-label": "medium" }}
              style={{ marginRight: ".3rem" }}
            />
            Medium
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkay} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomButtonDialog;
