import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { apiURL } from "../../../config/default";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselSection from "./Components/Carousel/CarouselSection";
import Content from "./Components/Content/Content";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import ShopByDeptSection from "./Components/ShopByDept/ShopByDeptSection";
import ImgBanner from "./Components/6XImgBanner/ImgBanner";
import ImgTxtBanner from "./Components/6XImgTxtBanner/ImgTxtBanner";
import FullColBanner from "./Components/12XBanner/FullColBanner";
import ExploreBrands from "./Components/ExploreBrands/ExploreBrandSection";
import Features from "./Components/Features/Features";
import types from "./Types/types";
import getTypesData from "./Types/getTypesData";

import styles from "./styles.module.css";
import "./mainStyles.css";
import RecentlyView from "./Components/recently-view/RecentlyView";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from '@mui/styles';
import HeroBanner from "./Components/HeroBanner/HeroBanner";

const DragHandle = sortableHandle(() => (
  <i className={`${styles.iconGrid} icon-cursor-move px-2`}></i>
));

const SortableItem = sortableElement(({ value }) => (
  <li className="d-flex px-2 justify-content-between my-2 border-top border-bottom border-secondary">
    <DragHandle />
    {value}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul style={{ display: "flex", gap: "30px", flexDirection: "column" }}>
      {children}
    </ul>
  );
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex?.drawer + 1,
    color: "#fff",
  },
}));

const uploadImageToS3 = async (base64) => {
  const s3Image = await axios.post(
    `${apiURL}custom-home/upload-homepage-image`,
    {
      base64,
    }
  );
  return s3Image?.data?.link;
};

function Homepage() {
  useEffect(() => {
    const getHomePage = async () => {
      setloading(true);
      try {
        const {
          data: {
            customHomeScreen: { mainObject: homepage },
          },
        } = await axios.get(`${apiURL}custom-home/home-screen`);

        sethomepage(homepage ?? []);
      } catch (error) {
        toast.error("trouble getting homepage data", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setloading(false);
      }
    };
    getHomePage();
  }, []);

  const [homepage, sethomepage] = useState([]);
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [mainIndex, setmainIndex] = useState(-1);
  const [drawerIndex, setdrawerIndex] = useState(-1);
  const [showDrawer, setshowDrawer] = useState(false);
  const [loading, setloading] = useState(false);
  const [columns, setColumns] = useState(2);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current && homepage.length) firstRender.current = false;
    else window.scrollTo(0, document.body.scrollHeight);
  }, [homepage.length]);

  const getIdxOf = (arr, type) => {
    return arr.findIndex((x) => x.type === type);
  };

  useEffect(() => {
    let newHomepage = [...homepage];
    const indexOfHeroBanner = getIdxOf(newHomepage, "Hero Banner");
    const indexOfRecentlyViewed = getIdxOf(newHomepage, "Recently View");
    newHomepage[indexOfHeroBanner] = {
      ...newHomepage[indexOfHeroBanner],
      recentlyViewedBanners: { ...newHomepage[indexOfRecentlyViewed] },
    };
    sethomepage([...newHomepage]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepage.findIndex((x) => x.type === "Hero Banner")]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    sethomepage(arrayMove(homepage, oldIndex, newIndex));
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setselectedIndex(-1);
    setmainIndex(-1);
    setdrawerIndex(-1);
    setShow(false);
    setshowDrawer(false);
  };

  const handleShow = (index, secondary, drawer) => {
    if (index > -1) setselectedIndex(index);
    if (secondary > -1) setmainIndex(secondary);
    if (drawer || drawer === 0) {
      setshowDrawer(true);
      setdrawerIndex(drawer);
    } else {
      setShow(true);
    }
  };

  const toggleLoading = (loadingState) => {
    setloading(loadingState);
  };
  const handleAddSection = (type) => {
    const value = getTypesData(type);

    sethomepage([
      ...homepage,
      {
        ...value,
        key: Date.now(),
      },
    ]);
  };

  const handleAddValue = (value, index, _index = -1) => {
    let newHomepage = [...homepage];
    if (_index > -1) newHomepage[index].values[_index] = value;
    else newHomepage[index].values = [...homepage[index].values, value];
    sethomepage([...newHomepage]);
  };
  const handleParent = (index, key, value) => {
    let newHomepage = [...homepage];
    newHomepage[index][key] = value;
    sethomepage([...newHomepage]);
  };

  const handleAddValueAuto = (value, index) => {
    let newHomepage = [...homepage];
    newHomepage[index].values = [...newHomepage[index].values, ...value];
    sethomepage([...newHomepage]);
  };

  const toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e, index, _index = -1, name = "url") => {
    const file = e.target.files[0];
    const base64 = await toBase64(file);
    let value = null;
    setloading(true);
    const image = await uploadImageToS3(base64);
    setloading(false);

    if (_index > -1) value = homepage[index].values[_index];
    if (!value) value = { [name]: image, desc: "", link: "" };
    else value = { ...value, [name]: image };
    handleAddValue(value, index, _index);
    const el = document.getElementById("custom-file");
    if (el) el.value = "";
  };

  const handleRightBannerFileChange = async (
    e,
    index,
    _index = -1,
    name = "url"
  ) => {
    const file = e.target.files[0];
    const base64 = await toBase64(file);
    setloading(true);
    const image = await uploadImageToS3(base64);
    setloading(false);
    let newState = [...homepage];
    newState[index].rightHandBanners[_index] = {
      ...newState[index]?.rightHandBanners[_index],
      name: image,
    };
    newState[index].rightHandBanners[_index] = {
      ...newState[index].rightHandBanners[_index],
      url: image,
    };
    sethomepage(newState);
  };

  const handleChangeText = (e, index, _index = -1) => {
    const value = e.target.value;
    const name = e.target.name;
    let newState = [...homepage];
    if (_index === -1) _index = newState[index].values.length - 1;
    newState[index].values[_index] = {
      ...newState[index].values[_index],
      [name]: value,
      url:
        newState[index].values?.[_index]?.url ||
        "https://sundialinsider.s3.us-east-2.amazonaws.com/vendor/Jaipur-logo687.png",
    };

    sethomepage(newState);
  };
  const handleRightBannerTextChange = (e, index, _index = -1) => {
    const value = e.target.value;
    const name = e.target.name;
    let newState = [...homepage];
    if (_index === -1) _index = newState[index].rightHandBanners.length - 1;
    newState[index].rightHandBanners[_index] = {
      ...newState[index].rightHandBanners[_index],
      [name]: value,
      url:
        newState[index].rightHandBanners?.[_index]?.url ||
        "https://sundialinsider.s3.us-east-2.amazonaws.com/vendor/Jaipur-logo687.png",
    };

    sethomepage(newState);
  };

  const handleContentHtml = (html, index) => {
    let newState = [...homepage];
    newState[index].values = [{ html }];
  };

  const handleRemoveSection = (index, _index = -1) => {
    if (index < 0) return;
    if (_index > -1) {
      const newHomePage = [...homepage];
      newHomePage[index].values = newHomePage[index].values.filter(
        (_, i) => i !== _index
      );
      sethomepage(newHomePage);
    } else sethomepage(homepage.filter((_, i) => i !== index));
  };
  const handleColumns = (col) => {
    setColumns(col);
  };

  const handleCarouselAutoPlay = (index) => {
    let newState = [...homepage];
    newState[index].autoPlay = !newState[index].autoPlay;
    sethomepage(newState);
  };

  const saveHomePage = async () => {
    const dataToSend = {
      mainObject: homepage,
    };
    setloading(true);
    try {
      await axios.post(`${apiURL}custom-home/home-screen`, dataToSend);
      toast.success("Successful", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Homepage failed", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setloading(false);
    }
  };

  const renderPage = (section, index) => {
    switch (section.type) {
      case types.HEROBANNER:
        return (
          <HeroBanner
            handleCarouselAutoPlay={handleCarouselAutoPlay}
            handleRightBannerFileChange={handleRightBannerFileChange}
            section={section}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            show={show}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            index={index}
            handleRightBannerTextChange={handleRightBannerTextChange}
            handleChangeText={handleChangeText}
            mainIndex={mainIndex}
            handleRemoveSection={handleRemoveSection}
            handleAddValueAuto={handleAddValueAuto}
            toggleLoading={toggleLoading}
            handleParent={handleParent}
          />
        );

      case types.CAROUSEL:
        return (
          <CarouselSection
            section={section}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            show={show}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            index={index}
            handleChangeText={handleChangeText}
            mainIndex={mainIndex}
            removeSection={handleRemoveSection}
            handleAddValueAuto={handleAddValueAuto}
            toggleLoading={toggleLoading}
            handleParent={handleParent}
          />
        );

      case types.RECENTLYVIEW:
        return (
          <RecentlyView
            section={section}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            show={show}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            index={index}
            handleChangeText={handleChangeText}
            mainIndex={mainIndex}
            handleRemoveSection={handleRemoveSection}
          />
        );

      case types.SHOPBYDEPARTMENT:
        return (
          <ShopByDeptSection
            handleColumns={handleColumns}
            handleShow={handleShow}
            index={index}
            columns={columns}
            selectedIndex={selectedIndex}
            mainIndex={mainIndex}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            show={show}
            handleRemoveSection={handleRemoveSection}
            section={section}
            handleChangeText={handleChangeText}
            handleParent={handleParent}
          />
        );

      case types["12XBANNER"]:
        return (
          <FullColBanner
            section={section}
            handleChangeText={handleChangeText}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            show={show}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            index={index}
            mainIndex={mainIndex}
            handleRemoveSection={handleRemoveSection}
            handleParent={handleParent}
          />
        );

      case types.EXPLOREBRANDS:
        return (
          <ExploreBrands
            toggleLoading={toggleLoading}
            section={section}
            handleChangeText={handleChangeText}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            show={show}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            index={index}
            mainIndex={mainIndex}
            handleRemoveSection={handleRemoveSection}
            handleAddValueAuto={handleAddValueAuto}
            handleParent={handleParent}
          />
        );

      case types["6X6XBANNER"]:
        return (
          <ImgTxtBanner
            handleRemoveSection={handleRemoveSection}
            index={index}
            section={section}
            handleChangeText={handleChangeText}
            handleShow={handleShow}
            selectedIndex={selectedIndex}
            mainIndex={mainIndex}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            show={show}
            drawerIndex={drawerIndex}
            showDrawer={showDrawer}
          />
        );

      case types["6X6XHEROBANNER"]:
        return (
          <ImgBanner
            handleShow={handleShow}
            index={index}
            selectedIndex={selectedIndex}
            mainIndex={mainIndex}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            show={show}
            handleRemoveSection={handleRemoveSection}
            section={section}
            handleChangeText={handleChangeText}
          />
        );

      case types.FEATURES:
        return (
          <Features
            handleShow={handleShow}
            index={index}
            selectedIndex={selectedIndex}
            mainIndex={mainIndex}
            handleClose={handleClose}
            handleFileChange={handleFileChange}
            show={show}
            handleRemoveSection={handleRemoveSection}
            section={section}
            handleChangeText={handleChangeText}
          />
        );

      case types.CONTENT:
        return (
          <Content
            handleContentHtml={handleContentHtml}
            index={index}
            handleRemoveSection={handleRemoveSection}
            section={section}
            handleChangeText={handleChangeText}
          />
        );

      default:
    }
  };

  const classes = useStyles();

  return (
    <div className="container-scroller">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer />

      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          Home Page{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Home page
            </li>
          </ol>
        </nav>
      </div>
      <button className="btn btn-primary" onClick={saveHomePage}>
        Publish
      </button>
      <div className={`${styles.mainContainer} d-flex flex-column`}>
        <div className={styles.menu}>
          <ul
            className="p-0 px-2 d-flex align-items-center justify-content-between"
            style={{
              backgroundColor: "white",
              borderRadius: 4,
              fontWeight: 600,
            }}
          >
            {Object.keys(types).map((key) => (
              <li
                className={`my-3 d-flex flex-column align-items-center ${styles.homePMainList}`}
                onClick={() => handleAddSection(types[key])}
              >
                <span className="mx-3">
                  <i
                    className="icon-plus"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  ></i>
                </span>
                <span>{types[key].toUpperCase()}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.mainSection}>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {homepage.length > 0 &&
              homepage.map((section, i) => (
                <SortableItem
                  key={`item-${i}`}
                  index={i}
                  value={renderPage(section, i)}
                />
              ))}
          </SortableContainer>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
