// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layouts_colorPickerBtn__12llf {\n    margin: 12px;\n    padding: 12px;\n  }\n  .layouts_colorPickerBtn__12llf button {\n    display: block;\n    width: 100%;\n  }\n  .layouts_colorPickerBtn__12llf div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 12px 0;\n    width: 100%;\n  }\n  .layouts_btnTextColor__3xY7_{\n    margin: 12px;\n    padding: 12px;\n  }\n .layouts_inputGroupTextHeading__3tNV8{\n  color: #000000 !important;\n  font-weight: 700;\n  background-color: #fff !important;\n }\n .layouts_backgroundGray__1lPHA{\n  background-color: #ecf0f4 !important;\n }", "",{"version":3,"sources":["webpack://src/pages/admin/layouts/layouts.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;EACf;EACA;IACE,cAAc;IACd,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,WAAW;EACb;EACA;IACE,YAAY;IACZ,aAAa;EACf;CACD;EACC,yBAAyB;EACzB,gBAAgB;EAChB,iCAAiC;CAClC;CACA;EACC,oCAAoC;CACrC","sourcesContent":[".colorPickerBtn {\n    margin: 12px;\n    padding: 12px;\n  }\n  .colorPickerBtn button {\n    display: block;\n    width: 100%;\n  }\n  .colorPickerBtn div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 12px 0;\n    width: 100%;\n  }\n  .btnTextColor{\n    margin: 12px;\n    padding: 12px;\n  }\n .inputGroupTextHeading{\n  color: #000000 !important;\n  font-weight: 700;\n  background-color: #fff !important;\n }\n .backgroundGray{\n  background-color: #ecf0f4 !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPickerBtn": "layouts_colorPickerBtn__12llf",
	"btnTextColor": "layouts_btnTextColor__3xY7_",
	"inputGroupTextHeading": "layouts_inputGroupTextHeading__3tNV8",
	"backgroundGray": "layouts_backgroundGray__1lPHA"
};
export default ___CSS_LOADER_EXPORT___;
