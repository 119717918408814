/* eslint-disable array-callback-return */
import React from "react";
import { Link } from "react-router-dom";
// import CSVReader from "react-csv-reader";
import TopBarProgress from "react-topbar-progress-indicator";
import { getStores } from "../../../actions/store";
import * as XLSX from 'xlsx'
import ProgressBar from "react-bootstrap/ProgressBar";
import { getFilterGrouping } from "../../../actions/filters";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createBulkFilterItem,
  createBulkFilterDetailItem,
} from "../../../actions/filters";
import { getCategoryName } from "../../../actions/childCategory";
import { ToastContainer, toast } from "react-toastify";
import { getAttribute } from "../../../actions/product";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
// eslint-disable-next-line no-unused-vars
const handleForce = (data, fileInfo) => console.log(data, fileInfo);
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class BulkProducts extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      filterData: "",
      data: "",
      handleDarkSideForce: "",
      getCategoryName: [],
      buttonChange: "invisible",
      count: 0,
      detailsCount: 0,
      getStores: [],
      brand: "",
      image: "",
      attributes: [],
      attributeNotFound: [],
      getGroupFilters: [],
      filtersIssues: 0,
      filterDetailsIssues: 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
        this.fileData();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fileData = async () => {
    this.setState({ buttonChange: "invisible", loading: true });
    const response = await this.props.getStores();
    const _res = await this.props.getCategoryName();
    const res = await this.props.getFilterGrouping();
    const attribute = await this.props.getAttribute();

    if (_res && attribute) {
      this.setState({
        getCategoryName: _res,
        getAttribute: attribute.data,
        getStores: response.data,
        getGroupFilters: res.data,
        buttonChange: "visible",
        loading: false,
      });
    }
  };
  handleFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[1];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });
      const wsname1 = wb.SheetNames[0];
      const ws1 = wb.Sheets[wsname1];
      /* Convert array of arrays */
      const filterData = XLSX.utils.sheet_to_json(ws1, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });
      this.setState({
        buttonChange: "visible",
        data: data,
        filterData: filterData,
        cols: make_cols(ws["!ref"]),
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }

  _submitDetailHandler = async () => {
    // this.fileData();
    const { data, getCategoryName, getStores } = this.state;
    console.log(getCategoryName);
    if (data && data.length) {
      let counter = 2;
      let filterDetailsIssues = 0;
      this.setState({ buttonChange: "invisible", loading: true });
      const done = data.map(async (item, index1) => {
        counter++;
        setTimeout(async () => {
          if (item.category_name) {
            let formData = {};

            if (item.category_name !== null) {
              let category = false;
              getCategoryName.map((categoryData, index) => {
                if (
                  item.category_name !== null &&
                  categoryData.name.toLowerCase() ===
                    item.category_name.toLowerCase() &&
                  category === false
                ) {
                  formData.categoryId = categoryData._id;
                  category = true;
                }
              });
              formData.categoryImage = item.category_image;
              getStores.map((brandData, index) => {
                console.log(
                  brandData.fullName.toLowerCase() ===
                    item.brand_name.toLowerCase()
                );
                if (
                  item.brand_name !== null &&
                  brandData.fullName.toLowerCase() ===
                    item.brand_name.toLowerCase()
                ) {
                  formData.brandId = brandData._id;
                }
              });
              formData.brandImage = item.brand_image;
              formData.identifier = item.identifier;
            }
            const response = await this.props.createBulkFilterDetailItem(
              formData
            );
            if (typeof response === "undefined") {
              filterDetailsIssues = filterDetailsIssues + 1;
              this.setState({ filterDetailsIssues: filterDetailsIssues });
            }
          }
          this.setState({ detailsCount: index1 });
        }, counter * 100);
      });
      await Promise.all(done);
      this.setState({ loading: false, buttonChange: "visible", count: "All" });
    } else {
      toast(`Please Select Seller`, { containerId: "B" });
    }
  };

  _submitFilterHandler = async () => {
    const { filterData, getGroupFilters, getAttribute } = this.state;

    if (filterData.length > 0) {
      let counter = 2;
      let filtersIssues = 1;

      this.setState({ buttonChange: "invisible", loading: true });
      const done = filterData.map(async (filters, index1) => {
        counter++;
        setTimeout(async () => {
          if (filters.filter_group_name) {
            let formData = {};

            getGroupFilters.map((item, index) => {
              if (
                filters.filter_group_name !== null &&
                item.title.toLowerCase() ===
                  filters.filter_group_name.toLowerCase()
              ) {
                formData.filterGroupId = item._id;
              }
            });

            getAttribute.map((item, index) => {
              if (
                filters.attribute_name !== null &&
                item.name.toLowerCase() === filters.attribute_name.toLowerCase()
              ) {
                formData.attributeId = item._id;
              }
            });
            formData.title = filters.title;
            formData.selectedAttribute = filters.attribute_value;
            formData.pictureWidth = filters.picture_width;
            formData.pictureHeight = filters.picture_width;
            formData.identifier = filters.identifier;
            const response = await this.props.createBulkFilterItem(formData);

            if (typeof response === "undefined") {
              filtersIssues = filtersIssues + 1;
              this.setState({ filtersIssues: filtersIssues });
            }
          }
          this.setState({ count: index1 + 1 });

          if (filterData.length - 1 === index1) {
            this._submitDetailHandler();
            this.setState({
              loading: false,
              buttonChange: "visible",
              count: "All",
            });
          }
        }, counter * 100);
      });
      await Promise.all(done);
    } else {
      toast(`Please Select Seller`, { containerId: "B" });
    }
  };
  render() {
    // eslint-disable-next-line no-unused-vars
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> upload Filters </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Filters</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bulk Filters
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Upload Bulk Filters</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <div className="col-md-4">
                      <label>CSV File upload</label>
                    </div>

                    <div className="input-group col-md-8 col-xs-12">
                      {/*  <div className="input-group col-xs-12">
                           <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                            </div>*/}
                      <DragDropFile handleFile={this.handleFile}>
                        <div className="row">
                          <div className="col-xs-12">
                            <DataInput handleFile={this.handleFile} />
                          </div>
                        </div>
                      </DragDropFile>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      {this.state.filterData.length ? (
                        <div className="text-center">
                          <ProgressBar
                            animated
                            now={this.state.count}
                            max={this.state.filterData.length}
                          />

                          <h5 className="text-danger">
                            {` ${this.state.filtersIssues} `} filters have Issue
                            check Logs
                          </h5>
                          <h5>
                            you have added {` ${this.state.count} `} filters
                            proceeded
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.data.length ? (
                        <div className="text-center">
                          <ProgressBar
                            animated
                            now={this.state.detailsCount}
                            max={this.state.data.length}
                          />
                          <h5 className="text-danger">
                            {` ${this.state.filterDetailsIssues} `} filters
                            details have Issue check Logs
                          </h5>
                          <h5>
                            you have added {` ${this.state.detailsCount} `}{" "}
                            filters details proceeded
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Link to={"/admin/filter-groups"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                  <button
                    onClick={() => {
                      this._submitFilterHandler();
                    }}
                    type="button"
                    className={`btn btn-primary mr-2 ${this.state.buttonChange}`}
                  >
                    Submit
                  </button>
                </form>
                {this.state.attributeNotFound &&
                  this.state.attributeNotFound.map((item, indx) => (
                    <p key={indx}>{item} attribute not found</p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group" style={{ marginLeft: "12px" }}>
          <label htmlFor="file"> </label>
          <input
            type="file"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

getCategoryName.prototype = {
  getCategoryName: PropTypes.func.isRequired,
};

getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
getFilterGrouping.propTypes = {
  getFilterGrouping: PropTypes.func.isRequired,
};
createBulkFilterItem.propTypes = {
  createBulkFilterItem: PropTypes.func.isRequired,
};
createBulkFilterDetailItem.propTypes = {
  createBulkFilterDetailItem: PropTypes.func.isRequired,
};
export default connect(null, {
  getCategoryName,
  getAttribute,
  getStores,
  createBulkFilterDetailItem,
  createBulkFilterItem,
  getFilterGrouping,
})(BulkProducts);
