import React, { useState } from "react";

import moment from "moment";

import { useDispatch } from "react-redux";
import { verifyCogs } from "../../../../../actions/saleAnalysis";
import ActionButton from "../../../../../components/CustomeButton/ActionButton";

const ClaimUpdateComp = ({ SelectItem, setEdit }) => {
  const dispatch = useDispatch();
  const [ShowClaim, setShowClaim] = useState(
    SelectItem?.claims?.status ? true : false
  );
  const [loading, setloading] = useState(false);
  const [ClaimSubmited, setClaimSubmited] = useState(
    SelectItem?.claims?.status ? true : false
  );
  const [FormData, setFormData] = useState({
    submittedDate: SelectItem?.claims?.submittedDate ?? "",
    itemPrice: SelectItem?.claims?.itemPrice ?? "",
    companyName: SelectItem?.claims?.companyName ?? "",
    claimStatus: SelectItem?.claims?.claimStatus ?? "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setClaimSubmited(true);
  };

  const handleSendClaim = async (value) => {
    setloading(true);
    try {
      const res = await dispatch(
        verifyCogs(SelectItem._id, {
          claims: {
            ...FormData,
            status: true,
            claimStatus: value,
          },
        })
      );

      if (res) {
        setEdit({
          open: false,
          edit: "",
          title: "",
        });
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="p-3">
        {!ShowClaim && (
          <div className="my-4" onClick={() => setShowClaim(!ShowClaim)}>
            <button className="add-button">+ Add Claim</button>
          </div>
        )}

        {ShowClaim && (
          <form onSubmit={handleSubmit}>
            <div className="create-claim-container">
              <div className=" d-flex justify-content-between align-center">
                <h6>
                  {ClaimSubmited ? "Open Claim: Pending" : "Create Claim "}
                </h6>
                {ClaimSubmited ? (
                  <></>
                ) : (
                  <h6
                    onClick={() => setShowClaim(false)}
                    className=" text-danger border-bottom border-danger c-pointer  "
                  >
                    Cancel Claim
                  </h6>
                )}
              </div>

              <div className="my-3 px-3">
                <table className="cogs-table">
                  <tbody>
                    <tr>
                      <th>Variable</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>
                        <span className=" font-weight-bold">Claim Amount</span>
                      </td>

                      <td className="bg-grey">
                        {ClaimSubmited ? (
                          <>{FormData.itemPrice}</>
                        ) : (
                          <input
                            required
                            className=" border-0 bg-transparent"
                            name="itemPrice"
                            type="text"
                            placeholder="Enter Claim Amount"
                            onChange={(e) =>
                              setFormData({
                                ...FormData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className=" font-weight-bold">Provider</span>
                      </td>

                      <td className="bg-grey">
                        {ClaimSubmited ? (
                          <>{FormData.companyName}</>
                        ) : (
                          <>
                            {" "}
                            <select
                              required
                              name="companyName"
                              value={FormData.companyName}
                              onChange={(e) =>
                                setFormData({
                                  ...FormData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              className="bg-transparent"
                            >
                              <option value="">Select Provider</option>
                              <option value="Dylt">Dylt</option>
                              <option value="Metropoliton">Metropoliton</option>
                              <option value="UPS">UPS</option>
                              <option value="Exchange">Exchange</option>
                              <option value="Wrong Item Shipped">
                                Wrong Item Shipped
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className=" d-flex align-center my-3 font-weight-bold ">
                  <p className=" border-bottom-1">
                    {ClaimSubmited
                      ? "Claim Submitted:"
                      : "+Date Claim Submitted"}
                    {"  "}
                  </p>
                  {ClaimSubmited ? (
                    <p> {moment(FormData?.submittedDate).format("l")}</p>
                  ) : (
                    <input
                      required
                      type="date"
                      name="submittedDate"
                      value={FormData.submittedDate}
                      className=" border-0 ml-2"
                      onChange={(e) =>
                        setFormData({
                          ...FormData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  )}
                </div>

                {ClaimSubmited ? (
                  <>
                    {!SelectItem?.claims?.status && (
                      <div className="d-flex flex-column ">
                        <div
                          onClick={() =>
                            !SelectItem?.claims?.status
                              ? handleSendClaim("Accept")
                              : null
                          }
                        >
                          <ActionButton
                            className="mt-2"
                            text="Claim Accepeted"
                            type="success"
                          />
                        </div>
                        <div
                          onClick={() =>
                            !SelectItem?.claims?.status
                              ? handleSendClaim("Reject")
                              : null
                          }
                        >
                          <ActionButton
                            className="mt-2"
                            text="Claim Reject"
                            type="error"
                          />
                        </div>
                        <div
                          onClick={() =>
                            !SelectItem?.claims?.status
                              ? handleSendClaim("Cancel")
                              : null
                          }
                        >
                          <ActionButton className="mt-2" text="Claim Cancel" />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <button
                    type="submit"
                    style={{ background: "black" }}
                    className="add-button  text-white mt-3"
                  >
                    {loading ? "Loading" : "Open Claim"}
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ClaimUpdateComp;
