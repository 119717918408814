import React, { useState } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import "./product.css";

const OtherStufs = (props) => {
  const myobj = {
    yesNoRadioBtn: (
      <div className="col-md-12 grid-margin str1etch-card">
        <label
          for="requireShipping"
          className="col-sm-2 col-form-label text-right bold-text labelColor"
        >
          <span className="red">*</span>Yes
        </label>
        <input type="radio" id="requireShipping" />
      </div>
    ),
  };
  return myobj;
};

const GeneralInputFunc = (props) => {
  const [inpValue, setinpValue] = useState(props.value);
  const inputValue = (e) => {
    return setinpValue(e.target.value);
  };
  return (
    <>
      <div className="form-group my-2 row formRow-border">
        <label
          for={props.id}
          className="col-sm-2 col-form-label text-right bold-text labelColor"
        >
          <span className="red">*</span> {props.label}
        </label>
        <div className="col-sm-10">
          <input
            type={props.type}
            name={props.name}
            onChange={inputValue}
            value={inpValue}
            id={props.id}
            className="form-control"
            placeholder={props.placeholder}
          />
        </div>
      </div>
    </>
  );
};
const DataTabFunc = (props) => {
  const [selectValue, setSelectValue] = useState(props.value);
  const [selectValue1, setSelectValue1] = useState(props.value);
  const selectChange = (e) => {
    e.preventDefault();
    setSelectValue(e.target.value);
    setSelectValue1(e.target.value);
  };
  return (
    <>
      <div className="form-group my-2 row formRow-border">
        <label
          for="exampleFormControlSelect1"
          className="col-sm-2 col-form-label text-right bold-text"
        >
          {props.label}
        </label>
        <div className="col-sm-10">
          <select
            onChange={selectChange}
            className="form-control form-control-lg"
            id="exampleFormControlSelect1"
          >
            <option>{selectValue}</option>
            <option>{selectValue1}</option>
          </select>
        </div>
      </div>
    </>
  );
};

const TabsForm = () => {
  const otherStufs = OtherStufs();
  return (
    <>
      <Tabs defaultActiveKey="General" id="uncontrolled-tab-example">
        <Tab eventKey="General" title="General">
          <div className="col-md-12 grid-margin str1etch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  {GeneralInputFunc({
                    id: "productName",
                    type: "text",
                    placeholder: "Product Name",
                    label: "Product Name",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Meta Tag Title",
                    label: "Meta Tag Title",
                    id: "metaTitle",
                  })}

                  <div className="form-group my-2 row formRow-border">
                    <label
                      for="tagDescription"
                      className="col-sm-2 col-form-label text-right bold-text"
                    >
                      {" "}
                      Meta Tag Description
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        id="tagDescription"
                        placeholder="Meta Tag Description"
                      />
                    </div>
                  </div>
                  <div className="form-group my-2 row formRow-border">
                    <label
                      for="tagKeywords"
                      className="col-sm-2 col-form-label text-right bold-text"
                    >
                      {" "}
                      Meta Tag Keywords
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        id="tagKeywords"
                        placeholder="Meta Tag Keywords"
                      />
                    </div>
                  </div>
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Product Tags",
                    label: "Product Tags",
                    id: "productTags",
                  })}
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Data" title="Data">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Model",
                    id: "Model",
                    label: "model",
                  })}

                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "SKU",
                    id: "SKU",
                    label: "SKU",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "UPC",
                    id: "UPC",
                    label: "UPC",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "EAN",
                    id: "EAN",
                    label: "EAN",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "JAN",
                    id: "JAN",
                    label: "JAN",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "ISBN",
                    id: "ISBN",
                    label: "ISBN",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "MPN",
                    id: "MPN",
                    label: "MPN",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Location",
                    id: "Location",
                    label: "Location",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Price",
                    id: "Price",
                    label: "Price",
                  })}
                  {DataTabFunc({ label: "Tax Class" })}
                  {/* {dataTab}
                  {dataTab} */}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Quantity",
                    id: "Quantity",
                    label: "Quantity",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Minimum Quantity",
                    id: "minQuantity",
                    value: "1",
                    label: "Minimum Quantity",
                  })}
                  {DataTabFunc({
                    label: "Subtract Stock",
                    value: "Yes" && "No",
                  })}
                  {DataTabFunc({ label: "Out Of Stock Status" })}

                  {otherStufs.yesNoRadioBtn}
                  {otherStufs.yesNoRadioBtn}

                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "Weight",
                    id: "Weight",
                    value: "Weight",
                    label: "Weight",
                  })}
                  {GeneralInputFunc({
                    type: "text",
                    id: "Sort Order",
                    value: "1",
                    label: "Sort Order",
                  })}
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Links" title="Links">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  {GeneralInputFunc({ ph: "input", type: "number" })}
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "helo world",
                  })}

                  <div className="form-group my-2 row formRow-border">
                    <label
                      for="tagDescription"
                      className="col-sm-2 col-form-label text-right bold-text"
                    >
                      {" "}
                      Meta Tag Description
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        id="tagDescription"
                        placeholder="Meta Tag Description"
                      />
                    </div>
                  </div>
                  <div className="form-group my-2 row formRow-border">
                    <label
                      for="tagKeywords"
                      className="col-sm-2 col-form-label text-right bold-text"
                    >
                      {" "}
                      Meta Tag Keywords
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        id="tagKeywords"
                        placeholder="Meta Tag Keywords"
                      />
                    </div>
                  </div>
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "helo world",
                  })}
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Attribute" title="Attribute">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Attribute</th>
                        <th>Text</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </Table>
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Option" title="Option">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  {GeneralInputFunc({
                    type: "text",
                    placeholder: "helo world",
                  })}
                </form>
              </div>
            </div>
          </div>
        </Tab>

        <Tab eventKey="Recurring " title="Recurring ">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Attribute</th>
                        <th>Text</th>
                        <th> A</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>A </td>
                        <td>A </td>
                        <td>A </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </Table>
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Discount " title="Discount ">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Customer Group </th>
                        <th>Quantity</th>
                        <th>Priority</th>
                        <th>Price</th>
                        <th>Date Start </th>
                        <th>Date End </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Special " title="Special ">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample">
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Customer Group </th>
                        <th>Priority</th>
                        <th>Price</th>
                        <th>Date Start </th>
                        <th>Date End </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>{" "}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </form>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Image " title="Image ">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card formBorder">
              <div className="card-body">
                <form className="forms-sample"></form>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </>
  );
};
export default TabsForm;
