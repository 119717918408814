import React from "react";
import style from "./pricing-side-componet.module.css";

const InfoItem = ({ crawlType, CrawlDescription , styles }) => {
  return (
    <div className={style.crawlType}>
      <p className={style.crawlContent}>{crawlType}</p>
      <p className={style.crawlContent} style={{...styles}}>{CrawlDescription}</p>
    </div>
  );
};

export default InfoItem;
