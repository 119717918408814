/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import './productCreate.css';
import TopBarProgress from 'react-topbar-progress-indicator';
import ImageUploader from 'react-images-upload';

import Navbar from '../../../components/navbar/navbar.component';
import Sidebar from '../../../components/sidebar/sidebar.component';
import Footer from '../../../components/footer/footer.component';
import { getStores } from '../../../actions/store';
// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { create } from '../../../actions/product';
import { getAttribute } from '../../../actions/product';
import { getWeightClass } from '../../../actions/product';
import { getLengthClass } from '../../../actions/product';

import { usersRetreive } from '../../../actions/user';
import { subCategories } from '../../../actions/subCategory';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Tabs, Tab } from 'react-bootstrap';
import EditorConvertToHTML from '../../../components/TextEditor/TextEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Alert from '../../../components/alert/alert.component';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      image: '',
      getWeightClass: [],
      getLengthClass: [],
      description: '',
      title: '',
      slug: '',
      MetaTagTitle: '',
      brand: '',
      modelNumber: '',
      price: 0,
      minimumQuantity: '',
      weightAndDimension: [{ name: '', value: '' }],
      specification: [{ name: '', value: '' }],
      tag: [],
      categoryConfig: [],
      productConfig: [],
      discountInfo: [
        {
          customerGroup: '',
          quantity: 0,
          priority: '',
          price: 0,
          startDate: '',
          endDate: '',
        },
      ],
      specialInfo: [
        {
          customerGroup: '',
          priority: '',
          price: 0,
          startDate: '',
          endDate: '',
        },
      ],

      attribute: [{ name: '', text: '' }],
      images: [],
      model: '',
      sku: '',
      filter: '',
      quantity: 0,
      outOfStock: false,
      requireShipping: false,
      status: true,
      manufacturer: '',
      category: '',
      vendor: '',
      metaTag: [],
      metaDescription: '',
      metaKeyword: [],
      seoKeyword: [],
      getStores: [],
      getAttributes: [],

      subCategories: [],
      usersRetreive: [],
      upc: '',
      sortOrder: 0,
      weight: 0,
      weigthClass: '',
      lengthClass: '',
      salteEndDate: '',
      DataAvailability: '',
      width: 0,
      height: 0,
      length: 0,
      location: '',
      outOfStockStatus: '',
      pictures: [],
      RelatedProduct: '',
    };
    this.onDrop = this.onDrop.bind(this);
  }

  descriptionHtml = (html) => {
    console.log(html);
    //this.setState({ description: html });
  };

  async componentDidMount() {
    console.log(localStorage.getItem('token'));

    this._isMounted = true;
    const response = await this.props.getStores();
    const attribute = await this.props.getAttribute();
    const weightClass = await this.props.getWeightClass();
    const lengthClass = await this.props.getLengthClass();

    this.setState({ getAttributes: attribute.data });

    this.setState({ getWeightClass: weightClass.data });
    this.setState({ getLengthClass: lengthClass.data });

    console.log(attribute.data);
    console.log(response);
    this.setState({ getStores: response.data });
    console.log(this.state.getStores);

    const mainCat = await this.props.subCategories();

    this.setState({ subCategories: mainCat });
    const ven = await this.props.usersRetreive();
    console.log(ven);
    this.setState({ usersRetreive: ven.data });

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      title,
      modelNumber,
      MetaTagTitle,
      metaDescription,
      metaKeyword,
      tag,
      model,
      sku,
      upc,
      location,
      price,
      quantity,
      minimumQuantity,
      outOfStock,
      dateAvailibity,
      length,
      width,
      height,
      lengthClass,
      weigthClass,
      weight,
      status,
      sortOrder,
      brand,
      category,
      filter,
      RelatedProduct,
      attribute,
      discountInfo,
      pictures,
      seoKeyword,
      weightClass,
      requireShipping,
      categoryConfig,
      productConfig,
    } = this.state;

    const formData = new FormData();

    const dataToSend = {
      title,
      description: this.props.textEditor.textEditor,
      MetaTagTitle,
      metaTagDescription: metaDescription,
      tag,
      metaTagKeyword: metaKeyword,
      modelNumber,
      brand,
      minimumQuantity,
      price,
      specification: attribute,
      images: pictures,
      model,
      sku,
      upc,
      quantity,
      outOfStock,
      requireShipping,
      status,
      manufacturer: brand,
      category,
      sortOrder: sortOrder === null ? sortOrder : 1,
      weight,
      weightClassId: weigthClass,
      lengthClassId: lengthClass,
      width,
      height,
      length,
      categoryConfig,
      productConfig,
      dimensionAndWeight: `${length}x${width}x${height}`,
    };

    formData.append('weight', weight);
    formData.append('weightClassId', weigthClass);
    formData.append('lengthClassId', lengthClass);

    formData.append('width', width);
    formData.append('height', height);
    formData.append('length', length);
    formData.append('categoryConfig', categoryConfig);
    formData.append('productConfig', productConfig);
    formData.append('dimensionAndWeight', 'dummy value');

    const res = await this.props.create(dataToSend);
    console.log(res);

    this.setState({ loading: false });

    if (res) {
      this.setState({});
      toast(`${res.data}`, { containerId: 'B' });
    } else {
      toast(`Some Error Occur`, { containerId: 'B' });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //on image upload
  async onDrop(picture) {
    console.log(picture);
    let promise = [];
    picture.forEach((p) => promise.push(this.toBase64(picture[0])));
    picture = await Promise.all(promise);

    this.setState({
      pictures: picture,
    });
  }

  //change in value attribute click event
  handleAttributeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.attribute];
    list[index][name] = value;
    this.setState({ attribute: list });
  };

  // handle attribute remove click event
  handleAttributeRemove = (index) => {
    const list = [...this.state.attribute];
    list.splice(index, 1);
    this.setState({ attribute: list });
  };

  // handle attribute add
  handleAttributeAdd = () => {
    this.setState({
      attribute: [...this.state.attribute, { name: '', text: '' }],
    });
  };

  //change in value discount click event
  handleDiscountInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.discountInfo];
    list[index][name] = value;
    this.setState({ discountInfo: list });
  };
  handleStartDate = (date, index) => {
    const list = [...this.state.discountInfo];
    list[index]['startDate'] = date;
    this.setState({ discountInfo: list });
  };
  handleEndDate = (date, index) => {
    const list = [...this.state.discountInfo];
    list[index]['endDate'] = date;
    this.setState({ discountInfo: list });
  };

  // handle discount remove click event
  handleDiscountRemove = (index) => {
    const list = [...this.state.discountInfo];
    list.splice(index, 1);
    this.setState({ discountInfo: list });
  };

  // handle attribute add
  handleDiscountAdd = () => {
    this.setState({
      discountInfo: [
        ...this.state.discountInfo,
        {
          customerGroup: '',
          quantity: 0,
          priority: '',
          price: 0,
          startDate: '',
          endDate: '',
        },
      ],
    });
  };

  //change in value Special click event
  handleSpecialInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.specialInfo];
    list[index][name] = value;
    this.setState({ specialInfo: list });
  };
  handleSpecialStartDate = (date, index) => {
    const list = [...this.state.specialInfo];
    list[index]['startDate'] = date;
    this.setState({ specialInfo: list });
  };
  handleSpecialEndDate = (date, index) => {
    const list = [...this.state.specialInfo];
    list[index]['endDate'] = date;
    this.setState({ specialInfo: list });
  };

  // handle Special remove click event
  handleSpecialRemove = (index) => {
    const list = [...this.state.specialInfo];
    list.splice(index, 1);
    this.setState({ specialInfo: list });
  };

  // handle attribute add
  handleSpecialAdd = () => {
    this.setState({
      specialInfo: [
        ...this.state.specialInfo,
        {
          customerGroup: '',
          priority: '',
          price: 0,
          startDate: '',
          endDate: '',
        },
      ],
    });
  };

  changedateAvailibity = (date) => {
    this.setState({
      dateAvailibity: date,
    });
  };

  render() {
    const {
      title,
      brand,
      modelNumber,
      price,
      slug,
      weightAndDimension,
      specification,
      tag,
      minimumQuantity,
      attribute,
      images,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      dimensionAndWeight,
      status,
      manufacturer,
      category,
      vendor,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
      description,
      upc,
      sortOrder,
      lengthClass,
      weight,
      weigthClass,
      subCategories,
      salteEndDate,
      dateAvailibity,
      width,
      height,
      length,
      MetaTagTitle,
      location,
      outOfStockStatus,
      discountInfo,
      specialInfo,
      filter,
      RelatedProduct,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: 'rgb(51, 201, 45, .7)',
        '.3': 'rgb(255, 77, 107, .7)',
        '1.0': 'rgb(200, 125, 255, .7)',
      },
      shadowBlur: 5,
      shadowColor: 'rgba(0, 0, 0, .5)',
    });
    const colourOptions = [];
    subCategories.forEach((element) => {
      if (element?.children?.length !== 0) {
        element.children &&
          element.children.forEach((elements) => {
            var obj = {};
            obj.value = elements._id;
            obj.label = elements.name;
            obj.color = '#00B8d9';
            obj.isFixed = true;
            colourOptions.push(obj);
          });
        let obj = {};
        obj.value = element._id;
        obj.label = element.name;
        obj.color = '#00B8d9';
        obj.isFixed = true;
        colourOptions.push(obj);
      } else {
        let obj = {};
        obj.value = element._id;
        obj.label = element.name;
        obj.color = '#00B8d9';
        obj.isFixed = true;
        colourOptions.push(obj);
      }
    });
    // console.log("cate", colourOptions);

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create Product </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Product
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Alert />

            <Tabs defaultActiveKey="General" id="uncontrolled-tab-example">
              <Tab eventKey="General" title="General">
                <div className="container-fluid">
                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span> Product Name
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control   "
                        id="title"
                        placeholder="Product Name"
                        name="title"
                        value={title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Description
                      </label>
                    </div>
                    <div className="col-md-10">
                      <EditorConvertToHTML
                        htmlValue={() => this.descriptionHtml}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span>Meta Tag Title
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control   "
                        id="MetaTagTitle"
                        placeholder="Meta Tag Title"
                        name="MetaTagTitle"
                        value={MetaTagTitle}
                        onChange={(e) =>
                          this.setState({ MetaTagTitle: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label  bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Meta Tag Description
                      </label>
                    </div>
                    <div className="col-md-10">
                      <textarea
                        className="form-control"
                        placeholder="Meta Tag Description"
                        id="metaDescription"
                        name="metaDescription"
                        rows={4}
                        value={metaDescription}
                        onChange={(e) =>
                          this.setState({
                            metaDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Meta Tag Keywords
                      </label>
                    </div>
                    <div className="col-md-10">
                      <CreatableSelect
                        placeholder="Enter Tag Keywords"
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                metaKeyword: data,
                              })
                            : this.setState({
                                metaKeyword: [],
                              });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Product Tags
                      </label>
                    </div>
                    <div className="col-md-10">
                      <CreatableSelect
                        placeholder="Product Tags"
                        isMulti
                        defaultValue={[]}
                        onChange={(e) => {
                          const data = [];
                          e &&
                            e.map((item, i) => {
                              return data.push(item.value);
                            });

                          data.length > 0
                            ? this.setState({
                                tag: data,
                              })
                            : this.setState({
                                tag: [],
                              });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Data" title="Data">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span>Model
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="model"
                        placeholder="Model"
                        name="model"
                        value={model}
                        onChange={(e) =>
                          this.setState({ model: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        Model Number
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        className="form-control"
                        id="modelNumber"
                        placeholder="Model Number"
                        name="modelNumber"
                        value={modelNumber}
                        onChange={(e) =>
                          this.setState({ modelNumber: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        SKU
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="sku"
                        placeholder="SKU"
                        name="sku"
                        value={sku}
                        onChange={(e) => this.setState({ sku: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        UPC
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        step="1"
                        min="1"
                        className="form-control"
                        id="upc"
                        placeholder="upc"
                        name="upc"
                        value={upc}
                        onChange={(e) => this.setState({ upc: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Location
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Location"
                        name="location"
                        value={location}
                        onChange={(e) =>
                          this.setState({ location: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Price
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        step="0.10"
                        min="0"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        name="price"
                        value={price}
                        onChange={(e) =>
                          this.setState({ price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Quantity
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        id="quantity"
                        placeholder="Quantity"
                        name="quantity"
                        value={quantity}
                        onChange={(e) =>
                          this.setState({ quantity: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Minimum Quantity
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        id="minimumQuantity"
                        placeholder="Minimum quantity"
                        name="minimumQuantity"
                        value={minimumQuantity}
                        onChange={(e) =>
                          this.setState({
                            minimumQuantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Subtract Stock
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="outOfStock"
                        className="form-control"
                        value={outOfStock && outOfStock}
                        onChange={(e) => {
                          this.setState({
                            outOfStock: e.target.value,
                          });
                        }}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Out of Stock Status
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="outOfStockStatus"
                        className="form-control"
                        value={outOfStockStatus}
                        onChange={(e) => {
                          this.setState({
                            outOfStockStatus: e.target.value,
                          });
                        }}
                      >
                        <option value="2-3 days">2-3 days</option>
                        <option value="In Stock">In Stock</option>
                        <option value="Out of Stock">Out of Stock</option>
                        <option value="Pre Order">Pre Order</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Require Shipping
                      </label>
                    </div>
                    <div className="col-md-10 my-auto ">
                      <input
                        className="mr-2"
                        id="YesShipping"
                        value="true"
                        name="radio"
                        type="radio"
                        checked={requireShipping === "true"}
                        onChange={(e) => {
                          this.setState({
                            requireShipping: e.target.value,
                          });
                        }}
                      />
                      <label for="YesShipping">Yes</label>
                      <input
                        className="ml-2 mr-2"
                        value="false"
                        id="NoShipping"
                        name="radio"
                        type="radio"
                        checked={requireShipping === "false"}
                        onChange={(e) => {
                          this.setState({
                            requireShipping: e.target.value,
                          });
                        }}
                      />{" "}
                      <label for="NoShipping">No</label>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Date Availability
                      </label>
                    </div>
                    <div className="col-md-5">
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateAvailibity}
                        onChange={this.changedateAvailibity}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label   bold-text labelColor">
                        Dimension (L x W x H)
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="number"
                        className="form-control"
                        id="length"
                        placeholder="Length"
                        name="length"
                        value={length}
                        onChange={(e) =>
                          this.setState({ length: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        id="width"
                        placeholder="Width"
                        name="width"
                        value={width}
                        onChange={(e) =>
                          this.setState({ width: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        id="height"
                        placeholder="Height"
                        name="height"
                        value={height}
                        onChange={(e) =>
                          this.setState({ height: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Weight Class
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="weigthClass"
                        className="form-control"
                        value={weigthClass && weigthClass}
                        onChange={(e) => {
                          this.setState({ weigthClass: e.target.value });
                        }}
                      >
                        <option value="">Choose</option>
                        {this.state.getWeightClass &&
                          this.state.getWeightClass.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Length Class
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="weigthClass"
                        className="form-control"
                        value={lengthClass && lengthClass}
                        onChange={(e) => {
                          this.setState({ lengthClass: e.target.value });
                        }}
                      >
                        <option value="">Choose</option>
                        {this.state.getLengthClass &&
                          this.state.getLengthClass.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Weight
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        className="form-control"
                        id="weight"
                        placeholder="Model Number"
                        name="weight"
                        value={weight}
                        onChange={(e) =>
                          this.setState({ weight: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Status
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="status"
                        className="form-control"
                        value={status && status}
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                        }}
                      >
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Sort Order
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        step="1"
                        min="1"
                        className="form-control"
                        id="sortOrder"
                        placeholder="sortOrder"
                        name="sortOrder"
                        value={sortOrder ? sortOrder : 1}
                        onChange={(e) =>
                          this.setState({ sortOrder: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Links" title="Links">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Brand
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="brand"
                        onChange={(e) => {
                          this.setState({ brand: e.target.value });
                        }}
                      >
                        <option>choose</option>
                        {this.state.getStores &&
                          this.state.getStores.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.fullName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Category
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="category"
                        onChange={(e) => {
                          this.setState({ category: e.target.value });
                        }}
                        value={category}
                      >
                        <option>choose</option>
                        {this.state.subCategories &&
                          this.state.subCategories.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Filters
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="form-control form-control-sm"
                        id="filter"
                        onChange={(e) => {
                          this.setState({ filter: e.target.value });
                        }}
                        value={filter}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Stores
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="brand"
                        onChange={(e) => {
                          this.setState({ brand: e.target.value });
                        }}
                      >
                        <option>choose</option>
                        {this.state.getStores &&
                          this.state.getStores.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.fullName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Related Products
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="form-control form-control-sm"
                        id="RelatedProduct"
                        onChange={(e) => {
                          this.setState({
                            RelatedProduct: e.target.value,
                          });
                        }}
                        value={RelatedProduct}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Attributes" title="Attributes">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Attribute
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Text
                      </label>
                    </div>
                  </div>
                </div>
                {attribute.map((x, i) => (
                  <div className="container-fluid mt-2" key={i}>
                    <div className="row">
                      <div className="col-md-4">
                        <select
                          name="name"
                          className="form-control"
                          value={x.name}
                          onChange={(e) => this.handleAttributeChange(e, i)}
                          placeholder="enter attribute name"
                        >
                          <option value="">choose</option>
                          {this.state.getAttributes &&
                            this.state.getAttributes.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <textarea
                          className="form-control"
                          name="text"
                          value={x.text}
                          rows="4"
                          onChange={(e) => this.handleAttributeChange(e, i)}
                          placeholder="enter text"
                        />
                      </div>
                      <div className="col-md-2 ">
                        <div className="d-flex">
                          {attribute.length !== 1 && (
                            <button
                              type="button"
                              className="minusBtn actionBtn "
                              onClick={() => this.handleAttributeRemove(i)}
                            >
                              <div class="icon-minus actionBtn-icon minus">
                                {" "}
                              </div>
                            </button>
                          )}
                          {attribute.length - 1 === i && (
                            <button
                              type="button"
                              className="plusBtn actionBtn ml-2"
                              onClick={this.handleAttributeAdd}
                            >
                              <div className="icon-plus actionBtn-icon plus">
                                {" "}
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Tab>

              <Tab eventKey="Discount" title="Discount">
                <div className="d-flex flex-column-custom align-center">
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Customer Group
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Quantity
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Priority
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Price
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date Start
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date End
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Add/Remove
                    </label>
                  </div>
                </div>
                {discountInfo.map((x, i) => {
                  return (
                    <div className="container-fluid mt-2">
                      <div className="d-flex flex-column-custom align-center">
                        <div className="custom-col-1">
                          <select
                            name="customerGroup"
                            className="form-control"
                            value={x.customerGroup}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="customer group"
                          >
                            <option value="default">Default</option>
                          </select>
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="quantity"
                            value={x.quantity}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter quantity"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="priority"
                            value={x.priority}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="price"
                            value={x.price}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.startDate}
                            onChange={(date) => this.handleStartDate(date, i)}
                            name="startDate"
                            placeholder="Enter Start Date"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.endDate}
                            name="endDate"
                            onChange={(date) => this.handleEndDate(date, i)}
                            placeholder="Enter End Date"
                          />
                        </div>
                        <div className="custom-col-1 ">
                          <div className="d-flex">
                            {discountInfo.length !== 1 && (
                              <button
                                type="button"
                                class="minusBtn actionBtn "
                                onClick={() => this.handleDiscountRemove(i)}
                              >
                                <div class="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {discountInfo.length - 1 === i && (
                              <button
                                type="button"
                                class="plusBtn actionBtn ml-2"
                                onClick={this.handleDiscountAdd}
                              >
                                <div class="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab eventKey="Special" title="Special">
                <div className="d-flex flex-column-custom align-center">
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Customer Group
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      priority
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Price
                    </label>
                  </div>

                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date Start
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date End
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Add/Remove
                    </label>
                  </div>
                </div>
                {specialInfo.map((x, i) => {
                  return (
                    <div className="container-fluid mt-2">
                      <div className="d-flex flex-column-custom align-center">
                        <div className="custom-col-1">
                          <select
                            name="customerGroup"
                            className="form-control"
                            value={x.customerGroup}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="customer group"
                          >
                            <option value="default">Default</option>
                          </select>
                        </div>

                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="priority"
                            value={x.priority}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="price"
                            value={x.price}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.startDate}
                            onChange={(date) =>
                              this.handleSpecialStartDate(date, i)
                            }
                            name="SpecialstartDate"
                            placeholder="Enter Start Date"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.endDate}
                            name="SpecialendDate"
                            onChange={(date) =>
                              this.handleSpecialEndDate(date, i)
                            }
                            placeholder="Enter End Date"
                          />
                        </div>
                        <div className="custom-col-1 ">
                          <div className="d-flex">
                            {specialInfo.length !== 1 && (
                              <button
                                type="button"
                                class="minusBtn actionBtn "
                                onClick={() => this.handleSpecialRemove(i)}
                              >
                                <div class="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {specialInfo.length - 1 === i && (
                              <button
                                type="button"
                                class="plusBtn actionBtn ml-2"
                                onClick={this.handleSpecialAdd}
                              >
                                <div class="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab eventKey="Images" title="Images">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col"></div>
                    <div className="col-md-8">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={this.onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={false}
                        withPreview={true}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="categoryConfig" title="Category Config">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-6">
                      <Select
                        closeMenuOnSelect={false}
                        options={this.state.getAttributes.map((attr) => ({
                          value: attr._id,
                          label: attr.name,
                        }))}
                        onChange={(e) => {
                          const attr = this.state.getAttributes.find(
                            (attr) => attr._id === e.value
                          );
                          const categoryConfig = [...this.state.categoryConfig];
                          categoryConfig.push({
                            attributeId: attr._id,
                            name: attr.name,
                          });
                          this.setState({ categoryConfig });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      {this.state.categoryConfig.map((config) => (
                        <p>
                          {config.name}{" "}
                          <span
                            className="ml-3"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                categoryConfig:
                                  this.state.categoryConfig.filter(
                                    (attr) =>
                                      attr.attributeId !== config.attributeId
                                  ),
                              })
                            }
                          >
                            X
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="productConfig" title="Product Config">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-6">
                      <Select
                        closeMenuOnSelect={false}
                        options={this.state.getAttributes.map((attr) => ({
                          value: attr._id,
                          label: attr.name,
                        }))}
                        onChange={(e) => {
                          const attr = this.state.getAttributes.find(
                            (attr) => attr._id === e.value
                          );
                          const productConfig = [...this.state.productConfig];
                          productConfig.push({
                            attributeId: attr._id,
                            name: attr.name,
                          });
                          this.setState({ productConfig });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      {this.state.productConfig.map((config) => (
                        <p>
                          {config.name}
                          <span
                            className="ml-3"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                productConfig: this.state.productConfig.filter(
                                  (attr) =>
                                    attr.attributeId !== config.attributeId
                                ),
                              })
                            }
                          >
                            X
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="SEO" title="SEO">
                <div className="row mt-4">
                  <div className="col-md-2">
                    <label
                      className=" col-form-label   bold-text labelColor"
                      style={{ marginLeft: "12px" }}
                    >
                      Stores
                    </label>
                  </div>
                  <div className="col-md-10">
                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              seoKeyword: data,
                            })
                          : this.setState({
                              seoKeyword: [],
                            });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col-md-8 text-center">
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                    <Link to={"/admin/products"}> 
                    <button
                      className="btn btn-light"

                    >
                      Cancel
                    </button>
                    </Link>
                  </div>
                  <div className="col"></div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

subCategories.propTypes = {
  subCategories: PropTypes.func.isRequired,
};
usersRetreive.propTypes = {
  usersRetreive: PropTypes.func.isRequired,
};

getWeightClass.propTypes = {
  getWeightClass: PropTypes.func.isRequired,
};
getLengthClass.propTypes = {
  getLengthClass: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    textEditor: state.textEditor,
  };
};

export default connect(mapStateToProps, {
  create,
  getAttribute,
  getStores,
  usersRetreive,
  subCategories,
  getWeightClass,
  getLengthClass,
})(Create);
