import React, { useEffect } from "react";
import "../process-orders/styles.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getAllQuoteOrders } from "../../../../actions/order";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import SearchDateFilter from "../../../../components/CustomeFilter/SearchDateFilter";
import { Pagination } from "@mui/material";
import moment from "moment";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import DashboardCard from "../../customerServiceDashboard/components/DashboardCard";

const QuotesOrders = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setloading] = useState(true);
  const [AllOrders, setAllOrders] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const initialSearchFilter = {
    OrderStatus: "Available",
    TrackingDetails: "",
    SearchByDate: "",
    SearchQuery: "",
    sort: "",
    shipandtrack: "",
    claims: "",
    deliverytype: "",
  };
  const [SearchFillter, setSearchFillter] = useState(initialSearchFilter);

  const resetFilters = () => {
    setSearchFillter(initialSearchFilter);
  };

  // Api calls for get Order
  const getOrders = useCallback(
    async (pageNumber, searchQuery, date, sort) => {
      try {
        const res = await dispatch(
          getAllQuoteOrders(pageNumber, searchQuery, date, sort)
        );
        if (res) {
          console.log(res);
          setAllOrders(res?.quotes);
          setpagination({
            ...res.pagination,
          });
          setloading(false);
        }
      } catch (error) {
        setloading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllOrders]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getOrders(
          pagination.currentPage,
          SearchFillter.SearchQuery,
          SearchFillter.SearchByDate,
          SearchFillter.sort
        );
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    SearchFillter.OrderStatus,
    SearchFillter.SearchByDate,
    SearchFillter.sort,
  ]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div
            className=" d-flex  justify-content-end align-center"
            style={{ gap: "20px" }}
          >
            <SearchFilter
              type="text"
              placeholder={"Search Order Number"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
            <SearchDateFilter
              placeholder="Order Date"
              value={SearchFillter.SearchByDate}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, SearchByDate: value })
              }
            />
            <SelectSearchFilter
              title={"Sort"}
              placeholder="Select"
              value={SearchFillter?.sort}
              options={[
                { value: "desc", label: "Newest First" },
                { value: "asc", label: "Oldest First" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, sort: value })
              }
            />

            <button
              onClick={resetFilters}
              className="reset-filter-button search-filter "
            >
              Reset Filters
            </button>
          </div>
          <div className="my-5">
            <DashboardCard
              title="All Quote Orders"
              subTitle="Tickets"
              count={pagination?.count}
              // link={"/admin/all-tickets"}
            >
              <div className="recently-view-table">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Email</th>
                      <th>ZipCode</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllOrders?.length ? (
                      <>
                        {AllOrders?.map((order) => (
                          <tr key={order?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/view-order/${order?._id}`)
                              }
                            >
                              {order?.quoteNumber}
                            </td>
                            <td>{moment(order?.createdAt).format("l")}</td>
                            <td>{order?.productId?.title}</td>
                            <td>
                              <div
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  objectFit: "contain",
                                  border: "1px solid #e2e2e2",
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                }}
                              >
                                {console.log(order)}
                                <img
                                  className="w-100"
                                  src={order?.productId?.mainImage}
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>{order?.price}</td>

                            <td>{order?.email}</td>
                            <td>{order?.zipCode}</td>
                            <td>{order?.quantity}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={7} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {AllOrders?.length} of {pagination?.count} Items
                </span>
              </div>
            </DashboardCard>
          </div>
        </>
      )}
    </>
  );
};

export default QuotesOrders;
