import React from "react";

const SwitchButton = ({ label, checked, onChange }) => {
  return (
    <div className="d-flex align-items-center">
      <div style={{ marginRight: ".6rem", fontWeight: "bold", color: "#000" }}>
        Active
      </div>
      <div style={{ zoom: 1.5 }}>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitch"
            checked={checked}
            onChange={onChange}
          />
          <label
            style={{ cursor: "pointer" }}
            className="custom-control-label"
            htmlFor="customSwitch"
          >
            <span className="icon" style={{ fontSize: "1.2rem" }}></span>
            {/* {label} */}
          </label>
        </div>
      </div>
      <div style={{ fontWeight: "bold", color: "#000" }}>Archived</div>
    </div>
  );
};

export default SwitchButton;
