import React from "react";

const CustomInput = ({ id, name, label, onChange, type, checked, style }) => {
  return (
    <div className="mx-3">
      <input
        onChange={onChange}
        type={type}
        id={id}
        name={name}
        checked={checked}
        style={{ cursor: "pointer" }}
      />
      <label
        className="mx-1"
        htmlFor={id}
        style={{ cursor: "pointer", ...style }}
      >
        {label}
      </label>
    </div>
  );
};

export default CustomInput;
