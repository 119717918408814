import types from './types';

function getTypesData(type) {
  switch (type) {
    case types.HEROBANNER:
      return {
        type: type,
        values: [
          {
            url: "https://secure.img1-fg.wfcdn.com/im/30258592/resize-h573-w927%5Ecompr-r50/1694/169437776/heroSlideBg.jpg",
            link: "",
            desc: "",
          },
        ],
        autoPlay: false,
        rightHandBanners: [
          {
            url: "https://secure.img1-fg.wfcdn.com/im/85709435/compr-r100/1698/169809248/bottomBarTile4Overlay.png",
            link: "",
            desc: "",
            bgColor: "#000000",
          },
          {
            url: "https://secure.img1-fg.wfcdn.com/im/85709435/compr-r100/1698/169809248/bottomBarTile4Overlay.png",
            link: "",
            desc: "",
            bgColor: "#000000",
          },
        ],
      };
    case types.CAROUSEL:
      return {
        type: type,
        values: [
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/slider/405Bathroom%20Lighting495.png",
            link: "/category/furniture",
            desc: "home page carousel",
          },
        ],
      };

    case types.RECENTLYVIEW:
      return {
        type: type,
        values: [
          {
            url: "https://secure.img1-fg.wfcdn.com/im/33723174/resize-h175-w517%5Ecompr-r85/1308/130882904/bottomBarTile4Bg.jpg",
            desc: "Lorem Ipsum dollar here ",
            link: "",
          },
          {
            url: "https://secure.img1-fg.wfcdn.com/im/33723174/resize-h175-w517%5Ecompr-r85/1308/130882904/bottomBarTile4Bg.jpg",
            desc: "Lorem Ipsum dollar here ",
            link: "",
          },
        ],
      };

    case types.SHOPBYDEPARTMENT:
      return {
        type: type,
        values: [],
      };

    case types["12XBANNER"]:
      return {
        type: type,
        values: [
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/slider/405Bathroom%20Lighting495.png",
            desc: "comfy",
            link: "/category/furniture",
            showDesc: true,
            title: "sofas",
          },
        ],
      };

    case types.EXPLOREBRANDS:
      return {
        type: type,
        values: [],
      };

    case types["6X6XBANNER"]:
      return {
        type: type,
        values: [
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/slider/405Bathroom%20Lighting495.png",
            btnClr: "#16A0B5",
            btnTitle: "Shop Now",
            color: "#ffff",
            desc: "i am description and trying to test you here",
            fontSize: "18",
            link: "/category/furniture",
            title: "Single Room",
          },
        ],
      };

    case types["6X6XHEROBANNER"]:
      return {
        type: type,
        values: [
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/410endles.jpg",
            desc: "your first order of $23 with the Sundial Offer expires on  1/6/2021",
            title: "Take $50 Off",
            link: "/category/furniture",
          },
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/410endles.jpg",
            desc: "your first order of $23 with the Sundial Offer expires on  1/6/2021",
            title: "Test 12 $ 45",
            link: "/category/furniture",
          },
        ],
      };

    case types.FEATURES:
      return {
        type: type,
        values: [
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/107membership-icon.png",
            title: "A New Take On Cash-Back",
            desc: "Members get paid cash every time they shop.",
          },
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/107membership-icon.png",
            title: "A New Take On Cash-Back",
            desc: "Members get paid cash every time they shop.",
          },
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/107membership-icon.png",
            title: "A New Take On Cash-Back",
            desc: "Members get paid cash every time they shop.",
          },
          {
            url: "https://sundialinsider.s3.us-east-2.amazonaws.com/107membership-icon.png",
            title: "A New Take On Cash-Back",
            desc: "Members get paid cash every time they shop.",
          },
        ],
      };

    case types.CONTENT:
      return {
        type: type,
        values: [{}],
      };

    default:
  }
}

export default getTypesData;
