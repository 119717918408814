import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create =
  ({
    commissionCalculateType,
    refundCalculate,
    commission,
    shippingCommission,
    manageOrderByVendor,
    sellerApproval,
    productApprovel,
  }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    const body = JSON.stringify({
      commissionCalculateType,
      refundCalculate,
      commission,
      shippingCommission,
      manageOrderByVendor,
      sellerApproval,
      productApprovel,
    });

    try {
      const res = await axios.post(
        `${apiURL}admin/create-vendor-setting`,
        body,
        _config
      );
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };

export const retreive = () => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/get-vendor-setting`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getVendorProducts =
  (page, brandId, categoryId, sku, filter) => async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.get(
        `${apiURL}admin/get-brand-products-by-category?page=${
          page ? page : 1
        }&brand=${brandId}&category=${categoryId}&sku=${sku}&filter=${filter}`
      );
      return res.data;
    } catch (err) {
      const errors = err.response.data.error;
      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };
export const addAdditionalPrice = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/update-additional-price`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
