import React, { useCallback, useEffect, useState } from "react";
import { getVendorStats } from "../../../../services/vendor/vendor.service";

const ProductsStatistics = ({ selectedItem }) => {
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState({});
  const fetchData = useCallback(
    async (id) => {
      setLoading(true);
      const res = await getVendorStats(id);
      console.log(res);

      if (res) {
        setLoading(false);
        setData(res);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe && selectedItem?._id) {
      fetchData(selectedItem?._id);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?._id]);

  return (
    <>
      {loading ? (
        <div className=" my-2 mx-auto">Laoding.....</div>
      ) : (
        <div className="  d-flex flex-column gap-10">
          <div className=" px-3 d-flex justify-content-between align-center ">
            <span>Total Products</span>
            <span>{Data?.totalProducts}</span>
          </div>
          <hr />
          <div className=" px-3 d-flex justify-content-between align-center ">
            <span>Total Enabled</span>
            <span>{Data?.enabledProducts}</span>
          </div>
          <hr />
          <div className=" px-3 d-flex justify-content-between align-center ">
            <span>Total Disabled</span>
            <span>{Data?.disabledProduts}</span>
          </div>
          <hr />
          <div className=" px-3 d-flex justify-content-between align-center ">
            <span>MAP</span>
            <span>{Data?.mapProducts}</span>
          </div>
          <hr />
          <div className=" px-3 d-flex justify-content-between align-center ">
            <span>Non MAP</span>
            <span>{Data?.nonMapProducts}</span>
          </div>
          <hr />
        </div>
      )}
    </>
  );
};

export default ProductsStatistics;
