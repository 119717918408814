import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { paidUsersRetreive } from "../../../actions/user";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

class PaidUsers extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paidUsers: null,
      paidUsersClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 10,
      deleteId: null,
    };
  }

  async componentDidMount() {
    const res = await this.props.paidUsersRetreive();
    console.log(res);
    this.setState({
      paidUsers: res.data,
      paidUsersClone: res.data,
      loading: false,
    });
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { paidUsers, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(paidUsers.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteSeller = async (id) => {
    this.setState({ loading: true });
    const data = {
      userId: id,
    };
    const _res = await this.props.deleteSeller(data);
    if (_res) {
      toast(`Seller deleted`, { containerId: "B" });
    }
    const res = await this.props.getSellers();
    this.setState({
      loading: false,
      deleteConfirm: false,
      paidUsers: res.data,
    });
  };

  _approveSeller = async (id) => {
    this.setState({ loading: true });
    const res = await this.props.updateSeller({ userId: id });
    if (res) {
      toast(`${res}`, { containerId: "B" });
    }
    const _res = await this.props.getSellers();
    this.setState({
      loading: false,
      deleteConfirm: false,
      paidUsers: _res.data,
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const filteredArr = [];
      this.state.paidUsers.forEach((element) => {
        if (
          element.email.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      this.setState({ paidUsers: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ paidUsers: this.state.paidUsersClone });
    }
  };

  render() {
    const { loading, paidUsers, currentPage, dataPerPage } = this.state;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData =
      paidUsers && paidUsers.slice(indexOfFirstData, indexOfLastData);

    const renderTodos =
      currentData &&
      // eslint-disable-next-line array-callback-return
      currentData.map((item, index) => {
        if (item && item.becomeVendor) {
          return (
            <tr key={index}>
              <td> {index + 1} </td>
              <td> {item.firstName + " " + item.lastName} </td>
              <td>{item.email}</td>
              <td>
                {" "}
                <Moment format="D MMM, YYYY">{item.createdAt}</Moment>{" "}
              </td>
              <td>
                {item.membershipType === "gold" ? (
                  <p class="badge badge-success">Gold</p>
                ) : (
                  <p href="#" class="badge badge-info">
                    Platinum
                  </p>
                )}
              </td>
            </tr>
          );
        }
      });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(paidUsers && paidUsers.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });

    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {paidUsers && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View Paid Users </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/paid-users">Paid Users</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Paid Users List</h4>
                    <span
                      className="twitter-typeahead"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      <input
                        className="typeahead form-control tt-input"
                        type="text"
                        placeholder="Search..."
                        autoComplete="off"
                        spellCheck="false"
                        dir="auto"
                        style={{
                          position: "relative",
                          verticalAlign: "top",
                          backgroundColor: "transparent",
                          borderColor: "green",
                          color: "black",
                        }}
                        onKeyDown={this._handleKeyDown}
                        onChange={this._onChangeHandler}
                      />
                    </span>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Owner Name </th>
                          <th> Email </th>
                          <th> Created At </th>
                          <th> Package </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paidUsers && paidUsers.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No seller found</td>
                          </tr>
                        ) : (
                          renderTodos
                        )}
                      </tbody>
                    </table>
                    <div
                      style={{
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {paidUsers.length > 0 && (
                        <nav
                          aria-label="Page navigation example"
                          style={{ maxWidth: "30%", overflowX: "auto" }}
                        >
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.previous}
                              >
                                Previous
                              </button>
                            </li>

                            {renderPageNumbers}
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.next}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

paidUsersRetreive.propTypes = {
  paidUsersRetreive: PropTypes.func.isRequired,
};

export default connect(null, { paidUsersRetreive })(PaidUsers);
