import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { retreive, edit } from "../../../actions/blog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Editor } from "@tinymce/tinymce-react";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      image: "",
      tags: [],
      description: null,
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.retreive(id);
    const metaTagData = [];
    res.metaTag &&
      res.metaTag.forEach((element) => {
        metaTagData.push({
          value: element,
          label: element,
        });
      });

    const metaKeywordData = [];

    res.metaKeyword &&
      res.metaKeyword.forEach((_element) => {
        metaKeywordData.push({
          value: _element,
          label: _element,
        });
      });

    const seoKeywordData = [];

    res.seoKeyword &&
      res.seoKeyword.forEach((element) => {
        seoKeywordData.push({
          value: element,
          label: element,
        });
      });

    const tags = [];
    res.tags &&
      res.tags.forEach((element) => {
        tags.push({ value: element, label: element });
      });
    this.setState({
      loading: false,
      title: res.title,
      image: res.image,
      description: res.description,
      tags: tags,
      metaTag: metaTagData,
      metaDescription: res.metaDescription,
      metaKeyword: metaKeywordData,
      seoKeyword: seoKeywordData,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      title,
      image,
      description,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      tags,
    } = this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);

    // const metaTagArray = metaTag.split(",");

    const tagsData = [];

    tags.forEach((_element) => {
      tagsData.push(_element.value);
    });

    const metaTagData = [];

    metaTag.forEach((element) => {
      metaTagData.push(element.value);
    });

    const metaKeywordData = [];

    metaKeyword.forEach((_element) => {
      metaKeywordData.push(_element.value);
    });

    const seoKeywordData = [];

    seoKeyword.forEach((element) => {
      seoKeywordData.push(element.value);
    });



    // console.log({ html });

    let formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("blogId", id);
    formData.append("description", description);
    // formData.append("tags", tagsData);
    for (let i = 0; i < tagsData.length; i++) {
      formData.append("tags", tagsData[i]);
    }
    for (let i = 0; i < metaTagData.length; i++) {
      formData.append("metaTag", metaTagData[i]);
    }
    for (let i = 0; i < seoKeywordData.length; i++) {
      formData.append("seoKeyword", seoKeywordData[i]);
    }
    for (let i = 0; i < metaKeywordData.length; i++) {
      formData.append("metaKeyword", metaKeywordData[i]);
    }
    // formData.append("metaTag", metaTagData);
    formData.append("metaDescription", metaDescription);
    // formData.append("metaKeyword", metaKeywordData);
    // formData.append("seoKeyword", seoKeywordData);

    const res = await this.props.edit(formData);

    this.setState({ loading: false });

    if (res) {
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }
  _metaTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaTag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaTag: data,
            })
          : this.setState({
              metaTag: null,
            });
      }}
    />
  );

  _metaKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: null,
            });
      }}
    />
  );

  _seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: null,
            });
      }}
    />
  );
  _tagsJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.tags}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              tags: data,
            })
          : this.setState({
              tags: null,
            });
      }}
    />
  );
  render() {
    const { title, description } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Edit Article </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/blogs">Blogs</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Article
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit article</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label>Image upload</label>

                    <div className="input-group col-xs-12">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="product tags">Blog Tags</label>

                    {!this.state.loading && this._tagsJsx()}
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <Editor
                      apiKey="lzrznnnwd57r5ycnxvdah9p73u2co5gxa5raikdqa6o49lbg"
                      value={description && description}
                      init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e) => {
                        this.setState({
                          description: e.target.getContent(),
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Meta Tags">Meta Tags</label>

                    {!this.state.loading && this._metaTagJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Meta Keywords</label>

                    {!this.state.loading && this._metaKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="meta description">Meta Description</label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seo keywords">Seo Keywords</label>

                    {!this.state.loading && this._seoKeywordJsx()}
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { retreive, edit })(Edit));
