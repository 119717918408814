/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import TopBarProgress from 'react-topbar-progress-indicator';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editStore, updateStore, deleteStore } from '../../../actions/store';
import { getAttribute } from '../../../actions/product';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import Alert from '../../../components/alert/alert.component';
import { apiURL } from '../../../config/default';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function FiltersAutoSettings({ getAttribute }) {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      await getSettings();
      const { data: res } = await getAttribute();
      setattributes(res);
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettings = async () => {
    const { data: settings } = await axios.get(
      `${apiURL}filters/get-filter/${location.search.split('=')[1]}`
    );
    const attr = [];
    if (settings.length !== 0) {
      const data = settings[0];
      if (!data.range)
        data.range = [
          { rangeMin: '', rangeMax: '', attributeId: '', isFillable: false },
        ];
      if (!data.values)
        data.values = [
          {
            productAttribute: [],
            attributeId: '',
            title: '',
            showImage: false,
            imageURL: '',
            description: '',
          },
        ];
      else {
        data.values = data.values.map((val) => ({ ...val, isbase64: false }));

        for (let index = 0; index < data.values.length; index++) {
          let id = data.values[index].attributeId;
          if (id) {
            const { data: res } = await axios.get(
              `${apiURL}admin/get-product-attribute-text/${id}`
            );

            attr[index] = res || [];
            let newAttribute = [];
            newAttribute = data.values[index].productAttribute.map((attr_) => ({
              value: attr_._id,
              label: attr_.text,
            }));
            data.values[index].productAttribute = newAttribute;
          }
        }
      }
      setSecondLevelAttribute(attr);
      setstate({ ...data, isbase64: false });
    }
  };

  const [state, setstate] = useState({
    title: '',
    showImage: false,
    imageURL: '',
    checkBoxes: false,
    isRange: false,
    filter: location.search.split('=')[1],
    range: [{ rangeMin: '', rangeMax: '', attributeId: '', isFillable: false }],
    values: [
      {
        productAttribute: [],
        attributeId: '',
        title: '',
        showImage: false,
        imageURL: '',
        description: '',
      },
    ],
  });

  const [loading, setloading] = useState(true);
  const [attributes, setattributes] = useState([]);
  const [SecondLevelAttribute, setSecondLevelAttribute] = useState([[]]);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          productAttribute: [],
          attributeId: '',
          title: '',
          showImage: false,
          imageURL: '',
          description: '',
        },
      ],
    });
  };

  const handleAddRange = () => {
    setstate({
      ...state,
      range: [
        ...state.range,
        { rangeMin: '', rangeMax: '', attributeId: '', isFillable: false },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };
    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleRemoveRange = (index) => {
    const newState = { ...state };
    newState.range.splice(index, 1);
    setstate(newState);
  };

  const handleValuesChange = (e, index) => {
    const newState = { ...state };
    newState.values[index][e.target.name] = e.target.value;
    setstate(newState);
  };

  const handleGetAttributes = async (index, id) => {
    const { data: res } = await axios.get(
      `${apiURL}admin/get-product-attribute-text/${id}`
    );

    const attr = [...SecondLevelAttribute];
    attr[index] = res || [];
    setSecondLevelAttribute(attr);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === '') return alert('title is required');

    let dataToSend = { ...state };

    const values1 = [];

    state.values.forEach((value, index) => {
      let attribute = [];
      value.productAttribute.forEach((attr) => {
        attribute.push(attr.value);
      });
      values1[index] = {
        ...state.values[index],
        productAttribute: attribute,
      };
    });
    dataToSend = { ...dataToSend, values: values1 };
    if (!state.checkBoxes) delete dataToSend.values;
    if (!state.isRange) delete dataToSend.range;
    setloading(true);

    const { data: res } = await axios.put(`${apiURL}filters/edit-filter`, {
      ...dataToSend,
    });

    await getSettings();

    setloading(false);
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);

    if (state._id) setstate({ ...state, imageURL: image, isbase64: true });
    else setstate({ ...state, imageURL: image });
  };

  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}

      <div className="page-header">
        <h3 className="page-title"> Custom Settings </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/filter-category">Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              <div className="row mb-3 mt-3">
                <div className="col-md-3  mt-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Title"
                    name="title"
                    value={state.title}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="col-md-3  mt-3">
                  <label>Show Image</label>
                  <div
                    className="connection-all"
                    onClick={() =>
                      setstate({ ...state, showImage: !state.showImage })
                    }
                  >
                    <span
                      className={state.showImage ? "allcenter" : "allLeft "}
                    >
                      ON
                    </span>

                    <span
                      className={state.showImage ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>

                {state.showImage && (
                  <div className="col-md-3  mt-3">
                    <label>Image URL</label>
                    <input
                      type="file"
                      name="imageURL"
                      onChange={(e) => _handleImageChange(e)}
                      className="review-input border-radius-8"
                    />
                  </div>
                )}
                {/* 
                      <div className="col-md-3  mt-3">
                        <label>Show CheckBoxes</label>
                        <div
                          className="connection-all"
                          //   onClick={() =>
                          //     setstate({
                          //       ...state,
                          //       checkBoxes: !state.checkBoxes,
                          //     })
                          //   }
                        >
                          <span
                            className={
                              state.checkBoxes ? 'allcenter' : 'allLeft '
                            }
                          >
                            ON
                          </span>

                          <span
                            className={
                              state.checkBoxes ? 'editRight' : 'editcenter '
                            }
                          >
                            OFF
                          </span>
                        </div>
                      </div> */}
              </div>
              {/* <div className="row mb-3 mt-3">
                      <div className="col-md-3  mt-3">
                        <label>Is Range</label>
                        <div
                          className="connection-all"
                          //   onClick={() =>
                          //     setstate({ ...state, isRange: !state.isRange })
                          //   }
                        >
                          <span
                            className={state.isRange ? 'allcenter' : 'allLeft '}
                          >
                            ON
                          </span>

                          <span
                            className={
                              state.isRange ? 'editRight' : 'editcenter '
                            }
                          >
                            OFF
                          </span>
                        </div>
                      </div>
                    </div> */}
            </div>
          </div>
        </div>
        <Button
          className="ml-2 mt-3"
          variant="outline-primary"
          size="sm"
          type="submit"
          disabled={loading}
        >
          {loading ? "loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
}

export default connect(null, { getAttribute })(FiltersAutoSettings);
