/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from './shopByDeptSection.module.css';

function ShopByDeptSection({
  handleRemoveSection,
  index,
  show,
  mainIndex,
  handleChangeText,
  handleClose,
  handleFileChange,
  handleShow,
  section,
  selectedIndex,
  handleParent,
}) {
  const [edit, setEdit] = useState(false);
  const [indexToChange, setindexToChange] = useState(null);

  const handleEdit = (index) => {
    setEdit({
      ...edit,
      [index]: !edit[index],
    });
  };
  return (
    <div className="container-fluid">
      <h4 className="text-center"> Shop By Department</h4>
      <div className="text-center dropdown show">
        <a
          className="btn btn-primary dropdown-toggle"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Adjust columns per row{" "}
          {
            <h4 className="d-inline-block text-dark">
              {section?.columns === "5-col" ? "5" : 12 / section?.columns || 6}
            </h4>
          }
        </a>

        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a
            onClick={() => handleParent(index, "columns", 6)}
            className="dropdown-item"
          >
            2
          </a>
          <a
            onClick={() => handleParent(index, "columns", 4)}
            className="dropdown-item"
          >
            3
          </a>
          <a
            onClick={() => handleParent(index, "columns", 3)}
            className="dropdown-item"
          >
            4
          </a>
          <a
            onClick={() => handleParent(index, "columns", "5-col")}
            className="dropdown-item"
          >
            5
          </a>
          <a
            onClick={() => handleParent(index, "columns", 2)}
            className="dropdown-item"
          >
            6
          </a>
        </div>
      </div>
      <div className="d-flex w-100">
        <div className="row m-0 w-100 justify-content-center">
          {section?.values?.map((value, _index) => (
            <div key={_index} className={`px-0 col-lg-${section?.columns ?? 2} my-1`}>
              <div
                className={`${styles.shopDepartmentCol} my-1 border-radius-8 h-100`}
              >
                <strong className={styles.shDepartmentContent}>
                  <div className={styles.shopDepartmentImg}>
                    <div className={`${styles.shopImages} position-relative`}>
                      <div className={`${styles.cardImg} position-absolute`}>
                        <i
                          className="icon-pencil"
                          title="Edit"
                          onClick={() => handleShow(_index, index)}
                        ></i>
                      </div>
                      <div
                        className={`${styles.cardImg}  ${styles.cardImg1} position-absolute`}
                      >
                        <i
                          title="Remove"
                          onClick={() => handleRemoveSection(index, _index)}
                          className="icon-close"
                        ></i>
                      </div>
                      <img src={value.url} width="100%" alt={value.title} />

                      {/* CANGE IMAGE MODAL */}
                      <Modal
                        show={
                          show &&
                          _index === selectedIndex &&
                          mainIndex === index
                        }
                        onHide={handleClose}
                      >
                        <Modal.Header closeButton centered>
                          <Modal.Title>Add Banner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {value?.url && (
                            <div className="d-flex justify-content-center mb-3">
                              <img
                                src={value?.url}
                                alt="preview"
                                width="400px"
                              />
                            </div>
                          )}
                          <Form.Group
                            controlId="formBasicimage"
                            className="m-2"
                          >
                            <Form.Label>
                              Upload File{" "}
                              <span className="ml-2 dimension__text">
                                (221 × 221 px)
                              </span>
                            </Form.Label>
                            <Form.File
                              id={index}
                              label="Add image"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleFileChange(e, index, _index);
                              }}
                              custom
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasiclink" className="m-2">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="paste link"
                              name="link"
                              defaultValue={value?.link ?? ""}
                              onBlur={(e) => handleChangeText(e, index, _index)}
                            />
                            <Form.Text className="text-muted">
                              This Link will redirect to product page or
                              category page
                            </Form.Text>
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className={`${styles.actionBtn} btn-danger`}
                            variant="secondary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className="mb-0 d-flex mt-auto">
                    {!edit[_index] ? (
                      <div className="mt-auto d-flex align-items-center justify-content-center text-center">
                        <span>{value.desc ?? "Description"}</span>
                        <i
                          onClick={() => handleEdit(_index)}
                          className="icon-pencil p-2"
                          title="Edit"
                        ></i>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center px-2">
                        <input
                          type="text"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEdit(_index);
                              handleChangeText(e, index, _index);
                            }
                          }}
                          name="desc"
                          defaultValue={value.desc || ""}
                          onBlur={(e) => {
                            handleEdit(_index);
                            handleChangeText(e, index, _index);
                          }}
                          className={styles.descInput}
                          autoFocus={true}
                          placeholder="desc"
                        />
                      </div>
                    )}
                  </div>
                </strong>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.h93}>
          <i
            onClick={() => handleRemoveSection(index)}
            className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-close`}
            title="Remove"
            style={{ height: "fit-content" }}
          ></i>
          <i
            className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-plus`}
            onClick={() => {
              setindexToChange(section.values.length);
              handleShow(-1, index);
            }}
            title="Add"
            style={{ height: "fit-content" }}
          ></i>
        </div>
      </div>

      <Modal
        show={show && mainIndex === index && selectedIndex === -1}
        onHide={handleClose}
      >
        <Modal.Header closeButton centered>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {section.values?.[indexToChange]?.url && (
            <div className="d-flex justify-content-center mb-3">
              <img
                src={section.values?.[indexToChange]?.url}
                alt="preview"
                width="400px"
              />
            </div>
          )}
          <Form.Group controlId="formBasicimage" className="m-2">
            <Form.Label>
              Upload File{" "}
              <span className="ml-2 dimension__text">(221 × 221 px)</span>
            </Form.Label>
            <Form.File
              id={index}
              label="Add image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e, index, indexToChange);
              }}
              custom
            />
          </Form.Group>
          <Form.Group controlId="formBasiclink" className="m-2">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="paste link"
              name="link"
              onBlur={(e) => handleChangeText(e, index, indexToChange)}
            />
            <Form.Text className="text-muted">
              This Link will redirect to product page or category page
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`${styles.actionBtn} btn-danger`}
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShopByDeptSection;
