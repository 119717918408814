/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React from "react";
import { Link } from "react-router-dom";
import "./productCreate.css";
import TopBarProgress from "react-topbar-progress-indicator";
import "./hyperProduct.css";
import { getStores } from "../../../actions/store";
import Select from "react-select";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAttribute, updateHPM, hpmRetreive } from "../../../actions/product";
import { getCategoryName } from "../../../actions/childCategory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Spinner } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Alert from "../../../components/alert/alert.component";
import { withRouter } from "../../../utils/withRouter";

class EditHPM extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      comments: "",
      status: false,
      showModel: false,
      catagory: "",
      brand: "",
      getCatagories: [],
      getBrand: [],
      catagoryStatus: false,
      catagoryTitle: "",
      weight: "",
      height: "",
      productStatus: false,
      productTitle: "",
      productWeight: "",
      productHeight: "",
      details: [{ title: "", output: "", keyField: "" }],
      productDetails: [{ title: "", output: "", keyField: "", id: "" }],
      dataFromServer: {},

      getAttributes: [],
      errorFlag: false,
      removedProductDetails: [],
      removedDetails: [],
      hyperUpdateData: {},
      serverSideBrand: {},
      serSideCatagory: {},
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  textToggler = () => {
    this.setState({
      status: !this.state.status,
    });
  };
  catagoryToggler = () => {
    this.setState({
      catagoryStatus: !this.state.catagoryStatus,
    });
  };
  productToggler = () => {
    this.setState({
      productStatus: !this.state.productStatus,
    });
  };
  handleClose = () => this.setState({ showModel: false });
  handleShow = () => this.setState({ showModel: true });
  handleSearchable = (e) => {
    this.setState({ catagory: e.value });
  };
  handleSearchableBrand = (e) => {
    this.setState({ brand: e.value });
  };
  async componentDidMount() {
    const id = this.props.location.search.substr(4);
    const res = await this.props.hpmRetreive(id);
    this.setState({ hyperUpdateData: res });

    const getStores = await this.props.getStores();
    this.setState({ getBrand: getStores.data });
    console.log(getStores.data);
    let getBrandNew = [];
    this.state.getBrand.map((item) => {
      getBrandNew.push({ value: item._id, label: item.fullName });
    });
    this.setState({ getBrand: getBrandNew });
    const attribute = await this.props.getAttribute();
    this.setState({ getAttributes: attribute.data });
    console.log(attribute.data);
    const resCatagories = await this.props.getCategoryName();
    this.setState({ getCatagories: resCatagories });
    console.log(this.state.getCatagories);
    let getCategoriesNew = [];
    this.state.getCatagories.map((item) => {
      getCategoriesNew.push({ value: item._id, label: item.name });
    });
    this.setState({ getCatagories: getCategoriesNew });
    this.state.getCatagories.map((item) => {
      if (item.value === this.state.hyperUpdateData.configuration.categoryId) {
        console.log(item);
        this.setState({
          serverSideCatagory: item,
        });
      }
    });
    this.state.getBrand.map((item) => {
      if (item.value === this.state.hyperUpdateData.configuration.brandId) {
        console.log(item);
        this.setState({
          serverSideBrand: item,
        });
      }
    });
    const detailsArray = [];

    this.state.hyperUpdateData.configuration.groupConfiguration[1].configurationDetail.map(
      (item) => {
        detailsArray.push({
          title: item.title,
          output: item.outputField,
          keyField: item.keyField,
          keyFieldId: item.keyFieldId,
          id: item._id,
          sortOrder: item.sortOrder,
        });
      }
    );

    const ProductDetailsArray = [];

    this.state.hyperUpdateData.configuration.groupConfiguration[0].configurationDetail.map(
      (item) => {
        ProductDetailsArray.push({
          title: item.title,
          output: item.outputField,
          keyField: item.keyField,
          keyFieldId: item.keyFieldId,
          id: item._id,
          sortOrder: item.sortOrder,
        });
      }
    );

    console.log(ProductDetailsArray);
    this.setState({
      catagory: this.state.hyperUpdateData.configuration.categoryId,
      brand: this.state.hyperUpdateData.configuration.brandId,

      name: this.state.hyperUpdateData.configuration.name,
      comments: this.state.hyperUpdateData.configuration.comments,
      status: this.state.hyperUpdateData.configuration.status,

      catagoryStatus:
        this.state.hyperUpdateData.configuration.groupConfiguration[1].status,
      catagoryTitle:
        this.state.hyperUpdateData.configuration.groupConfiguration[1].title,
      weight:
        this.state.hyperUpdateData.configuration.groupConfiguration[1]
          .pictureWidth,
      height:
        this.state.hyperUpdateData.configuration.groupConfiguration[1]
          .pictureHeight,
      productStatus:
        this.state.hyperUpdateData.configuration.groupConfiguration[0].status,
      productTitle:
        this.state.hyperUpdateData.configuration.groupConfiguration[0].title,
      productWeight:
        this.state.hyperUpdateData.configuration.groupConfiguration[0]
          .pictureWidth,
      productHeight:
        this.state.hyperUpdateData.configuration.groupConfiguration[0]
          .pictureHeight,
      details: detailsArray,
      productDetails: ProductDetailsArray,
    });

    // console.log(detailsArray);

    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      name,
      comments,
      status,
      showModel,
      catagory,
      brand,
      getCatagories,
      getBrand,
      catagoryStatus,
      catagoryTitle,
      weight,
      height,
      details,
      productTitle,
      attributeFlag,
      productWeight,
      productHeight,
      productStatus,
      productDetails,
      removedProductDetails,
      removedDetails,
      hyperUpdateData,
    } = this.state;

    const configProduct = {
      type: "Product",
      title: productTitle,
      pictureWidth: productWeight,
      pictureHeight: productHeight,
      status: productStatus,
    };
    const configCategory = {
      type: "Category",
      title: catagoryTitle,
      pictureWidth: weight,
      pictureHeight: height,
      status: catagoryStatus,
    };
    const detailsArray = [];
    details.map((item, index) => {
      detailsArray.push({
        title: item.title,
        keyField: item.keyField,
        keyFieldId: item.keyFieldId,
        outputField: item.output,
        sortOrder: index + 1,
      });
    });
    const productDetailsArray = [];
    productDetails.map((item, index) => {
      productDetailsArray.push({
        title: item.title,
        keyField: item.keyField,
        keyFieldId: item.keyFieldId,
        outputField: item.output,
        sortOrder: index + 1,
      });
    });

    this.setState({ loading: false });

    const dataToSend = {
      name,
      categoryId: catagory,
      brandId: brand,
      comments,
      status,
      _id: hyperUpdateData.configuration._id,

      groupConfiguration: [
        { ...configProduct, configurationDetail: productDetailsArray },
        { ...configCategory, configurationDetail: detailsArray },
      ],
    };

    console.log(dataToSend);
    const res = await this.props.updateHPM(JSON.stringify(dataToSend));
    console.log(res);
    this.setState({ loading: false });
    if (res) {
      this.setState({});
      toast(`${res.data.message}`, { containerId: "B" });
    } else {
      toast(` Error Occured`, { containerId: "B" });
    }
  };

  errorHandler = () => {};

  //change in value  click event
  handleDetails = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.details];
    list[index][name] = value;
    this.setState({ details: list });
  };

  // handle   remove click event
  handleDetailsRemove = (index) => {
    const list = [...this.state.details];
    const deleteId = [...this.state.removedDetails];
    deleteId.push(list[index].id);
    this.setState({
      removedDetails: [...deleteId],
    });

    list.splice(index, 1);
    this.setState({ details: list });
  };

  // handle attribute add
  handleDetailsAdd = () => {
    this.setState({
      details: [
        ...this.state.details,
        {
          title: "",
          output: "",
          keyField: "",
        },
      ],
    });
  };

  //change in value  click event
  handleProductDetails = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.productDetails];
    list[index][name] = value;
    this.setState({ productDetails: list });
  };

  // handle   remove click event
  handleProductDetailsRemove = (index) => {
    const list = [...this.state.productDetails];
    const deleteId = [...this.state.removedProductDetails];
    deleteId.push(list[index].id);
    this.setState({
      removedProductDetails: [...deleteId],
    });

    list.splice(index, 1);
    this.setState({ productDetails: list });
  };

  // handle attribute add
  handleProductDetailsAdd = () => {
    this.setState({
      productDetails: [
        ...this.state.productDetails,
        {
          title: "",
          output: "",
          keyField: "",
        },
      ],
    });
  };

  render() {
    console.log(this.state.removedDetails);
    const {
      name,
      comments,
      status,
      showModel,
      catagory,
      brand,
      getCatagories,
      getBrand,
      catagoryStatus,
      catagoryTitle,
      weight,
      height,
      productStatus,
      productTitle,
      productWeight,
      productHeight,
      details,
      productDetails,
    } = this.state;

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Hyper Product Model</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Hyper Product Model
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Alert />
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-md-2">
                  <p className="mb-0">Name</p>
                </div>
                <div className="col-md-2">
                  <p className="mb-0">Select Category</p>
                </div>
                <div className="col-md-2">
                  <p className="mb-0">Select Brand</p>
                </div>
                <div className="col-md-2 ">
                  <p className="mb-0">Comments</p>
                </div>
                <div className="col-md-2 ">
                  <p className="mb-0">Status</p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Product Name"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  {this.state.loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <>
                      {
                        <Select
                          name="catagory"
                          id="catagory"
                          defaultValue={this.state.serverSideCatagory}
                          options={getCatagories && getCatagories}
                          onChange={(e) => this.handleSearchable(e)}
                        />
                      }
                    </>
                  )}
                </div>
                <div className="col-md-2">
                  {this.state.loading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="ml-5"
                    />
                  ) : (
                    <>
                      {
                        <Select
                          name="brand"
                          id="brand"
                          defaultValue={this.state.serverSideBrand}
                          options={getBrand && getBrand}
                          onChange={(e) => this.handleSearchableBrand(e)}
                        />
                      }
                    </>
                  )}
                </div>
                <div className="col-md-2 ">
                  <textarea
                    className="form-control"
                    placeholder="Enter Comments"
                    id="comments"
                    name="comments"
                    rows={4}
                    value={comments}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2 ">
                  <div className="connection-all" onClick={this.textToggler}>
                    <span className={status ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span className={status ? "editRight" : "editcenter "}>
                      OFF
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-secondary">
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-md-12 mb-3 mt-3">
                    <h5>Catagory Configuration</h5>
                  </div>
                  <div className="col-md-1">
                    <p>Status</p>
                  </div>
                  <div className="col-md-3 ">
                    <p>Title</p>
                  </div>
                  <div className="col-md-3 ">
                    <p>Picture(W*H)</p>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row  ">
                  <div className="col-md-1">
                    <div
                      className="connection-all"
                      onClick={this.catagoryToggler}
                    >
                      <span
                        className={catagoryStatus ? "allcenter" : "allLeft "}
                      >
                        ON
                      </span>

                      <span
                        className={catagoryStatus ? "editRight" : "editcenter "}
                      >
                        OFF
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className="form-control   "
                      id="catagoryTitle"
                      placeholder="catagory title"
                      name="catagoryTitle"
                      value={catagoryTitle}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <div className="d-flex">
                      <input
                        type="number"
                        className="form-control   "
                        id="weight"
                        placeholder="Weight"
                        name="weight"
                        value={weight}
                        onChange={this.handleChange}
                      />
                      <input
                        type="number"
                        className="form-control   "
                        id="height"
                        placeholder="Height"
                        name="height"
                        value={height}
                        onChange={this.handleChange}
                      />
                    </div>
                    {this.state.weight > 200 ? (
                      <Button variant="danger">
                        Width cannot be greater than 200
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.weight < 0 ? (
                      <Button variant="danger">
                        Width can not be less than 0
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.height < 0 ? (
                      <Button variant="danger">
                        Height can not be less than 0
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.height > 200 ? (
                      <Button variant="danger">
                        Height can not be greater than 200
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-md-12 mb-3 mt-5">
                    <h5> Category Details</h5>
                  </div>
                  <div className="col-md-1">
                    <p>No</p>
                  </div>
                  <div className="col-md-2">
                    <p>Title</p>
                  </div>
                  <div className="col-md-2 ">
                    <p>Key Fields</p>
                  </div>
                  <div className="col-md-2 ">
                    <p>Output Fields</p>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                {this.state.loading ? (
                  ""
                ) : (
                  <div className="row mb-3">
                    {details.map((x, i) => {
                      return (
                        <div className="container-fluid mt-2">
                          <div className="row">
                            <div className="col-md-1">
                              {x.sortOrder ? (
                                <p>{x.sortOrder}</p>
                              ) : (
                                <p>{i + 1}</p>
                              )}
                            </div>
                            <div className="col-md-2">
                              <input
                                name="title"
                                className="form-control"
                                value={x.title}
                                onChange={(e) => this.handleDetails(e, i)}
                                placeholder="Enter Title"
                              ></input>
                            </div>
                            <div className="col-md-2">
                              <select
                                name="keyFieldId"
                                className="form-control mt-2"
                                value={x.keyFieldId}
                                onChange={(e) => this.handleDetails(e, i)}
                              >
                                <option value="default">Choose</option>
                                {this.state.getAttributes &&
                                  this.state.getAttributes.map(
                                    (item, index) => (
                                      <option key={index} value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                            <div className="col-md-2">
                              <select
                                name="output"
                                className="form-control"
                                value={x.output}
                                onChange={(e) => this.handleDetails(e, i)}
                              >
                                {i > 0 ? (
                                  <>
                                    <option value="not choosen">Choose</option>

                                    <option value="text">Text</option>
                                  </>
                                ) : (
                                  <>
                                    <option value="not choosen">Choose</option>

                                    <option value="text">Text</option>
                                    <option value="pictures">Picture</option>
                                  </>
                                )}
                              </select>
                            </div>

                            <div className="col-md-1 ">
                              <div className="d-flex">
                                {details.length !== 1 && (
                                  <button
                                    type="button"
                                    class="minusBtn actionBtn "
                                    onClick={() => this.handleDetailsRemove(i)}
                                  >
                                    <div class="icon-minus actionBtn-icon minus">
                                      {" "}
                                    </div>
                                  </button>
                                )}
                                {details.length - 1 === i && (
                                  <button
                                    type="button"
                                    class="plusBtn actionBtn ml-2"
                                    onClick={this.handleDetailsAdd}
                                  >
                                    <div class="icon-plus actionBtn-icon plus">
                                      {" "}
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="border border-secondary">
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-md-12 mb-3 mt-3">
                    <h5>Product Configuration</h5>
                  </div>
                  <div className="col-md-1">
                    <p>Status</p>
                  </div>
                  <div className="col-md-3 ">
                    <p>Title</p>
                  </div>
                  <div className="col-md-3 ">
                    <p>Picture(W*H)</p>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row  ">
                  <div className="col-md-1">
                    <div
                      className="connection-all"
                      onClick={this.productToggler}
                    >
                      <span
                        className={productStatus ? "allcenter" : "allLeft "}
                      >
                        ON
                      </span>

                      <span
                        className={productStatus ? "editRight" : "editcenter "}
                      >
                        OFF
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <input
                      type="text"
                      className="form-control   "
                      id="productTitle"
                      placeholder="product title"
                      name="productTitle"
                      value={productTitle}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <div className="d-flex">
                      <input
                        type="number"
                        className="form-control   "
                        id="productWeight"
                        placeholder="Weight"
                        name="productWeight"
                        value={productWeight}
                        onChange={this.handleChange}
                      />
                      <input
                        type="number"
                        className="form-control   "
                        id="height"
                        placeholder="Height"
                        name="productHeight"
                        value={productHeight}
                        onChange={this.handleChange}
                      />
                    </div>
                    {this.state.productWeight > 200 ? (
                      <Button variant="danger">
                        Width cannot be greater than 200
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.productWeight < 0 ? (
                      <Button variant="danger">
                        Width can not be less than 0
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.productHeight < 0 ? (
                      <Button variant="danger">
                        Height can not be less than 0
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.productHeight > 200 ? (
                      <Button variant="danger">
                        Height can not be greater than 200
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-md-12 mb-3 mt-5">
                    <h5> Product Details</h5>
                  </div>

                  <div className="col-md-1">
                    <p>No</p>
                  </div>
                  <div className="col-md-2">
                    <p>Title</p>
                  </div>
                  <div className="col-md-2 ">
                    <p>Key Fields</p>
                  </div>
                  <div className="col-md-2 ">
                    <p>Output Fields</p>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                {this.state.loading ? (
                  ""
                ) : (
                  <div className="row mb-3">
                    {productDetails.map((x, i) => {
                      return (
                        <div className="container-fluid mt-2">
                          <div className="row">
                            <div className="col-md-1">
                              {x.sortOrder ? (
                                <p>{x.sortOrder}</p>
                              ) : (
                                <p>{i + 1}</p>
                              )}
                            </div>
                            <div className="col-md-2">
                              <input
                                name="title"
                                className="form-control"
                                value={x.title}
                                onChange={(e) =>
                                  this.handleProductDetails(e, i)
                                }
                                placeholder="Enter Title"
                              ></input>
                            </div>
                            <div className="col-md-2">
                              <select
                                name="keyFieldId"
                                className="form-control mt-2"
                                value={x.keyFieldId}
                                onChange={(e) =>
                                  this.handleProductDetails(e, i)
                                }
                              >
                                <option value="default">Choose</option>
                                {this.state.getAttributes &&
                                  this.state.getAttributes.map(
                                    (item, index) => (
                                      <option key={index} value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                            <div className="col-md-2">
                              <select
                                name="output"
                                className="form-control"
                                value={x.output}
                                onChange={(e) =>
                                  this.handleProductDetails(e, i)
                                }
                              >
                                {i > 0 ? (
                                  <>
                                    <option value="not choosen">Choose</option>

                                    <option value="text">Text</option>
                                  </>
                                ) : (
                                  <>
                                    <option value="not choosen">Choose</option>

                                    <option value="text">Text</option>
                                    <option value="pictures">Picture</option>
                                  </>
                                )}
                              </select>
                            </div>

                            <div className="col-md-1 ">
                              <div className="d-flex">
                                {productDetails.length !== 1 && (
                                  <button
                                    type="button"
                                    class="minusBtn actionBtn "
                                    onClick={() =>
                                      this.handleProductDetailsRemove(i)
                                    }
                                  >
                                    <div class="icon-minus actionBtn-icon minus">
                                      {" "}
                                    </div>
                                  </button>
                                )}
                                {productDetails.length - 1 === i && (
                                  <button
                                    type="button"
                                    class="plusBtn actionBtn ml-2"
                                    onClick={this.handleProductDetailsAdd}
                                  >
                                    <div class="icon-plus actionBtn-icon plus">
                                      {" "}
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      this._submitHandler();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

getCategoryName.propTypes = {
  getCategoryName: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};

updateHPM.propTypes = {
  updateHPM: PropTypes.func.isRequired,
};

hpmRetreive.propTypes = {
  hpmRetreive: PropTypes.func.isRequired,
};
export default withRouter(
  connect(null, {
    getCategoryName,
    getStores,
    getAttribute,
    hpmRetreive,
    updateHPM,
  })(EditHPM)
);
