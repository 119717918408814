import { SHOW_ALERT, REMOVE_ALERT } from "./types";
import uuid from "uuid";

export const alert = (msg, alertType, style) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SHOW_ALERT,
    payload: { msg, alertType, id, style }
  });

  setTimeout(() => {
    dispatch({ type: REMOVE_ALERT, payload: id });
  }, 5000);
};
