import React, { useEffect, useState } from "react";
import VendorRow from "./VendorRow";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { updateVendor } from "../../../../actions/user";

const AddNewContact = ({ isMount, setIsMount, OldData, setEdit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [Data, setData] = useState({
    POC: "",
    addressId: "",
    department: "",
    fax: "",
    phone: "",
    pocEmail: "",
    whatsapp: "",
  });

  useEffect(() => {
    return () => {};
  }, [OldData, id]);

  // handleSubmit
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    e.persist();
    const newData = [...OldData.departments];
    newData.push(Data);
    const newVendor = {
      ...data,
      departments: newData,
    };
    const res = await dispatch(updateVendor(newVendor, id));
    if (res) {
      setIsMount(!isMount);
      setEdit({
        open: false,
        edit: "",
        title: "",
      });
      setIsMount(!isMount);
    }
  };

  return (
    <>
      <div className="mt-4 px-3">
        <form onSubmit={(e) => handleSubmit(e, OldData)}>
          <VendorRow name={"POC"}>
            <input
              required
              placeholder="Enter POC Name"
              type="text"
              value={Data.POC}
              onChange={(e) => setData({ ...Data, POC: e.target.value })}
            />
          </VendorRow>
          <VendorRow name={"Department"}>
            <input
              placeholder="Enter Department Name"
              required
              type="text"
              value={Data.department}
              onChange={(e) => setData({ ...Data, department: e.target.value })}
            />
          </VendorRow>
          <VendorRow name={"Phone"}>
            <input
              placeholder="Enter Phone Number"
              required
              type="number"
              maxLength={11}
              value={Data.phone}
              onChange={(e) => setData({ ...Data, phone: e.target.value })}
            />
          </VendorRow>
          <VendorRow name={"POCEmail"}>
            <input
              placeholder="POC Email"
              required
              type="email"
              value={Data.pocEmail}
              onChange={(e) => setData({ ...Data, pocEmail: e.target.value })}
            />
          </VendorRow>
          <VendorRow name={"WhatsApp"}>
            <input
              placeholder="WhatsApp Number "
              required
              type="number"
              maxLength={11}
              value={Data.whatsapp}
              onChange={(e) => setData({ ...Data, whatsapp: e.target.value })}
            />
          </VendorRow>
          <VendorRow name={"Addresses"}>
            <select
              style={{
                width: "100%",
                border: "none",
                background: "transparent",
              }}
              required
              value={Data.addressId}
              name="Address"
              id="Address"
              onChange={(e) => setData({ ...Data, addressId: e.target.value })}
            >
              <option value={""}>Select Address</option>
              {OldData?.wareHouses?.map((wareHouse, index) => (
                <option key={index} value={wareHouse._id}>
                  {wareHouse?.city}, {wareHouse?.country}
                </option>
              ))}
            </select>
          </VendorRow>
          <button type="submit" className="add-distributions-btn">
            Add{" "}
          </button>
        </form>
      </div>
    </>
  );
};

export default AddNewContact;
