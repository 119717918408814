/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
// import CSVReader from "react-csv-reader";
import TopBarProgress from "react-topbar-progress-indicator";
import { getAllStores } from "../../../actions/store";
import * as XLSX from "xlsx";
import ProgressBar from "react-bootstrap/ProgressBar";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  bulkProducts,
  bulkProductsUpload,
  etlErrorLogs,
} from "../../../actions/product";
import { subCategoryNames } from "../../../actions/subCategory";
import { childSubCategoryNames } from "../../../actions/childSubCategory";
import { ToastContainer, toast } from "react-toastify";
import { getAllAttribute } from "../../../actions/product";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import { apiURL } from "../../../config/default";

// buldroducts styles
import "./bulkProducts.css";
import { FormControlLabel, FormGroup } from "@mui/material";

const handleForce = (data, fileInfo) => console.log(data, fileInfo);
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}
function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  let str = Array.from(arr, dec2hex).join("");
  console.log(str);
  return str;
}

const url = `${apiURL}bulk-product`;
const uploadData = async (data) => {
  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": `multipart/form-data; boundary=------${generateId()}`,
    },
    body: data,
  });
  console.log(response);
  return response;
};

class BulkProducts extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      handleDarkSideForce: "",
      subCategories: "",
      childCategories: "",
      buttonChange: "disabled",
      count: 0,
      getAllStores: [],
      brand: "",
      image: "",
      attributes: [],
      attributeNotFound: [],
      productError: [],
      mainImage: false,
      additionalImages: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
        this.fileData();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  attributeIdGetter = (name) => {
    let nn = name;
    if (
      name.includes("Style") &&
      name !== "Revers Side Style" &&
      name !== "Handle Style"
    ) {
      name = name.substring(0, 5);
    }
    if (name.length < 9 && name.includes("Finish")) {
      name = name.substring(0, 6);
    }
    if (
      name.includes("Material Type") &&
      name !== "Material Type Front" &&
      name !== "Material Type Back"
    ) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    if (name.includes("Item Pattern")) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    var id = this.state.attributes.find(function (o) {
      return o.name === name.trim();
    });
    if (id && id._id) {
      return id._id;
    } else {
      console.log(nn);
      this.setState({
        attributeNotFound: [...this.state.attributeNotFound, name],
      });
    }
  };
  attributeNameGetter = (attributeId) => {
    var attributeName = this.state.attributes.find(function (o) {
      return o._id === attributeId.trim();
    });
    if (attributeName && attributeName.name) {
      return attributeName.name;
    }
  };

  fileData = async () => {
    this.setState({ buttonChange: "disabled" });
    const response = await this.props.getAllStores();
    const _res = await this.props.subCategoryNames();
    // const __res = await this.props.mainCategories();
    // const ___res = await this.props.childSubCategoryNames();
    const attribute = await this.props.getAllAttribute();

    if (_res) {
      this.setState({
        subCategories: _res,
        attributes: attribute.data,
        getAllStores: response.data,
        buttonChange: "",
      });
    }
  };
  handleFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });
      this.setState({
        buttonChange: "",
        data: data,
        cols: make_cols(ws["!ref"]),
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }

  _submitHandler = async () => {
    // this.fileData();
    const {
      data,
      subCategories,
      childCategories,
      mainCategories,
      brand,
      image,
    } = this.state;

    if (brand && brand !== "" && data && data !== "") {
      let counter = 3;
      this.setState({ buttonChange: "disabled" });
      // const done = data.map(async (product_item, index1) => {
      for (let index1 = 0; index1 < data.length; index1++) {
        let product_item = data[index1];
        counter++;
        // setTimeout(async () => {
        if (
          product_item.item_name &&
          product_item.item_name !== null &&
          product_item.item_name !== "Item Name" &&
          product_item.item_model !== "Model" &&
          product_item.product_id !== "general"
        ) {
          let subCheck = false;
          let formData = new FormData();

          if (product_item.category_1 !== null) {
            // eslint-disable-next-line array-callback-return
            subCategories.map((item, index) => {
              if (
                product_item.category_1 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_1.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_2 &&
                product_item.category_2 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_2.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_3 &&
                product_item.category_3 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_3.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_4 &&
                product_item.category_4 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_4.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_5 &&
                product_item.category_5 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_5.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_6 &&
                product_item.category_6 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_6.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_7 &&
                product_item.category_7 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_7.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_8 &&
                product_item.category_8 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_8.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_9 &&
                product_item.category_9 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_9.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
              if (
                product_item.category_10 &&
                product_item.category_10 !== null &&
                item.name.toLowerCase() ===
                  product_item.category_10.toLowerCase()
              ) {
                subCheck = true;
                formData.append("category", item._id);
              }
            });
          }

          if (product_item.item_status !== null) {
            formData.append(
              "status",
              product_item.item_status.trim() === "Enable" ||
                product_item.item_status.trim() === "Enabled"
                ? true
                : false
            );
          }
          if (product_item.manufacturer !== null) {
            formData.append("manufacturer", product_item.manufacturer);
          }
          if (product_item.cost && product_item.cost !== null) {
            formData.append("cost", product_item.cost);
          }
          if (product_item.item_model && product_item.item_model !== null) {
            formData.append("modelNumber", product_item.item_model);
          }
          if (
            product_item.cost_start_date &&
            product_item.cost_start_date !== null
          ) {
            formData.append("promoCostStartDate", product_item.cost_start_date);
          }
          if (
            product_item.cost_end_date &&
            product_item.cost_end_date !== null
          ) {
            formData.append("promoCostEndDate", product_item.cost_end_date);
          }
          if (product_item.msrp && product_item.msrp !== null) {
            formData.append("msrp", product_item.msrp);
          }
          if (product_item.sale_price && product_item.sale_price !== null) {
            formData.append("salePrice", product_item.sale_price);
          }
          if (product_item.price_type && product_item.price_type !== null) {
            formData.append("priceType", product_item.price_type);
          }
          if (product_item.promo_price && product_item.promo_price !== null) {
            formData.append("promoPrice", product_item.promo_price);
          }
          if (
            product_item.price_start_date &&
            product_item.price_start_date !== null
          ) {
            formData.append(
              "promoCostStartDate",
              product_item.price_start_date
            );
          }
          if (
            product_item.price_end_date &&
            product_item.price_end_date !== null
          ) {
            formData.append("promoPriceEndDate", product_item.price_end_date);
          }
          if (product_item.shipping_fee && product_item.shipping_fee !== null) {
            formData.append("shippingFee", product_item.shipping_fee);
          }
          if (
            product_item.dropship_fee_1 &&
            product_item.dropship_fee_1 !== null
          ) {
            formData.append("dropshipFeeOne", product_item.dropship_fee_1);
          }
          if (
            product_item.dropship_fee_2 &&
            product_item.dropship_fee_2 !== null
          ) {
            formData.append("dropshipFeeTwo", product_item.dropship_fee_2);
          }
          if (product_item.product_type && product_item.product_type !== null) {
            formData.append("productType", product_item.product_type);
          }
          if (product_item.lead_time && product_item.lead_time !== null) {
            formData.append("timeLead", product_item.lead_time);
          }
          if (
            product_item.bullet_feature_1 &&
            product_item.bullet_feature_1 !== null
          ) {
            formData.append("bullets", product_item.bullet_feature_1);
          }
          if (
            product_item.bullet_feature_2 &&
            product_item.bullet_feature_2 !== null
          ) {
            formData.append("bullets", product_item.bullet_feature_2);
          }
          if (
            product_item.bullet_feature_3 &&
            product_item.bullet_feature_3 !== null
          ) {
            formData.append("bullets", product_item.bullet_feature_3);
          }
          if (
            product_item.bullet_feature_4 &&
            product_item.bullet_feature_4 !== null
          ) {
            formData.append("bullets", product_item.bullet_feature_4);
          }
          if (
            product_item.bullet_feature_5 &&
            product_item.bullet_feature_5 !== null
          ) {
            formData.append("bullets", product_item.bullet_feature_5);
          }

          formData.append("action", product_item.action_needed);
          formData.append("sku", product_item.sku ? product_item.sku : "");
          formData.append(
            "upc",
            product_item.upc ? product_item.upc.toString() : ""
          );
          formData.append("title", product_item.item_name);
          formData.append("country", product_item.country);
          formData.append(
            "description",
            product_item.description !== null ? product_item.description : ""
          );
          formData.append("slug", product_item.item_name);
          formData.append(
            "minimumQuantity",
            product_item.minimum_order_quantity !== null
              ? product_item.minimum_order_quantity
              : 1
          );
          formData.append(
            "groupedItem",
            product_item.is_item_grouped === "Yes" ? true : false
          );
          formData.append(
            "parentModel",
            product_item.parent_model !== null ? product_item.parent_model : 0
          );

          if (product_item.main_image) {
            formData.append("images", product_item.main_image);
          }
          if (
            product_item.additional_image_1 !== null &&
            product_item.additional_image_1 !== 0 &&
            product_item.additional_image_1 !== ""
          ) {
            formData.append("images", product_item.additional_image_1);
          }
          if (
            product_item.additional_image_2 !== null &&
            product_item.additional_image_2 !== 0 &&
            product_item.additional_image_2 !== ""
          ) {
            formData.append("images", product_item.additional_image_2);
          }
          if (
            product_item.additional_image_3 !== null &&
            product_item.additional_image_3 !== 0 &&
            product_item.additional_image_3 !== ""
          ) {
            formData.append("images", product_item.additional_image_3);
          }
          if (
            product_item.additional_image_4 !== null &&
            product_item.additional_image_4 !== 0 &&
            product_item.additional_image_4 !== ""
          ) {
            formData.append("images", product_item.additional_image_4);
          }
          if (
            product_item.additional_image_5 !== null &&
            product_item.additional_image_5 !== 0 &&
            product_item.additional_image_5 !== ""
          ) {
            formData.append("images", product_item.additional_image_5);
          }
          if (
            product_item.additional_image_6 !== null &&
            product_item.additional_image_6 !== 0 &&
            product_item.additional_image_6 !== ""
          ) {
            formData.append("images", product_item.additional_image_6);
          }
          if (
            product_item.additional_image_7 !== null &&
            product_item.additional_image_7 !== 0 &&
            product_item.additional_image_7 !== ""
          ) {
            formData.append("images", product_item.additional_image_7);
          }
          if (
            product_item.additional_image_8 !== null &&
            product_item.additional_image_8 !== 0 &&
            product_item.additional_image_8 !== ""
          ) {
            formData.append("images", product_item.additional_image_8);
          }
          if (
            product_item.additional_image_9 !== null &&
            product_item.additional_image_9 !== 0 &&
            product_item.additional_image_9 !== ""
          ) {
            formData.append("images", product_item.additional_image_9);
          }
          if (
            product_item.additional_image_10 !== null &&
            product_item.additional_image_10 !== 0 &&
            product_item.additional_image_10 !== ""
          ) {
            formData.append("images", product_item.additional_image_10);
          }
          if (
            product_item.additional_image_11 !== null &&
            product_item.additional_image_11 !== 0 &&
            product_item.additional_image_11 !== ""
          ) {
            formData.append("images", product_item.additional_image_11);
          }
          if (
            product_item.item_video !== null &&
            product_item.item_video !== 0 &&
            product_item.item_video !== ""
          ) {
            formData.append("images", product_item.item_video);
          }

          formData.append("brand", brand);

          formData.append(
            "indoor",
            product_item.indoor_use === "yes" ? true : false
          );
          formData.append(
            "ourdoor",
            product_item.outdoor_use === "yes" ? true : false
          );
          formData.append("tag", product_item.item_name);
          if (
            product_item.list_group_config !== null &&
            product_item.list_group_config !== ""
          ) {
            let groupConfig = [];
            if (product_item.list_group_config.indexOf(",") !== -1) {
              groupConfig = product_item.list_group_config.split(",");
            } else {
              groupConfig = [product_item.list_group_config];
            }

            groupConfig.forEach((element) => {
              formData.append(
                "categoryConfig",
                JSON.stringify({
                  name: element.trim(),
                  attributeId: this.attributeIdGetter(element.trim()),
                })
              );
            });
          }
          if (
            product_item.detail_group_config !== null &&
            product_item.detail_group_config !== ""
          ) {
            let productConfig = [];

            if (product_item.detail_group_config.indexOf(",") > -1) {
              productConfig = product_item.detail_group_config.split(",");
            } else {
              productConfig = [product_item.detail_group_config];
            }

            productConfig.forEach((element) => {
              formData.append(
                "productConfig",
                JSON.stringify({
                  name: element.trim(),
                  attributeId: this.attributeIdGetter(element.trim()),
                })
              );
            });
          }

          formData.append("sortOrder", 1);
          formData.append("quantity", 50);
          formData.append("relatedProducts", product_item.related_items);
          formData.append("outOfStock", false);
          formData.append(
            "requireShipping",
            product_item.use_shipping === "Yes" ? true : false
          );
          if (
            product_item.item_height !== null &&
            product_item.item_height !== ""
          ) {
            formData.append("height", Number(product_item.item_height));
          }
          if (
            product_item.item_depth !== null &&
            product_item.item_depth !== ""
          ) {
            formData.append("depth", Number(product_item.item_depth));
          }
          if (
            product_item.item_weight !== null &&
            product_item.item_weight !== ""
          ) {
            formData.append("weight", Number(product_item.item_weight));
          }
          if (
            product_item.item_width !== null &&
            product_item.item_width !== ""
          ) {
            formData.append("width", Number(product_item.item_width));
          }
          // formData.append("weigthClass", product_item.package_weight_1);
          if (
            product_item.item_length !== null &&
            product_item.item_length !== ""
          ) {
            formData.append("length", Number(product_item.item_length));
          }

          // formData.append("lengthClass", product_item.package_length_1);

          formData.append("vendor", brand);
          formData.append("metaTagTitle", product_item.item_name);
          formData.append("metaTagDescription", product_item.item_name);
          formData.append("metaTagKeyword", product_item.item_name);
          formData.append("seoKeyword", product_item.item_name);

          if (
            product_item.sales_uom !== null &&
            product_item.sales_uom !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Sales UOM"),
                value: product_item.sales_uom,
              })
            );
          }
          if (
            product_item.top_seller !== null &&
            product_item.top_seller !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Top Seller (Yes/No)"),
                value: product_item.top_seller,
              })
            );
          }
          if (
            product_item.ada_compliant !== null &&
            product_item.ada_compliant !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("ADA Compliant (Yes/No)"),
                value: product_item.ada_compliant,
              })
            );
          }

          if (
            product_item.proposition_65_compliance !== null &&
            product_item.proposition_65_compliance !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter(
                  "Proposition 65 Compliance (Yes/No)"
                ),
                value: product_item.proposition_65_compliance,
              })
            );
          }
          if (
            product_item.eco_friendly !== null &&
            product_item.eco_friendly !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Eco Friendly (Yes/No)"),
                value: product_item.eco_friendly,
              })
            );
          }
          if (
            product_item.composite_wood_products !== null &&
            product_item.composite_wood_products !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Composite Wood Product (Yes/No)"),
                value: product_item.composite_wood_products,
              })
            );
          }
          if (
            product_item.reclaimed_wood !== null &&
            product_item.reclaimed_wood !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Reclaimed Wood (Yes/No)"),
                value: product_item.reclaimed_wood,
              })
            );
          }
          if (
            product_item.water_resistant !== null &&
            product_item.water_resistant !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Water Resistant (Yes/No)"),
                value: product_item.water_resistant,
              })
            );
          }
          if (
            product_item.water_absorption !== null &&
            product_item.water_absorption !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Water Absorption"),
                value: product_item.water_absorption,
              })
            );
          }
          if (
            product_item.stain_resistant !== null &&
            product_item.stain_resistant !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Stain Resistant (Yes/No)"),
                value: product_item.stain_resistant,
              })
            );
          }
          if (
            product_item.abrasion_resistance !== null &&
            product_item.abrasion_resistance !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Abrasion Resistance (Yes/No)"),
                value: product_item.abrasion_resistance,
              })
            );
          }
          if (
            product_item.freeze_resistance !== null &&
            product_item.freeze_resistance !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Freeze Resistance (Yes/No)"),
                value: product_item.freeze_resistance,
              })
            );
          }
          if (
            product_item.weather_resistant !== null &&
            product_item.weather_resistant !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Weather Resistant (Yes/No)"),
                value: product_item.weather_resistant,
              })
            );
          }
          if (
            product_item.mildew_resistant !== null &&
            product_item.mildew_resistant !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Mildew Resistant (Yes/No)"),
                value: product_item.mildew_resistant,
              })
            );
          }
          if (
            product_item.hypoallergenic !== null &&
            product_item.hypoallergenic !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hypoallergenic (Yes/No)"),
                value: product_item.hypoallergenic,
              })
            );
          }
          if (
            product_item.recycled_content !== null &&
            product_item.recycled_content !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Recycled Content (Yes/No)"),
                value: product_item.recycled_content,
              })
            );
          }
          if (
            product_item.moisture_wicking !== null &&
            product_item.moisture_wicking !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Moisture Wicking (Yes/No)"),
                value: product_item.moisture_wicking,
              })
            );
          }
          if (
            product_item.certified_wet_damp !== null &&
            product_item.certified_wet_damp !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Certified Wet or Damp"),
                value: product_item.certified_wet_damp,
              })
            );
          }
          if (
            product_item.item_reversible !== null &&
            product_item.item_reversible !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Reversible (Yes/No)"),
                value: product_item.item_reversible,
              })
            );
          }
          if (
            product_item.item_cover !== null &&
            product_item.item_cover !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Product Cover"),
                value: product_item.item_cover,
              })
            );
          }
          if (
            product_item.removable_cover !== null &&
            product_item.removable_cover !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Removable Cover (Yes/No)"),
                value: product_item.removable_cover,
              })
            );
          }
          if (
            product_item.item_embroidered !== null &&
            product_item.item_embroidered !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Embroidered (Yes/No)"),
                value: product_item.item_embroidered,
              })
            );
          }
          if (
            product_item.item_applique !== null &&
            product_item.item_applique !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Applique (Yes/No)"),
                value: product_item.item_applique,
              })
            );
          }
          if (
            product_item.item_buttons !== null &&
            product_item.item_buttons !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Buttons (Yes/No)"),
                value: product_item.item_buttons,
              })
            );
          }
          if (
            product_item.item_sequined !== null &&
            product_item.item_sequined !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Sequined (Yes/No)"),
                value: product_item.item_sequined,
              })
            );
          }
          if (
            product_item.item_tassels_included !== null &&
            product_item.item_tassels_included !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Tassels Included (Yes/No)"),
                value: product_item.item_tassels_included,
              })
            );
          }
          if (
            product_item.item_backing_material !== null &&
            product_item.item_backing_material !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Backing Material"),
                value: product_item.item_backing_material,
              })
            );
          }
          if (
            product_item.item_collection !== null &&
            product_item.item_collection !== ""
          ) {
            let item_collection = product_item.item_collection.split(",");
            item_collection.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Collection Name"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.collection_description !== null &&
            product_item.collection_description !== ""
          ) {
            let collection_description =
              product_item.collection_description.split(",");
            collection_description.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Collection Description"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_1 !== null &&
            product_item.item_style_1 !== ""
          ) {
            let item_style_1 = product_item.item_style_1.split(",");
            item_style_1.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_2 !== null &&
            product_item.item_style_2 !== ""
          ) {
            let item_style_2 = product_item.item_style_2.split(",");
            item_style_2.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 2"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_3 !== null &&
            product_item.item_style_3 !== ""
          ) {
            let item_style_3 = product_item.item_style_3.split(",");
            item_style_3.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 3"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_4 !== null &&
            product_item.item_style_4 !== ""
          ) {
            let item_style_4 = product_item.item_style_4.split(",");
            item_style_4.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 4"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_5 !== null &&
            product_item.item_style_5 !== ""
          ) {
            let item_style_5 = product_item.item_style_5.split(",");
            item_style_5.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 5"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_style_6 !== null &&
            product_item.item_style_6 !== ""
          ) {
            let item_style_6 = product_item.item_style_6.split(",");
            item_style_6.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Style 6"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.reverse_side_style !== null &&
            product_item.reverse_side_style !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Revers Side Style"),
                value: product_item.reverse_side_style,
              })
            );
          }
          if (
            product_item.item_color !== null &&
            product_item.item_color !== ""
          ) {
            let colors = product_item.item_color.split(",");
            colors.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Item Color"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.color_family !== null &&
            product_item.color_family !== ""
          ) {
            let familyColors = product_item.color_family.split(",");
            familyColors.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Color Family"),
                  value: element,
                })
              );
            });
          }

          if (
            product_item.item_pattern_1 !== null &&
            product_item.item_pattern_1 !== ""
          ) {
            let item_pattern_1 = product_item.item_pattern_1.split(",");
            item_pattern_1.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Item Pattern 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_pattern_2 !== null &&
            product_item.item_pattern_2 !== ""
          ) {
            let item_pattern_2 = product_item.item_pattern_2.split(",");
            item_pattern_2.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Item Pattern 2"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.reverse_side_pattern !== null &&
            product_item.reverse_side_pattern !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Reverse Side Pattern"),
                value: product_item.reverse_side_pattern,
              })
            );
          }
          if (
            product_item.item_flange !== null &&
            product_item.item_flange !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Flange Color"),
                value: product_item.item_flange,
              })
            );
          }
          if (
            product_item.item_tassel_color !== null &&
            product_item.item_tassel_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tassel Color"),
                value: product_item.item_tassel_color,
              })
            );
          }
          if (
            product_item.tassel_type !== null &&
            product_item.tassel_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tassel Type"),
                value: product_item.tassel_type,
              })
            );
          }
          if (
            product_item.item_piping_color !== null &&
            product_item.item_piping_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Piping Color"),
                value: product_item.item_piping_color,
              })
            );
          }
          if (
            product_item.reverse_side_color !== null &&
            product_item.reverse_side_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Reverse Side Color"),
                value: product_item.reverse_side_color,
              })
            );
          }
          if (
            product_item.item_construction !== null &&
            product_item.item_construction !== ""
          ) {
            let item_construction = product_item.item_construction.split(",");
            item_construction.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Item Construction"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_technique !== null &&
            product_item.item_technique !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Technique"),
                value: product_item.item_technique,
              })
            );
          }
          if (
            product_item.distressed_finish !== null &&
            product_item.distressed_finish !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Distressed Finish (Yes/No)"),
                value: product_item.distressed_finish,
              })
            );
          }
          if (
            product_item.item_finish_1 !== null &&
            product_item.item_finish_1 !== ""
          ) {
            let item_finish_1 = product_item.item_finish_1.split(",");
            item_finish_1.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Finish 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_finish_2 !== null &&
            product_item.item_finish_2 !== ""
          ) {
            let item_finish_2 = product_item.item_finish_2.split(",");
            item_finish_2.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Finish 2"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_finish_3 !== null &&
            product_item.item_finish_3 !== ""
          ) {
            let item_finish_3 = product_item.item_finish_3.split(",");
            item_finish_3.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Finish 3"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_finish_group !== null &&
            product_item.item_finish_group !== ""
          ) {
            let item_finish_group = product_item.item_finish_group.split(",");
            item_finish_group.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Finish Group"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_1 !== null &&
            product_item.item_material_1 !== ""
          ) {
            let attrib = product_item.item_material_1.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_2 !== null &&
            product_item.item_material_2 !== ""
          ) {
            let attrib = product_item.item_material_2.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type 2"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_3 !== null &&
            product_item.item_material_3 !== ""
          ) {
            let attrib = product_item.item_material_3.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type 3"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_4 !== null &&
            product_item.item_material_4 !== ""
          ) {
            let attrib = product_item.item_material_4.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type 4"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_5 !== null &&
            product_item.item_material_5 !== ""
          ) {
            let attrib = product_item.item_material_5.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type 5"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_front !== null &&
            product_item.item_material_front !== ""
          ) {
            let attrib = product_item.item_material_front.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type Front"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_material_back !== null &&
            product_item.item_material_back !== ""
          ) {
            let attrib = product_item.item_material_back.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Material Type Back"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.fill_material !== null &&
            product_item.fill_material !== ""
          ) {
            let attrib = product_item.fill_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Fill Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_border !== null &&
            product_item.item_border !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Border"),
                value: product_item.item_border,
              })
            );
          }
          if (
            product_item.assembly_required !== null &&
            product_item.assembly_required !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Assembly Required (Yes/No)"),
                value: product_item.assembly_required,
              })
            );
          }
          if (
            product_item.item_adjustable !== null &&
            product_item.item_adjustable !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Adjustable (Yes/No)"),
                value: product_item.item_adjustable,
              })
            );
          }
          if (
            product_item.set_number !== null &&
            product_item.set_number !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Set of"),
                value: product_item.set_number,
              })
            );
          }
          if (
            product_item.item_shape !== null &&
            product_item.item_shape !== ""
          ) {
            let attrib = product_item.item_shape.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Shape"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_size !== null &&
            product_item.item_size !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Size"),
                value: product_item.item_size,
              })
            );
          }
          if (
            product_item.item_size_group !== null &&
            product_item.item_size_group !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Size Group"),
                value: product_item.item_size_group,
              })
            );
          }
          if (
            product_item.item_dimensions !== null &&
            product_item.item_dimensions !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Dimensions (L x W x H)"),
                value: product_item.item_dimensions,
              })
            );
          }
          if (
            product_item.item_length !== null &&
            product_item.item_length !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Length (in)"),
                value: product_item.item_length,
              })
            );
          }
          if (
            product_item.item_overall_length_range !== null &&
            product_item.item_overall_length_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Overall Length Range (in)"),
                value: product_item.item_overall_length_range,
              })
            );
          }
          if (
            product_item.item_height !== null &&
            product_item.item_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Height (in)"),
                value: product_item.item_height,
              })
            );
          }
          if (
            product_item.item_overall_height_range !== null &&
            product_item.item_overall_height_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Overall Height Range (in)"),
                value: product_item.item_overall_height_range,
              })
            );
          }
          if (
            product_item.item_adjustable_height !== null &&
            product_item.item_adjustable_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Adjustable Height (in)"),
                value: product_item.item_adjustable_height,
              })
            );
          }
          if (
            product_item.item_pile_type !== null &&
            product_item.item_pile_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Pile Type"),
                value: product_item.item_pile_type,
              })
            );
          }
          if (
            product_item.item_pile_height !== null &&
            product_item.item_pile_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Pile Height (in)"),
                value: product_item.item_pile_height,
              })
            );
          }
          if (
            product_item.item_width !== null &&
            product_item.item_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Width (in)"),
                value: product_item.item_width,
              })
            );
          }
          if (
            product_item.item_overall_width_range !== null &&
            product_item.item_overall_width_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Overall Width Range (in)"),
                value: product_item.item_overall_width_range,
              })
            );
          }
          if (
            product_item.item_diameter !== null &&
            product_item.item_diameter !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Diameter (in)"),
                value: product_item.item_diameter,
              })
            );
          }
          if (
            product_item.item_overall_diameter_range !== null &&
            product_item.item_overall_diameter_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter(
                  "Item Overall Diameter Range (in)"
                ),
                value: product_item.item_overall_diameter_range,
              })
            );
          }
          if (
            product_item.item_weight !== null &&
            product_item.item_weight !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Weight (lbs)"),
                value: product_item.item_weight,
              })
            );
          }
          if (
            product_item.weight_capacity !== null &&
            product_item.weight_capacity !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Weight Capacity (lbs)"),
                value: product_item.weight_capacity,
              })
            );
          }
          if (
            product_item.item_depth !== null &&
            product_item.item_depth !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Depth (in)"),
                value: product_item.item_depth,
              })
            );
          }

          if (
            product_item.item_overall_depth_range !== null &&
            product_item.item_overall_depth_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Overall Depth Range (in)"),
                value: product_item.item_overall_depth_range,
              })
            );
          }
          if (
            product_item.item_thickness !== null &&
            product_item.item_thickness !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Thickness (in)"),
                value: product_item.item_thickness,
              })
            );
          }
          if (
            product_item.item_center_to_center !== null &&
            product_item.item_center_to_center !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Center to center (in)"),
                value: product_item.item_center_to_center,
              })
            );
          }
          if (
            product_item.item_center_to_center_range !== null &&
            product_item.item_center_to_center_range !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter(
                  "Item Center to Center Range (in)"
                ),
                value: product_item.item_center_to_center_range,
              })
            );
          }
          if (
            product_item.item_clearance !== null &&
            product_item.item_clearance !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Clearance (in)"),
                value: product_item.item_clearance,
              })
            );
          }
          if (
            product_item.item_fringe !== null &&
            product_item.item_fringe !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Fringe (Yes/No)"),
                value: product_item.item_fringe,
              })
            );
          }
          if (
            product_item.item_fringe_type !== null &&
            product_item.item_fringe_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Fringe Type"),
                value: product_item.item_fringe_type,
              })
            );
          }
          if (
            product_item.item_flange !== null &&
            product_item.item_flange !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Flange (Yes/No)"),
                value: product_item.item_flange,
              })
            );
          }
          if (
            product_item.item_closure_type !== null &&
            product_item.item_closure_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Closure Type"),
                value: product_item.item_closure_type,
              })
            );
          }
          if (
            product_item.item_foot_width !== null &&
            product_item.item_foot_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Foot Width (in)"),
                value: product_item.item_foot_width,
              })
            );
          }
          if (
            product_item.item_projection !== null &&
            product_item.item_projection !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Projection (in)"),
                value: product_item.item_projection,
              })
            );
          }
          if (
            product_item.with_without_screws !== null &&
            product_item.with_without_screws !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("With or Without Screws"),
                value: product_item.with_without_screws,
              })
            );
          }
          if (
            product_item.recommended_screws !== null &&
            product_item.recommended_screws !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Recommended Screws"),
                value: product_item.recommended_screws,
              })
            );
          }
          if (
            product_item.helpful_tips !== null &&
            product_item.helpful_tips !== ""
          ) {
            let attrib = product_item.helpful_tips.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Product Helpful Tips"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.indoor_use !== null &&
            product_item.indoor_use !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Indoor Use (Yes/No)"),
                value: product_item.indoor_use,
              })
            );
          }
          if (
            product_item.outdoor_use !== null &&
            product_item.outdoor_use !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Outdoor Use (Yes/No)"),
                value: product_item.outdoor_use,
              })
            );
          }
          if (
            product_item.installation_type !== null &&
            product_item.installation_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Installation Type"),
                value: product_item.installation_type,
              })
            );
          }
          if (
            product_item.sink_back_front !== null &&
            product_item.sink_back_front !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Sink Back to Front Dimension"),
                value: product_item.sink_back_front,
              })
            );
          }
          if (
            product_item.sink_bowl_depth !== null &&
            product_item.sink_bowl_depth !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Sink Bowl Depth"),
                value: product_item.sink_bowl_depth,
              })
            );
          }
          if (
            product_item.sink_gauge !== null &&
            product_item.sink_gauge !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Gauge"),
                value: product_item.sink_gauge,
              })
            );
          }
          if (
            product_item.overflow_drain !== null &&
            product_item.overflow_drain !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Overflow Drain (Yes/No)"),
                value: product_item.overflow_drain,
              })
            );
          }
          if (
            product_item.sink_shape !== null &&
            product_item.sink_shape !== ""
          ) {
            let attrib = product_item.sink_shape.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Sink Shape"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.drain_placement !== null &&
            product_item.drain_placement !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Drain Placement"),
                value: product_item.drain_placement,
              })
            );
          }
          if (
            product_item.spout_reach_width !== null &&
            product_item.spout_reach_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Spout Reach Width (in)"),
                value: product_item.spout_reach_width,
              })
            );
          }
          if (
            product_item.size_class !== null &&
            product_item.size_class !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Size Class"),
                value: product_item.size_class,
              })
            );
          }
          if (
            product_item.top_and_bowl_preinstalled !== null &&
            product_item.top_and_bowl_preinstalled !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter(
                  "Top and Bowl Pre-Installed? (Yes/No)"
                ),
                value: product_item.top_and_bowl_preinstalled,
              })
            );
          }
          if (
            product_item.coordinating_mirrors_for_vanity !== null &&
            product_item.coordinating_mirrors_for_vanity !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Coordinating Mirrors for Vanity"),
                value: product_item.coordinating_mirrors_for_vanity,
              })
            );
          }
          if (
            product_item.spout_reach_height !== null &&
            product_item.spout_reach_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Spout Reach Height (in)"),
                value: product_item.spout_reach_height,
              })
            );
          }
          if (
            product_item.water_flow_rate !== null &&
            product_item.water_flow_rate !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Water Flow Rate (GPM)"),
                value: product_item.water_flow_rate,
              })
            );
          }
          if (
            product_item.pullout_sprayer !== null &&
            product_item.pullout_sprayer !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Pullout Sprayer (Yes/No)"),
                value: product_item.pullout_sprayer,
              })
            );
          }
          if (
            product_item.sensor_activated !== null &&
            product_item.sensor_activated !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Sensor Activated (Yes/No)"),
                value: product_item.sensor_activated,
              })
            );
          }
          if (
            product_item.number_handles !== null &&
            product_item.number_handles !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Number of Handles"),
                value: product_item.number_handles,
              })
            );
          }
          if (
            product_item.handle_style !== null &&
            product_item.handle_style !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Handle Style"),
                value: product_item.handle_style,
              })
            );
          }
          if (
            product_item.number_faucet_holes_required !== null &&
            product_item.number_faucet_holes_required !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Number of Faucet Holes Required"),
                value: product_item.number_faucet_holes_required,
              })
            );
          }
          if (
            product_item.water_flow_controls !== null &&
            product_item.water_flow_controls !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Water Flow Controls"),
                value: product_item.water_flow_controls,
              })
            );
          }
          if (
            product_item.valve_type !== null &&
            product_item.valve_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Valve Type"),
                value: product_item.valve_type,
              })
            );
          }
          if (
            product_item.bulb_count_1 !== null &&
            product_item.bulb_count_1 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Count 1"),
                value: product_item.bulb_count_1,
              })
            );
          }
          if (
            product_item.bulb_wattage_1 !== null &&
            product_item.bulb_wattage_1 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Wattage 1"),
                value: product_item.bulb_wattage_1,
              })
            );
          }
          if (
            product_item.bulb_type_1 !== null &&
            product_item.bulb_type_1 !== ""
          ) {
            let attrib = product_item.bulb_type_1.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Bulb Type 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.bulb_base_1 !== null &&
            product_item.bulb_base_1 !== ""
          ) {
            let attrib = product_item.bulb_base_1.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Bulb Base 1"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.bulb_included_1 !== null &&
            product_item.bulb_included_1 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Included 1 (Yes/No)"),
                value: product_item.bulb_included_1,
              })
            );
          }
          if (
            product_item.bulb_count_2 !== null &&
            product_item.bulb_count_2 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Count 2"),
                value: product_item.bulb_count_2,
              })
            );
          }
          if (
            product_item.bulb_wattage_2 !== null &&
            product_item.bulb_wattage_2 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Wattage 2"),
                value: product_item.bulb_wattage_2,
              })
            );
          }
          if (
            product_item.bulb_type_2 !== null &&
            product_item.bulb_type_2 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Type 2"),
                value: product_item.bulb_type_2,
              })
            );
          }
          if (
            product_item.bulb_base_2 !== null &&
            product_item.bulb_base_2 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Base 2"),
                value: product_item.bulb_base_2,
              })
            );
          }
          if (
            product_item.bulb_included_2 !== null &&
            product_item.bulb_included_2 !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Included 2 (Yes/No)"),
                value: product_item.bulb_included_2,
              })
            );
          }
          if (product_item.led !== null && product_item.led !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("LED (Yes/No)"),
                value: product_item.led,
              })
            );
          }
          if (
            product_item.total_lumens !== null &&
            product_item.total_lumens !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Total Lumens"),
                value: product_item.total_lumens,
              })
            );
          }
          if (
            product_item.color_temperature !== null &&
            product_item.color_temperature !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Color Temperature"),
                value: product_item.color_temperature,
              })
            );
          }
          if (product_item.cri !== null && product_item.cri !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Color Rendering Index"),
                value: product_item.cri,
              })
            );
          }
          if (product_item.voltage !== null && product_item.voltage !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Voltage"),
                value: product_item.voltage,
              })
            );
          }
          if (
            product_item.switch_type !== null &&
            product_item.switch_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Switch Type"),
                value: product_item.switch_type,
              })
            );
          }
          if (product_item.dimmable !== null && product_item.dimmable !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Dimmable (Yes/No)"),
                value: product_item.dimmable,
              })
            );
          }
          if (
            product_item.lamp_base_dimensions !== null &&
            product_item.lamp_base_dimensions !== ""
          ) {
            let attrib = product_item.lamp_base_dimensions.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Lamp Base Dimensions (in)"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.backplate_canopy_dimensions !== null &&
            product_item.backplate_canopy_dimensions !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter(
                  "Backplate/Canopy Dimensions (in)"
                ),
                value: product_item.backplate_canopy_dimensions,
              })
            );
          }
          if (
            product_item.extension_rods !== null &&
            product_item.extension_rods !== ""
          ) {
            const isString = /,/.test(product_item.extension_rods);
            if (isString) {
              let attrib = product_item.extension_rods.split(",");
              attrib.forEach((element) => {
                formData.append(
                  "specification",
                  JSON.stringify({
                    name: this.attributeIdGetter("Extension Rods (in)"),
                    value: element,
                  })
                );
              });
            } else {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Extension Rods (in)"),
                  value: product_item.extension_rods,
                })
              );
            }
          }
          if (
            product_item.shade_glass_description !== null &&
            product_item.shade_glass_description !== ""
          ) {
            let attrib = product_item.shade_glass_description.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Shade/Glass Description"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.shade_glass_material !== null &&
            product_item.shade_glass_material !== ""
          ) {
            let attrib = product_item.shade_glass_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Shade/Glass Materials"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.shade_glass_finish !== null &&
            product_item.shade_glass_finish !== ""
          ) {
            let attrib = product_item.shade_glass_finish.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Shade/Glass Finish"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.shade_glass_width !== null &&
            product_item.shade_glass_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Shade/Glass Width (in)"),
                value: product_item.shade_glass_width,
              })
            );
          }
          if (
            product_item.shade_glass_height !== null &&
            product_item.shade_glass_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Shade/Glass Height (in)"),
                value: product_item.shade_glass_height,
              })
            );
          }
          if (
            product_item.shade_shape !== null &&
            product_item.shade_shape !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Shade Shape"),
                value: product_item.shade_shape,
              })
            );
          }
          if (
            product_item.harp_spider !== null &&
            product_item.harp_spider !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Harp/Spider"),
                value: product_item.harp_spider,
              })
            );
          }

          if (
            product_item.cord_color !== null &&
            product_item.cord_color !== ""
          ) {
            let attrib = product_item.cord_color.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Cord Color"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.cord_length !== null &&
            product_item.cord_length !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Cord Length (in)"),
                value: product_item.cord_length,
              })
            );
          }
          if (
            product_item.build_in_outlet !== null &&
            product_item.build_in_outlet !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Build-in Outlet (Yes/No)"),
                value: product_item.build_in_outlet,
              })
            );
          }
          if (
            product_item.chain_length !== null &&
            product_item.chain_length !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Chain Length (in)"),
                value: product_item.chain_length,
              })
            );
          }
          if (
            product_item.mirror_width !== null &&
            product_item.mirror_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Mirror Width (in)"),
                value: product_item.mirror_width,
              })
            );
          }
          if (
            product_item.mirror_height !== null &&
            product_item.mirror_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Mirror Height (in)"),
                value: product_item.mirror_height,
              })
            );
          }
          if (
            product_item.mirror_shape !== null &&
            product_item.mirror_shape !== ""
          ) {
            let attrib = product_item.mirror_shape.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Mirror Shape"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.print_process !== null &&
            product_item.print_process !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Print Process"),
                value: product_item.print_process,
              })
            );
          }
          if (
            product_item.printed_on !== null &&
            product_item.printed_on !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Printed On"),
                value: product_item.printed_on,
              })
            );
          }
          if (
            product_item.hang_orientation !== null &&
            product_item.hang_orientation !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hang Orientation"),
                value: product_item.hang_orientation,
              })
            );
          }
          if (
            product_item.hang_hardware !== null &&
            product_item.hang_hardware !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hang Hardware"),
                value: product_item.hang_hardware,
              })
            );
          }
          if (
            product_item.tabletop_thickness !== null &&
            product_item.tabletop_thickness !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tabletop Thickness (in)"),
                value: product_item.tabletop_thickness,
              })
            );
          }
          if (
            product_item.furniture_stackable !== null &&
            product_item.furniture_stackable !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Stackable (Yes/No)"),
                value: product_item.furniture_stackable,
              })
            );
          }
          if (
            product_item.furniture_legs !== null &&
            product_item.furniture_legs !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Legs (Yes/No)"),
                value: product_item.furniture_legs,
              })
            );
          }
          if (
            product_item.beveled_glass !== null &&
            product_item.beveled_glass !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Beveled Glass (Yes/No)"),
                value: product_item.beveled_glass,
              })
            );
          }
          if (
            product_item.gloss_level !== null &&
            product_item.gloss_level !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Gloss Level"),
                value: product_item.gloss_level,
              })
            );
          }
          if (
            product_item.tempered_glass !== null &&
            product_item.tempered_glass !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tempered Glass"),
                value: product_item.tempered_glass,
              })
            );
          }
          if (
            product_item.wood_finish !== null &&
            product_item.wood_finish !== ""
          ) {
            let attrib = product_item.wood_finish.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Wood Finish"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.furniture_headboard !== null &&
            product_item.furniture_headboard !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Headboard (Yes/No)"),
                value: product_item.furniture_headboard,
              })
            );
          }
          if (
            product_item.furniture_headboard_height !== null &&
            product_item.furniture_headboard_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Headboard Height (in)"),
                value: product_item.furniture_headboard_height,
              })
            );
          }
          if (
            product_item.furniture_headboard_length !== null &&
            product_item.furniture_headboard_length !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Headboard Length (in)"),
                value: product_item.furniture_headboard_length,
              })
            );
          }
          if (
            product_item.furniture_headboard_width !== null &&
            product_item.furniture_headboard_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Headboard Width (in)"),
                value: product_item.furniture_headboard_width,
              })
            );
          }
          if (
            product_item.drawer_count !== null &&
            product_item.drawer_count !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Drawer Count"),
                value: product_item.drawer_count,
              })
            );
          }
          if (
            product_item.furniture_arm_color !== null &&
            product_item.furniture_arm_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Arm Color"),
                value: product_item.furniture_arm_color,
              })
            );
          }
          if (
            product_item.furniture_lumbar_support !== null &&
            product_item.furniture_lumbar_support !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Lumbar Support (Yes/No)"),
                value: product_item.furniture_lumbar_support,
              })
            );
          }
          if (
            product_item.furniture_headrest_included !== null &&
            product_item.furniture_headrest_included !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Headrest Included (Yes/No)"),
                value: product_item.furniture_headrest_included,
              })
            );
          }
          if (
            product_item.furniture_frame_color !== null &&
            product_item.furniture_frame_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Frame Color"),
                value: product_item.furniture_frame_color,
              })
            );
          }
          if (
            product_item.furniture_paint_type !== null &&
            product_item.furniture_paint_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Paint Type"),
                value: product_item.furniture_paint_type,
              })
            );
          }
          if (
            product_item.frame_material !== null &&
            product_item.frame_material !== ""
          ) {
            let attrib = product_item.frame_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Frame Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_upholstered !== null &&
            product_item.item_upholstered !== ""
          ) {
            let attrib = product_item.item_upholstered.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Upholstered (Yes/No)"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.letters_numbers !== null &&
            product_item.letters_numbers !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Letters/Numbers"),
                value: product_item.letters_numbers,
              })
            );
          }
          if (
            product_item.item_type !== null &&
            product_item.item_type !== ""
          ) {
            let itemType = product_item.item_type.split(",");
            itemType.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Item Type"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.item_side !== null &&
            product_item.item_side !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Item Side (Left/Right)"),
                value: product_item.item_side,
              })
            );
          }
          if (
            product_item.bulb_type !== null &&
            product_item.bulb_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Type"),
                value: product_item.bulb_type,
              })
            );
          }
          if (
            product_item.bulb_type !== null &&
            product_item.bulb_type !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Bulb Type"),
                value: product_item.bulb_type,
              })
            );
          }
          if (
            product_item.furniture_footrest !== null &&
            product_item.furniture_footrest !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Footrest (Yes/No)"),
                value: product_item.furniture_footrest,
              })
            );
          }
          if (
            product_item.footrest_distance_to_floor !== null &&
            product_item.footrest_distance_to_floor !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Footrest Distance to Seat (in)"),
                value: product_item.footrest_distance_to_floor,
              })
            );
          }
          if (
            product_item.upholstery_fill_material !== null &&
            product_item.upholstery_fill_material !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Upholstery Fill Material"),
                value: product_item.upholstery_fill_material,
              })
            );
          }
          if (
            product_item.upholstery_material !== null &&
            product_item.upholstery_material !== ""
          ) {
            let attrib = product_item.upholstery_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Upholstery Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.upholstery_color !== null &&
            product_item.upholstery_color !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Upholstery Color"),
                value: product_item.upholstery_color,
              })
            );
          }
          if (
            product_item.tufted_upholstery !== null &&
            product_item.tufted_upholstery !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tufted Upholstery"),
                value: product_item.tufted_upholstery,
              })
            );
          }
          if (
            product_item.seat_material !== null &&
            product_item.seat_material !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Seat Material"),
                value: product_item.seat_material,
              })
            );
          }
          if (
            product_item.seat_width !== null &&
            product_item.seat_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Seat Width (in)"),
                value: product_item.seat_width,
              })
            );
          }
          if (
            product_item.seat_height !== null &&
            product_item.seat_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Seat Height (in)"),
                value: product_item.seat_height,
              })
            );
          }
          if (
            product_item.seat_depth !== null &&
            product_item.seat_depth !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Seat Depth (in)"),
                value: product_item.seat_depth,
              })
            );
          }
          if (
            product_item.item_swivel !== null &&
            product_item.item_swivel !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Swivel (Yes/No)"),
                value: product_item.item_swivel,
              })
            );
          }
          if (
            product_item.item_arms !== null &&
            product_item.item_arms !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Arms (Yes/No)"),
                value: product_item.item_arms,
              })
            );
          }
          if (
            product_item.seating_capacity !== null &&
            product_item.seating_capacity !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Seating Capacity"),
                value: product_item.seating_capacity,
              })
            );
          }
          if (
            product_item.furniture_arm_material !== null &&
            product_item.furniture_arm_material !== ""
          ) {
            let attrib = product_item.furniture_arm_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Arm Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.product_has_a_back !== null &&
            product_item.product_has_a_back !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Product has a back (Yes/No)"),
                value: product_item.product_has_a_back,
              })
            );
          }
          if (
            product_item.furniture_back_height !== null &&
            product_item.furniture_back_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Back Height (in)"),
                value: product_item.furniture_back_height,
              })
            );
          }
          if (
            product_item.removable_cushions !== null &&
            product_item.removable_cushions !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Removable Cushions (Yes/No)"),
                value: product_item.removable_cushions,
              })
            );
          }
          if (
            product_item.reversible_cushions !== null &&
            product_item.reversible_cushions !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Reversible Cushions (Yes/No)"),
                value: product_item.reversible_cushions,
              })
            );
          }
          if (
            product_item.seat_material_variety !== null &&
            product_item.seat_material_variety !== ""
          ) {
            let attrib = product_item.seat_material_variety.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Seat Material Variety"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.seat_cushion_fill_material !== null &&
            product_item.seat_cushion_fill_material !== ""
          ) {
            let attrib = product_item.seat_cushion_fill_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Seat Cushion Fill Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.back_cushion_fill_material !== null &&
            product_item.back_cushion_fill_material !== ""
          ) {
            let attrib = product_item.back_cushion_fill_material.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Back Cushion Fill Material"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.cleaning_code !== null &&
            product_item.cleaning_code !== ""
          ) {
            let attrib = product_item.cleaning_code.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Cleaning Code"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.rub_count !== null &&
            product_item.rub_count !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Rub Count"),
                value: product_item.rub_count,
              })
            );
          }
          if (
            product_item.frame_material_variety !== null &&
            product_item.frame_material_variety !== ""
          ) {
            let attrib = product_item.frame_material_variety.split(",");
            attrib.forEach((element) => {
              formData.append(
                "specification",
                JSON.stringify({
                  name: this.attributeIdGetter("Frame Material Variety"),
                  value: element,
                })
              );
            });
          }
          if (
            product_item.recommended_cabinet_size !== null &&
            product_item.recommended_cabinet_size !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Recommended Cabinet Size"),
                value: product_item.recommended_cabinet_size,
              })
            );
          }
          if (
            product_item.minimum_cabinet_opening !== null &&
            product_item.minimum_cabinet_opening !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Minimum Cabinet Opening (in)"),
                value: product_item.minimum_cabinet_opening,
              })
            );
          }
          if (
            product_item.hinge_overlay !== null &&
            product_item.hinge_overlay !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hinge Overlay"),
                value: product_item.hinge_overlay,
              })
            );
          }
          if (
            product_item.hinge_degree_of_opening !== null &&
            product_item.hinge_degree_of_opening !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hinge Degree of Opening"),
                value: product_item.hinge_degree_of_opening,
              })
            );
          }
          if (
            product_item.hinge_crank_amount !== null &&
            product_item.hinge_crank_amount !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Hinge Crank Amount"),
                value: product_item.hinge_crank_amount,
              })
            );
          }
          if (
            product_item.soft_closing !== null &&
            product_item.soft_closing !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Soft Closing (Yes/No)"),
                value: product_item.soft_closing,
              })
            );
          }
          if (
            product_item.self_closing !== null &&
            product_item.self_closing !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Self Closing (Yes/No)"),
                value: product_item.self_closing,
              })
            );
          }
          if (
            product_item.furniture_arm_height !== null &&
            product_item.furniture_arm_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Arm Height (in)"),
                value: product_item.furniture_arm_height,
              })
            );
          }
          if (
            product_item.furniture_arm_width !== null &&
            product_item.furniture_arm_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Arm Width (in)"),
                value: product_item.furniture_arm_width,
              })
            );
          }
          if (
            product_item.furniture_arm_depth !== null &&
            product_item.furniture_arm_depth !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Furniture Arm Depth (in)"),
                value: product_item.furniture_arm_depth,
              })
            );
          }
          if (
            product_item.furniture_leg_glides !== null &&
            product_item.furniture_leg_glides !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Leg Glides (Yes/No)"),
                value: product_item.furniture_leg_glides,
              })
            );
          }
          if (
            product_item.feet_material !== null &&
            product_item.feet_material !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Feet Material"),
                value: product_item.feet_material,
              })
            );
          }
          if (
            product_item.edge_treatment !== null &&
            product_item.edge_treatment !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Edge Treatment"),
                value: product_item.edge_treatment,
              })
            );
          }
          if (
            product_item.shade_variation !== null &&
            product_item.shade_variation !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Shade Variation"),
                value: product_item.shade_variation,
              })
            );
          }
          if (
            product_item.nominal_size !== null &&
            product_item.nominal_size !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Nominal Size"),
                value: product_item.nominal_size,
              })
            );
          }
          if (
            product_item.grout_joint !== null &&
            product_item.grout_joint !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Grout Joint Recommendation (in)"),
                value: product_item.grout_joint,
              })
            );
          }
          if (
            product_item.tile_thickness !== null &&
            product_item.tile_thickness !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Tile Thickness (in)"),
                value: product_item.tile_thickness,
              })
            );
          }
          if (
            product_item.case_length !== null &&
            product_item.case_length !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Case Length (in)"),
                value: product_item.case_length,
              })
            );
          }
          if (
            product_item.case_width !== null &&
            product_item.case_width !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Case Width (in)"),
                value: product_item.case_width,
              })
            );
          }
          if (
            product_item.case_height !== null &&
            product_item.case_height !== ""
          ) {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Case Height (in)"),
                value: product_item.case_height,
              })
            );
          }
          if (product_item.case_upc !== null && product_item.case_upc !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Case UPC"),
                value: product_item.case_upc,
              })
            );
          }
          if (product_item.sf_pc !== null && product_item.sf_pc !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("Square Ft. per Piece"),
                value: product_item.sf_pc,
              })
            );
          }
          if (product_item.lb_pc !== null && product_item.lb_pc !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("LB/PC"),
                value: product_item.lb_pc,
              })
            );
          }
          if (product_item.pc_ct !== null && product_item.pc_ct !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("PC/CT"),
                value: product_item.pc_ct,
              })
            );
          }
          if (product_item.sf_ct !== null && product_item.sf_ct !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("SF/CT"),
                value: product_item.sf_ct,
              })
            );
          }
          if (product_item.lb_ct !== null && product_item.lb_ct !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("LB/CT"),
                value: product_item.lb_ct,
              })
            );
          }
          if (product_item.lf_ct !== null && product_item.lf_ct !== "") {
            formData.append(
              "specification",
              JSON.stringify({
                name: this.attributeIdGetter("LF/CT"),
                value: product_item.lf_ct,
              })
            );
          }
          let shippingObj = {};
          if (
            product_item.use_shipping !== null &&
            product_item.use_shipping !== ""
          ) {
            shippingObj.sundialShipping =
              product_item.use_shipping === "Yes" ? true : false;
          }
          if (
            product_item.number_of_boxes !== null &&
            product_item.number_of_boxes !== ""
          ) {
            shippingObj.numberOfBoxes = product_item.number_of_boxes;
          }
          if (
            product_item.freight_class !== null &&
            product_item.freight_class !== ""
          ) {
            shippingObj.freightClass = product_item.freight_class;
          }
          if (
            product_item.drop_ship_fee !== null &&
            product_item.drop_ship_fee !== ""
          ) {
            shippingObj.dropShippingFee = product_item.drop_ship_fee;
          }
          const concatination = (first, second) => {
            return `${first}${second}`;
          };
          let dimensions = [];
          let dimensionsForBoxId = [];
          for (let index = 1; index < 7; index++) {
            let dimensionObjt = {};
            //   let test = eval("index");
            let packageLenght = eval(
              concatination("product_item.package_length_", index).toString()
            );
            let packageHeight = eval(
              concatination("product_item.package_height_", index).toString()
            );
            let packageWidth = eval(
              concatination("product_item.package_width_", index).toString()
            );
            let packageWeight = eval(
              concatination("product_item.package_weight_", index).toString()
            );

            let packageVolume = eval(
              concatination("product_item.package_volume_", index).toString()
            );

            if (packageLenght !== null && packageLenght !== "") {
              dimensionObjt.length = Number(packageLenght);
            }
            if (packageHeight !== null && packageHeight !== "") {
              dimensionObjt.height = Number(packageHeight);
            }
            if (packageWidth !== null && packageWidth !== "") {
              dimensionObjt.width = Number(packageWidth);
            }
            if (packageWeight !== null && packageWeight !== "") {
              dimensionObjt.weight = Number(packageWeight);
            }
            if (packageVolume !== null && packageVolume !== "") {
              dimensionObjt.volume = Number(packageVolume);
            }

            if (Object.keys(dimensionObjt).length > 0) {
              dimensions.push(dimensionObjt);
              delete dimensionObjt.volume;
              dimensionsForBoxId.push(dimensionObjt);
            }
          }
          shippingObj.dimensions = dimensions;
          let boxId = this.getString(dimensionsForBoxId);

          shippingObj.boxId = boxId;

          formData.append("shipping", JSON.stringify(shippingObj));
          this.setState({ loading: true });
          //           if (this.state.count < 2) {
          //
          try {
            const { mainImage, additionalImages } = this.state;
            mainImage && formData.append("mainImage", mainImage);
            additionalImages &&
              formData.append("additionalImages", additionalImages);

            const response = await this.props.bulkProducts(formData);
            if (response.status !== 200) {
              let errors = "";
              errors = response.data.data;
              let attributeErrors = response.data.attributeErrors;

              if (attributeErrors && attributeErrors.length > 0) {
                for (
                  let attributeName = 0;
                  attributeName < attributeErrors.length;
                  attributeName++
                ) {
                  let name = await this.attributeNameGetter(
                    attributeErrors[attributeName]
                  );
                  errors = errors + " " + name + ", ";
                }
              }

              product_item.issues = errors;
              const result = await this.props.etlErrorLogs(product_item);
              console.log(errors);
            }
            if (response.status === 500) {
              console.log("500", index1);
            }
          } catch (error) {
            // eslint-disable-next-line no-loop-func
            var intervalId = setInterval(() => {
              this.setState({
                productError: [
                  ...this.state.productError,
                  product_item.item_model,
                ],
              });
              this.setState({ count: index1 });
              clearInterval(intervalId);
            }, 20000);
          }
          //           } else {
          //             this.setState({ data: "" });
          //           }
        }
        this.setState({ count: index1 });
        // }, counter * 300);
      }
      // );

      this.setState({ loading: false, buttonChange: "", count: "All" });
    } else {
      toast.error(`Please add both inputs`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }
  getString = (arr) => {
    let sortable;
    let temp = [];

    arr.forEach((item, idx) => {
      const myObj = { ...item };
      delete myObj.weight;

      sortable = Object.entries(myObj)
        .sort(([, a], [, b]) => a - b)
        .reduce((r, [k, v]) => ({ ...r, [k]: Math.ceil(v) }), {});

      sortable = {
        ...sortable,
        weight: Math.ceil(item.weight < 0.01 ? 1 : item.weight),
      };
      temp.push(sortable);
    });

    return this.helperSorter(temp);
  };
  helperSorter(array) {
    let check = array.map((item) => {
      return Object.values(item)
        .map((x) => {
          return x < 1 ? Math.ceil(x) : Math.round(x);
        })
        .join("_");
    });

    return (check = check
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
      .join("-"));
  }

  handleChangeImageCheck = ({ target: { name, value } }) => {
    this.setState((prev) => {
      return { ...prev, [name]: !prev.additionalImages };
    });
  };

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Upload Products{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bulk Products
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Upload Bulk Products
                </h4>

                <Alert />

                <form className="forms-sample p-2">
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="brand">
                      Select Brand
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="brand"
                      onChange={(e) => {
                        this.setState({ brand: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.getAllStores &&
                        this.state.getAllStores.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.fullName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <div className="col-md-4 bulk-position-relative">
                      <div className="bulk-flex">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <input
                                disabled={this.state.count}
                                onChange={() =>
                                  this.setState({
                                    mainImage: !this.state.mainImage,
                                  })
                                }
                                type={"checkbox"}
                                name="mainImage"
                                id="mainImage"
                                className="bulk-checkbox bulk-mr-5"
                              />
                            }
                            label="mainImage"
                            htmlFor="mainImage"
                            className="bulk-bold bulk-mr-5 bulk-capitalize"
                          />
                        </FormGroup>

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <input
                                disabled={this.state.count}
                                onChange={() =>
                                  this.setState({
                                    mainImage: !this.state.mainImage,
                                  })
                                }
                                type={"checkbox"}
                                name="additionalImages"
                                id="additionalImages"
                                className="bulk-checkbox bulk-mr-5"
                              />
                            }
                            label="additionalImages"
                            htmlFor="additionalImages"
                            className="bulk-bold bulk-mr-5 bulk-capitalize"
                          />
                        </FormGroup>

                        {/* Custom CheckBoxes Comment */}
                        {/* Main Image */}
                        {/* className={`btn btn-primary mr-2 ${this.state.buttonChange}`} */}
                        {/* <div className="bulk-mainImage">
                          <input
                            disabled={this.state.count}
                            onChange={() =>
                              this.setState({
                                mainImage: !this.state.mainImage,
                              })
                            }
                            type={"checkbox"}
                            name="mainImage"
                            id="mainImage"
                            className="bulk-checkbox "
                          />
                          <label
                            htmlFor="mainImage"
                            className="bulk-bold bulk-mr-5 bulk-capitalize bulk-bold"
                          >
                            mainImage
                          </label>
                        </div> */}
                        {/* additionalImage */}
                        {/* <div className="bulk-mainImage bulk-ml-10">
                          <input
                            disabled={this.state.count}
                            value={this.state.additionalImages}
                            onChange={() =>
                              this.setState({
                                additionalImages: !this.state.additionalImages,
                              })
                            }
                            type={"checkbox"}
                            name="additionalImages"
                            id="additionalImages"
                            className="bulk-checkbox"
                          />
                          <label
                            htmlFor="additionalImages"
                            className="bulk-bold bulk-mr-5 bulk-capitalize"
                          >
                            additionalImages
                          </label>
                        </div> */}
                      </div>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <label style={{ fontWeight: "bold" }}>
                        CSV File upload
                      </label>
                    </div>

                    <div className="input-group col-md-8 col-xs-12">
                      {/*  <div className="input-group col-xs-12">
                           <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                            </div>*/}
                      <DragDropFile handleFile={this.handleFile}>
                        <div className="row">
                          <div className="col-xs-12">
                            <DataInput handleFile={this.handleFile} />
                          </div>
                        </div>
                      </DragDropFile>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {this.state.loading && this.state.loading === true ? (
                        <div className="text-center">
                          <ProgressBar
                            animated
                            now={this.state.count}
                            max={this.state.data.length}
                          />
                          <h5>
                            you have added {` ${this.state.count} `} products
                            proceeded
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className={`btn btn-primary mr-2 ${this.state.buttonChange}`}
                  >
                    Submit
                  </button>
                </form>
                {this.state.attributeNotFound &&
                  this.state.attributeNotFound.map((item, indx) => (
                    <p key={indx}>{item} attribute not found</p>
                  ))}
                {this.state.productError &&
                  this.state.productError.map((item, indx) => (
                    <p key={indx}>
                      <b>{item}</b> Product has been failed
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div className="form-inline">
        <div className="form-group" style={{ marginLeft: "12px" }}>
          <label style={{ fontWeight: "bold" }} htmlFor="file">
            {" "}
          </label>
          <input
            type="file"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
// childCategories.propTypes = {
//   childCategories: PropTypes.func.isRequired,
// };
subCategoryNames.prototype = {
  subCategoryNames: PropTypes.func.isRequired,
};
// mainCategories.prototype = {
//   mainCategories: PropTypes.func.isRequired,
// };
childSubCategoryNames.prototype = {
  childSubCategoryNames: PropTypes.func.isRequired,
};
bulkProducts.prototype = {
  bulkProducts: PropTypes.func.isRequired,
};
bulkProductsUpload.propTypes = {
  bulkProductsUpload: PropTypes.func.isRequired,
};
getAllStores.propTypes = {
  getAllStores: PropTypes.func.isRequired,
};
getAllAttribute.propTypes = {
  getAllAttribute: PropTypes.func.isRequired,
};
etlErrorLogs.propTypes = {
  etlErrorLogs: PropTypes.func.isRequired,
};

export default connect(null, {
  subCategoryNames,
  getAllAttribute,
  childSubCategoryNames,
  bulkProducts,
  getAllStores,
  bulkProductsUpload,
  etlErrorLogs,
})(BulkProducts);
