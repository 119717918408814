import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../components/alert/alert.component";

import { create } from "../../../actions/banner";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bannerUrl: "",
      bannerUrl1: "",
      images: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { bannerUrl, bannerUrl1, images } = this.state;

    let formData = new FormData();

    formData.append("bannerUrl", bannerUrl);
    formData.append("bannerUrl1", bannerUrl1);

    for (var i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    this.setState({ loading: true });

    const res = await this.props.create(formData);

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      toast(`${res}`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let file = Array.from(e.target.files);

    file.forEach((element, index) => {
      let reader = new FileReader();
      let f = e.target.files[index];

      reader.onloadend = () => {
        this.setState({ images: [...this.state.images, f] });
      };

      reader.readAsDataURL(f);
    });
  }

  render() {
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <Sidebar show="banner" />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title"> Create Banners </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin/banners">Banners</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create Banners
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Add a new Banner</h4>

                      <Alert />

                      <form className="forms-sample">
                        <div className="form-group">
                          <label htmlFor="exampleInputName1">
                            Banner One Url
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="url"
                            placeholder="Url"
                            name="bannerUrl"
                            value={this.state.bannerUrl}
                            onChange={(e) =>
                              this.setState({ bannerUrl: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputName1">
                            Banner Two Url
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="url"
                            placeholder="Url"
                            name="bannerUrl"
                            value={this.state.bannerUrl1}
                            onChange={(e) =>
                              this.setState({ bannerUrl1: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <label>Images</label>

                          <div className="input-group col-xs-12">
                            <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            this._submitHandler();
                          }}
                          type="button"
                          className="btn btn-primary mr-2"
                        >
                          Submit
                        </button>
                        <Link to={"/admin/banners"}>
                        <button
                          className="btn btn-light"

                        >
                          Cancel
                        </button>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
