import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  deleteAttribute,
  retreiveAllGroupAttributes,
  retreiveSearchAttributes,
} from "../../../../actions/product";
import { Pagination } from "@mui/material";
import EditTabelDrawer from "../../../../components/Edit-tabel-drawer/EditTabelDrawer";
import AddNewAttributes from "./components/AddNewAttributes";
import UpdateAttributes from "./components/UpdateAttributes";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";

const Attributes = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [groupAttributes, setgroupAttributes] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });
  const [SelectItem, setSelectItem] = useState({});
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  // Api calls for get Order
  const getCategories = useCallback(
    async (pageNo, search) => {
      const res = await dispatch(retreiveSearchAttributes(pageNo, search));
      console.log(res);
      setData(res?.attributes);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  // Get attribut group
  const getAllAttributes = useCallback(async () => {
    const res = await dispatch(retreiveAllGroupAttributes());
    setgroupAttributes(res?.attributeGroupes);

    setloading(false);
  }, [dispatch]);
  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getCategories(pagination.currentPage, SearchFillter.SearchQuery);
        getAllAttributes();
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    Edit.open,
    isMount,
    getCategories,
    getAllAttributes,
  ]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/attributes",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const hanldeDeleteConfirm = async (id) => {
    setloading(true);
    const res = await dispatch(deleteAttribute(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };

  // hanlde add new Attributes
  const handleAddNew = () => {
    setEdit({
      open: true,
      edit: "AddNew",
      title: "Add New Attributes",
    });
    setSelectItem({});
  };
  // handle update attribute open
  const handleUpdate = (item) => {
    setEdit({
      open: true,
      edit: "update",
      title: "update Attributes",
    });
    setSelectItem({ ...item });
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex  justify-content-end align-center">
            <CustomeTableBtn
              className={"mx-2 py-3 px-5"}
              color="secondary"
              width={"100px"}
              onClick={() => handleAddNew()}
            >
              Add New
            </CustomeTableBtn>
            <SearchFilter
              type="text"
              placeholder={"Search Attributes"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Attributes"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Sn#</th>
                      <th>Name</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td className="order-item-number">{i + 1}</td>
                            <td>{item.name}</td>

                            <td>
                              <CustomeTableBtn
                                color="secondary"
                                width={"100px"}
                                className={"mr-2"}
                                onClick={() => handleUpdate(item)}
                              >
                                Edit
                              </CustomeTableBtn>

                              <CustomeTableBtn
                                color="danger"
                                width={"100px"}
                                onClick={() =>
                                  setdeleteConfirm({
                                    open: true,
                                    id: item?._id,
                                  })
                                }
                              >
                                Delete
                              </CustomeTableBtn>

                              {deleteConfirm.open && (
                                <SweetAlert
                                  warning
                                  showCancel
                                  confirmBtnText="Yes, delete it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={(e) => {
                                    hanldeDeleteConfirm(deleteConfirm.id);
                                  }}
                                  onCancel={() =>
                                    setdeleteConfirm({
                                      open: false,
                                      id: null,
                                    })
                                  }
                                  focusCancelBtn
                                >
                                  Are you sure you want to delete?
                                </SweetAlert>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <EditTabelDrawer
                  Edit={Edit}
                  setEdit={setEdit}
                  shippingId={SelectItem?.name}
                >
                  {Edit.edit === "AddNew" && (
                    <AddNewAttributes
                      groupAttributes={groupAttributes}
                      setEdit={setEdit}
                    />
                  )}
                  {Edit.edit === "update" && (
                    <UpdateAttributes
                      groupAttributes={groupAttributes}
                      SelectItem={SelectItem}
                      setEdit={setEdit}
                    />
                  )}

                  <hr className=" mb-0" />
                </EditTabelDrawer>
                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {Data?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default Attributes;
