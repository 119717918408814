import React from "react";

const CutomeDatePicker = ({ label, value, onChange }) => {
  return (
    <div
      className=" text-dark c-pointer d-flex-y justify-content-between  "
      htmlFor={"pick-date-tracking"}
    >
      <label htmlFor="pick-date-tracking">{label ?? ""}</label>
      <input
        type="date"
        required
        id="pick-date-tracking"
        value={value}
        onChange={onChange}
        className="ml-2"
        style={{ border: "none", background: "transparent" }}
      />
    </div>
  );
};

export default CutomeDatePicker;
