import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiURL } from "../../../config/default";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import TopBarProgress from "react-topbar-progress-indicator";
import TreeHeader from "./TreeHeader";

function Index() {
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${apiURL}header/header`);

        if (res.data.Header.header) setselectedCategory(res.data.Header.header);

        await getsecondLevelCategory();
      } catch (error) {
        toast.error("Something Went wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setloading(false);
      }
    })();
  }, []);

  const [loading, setloading] = useState(true);
  const [secondLevelCategories, setsecondLevelCategories] = useState([]);
  const [selectedCategory, setselectedCategory] = useState([
    {
      name: "",
      slug: "",
      haveSubMenu: false,
      showInHeader: true,
      showInCategory: true,
      children: [],
      isCustomLink: false,
      link: {
        link: "",
        color: "",
        fontWeight: "",
        showArrow: "",
        target: "_self",
      },
    },
  ]);

  const getsecondLevelCategory = async () => {
    const { data: header } = await axios.get(
      `${apiURL}get-categories-header-children`
    );

    setsecondLevelCategories(header);
  };

  const validator = (megaMenu) => {
    let isValid = true;

    const traverse = (megaMenu) => {
      megaMenu.forEach((menu) => {
        // eslint-disable-next-line no-unused-vars
        const { name, slug, children } = menu;
        if (!name) isValid = false;
        else if (children && children.length > 0) traverse(children);
      });
    };

    traverse(megaMenu);

    return isValid;
  };

  // const scriptpopulate = (megaMenu) => {
  //   const newMenu = [...megaMenu];

  //   const traverse = (newMenu) => {
  //     newMenu.forEach((menu) => {
  //       menu.showInCategory = true;
  //       if (menu.children && menu.children.length > 0) traverse(menu.children);
  //     });
  //   };

  //   traverse(newMenu);

  //   return newMenu;
  // };

  const handleSubmit = async (megaMenu) => {
    const isValid = validator(megaMenu);
    if (!isValid)
      toast.error("Data is Not Valid, Please make sure no field is Empty", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else
      try {
        setloading(true);
        await axios.post(`${apiURL}header/header`, megaMenu);
        toast.success("Mega Menu Updated successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        toast.error("Something went Wrong, please try again", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setloading(false);
      }
  };
  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}

      <ToastContainer />

      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          {" "}
          Customize Mega Menu{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              mega menu
            </li>
          </ol>
        </nav>
      </div>
      <TreeHeader
        treeData={selectedCategory}
        loading={loading}
        secondLevelCategories={secondLevelCategories}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default Index;
