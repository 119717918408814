import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import TopBarProgress from "react-topbar-progress-indicator";
import { DeleteComapny, getAllCompany } from "../../../../actions/shipping";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Pagination } from "@mui/material";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";

const ListsCompanies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [loading, setloading] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [IsMount, setIsMount] = useState(false);
  const [ShippingComapny, setShippingComapny] = useState([]);

  // pagnination logic
  const handleChange = (e, value) => {
    setpagination({ ...pagination, currentPage: value });
  };

  const getAllComapnyList = useCallback(async () => {
    setloading(true);
    const res = await dispatch(getAllCompany());
    if (res) {
      setShippingComapny(res);
      setloading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getAllComapnyList();
    return () => {};
  }, [getAllComapnyList, IsMount]);

  const handleDelete = async (id) => {
    const res = await dispatch(DeleteComapny(id));
    if (res) {
      setIsMount(!IsMount);
    }
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Companies Lists"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Link</th>
                      <th>Special Instructions</th>
                      <th>Enable/Disable</th>
                      <th>Sundial Partner</th>
                      <th>Sundial Partner</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ShippingComapny?.length ? (
                      <>
                        {ShippingComapny?.map((item) => (
                          <>
                            <tr key={item._id}>
                              <td>{item?.name}</td>
                              <td>
                                <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  {item.link}
                                </a>
                              </td>
                              <td>{item.instructions}</td>
                              <td>{item.enable ? "Enabled" : "Disabled"}</td>
                              <td>{item.partner ? "Yes" : "No"}</td>
                              <td>{item.show ? "Show" : "Hide"}</td>

                              <td className="d-flex justify-content-center ">
                                <FaRegEdit
                                  className=" h3 mx-2 c-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/admin/shipping/edit-shipping-company/${item._id}`
                                    )
                                  }
                                />
                                <MdDelete
                                  className="h3 text-danger c-pointer"
                                  onClick={() => handleDelete(item._id)}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={7} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {ShippingComapny?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ListsCompanies;
