import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import React from "react";

const TableLoader = ({ rowsNum, cellNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(cellNum)].map((cell, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton animation="wave" variant="text" height={30} />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default TableLoader;
