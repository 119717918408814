import React, { useState } from 'react';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';

function Tag({ tag, handleMove, index, handleEdit }) {
  const [draging, setdraging] = useState(false);
  const [controlledTag, setcontrolledTag] = useState({ ...tag });
  const [openOnClick, setopenOnClick] = useState(null);

  function mouseDown(e) {
    setdraging(true);
    setopenOnClick(Date.now());

    e.preventDefault();
  }

  function mouseUp(e) {
    setdraging(false);
    handleMove(controlledTag, index);
  }

  function mouseMove(e) {
    if (!draging) return;

    var element = document.getElementById('tagable-image');
    var clientRect = element.getBoundingClientRect();

    let x = e.clientX - clientRect.left; //x position within the element.
    let y = e.clientY - clientRect.top; //y position within the element.

    let xPercentage = (x / clientRect.width) * 100 - 2;
    let yPercentage = (y / clientRect.height) * 100 - 2;

    if (xPercentage > 90) xPercentage = 90;

    if (xPercentage < 2) xPercentage = 2;

    if (yPercentage > 91) yPercentage = 91;

    if (yPercentage < 2) yPercentage = 2;

    setcontrolledTag({ ...controlledTag, x: xPercentage, y: yPercentage });
  }

  return (
    <div
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseMove={mouseMove}
      style={
        draging
          ? { position: 'absolute', top: '0', width: '100%', height: '100%' }
          : { width: '100%', height: '100%' }
      }
    >
      <div
        style={{
          position: 'absolute',
          left: `${controlledTag.x}%`,
          top: `${controlledTag.y}%`,
          backgroundColor: 'rgba(33,30,34,.2)',
          borderRadius: '50%',
          padding: '3px 6px 6px 4px',
        }}
      >
        <LocalOfferSharpIcon
          title="tag"
          style={{
            width: 20,
            height: 20,
            transform: 'rotate(90deg)',
            color: 'white',
          }}
          onClick={(e) => {
            if (openOnClick && Date.now() - openOnClick < 0.3 * 1000)
              handleEdit(tag, index);
          }}
        />
      </div>
    </div>
  );
}

export default Tag;
