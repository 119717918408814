import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const ClarificationBtn = ({ status, value, onClick }) => {
  return (
    <div
      value={value}
      onClick={() => onClick()}
      className={` d-flex j border-orange border-radius-10 p-2  c-pointer   gap-25 ${
        status ? "bg-orange text-white" : "bg-light-orange text-orange "
      } `}
    >
      <span className=" align-self-start">
        {status && (
          <IoCheckmarkCircleOutline size={25} className=" font-weight-bold" />
        )}
      </span>
      <span className=" mx-auto">
        {status ? "Need Calrification" : "Get Clarifications"}
      </span>
    </div>
  );
};

export default ClarificationBtn;
