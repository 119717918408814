import React from "react";

const FormatCard = (props) => {
    const { TilteText, handleChange } = props;

    return (
        <div className="card  cardRaduis">
            <div className="card-header card-title">Title Format remommended max character Count:150</div>
            <div className="card-body">
                <textarea rows="5" className="textArea" value={TilteText} onChange={(e) => handleChange(e.target.value)} />
            </div>
        </div>
    );
};

export default FormatCard;
