import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteUser, usersRetreive } from "../../../actions/user";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";

const Users = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  // Api calls for get Order
  const getData = useCallback(
    async (pageNo, search) => {
      const res = await dispatch(usersRetreive(pageNo, search));
      setData(res?.users);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getData(pagination.currentPage, SearchFillter?.SearchQuery);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date, pagination.currentPage, SearchFillter?.SearchQuery, isMount]);
  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/users",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const deleteData = async (id) => {
    setloading(true);
    const res = await dispatch(deleteUser(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex justify-content-end align-center">
            <SearchFilter
              type="text"
              placeholder={"Search User ..."}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
          </div>

          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Users"
              subTitle="users"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Sn#</th>
                      <th>Name</th>
                      <th> Email</th>
                      <th>No. of Orders</th>
                      <th>Joined At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/user-details/${item._id}`)
                              }
                            >
                              {i + 1}
                            </td>
                            <td> {item.fullName} </td>
                            <td>
                              {" "}
                              <a href={`mailto:${item.email}`} target="_top">
                                {item.email}
                              </a>
                            </td>

                            <td>{item?.numberOfOrders}</td>

                            <td>{moment(item?.createdAt).format("l")}</td>
                            <td>
                              <CustomeTableBtn
                                color="secondary"
                                width={"100px"}
                                className={"mr-2"}
                                onClick={() =>
                                  navigate(`/admin/user-details/${item._id}`)
                                }
                              >
                                Edit
                              </CustomeTableBtn>
                              {item.numberOfOrders > 0 ? (
                                <></>
                              ) : (
                                <CustomeTableBtn
                                  color="danger"
                                  width={"100px"}
                                  onClick={() =>
                                    setdeleteConfirm({
                                      open: true,
                                      id: item?._id,
                                    })
                                  }
                                >
                                  Delete
                                </CustomeTableBtn>
                              )}

                              {deleteConfirm.open && (
                                <SweetAlert
                                  warning
                                  showCancel
                                  confirmBtnText="Yes, delete it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={(e) => {
                                    deleteData(deleteConfirm.id);
                                  }}
                                  onCancel={() =>
                                    setdeleteConfirm({
                                      open: false,
                                      id: null,
                                    })
                                  }
                                  focusCancelBtn
                                >
                                  Are you sure you want to delete?
                                </SweetAlert>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {Data?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default Users;
