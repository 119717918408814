/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";
import { CAREATE_ATTRIBUTE, CAREATE_ATTRIBUTE_GROUP } from "./types";

export const create = (formdata) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}create-product`, formdata);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}product-by-id/${id}`, _config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const edit = (formdata) => async (dispatch) => {
  console.log("formatData", formdata);
  const _config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/edit-product`, formdata);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const products =
  (
    currentPage,
    title,
    modelNumber,
    sku,
    price,
    filter,
    status,
    brand,
    categoryId
  ) =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `${apiURL}admin/products?page=${
          currentPage ? currentPage : 1
        }&title=${title}&modelNumber=${modelNumber}&sku=${sku}&price=${price}&filter=${filter}&status=${status}&brand=${brand}&categoryId=${
          categoryId ? categoryId : ""
        }`
      );
      return res.data;
    } catch (err) {
      const errors = err.response.data.error;
      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
        });
      }
    }
  };

export const deleteProduct = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${apiURL}delete-product/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const bulkProducts = (data) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "multipart/form-data; boundary=" + data._boundary,
      // "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await axios.post(`${apiURL}bulk-product`, data, _config);

    return res;
  } catch (err) {
    console.log(err);
  }
};
export const bulkProductsUpload = (data) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const _data = JSON.stringify(data);

  try {
    const res = await axios.post(`${apiURL}upload-xlsx`, data, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", {
            marginTop: "3em",
            // marginBottom: "3em",
            width: "25%",
            marginBottom: "1%",
          })
        );
      });
    }
  }
};
export const discountProducts = (e) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/product-discount`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};
export const vendorProducts = (brand, pageNum) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${apiURL}seller-products/${brand}?page=${pageNum ? pageNum : 1}`,
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const getAttribute = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/attribute`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      console.log(errors);
    }
  }
};
export const getAllAttribute = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/get-attribute`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      console.log(errors);
    }
  }
};

export const getWeightClass = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/weight-class`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getLengthClass = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/length-class`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const createProductDiscount = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/product-discount`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const createHPM = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/configuration`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const updateHPM = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.put(
      `${apiURL}admin/configuration`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getHPM = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/configuration`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const hpmRetreive = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/configuration/${id}`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const deleteHPM = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(
      `${apiURL}admin/configuration/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const retreiveSearchAttributes = (page, query) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiURL}admin/attribute?page=${page ? page : 1}&search=${
        query ? query : ""
      }`
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreiveAllAttributes = (page) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${apiURL}admin/attribute?page=${page}`,
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreiveAllGroupAttributes = () => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/attribute-group`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const updateNewAttributes = (attribute, id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.put(`${apiURL}admin/attribute/${id}`, {
      ...attribute,
      isRange: false,
    });

    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};

export const createNewAttributes = (attribute) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(`${apiURL}admin/attribute`, { ...attribute });
    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};

export const deleteAttribute = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(`${apiURL}admin/attribute/${id}`);
    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};

export const createNewGroupAttribute = (attribute) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(`${apiURL}admin/attribute-group`, {
      ...attribute,
    });
    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};
export const updateGroupAttribute = (attribute, id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.put(`${apiURL}admin/attribute-group/${id}`, {
      ...attribute,
    });

    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};

export const deleteGroupAttribute = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(
      `${apiURL}admin/attribute-group/${id}`,
      _config
    );
    return res.data;
  } catch (err) {
    dispatch(
      alert("Something went wrong, try refreshing", "danger", {
        marginTop: "2em",
      })
    );
  }
};
export const etlErrorLogs = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${apiURL}admin/etl-error`, data, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};
