import React from 'react';
import { Link } from 'react-router-dom';
import FilterRow from './FilterRow';

// Redux
import 'react-toastify/dist/ReactToastify.css';

function CreateBrandFilter() {
  return (
    <div className="container-scroller">
      <div className="page-header">
        <h3 className="page-title"> Create Filter </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create Filter
            </li>
          </ol>
        </nav>
      </div>
      <FilterRow />
    </div>
  );
}

export default CreateBrandFilter;
