export const setButtonBackgroundColor = (item) => {
  switch (item) {
    case "Pending Crawl":
      return "dark-purpal";

    case "Matched":
      return "primary";

    case "Unmatched":
      return "danger";

    default:
      return "default";
  }
};

export const changeDataFormat = (date) => {
  const crawlerDateTime = `${date}`;
  const dateObj = new Date(crawlerDateTime);

  const options = { month: "short", day: "2-digit", year: "numeric" };
  const formattedDateTime = dateObj.toLocaleDateString("en-US", options);
  return formattedDateTime;
};
