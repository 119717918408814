import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getChildSubCategories } from "../../../services/categories/child-subcategories";
import makeAnimated from "react-select/animated";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/childSubCategory";
import { getFilterGrouping } from "../../../actions/filters";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";

import { Modal, Button } from "react-bootstrap";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import ImageUploader from "react-images-upload";
// eslint-disable-next-line no-unused-vars
const animatedComponents = makeAnimated();

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      slug: "",
      displayName: "",
      childSubCategoriesId: null,
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      subCategories: [],
      show: true,
      filterOptions: [],
      image: "",
      showModal: false,
      serverSideFilterOptions: [],
      banner: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const res1 = await this.props.getFilterGrouping();
    const filteredData = [];
    // eslint-disable-next-line array-callback-return
    res1.data.map((item) => {
      filteredData.push({
        value: item._id,
        label: item.title,
        isFixed: item.status,
      });
    });
    this.setState({ serverSideFilterOptions: filteredData });

    const res = await getChildSubCategories();

    this.setState({ subCategories: res?.data });
    if (this._isMounted) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      name,
      slug,
      displayName,
      image,
      childSubCategoriesId,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      description,
      filterOptions,
      show,
      banner,
    } = this.state;
    const filterOptionData = [];
    filterOptions.forEach((element) => {
      filterOptionData.push(element.value);
    });
    if (
      name.length > 1 &&
      displayName.length > 1 &&
      slug.length > 1 &&
      childSubCategoriesId
    ) {
      let sendData = {
        name: name,
        slug: slug,
        displayName,
        categoryId: childSubCategoriesId,
        image: image,
        metaTag: JSON.stringify(metaTag),
        metaDescription: metaDescription,
        metaKeyword: JSON.stringify(metaKeyword),
        seoKeyword: JSON.stringify(seoKeyword),
        description: description,
        show: show,
        banner,
      };
      // filterOptionData.length > 0 &&
      //   filterOptionData.forEach((element) => {
      //     formData.append("filter", element);
      //   });
      this.setState({ loading: true });

      const res = await this.props.create(sendData);

      this.setState({ loading: false });

      if (res) {
        this.setState({
          loading: false,
          name: "",
          slug: "",
          displayName: "",
          mainCategoryId: "",
          description: "",
          metaTag: [],
          metaDescription: "",
          metaKeyword: [],
          seoKeyword: [],
          filterOptions: [],
          show: true,
          banner: "",
        });
        toast(`${res.data}`, { containerId: "B" });
      }
    } else {
      toast.error(`Fill all the fields`, { containerId: "B" });
    }
  };
  _handleImageChange = async (e) => {
    // e.preventDefault();

    const base64Image = await this.toBase64(e[0]);

    this.setState({
      image: base64Image,
    });
  };

  _handleImageBannerChange = async (e) => {
    // e.preventDefault();

    const base64Image = await this.toBase64(e[0]);
    this.setState({
      banner: base64Image,
      isBannerBase64: true,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  open = () => this.setState({ showModal: true });

  handleClose = () => this.setState({ showModal: false });

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      filterOptions: arrayMove(this.state.filterOptions, oldIndex, newIndex),
    });
  };

  getCategoriesTree = () => {
    return (
      Array.isArray(this.state.subCategories) &&
      this.state.subCategories?.map((item, index) => (
        <option key={item?._id ?? index} value={item._id}>
          {`${item?.category?.maincategory?.name ?? ""} >  ${
            item?.category?.name ?? ""
          } > ${item?.name ?? ""}`}
        </option>
      ))
    );
  };
  render() {
    const {
      name,
      slug,
      displayName,
      metaDescription,
      description,
    } = this.state;
    // const colourOptions = [];
    // childSubCategories.forEach((element) => {
    //   var obj = {};
    //   obj.value = element._id;
    //   obj.label = element.name;
    //   obj.color = "#00B8d9";
    //   obj.isFixed = true;
    //   colourOptions.push(obj);
    // });
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    const SortableItem = SortableElement(({ item }) => (
      <div className="__item">
        <div className="__inner-item">
          <p>{item.label}</p>
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div className="__container">
        {items.map((item, index) => (
          <SortableItem key={`${item.value}`} index={index} item={item} />
        ))}
      </div>
    ));

    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Create Sub Child Category{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/child-categories">Sub Child Categories</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Sub Child Category
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Add a new sub child category
                </h4>

                <Alert />

                <form className="forms-sample m-3">
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Name">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Name">
                      Display Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="displayName"
                      value={displayName}
                      onChange={(e) =>
                        this.setState({ displayName: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Slug">
                      Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      placeholder="Slug"
                      name="slug"
                      value={slug}
                      onChange={(e) => this.setState({ slug: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="category">
                      Select parent category
                    </label>
                    <select
                      className="form-control form-control-sm js-example-basic-single"
                      id="category"
                      onChange={(e) => {
                        this.setState({
                          childSubCategoriesId: e.target.value,
                        });
                      }}
                    >
                      <option>choose</option>
                      {this.getCategoriesTree()}
                    </select>
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>Image upload</label>

                    <div className="input-group col-xs-12">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={(e) => this._handleImageChange(e)}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                        withPreview={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>Banner upload</label>

                    <div className="input-group col-xs-12">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={(e) => this._handleImageBannerChange(e)}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                        withPreview={true}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Meta Tags">
                      Meta Tags
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaTag: data,
                            })
                          : this.setState({
                              metaTag: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="Meta Keywords"
                    >
                      Meta Keywords
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaKeyword: data,
                            })
                          : this.setState({
                              metaKeyword: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="meta description"
                    >
                      Meta Description
                    </label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      value={metaDescription}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="seo keywords"
                    >
                      Seo Keywords
                    </label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              seoKeyword: data,
                            })
                          : this.setState({
                              seoKeyword: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="description">
                      Seo Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={6}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label
                      style={{ fontWeight: "bold" }}
                      className="col-sm-3 col-form-label"
                    >
                      Show in Header
                    </label>
                    <div className="col-sm-12">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="1"
                            defaultValue
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                show: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="membershipRadios2"
                            defaultValue="0"
                            onChange={() => {
                              this.setState({
                                show: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/child-categories"}>
                  <button
                    className="btn btn-light"
                    type="button"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Sort</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SortableList
              items={this.state.filterOptions}
              onSortEnd={this.onSortEnd}
              axis="xy"
              helperClass="__SortableHelper"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create, getFilterGrouping })(Create);
