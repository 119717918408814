/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import TableLoader from "../../../../components/TableSkelton/TableLoader";
import PriceCardDrawer from "../../../../components/price-card/price-card-components/price-card-drawer";
import PriceCard from "../../../../components/price-card/price-card";
import CreateClone from "./components/create-clone/create-clone";
import SearchDateFilter from "../../../../components/CustomeFilter/SearchDateFilter";
import axios from "axios";
import { apiURL } from "../../../../config/default";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { getStores } from "../../../../actions/store";
import { useDispatch } from "react-redux";
import SwitchButton from "./components/switch-button/switch-button";

const DiscountCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [pagePagination, setPagination] = useState({
    currentPage: 1,
  });

  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [SearchFillter, setSearchFillter] = useState({
    discountId: "",
    isTail: "",
    productMatchingStatus: "",
    hasParent: "",
    discountType: "",
    isClone: "",
    batchDate: "",
    brandId: "",
    recentRecord: "",
    batchStatus: "active",
  });

  // handle for Create Clone
  const handleCreateClone = (itemId) => {
    setEdit({
      open: true,
      edit: "Clone",
      title: "Create Clone",
    });
  };

  useEffect(() => {
    const fetchAllStore = async () => {
      try {
        const res = await dispatch(getStores());

        if (res) {
          const brand = res?.data?.data?.map((item) => {
            return {
              id: item?._id,
              value: item?.fullName,
              label: item?.fullName,
            };
          });
          setBrand(brand);
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };
    fetchAllStore();
  }, []);

  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/discount-code",
      search: `?${createSearchParams({
        page: value,
      })}`,
    };
    navigate(options, { replace: true });
    setPagination({ ...pagePagination, currentPage: value });
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiURL}admin/discount-id-crawlers?productMatchingStatus=${SearchFillter.productMatchingStatus}&discountId=${SearchFillter.discountId}&validTail=${SearchFillter.isTail}&discountType=${SearchFillter.discountType}&batchDate=${SearchFillter.batchDate}&brand=${SearchFillter.brandId}&page=${pagePagination?.currentPage}&status=${SearchFillter.batchStatus}`
      );
      const { data, pagination } = response.data;

      setDiscountData(data);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      clearTimeout(timer);
      timer = setTimeout(fetchProducts, 1000);
    };

    debounceSearch();
    return () => clearTimeout(timer);
  }, [
    SearchFillter.discountId,
    SearchFillter.productMatchingStatus,
    SearchFillter.isTail,
    SearchFillter.batchDate,
    SearchFillter.discountType,
    SearchFillter.brandId,
    pagePagination.currentPage,
    SearchFillter.batchStatus,
  ]);

  const detectCurrencySymbol = (prefix) => {
    if (prefix.includes("%")) {
      return "%";
    }
    const regex = /\$|SAVE|LOW PRICE/i;
    let hasDollarSign = regex.test(prefix);
    if (hasDollarSign) {
      return "$";
    }
    return "%";
  };

  function extractDiscountValue(discountId) {
    const parts = discountId.split("_");
    const lastPart = parts[parts.length - 1];

    const numberMatch = lastPart.match(/\d+/);
    if (numberMatch) {
      return numberMatch[0];
    } else {
      const keywords = ["OFF", "SALE", "SAVE", "$", "LOW PRICE"];
      for (const keyword of keywords) {
        if (lastPart.includes(keyword)) {
          const numberInString = discountId.match(/\d+/);
          if (numberInString) {
            return numberInString[0];
          }
        }
      }
    }
    return "";
  }

  const checkBoxIsChecked = (e) => {
    const isChecked = e.target.checked;
    setSearchFillter((prev) => ({
      ...prev,
      batchStatus: isChecked ? "archived" : "active",
    }));
    if (isChecked) {
      handleChange("", 1);
    }
  };

  const displayBatchStatus = (status) => {
    if (status === "active") {
      return (
        <span className="font-weight-bold" style={{ color: "#24AD62" }}>
          Active
        </span>
      );
    }
    return (
      <span className="text-red font-weight-bold" style={{ color: "#FB4757" }}>
        Archived
      </span>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-end align-center gap-8 flex-wrap">
        <SearchFilter
          type="text"
          placeholder={"Search Discount ID ..."}
          value={SearchFillter?.discountId}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              discountId: e.target.value,
            })
          }
        />
        <SelectSearchFilter
          title={"Valid Tail"}
          width="225px"
          placeholder="Select"
          value={
            `${SearchFillter?.isTail}`.charAt(0).toUpperCase() +
            `${SearchFillter?.isTail}`.slice(1)
          }
          options={[
            { value: "True", label: "True" },
            { value: "False", label: "False" },
          ]}
          onChange={(value) => {
            if (value === "") {
              setSearchFillter({ ...SearchFillter, isTail: "" });
            } else {
              const newValue = value === "True";
              setSearchFillter({
                ...SearchFillter,
                isTail: newValue,
              });
            }
          }}
        />
        <SelectSearchFilter
          title={"Product Matching Status"}
          width="225px"
          placeholder="Select"
          value={SearchFillter?.productMatchingStatus}
          options={[
            { value: "Matched", label: "Matched" },
            { value: "Unmatched", label: "Unmatched" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              productMatchingStatus: value,
            })
          }
        />
        <SelectSearchFilter
          title={"Brand"}
          placeholder="Select"
          width={"200px"}
          value={
            SearchFillter.brandId
              ? brand.find((item) => item?.id === SearchFillter?.brandId).value
              : ""
          }
          options={[
            // { value: "", label: "All" },
            ...brand,
          ]}
          onChange={(value) => {
            if (value === "") {
              setSearchFillter({
                ...SearchFillter,
                brandId: "",
              });
            } else {
              const selectedBrand = brand.find((item) => item.value === value);
              setSearchFillter({
                ...SearchFillter,
                brandId: selectedBrand ? selectedBrand.id : "",
              });
            }
          }}
        />
        <SelectSearchFilter
          title={"Discount type"}
          // style={{
          //   padding: ".7rem 0",
          // }}
          width="225px"
          placeholder="Select"
          value={SearchFillter?.discountType}
          options={[
            { value: "$", label: "$" },
            { value: "%", label: "%" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              discountType: value,
            })
          }
        />
        <SelectSearchFilter
          title={"Is Clone"}
          // style={{
          //   padding: ".7rem 0",
          // }}
          width="225px"
          placeholder="Select"
          value={SearchFillter?.isClone}
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          onChange={(value) =>
            setSearchFillter({
              ...SearchFillter,
              isClone: value,
            })
          }
        />

        <SearchDateFilter
          placeholder="Batch Date"
          value={SearchFillter.batchDate}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, batchDate: value })
          }
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <SwitchButton
          label="Recent Record"
          // checked={isChecked}
          onChange={checkBoxIsChecked}
        />
      </div>
      {loading ? (
        // <TopBarProgress />
        <TableLoader rowsNum={20} cellNum={57} />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Discount Code"
              subTitle={`items`}
              count={pagePagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Discount ID</th>
                      <th>Linked Products</th>
                      <th>Valid Tail</th>
                      <th>Discount ID Type</th>
                      <th>Parent ID</th>
                      <th>Discount Type</th>
                      <th>Discount Value</th>
                      <th>Discount ID Status</th>
                      <th>Status</th>
                      <th>Is Clone</th>
                      <th>Batch No</th>
                      <th>Create Clone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discountData?.length ? (
                      <>
                        {discountData?.map((item, i) => (
                          <tr key={item?.result?.discountId}>
                            <td>{item?.result?.discountId} </td>
                            <td
                              style={{
                                border: "1px solid black",
                                fontWeight: 700,
                              }}
                            >
                              {item?.result?.totalProducts}
                            </td>
                            <td>{`${item?.result?.validTail}`}</td>
                            <td>{item?.result?.discountIdType}</td>
                            <td>
                              {item?.result?.parentId}
                              {/* {checkValuesIsMatching(item?.result?.prefix)} */}
                            </td>
                            <td style={{ fontWeight: 700 }}>
                              {!item?.result?.validTail &&
                              item?.result?.parentId
                                ? detectCurrencySymbol(item?.result?.parentId)
                                : item?.result?.validTail
                                ? detectCurrencySymbol(item?.result?.discountId)
                                : ""}
                            </td>

                            <td style={{ fontWeight: 700 }}>
                              {!item?.result?.validTail &&
                              item?.result?.parentId
                                ? extractDiscountValue(item?.result?.parentId)
                                : item?.result?.validTail
                                ? extractDiscountValue(item?.result?.discountId)
                                : ""}
                            </td>

                            <td>{item?.result?.validationStatus}</td>
                            <td
                              className="font-weight-bold"
                              style={{
                                color: "#24AD62",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.result?.status ??
                                displayBatchStatus(SearchFillter?.batchStatus)}
                            </td>
                            <td style={{ color: "#ffb706", fontWeight: 600 }}>
                              {/* {changeDataFormat(
                                `${item?.crawlRert?.crawlerDateTime}`
                              )} */}
                            </td>
                            <td>{item?.result?.batchNo}</td>
                            <td>
                              <CustomeTableBtn
                                color={"dark-gray"}
                                width={"full"}
                                className="font-size-sm"
                                onClick={() => handleCreateClone(item._id)}
                              >
                                clone
                              </CustomeTableBtn>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {/* Side bar Drawer */}
                {Edit.edit === "Clone" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="22"
                  >
                    <CreateClone />
                  </PriceCardDrawer>
                )}
                {Edit.edit === "pricing" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="22"
                  >
                    <PriceCard productInfo={discountData[0]} />
                  </PriceCardDrawer>
                )}
                <div className="d-flex justify-content-center align-center p-2">
                  <div className="mx-auto">
                    <Pagination
                      count={pagePagination.totalPages}
                      page={pagePagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className="font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {discountData?.length} of {pagePagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default DiscountCode;
