import React from "react";
import { Link } from "react-router-dom";
import styles from "../../style.module.css";
const EmailHeader = ({ header }) => {
  return (
    <div style={header?.style}>
      <div className="text-center">
        <img
          src={header?.logo?.img}
          width={header?.logo?.width}
          height={header?.logo?.height}
          alt=""
          style={{ objectFit: "contain" }}
        />
      </div>
      <div
        className={`d-flex align-items-center border-bottom overflow-x-auto  ${styles?.scrollbar}`}
      >
        {header?.navbar.links?.map((link) => (
          <Link
            to={`/${link.slug}`}
            className="link"
            style={header?.navbar?.style}
          >
            {link.text ?? ""}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default EmailHeader;
