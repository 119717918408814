import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { apiURL } from "../../../config/default";
import CircularProgress from "@material-ui/core/CircularProgress";
const AddUser = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    error: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSelect = (e) => {
    setUser({
      ...user,
      role: e.value,
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const {
    firstName,
    lastName,
    phone,
    email,
    password,
    confirmPassword,
    role,
    error,
  } = user;
  const options = [
    { value: "admin", label: "Admin" },
    { value: "techTeam", label: "TechTeam" },
    { value: "dataTeam", label: "DataTeam" },
    { value: "customerSupport", label: "CustomerSupport" },
    { value: "callCenter", label: "CallCenter" },
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];

  const registerUser = async (user) => {
    setLoading(true);
    try {
      const res = await axios.post(`${apiURL}admin/user-register`, user);
      toast(res?.data);
      return res;
    } catch (err) {
      toast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmailValid = validateEmail(email);
    if (role === "") {
      setUser({
        ...user,
        error: "Please select a role to continue!",
      });
    } else if (password.length < 6) {
      setUser({
        ...user,
        error: "Password must be atleast 6 chars ",
      });
    } else if (confirmPassword !== password) {
      setUser({
        ...user,
        error: "Passwords does not match!",
      });
    } else if (!isEmailValid) {
      setUser({
        ...user,
        error: "Please enter a valid Email",
      });
    } else if (firstName.length < 3) {
      setUser({
        ...user,
        error: "First Name must be atleast 3 chars ",
      });
    } else if (lastName.length < 3) {
      setUser({
        ...user,
        error: "Last Name must be atleast 3 chars ",
      });
    } else if (phone.length < 3) {
      setUser({
        ...user,
        error: "Phone number is required",
      });
    } else {
      registerUser(user);
      setUser({
        ...user,
        error: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      });
    }
  };

  return (
    <>
      <div className="page-header">
        <h3 className="page-title"> Add User </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/paid-users">Add User</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              View
            </li>
          </ol>
        </nav>
      </div>
      <ToastContainer />
      {loading ? (
        <h1>
          {
            <div className="text-center mt-5 mb-5">
              <CircularProgress valueBuffer={32} size={40} />
            </div>
          }
        </h1>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <span className="text-danger">{error}</span>
            <form onSubmit={handleSubmit} className="forms-sample">
              <div className="form-group">
                <label htmlFor="exampleInputName1">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="First Name"
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder="Phone Number"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName1">Role</label>
                <Select name="role" onChange={handleSelect} options={options} />
              </div>
              <button type="submit" className="btn btn-primary mr-2">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddUser;
