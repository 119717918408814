/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./styles.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import TopBarProgress from "react-topbar-progress-indicator";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";

import { ImAttachment } from "react-icons/im";
import { GrEdit } from "react-icons/gr";

import OrderStatusButton from "../../order/components/OrderStatusButton";
import EditTabelDrawer from "../../../../components/Edit-tabel-drawer/EditTabelDrawer";
import ShowNotes from "../../order/components/ShowNotes";
import OrderDetails from "../../order/components/OrderDetails";
import { getAllPendingOrders } from "../../../../actions/order";
import CostsUpdateComp from "./components/CostsUpdateComp";
import CostBreakDown from "./components/CostBreakDown";
import RefundUpdateComp from "./components/RefundUpdateComp";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import AddNewNotes from "../../order/components/AddNewNotes";
import { Calculation } from "../../../../utils/Calculation";

import ClaimUpdateComp from "./components/ClaimUpdateComp";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import DateRangeFilter from "../../../../components/CustomeFilter/DateRangeFilter";
import { MMDDYYFormat } from "../../../../utils/dateFormate";
import SelectMultipleFilter from "../../../../components/CustomeFilter/SelectMultipleFilter";
const SaleAnalysis = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get("orderNumber");
  const brand = searchParams.get("brand");

  const [loading, setloading] = useState(true);
  const [InnderLoader, setInnderLoader] = useState(false);

  const [IsMount, setIsMount] = useState(false);

  const [AllOrders, setAllOrders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Brand, setBrand] = useState(JSON.parse(localStorage.getItem("Brand")));

  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SelectItem, setSelectItem] = useState({});
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const initialSearchFilter = {
    OrderStatus: "",
    TrackingDetails: "",
    SearchByDate: "",
    SearchQuery: orderNumber ?? "",
    sort: "",
    shipandtrack: "",
    claims: "",
    deliverytype: "",
    brand: brand ?? "",
  };

  const [SearchFillter, setSearchFillter] = useState(initialSearchFilter);

  const resetFilters = () => {
    setSearchFillter(initialSearchFilter);
  };

  const setDateToEmpty = (inputDate) => {
    return inputDate === "mm/dd/yy,mm/dd/yy" ? "" : inputDate;
  };

  // Api calls for get Order
  const getOrders = useCallback(
    async (
      pageNumber,
      searchQuery,
      status,
      date,
      tracking,
      sort,
      claims,
      deliverytype,
      brand,
      trackingStatus,
      shipandtrack
    ) => {
      setInnderLoader(true);
      try {
        date = setDateToEmpty(date);
        const res = await dispatch(
          getAllPendingOrders(
            pageNumber,
            searchQuery,
            status,
            date,
            tracking,
            sort,
            claims,
            deliverytype,
            "",
            "",
            "",
            brand,
            trackingStatus,
            shipandtrack
          )
        );
        if (res) {
          // set to Seleted item when update because we set new Seleted item
          if (!SelectItem) {
            setSelectItem({});
          } else {
            const newSelected = res?.orders?.find(
              (order) => order._id === SelectItem._id
            );
            setSelectItem(newSelected);
          }
          setAllOrders(res?.orders);
          setpagination({
            ...res.pagination,
          });
          setloading(false);
          setInnderLoader(false);
        }
      } catch (error) {
        setloading(false);
        setInnderLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllOrders, SelectItem]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getOrders(
          pagination?.currentPage,
          SearchFillter.SearchQuery,
          SearchFillter.OrderStatus,
          SearchFillter.SearchByDate,
          SearchFillter.TrackingDetails,
          SearchFillter.sort,
          SearchFillter.claims,
          SearchFillter.deliverytype,
          SearchFillter.brand,
          SearchFillter?.trackingStatus,
          SearchFillter?.shipandtrack
        );
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination?.currentPage,
    SearchFillter.SearchQuery,
    SearchFillter.OrderStatus,
    SearchFillter.SearchByDate,
    SearchFillter.TrackingDetails,
    SearchFillter.sort,
    SearchFillter.claims,
    SearchFillter.deliverytype,
    SearchFillter.brand,
    SearchFillter?.trackingStatus,
    SearchFillter?.shipandtrack,

    IsMount,
    Edit.open,
  ]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/sale-analysis",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  const handleNotes = (item) => {
    setEdit({
      open: true,
      edit: "Notes",
      title: "Notes",
    });
    setSelectItem({ ...item });
  };

  // Edit Cost Click
  const handleCosts = (item) => {
    setEdit({
      open: true,
      edit: "editCosts",
      title: "Costs",
    });
    setSelectItem({ ...item });
  };

  const handleRefund = (item) => {
    setEdit({
      open: true,
      edit: "refund",
      title: "Refunds",
    });
    setSelectItem({ ...item });
  };
  const handleCliam = (item) => {
    setEdit({
      open: true,
      edit: "Claim",
      title: "Claim",
    });
    setSelectItem({ ...item });
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div
            className=" d-flex  justify-content-end align-center"
            style={{ gap: "20px" }}
          >
            <SearchFilter
              width={"250px"}
              type="text"
              placeholder={"Search Order #, Tracking #"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
            <DateRangeFilter
              title={"Order Date"}
              placeholder={"Select"}
              startDate={SearchFillter?.SearchByDate.split(",")[0]}
              endDate={SearchFillter?.SearchByDate.split(",")[1]}
              onChange={(Date) => {
                setSearchFillter({
                  ...SearchFillter,
                  SearchByDate: `${MMDDYYFormat(Date[0])},${MMDDYYFormat(
                    Date[1]
                  )}`,
                });
              }}
            />
            <SelectSearchFilter
              title={"Brand"}
              placeholder="Select"
              width={"200px"}
              value={SearchFillter?.brand ? Brand.find((item) => SearchFillter?.brand === item?.id)?.value : ""}
              options={Brand}
              onChange={(value) => {
                setSearchFillter({
                  ...SearchFillter,
                  brand:
                    value !== ""
                      ? Brand.find((item) => item.value === value)?.id
                      : "",
                });
              }}
            />

            <SelectMultipleFilter
              width={"200px"}
              title={"Order Status"}
              placeholder="All"
              value={[]}
              options={[
                { value: "Pending", label: "Pending" },
                { value: "Processing", label: "Processing" },
                { value: "Preparing To Ship", label: "Preparing To Ship" },
                { value: "In Transit", label: "In Transit" },
                { value: "Delivered", label: "Delivered" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, trackingStatus: value })
              }
            />
            <SelectSearchFilter
              title={"Shipping & Tracking"}
              placeholder="Select"
              value={SearchFillter?.shipandtrack}
              options={[
                { value: "notProvided", label: "+Add" },
                { value: "provided", label: "included" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, shipandtrack: value })
              }
            />
            <SelectSearchFilter
              title={"Sort"}
              placeholder="Select"
              value={SearchFillter?.sort}
              options={[
                { value: "desc", label: "Newest First" },
                { value: "asc", label: "Oldest First" },
              ]}
              onChange={(value) =>
                setSearchFillter({ ...SearchFillter, sort: value })
              }
            />

            <button
              onClick={resetFilters}
              className="reset-filter-button search-filter "
            >
              Reset Filters
            </button>
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Sale Analysis"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="process-orders-table">
                <table className="m-0">
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Order Status</th>
                      <th>Order Date</th>
                      <th>Brand</th>
                      <th>Qty</th>
                      <th>COGS</th>
                      <th>Ship Type</th>
                      <th>Ship Cost</th>
                      <th>
                        DS <br /> Fees
                      </th>
                      <th>Subtotal</th>
                      <th>Total</th>
                      <th>Profit</th>
                      <th>Refund</th>
                      <th>Claim</th>
                      <th>Edit Costs</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllOrders?.length ? (
                      <>
                        {AllOrders?.map((order, i) => (
                          <tr key={order?._id}>
                            <td
                              className="order-item-number"
                              onClick={() =>
                                navigate(`/admin/view-order/${order?._id}`)
                              }
                            >
                              {order?.itemOrderNumber}
                            </td>
                            <td>
                              <span>
                                <OrderStatusButton
                                  width={250}
                                  status={order.trackingStatus}
                                  // status={"Returned"}
                                >
                                  {order.trackingStatus}{" "}
                                  {order.trackingStatus === "Backordered"
                                    ? `(${
                                        order?.backorderStatus?.status
                                          ? order?.backorderStatus?.status
                                          : "Pending"
                                      })`
                                    : null}
                                </OrderStatusButton>
                              </span>
                            </td>
                            <td>{moment(order?.createdAt).format("L")}</td>
                            <td>{order?.brandId?.fullName.split(" ")[0]}</td>

                            <td>{order?.quantity}</td>
                            <td
                              style={
                                order?.orCogs?.type === "promo"
                                  ? { color: "#17a858" }
                                  : order?.costType === "promo"
                                  ? { color: "#17a858" }
                                  : {}
                              }
                            >
                              ${Calculation(order, "COGS")}
                            </td>
                            <td>
                              <span
                                style={
                                  order?.orShipping?.shippingFee === 299 ||
                                  order?.shippingFee === 299
                                    ? {}
                                    : { color: "#878787" }
                                }
                              >
                                {`${
                                  order?.orShipping?.type
                                    ? order?.orShipping?.type
                                    : order?.shippingFee === 299
                                    ? "WG"
                                    : "standard"
                                }`}
                              </span>
                            </td>
                            <td>${Calculation(order, "shippingTotal")}</td>
                            <td>${Calculation(order, "dsFee")}</td>

                            <td>{Calculation(order, "OrderSubTotal")}</td>
                            <td>${Calculation(order, "OrderTotal")}</td>
                            <td>${Calculation(order, "profit")}</td>
                            <td>
                              <span onClick={() => handleRefund(order)}>
                                <OrderStatusButton
                                  width={100}
                                  status={
                                    order?.refunds?.status ? "Returned" : "+add"
                                  }
                                >
                                  {order?.refunds?.itemPrice
                                    ? `$${order?.refunds?.itemPrice}`
                                    : "+ Add"}
                                </OrderStatusButton>
                              </span>
                            </td>

                            <td>
                              <span onClick={() => handleCliam(order)}>
                                <OrderStatusButton
                                  width={100}
                                  status={
                                    order?.claims?.claimStatus === "Accept"
                                      ? "Accept"
                                      : order?.claims?.claimStatus === "Cancel"
                                      ? ""
                                      : order?.claims?.claimStatus === "Reject"
                                      ? "Returned"
                                      : "+add"
                                  }
                                >
                                  {order?.claims?.claimStatus === "Accept" ||
                                  order?.claims?.claimStatus === "Cancel"
                                    ? `$${order?.claims?.itemPrice}`
                                    : order?.claims?.claimStatus
                                    ? order?.claims?.claimStatus
                                    : "+Add"}
                                </OrderStatusButton>
                              </span>
                            </td>
                            {/* Edit Cost */}
                            <td>
                              <div onClick={() => handleCosts(order)}>
                                <OrderStatusButton
                                  width={150}
                                  status={
                                    order?.orCogs?.status &&
                                    order?.orDsFees?.status &&
                                    order?.orShipping?.status
                                      ? "Delivered"
                                      : "+add"
                                  }
                                >
                                  <span>
                                    {[
                                      order?.orCogs,
                                      order?.orShipping,
                                      order?.orDsFees,
                                    ].reduce(
                                      (acc, obj) =>
                                        obj.status ? acc + 1 : acc,
                                      0
                                    )}
                                    /3
                                  </span>
                                  <span>
                                    <GrEdit />
                                  </span>

                                  <span className="ml-2">Edit</span>
                                </OrderStatusButton>
                              </div>
                            </td>

                            <td>
                              <div onClick={() => handleNotes(order)}>
                                <OrderStatusButton
                                  width={90}
                                  status={
                                    order?.notes?.length ? "In Transit" : "+add"
                                  }
                                >
                                  <span>
                                    <ImAttachment
                                      style={{
                                        fontSize: "20px",
                                      }}
                                    />
                                  </span>
                                  <span className="ml-2">
                                    {order?.notes?.length}
                                  </span>
                                </OrderStatusButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={16} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <EditTabelDrawer
                  Edit={Edit}
                  setEdit={setEdit}
                  shippingId={SelectItem?.itemOrderNumber}
                >
                  {InnderLoader ? (
                    <div className=" d-flex align-center justify-content-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {Edit.edit === "editCosts" && (
                        <>
                          <CostsUpdateComp
                            SelectItem={SelectItem}
                            IsMount={IsMount}
                            setIsMount={setIsMount}
                          />
                          <CostBreakDown SelectItem={SelectItem} />
                          <ShowNotes
                            title="Cost Notes"
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Costs"
                            )}
                          />
                        </>
                      )}

                      {Edit.edit === "Claim" && (
                        <>
                          <ClaimUpdateComp
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                          <hr />
                          <ShowNotes
                            title="Claim Notes"
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Claim"
                            )}
                          />
                          <hr />

                          <AddNewNotes
                            SelectItem={SelectItem}
                            setEdit={setEdit}
                          />
                        </>
                      )}
                      {Edit.edit === "refund" && (
                        <>
                          <RefundUpdateComp
                            setEdit={setEdit}
                            SelectItem={SelectItem}
                          />
                          <hr />
                          <ShowNotes
                            title="Refund Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes?.filter(
                              (message) => message.subject === "Refunds"
                            )}
                          />
                          <hr />

                          <AddNewNotes
                            SelectItem={SelectItem}
                            setEdit={setEdit}
                          />
                        </>
                      )}
                      {Edit.edit === "Notes" && (
                        <>
                          <AddNewNotes
                            SelectItem={SelectItem}
                            setEdit={setEdit}
                          />
                          <ShowNotes
                            title="All Notes"
                            SelectItem={SelectItem}
                            notes={SelectItem?.notes}
                          />
                        </>
                      )}

                      <hr className=" mb-0" />
                      <OrderDetails
                        IsMount={IsMount}
                        setIsMount={setIsMount}
                        setEdit={setEdit}
                        SelectItem={SelectItem}
                      />
                    </>
                  )}
                </EditTabelDrawer>
              </div>

              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {AllOrders?.length} of {pagination?.count} Items
                </span>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default SaleAnalysis;
