// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blog_blogButtons__2_PKw {\n  outline: none;\n  background-color: #222021;\n  color: #cccacb;\n  font-size: 1.1rem;\n  font-weight: 600;\n  padding: 0.3rem 3rem;\n  border-radius: 0.3rem;\n  border: none;\n  transition: 0.3s all;\n}\n\n.blog_blogButtons__2_PKw:hover {\n  background-color: #cccacb;\n  color: #222021;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/blog/blog.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".blogButtons {\n  outline: none;\n  background-color: #222021;\n  color: #cccacb;\n  font-size: 1.1rem;\n  font-weight: 600;\n  padding: 0.3rem 3rem;\n  border-radius: 0.3rem;\n  border: none;\n  transition: 0.3s all;\n}\n\n.blogButtons:hover {\n  background-color: #cccacb;\n  color: #222021;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogButtons": "blog_blogButtons__2_PKw"
};
export default ___CSS_LOADER_EXPORT___;
