import React, { useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";

const CustomeSelect = ({
  onChange,
  placeholder,
  value,
  title,
  options,
  width,
  className,
}) => {
  const [data, setdata] = useState(options ?? []);
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value ?? null);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (value) => {
    if (selectedItem !== value) {
      setSelectedItem(value);
      onChange(value);
    }
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ minWidth: width }}
        className={`select-dropdown ${className}`}
      >
        <div className="select-dropdown-header" onClick={toggleDropdown}>
          <div>
            <p className="font-weight-bold">{title}</p>
            <p className="select-value">
              {selectedItem ? selectedItem : placeholder}
            </p>
          </div>

          <RiArrowDownSFill
            className={`${isOpen ? "icon" : ""}`}
            style={{ fontSize: "25px" }}
          />
        </div>
        <div
          onMouseLeave={() => setOpen(false)}
          className={`dropdown-body ${isOpen && "open"}`}
        >
          {data.map((item, i) => (
            <div
              key={i}
              value={item.value}
              className="select-dropdown-item"
              onClick={() => handleItemClick(item.value)}
              id={item.value}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomeSelect;
