import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import DashboardPagesLayout from "./components/DashboardPagesLayout";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import OrderStatusButton from "../order/components/OrderStatusButton";
import { getTickets } from "../../../actions/tickets";
import "./csDashboard.css";
import "./ticketDetails.css";
import { ImAttachment } from "react-icons/im";
import ManageTicket from "./components/ManageTicket";
import moment from "moment";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../components/CustomeFilter/SelectSearchFilter";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import { GoAlertFill } from "react-icons/go";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { Pagination } from "@mui/material";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";
import { CircularProgress } from "@mui/material";
import AddNewNotes from "./components/ticketsComponents/AddNewNotes";
import ShowAllNotes from "./components/ticketsComponents/ShowAllNotes";

const Tickets = () => {
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEscalated = searchParams.get("isEscalated");
  const status = searchParams.get("status");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [InnderLoader, setInnderLoader] = useState(false);
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const handleNotes = (item) => {
    setEdit({
      open: true,
      edit: "Notes",
      title: "Notes",
    });
    setSelectedItem({ ...item });
  };

  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
    SearchByCustomer: "",
    ticketStatus: status ?? "",
    ticketReason: "",
    isEscalated: isEscalated ?? "",
    attention: "",
    priority: "",
    ticketType: "",
  });
  const fetchData = useCallback(
    async (
      currentPage,
      SearchQuery,
      SearchByCustomer,
      ticketStatus,
      isEscalated,
      attention,
      priority,
      ticketType
    ) => {
      setLoading(true);
      setInnderLoader(true)
      const res = await dispatch(
        getTickets(
          currentPage,
          SearchQuery,
          SearchByCustomer,
          ticketStatus,
          isEscalated,
          attention,
          priority,
          ticketType
        )
      );
      if (res) {
        // debugger;
        if (!selectedItem) {
          setSelectedItem({});
        } else {
          const newSelected = res?.tickets?.find(
            (item) => item._id === selectedItem._id
          );
          setSelectedItem(newSelected);
        }
        setLoading(false);
        setInnderLoader(false)
        setData(res?.tickets);
        console.log(res);
        setpagination({
          ...res?.pagination,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data, selectedItem]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(
        pagination.currentPage,
        SearchFillter.SearchQuery,
        SearchFillter.SearchByCustomer,
        SearchFillter.ticketStatus,
        SearchFillter.isEscalated,
        SearchFillter.attention,
        SearchFillter.priority,
        SearchFillter.ticketType
      );
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.SearchQuery,
    SearchFillter.SearchByCustomer,
    SearchFillter.ticketStatus,
    SearchFillter.isEscalated,
    SearchFillter.attention,
    SearchFillter.priority,
    SearchFillter.ticketType,

    isRender,
  ]);

  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/all-tickets",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedItem(item);
  };

  return (
    <>
      <div
        className=" d-flex  justify-content-end align-center flex-wrap"
        style={{ gap: "20px" }}
      >
        <SearchFilter
          type="text"
          placeholder={"Search Ticket Number"}
          value={SearchFillter?.SearchQuery}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchQuery: e.target.value,
            })
          }
        />
        <SearchFilter
          type="text"
          placeholder={"Search customer Details"}
          value={SearchFillter?.SearchByCustomer}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchByCustomer: e.target.value,
            })
          }
        />
        <SelectSearchFilter
          title={"Ticket Type"}
          value={SearchFillter?.ticketType}
          placeholder="Select"
          options={[
            { value: "Tracking number issue", label: "Tracking number issue" },
            {
              value: "Item arrived damaged or defective",
              label: "Item arrived damaged or defective",
            },
            {
              value: "Change your mind & want to return item",
              label: "Change your mind & want to return item",
            },
            {
              value: "Want to exchange an item",
              label: "Want to exchange an item",
            },
            { value: "Want to cancel order", label: "Want to cancel order" },
            {
              value: "Make a correction to shipping address",
              label: "Make a correction to shipping address",
            },
            {
              value: "Request special delivery instructions",
              label: "Request special delivery instructions",
            },
            {
              value: "Request update on order status",
              label: "Request update on order status",
            },
            { value: "Other", label: "Other" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, ticketType: value })
          }
        />

        <SelectSearchFilter
          title={"Filter Ticket Status"}
          value={SearchFillter?.ticketStatus}
          placeholder="Select"
          options={[
            { value: "", label: "All" },
            { value: "open", label: "Open" },
            { value: "closed", label: "Closed" },
            { value: "cancelled", label: "Cancelled" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, ticketStatus: value })
          }
        />
        <SelectSearchFilter
          title={"Filter Escalated"}
          value={SearchFillter?.isEscalated}
          placeholder="Select"
          options={[
            { value: "", label: "All" },
            { value: "true", label: "Escalated" },
            { value: "false", label: "Not Escalated" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, isEscalated: value })
          }
        />

        <SelectSearchFilter
          title={"Filter Reply"}
          placeholder="Select"
          options={[
            { value: "", label: "All" },
            { value: "user", label: "Action Required" },
            { value: "admin", label: "No Action Required" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, attention: value })
          }
        />
        <SelectSearchFilter
          title={"Filter Priority"}
          placeholder="Select"
          options={[
            { value: "", label: "All" },
            { value: "normal", label: "Normal" },
            { value: "high", label: "High" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, priority: value })
          }
        />
      </div>
      <div className=" mt-4">
        <DashboardPagesLayout
          pageTitle="Tickets"
          subTitle="Tickets"
          count={pagination?.count}
        >
          <div className="dashboard-pages-table">
            <table className=" w-100">
              <thead>
                <tr>
                  <th style={{ width: "110px" }}>Ticket#</th>
                  <th> Ticket Type</th>
                  <th>Ticket Date</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Media</th>
                  <th>Brand</th>
                  <th>Customer Details</th>
                  <th>Order Date</th>
                  <th>Status</th>
                  <th>Priority</th>
                  <th>Reply</th>
                  <th>Escalated</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={13} />}
                {!loading && Data?.length ? (
                  <>
                    {Data?.map((item, index) => (
                      <tr key={item?._id}>
                        <td>{item?.ticketId}</td>

                        <td>{item?.reason}</td>
                        <td>{moment(item?.createdAt).format("l")}</td>
                        <td>
                          <div className="table-image-container bg-cream  mx-auto">
                            <img
                              src={item?.orderItemId?.mainImage}
                              alt="Product imaghe"
                            />
                          </div>
                        </td>
                        <td>{item?.quantity}</td>
                        <td>{item?.images?.length}</td>
                        <td>{item?.orderItemId?.brandId?.fullName}</td>
                        <td>
                          <span className=" font-weight-bold">
                            {item?.openedBy?.fullName}
                          </span>
                          <br />
                          <span className=" text-black">
                            {item?.openedBy?.email}
                          </span>
                        </td>
                        <td>
                          <p className="font-weight-bold">Shipping</p>
                          {moment(
                            item?.orderItemId?.shippingInfo?.estimateShipDate
                          ).format("l")}{" "}
                          <p className="font-weight-bold">Delivery</p>
                          {moment(
                            item?.orderItemId?.shippingInfo?.finalDeliveryDate
                          ).format("l")}
                        </td>
                        <td>
                          <OrderStatusButton
                            onClick={() => handleOpen(item)}
                            className="mx-auto"
                            status={item?.status}
                            width={"100px"}
                          >
                            {capitalizeFirstLetter(item?.status)}
                          </OrderStatusButton>
                        </td>
                        <td>
                          <OrderStatusButton
                            onClick={() => handleOpen(item)}
                            className="mx-auto"
                            status={item?.priority}
                            width={"100px"}
                          >
                            {capitalizeFirstLetter(item?.priority)}
                          </OrderStatusButton>
                        </td>
                        <td>
                          {item?.lastMessage === "user" ? (
                            <OrderStatusButton
                              onClick={() => handleOpen(item)}
                              className="mx-auto"
                              status={"Returned"}
                              width={"100px"}
                            >
                              !
                            </OrderStatusButton>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {item?.isEscalated ? (
                            <div
                              className="d-flex justify-content-center align-center mx-auto border-radius-8 "
                              style={{
                                width: "40px",
                                height: "40px",
                                background: "red",
                              }}
                            >
                              <GoAlertFill size={20} color="#ffffff" />
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <div onClick={() => handleNotes(item)}>
                            <OrderStatusButton
                              width={90}
                              status={
                                item?.notes?.length ? "In Transit" : "+add"
                              }
                            >
                              <span>
                                <ImAttachment
                                  style={{
                                    fontSize: "20px",
                                  }}
                                />
                              </span>
                              <span className="ml-2">
                                {item?.notes?.length ?? 0}
                              </span>
                            </OrderStatusButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={13} className=" text-center">
                        No Record Found
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            <div>
              <EditTabelDrawer
                Edit={Edit}
                setEdit={setEdit}
                shippingId={selectedItem?.ticketId}
              >
                {InnderLoader ? (
                  <div className=" d-flex align-center justify-content-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {Edit.edit === "Notes" && (
                      <>
                        <AddNewNotes
                          SelectItem={selectedItem}
                          setEdit={setEdit}
                          setData = {setData}
                        />
                        <ShowAllNotes
                          title="All Notes"
                          SelectItem={selectedItem}
                          notes={selectedItem?.notes}
                        />
                      </>
                    )}
                  </>
                )}
              </EditTabelDrawer>
            </div>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
          </div>
        </DashboardPagesLayout>

        <ManageTicket
          outerLoading={loading}
          isRender={isRender}
          setIsRender={setIsRender}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </>
  );
};

export default Tickets;
