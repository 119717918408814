/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import "./productCreate.css";
import TopBarProgress from "react-topbar-progress-indicator";
import ImageUploader from "react-images-upload";
import moment from "moment";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";
import { getStores } from "../../../actions/store";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/product";
import { getAttribute } from "../../../actions/product";
import { getWeightClass } from "../../../actions/product";
import { getLengthClass } from "../../../actions/product";
import { retreive } from "../../../actions/product";
import { edit } from "../../../actions/product";
import EditorConvertUpdateToHTML from "../../../components/textEditorUpdate/textEditorUpdate";
import { usersRetreive } from "../../../actions/user";
import { subCategories } from "../../../actions/subCategory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tabs, Tab, FormControl } from "react-bootstrap";
import EditorConvertToHTML from "../../../components/TextEditor/TextEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
// const specOptions = [
//   { value: "Shape", label: "Shape", color: "#00B8D9", isFixed: true },
//   {
//     value: "Item Length (in)",
//     label: "Item Length (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Item Color", label: "Item Color", color: "#00B8D9", isFixed: true },
//   {
//     value: "Material Type",
//     label: "Material Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Weight (lbs)",
//     label: "Item Weight (lbs)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Sales UOM", label: "Sales UOM", color: "#00B8D9", isFixed: true },
//   { value: "Finish", label: "Finish", color: "#00B8D9", isFixed: true },
//   { value: "Style", label: "Style", color: "#00B8D9", isFixed: true },
//   {
//     value: "Installation Type",
//     label: "Installation Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Height (in)",
//     label: "Item Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Center to center (in)",
//     label: "Item Center to center (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Diameter (in)",
//     label: "Item Diameter (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Overall Height Range (in)",
//     label: "Item Overall Height Range (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Overall Width Range (in)",
//     label: "Item Overall Width Range (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Overall Depth Range (in)",
//     label: "Item Overall Depth Range (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Center to Center Range (in)",
//     label: "Item Center to Center Range (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Overall Diameter Range (in)",
//     label: "Item Overall Diameter Range (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Clearance (in)",
//     label: "Clearance (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Foot Width (in)",
//     label: "Item Foot Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Minimum Cabinet Opening (in)",
//     label: "Minimum Cabinet Opening (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Hinge Overlay",
//     label: "Hinge Overlay",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Hinge Degree of Opening",
//     label: "Hinge Degree of Opening",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Soft Closing (Yes/No)",
//     label: "Soft Closing (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Self Closing (Yes/No)",
//     label: "Self Closing (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "With or Without Screws",
//     label: "With or Without Screws",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Sink Shape", label: "Sink Shape", color: "#00B8D9", isFixed: true },
//   {
//     value: "Drain Placement",
//     label: "Drain Placement",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Coordinating Mirrors for Vanity",
//     label: "Coordinating Mirrors for Vanity",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Size", label: "Size", color: "#00B8D9", isFixed: true },
//   {
//     value: "Collection Name",
//     label: "Collection Name",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Product Helpful Tips",
//     label: "Product Helpful Tips",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "LED (Yes/No)",
//     label: "LED (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade Shape",
//     label: "Shade Shape",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade/Glass Materials",
//     label: "Shade/Glass Materials",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Switch Type",
//     label: "Switch Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Voltage", label: "Voltage", color: "#00B8D9", isFixed: true },
//   {
//     value: "Assembly Required (Yes/No)",
//     label: "Assembly Required (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Type 1",
//     label: "Bulb Type 1",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Dimmable (Yes/No)",
//     label: "Dimmable (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Wattage 1",
//     label: "Bulb Wattage 1",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Proposition 65 Compliance (Yes/No)",
//     label: "Proposition 65 Compliance (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Tabletop Thickness (in)",
//     label: "Tabletop Thickness (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Upholstery Material",
//     label: "Upholstery Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Backing Material",
//     label: "Backing Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Construction",
//     label: "Item Construction",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Adjustable Height (in)",
//     label: "Adjustable Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Arm Color", label: "Arm Color", color: "#00B8D9", isFixed: true },
//   {
//     value: "Arm Material",
//     label: "Arm Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Arms (Yes/No)",
//     label: "Arms (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Back Cushion Fill Material",
//     label: "Back Cushion Fill Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Back Height (in)",
//     label: "Back Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Beveled Glass (Yes/No)",
//     label: "Beveled Glass (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Included 1 (Yes/No)",
//     label: "Bulb Included 1 (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Cleaning Code",
//     label: "Cleaning Code",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Closure Type",
//     label: "Closure Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Composite Wood Product (Yes/No)",
//     label: "Composite Wood Product (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Certified Wet or Damp",
//     label: "Certified Wet or Damp",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Depth (in)",
//     label: "Item Depth (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Distressed Finish (Yes/No)",
//     label: "Distressed Finish (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Drawer Count",
//     label: "Drawer Count",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Feet Material",
//     label: "Feet Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Fill Material",
//     label: "Fill Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Footrest (Yes/No)",
//     label: "Footrest (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Footrest Distance to Seat (in)",
//     label: "Footrest Distance to Seat (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Frame Color",
//     label: "Frame Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Frame Material Variety",
//     label: "Frame Material Variety",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Gloss Level",
//     label: "Gloss Level",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Headboard (Yes/No)",
//     label: "Furniture Headboard (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Headrest Included (Yes/No)",
//     label: "Headrest Included (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Leg Glides (Yes/No)",
//     label: "Leg Glides (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Lumbar Support (Yes/No)",
//     label: "Lumbar Support (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Mirror Shape",
//     label: "Mirror Shape",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Outdoor Use (Yes/No)",
//     label: "Outdoor Use (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Paint Type", label: "Paint Type", color: "#00B8D9", isFixed: true },
//   {
//     value: "Product has a back (Yes/No)",
//     label: "Product has a back (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Reclaimed Wood (Yes/No)",
//     label: "Reclaimed Wood (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Recycled Content (Yes/No)",
//     label: "Recycled Content (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Removable Cushions (Yes/No)",
//     label: "Removable Cushions (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Reverse Side Color",
//     label: "Reverse Side Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Reverse Side Pattern",
//     label: "Reverse Side Pattern",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Reversible Cushions (Yes/No)",
//     label: "Reversible Cushions (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Rub Count", label: "Rub Count", color: "#00B8D9", isFixed: true },
//   {
//     value: "Seat Cushion Fill Material",
//     label: "Seat Cushion Fill Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seat Depth (in)",
//     label: "Seat Depth (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seat Height (in)",
//     label: "Seat Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seat Material",
//     label: "Seat Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seat Material Variety",
//     label: "Seat Material Variety",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seat Width (in)",
//     label: "Seat Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Seating Capacity",
//     label: "Seating Capacity",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Stain Resistant (Yes/No)",
//     label: "Stain Resistant (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Swivel (Yes/No)",
//     label: "Swivel (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Upholstered (Yes/No)",
//     label: "Upholstered (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Upholstery Color",
//     label: "Upholstery Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Upholstery Fill Material",
//     label: "Upholstery Fill Material",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Weight Capacity (lbs)",
//     label: "Weight Capacity (lbs)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Wood Finish",
//     label: "Wood Finish",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Width (in)",
//     label: "Item Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Lamp Base Dimensions (in)",
//     label: "Lamp Base Dimensions (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Cord Color", label: "Cord Color", color: "#00B8D9", isFixed: true },
//   {
//     value: "Cord Length (in)",
//     label: "Cord Length (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Mirror Width (in)",
//     label: "Mirror Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Mirror Height (in)",
//     label: "Mirror Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Build-in Outlet (Yes/No)",
//     label: "Build-in Outlet (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Chain Length (in)",
//     label: "Chain Length (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Headboard Height (in)",
//     label: "Headboard Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Headboard Width (in)",
//     label: "Headboard Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade/Glass Height (in)",
//     label: "Shade/Glass Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade/Glass Width (in)",
//     label: "Shade/Glass Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "ADA Compliant (Yes/No)",
//     label: "ADA Compliant (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Eco Friendly (Yes/No)",
//     label: "Eco Friendly (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Water Resistant (Yes/No)",
//     label: "Water Resistant (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Water Absorption",
//     label: "Water Absorption",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Abrasion Resistance (Yes/No)",
//     label: "Abrasion Resistance (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Freeze Resistance (Yes/No)",
//     label: "Freeze Resistance (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Weather Resistant (Yes/No)",
//     label: "Weather Resistant (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Mildew Resistant (Yes/No)",
//     label: "Mildew Resistant (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Hypoallergenic (Yes/No)",
//     label: "Hypoallergenic (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Moisture Wicking (Yes/No)",
//     label: "Moisture Wicking (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Reversible (Yes/No)",
//     label: "Item Reversible (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Removable Cover (Yes/No)",
//     label: "Removable Cover (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Embroidered (Yes/No)",
//     label: "Item Embroidered (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Applique (Yes/No)",
//     label: "Item Applique (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Buttons (Yes/No)",
//     label: "Item Buttons (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Sequined (Yes/No)",
//     label: "Item Sequined (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Tassels Included (Yes/No)",
//     label: "Item Tassels Included (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Collection Description",
//     label: "Collection Description",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Color Family",
//     label: "Color Family",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Pattern",
//     label: "Item Pattern",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Flange Color",
//     label: "Flange Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Tassel Color",
//     label: "Tassel Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Tassel Type",
//     label: "Tassel Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Piping Color",
//     label: "Piping Color",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Technique", label: "Technique", color: "#00B8D9", isFixed: true },
//   {
//     value: "Finish Group",
//     label: "Finish Group",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Material Type Front",
//     label: "Material Type Front",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Material Type Back",
//     label: "Material Type Back",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Border",
//     label: "Item Border",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Adjustable (Yes/No)",
//     label: "Item Adjustable (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Set of", label: "Set of", color: "#00B8D9", isFixed: true },
//   {
//     value: "Item Size Group",
//     label: "Item Size Group",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Dimensions (L x W x H)",
//     label: "Item Dimensions (L x W x H)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Pile Type", label: "Pile Type", color: "#00B8D9", isFixed: true },
//   {
//     value: "Pile Height (in)",
//     label: "Pile Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Thickness (in)",
//     label: "Item Thickness (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Fringe (Yes/No)",
//     label: "Item Fringe (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Fringe Type",
//     label: "Item Fringe Type",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Item Projection (in)",
//     label: "Item Projection (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Recommended Screws",
//     label: "Recommended Screws",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Indoor Use (Yes/No)",
//     label: "Indoor Use (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Sink Back to Front Dimension",
//     label: "Sink Back to Front Dimension",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Sink Bowl Depth",
//     label: "Sink Bowl Depth",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Gauge", label: "Gauge", color: "#00B8D9", isFixed: true },
//   {
//     value: "Overflow Drain (Yes/No)",
//     label: "Overflow Drain (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Spout Reach Width (in)",
//     label: "Spout Reach Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Size Class", label: "Size Class", color: "#00B8D9", isFixed: true },
//   {
//     value: "Top and Bowl Pre-Installed? (Yes/No)",
//     label: "Top and Bowl Pre-Installed? (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Spout Reach Height (in)",
//     label: "Spout Reach Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Water Flow Rate (GPM)",
//     label: "Water Flow Rate (GPM)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Pullout Sprayer (Yes/No)",
//     label: "Pullout Sprayer (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Sensor Activated (Yes/No)",
//     label: "Sensor Activated (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Number of Handles",
//     label: "Number of Handles",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Handle Style",
//     label: "Handle Style",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Number of Faucet Holes Required",
//     label: "Number of Faucet Holes Required",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Water Flow Controls",
//     label: "Water Flow Controls",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Valve Type", label: "Valve Type", color: "#00B8D9", isFixed: true },
//   {
//     value: "Bulb Count 1",
//     label: "Bulb Count 1",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Base 1",
//     label: "Bulb Base 1",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Count 2",
//     label: "Bulb Count 2",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Wattage 2",
//     label: "Bulb Wattage 2",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Type 2",
//     label: "Bulb Type 2",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Base 2",
//     label: "Bulb Base 2",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Bulb Included 2 (Yes/No)",
//     label: "Bulb Included 2 (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Total Lumens",
//     label: "Total Lumens",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Color Temperature",
//     label: "Color Temperature",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Color Rendering Index",
//     label: "Color Rendering Index",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Backplate/Canopy Dimensions (in)",
//     label: "Backplate/Canopy Dimensions (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Extension Rods (in)",
//     label: "Extension Rods (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade/Glass Description",
//     label: "Shade/Glass Description",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Shade/Glass Finish",
//     label: "Shade/Glass Finish",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Harp/Spider",
//     label: "Harp/Spider",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Print Process",
//     label: "Print Process",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Printed On", label: "Printed On", color: "#00B8D9", isFixed: true },
//   {
//     value: "Hang Orientation",
//     label: "Hang Orientation",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Hang Hardware",
//     label: "Hang Hardware",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Stackable (Yes/No)",
//     label: "Furniture Stackable (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Legs (Yes/No)",
//     label: "Furniture Legs (Yes/No)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Tempered Glass",
//     label: "Tempered Glass",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Headboard Length (in)",
//     label: "Headboard Length (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Recommended Cabinet Size",
//     label: "Recommended Cabinet Size",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Hinge Crank Amount",
//     label: "Hinge Crank Amount",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Arm Height (in)",
//     label: "Furniture Arm Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Arm Width (in)",
//     label: "Furniture Arm Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Furniture Arm Depth (in)",
//     label: "Furniture Arm Depth (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Edge Treatment",
//     label: "Edge Treatment",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Nominal Size",
//     label: "Nominal Size",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Grout Joint Recommendation (in)",
//     label: "Grout Joint Recommendation (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Tile Thickness (in)",
//     label: "Tile Thickness (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Case Length (in)",
//     label: "Case Length (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Case Width (in)",
//     label: "Case Width (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   {
//     value: "Case Height (in)",
//     label: "Case Height (in)",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "Case UPC", label: "Case UPC", color: "#00B8D9", isFixed: true },
//   {
//     value: "Square Ft. per Piece",
//     label: "Square Ft. per Piece",
//     color: "#00B8D9",
//     isFixed: true,
//   },
//   { value: "LB/PC", label: "LB/PC", color: "#00B8D9", isFixed: true },
//   { value: "PC/CT", label: "PC/CT", color: "#00B8D9", isFixed: true },
//   { value: "SF/CT", label: "SF/CT", color: "#00B8D9", isFixed: true },
//   { value: "LB/CT", label: "LB/CT", color: "#00B8D9", isFixed: true },
//   { value: "LF/CT", label: "LF/CT", color: "#00B8D9", isFixed: true },
// ];
// const createOption = (label: string) => ({
//   value: label.toLowerCase().replace(/\W/g, ""),
//   label: label.toLowerCase().replace(/\W/g, ""),
//   color: "#00B8D9",
//   isFixed: true,
// });

class editProduct extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updatedImages: [],
      image: "",
      getWeightClass: [],
      getLengthClass: [],
      description: "",
      title: "",
      slug: "",
      MetaTagTitle: "",
      brand: "",
      modelNumber: "",
      price: 0,
      minimumQuantity: "",
      weightAndDimension: [{ name: "", value: "" }],
      specification: [{ name: "", value: "" }],
      tag: [],

      discountInfo: [
        {
          customerGroup: "",
          quantity: 0,
          priority: "",
          price: 0,
          startDate: "",
          endDate: "",
        },
      ],
      specialInfo: [
        {
          customerGroup: "",
          priority: "",
          price: 0,
          startDate: "",
          endDate: "",
        },
      ],

      attribute: [{ name: "", text: "" }],
      oldImages: [],
      model: "",
      sku: "",
      filter: "",
      quantity: 0,
      outOfStock: false,
      requireShipping: false,
      status: true,
      manufacturer: "",
      category: "",
      vendor: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      getStores: [],
      getAttributes: [],

      subCategories: [],
      usersRetreive: [],
      upc: "",
      sortOrder: 0,
      weight: 0,
      weigthClass: "kilogram",
      lengthClass: "inch",
      salteEndDate: "",
      DataAvailability: "",
      width: 0,
      height: 0,
      length: 0,
      location: "",
      outOfStockStatus: "",
      pictures: [],
      RelatedProduct: "",
    };
    this.onDrop = this.onDrop.bind(this);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    this._isMounted = true;
    const res = await this.props.retreive("5f7af83ff85cbb002486e9b2");
    //  const res = resData.data;
    console.log(res);
    const response = await this.props.getStores();
    const attribute = await this.props.getAttribute();
    console.log(attribute);
    const weightClass = await this.props.getWeightClass();
    const lengthClass = await this.props.getLengthClass();

    this.setState({ getAttributes: attribute.data });

    this.setState({ getWeightClass: weightClass.data });
    this.setState({ getLengthClass: lengthClass.data });

    console.log(attribute.data);
    console.log(response);
    this.setState({ getStores: response.data });
    console.log(this.state.getStores);

    const mainCat = await this.props.subCategories();

    this.setState({ subCategories: mainCat });

    const ven = await this.props.usersRetreive();
    console.log(ven);
    this.setState({ usersRetreive: ven.data });

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
    const metaKeywordDataConvert = [];
    const seoKeywordDataConvert = [];
    const TagsConvert = [];

    res.seoKeyword &&
      res.seoKeyword.forEach((element) => {
        seoKeywordDataConvert.push({ value: element, label: element });
      });

    res.metaTagKeyword &&
      res.metaTagKeyword.map((_element) => {
        metaKeywordDataConvert.push({ value: _element, label: _element });
      });
    if (Array.isArray(res.tag)) {
      res.tag &&
        res.tag.map((_element) => {
          TagsConvert.push({ value: _element, label: _element });
        });
    } else {
      TagsConvert.push({ value: res.tag, label: res.tag });
    }

    const convertedAttributes = [];
    res.attribute.map((item) => {
      convertedAttributes.push({ name: item.attributeId, text: item.text });
    });
    this.setState({
      title: res.title,
      brand: res.brand,
      minimumQuantity: res.minimumQuantity,
      modelNumber: res.modelNumber,
      price: res.price,
      model: res.model,
      sku: res.sku,
      outOfStock: res.outOfStock,
      requireShipping: res.requireShipping,
      status: res.status,
      category: res.category,
      metaDescription: res.metaTagDescription,
      upc: res.upc,
      sortOrder: res.sortOrder,
      length: res.length,
      weigthClass: res.weigthClass,
      lengthClass: res.lengthClass,
      enableSale: res.enableSale,
      saleDiscount: res.saleDiscount,
      weight: res.weight,
      width: res.width,
      height: res.height,
      salteEndDate: res.salteEndDate
        ? moment(res.salteEndDate).toDate()
        : moment().toDate(),
      metaKeyword: metaKeywordDataConvert,
      seoKeyword: seoKeywordDataConvert,
      tag: TagsConvert,
      description: res.description,
      oldImages: [
        "https://via.placeholder.com/200",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/400",
        "https://via.placeholder.com/500",
      ],
      // requireShipping: res.requireShipping,
      // weigthClass: res.weightClassId,
      // lengthClass: res.lengthClassId,
      quantity: res.quantity,
      attribute: convertedAttributes,
      // seoKeyword: seoKeywordDataConvert,
    });

    console.log(convertedAttributes);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      title,
      modelNumber,
      MetaTagTitle,
      metaDescription,
      metaKeyword,
      tag,
      model,
      sku,
      upc,
      location,
      price,
      quantity,
      minimumQuantity,
      outOfStock,
      dateAvailibity,
      length,
      width,
      height,
      lengthClass,
      weigthClass,
      weight,
      status,
      sortOrder,
      brand,
      category,
      filter,
      RelatedProduct,
      attribute,
      discountInfo,
      pictures,
      seoKeyword,
      weightClass,
      requireShipping,
    } = this.state;
    console.log(this.state);
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", this.props.textEditor.textEditor);
    formData.append("MetaTagTitle", MetaTagTitle);
    formData.append("metaTagDescription", metaDescription);

    for (let i = 0; i < tag.length; i++) {
      formData.append("tag", JSON.stringify(tag[i]));
    }

    for (let i = 0; i < metaKeyword.length; i++) {
      formData.append("metaTagKeyword", JSON.stringify(metaKeyword[i]));
    }
    formData.append("modelNumber", modelNumber);
    formData.append("brand", brand);
    formData.append("minimumQuantity", minimumQuantity);
    formData.append("price", price);

    for (var i = 0; i < attribute.length; i++) {
      if (attribute.length === 1) {
        const data = [];
        data.push(JSON.stringify(attribute[0]));
        formData.append("specification", data);
      } else {
        formData.append("specification", JSON.stringify(attribute[i]));
      }
    }

    for (let i = 0; i < pictures.length; i++) {
      formData.append("images", pictures[i]);
    }
    formData.append("model", model);

    console.log(pictures);

    formData.append("sku", sku);
    formData.append("upc", upc);
    formData.append("quantity", quantity);
    formData.append("outOfStock", outOfStock);
    formData.append("requireShipping", requireShipping);
    formData.append("status", status);
    formData.append("manufacturer", brand);
    formData.append("category", category);
    formData.append("sortOrder", sortOrder == null ? sortOrder : 1);
    formData.append("weight", weight);
    formData.append("weightClassId", weigthClass);
    formData.append("lengthClassId", lengthClass);

    formData.append("width", width);
    formData.append("height", height);
    formData.append("length", length);
    formData.append("dimensionAndWeight", "dummy value");
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const res = await this.props.create(formData);
    console.log(res);
    //my commented out attribute

    // formData.append("location", location);
    // formData.append("dateAvailibity", dateAvailibity);
    // formData.append("width", width);
    // formData.append("height", height);
    // formData.append("length", length);
    // formData.append("lengthClass", lengthClass);
    // formData.append("weigthClass", weigthClass);

    // formData.append("filter", filter);
    // formData.append("RelatedProduct", RelatedProduct);

    // formData.append("RelatedProduct", RelatedProduct);
    // formData.append("seoKeyword", seoKeyword);
    // for (var value of formData.values()) {
    //   console.log(value);
    //   }

    //    formData.append("minimumQuantity", minimumQuantity);
    //   // formData.append("attribute", attribute);
    //   for (var i = 0; i < attribute.length; i++) {
    //     formData.append("attribute", JSON.stringify(attribute[i]));
    //   }
    //   formData.append("modelNumber", modelNumber);
    //   formData.append("model", model);
    //   formData.append("price", price);

    //   // formData.append("specification", specification);
    //   for (var i = 0; i < specification.length; i++) {
    //     formData.append("specification", JSON.stringify(specification[i]));
    //   }
    //   formData.append("tag", tag);
    //   // formData.append("weightAndDimension", weightAndDimension);
    //   for (var i = 0; i < weightAndDimension.length; i++) {
    //     formData.append(
    //       "weightAndDimension",
    //       JSON.stringify(weightAndDimension[i])
    //     );
    //   }
    //   formData.append("sku", sku);
    //   formData.append("upc", upc);
    //   formData.append("sortOrder", sortOrder == null ? sortOrder : 1);
    //   formData.append("quantity", quantity);
    //   formData.append("outOfStock", outOfStock);
    //   formData.append("requireShipping", requireShipping);
    //   formData.append("status", status);
    //   formData.append("manufacturer", brand);
    //   formData.append("width", width);
    //   formData.append("height", height);
    //   formData.append("length", length);

    //   formData.append("vendor", brand);
    //   formData.append("metaTagTitle", MetaTagTitle);
    //   formData.append("metaTagDescription", metaDescription);
    //   formData.append("metaTagKeyword", metaKeyword);
    //   formData.append("seoKeyword", seoKeyword);
    //   formData.append("lengthClass", lengthClass);
    //   formData.append("weight", weight);
    //   formData.append("weigthClass", weigthClass);
    //   formData.append("description", description);
    //   formData.append("saleDiscount", saleDiscount);
    //   formData.append("salteEndDate", salteEndDate);
    //   // for (var i = 0; i < category.length; i++) {
    //   //   formData.append("category", category[i]);
    //   // }
    //   formData.append("category", category);
    //   if (ind === 0) {
    //     formData.append("slug", slug);
    //   } else {
    //     var slg = slug + ind;
    //     formData.append("slug", slg);
    //   }

    //   ind++;
    //   res = await this.props.create(formData);
    //   // }, counter * 1500);
    //   // });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: true,
        image: "",
        getWeightClass: [],
        getLengthClass: [],
        description: "",
        title: "",
        slug: "",
        MetaTagTitle: "",
        brand: "",
        modelNumber: "",
        price: 0,
        minimumQuantity: "",
        weightAndDimension: [{ name: "", value: "" }],
        specification: [{ name: "", value: "" }],
        tag: [],

        discountInfo: [
          {
            customerGroup: "",
            quantity: 0,
            priority: "",
            price: 0,
            startDate: "",
            endDate: "",
          },
        ],
        specialInfo: [
          {
            customerGroup: "",
            priority: "",
            price: 0,
            startDate: "",
            endDate: "",
          },
        ],

        attribute: [{ name: "", text: "" }],
        oldImages: [],
        model: "",
        sku: "",
        filter: "",
        quantity: 0,
        outOfStock: false,
        requireShipping: false,
        status: true,
        manufacturer: "",
        category: "",
        vendor: "",
        metaTag: [],
        metaDescription: "",
        metaKeyword: [],
        seoKeyword: [],
        getStores: [],
        getAttributes: [],
        subCategories: [],
        usersRetreive: [],
        upc: "",
        sortOrder: 0,
        weight: 0,
        weigthClass: "kilogram",
        lengthClass: "inch",
        salteEndDate: "",
        DataAvailability: "",
        width: 0,
        height: 0,
        length: 0,
        location: "",
        outOfStockStatus: "",
        pictures: [],
        RelatedProduct: "",
      });
      toast(`${res.data}`, { containerId: "B" });
    } else {
      toast(`Please Select Image`, { containerId: "B" });
    }
  };

  // _handleImageChange(e) {
  //   e.preventDefault();

  //   let file = Array.from(e.target.files);

  //   file.forEach((element, index) => {
  //     let reader = new FileReader();
  //     let f = e.target.files[index];

  //     reader.onloadend = () => {
  //       this.setState({ images: [...this.state.images, f] });
  //     };

  //     reader.readAsDataURL(f);
  //   });
  // }

  // // weight and dimensions
  // handleWeightAndDimensionNameChange = (idx) => (evt) => {
  //   const newWeightAndDimension = this.state.weightAndDimension.map(
  //     (weightAndDimension, sidx) => {
  //       if (idx !== sidx) return weightAndDimension;
  //       return { ...weightAndDimension, name: evt.target.value };
  //     }
  //   );

  //   this.setState({ weightAndDimension: newWeightAndDimension });
  // };
  // handleWeightAndDimensionValueChange = (idx) => (evt) => {
  //   const newWeightAndDimension = this.state.weightAndDimension.map(
  //     (weightAndDimension, sidx) => {
  //       if (idx !== sidx) return weightAndDimension;
  //       return { ...weightAndDimension, value: evt.target.value };
  //     }
  //   );

  //   this.setState({ weightAndDimension: newWeightAndDimension });
  // };

  // handleAddWeightAndDimension = () => {
  //   this.setState({
  //     weightAndDimension: this.state.weightAndDimension.concat([
  //       { name: "", value: "" },
  //     ]),
  //   });
  // };

  // handleRemoveWeightAndDimension = (idx) => () => {
  //   this.setState({
  //     weightAndDimension: this.state.weightAndDimension.filter(
  //       (s, sidx) => idx !== sidx
  //     ),
  //   });
  // };

  // //specifications
  // handleSpecificationNameChange = (idx) => (evt) => {
  //   console.log("anme", idx, evt);
  //   const newSpecification = this.state.specification.map(
  //     (specification, sidx) => {
  //       if (idx !== sidx) return specification;
  //       return { ...specification, name: evt.value };
  //     }
  //   );

  //   this.setState({ specification: newSpecification });
  // };
  // handleSpecificationValueChange = (idx) => (evt) => {
  //   const newSpecification = this.state.specification.map(
  //     (specification, sidx) => {
  //       if (idx !== sidx) return specification;
  //       return { ...specification, value: evt.target.value };
  //     }
  //   );

  //   this.setState({ specification: newSpecification });
  // };

  // handleAddSpecification = () => {
  //   this.setState({
  //     specification: this.state.specification.concat([{ name: "", value: "" }]),
  //   });
  // };

  // handleRemoveSpecification = (idx) => () => {
  //   this.setState({
  //     specification: this.state.specification.filter((s, sidx) => idx !== sidx),
  //   });
  // };

  // //attribues
  // handleAttributeColorChange = (idx) => (evt) => {
  //   const newAttribute = this.state.attribute.map((attribute, sidx) => {
  //     if (idx !== sidx) return attribute;
  //     return { ...attribute, color: evt.target.value };
  //   });

  //   this.setState({ attribute: newAttribute });
  // };

  // handleAttributeSizeChange = (idx) => (evt) => {
  //   const newAttribute = this.state.attribute.map((attribute, sidx) => {
  //     if (idx !== sidx) return attribute;
  //     return { ...attribute, size: evt.target.value };
  //   });

  //   this.setState({ attribute: newAttribute });
  // };
  // handleAttributePriceChange = (idx) => (evt) => {
  //   const newAttribute = this.state.attribute.map((attribute, sidx) => {
  //     if (idx !== sidx) return attribute;
  //     return { ...attribute, price: evt.target.value };
  //   });

  //   this.setState({ attribute: newAttribute });
  // };
  // handleAttributeImageChange = (idx) => {
  //   setTimeout(() => {
  //     const newAttribute = this.state.attribute.map((attribute, sidx) => {
  //       console.log(sidx, idx);
  //       if (idx !== sidx) return attribute;

  //       console.log(this.state.images.length);
  //       return { ...attribute, image: this.state.images.length - 1 };
  //     });
  //     this.setState({ attribute: newAttribute });
  //   }, 1000);
  // };
  // handleAddAttribute = () => {
  //   this.setState({
  //     attribute: this.state.attribute.concat([
  //       { color: "", size: "", price: "", image: "" },
  //     ]),
  //   });
  // };

  // handleRemoveAttribute = (idx) => () => {
  //   this.setState({
  //     attribute: this.state.attribute.filter((s, sidx) => idx !== sidx),
  //   });
  // };
  //on image upload
  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  //change in value attribute click event
  handleAttributeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.attribute];
    list[index][name] = value;
    this.setState({ attribute: list });
  };

  // handle attribute remove click event
  handleAttributeRemove = (index) => {
    const list = [...this.state.attribute];
    list.splice(index, 1);
    this.setState({ attribute: list });
  };

  // handle attribute add
  handleAttributeAdd = () => {
    console.log(this.state.attribute);

    this.setState({
      attribute: [...this.state.attribute, { name: "", text: "" }],
    });
  };

  //change in value discount click event
  handleDiscountInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.discountInfo];
    list[index][name] = value;
    this.setState({ discountInfo: list });
  };
  handleStartDate = (date, index) => {
    const list = [...this.state.discountInfo];
    list[index]["startDate"] = date;
    this.setState({ discountInfo: list });
  };
  handleEndDate = (date, index) => {
    const list = [...this.state.discountInfo];
    list[index]["endDate"] = date;
    this.setState({ discountInfo: list });
  };

  // handle discount remove click event
  handleDiscountRemove = (index) => {
    const list = [...this.state.discountInfo];
    list.splice(index, 1);
    this.setState({ discountInfo: list });
  };

  // handle attribute add
  handleDiscountAdd = () => {
    this.setState({
      discountInfo: [
        ...this.state.discountInfo,
        {
          customerGroup: "",
          quantity: 0,
          priority: "",
          price: 0,
          startDate: "",
          endDate: "",
        },
      ],
    });
  };

  //change in value Special click event
  handleSpecialInfo = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.specialInfo];
    list[index][name] = value;
    this.setState({ specialInfo: list });
  };
  handleSpecialStartDate = (date, index) => {
    const list = [...this.state.specialInfo];
    list[index]["startDate"] = date;
    this.setState({ specialInfo: list });
  };
  handleSpecialEndDate = (date, index) => {
    const list = [...this.state.specialInfo];
    list[index]["endDate"] = date;
    this.setState({ specialInfo: list });
  };

  // handle Special remove click event
  handleSpecialRemove = (index) => {
    const list = [...this.state.specialInfo];
    list.splice(index, 1);
    this.setState({ specialInfo: list });
  };

  // handle attribute add
  handleSpecialAdd = () => {
    this.setState({
      specialInfo: [
        ...this.state.specialInfo,
        {
          customerGroup: "",
          priority: "",
          price: 0,
          startDate: "",
          endDate: "",
        },
      ],
    });
  };

  changedateAvailibity = (date) => {
    this.setState({
      dateAvailibity: date,
    });
  };

  // handleCreate = (e) => {
  //   console.log(e);
  //   this.setState({ isLoading: true });
  //   console.group("Option created");
  //   console.log("Wait a moment...");
  //   setTimeout(() => {
  //     const { options } = this.state;
  //     const newOption = createOption(e);
  //     console.log(newOption);
  //     console.groupEnd();
  //     this.setState({
  //       isLoading: false,
  //       options: [...options, newOption],
  //       value: newOption,
  //     });
  //   }, 1000);
  // };
  metaKeywordJsx = () => (
    <CreatableSelect
      placeholder="Enter Tag Keywords"
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push(item.value);
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: [],
            });
      }}
    />
  );

  seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push(item.value);
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: [],
            });
      }}
    />
  );

  productTagsJsx = () => (
    <CreatableSelect
      placeholder="Product Tags"
      isMulti
      defaultValue={this.state.tag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push(item.value);
          });

        data.length > 0
          ? this.setState({
              tag: data,
            })
          : this.setState({
              tag: [],
            });
      }}
    />
  );

  removeImagesItem = (index) => {
    const removeData = [];
    removeData.push(this.state.oldImages[index]);
    this.setState({
      updatedImages: [...this.state.updatedImages, ...removeData],
    });
    const data = this.state.oldImages;
    data.splice(index, 1);
    this.setState({ oldImages: data });
  };

  render() {
    const {
      title,
      brand,
      modelNumber,
      price,
      slug,
      weightAndDimension,
      specification,
      tag,
      minimumQuantity,
      attribute,
      oldImages,
      model,
      sku,
      quantity,
      outOfStock,
      requireShipping,
      dimensionAndWeight,
      status,
      manufacturer,
      category,
      vendor,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
      description,
      upc,
      sortOrder,
      lengthClass,
      weight,
      weigthClass,
      subCategories,
      salteEndDate,
      dateAvailibity,
      width,
      height,
      length,
      MetaTagTitle,
      location,
      outOfStockStatus,
      discountInfo,
      specialInfo,
      filter,
      RelatedProduct,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    const colourOptions = [];
    subCategories.forEach((element) => {
      if (element.children.length !== 0) {
        element.children.forEach((elements) => {
          var obj = {};
          obj.value = elements._id;
          obj.label = elements.name;
          obj.color = "#00B8d9";
          obj.isFixed = true;
          colourOptions.push(obj);
        });
        let obj = {};
        obj.value = element._id;
        obj.label = element.name;
        obj.color = "#00B8d9";
        obj.isFixed = true;
        colourOptions.push(obj);
      } else {
        let obj = {};
        obj.value = element._id;
        obj.label = element.name;
        obj.color = "#00B8d9";
        obj.isFixed = true;
        colourOptions.push(obj);
      }
    });
    // console.log("cate", colourOptions);

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create Product </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Product
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Alert />

            <Tabs defaultActiveKey="General" id="uncontrolled-tab-example">
              <Tab eventKey="General" title="General">
                <div className="container-fluid">
                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span> Product Name
                      </label>
                    </div>
                    <div className="col-md-10">
                      <FormControl
                        type="text"
                        className="form-control   "
                        id="title"
                        placeholder="Product Name"
                        name="title"
                        value={title}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Description
                      </label>
                    </div>
                    <div className="col-md-10">
                      {!this.state.loading && (
                        <EditorConvertUpdateToHTML htmlValue={description} />
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span>Meta Tag Title
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control   "
                        id="MetaTagTitle"
                        placeholder="Meta Tag Title"
                        name="MetaTagTitle"
                        value={MetaTagTitle}
                        onChange={(e) =>
                          this.setState({ MetaTagTitle: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label  bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Meta Tag Description
                      </label>
                    </div>
                    <div className="col-md-10">
                      <textarea
                        className="form-control"
                        placeholder="Meta Tag Description"
                        id="metaDescription"
                        name="metaDescription"
                        rows={4}
                        value={metaDescription}
                        onChange={(e) =>
                          this.setState({
                            metaDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Meta Tag Keywords
                      </label>
                    </div>
                    <div className="col-md-10">
                      {!this.state.loading && this.metaKeywordJsx()}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label bold-text labelColor "
                        style={{ marginLeft: "12px" }}
                      >
                        Product Tags
                      </label>
                    </div>
                    <div className="col-md-10">
                      {!this.state.loading && this.productTagsJsx()}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Data" title="Data">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        <span className="red">*</span>Model
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="model"
                        placeholder="Model"
                        name="model"
                        value={model}
                        onChange={(e) =>
                          this.setState({ model: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label text-right bold-text labelColor">
                        Model Number
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="modelNumber"
                        placeholder="Model Number"
                        name="modelNumber"
                        value={modelNumber}
                        onChange={(e) =>
                          this.setState({ modelNumber: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        SKU
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="sku"
                        placeholder="SKU"
                        name="sku"
                        value={sku}
                        onChange={(e) => this.setState({ sku: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        UPC
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        step="1"
                        min="1"
                        className="form-control"
                        id="upc"
                        placeholder="upc"
                        name="upc"
                        value={upc}
                        onChange={(e) => this.setState({ upc: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Location
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Location"
                        name="location"
                        value={location}
                        onChange={(e) =>
                          this.setState({ location: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Price
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        step="0.10"
                        min="0"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        name="price"
                        value={price}
                        onChange={(e) =>
                          this.setState({ price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Quantity
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        id="quantity"
                        placeholder="Quantity"
                        name="quantity"
                        value={quantity}
                        onChange={(e) =>
                          this.setState({ quantity: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Minimum Quantity
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        id="minimumQuantity"
                        placeholder="Minimum quantity"
                        name="minimumQuantity"
                        value={minimumQuantity}
                        onChange={(e) =>
                          this.setState({
                            minimumQuantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Subtract Stock
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="outOfStock"
                        className="form-control"
                        value={outOfStock && outOfStock}
                        onChange={(e) => {
                          this.setState({
                            outOfStock: e.target.value,
                          });
                        }}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Out of Stock Status
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="outOfStockStatus"
                        className="form-control"
                        value={outOfStockStatus}
                        onChange={(e) => {
                          this.setState({
                            outOfStockStatus: e.target.value,
                          });
                        }}
                      >
                        <option value="2-3 days">2-3 days</option>
                        <option value="In Stock">In Stock</option>
                        <option value="Out of Stock">Out of Stock</option>
                        <option value="Pre Order">Pre Order</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label text-right bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Require Shipping
                      </label>
                    </div>
                    <div className="col-md-10 my-auto ">
                      <input
                        className="mr-2"
                        id="YesShipping"
                        value="true"
                        name="radio"
                        type="radio"
                        checked={
                          requireShipping
                            ? "checked"
                            : requireShipping === "true"
                        }
                        onChange={(e) => {
                          this.setState({
                            requireShipping: e.target.value,
                          });
                        }}
                      />
                      <label for="YesShipping">Yes</label>
                      <input
                        className="ml-2 mr-2"
                        value="false"
                        id="NoShipping"
                        name="radio"
                        type="radio"
                        checked={
                          requireShipping
                            ? requireShipping === "false"
                            : "checked"
                        }
                        onChange={(e) => {
                          this.setState({
                            requireShipping: e.target.value,
                          });
                        }}
                      />{" "}
                      <label for="NoShipping">No</label>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Date Availability
                      </label>
                    </div>
                    <div className="col-md-5">
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateAvailibity}
                        onChange={this.changedateAvailibity}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label className=" col-form-label   bold-text labelColor">
                        Dimension (L x W x H)
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="number"
                        className="form-control"
                        id="length"
                        placeholder="Length"
                        name="length"
                        value={length}
                        onChange={(e) =>
                          this.setState({ length: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        id="width"
                        placeholder="Width"
                        name="width"
                        value={width}
                        onChange={(e) =>
                          this.setState({ width: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        id="height"
                        placeholder="Height"
                        name="height"
                        value={height}
                        onChange={(e) =>
                          this.setState({ height: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Weight Class
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="weigthClass"
                        className="form-control"
                        value={weigthClass && weigthClass}
                        onChange={(e) => {
                          this.setState({ weigthClass: e.target.value });
                        }}
                      >
                        <option value="">Choose</option>
                        {this.state.getWeightClass &&
                          this.state.getWeightClass.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Length Class
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="weigthClass"
                        className="form-control"
                        value={lengthClass && lengthClass}
                        onChange={(e) => {
                          this.setState({ lengthClass: e.target.value });
                        }}
                      >
                        <option value="">Choose</option>
                        {this.state.getLengthClass &&
                          this.state.getLengthClass.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Weight
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        className="form-control"
                        id="weight"
                        placeholder="Model Number"
                        name="weight"
                        value={weight}
                        onChange={(e) =>
                          this.setState({ weight: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Status
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        name="status"
                        className="form-control"
                        value={status && status}
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                        }}
                      >
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Sort Order
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="number"
                        step="1"
                        min="1"
                        className="form-control"
                        id="sortOrder"
                        placeholder="sortOrder"
                        name="sortOrder"
                        value={sortOrder ? sortOrder : 1}
                        onChange={(e) =>
                          this.setState({ sortOrder: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Links" title="Links">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Brand
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="brand"
                        value={brand}
                        onChange={(e) => {
                          this.setState({ brand: e.target.value });
                        }}
                      >
                        <option>choose</option>
                        {this.state.getStores &&
                          this.state.getStores.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.firstName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Category
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="category"
                        onChange={(e) => {
                          this.setState({ category: e.target.value });
                        }}
                        value={category}
                      >
                        <option>choose</option>
                        {this.state.subCategories &&
                          this.state.subCategories.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Filters
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="form-control form-control-sm"
                        id="filter"
                        onChange={(e) => {
                          this.setState({ filter: e.target.value });
                        }}
                        value={filter}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Stores
                      </label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-control-sm"
                        id="brand"
                        onChange={(e) => {
                          this.setState({ brand: e.target.value });
                        }}
                        value={brand}
                      >
                        <option>choose</option>
                        {this.state.getStores &&
                          this.state.getStores.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.firstName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Related Products
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="form-control form-control-sm"
                        id="RelatedProduct"
                        onChange={(e) => {
                          this.setState({
                            RelatedProduct: e.target.value,
                          });
                        }}
                        value={RelatedProduct}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Attributes" title="Attributes">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Attribute
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label
                        className=" col-form-label   bold-text labelColor"
                        style={{ marginLeft: "12px" }}
                      >
                        Text
                      </label>
                    </div>
                  </div>
                </div>
                {attribute.map((x, i) => (
                  <div className="container-fluid mt-2" key={i}>
                    <div className="row">
                      <div className="col-md-4">
                        <select
                          name="name"
                          className="form-control"
                          value={x.name}
                          onChange={(e) => this.handleAttributeChange(e, i)}
                          placeholder="enter attribute name"
                        >
                          <option value="">choose</option>
                          {this.state.getAttributes &&
                            this.state.getAttributes.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <textarea
                          className="form-control"
                          name="text"
                          value={x.text}
                          rows="4"
                          onChange={(e) => this.handleAttributeChange(e, i)}
                          placeholder="enter text"
                        />
                      </div>
                      <div className="col-md-2 ">
                        <div className="d-flex">
                          {attribute.length !== 1 && (
                            <button
                              type="button"
                              className="minusBtn actionBtn "
                              onClick={() => this.handleAttributeRemove(i)}
                            >
                              <div class="icon-minus actionBtn-icon minus">
                                {" "}
                              </div>
                            </button>
                          )}
                          {attribute.length - 1 === i && (
                            <button
                              type="button"
                              className="plusBtn actionBtn ml-2"
                              onClick={this.handleAttributeAdd}
                            >
                              <div className="icon-plus actionBtn-icon plus">
                                {" "}
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Tab>

              <Tab eventKey="Discount" title="Discount">
                <div className="d-flex flex-column-custom align-center">
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Customer Group
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Quantity
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Priority
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Price
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date Start
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date End
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Add/Remove
                    </label>
                  </div>
                </div>
                {discountInfo.map((x, i) => {
                  return (
                    <div className="container-fluid mt-2" key={i}>
                      <div className="d-flex flex-column-custom align-center">
                        <div className="custom-col-1">
                          <select
                            name="customerGroup"
                            className="form-control"
                            value={x.customerGroup}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="customer group"
                          >
                            <option value="default">Default</option>
                          </select>
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="quantity"
                            value={x.quantity}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter quantity"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="priority"
                            value={x.priority}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="price"
                            value={x.price}
                            onChange={(e) => this.handleDiscountInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.startDate}
                            onChange={(date) => this.handleStartDate(date, i)}
                            name="startDate"
                            placeholder="Enter Start Date"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.endDate}
                            name="endDate"
                            onChange={(date) => this.handleEndDate(date, i)}
                            placeholder="Enter End Date"
                          />
                        </div>
                        <div className="custom-col-1 ">
                          <div className="d-flex">
                            {discountInfo.length !== 1 && (
                              <button
                                type="button"
                                className="minusBtn actionBtn "
                                onClick={() => this.handleDiscountRemove(i)}
                              >
                                <div className="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {discountInfo.length - 1 === i && (
                              <button
                                type="button"
                                className="plusBtn actionBtn ml-2"
                                onClick={this.handleDiscountAdd}
                              >
                                <div className="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab eventKey="Special" title="Special">
                <div className="d-flex flex-column-custom align-center">
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Customer Group
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      priority
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Price
                    </label>
                  </div>

                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date Start
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Date End
                    </label>
                  </div>
                  <div className="custom-col-1">
                    <label className=" col-form-label   bold-text labelColor">
                      Add/Remove
                    </label>
                  </div>
                </div>
                {specialInfo.map((x, i) => {
                  return (
                    <div className="container-fluid mt-2">
                      <div className="d-flex flex-column-custom align-center">
                        <div className="custom-col-1">
                          <select
                            name="customerGroup"
                            className="form-control"
                            value={x.customerGroup}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="customer group"
                          >
                            <option value="default">Default</option>
                          </select>
                        </div>

                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="priority"
                            value={x.priority}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <input
                            className="form-control"
                            name="price"
                            value={x.price}
                            onChange={(e) => this.handleSpecialInfo(e, i)}
                            placeholder="enter priority"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.startDate}
                            onChange={(date) =>
                              this.handleSpecialStartDate(date, i)
                            }
                            name="SpecialstartDate"
                            placeholder="Enter Start Date"
                          />
                        </div>
                        <div className="custom-col-1">
                          <DatePicker
                            className="form-control"
                            selected={x.endDate}
                            name="SpecialendDate"
                            onChange={(date) =>
                              this.handleSpecialEndDate(date, i)
                            }
                            placeholder="Enter End Date"
                          />
                        </div>
                        <div className="custom-col-1 ">
                          <div className="d-flex">
                            {specialInfo.length !== 1 && (
                              <button
                                type="button"
                                className="minusBtn actionBtn "
                                onClick={() => this.handleSpecialRemove(i)}
                              >
                                <div className="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {specialInfo.length - 1 === i && (
                              <button
                                type="button"
                                className="plusBtn actionBtn ml-2"
                                onClick={this.handleSpecialAdd}
                              >
                                <div className="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab eventKey="Images" title="Images">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col"></div>
                    <div className="col-md-10">
                      <div className="row">
                        {this.state.oldImages.map((img, i) => (
                          <div className="col-md-3 text-center">
                            <img src={img} alt="" className="img-fluid" />
                            <button
                              type="button"
                              onClick={() => this.removeImagesItem(i)}
                              className="btn btn-danger mt-2"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>

                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={this.onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={false}
                        withPreview={true}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="SEO" title="SEO">
                <div className="row mt-4">
                  <div className="col-md-2">
                    <label
                      className=" col-form-label   bold-text labelColor"
                      style={{ marginLeft: "12px" }}
                    >
                      Stores
                    </label>
                  </div>
                  <div className="col-md-10">
                    {!this.state.loading && this.seoKeywordJsx()}
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col-md-8 text-center">
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col"></div>
                </div>
              </Tab>
            </Tabs>
            {/* <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Add a new Product</h4>

                      <Alert />

                      <form className="forms-sample">
                        <div className="form-group">
                      
                        </div>
                        <div className="form-group">
                          <label htmlFor="Slug">Slug</label>
                          <input
                            type="text"
                            className="form-control"
                            id="slug"
                            placeholder="Slug"
                            name="slug"
                            value={slug}
                            onChange={(e) =>
                              this.setState({ slug: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>Images</label>

                          <div className="input-group col-xs-12">
                            <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            rows={4}
                            value={description}
                            onChange={(e) =>
                              this.setState({ description: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="brand">Select Brand</label>
                          <select
                            className="form-control form-control-sm"
                            id="brand"
                            onChange={(e) => {
                              this.setState({ brand: e.target.value });
                            }}
                          >
                            <option>choose</option>
                            {this.state.getStores &&
                              this.state.getStores.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.firstName}
                                </option>
                              ))}
                          </select>
                        </div>
                       
                        <div className="form-group">
                          <label htmlFor="price">Price</label>
                          <input
                            type="number"
                            step="0.10"
                            min="0"
                            className="form-control"
                            id="price"
                            placeholder="Price"
                            name="price"
                            value={price}
                            onChange={(e) =>
                              this.setState({ price: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="price">Specifications</label>
                          {this.state.specification.map(
                            (specification, idx) => (
                              <div className="row" style={{ marginBottom: 5 }}>
                                <div className="col-11">
                                  <div
                                    className="row"
                                    style={{ marginLeft: 0 }}
                                  >
                                    <CreatableSelect
                                      onChange={this.handleSpecificationNameChange(
                                        idx
                                      )}
                                      value={createOption(specification.name)}
                                      className="col-5"
                                      onCreateOption={(e) => {
                                        this.handleCreate(e);
                                        this.handleSpecificationNameChange(idx);
                                      }}
                                      options={this.state.options}
                                    />

                                    <input
                                      type="text"
                                      placeholder="Value"
                                      value={specification.value}
                                      className="form-control col-5"
                                      onChange={this.handleSpecificationValueChange(
                                        idx
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-1">
                                  <button
                                    type="button"
                                    onClick={this.handleRemoveSpecification(
                                      idx
                                    )}
                                    className="btn btn-danger"
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                          <button
                            type="button"
                            onClick={this.handleAddSpecification}
                            className="small btn btn-primary"
                          >
                            Add New Specification
                          </button>
                        </div>
                        <div className="form-group">
                          <label htmlFor="price">Weight & Dimension</label>
                          {this.state.weightAndDimension.map(
                            (weightAndDimension, idx) => (
                              <div className="row" style={{ marginBottom: 5 }}>
                                <div className="col-11">
                                  <div
                                    className="row"
                                    style={{ marginLeft: 0 }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      value={weightAndDimension.name}
                                      className="form-control col-5"
                                      style={{ marginRight: 10 }}
                                      onChange={this.handleWeightAndDimensionNameChange(
                                        idx
                                      )}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Value"
                                      value={weightAndDimension.value}
                                      className="form-control col-5"
                                      onChange={this.handleWeightAndDimensionValueChange(
                                        idx
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-1">
                                  <button
                                    type="button"
                                    onClick={this.handleRemoveWeightAndDimension(
                                      idx
                                    )}
                                    className="btn btn-danger"
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                          <button
                            type="button"
                            onClick={this.handleAddWeightAndDimension}
                            className="small btn btn-primary"
                          >
                            Add Weight And Dimension
                          </button>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Meta Keywords">Product Tags</label>

                          <CreatableSelect
                            isMulti
                            defaultValue={[]}
                            onChange={(e) => {
                              const data = [];
                              e &&
                                e.map((item, i) => {
                                  return data.push(item.value);
                                });

                              data.length > 0
                                ? this.setState({
                                    tag: data,
                                  })
                                : this.setState({
                                    tag: [],
                                  });
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="model">Model </label>
                          <input
                            type="text"
                            className="form-control"
                            id="model"
                            placeholder="Model"
                            name="model"
                            value={model}
                            onChange={(e) =>
                              this.setState({ model: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="sku">SKU </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sku"
                            placeholder="SKU"
                            name="sku"
                            value={sku}
                            onChange={(e) =>
                              this.setState({ sku: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            id="quantity"
                            placeholder="Quantity"
                            name="quantity"
                            value={quantity}
                            onChange={(e) =>
                              this.setState({ quantity: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="quantity">Minimum Quantity </label>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            id="minimumQuantity"
                            placeholder="Minimum quantity"
                            name="minimumQuantity"
                            value={minimumQuantity}
                            onChange={(e) =>
                              this.setState({ minimumQuantity: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group ">
                          <label htmlFor="outOfStock">Out Of Stock</label>

                          <select
                            name="outOfStock"
                            className="form-control"
                            value={outOfStock && outOfStock}
                            onChange={(e) => {
                              this.setState({
                                outOfStock: e.target.value,
                              });
                            }}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="requireShipping">
                            Required Shipping
                          </label>
                          <select
                            name="requireShipping"
                            className="form-control"
                            value={requireShipping && requireShipping}
                            onChange={(e) => {
                              this.setState({
                                requireShipping: e.target.value,
                              });
                            }}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="status">Status</label>

                          <select
                            name="status"
                            className="form-control"
                            value={status && status}
                            onChange={(e) => {
                              this.setState({ status: e.target.value });
                            }}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="weigthClass">Weight Class</label>

                          <select
                            name="weigthClass"
                            className="form-control"
                            value={weigthClass && weigthClass}
                            onChange={(e) => {
                              this.setState({ weigthClass: e.target.value });
                            }}
                          >
                            <option value="kilogram">Kilogram</option>
                            <option value="gram">Gram</option>
                            <option value="pound">Pound</option>
                            <option value="ounce">Ounce</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="weight">Weight</label>
                          <input
                            type="number"
                            className="form-control"
                            id="weight"
                            placeholder="Model Number"
                            name="weight"
                            value={weight}
                            onChange={(e) =>
                              this.setState({ weight: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="length">Length</label>
                          <input
                            type="number"
                            className="form-control"
                            id="length"
                            placeholder="Length"
                            name="length"
                            value={length}
                            onChange={(e) =>
                              this.setState({ length: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="width">width</label>
                          <input
                            type="number"
                            className="form-control"
                            id="width"
                            placeholder="Width"
                            name="width"
                            value={width}
                            onChange={(e) =>
                              this.setState({ width: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="height">Height</label>
                          <input
                            type="number"
                            className="form-control"
                            id="height"
                            placeholder="Height"
                            name="height"
                            value={height}
                            onChange={(e) =>
                              this.setState({ height: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lengthClass">length Class</label>

                          <select
                            name="lengthClass"
                            className="form-control"
                            value={lengthClass && lengthClass}
                            onChange={(e) => {
                              this.setState({ lengthClass: e.target.value });
                            }}
                          >
                            <option value="centimeter">Centimeter</option>
                            <option value="inch">Inch</option>
                          </select>
                        </div>

                        <div className="form-group">
                          <label htmlFor="brand">Select Category</label>
                          <select
                            className="form-control form-control-sm"
                            id="category"
                            onChange={(e) => {
                              this.setState({ category: e.target.value });
                            }}
                            value={category}
                          >
                            <option>choose</option>
                            {this.state.subCategories &&
                              this.state.subCategories.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Meta Tags">Meta Tags</label>

                          <CreatableSelect
                            isMulti
                            defaultValue={[]}
                            onChange={(e) => {
                              const data = [];
                              e &&
                                e.map((item, i) => {
                                  return data.push(item.value);
                                });

                              data.length > 0
                                ? this.setState({
                                    metaTag: data,
                                  })
                                : this.setState({
                                    metaTag: [],
                                  });
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="Meta Keywords">Meta Keywords</label>

                          <CreatableSelect
                            isMulti
                            defaultValue={[]}
                            onChange={(e) => {
                              const data = [];
                              e &&
                                e.map((item, i) => {
                                  return data.push(item.value);
                                });

                              data.length > 0
                                ? this.setState({
                                    metaKeyword: data,
                                  })
                                : this.setState({
                                    metaKeyword: [],
                                  });
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="meta description">
                            Meta Description
                          </label>
                          <textarea
                            className="form-control"
                            id="metaDescription"
                            name="metaDescription"
                            rows={4}
                            value={metaDescription}
                            onChange={(e) =>
                              this.setState({ metaDescription: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="seo keywords">Seo Keywords</label>

                          <CreatableSelect
                            isMulti
                            defaultValue={[]}
                            onChange={(e) => {
                              const data = [];
                              e &&
                                e.map((item, i) => {
                                  return data.push(item.value);
                                });

                              data.length > 0
                                ? this.setState({
                                    seoKeyword: data,
                                  })
                                : this.setState({
                                    seoKeyword: [],
                                  });
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="price">Sort Order</label>
                          <input
                            type="number"
                            step="1"
                            min="1"
                            className="form-control"
                            id="sortOrder"
                            placeholder="sortOrder"
                            name="sortOrder"
                            value={sortOrder ? sortOrder : 1}
                            onChange={(e) =>
                              this.setState({ sortOrder: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="price">UPC</label>
                          <input
                            type="text"
                            step="1"
                            min="1"
                            className="form-control"
                            id="upc"
                            placeholder="upc"
                            name="upc"
                            value={upc}
                            onChange={(e) =>
                              this.setState({ upc: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group ">
                          <label htmlFor="enableSale">Enable Sale</label>
                          <select
                            name="enableSale"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                enableSale: e.target.value,
                              });
                            }}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                        </div>
                        <div className="form-group  ">
                          <label className="col-sm-3 col-form-label">
                            Discount Type
                          </label>
                          <div className="col-sm-9">
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                value="fixed"
                                className="btn btn-outline-secondary"
                              >
                                Fixed
                              </button>
                              <button
                                type="button"
                                value="percentage"
                                className="btn btn-outline-secondary"
                              >
                                Percentage
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="saleDiscount">
                            Sale Discount {"(in percentage %)"}
                          </label>
                          <input
                            type="number"
                            step="1"
                            min="1"
                            className="form-control"
                            id="saleDiscount"
                            placeholder="Sale Discount"
                            name="saleDiscount"
                            value={saleDiscount}
                            onChange={(e) =>
                              this.setState({ saleDiscount: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="salteEndDate">Sale End Date</label>

                          <DatePicker
                            className="form-control"
                            selected={this.state.salteEndDate}
                            onChange={this.handleChange}
                          />
                        </div>
                        <button
                          onClick={() => {
                            this._submitHandler();
                          }}
                          type="button"
                          className="btn btn-primary mr-2"
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            this.props.history.push("/admin/products");
                          }}
                        >
                          Cancel
                        </button>
                      </form>
                    </div>
                  </div> */}
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

subCategories.propTypes = {
  subCategories: PropTypes.func.isRequired,
};
usersRetreive.propTypes = {
  usersRetreive: PropTypes.func.isRequired,
};

getWeightClass.propTypes = {
  getWeightClass: PropTypes.func.isRequired,
};
getLengthClass.propTypes = {
  getLengthClass: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    textEditor: state.textEditor,
  };
};

export default connect(mapStateToProps, {
  retreive,
  edit,
  getAttribute,
  getStores,
  usersRetreive,
  subCategories,
  getWeightClass,
  getLengthClass,
})(editProduct);
