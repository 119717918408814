import React from "react";
import DataRow from "./components/DataRow";

const EditVendorNew = () => {
  return (
    <>
      <div className=" d-flex justify-content-start align-center gap-20">
        <h4 className=" font-weight-bold">Vendor Settings</h4>
        <button className=" btn btn-success ">Update Vendor</button>
      </div>

      <div className=" row my-5 gap-25 ">
        <div className=" col p-0 bg-white border-grey">
          <div>
            <div className=" p-3 font-weight-bold">Generals Inforamtion</div>
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />{" "}
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />{" "}
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />{" "}
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />{" "}
            <hr className=" m-0" />
            <DataRow name={"Vendor Name"} value={"Mather fathshdsh"} />
          </div>
        </div>
        <div className="col bg-white border-grey">Return Plocicy</div>
      </div>
    </>
  );
};

export default EditVendorNew;
