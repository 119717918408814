import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import "./filters.css";
import PropTypes from "prop-types";
import { createFilterGrouping } from "../../../actions/filters";

import { ToastContainer, toast } from "react-toastify";

import Alert from "../../../components/alert/alert.component";

class FilterGroupingCreate extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: false,
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  textToggler = () => {
    this.setState({
      status: !this.state.status,
    });
  };

  _submitHandler = async () => {
    const { title, status } = this.state;

    const dataToSend = { title, status };
    console.log(dataToSend);
    const res = await this.props.createFilterGrouping(
      JSON.stringify(dataToSend)
    );
    console.log(res);

    this.setState({ loading: false });

    if (res) {
      this.setState({});
      toast(`${res.data.status ? "Record Created" : ""}`, { containerId: "B" });
    } else {
      toast(`Some Error Occur`, { containerId: "B" });
    }
  };

  render() {
    const { title, status } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create Filter Grouping</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Filter Grouping{" "}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Alert />
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-md-3">
                  <p>Title</p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control   "
                    id="title"
                    placeholder="Enter Title"
                    name="title"
                    value={title}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 ">
                  <div className="connection-all" onClick={this.textToggler}>
                    <span className={status ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span className={status ? "editRight" : "editcenter "}>
                      OFF
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      this._submitHandler();
                    }}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

createFilterGrouping.propTypes = {
  createFilterGrouping: PropTypes.func.isRequired,
};

export default connect(null, {
  createFilterGrouping,
})(FilterGroupingCreate);
