import React from "react";
import InfoItem from "./info-item";

const CardInfoItems = ({
  itemPrice,
  discountId,
  parentDiscountId,
  discountValue,
  sundialDKP,
  marginDollar,
  marginPrevent,
}) => {
  return (
    <div>
      <InfoItem crawlType="Item Price:" CrawlDescription={`${itemPrice}`} />
      <InfoItem crawlType="Discount ID:" CrawlDescription={`${discountId}`} />
      <InfoItem
        crawlType="Parent Discount ID:"
        CrawlDescription={`${parentDiscountId}`}
      />
      <InfoItem
        crawlType="Discount Value:"
        CrawlDescription={`${discountValue}`}
      />
      <div className="mb-4"></div>
      <InfoItem
        styles={{ fontWeight: "bold" }}
        crawlType="Sundial DKP:"
        CrawlDescription={`${sundialDKP}`}
      />
      <InfoItem
        styles={{ fontWeight: "bold" }}
        crawlType="Margin Dollar:"
        CrawlDescription={`${marginDollar}`}
      />
      <InfoItem
        styles={{ fontWeight: "bold" }}
        crawlType="Margin Pervent:"
        CrawlDescription={`${marginPrevent}`}
      />
    </div>
  );
};

export default CardInfoItems;
