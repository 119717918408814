import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NotesUpdate } from "../../../../actions/saleAnalysis";

const AddNewNotes = ({ SelectItem, setEdit , setAllOrders }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [ShowAddNote, setShowAddNote] = useState(false);
  const [formData, setformData] = useState({
    subject: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setloading(true);
    try {
      const res = await dispatch(
        NotesUpdate(SelectItem._id, {
          note: {
            subject: formData.subject,
            message: formData.message,
            read: false,
          },
        })
      );

      if (res?.orderItem) {
        setloading(false);
        setEdit({
          open: false,
          edit: "",
          title: "",
        });
        setAllOrders((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item?._id === res?.orderItem?._id) {
              return {
                ...item,
                notes: res?.orderItem?.notes
              };
            }
            return item;
          });
          return updatedData;
        });
      }
    } catch (error) {
      setloading(false);
    }
  };
  return (
    <div className="px-3">
      {!ShowAddNote && (
        <div className="my-4" onClick={() => setShowAddNote(!ShowAddNote)}>
          <button className="add-button">+ Add Note</button>
        </div>
      )}
      {ShowAddNote && (
        <div className="system-genrated-shipping">
          <div className=" d-flex justify-content-between align-center ">
            <h6>+ Add Note</h6>
            <h6
              onClick={() => setShowAddNote(false)}
              className="cancel-new-notes-btn"
            >
              Cancel New Note
            </h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className=" mt-3">
              <select
                className="add-button"
                name="subject"
                value={formData.subject}
                required
                onChange={(e) =>
                  setformData({ ...formData, [e.target.name]: e.target.value })
                }
              >
                <option value="">Select Subject</option>
                <option value="Costs">Costs</option>
                <option value="Refunds">Refunds</option>
                <option value="Shipping & Tracking">Shipping & Tracking</option>
                <option value="Order Status">Order Status</option>
                <option value="Claim">Claim</option>
                <option value="General">General</option>
                <option value="Custome">Custome</option>
              </select>

              <textarea
                className="add-button mt-3"
                required
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={(e) =>
                  setformData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              <div className=" mt-3 ">
                <button type="submit" className="update-Order-button">
                  {loading ? "Loading..." : "Send Notes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddNewNotes;
