import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_SUCCESS,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
} from "../actions/types";

const initialState = {
  token:
    localStorage.getItem("token") === undefined
      ? null
      : localStorage.getItem("token"),
  isAuth: null,
  loading: true,
  user: null,
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuth: null,
        loading: false,
      };

    case REGISTER_FAIL:
      return {
        ...state,
        token: null,
        isAuth: null,
        loading: false,
        user: null,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuth: true,
        loading: false,
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        user: payload,
        isAuth: true,
        loading: false,
      };

    case LOGIN_FAIL:
    case AUTH_FAIL:
    case LOGOUT_SUCCESS:
    case FORGOT_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuth: false,
        loading: false,
        user: null,
      };

    case FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default auth