import React from "react";

const getStyle = (type) => {
  switch (type) {
    case "success":
      return {
        background: "#d1eede",
        color: "#17a858",
        border: "1px solid #17a858 ",
      };
    case "error":
      return {
        background: "#fee9eb",
        color: "#fb2a3d",
        border: "1px solid #fb2a3d ",
      };

    default:
      return {
        background: "#fafafa",
        color: "#bebebe",
        border: "1px solid #bebebe ",
      };
  }
};

const ActionButton = ({ text, type, className }) => {
  return (
    <div style={getStyle(type)} className={`action-button  ${className}`}>
      {text}
    </div>
  );
};

export default ActionButton;
