import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/communityInfluencer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

import { Editor } from "@tinymce/tinymce-react";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      description: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { title, description } = this.state;

    this.setState({ loading: true });

    const res = await this.props.create({ title, description });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
        title: "",
        description: "",
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const { title, description } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Create Community Influencer{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/slides">Community Influencer </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Community Influencer
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Add a new Community Influencer{" "}
                </h4>

                <Alert />

                <form className="forms-sample m-2">
                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="exampleInputName1"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="description">
                      Description
                    </label>

                    <Editor
                      apiKey="lzrznnnwd57r5ycnxvdah9p73u2co5gxa5raikdqa6o49lbg"
                      initialValue={description}
                      init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e) => {
                        this.setState({
                          description: e.target.getContent(),
                        });
                      }}
                    />
                  </div>

                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to="/admin/create-community-influencer">
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
