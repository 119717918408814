import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableLoader from "../../../../components/TableSkelton/TableLoader";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import style from "./review-summary.module.css";
import { createSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../../../config/default";

const ReviewSummary = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [reviewSummary, setReviewSummary] = useState({});
  const [pagePagination, setPagination] = useState({
    currentPage: 1,
  });

  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/pricing",
      search: `?${createSearchParams({
        page: value,
      })}`,
    };
    navigate(options, { replace: true });
    setPagination({ ...pagePagination, currentPage: value });
  };

  const getReviewSummary = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiURL}admin/review-summary`);
      setReviewSummary(response?.data);
    } catch (error) {
      console.log("error is : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReviewSummary();
  }, []);

  const handleStarsReview = (numberOfStars) => {
    navigate(`/admin/manage-reviews?rating=${numberOfStars}`);
  };

  return (
    <>
      <div className="d-flex justify-content-start align-center gap-8">
        <div className={style.reviewCount}>
          Total Number of Reviews: <br />
          {reviewSummary?.totalRatings}
        </div>
        <div className={style.reviewCount}>
          Average Star Rating: <br />
          {reviewSummary?.ratingsAvg}
        </div>
      </div>
      {loading ? (
        // <TopBarProgress />
        <TableLoader rowsNum={20} cellNum={57} />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Review Summary"
              //   subTitle="Review"
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Rating</th>
                      <th>Number of Reviews</th>
                      <th>Percentage</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewSummary?.stats?.length ? (
                      <>
                        {reviewSummary?.stats?.map((item, i) => (
                          <tr key={item?._id ?? i}>
                            <td>{`${item?.title} Stars:`}</td>
                            <td>{item?.count}</td>
                            <td>{item?.percentage}</td>

                            <td>
                              <CustomeTableBtn
                                color={`not-sent`}
                                width={"220"}
                                className="font-size-sm"
                                onClick={() => handleStarsReview(item?.title)}
                                styles={{ padding: ".6rem 0" }}
                              >
                                {i === 5
                                  ? `View ${item?.title}`
                                  : `View ${item?.title} Stars Reviews`}
                              </CustomeTableBtn>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {/* {Edit.edit === "reviews" && (
                  <PriceCardDrawer
                    Edit={Edit}
                    setEdit={setEdit}
                    shippingId="22"
                  >
                    <ReviewSidebar />
                  </PriceCardDrawer>
                )} */}

                <div className="d-flex justify-content-center align-center p-2">
                  <div className="mx-auto">
                    <Pagination
                      count={pagePagination.totalPages}
                      page={pagePagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {reviewSummary?.stats?.length} of {pagePagination?.count}{" "}
                    Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ReviewSummary;
