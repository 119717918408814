import React, { useState } from "react";
import { useEffect } from "react";
import { RiArrowDownSFill } from "react-icons/ri";

const SelectSearchFilter = ({
  onChange,
  placeholder,
  name,
  className,
  style,
  value,
  title,
  options,
  width,
}) => {
  const [data, setdata] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value ?? null);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (value) => {
    selectedItem === value ? setSelectedItem(null) : setSelectedItem(value);
    onChange(value);
    setOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(options)) {
      setdata([{ value: "", label: "All" }, ...options]);
    }
    if (value) {
      setSelectedItem(value);
    }

    return () => {
      setdata([]);
    };
  }, [options, value]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ minWidth: width, ...style }} className="select-dropdown">
          <div
            className="select-dropdown-header d-flex justify-content-between"
            onClick={toggleDropdown}
          >
            <div>
              <p className=" font-weight-bold">{title}</p>
              <p className="select-value">
                {selectedItem ? selectedItem : placeholder}
              </p>
            </div>

            <RiArrowDownSFill
              className={`${isOpen ? "icon" : ""}`}
              style={{ fontSize: "25px" }}
            />
          </div>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`dropdown-body ${isOpen && "open"}`}
          >
            {data.map((item, i) => (
              <div
                key={i}
                value={item.value}
                className="select-dropdown-item"
                onClick={() => handleItemClick(item.value)}
                id={item.value}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSearchFilter;
