import React, { useState } from "react";
import SelectSearchFilter from "../../../../components/CustomeFilter/SelectSearchFilter";
import { useDispatch } from "react-redux";
import { getAddFAQ } from "../../../../actions/faqs";

const AddNewConversations = ({ isRender, setIsRender, setEdit }) => {
  const dispatch = useDispatch();

  const [Data, setData] = useState({
    topic: "",
    creatorName: "",
    referenceTitle: "",
    referenceLink: "",
    question: "",
  });
  const [topicError, settopicError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Data?.topic !== "") {
      settopicError(false);

      try {
        const res = await dispatch(getAddFAQ(Data));

        if (res) {
          setIsRender(!isRender);
          setEdit({
            open: false,
            edit: "",
            title: "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      settopicError(true);
    }
  };
  return (
    <div>
      <div className="p-3">
        <form onSubmit={handleSubmit} className=" d-flex flex-column gap-10">
          {topicError && <p className=" text-danger">Select Topic</p>}
          <SelectSearchFilter
            title={"Select Topic*"}
            value={Data?.topic}
            placeholder="Select Topic"
            options={[
              { value: "Buyers Remorse", label: "Buyers Remorse" },
              { value: "Damage & Defective", label: "Damage & Defective" },
              { value: "Cancelled Order", label: "Cancelled Order" },
              {
                value: "Tracking Number Issue",
                label: "Tracking Number Issue",
              },
              { value: "Status Update", label: "Status Update" },
              { value: "Product Inquiry", label: "Product Inquiry" },

              { value: "Others", label: "Others" },
            ]}
            onChange={(value) => setData({ ...Data, topic: value })}
          />
          <input
            className=" border-grey p-3 border-radius-5 bg-half-white"
            type="text"
            placeholder="Enter Creator Name*"
            required
            name="creatorName"
            value={Data?.creatorName}
            onChange={(e) =>
              setData((pre) => {
                return {
                  ...pre,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />
          <input
            className=" border-grey p-3 border-radius-5 bg-half-white"
            type="text"
            placeholder="Enter Reference Title"
            name="referenceTitle"
            value={Data?.referenceTitle}
            onChange={(e) =>
              setData((pre) => {
                return {
                  ...pre,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />{" "}
          <input
            className=" border-grey p-3 border-radius-5 bg-half-white"
            type="text"
            placeholder="Enter Reference URL Link"
            name="referenceLink"
            value={Data?.referenceLink}
            onChange={(e) =>
              setData((pre) => {
                return {
                  ...pre,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />{" "}
          <textarea
            className=" border-grey p-3 border-radius-5 bg-half-white"
            type="text"
            placeholder="Enter  Question*"
            required
            rows={8}
            name="question"
            value={Data?.question}
            onChange={(e) =>
              setData((pre) => {
                return {
                  ...pre,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />
          <div className=" w-100 mt-3  ">
            <button
              type="submit"
              className="  w-100 p-3 border-radius-10  btn btn-success"
            >
              Create New Conversation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewConversations;
