import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getStores, disableStore, deleteStore } from "../../../actions/store";
import TopBarProgress from "react-topbar-progress-indicator";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class Store extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stores: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 10,
      deleteId: null,
    };
  }

  async componentDidMount() {
    const res = await this.props.getStores();
    this.setState({ stores: res.data, loading: false });
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { stores, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(stores.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteStore = async (id) => {
    this.setState({ loading: true });
    const data = {
      userId: id,
    };
    const _res = await this.props.deleteStore(data);
    if (_res) {
      toast(`Vendor deleted`, { containerId: "B" });
    }
    const res = await this.props.getStores();
    this.setState({ loading: false, deleteConfirm: false, stores: res.data });
  };

  _approveStore = async (id) => {
    this.setState({ loading: true });
    const res = await this.props.disableStore({ userId: id });
    if (res) {
      toast(`${res}`, { containerId: "B" });
    }
    const _res = await this.props.getStores();
    this.setState({ loading: false, deleteConfirm: false, stores: _res.data });
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { loading, stores, currentPage, dataPerPage, deleteId } = this.state;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData =
      stores && stores.slice(indexOfFirstData, indexOfLastData);

    const renderTodos =
      currentData &&
      currentData.map((item, index) => {
        return (
          <tr key={index}>
            <td> {index + 1} </td>
            <td> {item.firstName} </td>
            <td>{item.email}</td>
            <td> {item.userName} </td>
            <td>
              {" "}
              <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>{" "}
            </td>
            <td>
              <Link to={`/admin/edit-store/?id=${item._id}`}>
              <button
                type="button"
                className="btn btn-primary btn-sm mr-4"
                style={{ width: 78 }}

              >
                Edit
              </button>
              </Link>

              <button
                type="button"
                className="btn btn-success btn-sm mr-4"
                style={{ width: 86 }}
                onClick={() => {
                  this._approveStore(item._id);
                }}
                disabled={item.isVendor ? false : true}
              >
                Disabled
              </button>

              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ deleteId: item._id, deleteConfirm: true });
                }}
              >
                Delete
              </button>

              {this.state.deleteConfirm && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  title="Are you sure?"
                  onConfirm={() => {
                    this._deleteStore(this.state.deleteId);
                  }}
                  onCancel={this._onCancel}
                  focusCancelBtn
                >
                  Are you sure you want to delete?
                </SweetAlert>
              )}
            </td>
          </tr>
        );
      });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(stores && stores.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });

    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {stores && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View Stores </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/stores">Stores</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Stores List</h4>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Owner Name </th>
                          <th> Email </th>
                          <th> Username</th>
                          <th> Created At </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stores && stores.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No store found</td>
                          </tr>
                        ) : (
                          renderTodos
                        )}
                      </tbody>
                    </table>
                    <div
                      style={{
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {stores.length > 0 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.previous}
                              >
                                Previous
                              </button>
                            </li>

                            {renderPageNumbers}
                            <li className="page-item">
                              <button
                                className="page-link"
                                type="button"
                                onClick={this.next}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

disableStore.propTypes = {
  disableStore: PropTypes.func.isRequired,
};

deleteStore.propTypes = {
  deleteStore: PropTypes.func.isRequired,
};

export default connect(null, { getStores, disableStore, deleteStore })(Store);
