/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
import React from "react";
import { Link } from "react-router-dom";
// import CSVReader from "react-csv-reader";
import TopBarProgress from "react-topbar-progress-indicator";
import { getAllStores } from "../../../actions/store";

import * as XLSX from "xlsx";
import ProgressBar from "react-bootstrap/ProgressBar";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  bulkProducts,
  bulkProductsUpload,
  etlErrorLogs,
} from "../../../actions/product";
import { subCategoryNames } from "../../../actions/subCategory";
import { childSubCategoryNames } from "../../../actions/childSubCategory";
import { ToastContainer, toast } from "react-toastify";
import { getAttribute } from "../../../actions/product";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import axios from "axios";
import { apiURL } from "../../../config/default";
import { uploadBulkPricing } from "../../../services/products/product.service";
import CustomInput from "./custom-input/custom-input";
// const handleForce = (data, fileInfo) => console.log(data, fileInfo);
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class BulkInventory extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      handleDarkSideForce: "",
      subCategories: "",
      childCategories: "",
      buttonChange: "disabled",
      count: 0,
      getAllStores: [],
      brand: "",
      image: "",
      attributes: [],
      attributeNotFound: [],
      isInventoryFile: true,
      enableButton: false,
      dataUploading: false,
      mainImage: false,
      additionalImages: false,
      pricing: false,
      shipping: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({
      enableButton: true,
    });
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
        this.fileData();
      }
    }, 1000);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const brandIsSelected = this.state.brand !== "";
    const fileIsSelected =
      prevState.data !== this.state.data || this.state.data !== "";
    if (fileIsSelected && brandIsSelected && this.state.enableButton === true) {
      this.setState({
        enableButton: false,
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  attributeIdGetter = (name) => {
    // eslint-disable-next-line no-unused-vars
    let nn = name;
    if (
      name.includes("Style") &&
      name !== "Revers Side Style" &&
      name !== "Handle Style"
    ) {
      name = name.substring(0, 5);
    }
    if (name.length < 9 && name.includes("Finish")) {
      name = name.substring(0, 6);
    }
    if (
      name.includes("Material Type") &&
      name !== "Material Type Front" &&
      name !== "Material Type Back"
    ) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    if (name.includes("Item Pattern")) {
      let size = name.length;
      name = name.substring(0, size - 1);
    }
    var id = this.state.attributes.find(function (o) {
      return o.name === name.trim();
    });
    if (id && id._id) {
      return id._id;
    } else {
      this.setState({
        attributeNotFound: [...this.state.attributeNotFound, name],
      });
    }
  };
  attributeNameGetter = (attributeId) => {
    var attributeName = this.state.attributes.find(function (o) {
      return o._id === attributeId.trim();
    });
    if (attributeName && attributeName.name) {
      return attributeName.name;
    }
  };

  fileData = async () => {
    this.setState({ buttonChange: "disabled" });
    const response = await this.props.getAllStores();

    // const _res = await this.props.subCategoryNames();
    // const __res = await this.props.mainCategories();
    // const ___res = await this.props.childSubCategoryNames();
    // const attribute = await this.props.getAttribute();

    if (response) {
      this.setState({
        // subCategories: _res,
        // attributes: attribute.data,
        getAllStores: response.data,
        buttonChange: "",
      });
    }
  };

  handleFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        blankrows: false,
        defval: null,
        range: 0,
      });

      this.setState({
        buttonChange: "",
        data: data,
        cols: make_cols(ws["!ref"]),
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }

  getEtlInventory = async (productItems) => {
    try {
      const res = await axios.post(`${apiURL}update-inventory`, {
        ...productItems,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  handlePricingFile = async (data) => {
    console.log(data, "data");
    if (!data?.length) {
      toast.error("Something went wrong");
    }
    let dataTosend = [];
    if (this.state.pricing) {
      const filteredArr = data?.map((item) => {
        console.log("pricing", item);
        const {
          cost,
          promo_cost: promoCost,
          cost_start_date: promoCostStartDate,
          cost_end_date: promoCostEndDate,
          msrp,
          sale_price: salePrice,
          price_type: priceType,
          promo_price: promoPrice,
          price_start_date: promoPriceStartDate,
          price_end_date: promoPriceEndDate,
          shipping_fee: shippingFee,
          dropship_fee_1: dropshipFeeOne,
          dropship_fee_2: dropshipFeeTwo,
          product_type: productType,
          lead_time: timeLead,
          item_model: modelNumber,
        } = item;
        return {
          brand: this.state.brand,
          cost,
          promoCost,
          promoCostStartDate,
          promoCostEndDate,
          msrp,
          salePrice,
          priceType,
          promoPrice,
          promoPriceStartDate,
          promoPriceEndDate,
          shippingFee,
          dropshipFeeOne,
          dropshipFeeTwo,
          productType,
          timeLead,
          modelNumber,
        };
      });
      dataTosend = [...filteredArr];
      console.log(dataTosend, "dataToSend");
      console.log(filteredArr, "filteredArr");
    }

    let shippingData = [];
    if (this.state.shipping) {
      const shippingfilterArr = data?.map((product_item) => {
        let shippingObj = {};
        if (
          product_item.use_shipping !== null &&
          product_item.use_shipping !== ""
        ) {
          shippingObj.sundialShipping =
            product_item.use_shipping === "Yes" ? true : false;
        }
        let numberOfBoxes = 1;
        if (
          product_item.number_of_boxes !== null &&
          product_item.number_of_boxes !== ""
        ) {
          shippingObj.numberOfBoxes = product_item.number_of_boxes;
          numberOfBoxes = product_item.number_of_boxes;
        }
        if (
          product_item.freight_class !== null &&
          product_item.freight_class !== ""
        ) {
          shippingObj.freightClass = product_item.freight_class;
        }

        let dimensions = [];
        let dimensionsForBoxId = [];
        for (let index = 1; index <= numberOfBoxes; index++) {
          let dimensionObjt = {};
          //   let test = eval("index");
          let packageLenght = eval(
            ("product_item.package_length_" + index).toString()
          );
          let packageHeight = eval(
            ("product_item.package_height_" + index).toString()
          );
          let packageWidth = eval(
            ("product_item.package_width_" + index).toString()
          );
          let packageWeight = eval(
            ("product_item.package_weight_" + index).toString()
          );

          let packageVolume = eval(
            ("product_item.package_volume_" + index).toString()
          );
          if (packageLenght && packageLenght !== null && packageLenght !== "") {
            dimensionObjt.length = Number(packageLenght);
            dimensionObjt.height = Number(packageHeight);
            dimensionObjt.width = Number(packageWidth);
            dimensionObjt.weight = Number(packageWeight);
            if (
              packageVolume &&
              packageVolume !== null &&
              packageVolume !== ""
            ) {
              dimensionObjt.volume = Number(packageVolume);
            }
            if (Object.keys(dimensionObjt).length > 0) {
              dimensions.push(dimensionObjt);
              delete dimensionObjt.volume;
              dimensionsForBoxId.push(dimensionObjt);
            }
          }
        }
        shippingObj.dimensions = dimensions;

        let boxId = this.getString(dimensionsForBoxId);

        shippingObj.boxId = boxId;
        return {
          shipping: shippingObj,
          brand: this.state.brand,
          modelNumber: product_item.item_model,
        };
      });
      shippingData = [...shippingfilterArr];
    }
    // mainImage
    let mainImageToSend = [];
    if (this.state.mainImage) {
      const mainImageFiltred = data?.map((row) => {
        const { main_image: mainImage, item_model: modelNumber } = row;
        return {
          mainImage,
          modelNumber,
          brand: this.state.brand,
        };
      });
      mainImageToSend = [...mainImageFiltred];
    }

    // additional Images
    let additionalImagesToSend = [];
    const cleanArray = (arr) => arr.filter((a) => a);

    if (this.state.additionalImages) {
      const additionalImagesFiltered = data?.map((row) => {
        const {
          additional_image_1: additionalImage1,
          additional_image_2: additionalImage2,
          additional_image_3: additionalImage3,
          additional_image_4: additionalImage4,
          additional_image_5: additionalImage5,
          additional_image_6: additionalImage6,
          additional_image_7: additionalImage7,
          additional_image_8: additionalImage8,
          additional_image_9: additionalImage9,
          additional_image_10: additionalImage10,
          additional_image_11: additionalImage11,
          main_image: mainImage,
          item_model: modelNumber,
        } = row;
        return {
          addtitionalImagesLinks: cleanArray([
            additionalImage1,

            additionalImage2,

            additionalImage3,

            additionalImage4,

            additionalImage5,

            additionalImage6,

            additionalImage7,

            additionalImage8,

            additionalImage9,

            additionalImage10,

            additionalImage11,
          ]),
          mainImage,

          modelNumber,

          brand: this.state.brand,
        };
      });
      additionalImagesToSend = [...additionalImagesFiltered];
    }

    let len = data.length;
    // eslint-disable-next-line no-unused-vars
    let data1 = { data: dataTosend };
    for (let index1 = 0; index1 < Math.ceil(len / 500); index1++) {
      const thousandJson = this.state.pricing ? dataTosend.splice(0, 500) : [];
      const shippingThousandJson = this.state.shipping
        ? shippingData.splice(0, 500)
        : [];
      const mainImageThousandJson = this.state.mainImage
        ? mainImageToSend.splice(0, 500)
        : [];
      const additionalImagesThousandJson = this.state.additionalImages
        ? additionalImagesToSend.splice(0, 500)
        : [];
      this.setState({ dataUploading: true });
      try {
        const res = await uploadBulkPricing({
          pricing: thousandJson,
          shipping: shippingThousandJson,
          mainImage: mainImageThousandJson,
          additionalImages: additionalImagesThousandJson,
        });

        if (res.status === 200) {
          this.setState({ count: this.state.count + 1 });
          this.setState({ dataUploading: false });
        }
      } catch (err) {
        this.setState({ dataUploading: false });
      }
    }
  };

  handleInventoryFile = async (data) => {
    this.setState({
      buttonChange: "disabled",
      loading: true,
      dataUploading: true,
    });

    // for (let index1 = 0; index1 < data.length; index1++) {
    //   let product_item = data[index1];
    //   counter++;
    //   if (product_item.actual_inventory !== "Actual Inventory") {
    //     this.setState({ loading: true });
    //     await this.getEtlInventory(product_item, this.state.brand);
    //   }
    //   this.setState({ count: counter });
    // }
    let dataTosend = [];
    const filteredArr = data?.map((item) => {
      const {
        model: modelNumber,
        actual_inventory: quantity,
        stock_status: outOfStock,
        discontinued,
        product_status: status,
      } = item;
      if (status === "Disable" || status === "Enable") {
        return {
          brand: this.state.brand,
          modelNumber,
          quantity,
          status: status === "Disable" ? false : true,
          outOfStock: outOfStock === "In Stock" ? false : true,
          productAvailability: {
            status: discontinued === "Yes" ? "Discontinued" : "Available",
          },
        };
      }
    });
    dataTosend = [...filteredArr];

    let len = data.length;
    for (let index1 = 0; index1 < Math.ceil(len / 50); index1++) {
      const thousandJson = dataTosend.splice(0, 50);

      this.setState({ dataUploading: true, loading: true });
      try {
        const res = await this.getEtlInventory({
          inventory: thousandJson,
        });

        if (res.status === 200) {
          let counter = this.state.count + thousandJson.length;
          console.log(res.status, counter);
          this.setState({ count: counter });

          this.setState({ dataUploading: false, loading: false });
        }
      } catch (err) {
        this.setState({ dataUploading: false });
      }
    }
  };

  _submitHandler = async () => {
    // this.fileData();
    const { data, brand, isInventoryFile } = this.state;

    if (brand && brand !== "" && data && data !== "") {
      if (isInventoryFile) {
        this.handleInventoryFile(data);
      } else {
        this.handlePricingFile(data);
        this.setState({ loading: false, buttonChange: "", count: "All" });
      }
    } else {
      toast.error(`Please Add both inputs`, { containerId: "B" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }

  handleRadioChange = (inp) => {
    this.setState({
      isInventoryFile: inp !== "standard",
    });
  };

  getString = (arr) => {
    let sortable;
    let temp = [];

    arr.forEach((item, idx) => {
      const myObj = { ...item };
      delete myObj.weight;

      sortable = Object.entries(myObj)
        .sort(([, a], [, b]) => a - b)
        .reduce((r, [k, v]) => ({ ...r, [k]: Math.ceil(v) }), {});

      sortable = {
        ...sortable,
        weight: Math.ceil(item.weight < 0.01 ? 1 : item.weight),
      };
      temp.push(sortable);
    });

    return this.helperSorter(temp);
  };
  helperSorter(array) {
    let check = array.map((item) => {
      return Object.values(item)
        .map((x) => {
          return x < 1 ? Math.ceil(x) : Math.round(x);
        })
        .join("_");
    });

    return (check = check
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
      .join("-"));
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Bulk inventory{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bulk Inventory
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card p-0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Bulk inventory
                </h4>

                <Alert />

                <form className="forms-sample p-2">
                  <p className="font-weight-bold">1: Select </p>
                  <div className="my-2 d-flex align-items-center border-bottom mb-4">
                    <CustomInput
                      id="bulk"
                      type="radio"
                      checked={this.state.isInventoryFile}
                      name="selectionOfBulkAndPrice"
                      onChange={() => this.handleRadioChange("inventory")}
                      label="Inventory"
                      style={{ fontWeight: "bold" }}
                    />
                    <CustomInput
                      id="price"
                      type="radio"
                      name="selectionOfBulkAndPrice"
                      onChange={() => this.handleRadioChange("standard")}
                      label="Main Standard Sheet"
                      style={{ fontWeight: "bold" }}
                    />

                    {!this.state.isInventoryFile && (
                      <>
                        <CustomInput
                          id="pricing"
                          type="checkbox"
                          name="pricing"
                          onChange={() =>
                            this.setState({ pricing: !this.state.pricing })
                          }
                          label="Pricing"
                          style={{ fontWeight: "normal" }}
                        />
                        {/* <div className="mx-3">
                          <input
                            onChange={() =>
                              this.setState({ pricing: !this.state.pricing })
                            }
                            type="checkbox"
                            id="pricing"
                            name="pricing"
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            className="mx-1"
                            htmlFor="pricing"
                            style={{ cursor: "pointer" }}
                          >
                            Pricing
                          </label>
                        </div> */}

                        <CustomInput
                          id="Shipping"
                          type="checkbox"
                          name="Shipping"
                          onChange={() =>
                            this.setState({ shipping: !this.state.shipping })
                          }
                          label="Shipping"
                        />

                        {/* main Image */}
                        <CustomInput
                          id="mainImage"
                          type="checkbox"
                          name="mainImage"
                          onChange={() =>
                            this.setState({
                              mainImage: !this.state.mainImage,
                            })
                          }
                          label="MainImage"
                        />

                        {/* Additional Images */}
                        <CustomInput
                          id="additionalImages"
                          type="checkbox"
                          name="additionalImages"
                          onChange={() =>
                            this.setState({
                              additionalImages: !this.state.additionalImages,
                            })
                          }
                          label="additionalImages"
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="brand">
                      2: Select Brand
                    </label>
                    <select
                      className="ml-2 form-control form-control-sm"
                      id="brand"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => {
                        this.setState({ brand: e.target.value });
                      }}
                    >
                      <option>choose</option>
                      {this.state.getAllStores &&
                        this.state.getAllStores.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.fullName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <div>
                      <label style={{ fontWeight: "bold" }}>
                        3: CSV File upload
                      </label>
                    </div>

                    <div className="input-group  pl-2 col-xs-12">
                      {/*  <div className="input-group col-xs-12">
                           <input
                              required
                              type="file"
                              multiple
                              className="form-control"
                              onChange={(e) => this._handleImageChange(e)}
                            />
                            </div>*/}
                      <DragDropFile handleFile={this.handleFile}>
                        <div className="row">
                          <div className="col-xs-12">
                            <DataInput handleFile={this.handleFile} />
                          </div>
                        </div>
                      </DragDropFile>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {this.state.loading && this.state.loading === true ? (
                        <div className="text-center">
                          <ProgressBar
                            animated
                            now={this.state.count}
                            max={this.state.data.length}
                          />
                          <h5>
                            you have added {` ${this.state.count} `} products
                            proceeded
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    disabled={
                      this.state.enableButton || this.state.dataUploading
                    }
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className={`btn btn-primary mr-2 ${this.state.buttonChange}`}
                  >
                    {this.state.dataUploading ? "Updating" : "Submit"}
                  </button>
                </form>
                {this.state.attributeNotFound &&
                  this.state.attributeNotFound.map((item, indx) => (
                    <p key={indx}>{item} attribute not found</p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group" style={{ marginLeft: "12px" }}>
          <label style={{ fontWeight: "bold" }} htmlFor="file">
            {" "}
          </label>
          <input
            type="file"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
// childCategories.propTypes = {
//   childCategories: PropTypes.func.isRequired,
// };
subCategoryNames.prototype = {
  subCategoryNames: PropTypes.func.isRequired,
};
// mainCategories.prototype = {
//   mainCategories: PropTypes.func.isRequired,
// };
childSubCategoryNames.prototype = {
  childSubCategoryNames: PropTypes.func.isRequired,
};
bulkProducts.prototype = {
  bulkProducts: PropTypes.func.isRequired,
};
bulkProductsUpload.propTypes = {
  bulkProductsUpload: PropTypes.func.isRequired,
};
getAllStores.propTypes = {
  getAllStores: PropTypes.func.isRequired,
};
getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
etlErrorLogs.propTypes = {
  etlErrorLogs: PropTypes.func.isRequired,
};

export default connect(null, {
  subCategoryNames,
  getAttribute,
  childSubCategoryNames,
  bulkProducts,
  getAllStores,
  bulkProductsUpload,
  etlErrorLogs,
})(BulkInventory);
