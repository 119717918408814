import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { createUser } from "../../../../services/create-user.service";
import TopBarProgress from "react-topbar-progress-indicator";

import { Link } from "react-router-dom";

TopBarProgress.config({
  barThickness: 4,
  barColors: {
    0: "rgb(51, 201, 45, .7)",
    ".3": "rgb(255, 77, 107, .7)",
    "1.0": "rgb(200, 125, 255, .7)",
  },
  shadowBlur: 5,
  shadowColor: "rgba(0, 0, 0, .5)",
});
const roles = [
  "admin",
  "user",
  "techTeam",
  "dataTeam",
  "customerSupport",
  "callCenter",
  "marketing",
  "sales",
];
const CreateUser = () => {
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "12345",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await createUser(values);
      toast.success(res.data.message, { containerId: "B" });
      setLoading(false);
    } catch (err) {
      toast.error(err.data.message, { containerId: "B" });
      setLoading(false);
    }
  };
  const handleChangeRole = (e) => {
    setValues({
      ...values,
      role: e.target.value,
    });
  };
  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}

      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          {" "}
          Create User{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/users">Users</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create User
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div
            className="card p-0"
            style={{
              border: "1px solid #dcdcdc",
            }}
          >
            <div className="card-body p-0">
              <h4
                className="card-title"
                style={{
                  color: "#4c4d5a",
                  borderBottom: "1px solid #dcdcdc",
                  background: "#f6f6f6",
                  textShadow: "0 -1px 0 rgba(50,50,50,0)",
                  padding: 12,
                }}
              >
                Add a new main category
              </h4>
              <form className="forms-sample m-3">
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }} htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="first Name"
                    onChange={handleChange}
                    name="firstName"
                    value={values.firstName}
                  />
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }} htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={handleChange}
                    name="lastName"
                    value={values.lastName}
                  />
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }} htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    onChange={handleChange}
                    name="email"
                    value={values.email}
                  />
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }} htmlFor="password">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    onChange={handleChange}
                    name="password"
                    value={values.password}
                  />
                </div>

                <div className="form-group">
                  <label style={{ fontWeight: "bold" }} htmlFor="role">
                    Role
                  </label>
                  <select
                    onChange={handleChangeRole}
                    name="role"
                    id="role"
                    className="form-control"
                  >
                    {roles?.map((role,index) => (
                      <option key={index}>{role}</option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <form className="forms-sample">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="first Name"
                onChange={handleChange}
                name="firstName"
                value={values.firstName}
              />
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
};

export default CreateUser;
