import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fileSizeUnitConverter } from "../../../../utils/ConvertImagesIntoBase64";
import { CircularProgress } from "@mui/material";
import ImagePreview from "./ImagePreview";
import { SendNewMessage } from "../../../../actions/tickets";

const SendMessage = ({ selectedItem, setisMount, isMount }) => {
  const dispatch = useDispatch();
  const [MessageSendSuccess, setMessageSendSuccess] = useState(false);
  const [MessageValue, setMessageValue] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [images, setimages] = useState([]);
  const [loading, setloading] = useState(false);

  const [error, seterror] = useState({
    status: false,
    msg: "",
  });
  // handle image upload
  const handleImgupload = (e) => {
    const newImages = [...images];
    let newError = { status: false, msg: "" };

    for (let i = 0; i < e.target.files.length; i++) {
      const fileSize = e.target.files[i].size;
      const fileMb = fileSize / 1024 ** 2;
      if (newImages.length < 5) {
        if (fileMb < 5) {
          const size = fileSizeUnitConverter(e.target.files[i]?.size);
          newImages.push({
            file: e.target.files[i],
            name: e.target.files[i].name,
            size: size,
            src: URL.createObjectURL(e.target.files[i]),
          });
        } else {
          newError = {
            status: true,
            msg: "Some images exceed 5 MB in size",
          };
          break; // Exit the loop since an image exceeded the size limit
        }
      } else {
        newError = {
          status: true,
          msg: "Only up to 5 images can be uploaded",
        };
        break; // Exit the loop since maximum images are reached
      }
    }
    setimages(newImages);
    seterror(newError);
  };
  //remove upload image
  const handleRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setimages(newImages);
  };

  //send message when submit
  const handleSubmitResponse = async (e) => {
    e.preventDefault(); // Prevent page reload
    let imagesBase64 = [];

    // Loop through the selected images and compress them
    for (const image of images) {
      const img = new Image();
      img.src = image.src;

      await new Promise((resolve) => {
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 1000;

          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > maxWidth) {
            const scaleFactor = maxWidth / img.width;
            newWidth = img.width * scaleFactor;
            newHeight = img.height * scaleFactor;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const compressedBase64 = canvas.toDataURL("image/jpeg", 0.9);
          imagesBase64.push(compressedBase64);

          resolve();
        };
      });
    }
    try {
      setloading(true);
      const data = {
        ticketId: selectedItem?._id,
        message: MessageValue,
        images: imagesBase64.length ? imagesBase64 : [],
      };
      const res = await dispatch(SendNewMessage(selectedItem?._id, data));
      if (res?.message) {
        setMessageValue("");
        setimages([]);
        setloading(false);
        setMessageSendSuccess(!MessageSendSuccess);
        setisMount(!isMount);
      }
    } catch (error) {
      setloading(false);
    }
  };

  //preiew images chnage
  const handlePreview = (index) => {
    const imageToPreview = images[index];
    setPreviewImage(imageToPreview);
  };

  return (
    <div>
      {!selectedItem?.resolveByOpener && (
        <form
          onSubmit={handleSubmitResponse}
          className="send-response-container"
        >
          <textarea
            required
            placeholder="Being typing your message here"
            name="msg-text"
            id=""
            cols="30"
            rows="5"
            value={MessageValue}
            onChange={(e) => setMessageValue(e.target.value)}
          />
          {error.status && <p style={{ color: "red" }}>{error.msg}</p>}
          <button type="submit" className="response-ticket-btn bg-success">
            {loading ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              "Send Message"
            )}
          </button>
          <label
            htmlFor="attach-media-photo-input"
            className="response-ticket-btn bg-dark text-center"
          >
            Attach Media
          </label>
          <input
            type="file"
            id="attach-media-photo-input"
            name="filename"
            accept="image/*"
            multiple={true}
            onChange={handleImgupload}
          />

          {images?.map((img, index) => (
            <div
              key={index}
              className="d-flex justify-content-start align-center border-radius-10 border-grey px-3 py-2 gap-10 flex-wrap"
            >
              <div
                className=" border-grey border-radius-10 hidden"
                style={{ width: "70px", height: "70px" }}
              >
                <img
                  className="w-100 h-100 object-fit-contain"
                  src={img.src}
                  alt="product"
                  // onClick={() => handleClickOpen(img)}
                />
              </div>

              <div>
                <h6 className="m-0 font-weight-700">
                  {selectedItem?.ticketId} Attachment({index + 1})
                </h6>
                <p
                  className="m-0 c-pointer"
                  onClick={() => handlePreview(index)}
                >
                  Preview
                </p>
                <p
                  className="m-0 c-pointer text-danger"
                  onClick={() => handleRemoveImage(index)}
                >
                  Remove
                </p>
              </div>
            </div>
          ))}
          <>
            {previewImage && (
              <ImagePreview
                image={previewImage?.src}
                onClose={() => setPreviewImage(null)}
              />
            )}
          </>
        </form>
      )}
    </div>
  );
};

export default SendMessage;
