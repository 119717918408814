import React from "react";

const UpdateButton = ({ brand, loading, onClick, position }) => {
  return (
    <div
      style={{
        position: position ? position : "sticky",
        bottom: "0px",
        margin: "0px auto",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "35px 0px",
        boxShadow: "2px 2px 10px",
        maxWidth: "450px",
      }}
      className=" bg-white px-3 "
    >
      <button className=" btn btn-success w-100" onClick={onClick}>
        {loading ? "Laoding..." : `Update ${brand}`}
      </button>
    </div>
  );
};

export default UpdateButton;
