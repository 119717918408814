import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { editCompany, getSignleComapny } from "../../../../actions/shipping";
import TopBarProgress from "react-topbar-progress-indicator";

const EditShippingCompany = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setloading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    link: "",
    instructions: "",
    enable: true,
    partner: false,
    show: false,
    isSmall: false,
  });

  const getSingleComapny = useCallback(async () => {
    const res = await dispatch(getSignleComapny(id));
    setloading(false);
    setFormData({ ...res, isSmall: false });
  }, [dispatch, id]);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe && id) {
      getSingleComapny(id);
    }
    return () => {
      isSubscribe = false;
    };
  }, [getSingleComapny, id]);

  function handleInputChange(event) {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (name === "shippingType") {
      setFormData((prevData) => ({
        ...prevData,
        isSmall: value === "small",
      }));
    } else {
      const fieldValue = type === "checkbox" ? checked : value;
      setFormData((prevData) => ({
        ...prevData,
        [name]: fieldValue,
      }));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.name && formData.link) {
      const res = await dispatch(editCompany(id, formData));
      if (res) {
        navigate("/admin/shipping/companies-lists");
      }
    }
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className="container">
            <h1 className="my-4">Edit Shipping Company</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="link" className="form-label">
                  Link:
                </label>
                <input
                  type="url"
                  name="link"
                  id="link"
                  className="form-control"
                  value={formData.link}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="instructions" className="form-label">
                  Special Instructions:
                </label>
                <textarea
                  name="instructions"
                  id="instructions"
                  className="form-control"
                  value={formData.instructions}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="shipping-type" className="form-label">
                  Select shipping type
                </label>
                <div>
                  <input
                    type="radio"
                    name="shippingType"
                    id="ltl"
                    value="ltl"
                    className="form-check-input"
                    checked={formData.isSmall === false}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="ltl" className="form-check-label ml-4">
                    LTL
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="shippingType"
                    id="small"
                    value="small"
                    className="form-check-input"
                    checked={formData.isSmall === true}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="small" className="form-check-label ml-4">
                    Small
                  </label>
                </div>
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="show"
                  id="show"
                  className="form-check-input"
                  checked={formData.show}
                  onChange={handleInputChange}
                />
                <label htmlFor="enable" className="form-check-label">
                  {formData.show ? "Show" : "Hide"}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="enable"
                  id="enable"
                  className="form-check-input"
                  checked={formData.enable}
                  onChange={handleInputChange}
                />
                <label htmlFor="enable" className="form-check-label">
                  Enable/Disable
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  name="partner"
                  id="partner"
                  className="form-check-input"
                  checked={formData.partner}
                  onChange={handleInputChange}
                />
                <label htmlFor="partner" className="form-check-label">
                  Sundial Partner
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default EditShippingCompany;
