import { SHOW_SNACKBAR } from "../actions/types";

const initialState = {
  open: false,
  error: null,
  severity: "",
};

const snackBar = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        error: payload?.message ?? null,
        severity: payload?.severity ?? "",
        open: payload?.open,
      };

    default:
      return state;
  }
};

export default snackBar;
