import Axios from "axios";
import CryptoJS from "crypto-js";
import { showSnackBar } from "../actions/showSnackBar";
import { apiURL } from "../config/default";

/* Error Show/Hide logic */
const handleError = (store, err = null) => {
  if (err) {
    // if (
    //   err?.config?.url.includes("api/admin/login") ||
    //   window?.location.pathname.includes("login")
    // ) {
    //   store.dispatch(
    //     showSnackBar(err.message ?? "Invalid credentials", "error")
    //   );
    // }
    if (
      err?.config?.url.includes(apiURL) &&
      (!err?.config?.url.includes("auth") ||
        err?.config?.url.includes("authorize") ||
        err?.config?.url.includes("admin")) &&
      !window?.location.pathname.includes("login")
    ) {
      store.dispatch(
        showSnackBar(
          err?.error ?? err?.message ?? "Someting went wrong",
          "error"
        )
      );
    }
  }
};

/* Function to validate|prepare|modify error object */
const prepareErrorObject = (error) => {
  let err = (error?.response?.data ?? error) || new Error("Network Error");
  if (typeof err === "object") {
    err.timestamp = Date.now();
    err.config = error?.config;
  } else {
    err = {};
    err.message = "Something went wrong.";
    err.timestamp = Date.now();
  }
  return err;
};

const interceptorService = {
  setupInterceptors: (store) => {
    Axios.interceptors.request.use(
      (config) => {
        let stopEncryption = false;
        let endPoints = config.url.split("/");

        endPoints.forEach((endPoint) => {
          if (
            endPoint.includes("bulk-product") ||
            endPoint.includes("custom-home/home-screen")
          ) {
            stopEncryption = true;
          }
        });

        if (!stopEncryption) {
          if (
            config?.data &&
            !config?.data?.encryption &&
            process.env.REACT_APP_ENVIROMENT !== "DEV"
          ) {
            const encrypted = CryptoJS.AES.encrypt(
              typeof config.data === "string"
                ? config.data
                : JSON.stringify(config.data),
              "1EfSwhjetueGsu6ow07Oq2axDRwhRuZI"
            );

            config.data = { encryption: encrypted.toString() };
          }
        }
        return config;
      },
      (error) => {
        handleError(store, error);

        // return Promise.reject(error ? error["response"] : null);
      }
    );

    // Response interceptor
    Axios.interceptors.response.use(
      (response) => {
        if (!response) return;
        const { data = {} } = response;

        if (data.status >= 400) {
          const err = prepareErrorObject(data);
          handleError(store, err);
        }

        if (data?.encryption) {
          const bytes = CryptoJS.AES.decrypt(
            data.encryption,
            "1EfSwhjetueGsu6ow07Oq2axDRwhRuZI"
          );
          const body = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || {});

          response.data = body;
          if (typeof response.data === "string")
            response.data = JSON.parse(response.data);
        }
        if (data?.message) {
          store.dispatch(showSnackBar(data?.message ?? "", "success", true));
        }

        return response;
      },
      (error) => {
        if (!error) return;

        if (error?.response?.data?.encryption) {
          const bytes = CryptoJS.AES.decrypt(
            error?.response?.data?.encryption,
            "1EfSwhjetueGsu6ow07Oq2axDRwhRuZI"
          );
          const body = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || {});
          error.response.data = body;
          if (typeof error?.response?.data === "string")
            error.response.data = JSON.parse(error?.response?.data);
        }

        const err = prepareErrorObject(error);
        handleError(store, err);

        return Promise.reject(error ? error["response"] : null);
      }
    );
  },
};

export default interceptorService;
