import * as React from "react";

import Drawer from "@mui/material/Drawer";

const closeStyle = {
  fontSize: "2.3rem",
  display: "inline",
  width: "fit-content",
  padding: "5px",
  cursor: "pointer",
  background: "transparent",
  border: "none",
};
export default function FooterModal({
  open,
  closeDrawer,
  columns,
  handleAddFooterLink,
  handleRemoveFooterLink,
  handleTitleChange,
  handleFooterInpChange,
  handleAddColumn,
}) {
  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <button onClick={closeDrawer} style={closeStyle}>
        &times;
      </button>
      <button
        onClick={handleAddColumn}
        className="btn btn-primary p-1 ml-auto"
        style={{ maxWidth: "fit-content" }}
      >
        Add Column <span className="icon-plus"></span>
      </button>
      {columns?.map(
        (column, parentIndex) =>
          column?.links?.length > 0 && (
            <div
              key={parentIndex + 13}
              className="border p-2 mx-2 bg-secondary my-3 rounded"
            >
              <div className="my-3">
                <input
                  onChange={(e) => handleTitleChange(e, parentIndex)}
                  className="form-control"
                  type="text"
                  name="_title"
                  id={`title${parentIndex}`}
                  value={column.title ?? ""}
                />
              </div>
              <button
                onClick={() => handleAddFooterLink(parentIndex)}
                className="text-white border-0 bg-info rounded px-2"
              >
                Add Link <span className="icon-plus"></span>
              </button>
              <span className=" ml-1">{column?.links?.length ?? ""}</span>
              <div className="rounded">
                {column?.links?.map((link, childIndex) => (
                  <div
                    key={childIndex + 281}
                    className="d-flex align-items-center  border-bottom border-warning p-2 m-2"
                  >
                    <span
                      onClick={() =>
                        handleRemoveFooterLink(parentIndex, childIndex)
                      }
                      title="Remove"
                      className="position-absolute icon-minus c-pointer btn-danger p-1"
                    ></span>
                    <div className="m-2">
                      <input
                        className="form-control"
                        placeholder="text"
                        type="text"
                        name="text"
                        onChange={(e) =>
                          handleFooterInpChange(e, parentIndex, childIndex)
                        }
                        value={link.text}
                      />
                    </div>
                    <div className="m-2">
                      <input
                        name="slug"
                        className="form-control"
                        placeholder="slug"
                        type="text"
                        onChange={(e) =>
                          handleFooterInpChange(e, parentIndex, childIndex)
                        }
                        value={link.slug}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </Drawer>
  );
}
