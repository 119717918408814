/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";
import { alert } from "./alert";

export const getAllOrders =
  (pageNumber, searchQuery, status, charge, date) => async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.get(
        `${apiURL}admin/get-all-orders?page=${
          pageNumber ? pageNumber : 1
        }&search=${searchQuery ? searchQuery : ""}&status=${
          status ? status : ""
        }&charge=${charge ? charge : ""}&date=${date ? date : ""}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

export const checkShippingStatus = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}tracking-package/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateShipping = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/edit-order`, { ...data });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllPendingOrders =
  (
    pageNumber,
    searchQuery,
    status,
    date,
    tracking,
    sort,
    claims,
    deliverytype,
    pickupDate,
    deliveryDate,
    deliveryEndDate,
    brand,
    trackingStatus,
    shipandtrack,
    nextStatus,
    priority
  ) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }
    try {
      const res = await axios.get(
        `${apiURL}admin/get-all-orders?page=${
          pageNumber ? pageNumber : 1
        }&search=${searchQuery ? searchQuery : ""}&status=${
          status ? status : ""
        }&date=${date ? date : ""}&companyName=${
          tracking ? tracking : ""
        }&sort=${sort ? sort : ""}&claims=${
          claims ? claims : ""
        }&deliverytype=${deliverytype ? deliverytype : ""}&pickupDate=${
          pickupDate ? pickupDate : ""
        }&deliveryDate=${deliveryDate ? deliveryDate : ""}&deliveryEndDate=${
          deliveryEndDate ? deliveryEndDate : ""
        }&brand=${brand ? brand : ""}&trackingStatus=${
          trackingStatus ? trackingStatus : ""
        }&nextStatus=${nextStatus ? nextStatus : ""}&shipandtrack=${
          shipandtrack ? shipandtrack : ""
        }&priority=${priority ? priority : ""}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
export const getAllQuoteOrders =
  (pageNumber, searchQuery, date, sort) => async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.get(
        `${apiURL}admin/get-quotes?page=${pageNumber ? pageNumber : 1}&search=${
          searchQuery ? searchQuery : ""
        }&date=${date ? date : ""}&sort=${sort ? sort : ""}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

export const UpdateOrderStatus = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/order/update-status`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
// update Shipiing
export const UpdateShipping = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/add-tracking-number`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredOrders = (data) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/order/filter-order`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

export const retreive = (id) => async (dispatch) => {
  console.log(id);
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/retreive-order/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getHistory = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/get-all/${id}`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
  }
};

export const submitHistory = async (id, data) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/create/${id}`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
  }
};

export const edit = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/edit-order`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/delete-order/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

// update order tracking system

export const UpdateOrderTrackingStatus = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/update-order-tracking-status/${id}`,
      {
        ...data,
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// update order tracking system

export const genrateOrderInvice = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}order/generate-order-invoice/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// Get insurance quote

export const getInsuranceQuote = (id, postData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}order/get-insurance-quote/${id}`,
      postData
    );
    return res.data;
  } catch (err) {
    console.error("Error fetching insurance quote:", err);
    throw err;
  }
};

export const swtichShippingType = async (id, data) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/update-shipping-party/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
  }
};
