/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteFilterItem, getFilterItems } from "../../../actions/filters";
import { Route } from "react-router";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TopBarProgress from "react-topbar-progress-indicator";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class ViewFilterItem extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filters: null,
      productsClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 50,
      pagination: 0,
    };
  }

  async componentDidMount() {
    const pageNumber = window.location.toString().split("/")[5];
    const res = await this.props.getFilterItems(pageNumber);
    this.setState({
      filters: res.data.filter,
      pagination: res.data.pagination,
      loading: false,
    });
    console.log(this.state.pagination);
  }
  handleClick = async (event) => {
    console.log(event);
    this.setState(
      {
        currentPage: Number(event.page),
      },
      async () => {
        this.setState({ loading: true });
        const res = await this.props.getFilterItems(this.state.currentPage);
        this.setState({
          filters: res.data.filter,
          pagination: res.data.pagination,
          loading: false,
        });

        this.setState({ loading: false });
      }
    );
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { products, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(products.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };
  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteProduct = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteFilterItem(id);
    console.log(id);
    const res = await this.props.getFilterItems(1);
    this.setState({
      loading: false,
      deleteConfirm: false,
      filters: res.data.filter,
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.products.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  render() {
    const {
      loading,
      filters,
      currentPage,
      dataPerPage,
      hpmProducts,
    } = this.state;

    // const indexOfLastData = currentPage * dataPerPage;
    // const indexOfFirstData = indexOfLastData - dataPerPage;
    // const currentData =
    //   products && products.slice(indexOfFirstData, indexOfLastData);

    const renderFilters =
      filters &&
      Array.isArray(filters) &&
      filters.map((item, index) => {
        return (
          <tr key={index}>
            <td> {index + 1} </td>
            <td> {item.title} </td>

            <td className="align-middle text-center">
              {item.status ? (
                <button
                  type="button"
                  disabled
                  className="btn btn-inverse-success disabled btn-fw"
                >
                  ON
                </button>
              ) : (
                <button
                  type="button"
                  disabled
                  className="btn btn-inverse-danger btn-fw"
                >
                  OFF
                </button>
              )}
            </td>
            <td className="align-middle text-center">
              {item.image ? <img src={item.image} alt="" /> : <p>No Image</p>}
            </td>

            <td>
              <Link to={`/admin/update-filter-item/?id=${item._id}`}>
              <button
                type="button"
                className="btn btn-primary btn-sm mr-4"
                style={{ width: 78 }}

              >
                Action
              </button>
              </Link>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this._deleteProduct(item._id);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      });

    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> View Filter Item</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                View Filter Item{" "}
              </li>
            </ol>
          </nav>
        </div>
        <div className="container-fluid bg-white">
          <div className="hyper-heading-row d-flex justify-content-between ml-5 pt-5">
            <div className="row-center">
              <h4>Filter Items</h4>
            </div>
            <Link to={`/admin/create-filter-item`}>
            <button

              className="btn btn-success btn-fw"
            >
              Create New Item
            </button>
            </Link>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>

                <th scope="col">Title</th>

                <th className="text-center" scope="col">
                  Status
                </th>
                <th className="text-center" scope="col">
                  Image
                </th>
                <th className="text-center" scope="col">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody>
              {hpmProducts && hpmProducts.length === 0 ? (
                <tr className="text-center">
                  <td colSpan="6">No main products found</td>
                </tr>
              ) : (
                renderFilters
              )}
            </tbody>
          </table>
          <div className="container mt-4">
            {/* <MemoryRouter initialEntries={["/inbox"]} initialIndex={0}>
              <Route>
                {({ location }) => {
                  return ( */}
                    <Pagination
                      page={
                        this.state.pagination &&
                        this.state.pagination.currentPage
                      }
                      count={
                        this.state.pagination &&
                        this.state.pagination.totalPages
                      }
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          onClick={(e) => this.handleClick(item)}
                        />
                      )}
                    />
            {/* );
                }}
              </Route>
            </MemoryRouter> */}
          </div>
        </div>
      </div>
    );
  }
}

deleteFilterItem.propTypes = {
  deleteFilterItem: PropTypes.func.isRequired,
};
getFilterItems.propTypes = {
  getFilterItems: PropTypes.func.isRequired,
};
export default connect(null, {
  deleteFilterItem,
  getFilterItems,
})(ViewFilterItem);
