import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { mainCategories } from "../../../actions/mainCategory";
import { retreive, edit } from "../../../actions/childCategory";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mainCategoryId: null,
      name: "",
      displayName: "",
      slug: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
      mainCategories: null,
      show: true,
      image: "",
      isbase64: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const id = this.props.location.search.substr(4);

    const _res = await this.props.mainCategories();
    this.setState({ mainCategories: _res });

    const res = await this.props.retreive(id);

    const metaTagData = [];

    res.metaTag &&
      res.metaTag.forEach((element) => {
        metaTagData.push({
          value: element,
          label: element,
        });
      });

    const metaKeywordData = [];

    res.metaKeyword &&
      res.metaKeyword.forEach((_element) => {
        metaKeywordData.push({
          value: _element,
          label: _element,
        });
      });

    const seoKeywordData = [];

    res.seoKeyword &&
      res.seoKeyword.forEach((element) => {
        seoKeywordData.push({
          value: element,
          label: element,
        });
      });

    await this.setState({
      loading: false,
      slug: res.slug,
      displayName: res.displayName,
      mainCategoryId: res.mainCategoryId,
      name: res.name,
      description: res.description,
      metaTag: metaTagData,
      metaDescription: res.metaDescription,
      metaKeyword: metaKeywordData,
      seoKeyword: seoKeywordData,
      show: res.show,
      image: res.image,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      mainCategoryId,
      name,
      displayName,
      slug,
      description,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
      show,
      image,
      isbase64,
    } = this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);

    const metaTagData = [];

    metaTag.forEach((element) => {
      metaTagData.push(element.value);
    });

    const metaKeywordData = [];

    metaKeyword.forEach((_element) => {
      metaKeywordData.push(_element.value);
    });

    const seoKeywordData = [];

    seoKeyword.forEach((element) => {
      seoKeywordData.push(element.value);
    });
    const dataTosend = {
      _id: id,
      name,
      displayName,
      slug,
      mainCategoryId,
      image,
      metaTag: metaTagData,
      metaDescription,
      metaKeyword: metaKeywordData,
      seoKeyword: seoKeywordData,
      description,
      show,
      isbase64,
    };
    const res = await this.props.edit(dataTosend);

    this.setState({ loading: false });

    if (res) {
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _metaTagJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaTag}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaTag: data,
            })
          : this.setState({
              metaTag: null,
            });
      }}
    />
  );

  _metaKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.metaKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              metaKeyword: data,
            })
          : this.setState({
              metaKeyword: null,
            });
      }}
    />
  );

  _seoKeywordJsx = () => (
    <CreatableSelect
      isMulti
      defaultValue={this.state.seoKeyword}
      onChange={(e) => {
        const data = [];
        e &&
          e.map((item, i) => {
            return data.push({ value: item.value, label: item.value });
          });

        data.length > 0
          ? this.setState({
              seoKeyword: data,
            })
          : this.setState({
              seoKeyword: null,
            });
      }}
    />
  );

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async _handleImageChange(e) {
    e.preventDefault();

    let file = e.target.files[0];

    const base64 = await this.toBase64(file);

    this.setState({
      image: base64,
      isbase64: true,
    });
  }
  render() {
    const {
      name,
      displayName,
      slug,
      description,
      metaDescription,
      mainCategoryId,
      show,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller" ref={(el) => (this.instance = el)}>
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Edit Main Category </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/child-categories">Child Categories</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Main Category
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4
                  className="card-title d-flex align-item-center justify-content-statr "
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#ecf0f4",
                    height: "40px",
                    paddingTop: "10px",
                    paddingLeft: "5px",
                  }}
                >
                  Edit
                </h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Name">Display Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="displayName"
                      value={displayName}
                      onChange={(e) =>
                        this.setState({ displayName: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      placeholder="Slug"
                      name="slug"
                      value={slug}
                      onChange={(e) => this.setState({ slug: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="category">Select parent category</label>

                    {this.state.mainCategories && (
                      <select
                        className="form-control form-control-sm"
                        id="category"
                        onChange={(e) => {
                          this.setState({
                            mainCategoryId: e.target.value,
                          });
                        }}
                        defaultValue={mainCategoryId && mainCategoryId}
                      >
                        <option>choose</option>
                        {this.state.mainCategories &&
                          this.state.mainCategories.map((item, index) => (
                            <option
                              key={index}
                              value={item._id}
                              selected={item._id === mainCategoryId && true}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Image upload</label>

                    <div className="input-group col-xs-12">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <img
                      src={this.state.image && this.state.image}
                      alt="cate"
                      width="100"
                      height="auto"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Meta Tags">Meta Title</label>

                    {!this.state.loading && this._metaTagJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Meta Keywords</label>

                    {!this.state.loading && this._metaKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="meta description">Meta Description</label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      value={metaDescription}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seo keywords">Seo Keywords</label>

                    {!this.state.loading && this._seoKeywordJsx()}
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Seo Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={6}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <label className="col-sm-3 col-form-label">
                      Show in Header
                    </label>
                    <div className="col-sm-12">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="1"
                            defaultValue={show}
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                show: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="membershipRadios"
                            id="membershipRadios2"
                            defaultValue={show}
                            onChange={() => {
                              this.setState({
                                show: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                  <Link to={"/admin/child-categories"}>
                    <button className="btn btn-light">Cancel</button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};

mainCategories.propTypes = {
  mainCategories: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, { mainCategories, retreive, edit })(Edit)
);
