import React from "react";
import styles from "./pricing-side-componet.module.css";

const SmallCard = ({ title, rank, price, backgroundColor }) => {
  const containerStyle = {
    backgroundColor: backgroundColor || "#e7f6ee", // Default to transparent if no backgroundColor prop is provided
  };

  return (
    <div className={styles.smallCardContainer} style={containerStyle}>
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.rank}>Rank: {rank}</p>
      </div>
      <div className={styles.cardContent}>
        <p className={styles.label}>Final Price</p>
        <p className={styles.priceValue}>{price}</p>
      </div>
    </div>
  );
};

export default SmallCard;
