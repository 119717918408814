/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import style from "./customer-ticket.module.css";
import CustomerRatingContainer from "./customer-rating-container";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { apiURL } from "../../../../../config/default";

const CustomerTicket = ({ filterReviewData }) => {
  const [loading, setLoading] = useState(false);
  const [allTickets, setAllTickets] = useState([]);

  const getTicketsByUserId = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiURL}get-tickets-by-user/${id}`);
      setAllTickets(response?.data?.tickets);
    } catch (error) {
      console.log("error value is : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketsByUserId(filterReviewData?.orderID?.user?._id);
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div className={style.ticketContainer}>
      <div className={style.priceTitleBox}>
        <h3 className={style.priceTitleBoxHeading}>Customer Tickets</h3>
        <button className={style.customButton}>
          <a
            style={{ color: "#fb2a3d", textDecoration: "none" }}
            href="https://qaadmin.sundialhome.com/admin/all-tickets"
            target="_blank"
            rel="noreferrer"
          >
            {filterReviewData?.ticketCount}
          </a>
        </button>
      </div>

      <div className={style.productContainer}>
        <p className={style.productDescription}>
          <a
            style={{ color: "black" }}
            href={`https://qaadmin.sundialhome.com/admin/users`}
            target="_blank"
            rel="noreferrer"
          >
            {filterReviewData?.orderID?.user?.email}
          </a>
        </p>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : allTickets && allTickets.length > 0 ? (
        allTickets?.map((ticket) => (
          <CustomerRatingContainer
            key={ticket.ticketId}
            ticketNumber={ticket.ticketId}
            deliveredDate={
              ticket.orderItemId?.shippingInfo?.finalDeliveryDate
                ? formatDate(ticket.orderItemId.shippingInfo.finalDeliveryDate)
                : ""
            }
            orderDate={formatDate(ticket.updatedAt)}
            ticketDate={formatDate(ticket.createdAt)}
            reason={ticket.reason}
            ticketSummary={ticket.orderItemId?.sku}
            ticketSummaryDetail={ticket.description}
          />
        ))
      ) : (
        <p className="d-flex justify-content-center align-items-center h-100">
          No Ticket Found
        </p>
      )}

      {/* {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        allTickets?.map((ticket, index) => (
          <CustomerRatingContainer
            key={index}
            ticketNumber={ticket?.ticketId}
            deliveredDate={`${
              ticket?.orderItemId?.shippingInfo?.finalDeliveryDate
                ? formatDate(
                    ticket?.orderItemId?.shippingInfo?.finalDeliveryDate
                  )
                : ""
            }`}
            orderDate={formatDate(ticket?.updatedAt)}
            ticketDate={formatDate(ticket?.createdAt)}
            reason={ticket?.reason}
            ticketSummary={ticket?.orderItemId?.sku}
            ticketSummaryDetail={ticket?.description}
          />
        ))
      )} */}

      {/* <CustomerRatingContainer
        ticketNumber={filterReviewData?.itemOrderNumber}
        deliveredDate={`${
          ticket?.orderItemId
            ? formatDate(ticket?.orderItemId)
            : ""
        }`}
        orderDate={formatDate(filterReviewData?.createdAt)}
        ticketDate={"1/2/2024"}
        reason={"Damaged & Defective"}
        ticketSummary={"SDHP-ESSE-/pum-3323"}
      /> */}
    </div>
  );
};

export default CustomerTicket;
