import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllStores } from "../../../../actions/store";
import CreatableSelect from "react-select/creatable";
import { subCategories } from "../../../../actions/subCategory";
import { products } from "../../../../actions/product";
import { create } from "../../../../actions/coupon";
import { toISOStringFromat } from "../../../../utils/dateFormate";

const AddNewCoupon = ({ SelectItem, setEdit, isMount, setisMount }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [Brand, setBrand] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [formData, setformData] = useState({
    title: SelectItem?.title ?? "",
    brand: SelectItem?.brand ?? "",
    category: SelectItem?.category ?? [],
    code: SelectItem?.code ?? "",
    pricePercentage: SelectItem?.pricePercentage ?? "",
    product: SelectItem?.product ?? "",
    from: SelectItem?.from ?? new Date(),
    to: SelectItem?.to ?? new Date(),
  });

  // get Brand
  const fetchBrands = useCallback(async () => {
    const res = await dispatch(getAllStores());
    const BrandData = res?.data.map((item) => {
      return {
        value: item?._id,
        label: item?.fullName,
      };
    });
    setBrand(BrandData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Brand]);

  // get all categories
  const fetchCategories = useCallback(async () => {
    const res = await dispatch(subCategories());
    const categoryData = res?.subCategories.map((item) => {
      return {
        value: item?._id,
        label: item?.name,
      };
    });
    setCategories(categoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Categories]);

  // fecth product data
  const fetchProducts = useCallback(async () => {
    const res = await dispatch(products(1, "", "", "", "", "", "", ""));
    const productData = res?.products?.map((item) => {
      return {
        value: item?._id,
        label: item?.title,
      };
    });
    setProductOption(productData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOption]);

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        fetchBrands();
        fetchCategories();
        fetchProducts();
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((pre) => {
      return { ...pre, [name]: value };
    });
  };

  const handleSelectCategoy = (e) => {
    let data = [];
    e &&
      e.map((item, i) => {
        return data.push(item.value);
      });
    setformData((pre) => {
      return {
        ...pre,
        category: data,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      const res = await dispatch(create({ ...formData }));
      if (res) {
        setloading(false);
        setEdit({
          open: false,
          edit: "",
          title: "",
        });
        setisMount(!isMount);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <div>
      <div className="px-4 ">
        <form onSubmit={handleSubmit}>
          <div className=" mt-2 d-flex flex-column gap-10">
            <div>
              <label className=" font-weight-bold">Title</label>
              <input
                type="text"
                className="add-button "
                required
                placeholder="Title"
                name="title"
                autoComplete="off"
                value={formData.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <label className=" font-weight-bold" htmlFor="">
                Select Brands
              </label>
              <select
                className="add-button  "
                name="brand"
                value={formData?.brand}
                onChange={(e) => handleChange(e)}
              >
                <option value={""}>Select Brand</option>

                {Brand?.map((item) => (
                  <option value={item?.value}> {item?.label}</option>
                ))}
              </select>
            </div>
            <div>
              <label className=" font-weight-bold" htmlFor="">
                Select Category
              </label>

              <CreatableSelect
                isMulti
                defaultValue={Categories}
                onChange={(e) => handleSelectCategoy(e)}
                options={Categories && Categories}
              />
            </div>
            <div>
              <label className=" font-weight-bold" htmlFor="">
                Select product
              </label>
              <select
                className="add-button  "
                name="product"
                value={formData?.product}
                onChange={(e) => handleChange(e)}
              >
                <option value={""}> Select Product</option>
                {productOption?.map((item) => (
                  <option value={item?.value}> {item?.label}</option>
                ))}
              </select>
            </div>
            <div>
              <label className=" font-weight-bold">Price Percentage</label>
              <input
                type="number"
                className="add-button "
                required
                placeholder="Price Percentage"
                name="pricePercentage"
                autoComplete="off"
                value={formData.pricePercentage}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <label className=" font-weight-bold">Coupon Code</label>
              <input
                type="text"
                className="add-button "
                required
                placeholder="Coupon Code"
                name="code"
                autoComplete="off"
                value={formData.code}
                onChange={(e) => handleChange(e)}
              />
            </div>{" "}
            <div>
              <label className=" font-weight-bold">Valid From</label>
              <input
                type="date"
                className="add-button "
                required
                name="from"
                autoComplete="off"
                value={toISOStringFromat(formData?.from)}
                onChange={(e) => handleChange(e)}
              />
            </div>{" "}
            <div>
              <label className=" font-weight-bold">Valid To</label>
              <input
                type="date"
                className="add-button "
                required
                name="to"
                autoComplete="off"
                value={toISOStringFromat(formData?.to)}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className=" mt-3 ">
              <button type="submit" className="update-Order-button">
                {loading ? "Loading..." : "Add New Coupon"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewCoupon;
