import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { connect } from "react-redux";
import { getTextEditorState } from "../../actions/textEditor";
import "../TextEditor/TextEditor.style.css";
import PropTypes from "prop-types";

class EditorConvertToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.getTextEditorState(html);
  };

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}
getTextEditorState.propTypes = {
  getTextEditorState: PropTypes.func.isRequired,
};

export default connect(null, {
  getTextEditorState,
})(EditorConvertToHTML);
