import moment from "moment";
import React from "react";

const PageTitle = ({ pageName }) => {
  return (
    <div>
      <h5 className="mb-0 font-weight-medium d-none d-lg-flex">
        {pageName ? pageName : "Welcome"}
      </h5>
      <p className=" m-0 font-weight-bold text-muted">
        {moment(new Date()).format("LL")}
      </p>
    </div>
  );
};

export default PageTitle;
