import React from "react";

const RecommendedShipping = ({ SelectItem }) => {
  return (
    <div className="pending-shipping-details my-2">
      <h6> Recommended Shipping Carrier:</h6>
      <p className="mt-1">
        {SelectItem?.shippingInfo?.labels[0]?.companyName ||
          SelectItem?.orShipping?.companyName ||
          SelectItem?.companyName}
      </p>
      <div className="mt-2">
        <h6>Shipping type:</h6>
        <p>
          {SelectItem?.shippingInfo?.shippingType ||
            SelectItem?.orShipping?.type ||
            SelectItem?.shipType}
        </p>
      </div>
    </div>
  );
};

export default RecommendedShipping;
