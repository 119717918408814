import React from "react";
import { useDispatch } from "react-redux";
import { addAnswer } from "../../../../actions/faqs";
import { useState } from "react";

const SendAnswer = ({ selectedItem, isRender, setIsRender }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState({
    answer: "",
    answerBy: "",
  });

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    // e.presist();
    setLoading(true);
    try {
      const res = await dispatch(addAnswer(id, formData));
      if (res) {
        setLoading(false);
        setIsRender(!isRender);
        setformData({
          answer: "",
          answerBy: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => handleSubmit(e, selectedItem?._id)}
        className="d-flex flex-column gap-10 p-3 bg-light-warning border-radius-10 border-grey"
      >
        <textarea
          className="w-100 border-grey border-radius-10 p-3"
          placeholder="Enter Answer"
          required
          rows="5"
          name="answer"
          value={formData.answer}
          onChange={(e) =>
            setformData({
              ...formData,
              [e.target.name]: e.target.value,
            })
          }
        />
        <input
          className="w-100  border-grey border-radius-10 p-3"
          placeholder="By: Enter name"
          type="text"
          required
          name="answerBy"
          value={formData.answerBy}
          onChange={(e) =>
            setformData({
              ...formData,
              [e.target.name]: e.target.value,
            })
          }
        />
        <button type="submit" className=" btn btn-warning p-3 w-100">
          {loading ? "Laoding...." : "Submit Answer"}
        </button>
      </form>
    </>
  );
};

export default SendAnswer;
