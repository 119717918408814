import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./inspiration.module.css";
import Select from "react-select";
import { UseFetch } from "./useFetch";
import axios from "axios";
import { apiURL } from "../../../../config/default";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { withRouter } from "../../../../utils/withRouter";

const Inspiration = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [attributesData, setattributesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([
    { value: "", label: "", id: "" },
  ]);
  const [attributesChildern, setAttributesChildern] = useState([
    { value: "", label: "", id: "" },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [attrSelectWithChildern, setAttrSelectWithChildern] = useState(false);

  const [cardData, setCardData] = useState({
    title: "",
    attributeId: "",
    type: "category",
    name: "",
    values: [
      {
        showImage: false,
        imageURL:
          "https://sundialinsider.s3.us-east-2.amazonaws.com/customHomeScreen/664.png",
        selectedId: "",
        name: "name",
        // attribute: {
        //   name: "name",
        //   attributeId: "",
        // },
        isColor: false,
        colorCodes: ["white", "red", "black"],
        description: "",
        status: true,
      },
    ],
  });

  // fetch category and attributes
  useEffect(() => {
    const fetchData = async () => {
      console.log(window.location);

      const id = window.location.href.split("?id=")[1];
      if (id) {
        const cardData = await UseFetch(`admin/tag-category/${id}`);
        setCardData(cardData.data);
      }

      const gotCategory = await UseFetch("header/get-all-category");
      const gotAttributes = await UseFetch("admin/get-attribute");

      setCategoryData(gotCategory.data);
      setattributesData(gotAttributes.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const category = categoryData?.map((item) => {
      return { value: item.name, label: item.name, id: item._id };
    });
    const attributes = attributesData.attributes?.map((item) => {
      return { value: item.name, label: item.name, id: item._id };
    });
    setSelectOptions(cardData.type === "category" ? category : attributes);
    cardData.type === "attributes" &&
      handleAttributesDropdown({ id: cardData.attributeId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData, attributesData]);

  const handleOnOf = (idx, onOf) => {
    cardData.values[idx][onOf] = !cardData.values[idx][onOf];
    setCardData({ ...cardData });
  };

  const handleRadio = (check) => {
    setCardData({
      ...cardData,
      type: check,
    });
    setSelectOptions(
      check === "category"
        ? categoryData?.map((item) => {
            return { value: item.name, label: item.name, id: item._id };
          })
        : attributesData?.map((item) => {
            return { value: item.name, label: item.name, id: item._id };
          })
    );
  };
  // conver image to base 64
  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e, idx) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);
    cardData.values[idx].imageURL = await image;
    setCardData({ ...cardData });
  };

  const handleDescription = (e, idx) => {
    cardData.values[idx].description = e.target.value;
    setCardData({ ...cardData });
  };

  // when select item in dropdown
  const handleAttributesDropdown = async (item) => {
    setCardData({
      ...cardData,
      attributeId: item.id,
    });
    const res = await UseFetch(`admin/product-attribute/${item.id}`);
    setAttrSelectWithChildern(true);
    const tempArr = await res?.data?.attributes?.map((item) => ({
      ...item,
      value: item.title,
      label: item.title,
    }));
    console.log(tempArr);
    setAttributesChildern(tempArr);
  };

  const handleDropdown = (item, idx) => {
    const select = { ...cardData.values[idx] };

    select.name = item.value;
    console.log(item);
    select.selectedId = item.id || item._id;
    // select.attribute = {
    //   name: item.value,
    //   attributeId: item.id,
    // };

    cardData.values[idx] = select;
    setCardData({ ...cardData });
  };

  // colors
  const handleColor = (e, parentIndex, childIndex) => {
    const card = { ...cardData.values[parentIndex] };

    card.colorCodes[childIndex] = e.target.value;

    cardData.values[parentIndex] = card;

    setCardData({ ...cardData });
  };

  // when click on + button to add card
  const addCard = () => {
    setCardData({
      ...cardData,
      values: [
        ...cardData.values,
        {
          status: false,
          showImage: false,
          name: "",
          isColor: false,
          attribute: {
            name: "name",
            attributeId: "",
          },
          imageURL:
            "https://sundialinsider.s3.us-east-2.amazonaws.com/customHomeScreen/664.png",
          description: "",
          colors: ["#0000", "#a61c1c", "#a51d1d"],
        },
      ],
    });
  };

  // remove card
  const removeCard = (idx) => {
    setCardData({
      ...cardData,
      values: cardData.values.filter((_, idx1) => idx1 !== idx),
    });
  };

  const publishData = async () => {
    if (cardData.name === "") {
      toast("Make sure to select some values and enter Title 👈", {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });
    } else {
      setLoading(true);
      const id = window.location.href.split("?id=")[1];
      let res = null;

      if (!id) {
        res = await axios.post(`${apiURL}admin/create-category-tag`, cardData);
      } else
        res = await axios.post(`${apiURL}admin/edit-category-tag`, cardData);
      !id && props.navigate("/admin/inspirations");

      if (res.status === 200) {
        setLoading(false);
        toast(res.data.message, { type: toast.TYPE.INFO, autoClose: 5000 });
      } else {
        toast("Something went wrong 🙁", {
          type: toast.TYPE.INFO,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <div className="container-scroller">
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
      />

      <div className="mt-4 page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          Create Inspiration
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/inspirations">Inspirations</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create inspiration
            </li>
          </ol>
        </nav>
      </div>
      <hr />
      {loading && (
        <div
          style={{
            top: 0,
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "12",
            background: "rgba(0,0,0,0.5)",
          }}
          className="d-flex position-fixed justify-content-center align-items-center"
        >
          <CircularProgress disableShrink />
        </div>
      )}
      <div className="row">
        <div className="col-md-12 my-2">
          {" "}
          <h3>
            <button onClick={publishData} className="btn btn-primary">
              Publish
            </button>
          </h3>
          <div className="bg-white d-flex align-items-center p-2 justify-content-between">
            <div>
              <h3>
                {" "}
                <input
                  type="text"
                  className="typeahead form-control tt-input"
                  value={cardData.name}
                  onChange={(e) =>
                    setCardData({ ...cardData, name: e.target.value })
                  }
                  placeholder="Title"
                  id="mainTitle"
                />
              </h3>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <div className="m-2 d-flex flex-column align-items-center">
                <input
                  type="radio"
                  name="categoryOrAttr"
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onChange={() => handleRadio("attributes")}
                  id={`attributes`}
                  checked={cardData.type === "attributes"}
                />
                <label className="c-pointer" htmlFor={`attributes`}>
                  Attributes
                </label>
              </div>
              {cardData.type !== "category" && (
                <div style={{ width: "200px" }}>
                  <Select
                    onChange={(e) => handleAttributesDropdown(e)}
                    options={selectOptions}
                    value={selectOptions.find(
                      (s) => s.id === cardData.attributeId
                    )}
                  />
                </div>
              )}
              <div className="m-2 d-flex flex-column align-items-center">
                <input
                  type="radio"
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onChange={() => handleRadio("category")}
                  name="categoryOrAttr"
                  id="category"
                  checked={cardData.type === "category"}
                />
                <label className="c-pointer" htmlFor="category">
                  Category
                </label>
              </div>
            </div>
          </div>
        </div>
        {cardData.values.map((item, idx) => (
          <div className="col-md-3 mb-5" style={{ position: "relative" }}>
            <div className="def-row">
              <div className="col-md-12">
                <div className="d-flex align-items-start justify-content-between">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-view-stacked"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
                    </svg>
                  </button>
                  <h4>Inspiration</h4>
                  <div
                    onClick={() => removeCard(idx)}
                    style={{ cursor: "pointer" }}
                  >
                    &times;
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between my-3">
                <label>Status</label>
                <div
                  onClick={() => handleOnOf(idx, "status")}
                  className="connection-all"
                >
                  <span className={item.status ? "allcenter" : "allLeft "}>
                    ON
                  </span>

                  <span className={item.status ? "editRight" : "editcenter "}>
                    OFF
                  </span>
                </div>
              </div>

              {cardData.type === "category" && (
                <div className="col-md-12  mt-3">
                  <label>Select {cardData.type}</label>
                  <Select
                    onChange={(e) => handleDropdown(e, idx)}
                    options={selectOptions}
                    value={selectOptions.find((a) => item.selectedId === a.id)}
                  />
                </div>
              )}

              {cardData.type !== "category" && (
                <div className="col-md-12  mt-3">
                  <label>Select {cardData.type}</label>
                  <Select
                    onChange={(e) => handleDropdown(e, idx)}
                    options={attributesChildern}
                    value={attributesChildern?.find(
                      (a) => item.selectedId === a._id
                    )}
                  />
                </div>
              )}

              {cardData.type !== "category" && (
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div>
                    {" "}
                    <label>Is Color</label>
                    <div
                      onClick={() => handleOnOf(idx, "isColor")}
                      className="connection-all"
                    >
                      <span className={item.isColor ? "allcenter" : "allLeft "}>
                        ON
                      </span>

                      <span
                        className={item.isColor ? "editRight" : "editcenter "}
                      >
                        OFF
                      </span>
                    </div>
                  </div>
                  <div>
                    {item.isColor && (
                      <div>
                        <input
                          onChange={(e) => handleColor(e, idx, 0)}
                          type="color"
                          name=""
                          id=""
                          value={item.colorCodes?.[0]}
                        />
                        <input
                          onChange={(e) => handleColor(e, idx, 1)}
                          type="color"
                          name=""
                          id=""
                          value={item.colorCodes?.[1]}
                        />
                        <input
                          onChange={(e) => handleColor(e, idx, 2)}
                          type="color"
                          name=""
                          id=""
                          value={item.colorCodes?.[2]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="my-2">
                <h5>Description</h5>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  onChange={(e) => handleDescription(e, idx)}
                  rows={4}
                  value={item.description}
                />
              </div>

              <div className="my-3 d-flex align-items-center justify-content-between">
                <div>
                  {" "}
                  <label>Show Image</label>
                  <div
                    onClick={() => handleOnOf(idx, "showImage")}
                    className="connection-all"
                  >
                    <span className={item.showImage ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span
                      className={item.showImage ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>
                {item.showImage && (
                  <div className="d-flex justify-content-between align-items-center flex-column">
                    <label className="btn btn-primary" htmlFor={`image${idx}`}>
                      Upload
                    </label>
                  </div>
                )}
              </div>
              <div className="text-center">
                {item.showImage === true && (
                  <div>
                    <img
                      src={item.imageURL ?? ""}
                      alt=""
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "100px",
                      }}
                    />
                  </div>
                )}

                <input
                  id={`image${idx}`}
                  type="file"
                  onChange={(e) => _handleImageChange(e, idx)}
                  className={`${styles.image} review-input border-radius-8`}
                  name="imageURL"
                />
              </div>
            </div>
          </div>
        ))}
        <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center mt-3">
            <button
              className="btn btn-primary"
              variant="outline-primary"
              size="sm"
              onClick={addCard}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Inspiration);
