import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import TopBarProgress from 'react-topbar-progress-indicator';
import { Link } from 'react-router-dom';
import { getStores } from '../../../actions/store';
import { connect } from 'react-redux';
import ChildrenModal from './ViewChildren';
import axios from 'axios';
import { apiURL } from '../../../config/default';
import { toast } from 'react-toastify';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-block',
    float: 'right',
    paddingRight: 10,
  },
  searchInput: {
    position: 'relative',
    verticalAlign: 'top',
    backgroundColor: 'transparent',
    borderColor: 'green',
    color: 'black',
    marginLeft: 4,
    paddingLeft: 10,
  },
};

function FiltersBrand({ getStores }) {
  useEffect(() => {
    (async () => {
      const mainCat = await getStores();

      setcategories(mainCat.data);
      setfilteredcategories(mainCat.data);
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onChangeHandler = (e) => {
    setfilteredcategories(
      categories.filter((category) =>
        category.name.toUpperCase().includes(e.target.value.toUpperCase())
      )
    );
    setsearch(e.target.value);
  };

  const [loading, setloading] = useState(true);
  const [categories, setcategories] = useState([]);
  const [filteredcategories, setfilteredcategories] = useState([]);
  const [search, setsearch] = useState('');
  const [show, setShow] = useState(false);
  const [selectedChildFilter, setselectedChildFilter] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleShow = (index) => {
    setselectedChildFilter(index);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleAutoFilters = async (id) => {
    setloading(true);
    const { data: res } = await axios.get(
      `${apiURL}filters/create-filter/${id}`
    );
    if (res)
      toast.success('Successful', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    setloading(false);
  };

  return (
    <>
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <div className="page-header">
          <h3 className="page-title"> Select Filters</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Brands
              </li>
            </ol>
          </nav>
        </div>
        <div className="bg-white mb-5">
          <h4 class="card-title pt-4 pl-4">Brand List</h4>
          <span className="twitter-typeahead pb-1" style={styles.wrapper}>
            <input
              className="typeahead form-control tt-input"
              type="text"
              placeholder="Search..."
              autoComplete="off"
              spellCheck="false"
              dir="auto"
              value={search}
              style={styles.searchInput}
              onChange={_onChangeHandler}
            />
          </span>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th style={{ cursor: "pointer", width: 10 }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredcategories.map((category, i) => (
                <tr key={category._id}>
                  <td>{i + 1}</td>
                  <td>{category.fullName}</td>

                  <th style={{ cursor: "pointer", width: 10 }}>
                    <Button
                      className="mr-2"
                      variant="outline-primary"
                      onClick={() => handleAutoFilters(category._id)}
                      size="sm"
                    >
                      Auto Settings
                    </Button>
                    <Link to={`/admin/create-filter/?id=${category._id}`}>
                      Customize Filters
                    </Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <ChildrenModal
        children={filteredcategories[selectedChildFilter]?.children || []}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
}

export default connect(null, {
  getStores,
})(FiltersBrand);
