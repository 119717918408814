import React from "react";

const VendorImageUploader = ({
  name,
  children,
  className,
  id,
  handleChangeFile,
}) => {
  return (
    <div className="row-container">
      <div className="title text-muted">{name}</div>
      <div className={`feild ${className}`}>{children}</div>
      <input
        className="d-none"
        id={id}
        type="file"
        accept="image/*"
        name={id}
        onChange={handleChangeFile}
      />
      <label
        htmlFor={id}
        className=" border-0 bg-grey  text-center c-pointer py-2 px-3 w-100 underlined"
      >
        Chose File
      </label>
    </div>
  );
};

export default VendorImageUploader;
