import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";
import { alert } from "./alert";

export const getAllBrand = (pageNumber, search) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(
      `${apiURL}admin/seo-optimize?page=${pageNumber ? pageNumber : 1}&search=${
        search ? search : ""
      }`
    );

    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

//
export const getAllBrandCategories =
  (brandId, pageNumber, search) => async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.get(
        `${apiURL}admin/seo-brand-categories/${brandId}?page=${
          pageNumber ? pageNumber : 1
        }&search=${search ? search : ""}`
      );

      return res.data;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(
            alert(error.msg, "danger", {
              marginTop: "3em",
              marginBottom: "2em",
            })
          );
        });
      }
    }
  };

// get categoreis detail
export const getDetails = (brandId, categoryId, search) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/seo-detail/${brandId}/${categoryId}`
    );

    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

// get categoreis detail
export const updateMapData = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/update-map-nonmap-by-category`,
      data
    );

    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

// get categoreis detail
export const detailUpdate = (brandId, categoryId, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/seo-detail/${brandId}/${categoryId}`,
      data
    );

    return dispatch(
      alert(res.data, "success", { marginTop: "3em", marginBottom: "2em" })
    );
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

// get genrateSeoTitle
export const genrateSeoTitle =
  (brandId, categoryId, title) => async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.post(`${apiURL}admin/get-generated-seo-title`, {
        brandId,
        categoryId,
        title,
      });

      return res.data;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(
            alert(error.msg, "danger", {
              marginTop: "3em",
              marginBottom: "2em",
            })
          );
        });
      }
    }
  };

export const addKeyword = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/keywords`, { data });
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

//get keyword
export const getKeyword = (search, page) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/keywords?page=${page ? page : 1}&&search=${
        search ? search : ""
      }`
    );

    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};

//get keyword
export const seoTilteAndDescGenerate = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/seo-title-description-generator/${id}`
    );

    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          alert(error.msg, "danger", { marginTop: "3em", marginBottom: "2em" })
        );
      });
    }
  }
};
