import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { getAllBlogTags } from "../blogService/blogService";
import { updateBlogPage } from "../blogService/blogPagesService";
import { useLocation, useNavigate } from "react-router";
import TopBarProgress from "react-topbar-progress-indicator";

const EditBlog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state;
  const blogId = item?._id;

  const initialBlogContentState = {
    blogTitle: "",
    seoTitle: "",
    seoDescription: "",
  };
  const [blogContent, setBlogContent] = useState(initialBlogContentState);
  const [status, setStatus] = useState(true);
  const [blogImage, setBlogImage] = useState("");
  const [blogTags, setBlogTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isBase64, setIsBase64] = useState(false);
  const [blogDescription, setBlogDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  const resetForm = () => {
    setBlogContent(initialBlogContentState);
    setBlogImage("");
    setBlogDescription("");
    setSelectedTags([]);
    setStatus(false);
    setIsBase64(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogContent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTagChange = (event) => {
    const tagId = event.target.value;
    if (event.target.checked) {
      setSelectedTags([...selectedTags, tagId]);
    } else {
      setSelectedTags(selectedTags.filter((id) => id !== tagId));
    }
  };

  const updateBlogPageContent = async () => {
    setDisable(true);
    try {
      const data = await updateBlogPage(
        blogContent,
        blogDescription,
        selectedTags,
        status,
        isBase64,
        blogImage,
        blogId
      );
      console.log("data is : ", data);
      resetForm();
      navigate(-1);
    } catch {
    } finally {
      setDisable(false);
      setLoading(false);
    }
  };

  const updateStatus = (e) => {
    const { value } = e.target;
    if (value === "Publish") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  useEffect(() => {
    const fetchDataAndSetBlogData = async () => {
      setLoading(true);
      try {
        const tagsData = await getAllBlogTags();
        setBlogTags(tagsData?.blogTags);

        if (item) {
          const tagIds = item.tags.map((tag) => tag._id);
          setBlogContent({
            blogTitle: item.title || "",
            seoTitle: item.seoTitle || "",
            seoDescription: item.seoDescription || "",
          });
          setStatus(item.status !== undefined ? item.status : true);
          setBlogImage(item.image || "");
          setBlogDescription(item.description || "");
          setSelectedTags(tagIds);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndSetBlogData();
  }, [item]);

  const toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await toBase64(file);
    setBlogImage(base64);
    setIsBase64(true);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setBlogDescription(data);
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <div>
          <div className="form-group">
            <label
              htmlFor="title"
              style={{ fontSize: "1.2rem", fontWeight: 600 }}
            >
              Blog Title
            </label>
            <input
              style={{ fontSize: ".9rem" }}
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Title Here"
              name="blogTitle"
              value={blogContent.blogTitle}
              onChange={handleInputChange}
            />
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={blogDescription}
            onChange={handleEditorChange}
            config={{
              placeholder: "Enter your description here...",
              width: "100%",
              height: "300px",
            }}
          />

          <div style={{ marginTop: ".8rem" }}>
            <h4>All Blogs Tags</h4>
            {blogTags.map((tag) => (
              <div key={tag?._id}>
                <input
                  type="checkbox"
                  id={tag?._id}
                  value={tag?._id}
                  onChange={handleTagChange}
                  checked={selectedTags.includes(tag?._id)}
                  style={{ marginRight: ".3rem" }}
                />
                <label htmlFor={tag?._id}>{tag?.name}</label>
              </div>
            ))}
          </div>

          <br />
          <div className="d-flex align-items-center">
          <div className="form-group" style={{ width: "25rem" }}>
            <label style={{ fontWeight: 600, fontSize: "1.2rem" }}>
              Image upload
            </label>

            <div className="input-group col-xs-12">
              <input
                type="file"
                // value={blogImage}
                className="form-control"
                onChange={(e) => handleFileChange(e)}
                style={{ fontSize: ".8rem" }}
              />
            </div>
          </div>

          {/* status */}
          <div style={{marginLeft: "4rem"}}>
            <h4>Status</h4>
            <div style={{ display: "flex"}}>
              <div style={{ marginRight: "10px", fontSize: ".8rem" }}>
                <input
                  type="radio"
                  id="seoDescription1"
                  name="status"
                  value="Publish"
                  checked={status === true}
                  onChange={updateStatus}
                />
                <label
                  htmlFor="seoDescription1"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    marginLeft: ".3rem",
                  }}
                >
                  Publish
                </label>
              </div>
              <div style={{ fontSize: ".8rem", marginLeft: "1rem" }}>
                <input
                  type="radio"
                  id="seoDescription2"
                  name="status"
                  value="Hide"
                  checked={status === false}
                  onChange={updateStatus}
                />
                <label
                  htmlFor="seoDescription2"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    marginLeft: ".3rem",
                  }}
                >
                  Hide
                </label>
              </div>
            </div>
          </div>
          </div>

          {/* Status publish / hide */}
          

          {/* SEO Title */}
          <div className="form-group">
            <label
              htmlFor="title"
              style={{ fontSize: "1.2rem", fontWeight: 600 }}
            >
              Seo Title Here
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter SEO Title Here"
              name="seoTitle"
              value={blogContent.seoTitle}
              onChange={handleInputChange}
              style={{ fontSize: ".9rem" }}
            />
          </div>

          {/* SEO Description */}
          <div className="form-group">
            <label
              htmlFor="seoDescription"
              style={{ fontSize: "1.2rem", fontWeight: 600 }}
            >
              Seo Description Here
            </label>
            <textarea
              className="form-control"
              id="seoDescription"
              placeholder="Enter SEO Description Here ..."
              name="seoDescription"
              value={blogContent.seoDescription}
              onChange={handleInputChange}
              style={{ fontSize: ".9rem", height: "5rem", lineHeight: 1.6 }}
            />
          </div>

          <button
            disabled={disable}
            className="btn btn-primary"
            style={{ fontSize: "1.1rem", maxWidth: "12rem", width: "100%" }}
            onClick={updateBlogPageContent}
          >
            Update Blog
          </button>
        </div>
      )}
    </>
  );
};

export default EditBlog;
