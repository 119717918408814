/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxComponent from "../CreateFilters/Components/CheckBoxComponent";
import { apiURL } from "../../../config/default";
import axios from "axios";
import { Button } from "react-bootstrap";
import Select from "react-select";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));

function GlobalFilters() {
  useEffect(() => {
    (async () => {
      const { data: attributes } = await axios.get(`${apiURL}admin/attribute`);
      setattributes([...attributes.attributes]);
      setloading(false);
    })();
  }, []);

  const getSettings = async (id) => {
    setloading(true);
    const { data: settings } = await axios.get(
      `${apiURL}admin/product-attribute/${id}`
    );
    setloading(false);

    if (settings.attributes.length !== 0) {
      const data = settings;
      if (!data.attributes)
        data.values = [
          {
            attributeId: "",
            title: "",
            showImage: false,
            isShow: true,
            imageURL: "",
            description: "",
          },
        ];
      else {
        data.values = data.attributes.map((val) => ({
          ...val,
          isbase64: false,
        }));
      }

      setstate({ ...data, _id: true, isbase64: false });
    } else {
      setstate({ values: [], _id: true, isbase64: false });
    }
  };

  const [state, setstate] = useState({});
  const [attributes, setattributes] = useState([]);

  const [loading, setloading] = useState(true);

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          attributeId: "",
          title: "",
          showImage: false,
          isShow: true,
          imageURL: "",
          description: "",
        },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };
    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dataToSend = { values: [...state.values] };

    setloading(true);

    try {
      await axios.post(`${apiURL}admin/product-attribute/update`, {
        ...dataToSend,
      });
      toast.success("Update Successful", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Something went wrong 😟", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setloading(false);
    }
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  // const classes = useStyles();

  return (
    <div className="container-scroller">
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <ToastContainer />
      {loading && <TopBarProgress />}
      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          {" "}
          Custom Settings{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/filter-category">Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div
            className="card p-0"
            style={{
              border: "1px solid #dcdcdc",
            }}
          >
            <div className="card-body p-0">
              <h4
                className="card-title"
                style={{
                  color: "#4c4d5a",
                  borderBottom: "1px solid #dcdcdc",
                  background: "#f6f6f6",
                  textShadow: "0 -1px 0 rgba(50,50,50,0)",
                  padding: 12,
                }}
              >
                Globel Filters{" "}
              </h4>
              <form onSubmit={handleSubmit} className="forms-sample m-2">
                <div className="container-fluid">
                  <div className="col-md-12">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={attributes.map((a) => ({
                        value: a._id,
                        label: a.name,
                      }))}
                      onChange={(e) => getSettings(e.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      {state.values?.map((value, index) => (
                        <CheckBoxComponent
                          imageURL={value.imageURL}
                          title={value.title}
                          showImage={value.showImage}
                          showThisFilter={value.isShow}
                          state={state}
                          value={value}
                          description={value.description ?? ""}
                          handleRemoveField={handleRemoveField}
                          index={index}
                          setstate={setstate}
                          handleGetAttributes={() => {}}
                          attributes={[]}
                          SecondLevelAttribute={() => {}}
                          convertImgToBase64={convertImgToBase64}
                          productAttribute={""}
                          isAuto={true}
                        />
                      ))}

                      <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-center mt-3">
                          <Button
                            className=" btn btn-primary"
                            onClick={handleAddField}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  className="ml-2 mt-3 btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "loading..." : "Submit"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalFilters;
