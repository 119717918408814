import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  communityInfluencers,
  deleteCommunityInfluencer,
} from "../../../actions/communityInfluencer";

const CommunityInfluencer = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });

  // Api calls for get Order
  const getCommunityInfluencers = useCallback(
    async (pageNo) => {
      const res = await dispatch(communityInfluencers(pageNo));
      setData(res);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getCommunityInfluencers(pagination.currentPage);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date, pagination.currentPage, isMount]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/community-influencers",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const deleteCommunityInfluencers = async (id) => {
    setloading(true);
    const res = await dispatch(deleteCommunityInfluencer(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className="process-orders-table">
            <table className="mt-5">
              <thead>
                <tr>
                  <th>Sn#</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Data?.length ? (
                  <>
                    {Data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td
                          className="order-item-number"
                          onClick={() =>
                            navigate(
                              `/admin/edit-community-influencer/?id=${item._id}`
                            )
                          }
                        >
                          {i + 1}
                        </td>
                        <td>{item.title}</td>

                        <td>{item.description.substring(0, 25)}</td>

                        <td>{moment(item?.createdAt).format("l")}</td>
                        <td>
                          <CustomeTableBtn
                            color="secondary"
                            width={"100px"}
                            className={"mr-2"}
                            onClick={() =>
                              navigate(
                                `/admin/edit-community-influencer/?id=${item._id}`
                              )
                            }
                          >
                            Edit
                          </CustomeTableBtn>

                          <CustomeTableBtn
                            color="danger"
                            width={"100px"}
                            onClick={() =>
                              setdeleteConfirm({
                                open: true,
                                id: item?._id,
                              })
                            }
                          >
                            Delete
                          </CustomeTableBtn>

                          {deleteConfirm.open && (
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, delete it!"
                              confirmBtnBsStyle="danger"
                              title="Are you sure?"
                              onConfirm={(e) => {
                                deleteCommunityInfluencers(deleteConfirm.id);
                              }}
                              onCancel={() =>
                                setdeleteConfirm({
                                  open: false,
                                  id: null,
                                })
                              }
                              focusCancelBtn
                            >
                              Are you sure you want to delete?
                            </SweetAlert>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={9} className=" text-center">
                        {" "}
                        No Record Found
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className=" d-flex justify-content-center my-4">
            <Pagination
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CommunityInfluencer;
