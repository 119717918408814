import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";
import { isArray } from "jquery";

export const usersRetreive = (pageNo, search) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-all-users?page=${pageNo ? pageNo : ""}&search=${
        search ? search : ""
      }`
    );
    return res?.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const deleteUser = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.delete(`${apiURL}admin/delete-user/${id}`);
    return res?.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const getReferalUsers = () => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-users-refralcount`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const paidUsersRetreive = () => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/get-all-paid-users`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const getUsersDetails = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-user-details/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const getVendorDetails = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-vendor-details/${id}`,
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const createVendor = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(
      `${apiURL}admin/create-vendor`,
      JSON.stringify(formData),
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (isArray(errors)) {
      if (errors) {
        errors.forEach((error) => {
          dispatch(
            alert(error.msg, "danger", {
              marginTop: "3em",
              marginBottom: "2em",
            })
          );
        });
      }
    } else {
      dispatch(
        alert(errors, "danger", { marginTop: "3em", marginBottom: "2em" })
      );
    }
  }
};

export const updateVendor = (formData, id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(
      `${apiURL}admin/update-vendor/${id}`,
      JSON.stringify(formData),
      _config
    );
    return res.data;
  } catch (err) {
    console.log(err);
    // const errors = err.response.data.error;
    // if (isArray(errors)) {
    //   if (errors) {
    //     errors.forEach((error) => {
    //       dispatch(
    //         alert(error.msg, 'danger', {
    //           marginTop: '3em',
    //           marginBottom: '2em',
    //         })
    //       );
    //     });
    //   }
    // } else {
    //   dispatch(
    //     alert(errors, 'danger', { marginTop: '3em', marginBottom: '2em' })
    //   );
    // }
  }
};

export const getReferralUsersDetails = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/get-referal-info/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const bulkUsers = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(
      `${apiURL}admin/bulk-insertion`,
      JSON.stringify(formData),
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (isArray(errors)) {
      if (errors) {
        errors.forEach((error) => {
          dispatch(
            alert(error.msg, "danger", {
              marginTop: "3em",
              marginBottom: "2em",
            })
          );
        });
      }
    } else {
      dispatch(
        alert(errors, "danger", { marginTop: "3em", marginBottom: "2em" })
      );
    }
  }
};

export const updateBrandFilters = (id, filterData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.post(
      `${apiURL}admin/update-brand-filters/${id}`,
      JSON.stringify(filterData),
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (isArray(errors)) {
      if (errors) {
        errors.forEach((error) => {
          dispatch(
            alert(error.msg, "danger", {
              marginTop: "3em",
              marginBottom: "2em",
            })
          );
        });
      }
    } else {
      dispatch(
        alert(errors, "danger", { marginTop: "3em", marginBottom: "2em" })
      );
    }
  }
};
