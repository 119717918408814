const StatusText = ({ condition, text, value }) => {
  const statusClass = condition ? "ok" : "warning";
  const statusSymbol = condition ? "ok" : "!";

  return (
    <p className="status-text">
      <span className={`status-box ${statusClass}`}>{statusSymbol}</span>
      {text}: <span>{value}</span>
    </p>
  );
};

// Export the StatusText component as default
export default StatusText;
