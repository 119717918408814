/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { verifyCogs } from "../../../../../actions/saleAnalysis";
import axios from "axios";
import { apiURL } from "../../../../../config/default";

const ShippingCostTabel = ({ SelectItem, IsMount, setIsMount }) => {
  const dispatch = useDispatch();
  const [allCompanies, setAllCompanies] = useState([]);

  const [loading, setloading] = useState(false);
  const [updateValue, setupdateValue] = useState({
    shippingFee: SelectItem?.orShipping?.shippingFee,
    quantity: SelectItem?.orShipping?.quantity
      ? SelectItem?.orShipping?.quantity
      : SelectItem?.quantity ?? "",
    type: SelectItem?.orShipping?.type ?? "",
    status: SelectItem?.orShipping?.status ?? true,
    companyName: "",
  });

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    try {
      const res = await axios.get(`${apiURL}admin/shipping-company`);
      const filteredCompanies = res.data.filter(
        (company) => company.show === true
      );

      setAllCompanies(filteredCompanies);
    } catch (err) {
      console.log(err);
    } finally {
      setloading(false);
    }
  };

  const updateVerifyShipping = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const res = await dispatch(
        verifyCogs(SelectItem?._id, {
          orShipping: {
            quantity: updateValue?.quantity,
            type: updateValue?.type,
            status: true,
            companyName: updateValue?.companyName,
            shippingFee: updateValue?.shippingFee
              ? updateValue?.shippingFee
              : undefined,
          },
        })
      );
      if (res) {
        setloading(false);
        setIsMount(!IsMount);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handleChnageValue = (e) => {
    const { name, value } = e.target;
    setupdateValue((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <form onSubmit={updateVerifyShipping}>
        <div className="my-3 px-3">
          <table className="cogs-table">
            <tbody>
              <tr>
                <th>Variable</th>
                <th>Default</th>
                <th>Overide value </th>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Shipping Cost</span>
                </td>
                <td>
                  {SelectItem?.shippingCost
                    ? `$${SelectItem?.shippingCost}`
                    : "N/A"}
                </td>
                <td>
                  <input
                    className=" border-0"
                    type="text"
                    name="shippingFee"
                    value={updateValue?.shippingFee}
                    placeholder="Shipping cost"
                    onChange={(e) =>
                      setupdateValue({
                        ...updateValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Shipping Type</span>
                </td>
                <td>{SelectItem?.shipType}</td>
                <td>
                  <select
                    name="type"
                    defaultValue={updateValue?.type}
                    required={
                      updateValue.shippingFee === 0 || updateValue.shippingFee
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      setupdateValue({
                        ...updateValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Shipping</option>
                    <option value="standard">Standard</option>
                    <option value="WG">White Glove</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Quantity</span>
                </td>

                <td>{SelectItem?.quantity}</td>
                <td>
                  <select
                    name="quantity"
                    defaultValue={updateValue?.quantity}
                    onChange={handleChnageValue}
                  >
                    <option value="" disabled>
                      Select Quantity
                    </option>

                    <option value="1">Total</option>
                    <option value={SelectItem?.quantity}>
                      {SelectItem?.quantity}
                    </option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>
                  <span className=" font-weight-bold">Sug. Carrier Type</span>
                </td>
                <td>
                  {SelectItem?.companyName ? SelectItem?.companyName : "N/A"}
                </td>{" "}
                <td>
                  <select
                    name="companyName"
                    value={updateValue?.companyName || ""}
                    onChange={(e) =>
                      setupdateValue({
                        ...updateValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="">Please select</option>
                    {allCompanies.map((value, i) => (
                      <option key={i} value={value?.name}>
                        {value?.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Shipping Fee</span>
                </td>

                <td>
                  {SelectItem?.shippingFee ? `$${SelectItem?.shippingFee}` : ""}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span className=" font-weight-bold">Total</span>
                </td>

                <td>
                  $
                  {(
                    Number(SelectItem?.shippingFee) +
                    Number(SelectItem?.shippingCost) *
                      Number(SelectItem?.quantity)
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(updateValue.shippingFee * updateValue?.quantity).toFixed(
                    2
                  ) !== "NaN"
                    ? (updateValue.shippingFee * updateValue?.quantity).toFixed(
                        2
                      )
                    : 0}
                </td>
              </tr>
            </tbody>
          </table>

          <button
            type="submit"
            // onClick={updateVerifyShipping}
            className="update-Order-button text-white bg-success mt-3 "
          >
            {loading ? "Loading ..." : "Verify Shipping Cost Override"}
          </button>
        </div>
      </form>
    </>
  );
};

export default ShippingCostTabel;
