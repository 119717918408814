import { CircularProgress, Pagination } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../components/CustomeFilter/SelectSearchFilter";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  addAdditionalPrice,
  getVendorProducts,
} from "../../../actions/vendorSetting";
import { useDispatch } from "react-redux";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import moment from "moment";
import OrderStatusButton from "../order/components/OrderStatusButton";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";

const VendorProductCatagory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { brandId, categoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isRender, setIsRender] = useState(false);

  const [Data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [SearchFillter, setSearchFillter] = useState({
    sku: "",
    filter: "",
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const fetchData = useCallback(
    async (page, brandId, categoryId, sku, filter) => {
      setLoading(true);

      const res = await dispatch(
        getVendorProducts(page, brandId, categoryId, sku, filter)
      );
      if (!selectedItem) {
        setSelectedItem({});
      } else {
        const newSelected = res?.products?.find(
          (item) => item._id === selectedItem._id
        );
        setSelectedItem(newSelected);
      }
      if (res) {
        setLoading(false);
        setData(res?.products);
        setpagination(res?.pagination);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data, brandId, categoryId]
  );
  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(
        pagination?.currentPage,
        brandId,
        categoryId,
        SearchFillter?.sku,
        SearchFillter?.filter
      );
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination?.currentPage,
    SearchFillter?.sku,
    SearchFillter?.filter,
    isRender,
  ]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  function getTotalCubicUnits(data) {
    // console.log(data);
    return data
      .reduce((total, item) => {
        const volume = (item.length * item.width * item.height) / item?.weight;
        return total + volume;
      }, 0)
      .toFixed(2);
  }

  const handleActions = (item) => {
    setEdit({
      open: true,
      edit: item?.title,
      title: "Add Addtional Price",
    });
    setSelectedItem({ ...item });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(
        addAdditionalPrice({
          id: selectedItem?._id,
          additionalPrice: selectedItem?.additionalPrice,
        })
      );
      if (res) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  console.log(Data);
  return (
    <>
      <>
        <div className=" d-flex  justify-content-end align-center flex-wrap gap-10">
          <SearchFilter
            type="text"
            placeholder={"Search by sku title "}
            value={SearchFillter?.sku}
            onChange={(e) =>
              setSearchFillter({
                ...SearchFillter,
                sku: e.target.value,
              })
            }
          />

          <SelectSearchFilter
            title={"Price Type"}
            placeholder="Select"
            options={[
              { value: "Has Additional Price", label: "Has Additional Price" },
              { value: "No Additional Price", label: "No Additional Price" },
              { value: "On Promo", label: "On Promo" },
            ]}
            onChange={(value) =>
              setSearchFillter({
                ...SearchFillter,
                filter: value,
              })
            }
          />
        </div>

        <div className="process-orders-table m-0">
          <table className=" w-100 my-4">
            <thead>
              <tr>
                <th>sn#</th>
                <th>Title</th>
                <th>Item</th>
                <th>SKU</th>
                <th>QTY</th>
                <th>Price</th>
                <th>On Promo</th>
                <th>Promo End Data</th>
                <th>Addtional Price</th>
                <th>Cost</th>
                <th>Shippping Cost</th>
                <th>Total Cube (fit)</th>
                <th>Box Id</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && <TableLoader rowsNum={8} cellNum={14} />}

              {!loading && (
                <>
                  {Data?.map((item, index) => (
                    <tr key={item?._id}>
                      <td>{index + 1}</td>

                      <td>{item?.title}</td>
                      <td>
                        <img
                          src={item.mainImage}
                          className=" border-grey border-radius-10"
                          height="60px"
                          width="60px"
                          alt="item"
                        />
                      </td>
                      <td>
                        <a
                          href={
                            process.env.REACT_APP_ENVIROMENT === "DEV"
                              ? `https://qasundial.sundialhome.com/product/${item?.slug}`
                              : `https://www.sundialhome.com/product/${item?.slug}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.sku}
                        </a>
                      </td>

                      <td>{item?.quantity}</td>

                      <td>${item?.salePrice}</td>
                      <td>
                        {item?.onSale ? (
                          <span className={item?.onSale ? "text-success" : ""}>
                            Promo
                          </span>
                        ) : (
                          <span className={item?.onSale ? "text-success" : ""}>
                            Standard
                          </span>
                        )}
                      </td>

                      <td>
                        {item?.promoCostEndDate
                          ? moment(item?.promoCostEndDate).format("l")
                          : ""}
                      </td>
                      <td>
                        ${item?.additionalPrice ? item?.additionalPrice : 0}
                      </td>
                      <td className={item?.onSale ? "text-success" : ""}>
                        ${item?.onSale ? item?.promoCost : item?.cost}
                      </td>

                      <td>${item?.shippingCost}</td>
                      {/* <td>${item?.cost}</td> */}
                      <td>{getTotalCubicUnits(item?.shipping?.dimensions)}</td>

                      <td>
                        {item?.shipping?.boxId.split("-").map((segment) => (
                          <span className=" d-block">{segment}</span>
                        ))}
                      </td>
                      <td>
                        <OrderStatusButton
                          onClick={() => handleActions(item)}
                          width={150}
                          status={"+add"}
                        >
                          <span className="ml-2">Edit Cost</span>
                        </OrderStatusButton>
                      </td>
                    </tr>
                  ))}
                </>
              )}

              {!Data?.length && (
                <>
                  <tr>
                    <td colSpan={14} className=" text-center">
                      "No record Found"
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <EditTabelDrawer
            Edit={Edit}
            setEdit={setEdit}
            shippingId={Edit?.edit}
          >
            {loading ? (
              <div className=" d-flex align-center justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="px-3">
                  <input
                    className=" border-radius-10 p-3 border-grey w-100"
                    type="number"
                    required
                    value={
                      selectedItem?.additionalPrice
                        ? selectedItem?.additionalPrice
                        : 0
                    }
                    placeholder="Additional Price"
                    onChange={(e) =>
                      setSelectedItem({
                        ...selectedItem,
                        additionalPrice: Number(e.target.value),
                      })
                    }
                  />
                  <div className=" mt-4  ">
                    <button className="btn  btn-success  w-100">
                      {loading
                        ? "Loading...."
                        : `Update & Save SKU | Key Price $${
                            selectedItem?.productSalePrice +
                            selectedItem?.additionalPrice
                          }`}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </EditTabelDrawer>
        </div>
        <div className=" d-flex justify-content-center my-4">
          <Pagination
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
          />
        </div>
      </>
    </>
  );
};

export default VendorProductCatagory;
