import React from "react";

const DashboardPagesLayout = ({
  pageTitle,
  subTitle,
  count,
  children,
  className,
}) => {
  return (
    <div className={`dashboardCardContainer `}>
      <div className="px-4 py-3 d-flex justify-content-between align-center">
        <div className="">
          <h4 className=" font-weight-700 m-0">{pageTitle}</h4>
          {pageTitle !== "Cashback" ? (
            <p className=" text-muted">
              {count} {subTitle}
            </p>
          ) : (
            <p className=" text-muted">
              You Crrently hold{" "}
              <span className="text-success font-weight-700"> ${count} </span>
              in cashback credits!You may use cashback towards your next
              purchase.
            </p>
          )}
        </div>
      </div>
      <hr className=" m-0" />
      <div>{children}</div>
    </div>
  );
};

export default DashboardPagesLayout;
