import React from "react";
import { Calculation } from "../../../../../utils/Calculation";

const CostBreakDown = ({ SelectItem }) => {
  return (
    <>
      <div className=" px-3 py-4">
        <div>
          <h4 className="font-weight-bold ">Cost Breakdown</h4>

          <div className="order-summary-container my-4">
            <div>
              <div className=" d-flex justify-content-between item-center font-weight-bold">
                <span>Order Total:</span>
                <span>${Calculation(SelectItem, "OrderTotal")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>COGS Total:</span>
                <span>${Calculation(SelectItem, "COGS")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>DS Fee 1 Total:</span>
                <span>${Calculation(SelectItem, "dsFeeOneTotal")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>DS Fee 2 Total:</span>
                <span>${Calculation(SelectItem, "dsFeeTwoTotal")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Shipping Total:</span>
                <span>${Calculation(SelectItem, "shippingTotal")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center">
                <span>Tax:</span>
                <span>${SelectItem?.itemTax}</span>
              </div>
              {SelectItem?.refunds?.status && (
                <div className=" d-flex justify-content-between item-center">
                  <span>Refund:</span>
                  <span>${SelectItem?.refunds?.itemPrice}</span>
                </div>
              )}
              <hr />
              <div className=" d-flex justify-content-between item-center font-weight-bold text-success">
                <span>Profit:</span>
                <span>${Calculation(SelectItem, "profit")}</span>
              </div>
              <div className=" d-flex justify-content-between item-center font-weight-bold text-success">
                <span>Margin %:</span>
                <span>{Calculation(SelectItem, "profitPercentage")}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className=" m-0" />
    </>
  );
};

export default CostBreakDown;
