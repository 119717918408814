import React, { Fragment, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import CutomeDatePicker from "../../../../components/DatePicker/CutomeDatePicker";
import { useDispatch } from "react-redux";
import {
  UpdateOrderTrackingStatus,
  getAllOrders,
  getInsuranceQuote,
} from "../../../../actions/order";
import moment from "moment";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import { BsCalendar2WeekFill } from "react-icons/bs";
import { Calculation } from "../../../../utils/Calculation";
import StatusText from "./StatusText";

const getStyles = (key) => {
  switch (key) {
    case "Pending":
      return {
        background: "#fafafa",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };
    case "Processing":
      return {
        background: "#fff8e6",
        color: "#ffb706",
        borderColor: "#ffb706",
      };
    case "Preparing to Ship":
      return {
        background: "#e7f6ee",
        color: "#005a52",
        borderColor: "#005a52",
      };
    case "In Transit":
      return {
        background: "#e8f6fd",
        color: "#19aeee",
        borderColor: "#19aeee",
      };
    case "Arriving Soon":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delayed":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delivered":
      return {
        background: "#e1e5e8",
        color: "#234b5f",
        borderColor: "#234b5f",
      };
    case "Backordered":
      return {
        background: "#efe9ec",
        color: "#652a47",
        borderColor: "#652a47",
      };

    case "Not Available":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };
    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {
        background: "#ffffff",
        color: "#a1a1a1",
        borderColor: "#a1a1a1",
      };
  }
};

const getButtonStyles = (key) => {
  switch (key) {
    case "Pending":
    case "Processing":
      return {
        background: "#ffffff",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };

    case "Preparing to Ship":
      return {
        background: "#005a52",
        color: "#ffffff",
        borderColor: "#005a52",
      };
    case "In Transit":
      return {
        background: "#19aeee",
        color: "#ffffff",
        borderColor: "#19aeee",
      };

    case "Delayed":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delivered":
      return {
        background: "#023047",
        color: "#fff",
        borderColor: "#234b5f",
      };
    case "Backordered":
      return {
        background: "#652a47",
        color: "#fff",
        borderColor: "#652a47",
      };

    case "Not Available":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };
    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {
        background: "#ffffff",
        color: "#a1a1a1",
        borderColor: "#a1a1a1",
      };
  }
};

const CompleteButton = (key) => {
  switch (key) {
    case "Pending":
    case "Processing":
    case "Preparing to Ship":
    case "In Transit":
    case "Delivered":
    case "Not Available":
      return {
        background: "#ffffff",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };
    case "Backordered":
      return {
        background: "#652a47",
        color: "#ffffff",
        borderColor: "#652a47",
      };

    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {};
  }
};

const UpdateOrderTracking = ({
  status,
  Date,
  textNote,
  isComplete,
  SelectItem,
  statusName,
  DateLable,
  setIsMount,
  IsMount,
  buttonText,
  isActive,
  DateTitle,
  isDisable,
  backorderStatus,
}) => {
  const dispatch = useDispatch();
  const [Open, setOpen] = useState(false);
  const [TrackingDate, setTrackingDate] = useState(
    SelectItem?.estimateShipDate?.shippingInfo
  );
  const [insurance, setInsurance] = useState(null);
  const [sendMail, setSendMail] = useState(
    SelectItem?.status !== "Pending" ? true : false
  );
  const [Loader, setLoader] = useState(false);
  const [insuranceQuoteConfirmed, setInsuranceQuoteConfirmed] = useState(false);
  const [insuranceData, setInsuranceData] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const orderTotal = Calculation(SelectItem, "OrderTotal");
  const isOrderValueOk = orderTotal > 300;
  const isBrandOk = SelectItem?.brandId?.fullName !== "";
  const isCarrierOk = SelectItem?.companyName !== "";
  const handleUpdateOrderClick = () => {
    setShowConfirmation(true);
  };
  const handleChange = (e) => {
    setTrackingDate(e.target.value);

    setInsurance(null);
    setInsuranceQuoteConfirmed(false);
  };
  const updateTracking = async (e, status) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await dispatch(
        UpdateOrderTrackingStatus(SelectItem?._id, {
          orderStatus: status,
          estimateDate: TrackingDate,
          sendMail: sendMail,
          insurance: insurance,
        })
      );
      if (res) {
        setLoader(false);
        setIsMount(!IsMount);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const getInsurance = async (e, id) => {
    e.preventDefault();
    try {
      const postData = {
        shipDate: TrackingDate,
      };

      const resp = await dispatch(getInsuranceQuote(SelectItem?._id, postData));

      if (resp) {
        setInsuranceQuoteConfirmed(true);
        setInsuranceData(resp);
        const pageNumber = 1;
        const searchQuery = "";
        const status = "";
        const charge = "";
        const date = "";

        dispatch(getAllOrders(pageNumber, searchQuery, status, charge, date));
      }
    } catch (error) {
      console.error("Error in getting insurance:", error);
      setInsuranceQuoteConfirmed(false);
    }
  };
  const handleInsuranceSelection = (value) => {
    setInsurance(value);
  };
  console.log(insuranceData);

  useEffect(() => {}, [SelectItem]);
  return (
    <div
      className={`status-radio-button p-0 ${isComplete ? "bg-muted" : ""}`}
      style={isActive === status ? getStyles(status) : {}}
    >
      <div className=" status-inner-container p-0">
        <div
          className={` d-flex justify-content-between align-center  font-weight-bold p-3 ${
            isComplete ? "text-muted" : ""
          }`}
          onClick={() => setOpen(!Open)}
        >
          <div>
            <p>
              {status}{" "}
              {status === "Backordered"
                ? backorderStatus
                  ? backorderStatus
                  : ""
                : ""}
            </p>
          </div>
          <div>
            <MdArrowDropDown
              style={Open ? { rotate: "180deg" } : {}}
              className="h2 mb-0"
            />
          </div>
        </div>
        {Open && (
          <>
            {!isComplete ? (
              <>
                <form onSubmit={(e) => updateTracking(e, statusName)}>
                  {textNote && (
                    <p
                      className="font-weight-bold p-3"
                      style={{ lineHeight: "1rem" }}
                    >
                      {textNote}
                    </p>
                  )}
                  {!isDisable && (
                    <p
                      className="font-weight-bold p-3"
                      style={{ color: "red", lineHeight: "1rem" }}
                    >
                      Please Choose delivery date first
                    </p>
                  )}
                  <>
                    {status !== "Not Available" && (
                      <div className="p-3 status-text font-weight-bold">
                        <CutomeDatePicker
                          label={DateLable}
                          value={TrackingDate}
                          onChange={(e) => handleChange(e)}
                          disabled={!isDisable}
                        />
                      </div>
                    )}
                    <div className="insurance-wrapper bg-light p-3">
                      <p
                        className="font-weight-bold mt-3 mb-2 status-text"
                        style={{ lineHeight: "1rem" }}
                      >
                        Apply insurance to this shipment?
                      </p>
                      <StatusText
                        condition={isOrderValueOk}
                        text="Order value"
                        value={`$${orderTotal}`}
                      />
                      <StatusText
                        condition={isBrandOk}
                        text="Brand"
                        value={SelectItem?.brandId?.fullName || "N/A"}
                      />
                      <StatusText
                        condition={isCarrierOk}
                        text="Carrier"
                        value={SelectItem?.companyName || "N/A"}
                      />
                      {insuranceQuoteConfirmed === true ? (
                        Calculation(SelectItem, "OrderTotal") >= 300 &&
                        SelectItem?.brandId?.fullName !== "" &&
                        SelectItem?.companyName !== "" ? (
                          <div>
                            <button
                              type="button"
                              className={`insurance-button ${
                                insurance === true ? "border-dark" : ""
                              }`}
                              style={
                                insurance === true
                                  ? { backgroundColor: "rgb(232, 246, 253)" }
                                  : {}
                              }
                              onClick={() => handleInsuranceSelection(true)}
                            >
                              <span className="recommendation-tag">
                                Recommended
                              </span>
                              Insure Shipment | +$
                              {
                                insuranceData?.orderItem?.upsCapital
                                  ?.premiumAmount
                              }
                              {/* {insuranceData?.orderItem?.upsCapital?.status} */}
                            </button>
                            <button
                              type="button"
                              className={`insurance-button ${
                                insurance === false ? "border-dark" : ""
                              }`}
                              style={
                                insurance === false
                                  ? { backgroundColor: "rgb(232, 246, 253)" }
                                  : {}
                              }
                              onClick={() => handleInsuranceSelection(false)}
                            >
                              Do Not Insure Shipment
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              type="button"
                              className={`insurance-button ${
                                insurance === true ? "border-dark" : ""
                              }`}
                              style={
                                insurance === true
                                  ? { backgroundColor: "rgb(232, 246, 253)" }
                                  : {}
                              }
                              onClick={() => {
                                setInsurance(true);
                              }}
                            >
                              Insure Shipment | +${" "}
                              {
                                insuranceData?.orderItem?.upsCapital
                                  ?.premiumAmount
                              }
                              {/* {insuranceData?.orderItem?.upsCapital?.status} */}
                            </button>
                            <button
                              type="button"
                              className={`insurance-button ${
                                insurance === false ? "border-dark" : ""
                              }`}
                              style={
                                insurance === false
                                  ? { backgroundColor: "rgb(232, 246, 253)" }
                                  : {}
                              }
                              onClick={() => {
                                setInsurance(false);
                              }}
                            >
                              <span className="recommendation-tag">
                                Recommended
                              </span>
                              Do Not Insure Shipment
                            </button>
                          </div>
                        )
                      ) : (
                        <button
                          type="button"
                          className="insurance-button"
                          onClick={getInsurance}
                          disabled={!isDisable || !TrackingDate}
                        >
                          Check Insurance
                        </button>
                      )}
                      {}
                      {Calculation(SelectItem, "OrderTotal") >= 300 &&
                      SelectItem?.brandId?.fullName !== "" &&
                      SelectItem?.companyName !== "" ? (
                        <div></div>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    {status === "Preparing to Ship" ||
                    status === "In Transit" ||
                    status === "Delivered" ? (
                      <div className="d-flex align-center px-3">
                        <Checkbox
                          checked={sendMail}
                          onChange={(e) => setSendMail(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ padding: "5px" }}
                        />
                        <span className=" text-black">Email Send</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {status === "Backordered" && (
                      <>
                        <p className="">Customer Action Date: mm/dd/yyyy</p>
                      </>
                    )}

                    <div className="px-3 pb-3">
                      {" "}
                      {showConfirmation ? (
                        <>
                          <button
                            type="submit"
                            className="btn btn-success w-100 mt-2"
                          >
                            Confirm
                          </button>
                          <button
                            type="submit"
                            className="btn btn-danger w-100 mt-2"
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="update-Order-button"
                          style={getButtonStyles(status)}
                          onClick={handleUpdateOrderClick}
                          disabled={!isDisable}
                        >
                          {Loader
                            ? "Loading ... "
                            : `Update Status To ${status}`}
                        </button>
                      )}
                    </div>
                  </>
                </form>
              </>
            ) : (
              <div>
                <p className=" mt-3 text-black font-weight-bold">{textNote}</p>
                {status === "Backordered" && (
                  <>
                    <p className=" text-black font-weight-bold">
                      Est. Restocking Date: In 35 days,{" "}
                      {moment(SelectItem?.backorderStatus?.actionDate).format(
                        "l"
                      )}
                    </p>
                    <p className="text-black font-weight-bold">
                      Customer Action Date: 1 day ago, 5/15/2023
                    </p>
                  </>
                )}
                {DateTitle && (
                  <div className=" d-flex justify-content-between">
                    <p>{DateTitle}</p>
                    <div className=" d-flex justify-content-between align-center gap-10 ">
                      <span>{moment(Date?.estimateDate).format("l")} </span>
                      <span>
                        <BsCalendar2WeekFill size={20} />{" "}
                      </span>
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  className=" w-100 px-2 py-3 border-radius-5 mt-4 "
                  style={CompleteButton(status)}
                >
                  {buttonText} {moment(Date?.date).format("l")}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateOrderTracking;
