import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const CustomeSelect = ({ color, background, children, title, value }) => {
  const [Open, setOpen] = useState(false);

  return (
    <div className=" relative mb-3">
      <div
        onClick={() => setOpen(!Open)}
        className="d-flex justify-content-between align-center c-pointer border-grey px-3 py-1  "
        style={{
          background: background,
          color: color,
          border: `1px solid ${color} `,
          borderRadius: `${Open ? "10px 10px 0px 0px" : "10px"}`,
        }}
      >
        <div>
          <h4>{title}</h4>
          <p>{value}</p>
        </div>
        <div>
          <IoIosArrowDown size={30} />
        </div>
      </div>
      {Open && (
        <div
          style={{ borderRadius: "0px 0px 10px 10px" }}
          className="border-grey overflow-hidden  d-flex justify-content-between  flex-column "
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default CustomeSelect;
