import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./components/Header";

import "./styles.css";
import FormatCard from "./components/FormatCard";
import SuggestionKeywordCard from "./components/SuggestionKeywordCard";
import ExampleCard from "./components/ExampleCard";
import AvailableAttributes from "./components/AvailableAttributes";
import { getDetails, getKeyword } from "../../../../actions/seo";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

// editor
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
// import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState } from "draft-js";

const TitleAndDescriptionUpdate = () => {
  // hooks
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryId, brandId, brandName, categoryName } = useParams();

  // state
  const [loading, setloading] = useState(true);
  const [active1, setactive1] = useState(true);
  const [active2, setactive2] = useState(false);

  const [data, setData] = useState({
    isTitleOptimized: EditorState.createEmpty(),
    isDescriptionOptimized: null,
    title: null,
    description: null,
  });

  const [des, setDes] = useState(EditorState.createEmpty());

  const [attribute, setAttribute] = useState([]);
  const [searchKeyword, setsearchKeyword] = useState(null);
  const [keyword, setKeyword] = useState([]);
  const [seoId, setseoId] = useState(null);
  const fetchBrandCategories = async () => {
    const res = await dispatch(getDetails(brandId, categoryId));
    const {
      seoOptimize,
      seoOptimize: { _id },
      filters,
    } = res;

    setseoId(_id);

    if (seoOptimize?.description) {
      //set data to editor
      const blocksFromHtml = htmlToDraft(seoOptimize.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentStatess = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      const contentStates = EditorState.createWithContent(contentStatess);
      setDes(contentStates);
    }
    //set  title data
    setloading(false);
    setAttribute(filters);
    setData({
      isTitleOptimized: seoOptimize?.isTitleOptimized,
      isDescriptionOptimized: seoOptimize?.isDescriptionOptimized,
      title: seoOptimize?.title ? seoOptimize.title : "",
      description: seoOptimize.description ? seoOptimize.description : "",
    });
  };

  // fetch all Brand Category
  useEffect(() => {
    fetchBrandCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, categoryId]);

  // fetch keyword list
  useEffect(() => {
    async function fetchKeyword() {
      const res = await dispatch(getKeyword(searchKeyword));
      setKeyword(res.keywords);
    }
    fetchKeyword();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  //change title data
  const handleChange = (value) => {
    setData({
      ...data,
      title: value,
      isTitleOptimized: value !== "" ? true : false,
    });
  };
  // description handle change
  const descriptionHandleChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData({
      ...data,
      isDescriptionOptimized:
        html !== "" && html !== "<p></p>\n" ? true : false,
      description: html,
    });
    setDes(editorState);
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <div>
          <Header
            title="Title & Description Optimization"
            btn={true}
            data={data}
            seoId={seoId}
          />

          {/* <Link className="link">
            <p onClick={() => navigate(-1)} className="h6 mt-3 ml-1 text-muted">
              <ArrowBackIosIcon fontSize="small" />
              Selected Category
            </p>
          </Link> */}
          <p className="h4 mt-3 font-weight-bold">{`${brandName} |${categoryName} | Title & Description Optimization `}</p>

          {/* Tabs button */}
          <div
            className="btn-group d-flex justify-content-space-between my-4 "
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              className={
                active1 ? " activeTabButton TabButton btn " : "TabButton btn"
              }
              onClick={() => {
                setactive1(true);
                setactive2(false);
              }}
            >
              Title
            </button>
            <button
              type="button"
              className={
                active2 ? " activeTabButton TabButton btn " : "TabButton btn"
              }
              onClick={() => {
                setactive1(false);
                setactive2(true);
              }}
            >
              Description
            </button>
          </div>

          {active1 && (
            <div className="row">
              <div className="col-9 ">
                <FormatCard
                  TilteText={data.title}
                  handleChange={handleChange}
                />
                <SuggestionKeywordCard
                  keyword={keyword}
                  setsearchKeyword={setsearchKeyword}
                  searchKeyword={searchKeyword}
                />
                <ExampleCard isTitle={true} title={data?.title} />
              </div>
              <div className="col-3 ">
                <AvailableAttributes attribute={attribute} />
              </div>
            </div>
          )}

          {active2 && (
            <div className="row">
              <div className="col-9 ">
                <div className="card  cardRaduis">
                  <div className="card-header card-title">
                    Description Format remommended max character Count:500
                  </div>

                  <div className=" textEditor ">
                    <Editor
                      editorState={des}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={descriptionHandleChange}
                    />
                  </div>
                </div>

                <SuggestionKeywordCard
                  keyword={keyword}
                  setsearchKeyword={setsearchKeyword}
                  searchKeyword={searchKeyword}
                />
                <ExampleCard isTitle={false} title={data?.description} />
              </div>
              <div className="col-3 ">
                <AvailableAttributes attribute={attribute} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TitleAndDescriptionUpdate;
