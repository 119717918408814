import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PriceHistoryChart = ({ title, data }) => {
  console.log("data value are : ", data);
  const filterData1 = [];
  const filterData2 = [];
  const filterData3 = [];
  if (data) {
    data?.forEach((element) => {
      const startDate = new Date(element?.addedDate);

      let orderObj = {};
      orderObj.x = new Date(new Date(startDate));
      orderObj.y = element.sundialPrice;
      filterData1.push(orderObj);
    });

    data.forEach((element) => {
      const startDate = new Date(element?.addedDate);
      let orderObj = {};
      orderObj.x = new Date(new Date(startDate));
      orderObj.y = element.compitatorPrice;
      filterData2.push(orderObj);
    });

    data.forEach((element) => {
      const startDate = new Date(element?.addedDate);
      let orderObj = {};
      orderObj.x = new Date(new Date(startDate));
      orderObj.y = element.batchNo;
      filterData3.push(orderObj);
    });
  }

  const options = {
    theme: "light2",
    animationEnabled: true,
    title: {
      text: title,
      fontSize: 18,
      padding: 5,
      textAlign: "left",
      horizontalAlign: "left",
      verticalAlign: "top",
    },
    axisX: {
      // title: "States",
      valueFormatString: "DD MMM YYYY",
      labelFontSize: 14,
      labelFontStyle: "bold",
      labelFormatter: function (e) {
        return e.value.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      },
      interval: 1,
      intervalType: "day",
      labelAngle: 90,
      // labelMaxWidth: 50,
      labelWrap: true,
      margin: 10,
      scaleBreaks: {
        autoCalculate: true,
        collapsibleThreshold: "15%",
        lineThickness: 0,
        spacing: 0,
      },
    },
    axisY: {
      // title: "Units Sold",
      titleFontColor: "#6D78AD",
      lineColor: "#6D78AD",
      labelFontColor: "#6D78AD",
      tickColor: "#6D78AD",
    },

    axisY2: {
      // title: "Profit in USD",
      titleFontColor: "#51CDA0",
      lineColor: "#51CDA0",
      labelFontColor: "#51CDA0",
      tickColor: "#51CDA0",
    },
    toolTip: {
      shared: true,
      fontColor: "black",
    },
    legend: {
      cursor: "pointer",
      //   itemclick: this.toggleDataSeries,
    },
    data: [
      {
        name: "Batch No",
        dataPoints: filterData3,
      },
      {
        type: "line",
        lineColor: "#16AA56",
        markerColor: "#16AA56",
        name: `<span>Sundial</span>`,
        xValueFormatString: "DD MMM YYYY",
        yValueFormatString: "$#,##0",
        dataPoints: filterData1,
      },
      {
        type: "line",
        lineColor: "#FF5C3C",
        markerColor: "#FF5C3C",
        xValueFormatString: "MMM YYYY",
        yValueFormatString: "$#,##0.#",
        name: "<span style='color: red;'>Competitor</span>",
        nameFontColor: "red",
        dataPoints: filterData2,
      },
    ],
  };

  return (
    <>
      <CanvasJSChart options={options} />
    </>
  );
};

export default PriceHistoryChart;
