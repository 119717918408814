import React from 'react';
import { Link } from 'react-router-dom';
import FilterRow from './FilterRow';

import 'react-toastify/dist/ReactToastify.css';





function CreateFilter() {
  const filterParents =
    JSON.parse(localStorage.getItem('selectedFilters')) ?? [];
  return (
    <div className="container-scroller">
      <div className="page-header">
        <h3 className="page-title"> Create Filter </h3>
        <nav aria-label="breadcrumb">
          <Link to="/admin/filter-category">
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                {filterParents[0]}
              </li>
              <li className="breadcrumb-item " aria-current="page">
                {filterParents[1]}
              </li>
              <li
                className={`breadcrumb-item ${
                  !filterParents[3] ? "active" : ""
                }`}
                aria-current="page"
              >
                {filterParents[2]}
              </li>
              {filterParents[3] && (
                <li className="breadcrumb-item active" aria-current="page">
                  {filterParents[3]}
                </li>
              )}
            </ol>
          </Link>
        </nav>
      </div>
      <FilterRow />
    </div>
  );
}

export default CreateFilter;
