import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState } from "draft-js";
import { edit, retreive } from "../../../actions/term";
import { useDispatch } from "react-redux";
import htmlToDraft from "html-to-draftjs";

const EditPages = () => {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageID = queryParams.get("id");

  //   localStat
  const [loading, setLoading] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [des, setDes] = useState(EditorState.createEmpty());

  const [Data, setData] = useState({
    title: "",
    description: "",
  });

  // Api calls for get Order
  const getPage = useCallback(async () => {
    const res = await dispatch(retreive(pageID));
    setData(res);
    if (res?.description) {
      //set data to editor
      const blocksFromHtml = htmlToDraft(res.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentStatess = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const contentStates = EditorState.createWithContent(contentStatess);
      setDes(contentStates);
    }
    //set  title data
    setLoading(false);
    setData({
      title: res?.title,
      description: res?.description,
    });
  }, [dispatch, pageID]);

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getPage();
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
  }, [getPage]);

  // description handle change
  const descriptionHandleChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData({
      ...Data,
      description: html,
    });
    setDes(editorState);
  };

  const submitHandler = async (id) => {
    setBtnLoader(true);
    const { title, description } = Data;
    const res = await dispatch(edit({ title, description, id }));
    if (res) {
      setBtnLoader(false);
      //   navigate("/admin/terms");
    }
  };

  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}
      {!loading && (
        <>
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <form className="forms-sample">
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        // name="title"
                        value={Data?.title}
                        onChange={(e) =>
                          setData({ ...Data, title: e.target.value })
                        }
                      />
                    </div>

                    <div className="card  cardRaduis">
                      <div className="card-header card-title">Description</div>

                      <div className=" textEditor ">
                        <Editor
                          editorState={des}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          onEditorStateChange={descriptionHandleChange}
                        />
                      </div>
                    </div>

                    <div className=" my-5">
                      {" "}
                      <button
                        onClick={() => {
                          submitHandler(pageID);
                        }}
                        type="button"
                        className="btn btn-primary mr-2"
                      >
                        {btnLoader ? "Loading..." : "Update"}
                      </button>
                      <Link to={"/admin/terms"}>
                        <button className="btn btn-light">Cancel</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditPages;
