import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";
import UpdateButton from "./UpdateButton";
import { updateReturnPolicy } from "../../../../services/vendor/vendor.service";

const ManageLeadTime = ({
  selectedItem,
  setSelectedItem,
  isRender,
  setIsRender,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setSelectedItem({
      ...selectedItem,
      shippingAndReturns: {
        ...selectedItem?.shippingAndReturns,
        [name]: Number(value),
      },
    });
  };

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await updateReturnPolicy({
        id: selectedItem._id,
        shippingAndReturns: { ...selectedItem?.shippingAndReturns },
      });
      if (res) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="  d-flex flex-column gap-10">
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <div>Use Vendor Lead Times</div>
        <div>
          <ToggleSwitch
            checked={
              selectedItem?.shippingAndReturns?.policyType === "vendor"
                ? true
                : false
            }
            onChange={(e) => {
              console.log(e.target.checked);
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  policyType: e.target.checked ? "vendor" : "sundial",
                },
              });
            }}
            Name={"policyType"}
          />
        </div>
      </div>
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Lead Time Small Parcel</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2"
            type="number"
            name="leadTimeSmall"
            value={selectedItem?.shippingAndReturns?.leadTimeSmall}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Min Ship Time Small Parcel</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2"
            type="number"
            name="minShipTimeSmall"
            value={selectedItem?.shippingAndReturns?.minShipTimeSmall}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Max Ship Time Small Parcel</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2"
            type="number"
            name="maxShipTimeSmall"
            value={selectedItem?.shippingAndReturns?.maxShipTimeSmall}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>{" "}
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Lead Time LTL</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2"
            type="number"
            name="leadTimeLTL"
            value={selectedItem?.shippingAndReturns?.leadTimeLTL}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Min Ship Time LTL</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2"
            type="number"
            name="minShipTimeLTL"
            value={selectedItem?.shippingAndReturns?.minShipTimeLTL}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex justify-content-between gap-10 px-3">
        <div>Max Ship Time LTL</div>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-100-px mr-2 "
            type="number"
            name="maxShipTimeLTL"
            value={selectedItem?.shippingAndReturns?.maxShipTimeLTL}
            onChange={(e) => handleChange(e)}
          />
          Days
        </div>
      </div>
      <hr />
      <div className="  px-3">
        <div>
          SP Estimated Delivery:{" "}
          {selectedItem?.shippingAndReturns?.leadTimeSmall +
            selectedItem?.shippingAndReturns?.minShipTimeSmall}
          -
          {selectedItem?.shippingAndReturns?.leadTimeSmall +
            selectedItem?.shippingAndReturns?.maxShipTimeSmall}{" "}
          days
        </div>
        <div>
          LTL Estimated Delivery:{" "}
          {selectedItem?.shippingAndReturns?.leadTimeLTL +
            selectedItem?.shippingAndReturns?.minShipTimeLTL}
          -
          {selectedItem?.shippingAndReturns?.leadTimeLTL +
            selectedItem?.shippingAndReturns?.maxShipTimeLTL}{" "}
          days
        </div>
      </div>
      <UpdateButton
        position="fixed"
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManageLeadTime;
