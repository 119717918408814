/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/brand";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import CreatableSelect from "react-select/creatable";

class Create extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      image: "",
      title: "",
      description: "",
      metaTag: [],
      metaDescription: "",
      metaKeyword: [],
      seoKeyword: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      title,
      image,
      metaTag,
      metaDescription,
      metaKeyword,
      seoKeyword,
    } = this.state;
    if (this.state.image !== "") {
      let formData = new FormData();
      formData.append("image", image);
      formData.append("title", title);
      // formData.append("metaTag", metaTag);
      formData.append("metaDescription", metaDescription);

      for (let i = 0; i < metaTag.length; i++) {
        formData.append("metaTag", metaTag[i]);
      }
      for (let i = 0; i < seoKeyword.length; i++) {
        formData.append("seoKeyword", seoKeyword[i]);
      }
      for (let i = 0; i < metaKeyword.length; i++) {
        formData.append("metaKeyword", metaKeyword[i]);
      }

      this.setState({ loading: true });

      const res = await this.props.create(formData);

      this.setState({ loading: false });

      if (res) {
        this.setState({
          loading: false,
          image: "",
          title: "",
          metaTag: [],
          metaDescription: "",
          metaKeyword: [],
          seoKeyword: [],
        });
        toast(`${res.data}`, { containerId: "B" });
      }
    } else {
      toast(`Please Select Image`, { containerId: "B" });
    }
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };

    reader.readAsDataURL(file);
  }
  render() {
    const {
      title,
      metaTags,
      metaDescription,
      metaKeyword,
      seoKeywords,
      description,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create Brand </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/brands">Brands</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Brand
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add a new brand</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="Name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Name"
                      name="title"
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Tags">Meta Tags</label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaTag: data,
                            })
                          : this.setState({
                              metaTag: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="Meta Keywords">Meta Keywords</label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              metaKeyword: data,
                            })
                          : this.setState({
                              metaKeyword: [],
                            });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="meta description">Meta Description</label>
                    <textarea
                      className="form-control"
                      id="metaDescription"
                      name="metaDescription"
                      rows={4}
                      value={metaDescription}
                      onChange={(e) =>
                        this.setState({ metaDescription: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="seo keywords">Seo Keywords</label>

                    <CreatableSelect
                      isMulti
                      defaultValue={[]}
                      onChange={(e) => {
                        const data = [];
                        e &&
                          e.map((item, i) => {
                            return data.push(item.value);
                          });

                        data.length > 0
                          ? this.setState({
                              seoKeyword: data,
                            })
                          : this.setState({
                              seoKeyword: [],
                            });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Image upload</label>

                    <div className="input-group col-xs-12">
                      <input
                        required
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/brands"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(Create);
