import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  deleteAllErrorLog,
  deleteErrorLog,
  errorLogs,
} from "../../../actions/errorLog";
import { createSearchParams } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";
import SweetAlert from "react-bootstrap-sweetalert";
import { Pagination } from "@mui/material";
import moment from "moment";
import { MdDeleteOutline } from "react-icons/md";
import { CSVLink } from "react-csv";

const ErrorLog = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);

  const [deleteConfirm, setdeleteConfirm] = useState({
    open: false,
    id: null,
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  // Api calls for get Order
  const fetchData = useCallback(
    async (pageNo, search) => {
      const res = await dispatch(errorLogs(pageNo, search));
      if (res) {
        setData(res?.data);
        setpagination({
          ...res?.pagination,
        });
        setloading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        fetchData(pagination.currentPage, SearchFillter.SearchQuery);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, SearchFillter.SearchQuery, isMount]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //   Delete Category
  const hanldeDeleteConfirm = async (id) => {
    setloading(true);
    const res = await dispatch(deleteErrorLog(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };
  //   Delete Category
  const hanldeDeleteAll = async () => {
    setloading(true);
    const res = await dispatch(deleteAllErrorLog());
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" d-flex  justify-content-end align-center">
            <CustomeTableBtn
              className={"mx-2 py-2 px-5"}
              color="success"
              width={"100px"}
            >
              <CSVLink data={Data} filename={"error-logs.csv"}>
                Download All logs
              </CSVLink>{" "}
            </CustomeTableBtn>
            <CustomeTableBtn
              className={"mx-2 py-2 px-5"}
              color="danger"
              width={"100px"}
              onClick={() =>
                setdeleteConfirm({
                  open: true,
                  id: null,
                })
              }
            >
              Delete All Logs
            </CustomeTableBtn>
            <SearchFilter
              type="text"
              placeholder={" Search Error Logs"}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
          </div>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="List Error Logs"
              subTitle="items"
              count={pagination?.count}
            >
              <div className="dashboard-pages-table">
                <table>
                  <thead>
                    <tr>
                      <th>Sn#</th>
                      <th>Title</th>
                      <th>Issue</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td className="order-item-number">{i + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.message}</td>
                            <td>{moment(item?.createdAt).format("l")}</td>

                            <td>
                              <CustomeTableBtn
                                color="danger"
                                width={"100px"}
                                onClick={() =>
                                  setdeleteConfirm({
                                    open: true,
                                    id: item?._id,
                                  })
                                }
                              >
                                <MdDeleteOutline />
                              </CustomeTableBtn>

                              {deleteConfirm.open && (
                                <SweetAlert
                                  warning
                                  showCancel
                                  confirmBtnText="Yes, delete it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={(e) => {
                                    deleteConfirm.id
                                      ? hanldeDeleteConfirm(deleteConfirm.id)
                                      : hanldeDeleteAll();
                                  }}
                                  onCancel={() =>
                                    setdeleteConfirm({
                                      open: false,
                                      id: null,
                                    })
                                  }
                                  focusCancelBtn
                                >
                                  Are you sure you want to{" "}
                                  {deleteConfirm.id
                                    ? "delete?"
                                    : " delete all logs?"}{" "}
                                </SweetAlert>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-center align-center p-2">
                  <div className=" mx-auto">
                    <Pagination
                      count={pagination.totalPages}
                      page={pagination.currentPage}
                      onChange={handleChange}
                    />
                  </div>

                  <span
                    className=" font-weight-bold"
                    style={{ color: "#9f9f9f" }}
                  >
                    {Data?.length} of {pagination?.count} Items
                  </span>
                </div>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default ErrorLog;
