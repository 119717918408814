let apiURL = "https://api.onlinii.com/api/";

if (process.env.REACT_APP_ENVIROMENT === "DEV") {
  //tahir's ip
  // apiURL = "http://192.168.100.4:3001/api/";
  //qa Url
  apiURL = "https://qaapi.onlinii.com/api/";
  //local Api
  // apiURL = "http://localhost:3001/api/";
  // apiURL = "http://192.168.18.4:3001/api/";
}

export { apiURL };
