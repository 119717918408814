import React from "react";
import Drawer from "@mui/material/Drawer";

const SidebarHeader = ({ title, handleClick }) => {
  return (
    <div className="sidebar-header">
      <div className="p-2 d-flex align-items-center justify-content-between">
        <span
          onClick={handleClick}
          className="cPointer sidebar-close-btn"
          title="Close"
        >
          &times;
        </span>
        <h4 className="f1 text-center">{title}</h4>
      </div>
    </div>
  );
};
const SideDrawer = ({ section, open, handleClose, children, title, index }) => {
  return (
    <div>
      <Drawer open={open} onClose={handleClose} anchor="right">
        <SidebarHeader handleClick={() => handleClose(index)} title={title} />
        {children}
      </Drawer>
    </div>
  );
};

export default SideDrawer;
