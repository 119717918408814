import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";

const DashboardCard = ({ title, count, link }) => {
  const navigate = useNavigate();
  return (
    <div className="dashboard-card" onClick={() => link && navigate(link)}>
      <div>
        <h5 className=" font-weight-bold">{title}</h5>
        <h3 className=" font-weight-bold">
          {title === "Sales Revenue" ? "$" : ""}
          {count ? count : "N/A"}
        </h3>
      </div>
      <FaArrowRight />
    </div>
  );
};

export default DashboardCard;
