import React, { useEffect, useState } from "react";
import styles from "./addEditTags.module.css";
import {
  addNewBlogTag,
  deleteSingleBlogTag,
  getAllBlogTags,
  updateBlogTag,
} from "../../blogService/blogService";
import TagName from "../tagName/tagName";

const AddEditTags = () => {
  const [tagName, setTagName] = useState("");
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const fetchAllBlogs = async () => {
      try {
        const data = await getAllBlogTags();
        setBlogData(data?.blogTags);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllBlogs();
  }, []);

  const addNewTagName = async () => {
    try {
      const data = await addNewBlogTag(tagName);
      setBlogData((prev) => [...prev, data?.blogTag]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTagName("");
    }
  };

  const deleteSingleBlogTagById = async (blogTagId) => {
    try {
      await deleteSingleBlogTag(blogTagId);
      setBlogData((prev) => prev.filter((tag) => tag._id !== blogTagId));
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const updateBlogTagById = async (blogTagName, blogTagId) => {
    try {
      const data = await updateBlogTag(blogTagName, blogTagId);
      const updatedTagId = data?.blogTag?._id;
      setBlogData((prev) => {
        const updatedTagList = prev.map((tag) => {
          if (tag._id === updatedTagId) {
            return { ...tag, name: blogTagName };
          } else {
            return tag;
          }
        });
        return updatedTagList;
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    setTagName(e.target.value);
  };

  return (
    <div className={styles.addEditDrawer}>
      <h1 className={styles.tagTitle}>Add/Edit Tags</h1>
      <div className={styles.editTags}>
        <input
          placeholder="Enter Tage Name"
          value={tagName}
          name="name"
          onChange={handleChange}
          className={styles.addEditInput}
        />
        <button className={styles.saveButton} onClick={addNewTagName}>
          Save New Tag
        </button>
      </div>
      <h3 className={styles.activeTags}>Active Tags:</h3>
      {blogData.length ? (
        blogData.map((tag) => (
          <TagName
            key={tag._id}
            id={tag._id}
            text={tag.name}
            onDelete={deleteSingleBlogTagById}
            onUpdate={updateBlogTagById}
          />
        ))
      ) : (
        <div>No Blog Tags Found</div>
      )}
    </div>
  );
};

export default AddEditTags;
