import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from './imgTxtBanner.module.css';
import SideDrawer from '../sidebar/SideDrawer';
import ButtonEditor from './ButtonEditor';
import TagableImage from './TagableImage';
function ImgTxtBanner({
  handleRemoveSection,
  index,
  handleChangeText,
  section,
  handleShow,
  handleFileChange,
  handleClose,
  color,
  selectedIndex,
  show,
  drawerIndex,
  showDrawer,
  mainIndex,
}) {
  const [edit, setEdit] = useState({
    title: false,
    desc: false,
    button: false,
  });

  const handleEdit = (name) => setEdit({ ...edit, [name]: !edit[name] });
  // console.log(showDrawer, drawerIndex, index);
  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        {/* <h2 className="text-center">Endless Section</h2> */}
        <div className="row">
          <SideDrawer
            open={showDrawer && drawerIndex === index}
            handleClose={handleClose}
            title="Edit Button"
          >
            <ButtonEditor
              handleClose={handleClose}
              index={index}
              color={color}
              handleChangeText={handleChangeText}
              value={section?.values?.[0] ?? {}}
            />
          </SideDrawer>
          <div className="col-md-6 col-sm-6 text-center">
            <p className="d-flex flex-column justify-content-center h-100">
              <div className="endless-heading mb-md-2 position-relative d-flex align-items-center justify-content-center">
                {!edit.title ? (
                  <h3 className="mb-0">
                    {section?.values?.[0]?.title || (
                      <span style={{ opacity: '0.5' }}>Add Heading</span>
                    )}
                  </h3>
                ) : (
                  <input
                    type="text"
                    name="title"

                    onBlur={(e) => {
                      handleEdit('title');
                      handleChangeText(e, index, 0);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleEdit('title');
                        handleChangeText(e, index, 0);
                      }
                    }}
                    autoFocus={true}
                    defaultValue={section?.values?.[0]?.title ?? ''}
                      className={`${styles.editableInput} ${styles.wAuto}  ${styles.fontBig} w-auto`}
                  />
                )}

                <i
                  onClick={() => handleEdit('title')}
                  className={`${styles.EditPencil} icon-pencil`}
                ></i>
              </div>
              <div className="endless-heading mb-md-2 position-relative d-flex align-items-center justify-content-center">
                {!edit.desc ? (
                  <div className="endless-content">
                    <p className="text-dark mb-0">
                      {section?.values?.[0]?.desc || (
                        <span style={{ opacity: '0.5' }}>Add Description</span>
                      )}
                    </p>
                  </div>
                ) : (
                  <input
                    type="text"
                    name="desc"
                    onBlur={(e) => handleChangeText(e, index, 0)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleEdit('desc');
                        handleChangeText(e, index, 0);
                      }
                    }}
                    defaultValue={section?.values?.[0]?.desc ?? ''}
                    className={`${styles.editableInput} ${styles.fontSmall}`}
                  />
                )}
                <i
                  onClick={() => handleEdit('desc')}
                  className={`${styles.EditPencil} icon-pencil`}
                ></i>
              </div>
              <div className="endless-btn">
                <span className="endless-room-txt d-block">
                  <button
                    className={`${styles.bgPrimary} ${styles.shopInsideBtn}`}
                    type="button"
                    onClick={() => handleShow(-1, -1, index)}
                    style={{
                      color: `${section?.values?.[0]?.color}`,
                      fontSize: `${section?.values?.[0]?.fontSize}px`,
                      backgroundColor: section?.values?.[0]?.btnClr,
                    }}
                  >
                    <span>
                      {section?.values?.[0]?.btnTitle || (
                        <span style={{ opacity: '0.5' }}>Customize Me</span>
                      )}
                    </span>

                    {/*                     <i
                      onClick={() => handleEdit("button")}
                      className={`${styles.EditPencil} icon-pencil ml-2`}
                    ></i> */}
                    <span className={styles.showOnButtonHover}>
                      <i
                        title="Edit"
                        className={`${styles.EditPencil} icon-pencil`}
                      ></i>
                    </span>
                  </button>
                </span>
              </div>
            </p>
          </div>
          <div className="col-md-6 col-sm-6 px-0" style={{ minHeight: 300 }}>
            <div
              style={{
                position: 'absolute',
                top: '5px',
                left: '5px',
                zIndex: 1,
              }}
              className={`bg-white d-flex align-items-center ${styles.h92}`}
            >
              <i
                className={`${styles.actionBtn} ${styles.hoverPrimary} p-2  icon-pencil`}
                onClick={() => handleShow(-1, index)}
              ></i>
            </div>
            {section?.values?.[0]?.url && (
              <TagableImage
                image={section?.values?.[0]?.url}
                tags={section?.values?.[0]?.tags || []}
                handleChange={(tags) =>
                  handleChangeText(
                    { target: { value: tags, name: 'tags' } },
                    index,
                    0
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className={`bg-white ${styles.h93}`}>
        <i
          onClick={() => handleRemoveSection(index)}
          className={`bg-white ${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
          title="Remove"
          style={{ height: 'fit-content' }}
        ></i>
      </div>
      <Modal
        show={show && mainIndex === index && selectedIndex === -1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton centered>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {section.values?.[section?.values?.length - 1]?.url && (
            <div className="d-flex justify-content-center mb-3">
              <img
                src={section.values?.[section?.values?.length - 1]?.url}
                alt="preview"
                width="400px"
              />
            </div>
          )}
          <Form.Group
            controlId="formBasicimage"
            className="m-2 mb-0 d-flex align-items-center "
          >
            <div>
              <Form.Label>
                Upload File{' '}
                <span className="ml-2 dimension__text">(800 × 640 px)</span>
              </Form.Label>
            </div>
            <Form.File
              id={index}
              className={styles.modalInput}
              label="Add image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e, index, 0);
              }}
              custom
            />
          </Form.Group>
          <Form.Group controlId="formBasiclink" className="m-2">
            <Form.Label>Link</Form.Label>
            <Form.Control
              className={styles.modalInput}
              type="text"
              placeholder="paste link"
              defaultValue={section?.values?.[0]?.link ?? ''}
              name="link"
              onBlur={(e) => handleChangeText(e, index, 0)}
            />
          </Form.Group>
          <Form.Text className="text-muted">
            This Link will redirect to product page or category page
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={` btn btn-primary`}
            variant="primary"
            onClick={handleClose}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImgTxtBanner;
