const OrderStatusButton = ({ status, children, width, onClick, styles }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Processing":
        return {
          background: "#fff8e6",
          color: "#ffb900",
          borderColor: "#ffb900",
        };
      case "In Transit":
        return {
          background: "#e7f6fd",
          color: "#17aff0",
          borderColor: "#17aff0",
        };

      case "smart":
      case "open":
      case "Accept":
      case "Preparing To Ship":
        return {
          background: "#e7f6fd",
          color: "#023145",
          borderColor: "#023145",
        };
      case "Delivered":
      case "standardCoupon":
        return {
          background: "#e7f6ef",
          color: "#1ba957",
          borderColor: "#1ba957",
        };
      case "Cancelled":
      case "Returned":
      case "Not Available":
        return {
          background: "#ffe9eb",
          color: "#fb2a3d",
          borderColor: "#fb2a3d",
        };
      case "coupon":
      case "Backordered":
        return {
          background: "#efe9eb",
          color: "#6f334d",
          borderColor: "#6f334d",
        };
      case "Recommendation":
        return {
          background: "#ccdedc",
          color: "#005a52",
          borderColor: "#005a52",
        };
      case "closed":
      case "cancelled":
      case "+add":
      case "normal":
      case "completed":
        return {
          background: "#ffffff",
          color: "#7d7d7d",
          borderColor: "#7d7d7d",
        };

      case "break":
      case "Delayed":
      case "Arriving Soon":
        return {
          background: "#ffefeb",
          color: "#ff5e38",
          borderColor: "#ff5e38",
        };

      case "high":
        return {
          background: "#ffffff",
          color: "#F68E56",
          borderColor: "#F68E56",
        };

      case "urgent":
        return {
          background: "#ffffff",
          color: "#7365FF",
          borderColor: "#7365FF",
        };

        case "medium":
        return {
          background: "#ffffff",
          color: "#ffb706",
          borderColor: "#ffb706",
        };

      default:
        return {
          background: "#fff8e6",
          color: "#ffb706",
          borderColor: "#ffb706",
        };
    }
  };
  return (
    <div
      onClick={onClick}
      className="order-status-btn"
      style={{ ...getStatusColor(status), width: `${width}px`, ...styles }}
    >
      {children}
    </div>
  );
};

export default OrderStatusButton;
