/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";
import { alert } from "./alert";

export const getAllFAQs =
  (pageNumber, creatorName, faqId, topic, clarification) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    try {
      const res = await axios.get(
        `${apiURL}admin/faqs?page=${pageNumber ? pageNumber : 1}&creatorName=${
          creatorName ? creatorName : ""
        }&faqId=${faqId ? faqId : ""}&topic=${
          topic ? topic : ""
        }&clarification=${clarification ? clarification : ""}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
export const getAddFAQ = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/faqs`, { ...data });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateClarification = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/faqs-clarification`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addAnswer = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/faqs/${id}`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAnswers = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}admin/faqs/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
