import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const getStats = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}admin/get-stats`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors?.length) {
      console.log(errors);

      dispatch(alert(errors, "danger", { marginTop: "2em" }));
    }
  }
};
