import React, { useState } from "react";
import "./recentlyview.css";
import { Button, Modal, Form } from "react-bootstrap";

const RecentlyView = ({
  handleRemoveSection,
  handleChangeText,
  index,
  show,
  handleFileChange,
  handleClose,
  selectedIndex,
  section,
  mainIndex,
  handleShow,
}) => {
  const recentCards = [
    {
      url:
        "https://secure.img1-fg.wfcdn.com/im/41961103/c_crop_resize_zoom-h140-w140%5Ecompr-r85/1268/126855000/W004389190.jpg",
      desc: "Recently Viewed Products",
    },
    {
      url:
        "https://secure.img1-fg.wfcdn.com/im/41961103/c_crop_resize_zoom-h140-w140%5Ecompr-r85/1268/126855000/W004389190.jpg",
      desc: "Recently Viewed Products",
    },
    {
      url:
        "https://secure.img1-fg.wfcdn.com/im/41961103/c_crop_resize_zoom-h140-w140%5Ecompr-r85/1268/126855000/W004389190.jpg",
      desc: "Recently Viewed Products",
    },
  ];
  const [edit, setEdit] = useState({
    title: [false, false],
    desc: [false, false],
  });
  const handleEdit = (name, index) => {
    setEdit({
      ...edit,
      [name]: edit[name].map((value, i) => (i === index ? !value : value)),
    });
    console.log(edit);
  };

  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            <div className="d-flex align-items-center">
              {recentCards.map((item) => (
                <div className="f1">
                  <div className="h-100 hero-cards">
                    <div className="f1 hero-card h-100 d-flex flex-column justify-content-between">
                      <div className="hero-card-img h-100">
                        <div className="d-flex flex-row justify-content-evenly h-100">
                          <div
                            className=" w-100 m-2 p-1 text-center link-focused"
                            style={{ width: "33%" }}
                          >
                            <img
                              src={item.url}
                              alt=""
                              className="border-radius-8"
                              width="100%"
                            />
                          </div>
                        </div>

                        <div className="card-img-text"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <h4 className="my-3">Recently viewed</h4>
          </div>
          <div className="col-md-7">
            <div className="border-radius-8 link-focused p-2 d-flex hero-cards w-100 gap-8 ml-8 sm-flex-column">
              {section?.values.map((item, _index) => (
                <div className="f1 hero-card d-flex flex-column justify-content-between">
                  <div className="hero-card-img border-radius-8 text-center position-relative">
                    <img src={item?.url} width="100%" alt="" />
                    <div className="herocardPencil position-absolute">
                      <i
                        className="icon-pencil"
                        onClick={() => handleShow(_index, index)}
                      ></i>
                    </div>
                  </div>
                  {!edit.desc[_index] ? (
                    <div className="card-text">
                      <p className="m-0">{item?.desc ?? "Description"}</p>
                      <i
                        onClick={() => handleEdit("desc", _index)}
                        className="icon-pencil p-2"
                        title="Edit"
                      ></i>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center px-2">
                      <input
                        type="text"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleEdit("desc", _index);
                            handleChangeText(e, index, _index);
                          }
                        }}
                        name="desc"
                        defaultValue={item.desc || ""}
                        onBlur={(e) => {
                          handleEdit("desc", _index);
                          handleChangeText(e, index, _index);
                        }}
                        className="titleInput"
                        autoFocus={true}
                        placeholder="description"
                      />
                    </div>
                  )}
                  <Modal
                    show={
                      show && _index === selectedIndex && mainIndex === index
                    }
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton centered>
                      <Modal.Title>Change Feature Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {item?.url && (
                        <div className="d-flex justify-content-center mb-3">
                          <img
                            src={item?.url}
                            alt="Features pic"
                            width="400px"
                          />
                        </div>
                      )}
                      <Form.Group controlId="formBasicimage" className="m-2">
                        <Form.Label>
                          Upload File{" "}
                          <span className="ml-2 dimension__text">
                            (747 × 254 px)
                          </span>
                        </Form.Label>
                        <Form.File
                          id={_index}
                          label="Add image"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleFileChange(e, index, _index);
                          }}
                          custom
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasiclink" className="m-2">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="paste link"
                          name="link"
                          defaultValue={item?.link ?? ""}
                          onBlur={(e) => handleChangeText(e, index, _index)}
                        />
                        <Form.Text className="text-muted">
                          This Link will redirect to product page or category
                          page
                        </Form.Text>
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className={`btn-danger`}
                        variant="secondary"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white  h93">
        <i
          onClick={() => handleRemoveSection(index)}
          className="actionBtn-1 hoverWarning  d-block mb-2 icon-close"
          title="Remove"
          style={{ height: "fit-content" }}
        ></i>
      </div>
    </div>
  );
};
export default RecentlyView;
