import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create, retreive } from "../../../actions/userSetting";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

import CreatableSelect from "react-select/creatable";

class UserSetting extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      novice: "",
      intermediate: "",
      advanced: "",
      expert: "",
      referral: "",
      referralCommission: "",
      spamWords: [],
      skills: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const _res = await this.props.retreive();
    const {
      novice,
      intermediate,
      advanced,
      expert,
      referralCommission,
      referral,
      spamWords,
      skills
    } = _res.data;

    const spamWordsData = [];

    spamWords.forEach(element => {
      spamWordsData.push({ value: element, label: element });
    });

    const skillsData = [];

    skills.forEach(element => {
      skillsData.push({ value: element, label: element });
    });

    if (this._isMounted) {
      this.setState({
        loading: false,
        novice,
        intermediate,
        advanced,
        expert,
        referralCommission,
        referral,
        spamWords: spamWordsData,
        skills: skillsData
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      novice,
      intermediate,
      advanced,
      expert,
      referralCommission,
      referral,
      spamWords,
      skills
    } = this.state;

    this.setState({ loading: true });

    const spamWordsData = [];

    spamWords.forEach(element => {
      spamWordsData.push(element.value ? element.value : element);
    });

    const skillsData = [];

    skills.forEach(element => {
      skillsData.push(element.value ? element.value : element);
    });

    const res = await this.props.create({
      novice,
      intermediate,
      advanced,
      expert,
      referralCommission,
      referral,
      spamWords: spamWordsData,
      skills: skillsData
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
        title: "",
        description: "",
        type: ""
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const {
      novice,
      intermediate,
      advanced,
      expert,
      referralCommission,
      referral,
      spamWords,
      skills
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> User Setting </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User Setting
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit User Setting</h4>

                <Alert />

                <form className="form-sample">
                  <p className="card-description"> Seller levels </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Novice
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="novice"
                            value={novice && novice}
                            onChange={(e) =>
                              this.setState({ novice: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Intermediate
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="intermediate"
                            value={intermediate && intermediate}
                            onChange={(e) =>
                              this.setState({
                                intermediate: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Advanced
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="advanced"
                            value={advanced && advanced}
                            onChange={(e) =>
                              this.setState({ advanced: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Expert
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="expert"
                            value={expert && expert}
                            onChange={(e) =>
                              this.setState({ expert: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Referral Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="referralCommission"
                            value={referralCommission && referralCommission}
                            onChange={(e) =>
                              this.setState({
                                referralCommission: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Referral
                        </label>

                        {referral !== "" ? (
                          <Fragment>
                            <div className="col-sm-4">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="referral"
                                    defaultValue="true"
                                    checked={referral === true ? true : ""}
                                    onChange={(e) =>
                                      this.setState({
                                        referral: true,
                                      })
                                    }
                                  />
                                  Yes <i className="input-helper" />
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-5">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="referral"
                                    defaultValue="false"
                                    checked={referral === false ? true : ""}
                                    onChange={(e) =>
                                      this.setState({
                                        referral: false,
                                      })
                                    }
                                  />
                                  No <i className="input-helper" />
                                </label>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Spam Words
                        </label>
                        <div className="col-sm-9">
                          {spamWords && spamWords.length > 0 ? (
                            <CreatableSelect
                              isMulti
                              defaultValue={
                                spamWords && spamWords.length > 0
                                  ? spamWords
                                  : []
                              }
                              onChange={(e) => {
                                const data = [];
                                e &&
                                  e.map((item, i) => {
                                    return data.push(item.value);
                                  });

                                data.length > 0
                                  ? this.setState({
                                      spamWords: data,
                                    })
                                  : this.setState({
                                      spamWords: ["skype"],
                                    });
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Skills
                        </label>
                        <div className="col-sm-9">
                          {skills && skills.length > 0 ? (
                            <CreatableSelect
                              isMulti
                              defaultValue={
                                skills && skills.length > 0 ? skills : []
                              }
                              onChange={(e) => {
                                const data = [];
                                e &&
                                  e.map((item, i) => {
                                    return data.push(item.value);
                                  });

                                data.length > 0
                                  ? this.setState({
                                      skills: data,
                                    })
                                  : this.setState({
                                      skills: ["web developer"],
                                    });
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                    <Link to={"/admin/terms"}>
                    <button
                      className="btn btn-light"
                      type="button"
                   
                    >
                      Cancel
                    </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired
};

retreive.propTypes = {
  retreive: PropTypes.func.isRequired
};

export default connect(null, { create, retreive })(UserSetting);
