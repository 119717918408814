import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { brands, deleteBrand } from "../../../actions/brand";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class Brands extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      brands: null,
      deleteConfirm: false,
    };
  }

  async componentDidMount() {
    const res = await this.props.brands();
    this.setState({ brands: res, loading: false });
  }

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteBrand = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteBrand(id);
    const res = await this.props.brands();
    this.setState({ loading: false, deleteConfirm: false, brands: res });
  };

  render() {
    const { loading, brands } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {brands && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View All Brands & manufacturer </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/brands">Brands</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Main Brand List</h4>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Name </th>
                          <th> Image</th>
                          <th> Created At </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {brands && brands.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No main brands found</td>
                          </tr>
                        ) : (
                          brands.map((item, index) => (
                            <tr key={index}>
                              <td> {index + 1} </td>
                              <td> {item.title} </td>
                              <td>
                                <img src={item.image} alt={item.title} />{" "}
                              </td>
                              <td>
                                {" "}
                                <Moment format="DD/MM/YYYY">
                                  {item.createdAt}
                                </Moment>{" "}
                              </td>
                              <td>
                                <Link to={`/admin/edit-brand/?id=${item._id}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mr-4"
                                  style={{ width: 78 }}

                                >
                                  Edit
                                </button>
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                  onClick={() => {
                                    this.setState({ deleteConfirm: true });
                                  }}
                                >
                                  Delete
                                </button>

                                {this.state.deleteConfirm && (
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes, delete it!"
                                    confirmBtnBsStyle="danger"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      this._deleteBrand(item._id);
                                    }}
                                    onCancel={this._onCancel}
                                    focusCancelBtn
                                  >
                                    Are you sure you want to delete?
                                  </SweetAlert>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

brands.propTypes = {
  brands: PropTypes.func.isRequired,
};

deleteBrand.propTypes = {
  deleteBrand: PropTypes.func.isRequired,
};

export default connect(null, { brands, deleteBrand })(Brands);
