import React, { useState } from 'react';
import { apiURL } from '../../../../../../config/default';
import axios from 'axios';
import styles from './tagable.module.css';

function TagSettings({
  handleSave,
  handleClose,
  setcurrentTag,
  currentTag,
  deleteTag,
}) {
  const [showPop, setShowPop] = useState(false);
  const [products, setProducts] = useState([]);

  const handleSearch = async (e) => {
    setShowPop(true);
    try {
      const res = await axios.post(`${apiURL}custom-home/search-products`, {
        searchTerm: e.target.value,
      });
      setProducts(res?.data);
      return res;
    } catch (err) {
      console.log(err.message);
    }
  };

  // select product
  const handleClick = (product) => {
    setcurrentTag({ ...currentTag, ...product });
  };

  return (
    <>
      <div className="m-2 position-relative">
        <h4 className="text-center">Search Product</h4>
        <div className="text-center">
          <input
            type="search"
            placeholder="search"
            onChange={(e) => handleSearch(e)}
            className={styles.searchInp}
          />
        </div>
        {showPop && (
          <div className={styles.inpPopup}>
            <div className={styles.inpPopupBody}>
              {products.length > 0 &&
                products?.map((product) => (
                  <div
                    onClick={() => handleClick(product)}
                    className={`c-pointer d-flex align-items-center my-2 ${styles.productCrd}`}
                    key={product?._id.toString()}
                  >
                    <div className={styles.productSearchImg}>
                      <img src={product?.mainImage} width="100%" alt="" />
                    </div>
                    <div>
                      <h5 className={styles.productSearchTitle}>
                        {product?.title ?? ''}
                      </h5>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: '360px' }}>
        <div className={styles.selected_products}>
          <h4>{currentTag?.title}</h4>
        </div>
        <div className="d-flex align-items-center py-3 bg-grey">
          <button className="m-2 d-block btn btn-danger" onClick={deleteTag}>
            Delete
          </button>
          <button className="m-2 d-block btn btn-warning" onClick={handleClose}>
            Cancel
          </button>
          <button className="m-2 d-block btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default TagSettings;
