import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createVendor } from "../../../actions/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class CreateVendor extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Status: true,
      sellerData: [],
      userData: [],
      sellerDetails: "",
      storeName: "",
      storePhone: "",
      storeLogo: "",
      storeBanner: "",
      storeAddress: "",
      storeCountry: "",
      storeRegion: "",
      storeCity: "",
      storePostcode: "",
      storeShoppingPolicy: "",
      storeReturnPolicy: "",
      storeMetaKeywords: "",
      storeMetaDescription: "",
      storeSlug: "",
      storeFacebook: "",
      storeTwitter: "",
      storeInstagram: "",
      storePinterest: "",
      storeWebsite: "",
      storeWhatsapp: "",
      sellerBankDetail: "",
      taxIdentificationNumber: "",
      paypalEmail: "",
      sellerInfoTab: "show",
      sellerPaymentTab: "hide",
      sellerPaymentTabIcon: "",
      formClass: "show",
      successMessge: "hide",
      storeLogoUrl: "",
      storeBannerUrl: "",
      firstName: "",
      lastName: "",
      fullName: "",
      slug: "",
      emailConfirmError: false,
      email: "",
      emailConfirm: "",
      phone: "",
      passwordConfirmError: false,
      password: "",
      passwordConfirm: "",
      newsletter: true,
      tagline: "",
      storeDescription: "",
      wareHouses: [
        {
          company: "",
          address: "",
          city: "",
          postalCode: "",
          region: "",
          country: "",
        },
      ],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      fullName,
      slug,
      email,
      phone,
      password,
      newsletter,
      Status,

      storeName,
      storePhone,
      storeLogo,
      storeBanner,
      storeAddress,
      storeCountry,
      storeRegion,
      storeCity,
      storePostcode,
      storeShoppingPolicy,
      storeReturnPolicy,
      storeMetaKeywords,
      storeMetaDescription,
      storeFacebook,
      storeTwitter,
      storeInstagram,
      storePinterest,
      storeWebsite,
      storeWhatsapp,
      sellerBankDetail,
      taxIdentificationNumber,
      paypalEmail,
      tagline,
      storeDescription,
      wareHouses,
    } = this.state;

    // let formData = new FormData();
    // formData.append("firstName", firstName);
    // formData.append("lastName", lastName);
    // formData.append("email", email);
    // formData.append("phone", phone);
    // formData.append("password,", password);
    // formData.append("isSubscribe", newsletter);
    // formData.append("status", Status);
    // formData.append("firstNameAddress", firstNameAddress);
    // formData.append("lastNameAddress", lastNameAddress);
    // formData.append("company", company);
    // formData.append("address", address);
    // formData.append("city", city);
    // formData.append("country", country);
    // formData.append("postCode", postCode);
    // formData.append("region", region);
    // formData.append("storeName", storeName);
    // formData.append("storePhone", storePhone);
    // formData.append("storeLogo", storeLogo);
    // formData.append("storeBanner,", storeBanner);
    // formData.append("storeAddress,", storeAddress);
    // formData.append("storeCountry,", storeCountry);
    // formData.append("storeRegion,", storeRegion);
    // formData.append("storeCity,", storeCity);
    // formData.append("storePostcode,", storePostcode);
    // formData.append("storeShoppingPolicy,", storeShoppingPolicy);
    // formData.append("storeReturnPolicy,", storeReturnPolicy);
    // formData.append("storeMetaKeywords,", storeMetaKeywords);
    // formData.append("storeMetaDescription,", storeMetaDescription);
    // formData.append("storeFacebook,", storeFacebook);
    // formData.append("storeTwitter", storeTwitter);
    // formData.append("storeInstagram", storeInstagram);
    // formData.append("storePinterest", storePinterest);
    // formData.append("storeWebsite", storeWebsite);
    // formData.append("storeWhatsapp", storeWhatsapp);
    // formData.append("sellerBankDetail", sellerBankDetail);
    // formData.append("taxIdentificationNumber", taxIdentificationNumber);
    // formData.append("paypalEmail", paypalEmail);

    this.setState({ loading: true });

    const res = await this.props.createVendor({
      firstName,
      lastName,
      fullName,
      slug,
      email,
      phone,
      password,
      newsletter,
      Status,
      storeName,
      storePhone,
      storeLogo,
      storeBanner,
      storeAddress,
      storeCountry,
      storeRegion,
      storeCity,
      storePostcode,
      storeShoppingPolicy,
      storeReturnPolicy,
      storeMetaKeywords,
      storeMetaDescription,
      storeFacebook,
      storeTwitter,
      storeInstagram,
      storePinterest,
      storeWebsite,
      storeWhatsapp,
      sellerBankDetail,
      taxIdentificationNumber,
      paypalEmail,
      wareHouses,
      tagline,
      storeDescription,
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
        sellerDetails: "",
        storeName: "",
        storePhone: "",
        storeLogo: "",
        storeBanner: "",
        storeAddress: "",
        storeCountry: "",
        storeRegion: "",
        storeCity: "",
        storePostcode: "",
        storeShoppingPolicy: "",
        storeReturnPolicy: "",
        storeMetaKeywords: "",
        storeMetaDescription: "",
        storeSlug: "",
        storeFacebook: "",
        storeTwitter: "",
        storeInstagram: "",
        storePinterest: "",
        storeWebsite: "",
        storeWhatsapp: "",
        sellerBankDetail: "",
        taxIdentificationNumber: "",
        paypalEmail: "",
        sellerInfoTab: "show",
        sellerPaymentTab: "hide",
        sellerPaymentTabIcon: "",
        formClass: "show",
        successMessge: "hide",
        storeLogoUrl: "",
        storeBannerUrl: "",
        firstName: "",
        lastName: "",
        fullName: "",
        slug: "",
        emailConfirmError: false,
        email: "",
        emailConfirm: "",
        phone: "",
        passwordConfirmError: false,
        password: "",
        passwordConfirm: "",
        newsletter: true,
        tagline,
        storeDescription,
        wareHouses: [
          {
            company: "",
            address: "",
            city: "",
            postalCode: "",
            region: "",
            country: "",
          },
        ],
      });
      toast(`${res}`, { containerId: "B" });
    }
  };

  //change in value  click event
  handleWareHouses = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.wareHouses];

    list[index][name] = value;
    this.setState({ wareHouses: list });
  };

  // handle   remove click event
  handleWareHousesRemove = (index) => {
    const list = [...this.state.wareHouses];
    list.splice(index, 1);
    this.setState({ wareHouses: list });
  };

  // handle attribute add
  handleWareHousesAdd = () => {
    this.setState({
      wareHouses: [
        ...this.state.wareHouses,
        {
          company: "",
          address: "",
          city: "",
          postalCode: "",
          region: "",
          country: "",
        },
      ],
    });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  _handleImageChangeLogo(e) {
    e.preventDefault();

    this.getBase64(e.target.files[0], (base64) =>
      this.setState({
        storeLogo: base64,
      })
    );
  }

  _handleImageChangeBanner(e) {
    e.preventDefault();

    this.getBase64(e.target.files[0], (base64) =>
      this.setState({
        storeBanner: base64,
      })
    );
  }
  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      fullName,
      slug,
      storeName,
      storePhone,
      storeAddress,
      storeCountry,
      storeRegion,
      storeCity,
      storePostcode,
      storeShoppingPolicy,
      storeReturnPolicy,
      storeMetaKeywords,
      storeMetaDescription,
      storeFacebook,
      storeTwitter,
      storeInstagram,
      storePinterest,
      storeWebsite,
      storeWhatsapp,
      sellerBankDetail,
      taxIdentificationNumber,
      paypalEmail,
      wareHouses,
      tagline,
      storeDescription,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Create Vender{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/vendors">Vendor</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Vendor
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">General Information</h4>

                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName && firstName}
                      onChange={(e) =>
                        this.setState({
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group  col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName && lastName}
                      onChange={(e) =>
                        this.setState({
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group  col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fullName && fullName}
                      onChange={(e) =>
                        this.setState({
                          fullName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group  col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={slug && slug}
                      onChange={(e) =>
                        this.setState({
                          slug: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group  col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email && email}
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={phone && phone}
                      maxLength="10"
                      onChange={(e) =>
                        this.setState({
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group col-md-6 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={password && password}
                      onChange={(e) =>
                        this.setState({
                          password: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group col-md-6 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Tagline
                    </label>
                    <input
                      type="tagline"
                      className="form-control"
                      value={tagline && tagline}
                      onChange={(e) =>
                        this.setState({
                          tagline: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group col-md-6 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Description
                    </label>
                    <textarea
                      type="storeDescription"
                      className="form-control"
                      value={storeDescription && storeDescription}
                      onChange={(e) =>
                        this.setState({
                          storeDescription: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6 col-sm-12 ">
                    <label
                      style={{ fontWeight: "bold" }}
                      className="col-sm-3 col-form-label"
                    >
                      News Letter
                    </label>
                    <div className="col-sm-12">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="newsletter"
                            id="1"
                            defaultValue
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                newsletter: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="newsletter"
                            id="newsletter"
                            defaultValue="0"
                            onChange={() => {
                              this.setState({
                                newsletter: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label
                      style={{ fontWeight: "bold" }}
                      className="col-sm-3 col-form-label"
                    >
                      Status
                    </label>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="status"
                            id="1"
                            defaultValue
                            defaultChecked
                            onChange={() => {
                              this.setState({
                                status: true,
                              });
                            }}
                          />
                          Yes <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-check">
                        <label
                          style={{ fontWeight: "bold" }}
                          className="form-check-label"
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            name="status"
                            id="status"
                            defaultValue="0"
                            onChange={() => {
                              this.setState({
                                status: false,
                              });
                            }}
                          />{" "}
                          No <i className="input-helper" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h4 className="card-title">Address Information</h4>
                {wareHouses.map((wareHouse, index) => (
                  <div className="row">
                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        Company
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                        value={wareHouse.company}
                        onChange={(e) => this.handleWareHouses(e, index)}
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        Address
                      </label>
                      <textarea
                        className="form-control"
                        name="address"
                        value={wareHouse.address}
                        onChange={(e) => this.handleWareHouses(e, index)}
                        cols={40}
                        rows={1}
                        style={{
                          resize: "vertical",
                        }}
                        maxLength="70"
                        minlength="20"
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="country">
                        Country
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="country"
                        name="country"
                        value={wareHouse.country}
                        onChange={(e) => this.handleWareHouses(e, index)}
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>

                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="region"
                        value={wareHouse.region}
                        onChange={(e) => this.handleWareHouses(e, index)}
                        maxLength="2"
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={wareHouse.city}
                        onChange={(e) => this.handleWareHouses(e, index)}
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-12">
                      <label style={{ fontWeight: "bold" }} htmlFor="title">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="postalCode"
                        value={wareHouse.postalCode}
                        onChange={(e) => this.handleWareHouses(e, index)}
                      />
                    </div>

                    <div className="d-flex mb-4 w-100 justify-content-center">
                      {wareHouses.length > 1 && (
                        <button
                          type="button"
                          className="minusBtn actionBtn "
                          onClick={() => this.handleWareHousesRemove(index)}
                        >
                          <div class="icon-minus actionBtn-icon minus"></div>
                        </button>
                      )}
                      {wareHouses.length - 1 === index && (
                        <button
                          type="button"
                          className="plusBtn actionBtn ml-2"
                          onClick={() => this.handleWareHousesAdd()}
                        >
                          <div className="icon-plus actionBtn-icon plus"> </div>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="card-body">
                <h4 className="card-title">Vendor Store</h4>

                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeName && storeName}
                      onChange={(e) =>
                        this.setState({
                          storeName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12 ">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storePhone && storePhone}
                      onChange={(e) =>
                        this.setState({
                          storePhone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }}>Store Logo</label>

                    <div className="input-group col-xs-12">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChangeLogo(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }}>Store Banner</label>

                    <div className="input-group col-xs-12">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this._handleImageChangeBanner(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Address
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={storeAddress && storeAddress}
                      onChange={(e) =>
                        this.setState({
                          storeAddress: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="country">
                      Country
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="storeCountry"
                      value={storeCountry}
                      onChange={(e) => {
                        this.setState({
                          storeCountry: e.target.value,
                        });
                      }}
                    >
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">
                        Congo, The Democratic Republic of The
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">
                        Macedonia, The Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and The Grenadines">
                        Saint Vincent and The Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">
                        South Georgia and The South Sandwich Islands
                      </option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan, Province of China">
                        Taiwan, Province of China
                      </option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.S.">
                        Virgin Islands, U.S.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Region / State
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeRegion && storeRegion}
                      onChange={(e) =>
                        this.setState({
                          storeRegion: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeCity && storeCity}
                      onChange={(e) =>
                        this.setState({ storeCity: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Postcode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storePostcode && storePostcode}
                      onChange={(e) =>
                        this.setState({
                          storePostcode: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Shipping Policy
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={storeShoppingPolicy && storeShoppingPolicy}
                      onChange={(e) =>
                        this.setState({
                          storeShoppingPolicy: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Return Policy
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={storeReturnPolicy && storeReturnPolicy}
                      onChange={(e) =>
                        this.setState({
                          storeReturnPolicy: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Status
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeShoppingPolicy && storeShoppingPolicy}
                      onChange={(e) =>
                        this.setState({
                          storeShoppingPolicy: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Meta Keywords
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={storeMetaKeywords && storeMetaKeywords}
                      onChange={(e) =>
                        this.setState({
                          storeMetaKeywords: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Store Meta Description
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={storeMetaDescription && storeMetaDescription}
                      onChange={(e) =>
                        this.setState({
                          storeMetaDescription: e.target.value,
                        })
                      }
                      cols={40}
                      rows={1}
                      style={{
                        resize: "vertical",
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Website Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeWebsite && storeWebsite}
                      onChange={(e) =>
                        this.setState({
                          storeWebsite: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Facebook Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeFacebook && storeFacebook}
                      onChange={(e) =>
                        this.setState({
                          storeFacebook: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Twitter Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeTwitter && storeTwitter}
                      onChange={(e) =>
                        this.setState({
                          storeTwitter: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Instagram Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeInstagram && storeInstagram}
                      onChange={(e) =>
                        this.setState({
                          storeInstagram: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Pinterest Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storePinterest && storePinterest}
                      onChange={(e) =>
                        this.setState({
                          storePinterest: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storeWhatsapp && storeWhatsapp}
                      maxLength="10"
                      onChange={(e) =>
                        this.setState({
                          storeWhatsapp: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={taxIdentificationNumber && taxIdentificationNumber}
                      onChange={(e) =>
                        this.setState({
                          taxIdentificationNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Seller Bank detail
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={sellerBankDetail && sellerBankDetail}
                      onChange={(e) =>
                        this.setState({
                          sellerBankDetail: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <label style={{ fontWeight: "bold" }} htmlFor="title">
                      Paypal Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={paypalEmail && paypalEmail}
                      onChange={(e) =>
                        this.setState({
                          paypalEmail: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <Alert />
                <button
                  onClick={(e) => {
                    this._submitHandler(e);
                  }}
                  type="button"
                  className="btn btn-primary mr-2"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

createVendor.propTypes = {
  createVendor: PropTypes.func.isRequired,
};
export default connect(null, { createVendor })(CreateVendor);
