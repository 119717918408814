import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = (formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/create-main-category`,
      formData
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/main-category/${id}`, _config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const edit = (formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/edit-main-category`, formData);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getMainCategories = (e) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}admin/main-categories`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};
export const mainCategories = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/main-categories`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const deleteMainCategory = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}admin/delete-main-category/${id}`);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const getCategory = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}admin/main-category/${id}`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
