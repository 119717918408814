import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create } from "../../../actions/bulkMail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../components/alert/alert.component";


class bulkMail extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userType: "user",
      subject: "",
      message: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { userType, message, subject } = this.state;

    if (subject.length > 2 && message.length > 10) {
      const data = {
        subject: subject,
        userType: userType,
        message: message,
      };

      this.setState({ loading: true });

      const res = await this.props.create(data);

      this.setState({ loading: false });

      if (res) {
        this.setState({
          loading: false,
          userType: "",
          subject: "",
          message: "",
        });
        toast(`${res.data}`, { containerId: "B" });
      }
    } else {
      toast.error(`Fill all the fields`, { containerId: "B" });
    }
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { userType, subject, message } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Mail{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/bulk-mail">Mail</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Send Mail
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title" style={{ fontWeight: "bold" }}>
                  Send New Mail
                </h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="from">
                      From
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="from"
                      onChange={(e) => {
                        this.setState({ from: e.target.value });
                      }}
                    >
                      <option value="default">Default</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="to">
                      To
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="to"
                      onChange={(e) => {
                        this.setState({ userType: e.target.value });
                      }}
                    >
                      <option value="user" selected>
                        Customers
                      </option>
                      <option value="admin">Admins</option>
                      <option value="vendor">Vendors</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label style={{ fontWeight: "bold" }} htmlFor="Subject">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      name="subject"
                      value={subject}
                      onChange={(e) =>
                        this.setState({ subject: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ fontWeight: "bold" }}
                      htmlFor="meta description"
                    >
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={4}
                      value={message}
                      onChange={(e) =>
                        this.setState({ message: e.target.value })
                      }
                    />
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <Link to={"/admin/dashboard"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

export default connect(null, { create })(bulkMail);
