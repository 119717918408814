import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const PopupModal = ({ children, onClose, open }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        className="d-flex align-items-center justify-content-center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div>
          <div className="p-4 bg-white" style={{ minWidth: "300px" }}>
            {children}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PopupModal;
