import React, {  useState } from "react";
import style from "./review-sidebar.module.css";
import { getReviewStatusButtonBackground } from "../utils";
import InfoItem from "../../product/DynamicPricing/components/pricing-side-components/info-item";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import ConfirmationDialog from "./confirmation-dialog/confirmationDialog";

const ReviewSidebar = ({ filterReviewData, fetchSingleUser }) => {
  const [showModal, setShowModal] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  }

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.priceTitleBox}>
        <h3 className={style.priceTitleBoxHeading}>Review Status</h3>
        <CustomeTableBtn
          color={`${
            getReviewStatusButtonBackground[
              filterReviewData.reviewStatus ?? "not sent"
            ]
          }`}
          disabled={true}
          width={"100"}
          className="font-size-sm"
          styles={{ padding: ".45rem 0", margin: 0 }}
        >
          {/* {item?.crawlReport?.productMatchingStatus} */}
          {filterReviewData?.reviewStatus ?? "not sent"}
        </CustomeTableBtn>
      </div>

      <div className={style.productContainer}>
        <img
          src={filterReviewData?.mainImage}
          alt="user-img"
          className={style.productImage}
        />
        {filterReviewData?.slug ? (
          <a
            href={`https://www.sundialhome.com/product/${filterReviewData?.slug}`}
            target="_blank"
            rel="noreferrer"
            className={style.productDescription}
          >
            {filterReviewData?.title?.toUpperCase()}
          </a>
        ) : (
          <p
            style={{ marginLeft: "0.3rem", color: "black", cursor: "default" }}
          >
            {filterReviewData?.title?.toUpperCase()}
          </p>
        )}
      </div>

      {/* Product Details */}
      <div className={style.productDetails}>
        <h3 className={style.subHeading}>Order Details</h3>

        {/* <InfoItem
          crawlType="Order Number:"
          CrawlDescription={`$${filterReviewData?.creditUsed.toFixed(2)}`}
        /> */}
        <div
          className="d-flex justify-content-between font-1rem"
          style={{ fontWeight: "600" }}
        >
          <div>Order Number:</div>
          <div>
            <a
              href="https://qaadmin.sundialhome.com/admin/sale-analysis"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              ${filterReviewData?.itemOrderNumber}
            </a>
          </div>
        </div>

        <InfoItem
          crawlType="Manufacturer:"
          CrawlDescription={filterReviewData?.manufacturer}
        />
        <InfoItem crawlType="SKU:" CrawlDescription={filterReviewData?.sku} />
        <InfoItem
          crawlType="Account:"
          CrawlDescription={filterReviewData?.orderID?.user?.email}
        />
        <InfoItem
          crawlType="Customer Tickets:"
          CrawlDescription={
            filterReviewData?.ticketCount
            //  !== 0
            //   ? filterReviewData?.ticketCount
            //   : ""
          }
          styles={{ color: "red" }}
        />
        <InfoItem
          crawlType="Order Date:"
          CrawlDescription={formatDate(filterReviewData?.createdAt)}
        />
        <InfoItem
          crawlType="Delivered Date:"
          CrawlDescription={
            filterReviewData?.shippingInfo?.finalDeliveryDate
              ? formatDate(filterReviewData?.shippingInfo?.finalDeliveryDate)
              : ""
          }
        />
      </div>

      <div className={style.productDetails}>
        <h3 className={style.subHeading}>Review Details</h3>
        <InfoItem
          crawlType="Review Status:"
          CrawlDescription={filterReviewData?.reviewStatus ?? "not sent"}
        />
        <InfoItem
          crawlType="Alerts:"
          CrawlDescription={
            filterReviewData?.ticketCount > 0 &&
            "Customer has submitted a ticket"
          }
          styles={{ color: "red" }}
        />

        <InfoItem
          crawlType="Review Requested Date:"
          CrawlDescription={
            filterReviewData?.reviewRequestDate
              ? formatDate(filterReviewData?.reviewRequestDate)
              : ""
          }
        />
        <InfoItem
          crawlType="Review Received Date:"
          CrawlDescription={
            filterReviewData?.reviewDate
              ? formatDate(filterReviewData?.reviewDate)
              : ""
          }
        />
        <CustomeTableBtn
          color={`${
            getReviewStatusButtonBackground[
              filterReviewData.reviewStatus ?? "not sent"
            ]
          }`}
          disabled={
            filterReviewData?.reviewStatus === "pending" ||
            filterReviewData?.reviewStatus === "received"
          }
          className="font-size-sm"
          onClick={handleShowModal}
          styles={{ padding: ".55rem 0", marginTop: "1rem", width: "100%" }}
        >
          {filterReviewData.reviewStatus ?? "Send Review Request"}
        </CustomeTableBtn>
      </div>
      <ConfirmationDialog
        open={showModal}
        onClose={handleShowModal}
        onConfirm={() => fetchSingleUser(filterReviewData?._id)}
        message="Are you sure you want to send this for review request?"
      />
    </div>
  );
};

export default ReviewSidebar;
