/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DashboardCard from "./components/DashboardCard";
import TicketsTable from "./components/TicketsTable";
import "./csDashboard.css";
import { getDashboardData } from "../../../actions/tickets";
import TopBarProgress from "react-topbar-progress-indicator";
import { useNavigate } from "react-router-dom";

const CSDashboard = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [Data, setData] = useState({});
  const [loading, setloading] = useState(true);

  // Api calls for get Order
  const fetchDashboardData = useCallback(
    async () => {
      try {
        const res = await dispatch(getDashboardData());
        if (res) {
          setData(res);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchDashboardData();
    }
    return () => {
      isSubscribe = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          {" "}
          <div>
            <div className="row mt-4 pb-5">
              <div className="col-lg-6 col-md-12">
                <DashboardCard title="Ticket Type Breakdown">
                  <div className="recently-view-table">
                    <table className=" w-100">
                      <tbody>
                        <tr>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Tracking Issue</span>
                              <span>
                                <strong>{Data?.trackingIssue}</strong>
                              </span>
                            </div>
                          </td>
                          <td className="border-grey p-2 ">
                            <div className="d-flex justify-content-between align-center">
                              <span>Address Correction</span>
                              <span>
                                <strong>{Data?.addressCorrection}</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Damaged Defective</span>
                              <span>
                                <strong>{Data?.damagedDefective}</strong>
                              </span>
                            </div>
                          </td>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Delivery Instructions</span>
                              <span>
                                <strong>{Data?.deliveryInstructions}</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Change of Mind</span>
                              <span>
                                <strong>{Data?.changeYourMind}</strong>
                              </span>
                            </div>
                          </td>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Product Inquiry</span>
                              <span>
                                <strong>{Data?.productInquiry}</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Exchange</span>
                              <span>
                                <strong>{Data?.exchangeItem}</strong>
                              </span>
                            </div>
                          </td>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>General Inquiry</span>
                              <span>
                                <strong>{Data?.generalInquiry}</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-grey p-2">
                            <div className="d-flex justify-content-between align-center">
                              <span>Cancel Order</span>
                              <span>
                                <strong>{Data?.cancelOrder}</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </DashboardCard>
              </div>
              <div className="col-lg-6 col-md-12 mt-4 mt-lg-0">
                <DashboardCard title="Tickets Summary">
                  <div className="recently-view-table">
                    <table className=" w-100">
                      <tbody>
                        <tr>
                          <td className="border-grey text-center p-3">
                            <p
                              onClick={() => navigate("/admin/all-tickets")}
                              className="underlined c-pointer"
                            >
                              All Tickets
                            </p>
                            <h1 className="underlined font-weight-bold">
                              {Data?.allTickets}
                            </h1>
                          </td>
                          <td
                            className="border-grey text-center p-3  text-success"
                            style={{ background: "#e7f6ee" }}
                          >
                            <p
                              onClick={() =>
                                navigate("/admin/all-tickets?status=open")
                              }
                              className="underlined c-pointer"
                            >
                              Open Tickets
                            </p>
                            <h1 className="underlined font-weight-bold">
                              {Data?.openTickets}
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="border-grey text-center p-3"
                            style={{ background: "#fafafa", color: "#7d7d7d" }}
                          >
                            <p
                              onClick={() =>
                                navigate("/admin/all-tickets?status=close")
                              }
                              className="underlined c-pointer"
                            >
                              Close & Cancelled Tickets
                            </p>
                            <h1 className="underlined font-weight-bold">
                              {Data?.closeCancelTickets}
                            </h1>
                          </td>
                          <td
                            className="border-grey text-center p-3"
                            style={{ background: "#fdefec", color: "#ff6a49" }}
                          >
                            <p
                              onClick={() =>
                                navigate("/admin/all-tickets?isEscalated=true")
                              }
                              className="underlined c-pointer"
                            >
                              Escalated Tickets
                            </p>
                            <h1 className="underlined font-weight-bold">
                              {Data?.escalatedTickets}
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </DashboardCard>
              </div>
            </div>
            <div className="row mt-4 pb-5">
              <div className="col-lg-6 col-md-12">
                <DashboardCard
                  title="All Tickets"
                  subTitle="Tickets"
                  count={Data?.allTickets}
                  link={"/admin/all-tickets"}
                >
                  <TicketsTable Data={Data?.recentTickets} />
                </DashboardCard>
              </div>
              <div className="col-lg-6 col-md-12 mt-4 mt-lg-0">
                <DashboardCard
                  title="Escalated Tickets"
                  subTitle="Tickets"
                  count={Data?.escalatedTickets}
                  link={"/admin/all-tickets?isEscalated=true"}
                >
                  <TicketsTable
                    type="EscalatedTickets"
                    Data={Data?.recentEscalatedTickets}
                  />
                </DashboardCard>
              </div>
            </div>
          </div>
        </>
      )}
      {/* this design for large device hide in small and meduim devices */}
    </>
  );
};

export default CSDashboard;
