import React, { useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Box, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const LinkCard = ({ name, i, link, setaddLinks }) => {
  const [Open, setOpen] = useState(false);
  const [value, setvalue] = useState({
    title: "",
    link: "",
  });

  const [error, seterror] = useState(false);
  const handelDelete = (index) => {
    const items = JSON.parse(localStorage.getItem("addLink")) || [];

    const newItems = items.filter((item, i) => i !== index);
    localStorage.setItem("addLink", JSON.stringify(newItems));
    setaddLinks(newItems);
  };

  const handleEdit = (index) => {
    setOpen(true);
    const items = JSON.parse(localStorage.getItem("addLink")) || [];
    const newItems = items.find((item, i) => i === index);
    setvalue(newItems);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handeUpdate = (index) => {
    if (value.link !== "" || value.title !== "") {
      const items = JSON.parse(localStorage.getItem("addLink")) || [];
      let newArr = [...items];
      newArr[index] = {
        ...value,
      };

      localStorage.setItem("addLink", JSON.stringify(newArr));
      setaddLinks(newArr);
      setOpen(false);
    } else {
      seterror(true);
      setTimeout(() => {
        seterror(false);
      }, 2000);
    }
  };

  return (
    <>
      <div className="link-card">
        <a
          style={{ color: "#000000" }}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <h4 className=" font-weight-bold">{name}</h4>
          </div>
        </a>
        <div className="link-action-button">
          <AiFillEdit
            className="link-edit-icon"
            onClick={() => handleEdit(i)}
          />

          <AiFillDelete
            className="link-delete-icon"
            onClick={() => handelDelete(i)}
          />
        </div>
      </div>

      <Modal
        open={Open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {error && <p className=" text-danger">please fill all feild</p>}
          <TextField
            className=" w-100"
            required
            id="outlined-required"
            label="Title"
            placeholder="Please Enter Title"
            value={value.title}
            onChange={(e) => setvalue({ ...value, title: e.target.value })}
          />
          <TextField
            className=" w-100 my-4"
            required
            id="outlined-required"
            label="Link"
            placeholder="Enter Link"
            value={value.link}
            onChange={(e) => setvalue({ ...value, link: e.target.value })}
          />
          <div className="model-btn-container">
            <button
              type="button"
              className="btn btn-danger w-100 "
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => handeUpdate(i)}
              className="btn btn-success w-100"
            >
              Update
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LinkCard;
