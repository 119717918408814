import React from "react";
import styles from "./Content.module.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, convertFromHTML } from "draft-js";
import { EditorState, ContentState } from "draft-js";

// const labelStyle = {
//   height: 40,
//   width: 40,
//   borderRadius: "50%",
//   margin: "12px",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// };

// const Editor = ({ section, handleChangeText, index }) => {
//   return (
//     <div className={styles.tools}>
//       <div>
//         <label
//           style={{
//             ...labelStyle,
//             color: section?.values?.[0]?.titleColor ?? "",
//             background: "#ddd",
//           }}
//           htmlFor="titleColor"
//         >
//           Color:
//         </label>
//         <input
//           value={section?.values?.[0]?.titleColor ?? ""}
//           type="color"
//           onChange={(e) => handleChangeText(e, index)}
//           name="titleColor"
//           id="titleColor"
//           className="d-none"
//         />
//       </div>
//       <div className={styles.alignMents}>
//         <i
//           className="d-block icon-arrow-left"
//           title="Left"
//           style={{ fontSize: 12 }}
//         ></i>
//         <i
//           className="d-block icon-size-actual"
//           title="Center"
//           style={{ fontSize: 12 }}
//         ></i>

//         <i
//           className="d-block icon-arrow-right"
//           title="Right"
//           style={{ fontSize: 12 }}
//         ></i>
//       </div>
//       <div>
//         <input
//           className={`${styles.inputNumber} text-center form-control p-0`}
//           style={labelStyle}
//           value={`${section?.values?.[0]?.titleFontSize ?? 16}`}
//           type="number"
//           onChange={(e) => {
//             if (e.target.value <= 12 || e.target.value > 40) return;
//             handleChangeText(e, index);
//           }}
//           name="titleFontSize"
//           id="titleFontSize"
//         />
//       </div>
//     </div>
//   );
// };

const options = {
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true },
  options: [
    "inline",
    "blockType",
    "fontFamily",
    "textAlign",
    "colorPicker",
    "link",
    "emoji",
    "history",
  ],
};

const Content = ({
  section,
  handleRemoveSection,
  index,
  handleChangeText,
  handleContentHtml,
}) => {
  const [show, setShow] = React.useState(false);
  const [editorState, setEditiorState] = React.useState(
    EditorState.createEmpty()
  );
  // eslint-disable-next-line no-unused-vars
  const [html, setHtml] = React.useState(null);
  const onEditorStateChange = (draftObj) => {
    setEditiorState(draftObj);
    const html = draftToHtml(convertToRaw(draftObj.getCurrentContent()));
    setHtml(html);
    // const gotit = EditorState.createWithContent(
    //   ContentState.createFromBlockArray(convertFromHTML(html))
    // );
    handleContentHtml(html, index);
  };
  const handleEdit = () => setShow(!show);

  React.useEffect(() => {
    const html = section?.values?.[0].html;
    console.log({ html });
    if (html) {
      setEditiorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(html))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="d-flex w-100 justify-content-between">
      <div className="w-100 position-relative">
        <i
          onClick={handleEdit}
          className="border border-info rounded-circle icon-pencil p-2"
          title="Edit"
          id={styles.pencil}
        ></i>

        {show ? (
          <div className="mt-5">
            <Editor
              toolbar={options}
              editorState={editorState}
              value="hello world"
              placeholder="CHANGE ME"
              onEditorStateChange={onEditorStateChange}
              name="testContent"
            />
          </div>
        ) : (
          <div
            className="mt-5"
            dangerouslySetInnerHTML={{ __html: section?.values?.[0].html }}
          ></div>
        )}

        {/* {show ? (
          <div className={styles.container}>
            <Editor
              section={section}
              handleChangeText={handleChangeText}
              index={index}
            />
 
            <div className="my-2">
              <input
                value={section?.values?.[0]?.title ?? ""}
                type="text"
                onChange={(e) => handleChangeText(e, index)}
                className={styles.titleinput}
                placeholder="Title"
                name="title"
                style={{
                  color: section?.values?.[0]?.titleColor ?? "",
                  fontSize: `${section?.values?.[0]?.titleFontSize ?? 16}px`,
                  resize: "vertical",
                }}
              />
            </div>
            <textarea
              name="content"
              onChange={(e) => handleChangeText(e, index)}
              value={section?.values?.[0]?.content ?? ""}
              placeholder="Content"
              style={{
                color: section?.values?.[0]?.color ?? "",
                fontSize: `${section?.values?.[0]?.fontSize ?? 16}px`,
                resize: "vertical",
              }}
              rows="3"
              // autoFocus

              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEdit();
                }
              }}
            />
          </div>
        ) : (
          <div className="pl-5 pt-5">
            <p>{section?.values?.[0]?.title ?? ""}</p>
            <p
              className="mb-0"
              style={{
                color: section?.values?.[0]?.color ?? "",
                fontSize: `${section?.values?.[0]?.fontSize ?? 16}px`,
              }}
            >
              {section?.values?.[0]?.content ?? "Add Content"}
            </p>
          </div>
        )} */}
      </div>
      <div className={`bg-white ${styles.h93}`}>
        <i
          onClick={() => handleRemoveSection(index)}
          className="hoverWarning d-block mb-2 icon-close"
          title="Remove"
          style={{ height: "fit-content" }}
        ></i>
      </div>
    </div>
  );
};

export default Content;
