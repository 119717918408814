// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rating-card-sidebar_mainContainer__IqCa3 {\n  border: 2px solid rgb(184, 184, 184);\n  border-bottom: none;\n}\n\n.rating-card-sidebar_priceTitleBox__1wxNn {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid rgb(184, 184, 184);\n  padding: 1rem 0.7rem;\n}\n\n.rating-card-sidebar_priceTitleBoxHeading__16eQQ {\n  font-size: 1.2rem;\n  font-weight: 700;\n  margin-bottom: 0;\n}\n\n.rating-card-sidebar_customButton__3eHgx {\n  border: 1px solid rgb(184, 184, 184);\n  padding: 0.4rem 1.2rem;\n  background-color: white;\n  color: black;\n  font-size: 16px;\n  font-weight: bold;\n  cursor: pointer;\n  outline: none;\n  border-radius: 0.3rem;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/reviews/components/rating-card-sidebar/rating-card-sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2CAA2C;EAC3C,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".mainContainer {\n  border: 2px solid rgb(184, 184, 184);\n  border-bottom: none;\n}\n\n.priceTitleBox {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid rgb(184, 184, 184);\n  padding: 1rem 0.7rem;\n}\n\n.priceTitleBoxHeading {\n  font-size: 1.2rem;\n  font-weight: 700;\n  margin-bottom: 0;\n}\n\n.customButton {\n  border: 1px solid rgb(184, 184, 184);\n  padding: 0.4rem 1.2rem;\n  background-color: white;\n  color: black;\n  font-size: 16px;\n  font-weight: bold;\n  cursor: pointer;\n  outline: none;\n  border-radius: 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "rating-card-sidebar_mainContainer__IqCa3",
	"priceTitleBox": "rating-card-sidebar_priceTitleBox__1wxNn",
	"priceTitleBoxHeading": "rating-card-sidebar_priceTitleBoxHeading__16eQQ",
	"customButton": "rating-card-sidebar_customButton__3eHgx"
};
export default ___CSS_LOADER_EXPORT___;
