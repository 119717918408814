export const ConvertImgToBase64 = (image) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = function () {
      resolve(reader.result); //base64encoded string
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};
