import React from "react";
import { ColorPicker } from "material-ui-color";
import styles from "./imgTxtBanner.module.css";
import "./datePicker.css";
const ButtonEditor = ({ value = {}, handleClose, index, handleChangeText }) => {
  return (
    <div className="text-center">
      <div className={`my-3 ${styles.maiBtnContainer}`}>
        <button
          style={{
            fontSize: `${value.fontSize}px`,
            color: value.color || "#fff",
            backgroundColor: value.btnClr ?? "#17a2b8",
          }}
          className={styles.maiBtn}
        >
          {value.btnTitle || (
            <span style={{ opacity: "0.3" }}>I am a placeholder</span>
          )}
        </button>
      </div>
      <div className="my-3">
        <div className="my-2">
          Change Font Size {`${value?.fontSize} (pixels)`}
        </div>
        <div>
          <span className="mx-auto">min: 13: max: 32</span>
        </div>
        <input
          type="range"
          onChange={(e) => handleChangeText(e, index, 0)}
          name="fontSize"
          max={32}
          min={13}
          className="cPointer"
          value={value.fontSize || 16}
          id="borderRadius"
        />
      </div>
      <div className={styles.buttonText}>
        <input
          value={value.btnTitle ?? ""}
          onChange={(e) => handleChangeText(e, index, 0)}
          type="text"
          placeholder="Customize Me"
          name="btnTitle"
          className={styles.btnTextInput}
        />
      </div>
      <span className="my-2 d-block">Change background Color</span>
      <div className="d-flex justify-content-center color-picker-btn">
        <ColorPicker
          value={value.btnClr ?? "#17a2b8"}
          onChange={(e) =>
            handleChangeText(
              {
                target: {
                  name: "btnClr",
                  value: e.css?.backgroundColor ?? "#17a2b8",
                },
              },
              index,
              0
            )
          }
        />
      </div>
      <div className="btnTextColor">
        <span>Change text Color</span>
        <input
          className={`${styles.btnTextInput} w-100`}
          value={value.color ?? "#fff"}
          onChange={(e) => handleChangeText(e, index, 0)}
          type="text"
          placeholder="Paste color code or Enter value"
          name="color"
        />
      </div>
      <div className="save-btn-container mt-3" onClick={handleClose}>
        <button type="button" className="save-btn">
          Save
        </button>
      </div>
    </div>
  );
};
export default ButtonEditor;
