import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";

import { getAllTerms } from "../../../actions/term";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";

const Terms = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });

  // Api calls for get Order
  const getTerms = useCallback(
    async (pageNo) => {
      const res = await dispatch(getAllTerms(pageNo));
      console.log(res);
      setData(res);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getTerms(pagination.currentPage);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date, pagination.currentPage]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/terms",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className=" my-5">
            <DashboardPagesLayout
              pageTitle="Pages"
              // subTitle="items"
              // count={pagination?.count}
            >
              <div className="process-orders-table">
                <table className="m-0">
                  <thead>
                    <tr>
                      <th>Sn#</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.length ? (
                      <>
                        {Data?.map((item, i) => (
                          <tr key={item?._id}>
                            <td
                              className="order-item-number"
                              // onClick={() =>
                              //   navigate(
                              //     `/admin/Edit-${item?.title
                              //       .split(" ")
                              //       .join("-")}?id=${item._id}/`
                              //   )
                              // }
                            >
                              {i + 1}
                            </td>
                            <td>{item.title}</td>

                            <td>{item.description.substring(0, 25)}</td>

                            <td>{moment(item?.createdAt).format("l")}</td>
                            <td>
                              <CustomeTableBtn
                                color="secondary"
                                width={"100px"}
                                className={"mr-2"}
                                onClick={() =>
                                  navigate(
                                    `/admin/Edit-${item?.title
                                      .split(" ")
                                      .join("-")}?id=${item._id}`
                                  )
                                }
                              >
                                Edit
                              </CustomeTableBtn>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className=" text-center">
                            {" "}
                            No Record Found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {Data?.length} of {pagination?.count} Items
                </span>
              </div>
            </DashboardPagesLayout>
          </div>
        </>
      )}
    </>
  );
};

export default Terms;
