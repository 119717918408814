/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

import { getAttribute } from '../../../actions/product';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import { apiURL } from '../../../config/default';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function FiltersCustomSettings({ getAttribute }) {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      await getSettings();
      const { data: res } = await getAttribute();
      setattributes(res);
      setloading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettings = async () => {
    const { data: settings } = await axios.get(
      `${apiURL}filters/custom-filter/${location.search.split('=')[1]}`
    );
    const attr = [];
    if (settings.length !== 0) {
      const data = settings[0];
      if (!data.range)
        data.range = [
          { rangeMin: '', rangeMax: '', text: '', isFillable: false },
        ];
      if (!data.values)
        data.values = [
          {
            productAttribute: [],
            text: '',
            title: '',
            showImage: false,
            imageURL: '',
            description: '',
          },
        ];
      else {
        data.values = data.values.map((val) => ({ ...val, isbase64: false }));

        for (let index = 0; index < data.values.length; index++) {
          let id = data.values[index].attributeId;

          const { data: res } = await axios.get(
            `${apiURL}admin/get-product-attribute-text/${id}`
          );

          attr[index] = res || [];
          let newAttribute = [];
          newAttribute = data.values[index].productAttribute.map((attr_) => ({
            value: attr_._id,
            label: attr_.text,
          }));
          data.values[index].productAttribute = newAttribute;
        }
      }
      setSecondLevelAttribute(attr);
      setstate({ ...data, isbase64: false });
    }
  };

  const [state, setstate] = useState({
    title: '',
    showImage: false,
    imageURL: '',
    checkBoxes: false,
    isRange: false,
    filter: location.search.split('=')[1],
    range: [
      { title: '', rangeMin: '', rangeMax: '', text: '', isFillable: false },
    ],
    values: [
      {
        productAttribute: [],
        attributeId: '',
        title: '',
        showImage: false,
        imageURL: '',
        description: '',
      },
    ],
  });

  const [loading, setloading] = useState(true);
  const [attributes, setattributes] = useState([]);
  const [SecondLevelAttribute, setSecondLevelAttribute] = useState([[]]);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          productAttribute: [],
          attributeId: '',
          title: '',
          showImage: false,
          imageURL: '',
          description: '',
        },
      ],
    });
  };

  const handleAddRange = () => {
    setstate({
      ...state,
      range: [
        ...state.range,
        { title: '', rangeMin: '', rangeMax: '', text: '', isFillable: false },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };
    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleRemoveRange = (index) => {
    const newState = { ...state };
    newState.range.splice(index, 1);
    setstate(newState);
  };

  const handleValuesChange = (e, index) => {
    const newState = { ...state };
    newState.values[index][e.target.name] = e.target.value;
    setstate(newState);
  };

  const handleGetAttributes = async (index, id) => {
    const { data: res } = await axios.get(
      `${apiURL}admin/get-product-attribute-text/${id}`
    );

    const attr = [...SecondLevelAttribute];
    attr[index] = res || [];
    setSecondLevelAttribute(attr);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === '') return alert('title is required');

    let dataToSend = { ...state };

    const values1 = [];

    state.values.forEach((value, index) => {
      let attribute = [];
      value.productAttribute.forEach((attr) => {
        attribute.push(attr.value);
      });
      values1[index] = {
        ...state.values[index],
        productAttribute: attribute,
      };
    });
    dataToSend = { ...dataToSend, values: values1 };
    if (!state.checkBoxes) delete dataToSend.values;
    if (!state.isRange) delete dataToSend.range;
    setloading(true);
    if (state._id) {
      const { data: res } = await axios.put(`${apiURL}filters/custom-filter`, {
        ...dataToSend,
      });
      toast.success('Update Successful', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const { data: res } = await axios.post(`${apiURL}filters/custom-filter`, {
        ...dataToSend,
      });
      toast.success('Creation Successful', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    await getSettings();

    setloading(false);
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);

    if (state._id) setstate({ ...state, imageURL: image, isbase64: true });
    else setstate({ ...state, imageURL: image });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />

      <div className="page-header">
        <h3 className="page-title"> Custom Settings </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/filter-category">Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              <div className="row mb-3 mt-3">
                <div className="col-md-3  mt-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Title"
                    name="title"
                    value={state.title}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="col-md-3  mt-3">
                  <label>Show Image</label>
                  <div
                    className="connection-all"
                    onClick={() =>
                      setstate({ ...state, showImage: !state.showImage })
                    }
                  >
                    <span
                      className={state.showImage ? "allcenter" : "allLeft "}
                    >
                      ON
                    </span>

                    <span
                      className={state.showImage ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>

                {state.showImage && (
                  <div className="col-md-3  mt-3">
                    <label>Image URL</label>
                    <input
                      type="file"
                      name="imageURL"
                      onChange={(e) => _handleImageChange(e)}
                      className="review-input border-radius-8"
                    />
                  </div>
                )}

                <div className="col-md-3  mt-3">
                  <label>Show CheckBoxes</label>
                  <div
                    className="connection-all"
                    onClick={() =>
                      setstate({
                        ...state,
                        checkBoxes: !state.checkBoxes,
                      })
                    }
                  >
                    <span
                      className={state.checkBoxes ? "allcenter" : "allLeft "}
                    >
                      ON
                    </span>

                    <span
                      className={state.checkBoxes ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>
                <div className="col-md-3  mt-3">
                  <label>Is Range</label>
                  <div
                    className="connection-all"
                    onClick={() =>
                      setstate({ ...state, isRange: !state.isRange })
                    }
                  >
                    <span className={state.isRange ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span
                      className={state.isRange ? "editRight" : "editcenter "}
                    >
                      OFF
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row my-2">
                {state.isRange &&
                  state.range.map((range_, index) => (
                    <>
                      <div
                        className="col-md-3 mb-5"
                        style={{ position: "relative" }}
                      >
                        <div className="def-row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-start justify-content-between">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-view-stacked"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
                              </svg>
                              <h4>Range</h4>
                              {state.range.length > 1 && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRemoveRange(index)}
                                >
                                  &times;
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12  mt-3">
                            <label>Is Fillable</label>
                            <div
                              className="connection-all"
                              onClick={() => {
                                const newState = { ...state };
                                state.range[index].isFillable =
                                  !state.range[index].isFillable;
                                setstate(newState);
                              }}
                            >
                              <span
                                className={
                                  state.range[index].isFillable
                                    ? "allcenter"
                                    : "allLeft "
                                }
                              >
                                ON
                              </span>

                              <span
                                className={
                                  state.range[index].isFillable
                                    ? "editRight"
                                    : "editcenter "
                                }
                              >
                                OFF
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12  mt-3">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Title"
                              name="title"
                              value={state.range[index].title}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.range[index].title = e.target.value;
                                setstate(newState);
                              }}
                              required
                            />
                          </div>

                          <div className="col-md-12  mt-3">
                            <label>Select Attribute</label>
                            <select
                              name="text"
                              className="form-control"
                              value={range_.text}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.range[index].text = e.target.value;
                                setstate(newState);
                              }}
                              required
                            >
                              <option value="default">Choose</option>
                              <option value="length">Length</option>
                              <option value="width">Width</option>
                              <option value="height">Height</option>
                              <option value="depth">Depth</option>
                              {/* {attributes &&
                              attributes.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))} */}
                            </select>
                          </div>
                          <div className="col-md-12  mt-3">
                            <label>Min Range</label>
                            <input
                              type="number"
                              className="form-control"
                              id="rangeMin"
                              placeholder="rangeMin"
                              name="rangeMin"
                              value={range_.rangeMin}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.range[index].rangeMin = e.target.value;
                                setstate(newState);
                              }}
                              required
                            />
                          </div>
                          <div className="col-md-12  mt-3">
                            <label>Max Range</label>
                            <input
                              type="number"
                              className="form-control"
                              id="rangeMax"
                              placeholder="rangeMax"
                              name="rangeMax"
                              value={range_.rangeMax}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.range[index].rangeMax = e.target.value;
                                setstate(newState);
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </>
                  ))}
                {state.isRange && (
                  <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Button
                        className="p-3"
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddRange}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="row my-2">
                {state.checkBoxes &&
                  state.values.map((value, index) => (
                    <>
                      <div
                        className="col-md-3"
                        style={{ position: "relative" }}
                      >
                        <div className="def-row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-start justify-content-between">
                              <h5>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-check2-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                                  <path d="M8.354 10.354l7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                </svg>
                              </h5>
                              <h4>CheckBox</h4>
                              {state.values.length > 1 && (
                                <div
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRemoveField(index)}
                                >
                                  &times;
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12  mt-3">
                            <label>Select Attribute</label>
                            <select
                              name="attributeId"
                              className="form-control"
                              value={value.attributeId}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.values[index].attributeId =
                                  e.target.value;
                                setstate(newState);
                                handleGetAttributes(index, e.target.value);
                              }}
                              required
                            >
                              <option value="">Choose</option>
                              {attributes &&
                                attributes.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-12  mt-3">
                            <label>Select Attribute</label>

                            <Select
                              isMulti
                              name="productAttribute"
                              closeMenuOnSelect={false}
                              // ref={(select) => {
                              //   if (select) select.setState({ isOpen: true });
                              // }}
                              defaultValue={
                                state.values[index]?.productAttribute || []
                              }
                              options={
                                SecondLevelAttribute[index]?.map((attr) => ({
                                  value: attr._id,
                                  label: attr.text,
                                })) || []
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.values[index].productAttribute = e;
                                setstate(newState);
                              }}
                            />
                          </div>

                          <div className="col-md-12  mt-3">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Title"
                              name="title"
                              value={state.values[index].title}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.values[index].title = e.target.value;
                                setstate(newState);
                              }}
                              required
                            />
                          </div>

                          <div className="col-md-12  mt-3">
                            <label>Show Image</label>
                            <div
                              className="connection-all"
                              onClick={() => {
                                const newState = { ...state };
                                newState.values[index].showImage =
                                  !state.values[index].showImage;
                                setstate(newState);
                              }}
                            >
                              <span
                                className={
                                  state.values[index].showImage
                                    ? "allcenter"
                                    : "allLeft "
                                }
                              >
                                ON
                              </span>

                              <span
                                className={
                                  state.values[index].showImage
                                    ? "editRight"
                                    : "editcenter "
                                }
                              >
                                OFF
                              </span>
                            </div>
                          </div>

                          {state.values[index].showImage && (
                            <div className="col-md-12  mt-3">
                              <label>Image URL</label>
                              <input
                                type="file"
                                onChange={async (e) => {
                                  const image = await convertImgToBase64(
                                    e.target.files[0]
                                  );
                                  const newState = { ...state };
                                  newState.values[index].imageURL = image;
                                  if (state._id)
                                    newState.values[index].isbase64 = true;
                                  setstate(newState);
                                }}
                                className="review-input border-radius-8"
                                name="imageURL"
                              />
                            </div>
                          )}

                          <div className="col-md-12  mt-3">
                            <label>Description</label>
                            <input
                              type="text"
                              className="form-control"
                              id="description"
                              placeholder="Description URL"
                              name="description"
                              value={state.values[index].description}
                              onChange={(e) => {
                                const newState = { ...state };
                                newState.values[index].description =
                                  e.target.value;
                                setstate(newState);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {state.checkBoxes && (
                  <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Button
                        className="p-3"
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddField}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button
          className="ml-2 mt-3"
          variant="outline-primary"
          size="sm"
          type="submit"
          disabled={loading}
        >
          {loading ? "loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
}

export default connect(null, { getAttribute })(FiltersCustomSettings);
