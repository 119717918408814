/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { discountProducts, deleteProduct } from "../../../actions/product";

import TopBarProgress from "react-topbar-progress-indicator";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class DiscountProducts extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: null,
      productsClone: null,
      deleteConfirm: false,
      currentPage: 1,
      dataPerPage: 50,
    };
  }

  async componentDidMount() {
    const res = await this.props.discountProducts();
    this.setState({ products: res, productsClone: res, loading: false });
  }
  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  previous = (event) => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  next = (event) => {
    const { products, dataPerPage } = this.state;
    if (this.state.currentPage < Math.ceil(products.length / dataPerPage)) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };
  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteProduct = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteProduct(id);
    const res = await this.props.discountProducts();
    this.setState({ loading: false, deleteConfirm: false, products: res });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.products.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  render() {
    const { loading, products, currentPage, dataPerPage } = this.state;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData =
      products && products.slice(indexOfFirstData, indexOfLastData);

    const renderTodos =
      currentData &&
      currentData.map((item, index) => {
        return (
          <tr key={index}>
            <td> {index + 1} </td>
            <td> {item?.productId?.title} </td>
            <td>
              <img src={item.images && item.images[0]} alt={item.title} />
            </td>
            <td>
              {" "}
              <Moment format="DD/MM/YYYY">{item.salteEndDate}</Moment>{" "}
            </td>
            <td>
              <Link to={`/admin/edit-product/?id=${item._id}`}>
              <button
                type="button"
                className="btn btn-primary btn-sm mr-4"
                style={{ width: 78 }}

              >
                Edit
              </button>
              </Link>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ deleteConfirm: true });
                }}
              >
                Delete
              </button>

              {this.state.deleteConfirm && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  title="Are you sure?"
                  onConfirm={() => {
                    this._deleteProduct(item._id);
                  }}
                  onCancel={this._onCancel}
                  focusCancelBtn
                >
                  Are you sure you want to delete?
                </SweetAlert>
              )}
            </td>
          </tr>
        );
      });
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(products && products.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers =
      pageNumbers &&
      pageNumbers.map((number, index) => {
        return (
          <li
            key={number}
            className={
              this.state.currentPage === number
                ? "page-item active"
                : "page-item"
            }
          >
            <button
              className="page-link"
              type="button"
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </button>
          </li>
        );
      });
    // const { loading, products } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {products && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View All Products </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/products">Products</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Products List</h4>
                    <span
                      className="twitter-typeahead"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      <input
                        className="typeahead form-control tt-input"
                        type="text"
                        placeholder="Search..."
                        autoComplete="off"
                        spellCheck="false"
                        dir="auto"
                        style={{
                          position: "relative",
                          verticalAlign: "top",
                          backgroundColor: "transparent",
                          borderColor: "green",
                          color: "black",
                        }}
                        onKeyDown={this._handleKeyDown}
                        onChange={this._onChangeHandler}
                      />
                    </span>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Name </th>
                          <th> Image</th>
                          <th> Sale End </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products && products.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No main products found</td>
                          </tr>
                        ) : (
                          renderTodos
                        )}
                      </tbody>
                    </table>
                    <div className="row">
                      <div
                        style={{
                          marginTop: "2em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {products.length > 0 && (
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  type="button"
                                  onClick={this.previous}
                                >
                                  Previous
                                </button>
                              </li>

                              {renderPageNumbers}
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  type="button"
                                  onClick={this.next}
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

discountProducts.propTypes = {
  discountProducts: PropTypes.func.isRequired,
};

deleteProduct.propTypes = {
  deleteProduct: PropTypes.func.isRequired,
};

export default connect(null, { discountProducts, deleteProduct })(
  DiscountProducts
);
