/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import Header from "./components/Header";
import Search from "./components/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./styles.css";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getAllBrand } from "../../../../actions/seo";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import TableLoader from "../../../../components/TableSkelton/TableLoader";
import moment from "moment";
import OrderStatusButton from "../../order/components/OrderStatusButton";
import { Pagination } from "@mui/material";

const TitleAndDescription = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // local state
  const [loading, setLoading] = useState(false);

  const [Data, setData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  const fetchData = useCallback(
    async (currentPage, SearchQuery) => {
      setLoading(true);
      const res = await dispatch(getAllBrand(currentPage, SearchQuery));
      if (res) {
        setData(res?.seoOptimizes);
        setpagination({
          ...res?.pagination,
        });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(pagination.currentPage, SearchFillter.SearchQuery);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, SearchFillter.SearchQuery]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  return (
    <>
      <div className=" d-flex  justify-content-end align-center flex-wrap gap-10">
        <SearchFilter
          type="text"
          placeholder={"Search Vendor"}
          value={SearchFillter?.SearchQuery}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchQuery: e.target.value,
            })
          }
        />
      </div>
      <div className=" my-5">
        <DashboardPagesLayout
          pageTitle="Title And Description"
          subTitle="items"
          count={pagination?.count}
        >
          <div className="process-orders-table">
            <table className="m-0">
              <thead>
                <tr>
                  <th> Active</th>
                  <th>Vendor</th>
                  <th>Title Optimized</th>
                  <th>Description Optimized</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={6} />}

                {!loading &&
                  Data?.map((item, index) => (
                    <tr
                      key={index}
                      className=" c-pointer"
                      onClick={() =>
                        navigate(
                          `/admin/title-and-description/${item.brandName}/${item.brandId}/category`
                        )
                      }
                    >
                      {console.log(item)}
                      <td>
                        <span className="mr-4">
                          <input
                            type="checkbox"
                            checked
                            className="CheckColor"
                          />
                        </span>
                      </td>
                      <td> {item.brandName}</td>
                      <td className="font-weight-bold">
                        {item?.totalTitleOptimized} /{item?.total}
                      </td>

                      <td>
                        {item?.totalDescriptionOptimized} /{item?.total}
                      </td>

                      <td>
                        <ArrowForwardIosIcon
                          className="text-body"
                          fontSize="small"
                        />
                      </td>
                    </tr>
                  ))}
                {!Data?.length && !loading && (
                  <tr>
                    <td colSpan={19} className=" text-center">
                      "No record Found"
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span className=" font-weight-bold" style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
          </div>
        </DashboardPagesLayout>
      </div>
    </>
  );
};

export default TitleAndDescription;
