import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getReferralUsersDetails } from "../../../actions/user";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orders: [],
      userData: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = window.location.toString().split("referral-user-details/")[1];
    const res = await this.props.getReferralUsersDetails(id);

    this.setState({
      loading: false,
      userData: res.data[0],
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { userData } = this.state;
    console.log(userData);
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> User Detail </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/users">Users</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User Detail
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <Alert />

                <div className="row">
                  <div className="col-md-3">
                    <div className="card profile" style={{ width: "18rem" }}>
                      <div className="card-body">
                        <img
                          alt=""
                          src={`https://ui-avatars.com/api/?background=38ce3c&color=fff&name=${userData.fullName}`}
                        />
                        <h5 className="card-title">
                          {" "}
                          {userData && userData.fullName}
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          {userData && userData.userName}
                        </h6>
                        <p className="card-text">
                          {userData && userData.email}
                        </p>
                        <p className="card-text">
                          Number of Referrals :{" "}
                          {userData &&
                            userData.referrals &&
                            userData.referrals.length}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <div className="row">
                      {userData &&
                        userData.referrals &&
                        userData.referrals.map((item, index) => (
                          <div key={index} className="col-md-4">
                            <div className="our-team-main">
                              <div className="team-front">
                                <img
                                  alt=""
                                  src={`https://ui-avatars.com/api/?background=38ce3c&color=fff&name=${item.referralsInfo[0].fullName}`}
                                  className="img-fluid"
                                />

                                <h3>{item.referralsInfo[0].fullName}</h3>
                                <p>{item.referralsInfo[0].userName}</p>
                              </div>
                              <div className="team-back">
                                <span>
                                  <b>Referred By</b> :{" "}
                                  {userData && userData.fullName}
                                </span>{" "}
                                <br />
                                <span>{item.referralsInfo[0].email}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

getReferralUsersDetails.propTypes = {
  getReferralUsersDetails: PropTypes.func.isRequired,
};

export default connect(null, { getReferralUsersDetails })(Edit);
