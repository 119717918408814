import axios from "axios";
import { apiURL } from "../config/default";
export const getAllRoutes = async () => {
  try {
    const res = await axios.get(`${apiURL}admin/get-all-routes`);
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const getAllTypes = async () => {
  try {
    const res = await axios.get(`${apiURL}admin/get-all-roles`);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const sendPermissions = async (data) => {
  console.log(data)
try {
  const res = await axios.post(`${apiURL}admin/add-permissions-to-role`,data);
  return res;
} catch (err) {
  console.log(err);
}
};
