import React from 'react';

const strfy = (_JSON) => JSON.stringify(_JSON);

const areEqual = (prevProps, nextProps) => {
  if (
    strfy(prevProps.title) === strfy(nextProps.title) &&
    strfy(prevProps.isFillable) === strfy(nextProps.isFillable) &&
    strfy(prevProps.text) === strfy(nextProps.text) &&
    strfy(prevProps.showImage) === strfy(nextProps.showImage) &&
    strfy(prevProps.rangeMin) === strfy(nextProps.rangeMin) &&
    strfy(prevProps.rangeMax) === strfy(nextProps.rangeMax) &&
    strfy(prevProps.minMax) === strfy(nextProps.minMax)
  )
    return true;
  return false;
};

function RangeComponent({
  index,
  state,
  handleRemoveRange,
  setstate,
  range_,
  title,
  isFillable,
  text,
  rangeMin,
  rangeMax,
  getRangeMinMax,
  minMax,
  rangeAttr,
  showImage,
  convertImgToBase64 = async () => {},
}) {
  return (
    <div className="col-md-3 mb-5" style={{ position: "relative" }} key={index}>
      <div className="def-row">
        <div className="col-md-12">
          <div className="d-flex align-items-start justify-content-between">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-view-stacked"
              viewBox="0 0 16 16"
            >
              <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
            </svg>
            <h4>Range</h4>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleRemoveRange(index)}
            >
              &times;
            </div>
          </div>
        </div>
        <div className="col-md-12  mt-3">
          <label>Is Fillable</label>
          <div
            className="connection-all"
            onClick={() => {
              const newState = { ...state };
              state.range[index].isFillable = !isFillable;
              setstate(newState);
            }}
          >
            <span className={isFillable ? "allcenter" : "allLeft "}>ON</span>

            <span className={isFillable ? "editRight" : "editcenter "}>
              OFF
            </span>
          </div>
        </div>

        <div className="col-md-12  mt-3">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Title"
            name="title"
            value={title}
            onChange={(e) => {
              const newState = { ...state };
              newState.range[index].title = e.target.value;
              setstate(newState);
            }}
            required
          />
        </div>

        <div className="col-md-12  mt-3">
          <label>Select Attribute</label>
          <select
            name="text"
            className="form-control"
            value={text}
            onChange={(e) => {
              const newState = { ...state };

              newState.range[index].text = e.target.value;
              newState.range[index].attr_id = rangeAttr.find(
                (a) => a.text === e.target.value
              )?._id;

              setstate(newState);
              e.target.value && getRangeMinMax(e.target.value, index);
            }}
            required
          >
            <option value="">Choose</option>
            {rangeAttr.map((attr) => (
              <option value={attr.text}>{attr.text}</option>
            ))}
          </select>
        </div>
        <div className="col-md-12  mt-3">
          <label>Show Image</label>
          <div
            className="connection-all"
            onClick={() => {
              const newState = { ...state };
              newState.range[index].showImage = !showImage;
              setstate({ ...newState });
            }}
          >
            <span className={showImage ? 'allcenter' : 'allLeft '}>ON</span>

            <span className={showImage ? 'editRight' : 'editcenter '}>OFF</span>
          </div>
        </div>

        {showImage && (
          <div className="col-md-12  mt-3">
            <label>Image URL</label>
            <input
              type="file"
              onChange={async (e) => {
                const image = await convertImgToBase64(e.target.files[0]);
                const newState = { ...state };
                newState.range[index].imageURL = image;
                if (state._id) newState.range[index].isbase64 = true;
                setstate(newState);
              }}
              className="review-input border-radius-8"
              name="imageURL"
            />
          </div>
        )}
        <div className="col-md-12  mt-3">
          <label>Min Range - {minMax[text]?.min && minMax[text]?.min}</label>
          <input
            type="number"
            className="form-control"
            id="rangeMin"
            placeholder="rangeMin"
            name="rangeMin"
            value={rangeMin || minMax[text]?.min || 0}
            onChange={(e) => {
              const newState = { ...state };
              newState.range[index].rangeMin = e.target.value;
              setstate(newState);
            }}
            onBlur={(e) => {
              if (minMax[text] && e.target.value < minMax[text].min) {
                const newState = { ...state };
                newState.range[index].rangeMin = e.target.value;
                setstate(newState);
              }
            }}
            required
          />
        </div>
        <div className="col-md-12  mt-3">
          <label>Max Range - {minMax[text]?.max && minMax[text]?.max}</label>
          <input
            type="number"
            className="form-control"
            id="rangeMax"
            placeholder="rangeMax"
            name="rangeMax"
            value={rangeMax || minMax[text]?.max || 0}
            onChange={(e) => {
              if (minMax[text] && e.target.value > minMax[text].max) return;
              const newState = { ...state };
              newState.range[index].rangeMax = e.target.value;
              setstate(newState);
            }}
            required
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(RangeComponent, areEqual);
