import React, { useState } from "react";
import ConversationCard from "./ConversationCard";
import ClarificationBtn from "./ClarificationBtn";
import SendAnswer from "./SendAnswer";
import AnswerCard from "./AnswerCard";
import { useDispatch } from "react-redux";
import { getAnswers, updateClarification } from "../../../../actions/faqs";
import { useCallback } from "react";
import { useEffect } from "react";
import { Fragment } from "react";

const ManageConversation = ({ isRender, setIsRender, selectedItem }) => {
  const dispatch = useDispatch();
  const [status, setstatus] = useState(selectedItem?.clarification ?? false);
  const [answers, setAnswers] = useState([]);

  // handle Clarification status
  const handleUpdateClarification = async (id, status) => {
    try {
      const res = await dispatch(
        updateClarification({ id, clarification: status })
      );
      if (res) {
        setstatus(status);
        setIsRender(!isRender);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // get Answers
  const getAllAnswer = useCallback(
    async (id) => {
      const res = await dispatch(getAnswers(id));
      if (res) {
        setAnswers(res?.answers);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getAllAnswer(selectedItem?._id);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRender]);

  return (
    <div className=" p-3">
      <ClarificationBtn
        status={status}
        onClick={() => handleUpdateClarification(selectedItem?._id, !status)}
      />
      <ConversationCard selectedItem={selectedItem} />
      {answers?.map((item, index) => (
        <Fragment key={index}>
          <AnswerCard item={item} />
        </Fragment>
      ))}
      <SendAnswer
        isRender={isRender}
        setIsRender={setIsRender}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default ManageConversation;
