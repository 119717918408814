import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import SelectSearchFilter from "../../../components/CustomeFilter/SelectSearchFilter";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import OrderStatusButton from "../order/components/OrderStatusButton";
import { CircularProgress, Pagination } from "@mui/material";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";
import AddNewConversations from "./components/AddNewConversations";
import ManageConversation from "./components/ManageConversation";
import { getAllFAQs } from "../../../actions/faqs";

const FAQ = () => {
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [selectedItem, setSelectedItem] = useState({});

  const [SearchFillter, setSearchFillter] = useState({
    creatorName: "",
    faqId: "",
    topic: "",
    clarification: "",
  });
  const fetchData = useCallback(
    async (currentPage, creatorName, faqId, topic, clarification) => {
      setLoading(true);
      const res = await dispatch(
        getAllFAQs(currentPage, creatorName, faqId, topic, clarification)
      );
      if (res) {
        if (!selectedItem) {
          setSelectedItem({});
        } else {
          const newSelected = res?.faqs?.find(
            (item) => item._id === selectedItem._id
          );
          setSelectedItem(newSelected);
        }
        setLoading(false);
        setData(res?.faqs);

        setpagination({
          ...res?.pagination,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data, selectedItem]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(
        pagination.currentPage,
        SearchFillter.creatorName,
        SearchFillter.faqId,
        SearchFillter.topic,
        SearchFillter.clarification
      );
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.creatorName,
    SearchFillter.faqId,
    SearchFillter.topic,
    SearchFillter.clarification,
    isRender,
  ]);

  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  // add new conversations
  const handleConversations = (item) => {
    setEdit({
      open: true,
      edit: "AddNewConversation",
      title: "+ Add New Conversation",
    });
    setSelectedItem({ ...item });
  };

  // add new conversations
  const handleClarification = (item) => {
    setEdit({
      open: true,
      edit: "ManageConversation",
      title: "Conversation",
    });
    setSelectedItem({ ...item });
  };

  return (
    <>
      <div
        className=" d-flex  justify-content-end align-center flex-wrap"
        style={{ gap: "20px" }}
      >
        <SearchFilter
          type="text"
          placeholder={"Search Creater"}
          value={SearchFillter?.creatorName}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              creatorName: e.target.value,
            })
          }
        />
        <SearchFilter
          type="text"
          placeholder={"Search FAQ ID"}
          value={SearchFillter?.faqId}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              faqId: e.target.value,
            })
          }
        />
        <SelectSearchFilter
          title={"Filter Topic"}
          value={SearchFillter?.topic}
          placeholder="All"
          options={[
            { value: "Buyers Remorse", label: "Buyers Remorse" },
            { value: "Damage & Defective", label: "Damage & Defective" },
            { value: "Cancelled Order", label: "Cancelled Order" },
            {
              value: "Tracking Number Issue",
              label: "Tracking Number Issue",
            },
            { value: "Status Update", label: "Status Update" },
            { value: "Product Inquiry", label: "Product Inquiry" },

            { value: "Others", label: "Others" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, topic: value })
          }
        />

        <SelectSearchFilter
          title={"Filter Clarification"}
          value={SearchFillter?.clarification}
          placeholder="All"
          options={[
            { value: "true", label: "Clarification" },
            { value: "false", label: "No Clarification" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, clarification: value })
          }
        />
      </div>
      <div className=" mt-4">
        <div className="dashboardCardContainer bg-half-white">
          <div className="px-4 py-3 d-flex justify-content-between align-center">
            <div className="">
              <h4 className=" font-weight-bolder m-0">FAQs</h4>
              <p className=" text-muted"> {pagination?.count} Conversations </p>
            </div>
            <div className="">
              <button
                onClick={() => handleConversations({})}
                className=" btn btn-success"
              >
                + Add New Conversations
              </button>
            </div>
          </div>
          <hr className=" m-0" />
          <div>
            <div className="dashboard-pages-table">
              <table className=" w-100">
                <thead className="bg-half-white">
                  <tr>
                    <th>FAQ ID</th>
                    <th>Topic</th>
                    <th>Creator</th>
                    <th>Refrence</th>
                    <th>Question</th>
                    {/* <th>Answer</th> */}
                    <th>Clarification</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableLoader rowsNum={8} cellNum={8} />}
                  {Data?.map((item, index) => (
                    <tr key={item?._id}>
                      <td style={{ width: "150px" }}>{item?.faqId}</td>

                      <td>
                        <span>{item?.topic}</span>
                        {/* <br /> */}
                        {/* <span>Buyers Remorse</span> */}
                      </td>
                      <td>{item?.creatorName}</td>
                      <td>{item?.referenceTitle}</td>
                      <td>{item?.question}</td>
                      {/* <td>
                            Go to metro login page and search by customer name
                            here the details can be found for tracking.
                          </td> */}

                      <td>
                        {item?.clarification ? (
                          <OrderStatusButton
                            //   onClick={() => handleOpen(item)}
                            className="mx-auto"
                            status={"Returned"}
                            width={"40"}
                          >
                            !
                          </OrderStatusButton>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <OrderStatusButton
                          onClick={() => handleClarification(item)}
                          className="mx-auto c-pointer"
                          status={"+add"}
                          width={"100px"}
                        >
                          View
                        </OrderStatusButton>
                      </td>
                    </tr>
                  ))}

                  {!loading && !Data?.length && (
                    <tr>
                      <td colSpan={13} className=" text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="d-flex justify-content-center align-center p-2">
                <div className=" mx-auto">
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handleChange}
                  />
                </div>

                <span
                  className=" font-weight-bold"
                  style={{ color: "#9f9f9f" }}
                >
                  {Data?.length} of {pagination?.count} Items
                </span>
              </div>
            </div>
          </div>

          <EditTabelDrawer
            Edit={Edit}
            setEdit={setEdit}
            shippingId={selectedItem?.faqId}
          >
            {loading ? (
              <div className=" d-flex align-center justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {Edit.edit === "AddNewConversation" && (
                  <AddNewConversations
                    isRender={isRender}
                    setIsRender={setIsRender}
                    setEdit={setEdit}
                  />
                )}
                {Edit.edit === "ManageConversation" && (
                  <ManageConversation
                    isRender={isRender}
                    setIsRender={setIsRender}
                    selectedItem={selectedItem}
                  />
                )}
              </>
            )}
          </EditTabelDrawer>
        </div>
      </div>
    </>
  );
};

export default FAQ;
