/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { getAllRoutes, sendPermissions, getAllTypes } from "../../../services/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const userTypes = ["Data Team", "Vendor", "Users"];

const Permissions = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allRoutes, setAllRoutes] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [backendTitles, setBackendTitles] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [dataToSend, setDataToSend] = useState({});
  const [userRole, setUserRole] = useState({});
  const [selectedRoutesIds, setSelectedRoutesIds] = useState([]);
  const [backendRoute, setBackendRoute] = useState("");
  const [backendArr, setBackendArr] = useState([]);
  const fetchRoutes = async () => {
    setLoading(true);
    const res = await getAllRoutes();
    setLoading(false);
    setAllRoutes(res.data?.data);
  };
  const fetchTypes = async () => {
    setLoading(true);
    const res = await getAllTypes();
    setLoading(false);
    setUserTypes(res.data?.roles);
  };

  useEffect(() => {
    fetchTypes();
    fetchRoutes();
  }, []);

  const handleCategory = (e) => {
    console.log(e)
    setBackendArr(e.backend);
    setSelectedCategory(e?.label);
    // setBackendRoute(e)
    setPersonName([]);
  };

  const getStringsArr = (arr) => {
    return arr?.map((item) => item?.title);
  };

  const handleBackendRouteChange = (route, category) => {
    let seletecRoutes = dataToSend;
    if (seletecRoutes[category._id] && seletecRoutes[category._id][route._id]) {
      delete seletecRoutes[category._id][route._id];
    } else {
      if (seletecRoutes[category._id]) {
        seletecRoutes[category._id][route._id] = { ...route }
      } else {
        seletecRoutes[category._id] = {};
        seletecRoutes[category._id][route._id] = { ...route }
      }
    }
    setDataToSend({ ...seletecRoutes });
  };
  const handleSelectType = (param) => {
    let seletecRoles = {};
    if (seletecRoles[param._id]) {
      delete seletecRoles[param._id];
    } else {
      seletecRoles[param._id] = param
    }
    // console.log(seletecRoles);
    setUserRole(seletecRoles)
  };
  const handleSubmit = () => {
    let permissions = []
    Object.entries(dataToSend).forEach(([key, val]) => {
      let category =allRoutes.find(elem=>elem._id===key);
      permissions.push({
        title: category.title,
        frontendUrl: category.frontend,
        apiRoutes: Object.keys(val).map(elem => {
          return {title:dataToSend[key][elem].title,
            url:dataToSend[key][elem].url};
        })
      })
    });
    if(Object.keys(userRole).length > 0 && permissions.length > 0){
      sendPermissions({ type:userRole[Object.keys(userRole)[0]].type,
      id:userRole[Object.keys(userRole)[0]]._id,
      permissions:permissions,
      })
    }else{
      toast.error('U need to choose atleast one role and permission', { containerId: "permission" });
    }
    // console.log(Object.keys(userRole)[0], '<========data to send==');

  };
  // console.log(selectedRoutesIds);
  return (
    <div className="container-scroller">
      {loading && <TopBarProgress />}
      <ToastContainer
          enableMultiContainer
          containerId={"permission"}
          position={toast.POSITION.TOP_RIGHT}
        />
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-sm-flex align-items-baseline report-summary-header">
                    <h5 className="font-weight-semibold">Permissions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <p className="h4">Type:</p>
          <div>
            {userTypes.map((userType,index) => (
              <Fragment key={index}>
              <button
                onClick={() => handleSelectType(userType)}
                // className="btn btn-primary m-2"
                className={`m-2 btn ${!userRole[userType._id]
                  ? "btn-secondary"
                  : "btn-primary"
                  }`}
              >{userType.type}</button>
              </Fragment>
            ))}
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-12">
              <h2>Categories</h2>
              <div className="mt-3">
                <div
                  className="d-flex align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  {/* <div className="m-3 w-100">
                    <Select
                      className="basic-single"
                      classNamePrefix="select "
                      isDisabled={loading}
                      isLoading={loading}
                      onChange={(e) => handleCategory(e)}
                      isSearchable={true}
                      name="color"
                      options={allRoutes ?? []}
                    />
                  </div> */}
                  <ul>
                    {allRoutes && allRoutes.length > 0 && allRoutes.map(elem =>
                    <Fragment key={elem._id} >
                      <li>
                        <h3>{elem.title} </h3>
                        {elem.backend?.map((item,index) => (
                          <Fragment key={index}>
                          <button
                            onClick={() => handleBackendRouteChange(item, elem)}
                            className={`m-2 rounded btn ${!dataToSend[elem._id]?.[item._id]
                              ? "btn-secondary"
                              : "btn-primary"
                              }`}
                          >
                            {item?.title}
                          </button>
                          </Fragment>
                        ))}
                      </li>
                      </Fragment>
                    )}
                  </ul>
                </div>

                <div className="my-4">
                  <h2>Backend Routes</h2>

                  {backendArr.length > 0 && (
                    <div className="m-3 w-100">
                      {backendArr?.map((item,index) => (
                        <Fragment key={index}>
                        <button
                          onClick={() => handleBackendRouteChange(item)}
                          className={`m-2 rounded btn ${!selectedRoutesIds.includes(item._id)
                            ? "btn-secondary"
                            : "btn-primary"
                            }`}
                        >
                          {item?.title}
                        </button>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
                <button onClick={handleSubmit} className="btn btn-primary">
                  Submit
                </button>
                <div id="test" className="mt-5">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Dolorem atque ad molestiae iusto? Laudantium hic ex voluptate
                  voluptatem quae, similique debitis explicabo natus facilis
                  excepturi corporis numquam blanditiis dolore illo! Lorem ipsum
                  dolor sit amet consectetur, adipisicing elit. Dolorem atque ad
                  molestiae iusto? Laudantium hic ex voluptate voluptatem quae,
                  similique debitis explicabo natus facilis excepturi corporis
                  numquam blanditiis dolore illo! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Dolorem atque ad molestiae
                  iusto? Laudantium hic ex voluptate voluptatem quae, similique
                  debitis explicabo natus facilis excepturi corporis numquam
                  blanditiis dolore illo! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Dolorem atque ad molestiae
                  iusto? Laudantium hic ex voluptate voluptatem quae, similique
                  debitis explicabo natus facilis excepturi corporis numquam
                  blanditiis dolore illo! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Dolorem atque ad molestiae
                  iusto? Laudantium hic ex voluptate voluptatem quae, similique
                  debitis explicabo natus facilis excepturi corporis numquam
                  blanditiis dolore illo! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Dolorem atque ad molestiae
                  iusto? Laudantium hic ex voluptate voluptatem quae, similique
                  debitis explicabo natus facilis excepturi corporis numquam
                  blanditiis dolore illo!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;
