import React from "react";

const SaleAnalysisSuggestedShipping = ({ SelectItem }) => {
  return (
    <div className="pending-shipping-details my-2">
      <h6 className=" mb-3"> Sale Analysis Suggested:</h6>
      {SelectItem?.companyName && (
        <>
          <h6>Shipping Carrier:</h6>
          <p>
            {SelectItem?.shippingInfo?.labels[0]?.companyName ||
              SelectItem?.orShipping?.companyName ||
              SelectItem?.companyName}
          </p>
        </>
      )}

      {SelectItem?.shipType && (
        <div className="mt-2">
          <h6> Shipping type:</h6>
          <p>
            {SelectItem?.shippingInfo?.shippingType ||
              SelectItem?.orShipping?.type ||
              SelectItem?.shipType}
          </p>
        </div>
      )}
    </div>
  );
};

export default SaleAnalysisSuggestedShipping;
