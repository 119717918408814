import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create, retreive } from "../../../actions/paymentSetting";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class PaymentSetting extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orderCommissionPercentage: "",
      withdrawalLimitDays: "",
      minimumWithdrawalLimit: "",
      maximumWithdrawalLimit: "",
      processingFeePercentage: ""
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const _res = await this.props.retreive();
    const {
      orderCommissionPercentage,
      withdrawalLimitDays,
      minimumWithdrawalLimit,
      maximumWithdrawalLimit,
      processingFeePercentage
    } = _res.data;

    if (this._isMounted) {
      this.setState({
        loading: false,
        orderCommissionPercentage,
        withdrawalLimitDays,
        minimumWithdrawalLimit,
        maximumWithdrawalLimit,
        processingFeePercentage
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      orderCommissionPercentage,
      withdrawalLimitDays,
      minimumWithdrawalLimit,
      maximumWithdrawalLimit,
      processingFeePercentage
    } = this.state;

    this.setState({ loading: true });

    const res = await this.props.create({
      orderCommissionPercentage,
      withdrawalLimitDays,
      minimumWithdrawalLimit,
      maximumWithdrawalLimit,
      processingFeePercentage
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const {
      orderCommissionPercentage,
      withdrawalLimitDays,
      minimumWithdrawalLimit,
      maximumWithdrawalLimit,
      processingFeePercentage
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Payment Setting </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Payment Setting
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit Payment Setting</h4>

                <Alert />

                <form className="form-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Order Commission (%)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="orderCommissionPercentage"
                            value={
                              orderCommissionPercentage &&
                              orderCommissionPercentage
                            }
                            onChange={(e) =>
                              this.setState({
                                orderCommissionPercentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Withdrawal Limit (Days)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="withdrawalLimitDays"
                            value={withdrawalLimitDays && withdrawalLimitDays}
                            onChange={(e) =>
                              this.setState({
                                withdrawalLimitDays: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Minimum Withdrawal Limit
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="minimumWithdrawalLimit"
                            value={
                              minimumWithdrawalLimit && minimumWithdrawalLimit
                            }
                            onChange={(e) =>
                              this.setState({
                                minimumWithdrawalLimit: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Maximum Withdrawal Limit
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="maximumWithdrawalLimit"
                            value={
                              maximumWithdrawalLimit && maximumWithdrawalLimit
                            }
                            onChange={(e) =>
                              this.setState({
                                maximumWithdrawalLimit: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Processing Fee (%)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="processingFeePercentage"
                            value={
                              processingFeePercentage && processingFeePercentage
                            }
                            onChange={(e) =>
                              this.setState({
                                processingFeePercentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                    <Link to={"/admin/terms"}>
                    <button
                      className="btn btn-light"
                      type="button"

                    >
                      Cancel
                    </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired
};

retreive.propTypes = {
  retreive: PropTypes.func.isRequired
};

export default connect(null, { create, retreive })(PaymentSetting);
