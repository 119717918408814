import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const OrderChart = ({ title, data }) => {
  const filterData = [];
  if (data) {
    data.sort((a, b) => (a.month > b.month ? 1 : b.month > a.month ? -1 : 0));
    data.forEach((element) => {
      let orderObj = {};

      orderObj.x = new Date(2023, element.month);
      orderObj.y = element.count;
      filterData.push(orderObj);
    });
  }

  const options = {
    animationEnabled: true,
    toolTip: {
      // eslint-disable-next-line no-template-curly-in-string
      content: "{x}: ${y}",
      backgroundColor: "#fafafa",
      borderColor: "gray",
      borderThickness: 1,
      fontColor: "black",
      fontSize: 16,
      padding: 20,
      width: 500,
      height: 500,
    },
    title: {},

    axisX: {
      labelFontSize: 20,
      labelFontStyle: "bold",
      gridColor: "transparent",
      valueFormatString: "MMM",
      title: title,
        labelFormatter: function (e) {
          var monthNames = [
            "J",
            "F",
            "M",
            "A",
            "M",
            "J",
            "J",
            "A",
            "S",
            "O",
            "N",
            "D",
          ];
          return monthNames[e.value.getMonth()];
        },
    },
    axisY: {
      labelFontSize: 10,
      labelFontStyle: "bold",
      color: "black",
      gridColor: "transparent",
      // title: "Sales (in USD)",
      suffix: title === "Revenue" ? "k" : "",
    },
    data: [
      {
        type: "line",
        color: "#1baeee",
        toolTipContent: `{x} <br/> ${title}:<br/>  ${
          title === "Revenue" ? "$" : ""
        }{y}`,
        xValueFormatString: "MMMM",
        dataPoints: filterData,
      },
    ],
  };
  return (
    <>
      <CanvasJSChart options={options} />
    </>
  );
};

export default OrderChart;
