import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";

const strfy = (_JSON) => JSON.stringify(_JSON);

const areEqual = (prevProps, nextProps) => {
  if (
    strfy(prevProps.title) === strfy(nextProps.title) &&
    strfy(prevProps.showImage) === strfy(nextProps.showImage) &&
    strfy(prevProps.productAttribute) === strfy(nextProps.productAttribute) &&
    strfy(prevProps.attributes) === strfy(nextProps.attributes) &&
    strfy(prevProps.SecondLevelAttribute) ===
      strfy(nextProps.SecondLevelAttribute) &&
    strfy(prevProps.description) === strfy(nextProps.description) &&
    strfy(prevProps.attributeId) === strfy(nextProps.attributeId) &&
    strfy(prevProps.showThisFilter) === strfy(nextProps.showThisFilter)
  )
    return true;
  return false;
};

const SVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    class='bi bi-check2-square'
    viewBox='0 0 16 16'
  >
    <path d='M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z' />
    <path d='M8.354 10.354l7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z' />
  </svg>
);

function CheckBoxComponent({
  title,
  imageURL,
  showImage,
  state,
  value,
  handleRemoveField,
  index,
  setstate,
  handleGetAttributes,
  attributes,
  SecondLevelAttribute,
  convertImgToBase64,
  productAttribute = [],
  attributeId = "",
  description = "",
  isAuto,
  showThisFilter,
}) {
  // eslint-disable-next-line no-unused-vars
  const [options, setoptions] = useState([]);
  const [productAttributeoptions, setproductAttributeoptions] = useState([]);
  const [matchfromstart, setmatchfromstart] = useState(false);
  useEffect(() => {
    if (!isAuto) {
      setoptions(
        attributes.map((attr) => ({
          value: attr._id,
          label: attr.name,
        }))
      );

      setproductAttributeoptions(
        SecondLevelAttribute?.map((attr) => ({
          value: attr._id,
          label: attr.text,
        })) ?? []
      );

      const title = attributes.filter((attr) => attr._id === attributeId)[0]
        ?.name;

      if (title && title.toLowerCase().includes("size"))
        setmatchfromstart(true);
    }
  }, [attributes, attributeId, SecondLevelAttribute, index, isAuto]);

  const renderToggleButton = (key, title) => (
    <>
      <label>{title}</label>
      <div
        className='connection-all'
        onClick={() => {
          const newState = { ...state };
          newState.values[index][key] = !showThisFilter;
          setstate(newState);
        }}
      >
        <span className={showThisFilter ? "allcenter" : "allLeft "}>ON</span>

        <span className={showThisFilter ? "editRight" : "editcenter "}>
          OFF
        </span>
      </div>
    </>
  );

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: matchfromstart ? "start" : "any",
  };

  return (
    <div
      className='col-md-3 mb-3'
      style={{ position: "relative" }}
      key={value._id}
    >
      <div className='def-row '>
        <div className='col-md-12'>
          <div className='d-flex align-items-start justify-content-between'>
            <h5>
              <SVG />
            </h5>
            <h4>CheckBox</h4>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleRemoveField(index)}
            >
              &times;
            </div>
          </div>
        </div>

        {!isAuto && (
          <div className='col-md-12  mt-3'>
            <label>Select Attribute</label>

            <Select
              isMulti
              name='productAttribute'
              closeMenuOnSelect={false}
              value={productAttributeoptions.filter((op) =>
                productAttribute.some((v) => v.value === op.value)
              )}
              options={productAttributeoptions}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => {
                const newState = { ...state };

                newState.values[index].productAttribute = e ?? [];

                setstate(newState);
              }}
              filterOption={createFilter(filterConfig)}
            />
          </div>
        )}

        <div className='col-md-12  mt-3'>
          <label>Title</label>
          <input
            type='text'
            className='form-control'
            id='title'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => {
              const newState = { ...state };
              newState.values[index].title = e.target.value;
              setstate({ ...newState });
            }}
            required
          />
        </div>
        {isAuto && (
          <div className='col-md-12  mt-3'>
            {renderToggleButton("isShow", "Toggle Filter")}
          </div>
        )}

        <div className='col-md-12  mt-3'>
          <label>Show Image</label>

          <div
            className='connection-all'
            onClick={() => {
              const newState = { ...state };
              newState.values[index].showImage = !state.values[index].showImage;
              setstate(newState);
            }}
          >
            <span className={showImage ? "allcenter" : "allLeft "}>ON</span>

            <span className={showImage ? "editRight" : "editcenter "}>OFF</span>
          </div>
        </div>

        {showImage && (
          <div className='col-md-12  mt-3'>
            <label>Image URL</label>
            <div className='d-flex justify-content-between align-items-center'>
              <input
                type='file'
                onChange={async (e) => {
                  const image = await convertImgToBase64(e.target.files[0]);
                  const newState = { ...state };
                  newState.values[index].imageURL = image;
                  if (state._id) newState.values[index].isbase64 = true;
                  setstate(newState);
                }}
                className='review-input border-radius-8'
                name='imageURL'
              />
              {showImage === true && (
                <div>
                  <img
                    src={imageURL ?? ""}
                    style={{ objectFit: "contain" }}
                    wdith='50px'
                    height='50px'
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className='col-md-12  mt-3'>
          <label>Description</label>
          <input
            type='text'
            className='form-control'
            id='description'
            placeholder='Description'
            name='description'
            value={description}
            onChange={(e) => {
              const newState = { ...state };
              newState.values[index].description = e.target.value;
              setstate(newState);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(CheckBoxComponent, areEqual);
