import React from "react";
import UpdateOrderTracking from "./UpdateOrderTracking";
import UpdateOrderTrackingIntransit from "./UpdateOrderTrackingIntransit";
import { useEffect } from "react";
// import EmailHistory from "./EmailHistory";
// import moment from "moment";

const UpdateOrderTrackingComp = ({ SelectItem, setIsMount, IsMount }) => {
  useEffect(() => {}, [IsMount, SelectItem]);

  const handleNextStatus = (status) => {
    switch (status) {
      case "Processing":
        return "Processing";
      case "Preparing To Ship":
        return "Preparing To Ship";
      case "In Transit":
        return "In Transit";
      case "Delivered":
        return "Delivered";
      case "Backordered":
        return "Backordered";
      default:
        return "";
    }
  };

  return (
    <div className="px-3 pb-5">
      <div className="status-input-container ">
        <UpdateOrderTracking
          textNote={"Order has been placed by customer."}
          buttonText={`Update On:`}
          IsMount={IsMount}
          setIsMount={setIsMount}
          SelectItem={SelectItem}
          status={"Pending"}
          Date={{ date: SelectItem?.createdAt, status: "Pending" }}
          isComplete={true}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        />
        <UpdateOrderTracking
          textNote={"Order has been accepted. Estimated ship date is unknown"}
          buttonText={`Update On:`}
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          status={"Processing"}
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Processing"
          )}
          isComplete={true}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        />

        <UpdateOrderTracking
          textNote={"Estimated ship date is know."}
          buttonText={`Update On:`}
          DateTitle="Est. Ship Date:"
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          status={"Preparing To Ship"}
          statusName={"Preparing To Ship"}
          DateLable="Estimated Ship Date"
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Preparing To Ship"
          )}
          EstimatedDate={SelectItem?.orderStatus[1]?.estimateDate}
          ShipStartDate={SelectItem?.shippingInfo?.pickupDate}
          ShipEndDate={SelectItem?.shippingInfo?.pickupEndDate}
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "Preparing To Ship"
          )}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        />

        <UpdateOrderTrackingIntransit
          textNote={"Order has been released for delivery. Provide Tracking #."}
          buttonText={`Update On:`}
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          status={"In Transit"}
          statusName={"In Transit"}
          DateLable="Actual Ship Date"
          Date={SelectItem?.orderStatus?.find(
            (item) => item.status === "In Transit"
          )}
          EstimatedDate={SelectItem?.orderStatus[2]?.estimateDate}
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "In Transit"
          )}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
          isDisable={SelectItem?.shippingInfo?.deliveryDate}
        />
        <UpdateOrderTracking
          textNote={"Order has been Delivered."}
          buttonText={`Update On:`}
          DateTitle="Final Delivery Date:"
          emailStatus="Manual"
          statusName={"Delivered"}
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          DateLable="Final  Delivery Date"
          status={"Delivered"}
          EstimatedDate={SelectItem?.orderStatus[5]?.estimateDate}
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Delivered"
          )}
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "Delivered"
          )}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        />
        {/* <UpdateOrderTracking
          textNote={"Item is on backorder. Restock date is known."}
          buttonText={`Update On:`}
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          statusName={"Backordered"}
          DateLable="Estimated Restock Date"
          status={"Backordered"}
          backorderStatus={
            SelectItem?.backorderStatus?.status
              ? SelectItem?.backorderStatus?.status
              : "Pending"
          }
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Backordered"
          )}
          EstimatedDate={SelectItem?.orderStatus[6]?.estimateDate}
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "Backordered"
          )}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        /> */}
        {/* <UpdateOrderTracking
          textNote={"Order is not available."}
          buttonText={`Status updated On:`}
          setIsMount={setIsMount}
          IsMount={IsMount}
          SelectItem={SelectItem}
          statusName={"Not Available"}
          status={"Not Available"}
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Not Available"
          )}
          EstimatedDate={SelectItem?.orderStatus[7]?.estimateDate}
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "Not Available"
          )}
          isActive={handleNextStatus(
            SelectItem?.orderStatus[SelectItem?.orderStatus.length - 1]?.status
          )}
        /> */}

        {/* <UpdateOrderTracking
          color={"Cancelled"}
          setIsMount={setIsMount}
          IsMount={IsMount}
          statusName={"Cancelled"}
          status={"Cancelled"}
          Date={SelectItem?.orderStatus?.find(
            (item) => item?.status === "Cancelled"
          )}
          EstimatedDate={SelectItem?.orderStatus[8]?.estimateDate}
          textNote="The order is currently not in stock. The order will be available to ship on the restock date."
          isComplete={SelectItem?.orderStatus?.some(
            (item) => item?.status === "Cancelled"
          )}
        /> */}
        {/* <EmailHistory
          emailSend={SelectItem?.emailsSent ? SelectItem?.emailsSent : []}
        /> */}
      </div>
    </div>
  );
};

export default UpdateOrderTrackingComp;
