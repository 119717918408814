import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { homeAds, deleteHomeAds } from "../../../actions/homeAds";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class HomeAds extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      homeAds: null,
      deleteConfirm: false,
    };
  }

  async componentDidMount() {
    const res = await this.props.homeAds();
    this.setState({ homeAds: res, loading: false });
  }

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteHomeAds = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteHomeAds(id);
    const res = await this.props.homeAds();
    this.setState({ loading: false, deleteConfirm: false, homeAds: res });
  };

  render() {
    const { loading, homeAds } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {homeAds && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View All HomeAdss</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/homeAds">HomeAdss</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">HomeAdss List</h4>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Image One</th>
                          <th> Url One</th>
                          <th> Image Two</th>
                          <th> Url Two</th>
                          <th> Image Three</th>
                          <th> Url Three</th>
                          <th> Image Four</th>
                          <th> Url Four</th>
                          <th> Created At </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {homeAds && homeAds.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No homeAds found</td>
                          </tr>
                        ) : (
                          homeAds.map((item, index) => (
                            <tr key={index}>
                              <td> {index + 1} </td>
                              <td>
                                <img src={item && item.ad1} alt="" />
                              </td>
                              <td>{item.adUrl1}</td>
                              <td>
                                <img src={item && item.ad2} alt="" />
                              </td>
                              <td>{item.adUrl2}</td>
                              <td>
                                <img src={item && item.ad3} alt="" />
                              </td>
                              <td>{item.adUrl3}</td>
                              <td>
                                <img src={item && item.ad4} alt="" />
                              </td>
                              <td>{item.adUrl4}</td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {item.createdAt}
                                </Moment>
                              </td>
                              <td>
                                <Link to={`/admin/edit-home-ads/?id=${item._id}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mr-4"
                                  style={{ width: 78 }}

                                >
                                  Edit
                                </button>
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                  onClick={() => {
                                    this.setState({ deleteConfirm: true });
                                  }}
                                >
                                  Delete
                                </button>

                                {this.state.deleteConfirm && (
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes, delete it!"
                                    confirmBtnBsStyle="danger"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      this._deleteHomeAds(item._id);
                                    }}
                                    onCancel={this._onCancel}
                                    focusCancelBtn
                                  >
                                    Are you sure you want to delete?
                                  </SweetAlert>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

homeAds.propTypes = {
  homeAds: PropTypes.func.isRequired,
};

deleteHomeAds.propTypes = {
  deleteHomeAds: PropTypes.func.isRequired,
};

export default connect(null, { homeAds, deleteHomeAds })(HomeAds);
