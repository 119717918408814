import React from "react";
import { Link, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, auth } from "../../../actions/auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class Login extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      verified: false,
      email: "",
      password: "",
      signInBtn: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(async () => {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }, 1000);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let verified = params.get("verified");
    if (verified === "true") {
      this.setState({ verified: true });
      toast(`Account has been verifed, please login`, {
        containerId: "B",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    // this.setState({ signInBtn: false });
    await this.setState({ loading: true,signInBtn: false });
    let type = "admin";
    const res = await this.props.login({
      email,
      password,
      type,
    });
    // const res = await this.props.auth();
    if (res?.status !== 200) {
      this.setState({ loading: false,signInBtn: true });
      console.log(res);
      toast.error(res?.data?.message ?? res?.data?.error, {
        containerId: "B",
      });
    }
  };

  render() {
    const { isAuth } = this.props._auth;
    const { loading, email, password, signInBtn } = this.state;
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    return isAuth && !this.props._auth.loading ? (
      <Navigate exact to="/admin/dashboard" />
    ) : (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId="B"
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <img
                      src="/assets/images/logo.svg"
                      alt="logo"
                      draggable={false}
                    />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">
                    Enter your email address to sign in or to create an account.
                  </h6>

                  <Alert />

                  <form
                    onSubmit={(e) => {
                      this._submitHandler(e);
                    }}
                    className="pt-3"
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="password"
                        placeholder="Email"
                        value={email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                    <div className="mt-3">
                      {signInBtn ? (
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          SIGN IN
                        </button>
                      ) : (
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                          disabled
                        >
                          SIGN IN
                        </button>
                      )}
                    </div>
                    <br />
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <Link
                        to={"/admin/forgot"}
                        className="auth-link text-black"
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <div className="text-center mt-4 font-weight-light">
                      {" "}
                      Don't have an account?{" "}
                      <Link to={"/admin/register"} className="text-primary">
                        Register
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

login.propsTypes = {
  login: PropTypes.func.isRequired,
};

auth.propsTypes = {
  auth: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  _auth: state.auth,
});
export default connect(mapStateToProps, { login, auth })(Login);
