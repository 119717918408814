import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { BsCalendar2WeekFill } from "react-icons/bs";
import {
  toISOStringFromat1,
  toISOStringFromat,
  MMDDYYFormat,
} from "../../utils/dateFormate";

const DateRangePicker = ({ startDate, endDate, onChange, TBD, title }) => {
  const [open, setOpen] = useState(false);
  const [Date, setDate] = useState([null, null]);

  const handleChange = (dates) => {
    const [start, end] = dates;
    setDate([start, end]);
    onChange(dates);
  };
  const handleClearDates = () => {
    setDate([null, null]);
    onChange([null, null]); // This will communicate the reset back to the parent component
  };
  return (
    <div className="position-relative c-pointer">
      <div
        onClick={() => setOpen(!open)}
        className={`d-flex justify-content-between align-center add-button mt-3`}
        style={
          TBD
            ? {
                background: "#fdefec",
                borderColor: "#ff5e38",
                color: "#ff5e38",
              }
            : {}
        }
      >
        <p style={{ whiteSpace: "nowrap", color: TBD ? "#ff5e38" : "#898989" }}>
          {title}
          {TBD ? " TBD" : ""}
        </p>
        <p>
          {/* {toISOStringFromat1(startDate)} */}
          {MMDDYYFormat(startDate)}__{MMDDYYFormat(endDate)}
          {/* {toISOStringFromat1(endDate)} */}
          <span className="ml-3">
            <BsCalendar2WeekFill />
          </span>
        </p>
      </div>
      {open && (
        <div
          onMouseLeave={() => setOpen(false)}
          className="position-absolute"
          style={{ color: "#000000" }}
        >
          <ReactDatePicker
            className="text-black"
            selected={Date[0]}
            onChange={handleChange}
            startDate={Date[0]}
            endDate={Date[1]}
            selectsRange
            inline
            isClearable
            dateFormat="MM/dd/yy"
          />
          <button onClick={handleClearDates} className="clear-dates-button">
            Clear
          </button>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
