/* eslint-disable no-unused-vars */
import axios from "axios";
import { apiURL } from "../config/default";
import setAuthHeader from "../utils/setAuthHeader";
import { alert } from "./alert";

export const verifyCogs = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/update-sale-analytics/${id}`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const NotesUpdate = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/update-sale-analytics-notes/${id}`,
      {
        ...data,
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateMarkFraud = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/fraud-verify/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const priorityNotesUpdate = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/update-priority-notes/${id}`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updatePriorityWithStatus = (id, priority) => async () => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/update-priority-status/${id}/${priority}`
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
