import React from "react";
import style from "./customer-ticket.module.css";
import InfoItem from "../../../product/DynamicPricing/components/pricing-side-components/info-item";

const CustomerRatingContainer = ({
  ticketNumber,
  reason,
  ticketSummary,
  ticketDate,
  orderDate,
  deliveredDate,
  ticketSummaryDetail,
}) => {
  return (
    <div className={style.customerRatingContainer}>
      <h3 className={style.ticketTitle} style={{ textDecoration: "underline" }}>
        <a
          href="https://qaadmin.sundialhome.com/admin/all-tickets"
          target="_blank"
          rel="noreferrer"
          style={{color: "black"}}
        >
          {ticketNumber}
        </a>
      </h3>
      <InfoItem crawlType="Reason:" CrawlDescription={`${reason}`} />
      <InfoItem crawlType="SKU:" CrawlDescription={`${ticketSummary}`} />
      <InfoItem crawlType="Ticket Date:" CrawlDescription={`${ticketDate}`} />
      <InfoItem crawlType="Order Date:" CrawlDescription={`${orderDate}`} />
      <InfoItem
        crawlType="Delivered Date:"
        CrawlDescription={`${deliveredDate}`}
      />
      <h3 className={style.ticketTitle}>Ticket Summary:</h3>
      <p>{ticketSummaryDetail}</p>
    </div>
  );
};

export default CustomerRatingContainer;
