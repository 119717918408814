import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

import styles from "./features.module.css";

function Features({
  handleRemoveSection,
  handleChangeText,
  index,
  show,
  handleFileChange,
  handleClose,
  selectedIndex,
  section,
  handleShow,
  mainIndex,
}) {
  const [edit, setEdit] = useState({
    title: [false, false, false, false],
    desc: [false, false, false, false],
  });
  const handleEdit = (name, index) => {
    setEdit({
      ...edit,
      [name]: edit[name].map((value, i) => (i === index ? !value : value)),
    });
  };

  return (
    <div className="d-flex w-100">
      <div className="container-fluid">
        <div className="row">
          {section.values.map((item, _index) => (
            <div className="col-lg-3">
              <div className={styles.featuredCard}>
                <div className="position-relative text-center">
                  <div className={`${styles.cardImg} position-absolute`}>
                    <i
                      className="icon-pencil"
                      onClick={() => handleShow(_index, index)}
                    ></i>
                  </div>
                  <img src={item.url} width="50%" alt="Featured" />
                </div>
                {!edit.title[_index] ? (
                  <div className="position-relative">
                    <h4 className="text-center px-2 my-2">{item.title}</h4>
                    <div
                      className={`${styles.bannerTitlePencil} position-absolute`}
                    >
                      <i
                        onClick={() => handleEdit("title", _index)}
                        className="icon-pencil p-2"
                        title="Edit"
                      ></i>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center px-2">
                    <input
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleEdit("title", _index);
                          handleChangeText(e, index, _index);
                        }
                      }}
                      name="title"
                      defaultValue={item.title || ""}
                      onBlur={(e) => {
                        handleEdit("title", _index);
                        handleChangeText(e, index, _index);
                      }}
                      className={styles.titleInput}
                      autoFocus={true}
                      placeholder="Title"
                    />
                  </div>
                )}
                {!edit.desc[_index] ? (
                  <div className="mt-3 d-flex align-items-center justify-content-center position-relative">
                    <p className="px-3 text-center">{item.desc}</p>
                    <div
                      className={`${styles.bannerTitlePencil} position-absolute`}
                    >
                      <i
                        onClick={() => handleEdit("desc", _index)}
                        className="icon-pencil p-2"
                        title="Edit"
                      ></i>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center px-2">
                    <input
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleEdit("desc", _index);
                          handleChangeText(e, index, _index);
                        }
                      }}
                      name="desc"
                      defaultValue={item.desc || ""}
                      onBlur={(e) => {
                        handleEdit("desc", _index);
                        handleChangeText(e, index, _index);
                      }}
                      className={styles.descInput}
                      autoFocus={true}
                      placeholder="desc"
                    />
                  </div>
                )}
              </div>

              <Modal
                show={show && _index === selectedIndex && mainIndex === index}
                onHide={handleClose}
              >
                <Modal.Header closeButton centered>
                  <Modal.Title>Change Feature Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {item?.url && (
                    <div className="d-flex justify-content-center mb-3">
                      <img src={item?.url} alt="Features " width="400px" />
                    </div>
                  )}
                  <Form.Group controlId="formBasicimage" className="m-2">
                    <Form.Label>
                      Upload File{" "}
                      <span className="ml-2 dimension__text">(96 × 96 px)</span>
                    </Form.Label>
                    <Form.File
                      id={_index}
                      label="Add image"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleFileChange(e, index, _index);
                      }}
                      custom
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasiclink" className="m-2">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="paste link"
                      name="link"
                      defaultValue={item?.link ?? ""}
                      onBlur={(e) => handleChangeText(e, index, _index)}
                    />
                    <Form.Text className="text-muted">
                      This Link will redirect to product page or category page
                    </Form.Text>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={`${styles.actionBtn} btn-danger`}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ))}
        </div>
      </div>
      <div className={`bg-white ${styles.h93}`}>
        <i
          onClick={() => handleRemoveSection(index)}
          className={`${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
          title="Remove"
          style={{ height: "fit-content" }}
        ></i>
      </div>
    </div>
  );
}

export default Features;
