import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import Tabs from "@mui/material/Tabs";
import styles from "./carouselSection.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function BannerCarousel({
  section = [],
  handleShow,
  selectedIndex,
  show,
  handleClose,
  handleFileChange,
  index,
  handleChangeText,
  mainIndex,
  removeSection,
  handleCarouselAutoPlay,
}) {
  const [_value, setValue] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [edit, setEdit] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [indexToChange, setindexToChange] = useState(null);

  return (
    <div className="col-md-6">
      <div className="d-flex justify-content-between">
        <i
          className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-plus`}
          onClick={() => {
            setindexToChange(section.values.length);

            handleShow(-1, index);
          }}
          title="Add"
          style={{ height: "fit-content" }}
        ></i>

        <div className="d-flex align-items-center">
          <label htmlFor="autoPlay" className="mb-0 c-pointer">
            Auto Play
          </label>
          <input
            className="form-control ml-2"
            type="checkbox"
            style={{ width: 20, height: 20 }}
            name="autoPlay"
            onChange={() => handleCarouselAutoPlay(index)}
            id="autoPlay"
            checked={section?.autoPlay}
          />
        </div>
      </div>
      <div>
        <Carousel
          autoPlay={section?.autoPlay}
          centerSlidePercentage={100}
          centerMode={true}
          ismul
          className={styles.homePCarousel}
          showThumbs={false}
          useKeyboardArrows
        >
          {section?.values?.map((value, _index) => (
            <div
              className={`d-flex align-items-center w-100 h-100 ${styles.CarMainImgContainer}`}
              style={{ position: "relative" }}
            >
              <img
                src={value.url}
                width="100%"
                className={styles.carouselImg}
                alt="carousel"
              />
              <div
                style={{ position: "absolute", top: "5px", left: "25px" }}
                className={`bg-white d-flex align-items-center ${styles.h92}`}
              >
                <i
                  className={`${styles.actionBtn} ${styles.hoverPrimary} p-2  icon-pencil`}
                  onClick={() => {
                    handleShow(_index, index);
                  }}
                ></i>
                <i
                  className={`${styles.actionBtn} ${styles.hoverWarning} p-2  icon-close`}
                  onClick={() => removeSection(index, _index)}
                ></i>
              </div>

              <Modal
                show={show && _index === selectedIndex && mainIndex === index}
                onHide={handleClose}
              >
                <Modal.Header closeButton centered>
                  <Modal.Title>Add Images To Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Tabs
                    value={_value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  ></Tabs>

                  <TabPanel value={_value} index={0}>
                    <div className="d-flex flex-column justify-content-center mb-3">
                      <label>Image</label>
                      <img src={value.url} alt="preview" width="400px" />
                    </div>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>Upload Image</Form.Label>{" "}
                      <span className="ml-2 dimension__text">
                        (1133 × 700 px)
                      </span>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index);
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Main Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicdesc" className="m-2">
                      <Form.Label>Image Alt</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type Image Alt"
                        defaultValue={value.alt || ""}
                        name="alt"
                        onChange={(e) => handleChangeText(e, index, _index)}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasiclink" className="m-2">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="paste link"
                        defaultValue={value.link}
                        name="link"
                        onBlur={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        This Link will redirect to product page or category page
                      </Form.Text>
                    </Form.Group>
                  </TabPanel>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ))}
        </Carousel>
      </div>
      <Modal
        show={show && mainIndex === index && selectedIndex === -1}
        onHide={handleClose}
      >
        <Modal.Header closeButton centered>
          <Modal.Title>Add Slide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TabPanel value={_value} index={0}>
            <Form.Group controlId="formBasicimage" className="m-2">
              <img
                width="100%"
                src={section?.values?.[indexToChange]?.url ?? ""}
                alt=""
              />
            </Form.Group>
            <Form.Group controlId="formBasicimage" className="m-2">
              <Form.Label>Upload Image </Form.Label>{" "}
              <Form.File
                id={index}
                label="Add image"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleFileChange(e, index, indexToChange);
                }}
                custom
              />
            </Form.Group>

            <Form.Group controlId="formBasiclink" className="m-2">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="paste link"
                defaultValue={""}
                name="link"
                onBlur={(e) => handleChangeText(e, index, -1)}
              />
              <Form.Text className="text-muted">
                This Link will redirect to product page or category page
              </Form.Text>
            </Form.Group>
          </TabPanel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BannerCarousel;
