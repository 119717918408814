import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { withRouter } from "../../utils/withRouter";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _logout = async () => {
    await this.props.logout();
    this.props.navigate("/admin/login");
  };

  navbar = [
    {
      name: "Dashboard",
      id: "dashboard",
      icon: "icon-chart menu-icon",
      link: "/admin/dashboard",
      level: 1,
      children: [],
    },

    {
      name: "Users",
      id: "users",
      icon: "icon-user-following menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "All Users",
          id: "all-user",
          icon: null,
          link: "/admin/users",
          children: [],
          level: 2,
        },
        {
          name: "Add User",
          id: "add-user",
          icon: null,
          link: "/admin/add-users",
          children: [],
          level: 2,
        },
      ],
    },
    {
      name: "Products",
      id: "products",
      icon: "icon-basket menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "View Products",
          id: "view-products",
          icon: null,
          level: 2,
          link: "/admin/products",
          children: [],
        },
        {
          name: "Attributes",
          id: "Attributes",
          icon: null,
          level: 2,
          link: "/admin/attributes",
          children: [],
        },
        {
          name: "Attribute Groups",
          id: "Attributes",
          icon: null,
          level: 2,
          link: "/admin/attribute-groups",
          children: [],
        },
        {
          name: "Bulk Products",
          id: "bulk-products",
          icon: null,
          level: 2,
          link: "/admin/bulk-products",
          children: [],
        },
        {
          name: "Bulk Inventory",
          id: "bulk-inventory",
          icon: null,
          level: 2,
          link: "/admin/bulk-inventory",
          children: [],
        },

        {
          name: "Update Model Number",
          id: "update-productId",
          icon: null,
          level: 2,
          link: "/admin/update-model-number",
          children: [],
        },

        {
          name: "Add Product Cost",
          id: "add-bulk-products",
          icon: null,
          level: 2,
          link: "/admin/add-product-cost/",
          children: [],
        },
        {
          name: "Download SKU",
          id: "sku",
          icon: null,
          level: 2,
          link: "/admin/downloads/sku",
          children: [],
        },
        // {
        //   name: "Create Coupons",
        //   id: "create-coupons",
        //   icon: null,
        //   level: 2,
        //   link: "/admin/create-coupon",
        //   children: [],
        // },
        {
          name: "Coupons",
          id: "_Coupons",
          icon: null,
          level: 2,
          link: "/admin/coupons",
          children: [],
        },
        {
          name: "Dynamic Pricing",
          id: "dynamic pricing",
          icon: null,
          level: 2,
          link: "/admin/dynamic-pricing",
          children: [],
        },
        {
          name: "Discount Code",
          id: "discount-code",
          level: 2,
          icon: "null",
          link: "/admin/discount-code",
          children: [],
        },
      ],
    },
    {
      name: "Categories",
      id: "Categories",
      icon: "icon-tag menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "Main Category",
          id: "main-category",
          icon: null,
          link: null,
          level: 3,
          children: [
            {
              name: "Insert Main Category",
              id: "insert-main-category",
              icon: null,
              link: "/admin/create-main-category",
              children: [],
              level: 4,
              showArrow: false,
            },
            {
              name: "View Main Category",
              id: "view-main-category",
              icon: null,
              link: "/admin/main-categories",
              children: [],
              level: 4,
              showArrow: false,
            },
          ],
        },
        {
          name: "Child Category",
          id: "child-category",
          icon: null,
          link: null,
          level: 3,
          children: [
            {
              name: "Insert Child Category",
              id: "insert-child-category",
              icon: null,
              link: "/admin/create-child-category",
              children: [],
              level: 4,
              showArrow: false,
            },
            {
              name: "View Child Categories",
              id: "view-child-category",
              icon: null,
              link: "/admin/child-categories",
              children: [],
              level: 4,
              showArrow: false,
            },
          ],
        },
        {
          name: "Sub Categories",
          id: "sub-category",
          icon: null,
          level: 3,
          link: null,
          children: [
            {
              name: "Insert Sub Category",
              id: "insert-sub-category",
              icon: null,
              link: "/admin/create-sub-category",
              children: [],
              level: 4,
              showArrow: false,
            },
            {
              name: "View Sub Categories",
              id: "view-sub-category",
              icon: null,
              link: "/admin/sub-categories",
              children: [],
              level: 4,
              showArrow: false,
            },
          ],
        },
        {
          name: "Child Sub Categories",
          id: "child-sub-category",
          icon: null,
          level: 3,
          link: null,
          children: [
            {
              name: "Insert Child Sub Category",
              id: "insert-child-sub-category",
              icon: null,
              link: "/admin/create-child-subcategory",
              children: [],
              showArrow: false,
            },
            {
              name: "View Child Sub Categories",
              id: "view-child-sub-category",
              icon: null,
              link: "/admin/child-subcategories",
              children: [],
              showArrow: false,
            },
          ],
        },
      ],
    },
    {
      name: "Orders",
      id: "orders",
      level: 1,
      icon: "icon-grid menu-icon",
      link: null,
      children: [
        {
          name: "Sale Analysis",
          id: "sale-analysis",
          level: 1,
          icon: null,
          link: "/admin/sale-analysis",
          children: [],
        },
        {
          name: "Pending Orders",
          id: "pending-orders",
          icon: null,
          level: 2,
          link: "/admin/pending-orders",
          children: [],
        },
        {
          name: "Process Orders",
          id: "process-orders",
          icon: null,
          level: 2,
          link: "/admin/process-orders",
          children: [],
        },
        {
          name: "Quote Orders",
          id: "quote-orders",
          icon: null,
          level: 2,
          link: "/admin/quote-orders",
          children: [],
        },
      ],
    },

    {
      name: "Reviews",
      id: "reviews",
      level: 1,
      icon: "icon-envelope-letter menu-icon",
      link: null,
      children: [
        {
          name: "Manage Reviews",
          id: "manage-reviews",
          icon: null,
          level: 2,
          link: "/admin/manage-reviews",
          children: [],
        },
        {
          name: "Review Summary",
          id: "review-summary",
          icon: null,
          level: 2,
          link: "/admin/review-summary",
          children: [],
        },
      ],
    },

    {
      name: "Blogs",
      id: "blogs",
      level: 1,
      icon: "icon-speech menu-icon",
      link: null,
      children: [
        {
          name: "Create Blog Post",
          id: "create-blog",
          icon: null,
          level: 2,
          link: "/admin/blogs/create-blog",
          children: [],
        },

        {
          name: "Blogs",
          id: "blogs",
          icon: null,
          level: 2,
          link: "/admin/blogs",
          children: [],
        },
      ],
    },

    {
      name: "Mail",
      id: "mail",
      level: 1,
      icon: "icon-envelope-letter menu-icon",
      link: "/admin/bulk-mail",
      children: [],
    },

    {
      name: "Customer Management",
      id: "customer-management",
      icon: "icon-user-following menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "CS Dashboard",
          id: "cs-dashboard",
          icon: null,
          level: 2,
          link: "/admin/cs-dashboard",
          children: [],
        },
        {
          name: "All Tickets",
          id: "all-tickets",
          icon: null,
          level: 2,
          link: "/admin/all-tickets",
          children: [],
        },
        // {
        //   name: "Customer Tickets",
        //   id: "customer-service-dashboard",
        //   icon: null,
        //   level: 2,
        //   link: "/admin/customer-service-dashboard",
        //   children: [],
        // },

        {
          name: "Upload Files",
          id: "upload-files",
          icon: null,
          level: 2,
          link: "/admin/upload-file",
          children: [],
        },
      ],
    },
    {
      name: "Vendor Management",
      id: "multivendor-management",
      icon: "icon-people menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "Vendors",
          id: "vendors",
          icon: null,
          level: 2,
          link: "/admin/vendors",
          children: [],
        },
        {
          name: "Vendors Inventory",
          id: "vendors-inventory",
          icon: null,
          level: 2,
          link: "/admin/vendors-inventory",
          children: [],
        },
        {
          name: "Create Vendors",
          id: "create-vendors",
          icon: null,
          level: 2,
          link: "/admin/create-vendor",
          children: [],
        },
        {
          name: "Vendor Setting",
          id: "vendor-setting",
          icon: null,
          level: 2,
          link: "/admin/vendor-setting",
          children: [],
        },
      ],
    },
    {
      name: "SEO Management",
      id: "content-seo",
      level: 1,
      icon: "icon-magnifier menu-icon",
      link: null,
      children: [
        {
          name: "Title & Description",
          id: "title-description",
          icon: null,
          level: 2,
          link: "/admin/title-and-description",
          children: [],
        },
        {
          name: "Keyword List",
          id: "keyword-list",
          icon: null,
          level: 2,
          link: "/admin/keyword-list",
          children: [],
        },
      ],
    },
    {
      name: "FAQs",
      id: "FAQs",
      icon: "icon-chart menu-icon",
      link: "/admin/frequently-asked-questions",
      level: 1,
      children: [],
    },
    {
      name: "Logs",
      id: "error-logs",
      level: 1,
      icon: "icon-shield menu-icon",
      link: null,
      children: [
        {
          name: "Error Logs",
          id: "error-logs",
          icon: null,
          level: 2,
          link: "/admin/error-log",
          children: [],
        },
        {
          name: "ETL Error Logs",
          id: "etl-error-logs",
          icon: null,
          level: 2,
          link: "/admin/etl-error-log",
          children: [],
        },
      ],
    },
    {
      name: "Shipping",
      id: "shipping",
      level: 1,
      icon: "icon-plane menu-icon",
      link: null,
      children: [
        {
          name: "Companies Lists",
          id: "companies-lists",
          icon: null,
          level: 2,
          link: "/admin/shipping/companies-lists",
          children: [],
        },
        {
          name: "Add Company",
          id: "add-company",
          icon: null,
          level: 2,
          link: "/admin/shipping/add-shipping-company",
          children: [],
        },
      ],
    },
    {
      name: "Setting",
      id: "wp-homepage",
      icon: "icon-settings menu-icon",
      link: null,
      level: 1,
      children: [
        {
          name: "Home Page",
          id: "wp-homepage",
          icon: null,
          level: 2,
          link: "/admin/wp-homepage",
          children: [],
        },
        {
          name: "Layouts",
          id: "layouts",
          icon: null,
          level: 2,
          link: "/admin/layouts",
          children: [],
        },
        {
          name: "Header",
          id: "header",
          icon: null,
          level: 2,
          link: "/admin/header",
          children: [],
        },
        {
          name: "Inspiration",
          id: "Inspiration",
          icon: null,
          level: 2,
          link: "/admin/inspiration",
          children: [],
        },
        {
          name: "Inspiration Tags",
          id: "Tags",
          icon: null,
          level: 2,
          link: "/admin/inspiration-tags",
          children: [],
        },
        {
          name: "Filters",
          id: "filters",
          icon: null,
          link: null,
          level: 2,
          children: [
            {
              name: "Filter Category",
              id: "filter-category",
              icon: null,
              level: 3,
              link: "/admin/filter-category",
              children: [],
            },
            {
              name: "Global Filters",
              id: "global-filters",
              level: 3,
              icon: null,

              link: "/admin/global-filters",
              children: [],
            },
            {
              name: "Custom Global Filters",
              id: "custom-global-filters",
              level: 3,
              icon: null,
              link: "/admin/custom-global-filters",
              children: [],
            },
          ],
        },
        {
          name: "Pages",
          id: "terms-of-use",
          icon: null,
          level: 2,
          link: null,
          children: [
            {
              name: "View Pages",
              id: "view-terms-of-use",
              icon: null,
              level: 3,
              link: "/admin/terms",
              children: [],
            },
          ],
        },
        {
          name: "Community Influencers ",
          id: "community-Influencers",
          icon: null,
          link: null,
          level: 2,
          children: [
            {
              name: "Community Influencers ",
              id: "insert-community-Influencers",
              icon: null,
              level: 3,
              link: "/admin/create-community-influencer",
              children: [],
            },
            {
              name: "View Community Influencers ",
              id: "view-community-Influencers",
              icon: null,
              level: 3,
              link: "/admin/community-influencers",
              children: [],
            },
          ],
        },

        {
          name: "General Setting",
          id: "general-setting",
          icon: null,
          level: 2,
          link: "/admin/general-setting",
          children: [],
        },
        {
          name: "Emails",
          id: "emails",
          level: 1,
          icon: null,
          link: "/admin/emails",
          children: [],
        },
      ],
    },
  ];

  getStyles = (level) => {
    return level === 1
      ? "14px"
      : level === 2
      ? "13px"
      : level === 3
      ? "11px"
      : "10px";
  };
  renderNavbar = (navbar) => {
    return navbar.map((navItem, index) => {
      const {
        name,
        id,
        icon,
        link,
        children,
        style = {},
        showArrow = true,
        // catLevelTwo = false,
        level,
      } = navItem;

      if (children?.length)
        return (
          <Fragment key={index}>
            <li
              key={index}
              className={`nav-item ${
                navItem?.link === this.props.location.pathname && "active"
              }`}
              style={{ ...style, fontSize: this.getStyles(level) }}
            >
              <a
                className="nav-link"
                data-toggle="collapse"
                href={`#${id}`}
                aria-expanded="true"
                aria-controls={id}
                style={{ fontSize: "inherit" }}
              >
                <span className="menu-title" style={{ fontSize: "inherit" }}>
                  {name}
                </span>
                <i className={icon} />
              </a>
              <div
                className={`collapse ${this.props.show === id && "show"}`}
                id={id}
              >
                <ul className="nav flex-column sub-menu">
                  {this.renderNavbar(children)}
                </ul>
              </div>
            </li>
          </Fragment>
        );
      return (
        <Fragment key={index}>
          <li
            className={`nav-item ${
              this.props.show &&
              link === this.props.location.pathname &&
              "active"
            }`}
            style={{ ...style, fontSize: this.getStyles(level) }}
          >
            <Link
              className={showArrow ? "nav-link" : "nav-item-simplified"}
              to={link}
              style={{ fontSize: "inherit" }}
            >
              <span className="menu-title" style={{ fontSize: "inherit" }}>
                {name}
              </span>
              <i className={icon} />
            </Link>
          </li>
        </Fragment>
      );
    });
  };

  render() {
    return (
      <Fragment>
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            {this.renderNavbar(this.navbar)}
            <li className="nav-item">
              <a
                className="nav-link"
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  this._logout();
                }}
              >
                <span className="menu-title">Logout</span>
                <i className="icon-logout menu-icon" />
              </a>
            </li>
          </ul>
        </nav>
      </Fragment>
    );
  }
}

logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logout })(Sidebar));
