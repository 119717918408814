import axios from "axios";
import { apiURL } from "../../config/default";

export const getPricingFile = async () => {
  try {
    const res = axios.get(`${apiURL}admin/pricings`);
    return res;
  } catch (err) {
    return err;
  }
};
export const getLabelBuffer = async (body) => {
  const res = await axios.post(`${apiURL}admin/pdf-buffer`, body);

  return res;
};

export const getAllProductsPricingFileInfo = async () => {
  try {
    const res = axios.get(`${apiURL}admin/get-products-pricing-info`);
    return res;
  } catch (err) {
    return err;
  }
};

export const generateAllProductsPricingFile = async () => {
  try {
    const res = axios.get(`${apiURL}admin/generate-products-with-pricing`);
    return res;
  } catch (err) {
    return err;
  }
};