import axios from "axios";
import { apiURL } from "../../../../config/default";

export const call = async (path, method, data) => {
  let response = await axios({
    method: method,
    url: `${apiURL}${path}`,
    data: data,
  });

  return response;
};
