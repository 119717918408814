import { SwipeableDrawer } from "@mui/material";

const PriceCardDrawer = ({ Edit, setEdit, children, shippingId }) => {
  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={Edit.open}
        onClose={() =>
          setEdit({
            open: false,
            edit: "",
            title: "",
          })
        }
      >
        {children}
      </SwipeableDrawer>
    </>
  );
};

export default PriceCardDrawer;
