// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rating-details_ratingContainer__ecNkv{\n    margin: 1rem;\n}\n\n.rating-details_ratingType__4l2op{\n    font-weight: 700;\n}\n\n.rating-details_ratingValue__2usfS{\n    font-size: 1rem;\n    font-weight: 500;\n}", "",{"version":3,"sources":["webpack://src/pages/admin/reviews/components/rating-details/rating-details.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".ratingContainer{\n    margin: 1rem;\n}\n\n.ratingType{\n    font-weight: 700;\n}\n\n.ratingValue{\n    font-size: 1rem;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ratingContainer": "rating-details_ratingContainer__ecNkv",
	"ratingType": "rating-details_ratingType__4l2op",
	"ratingValue": "rating-details_ratingValue__2usfS"
};
export default ___CSS_LOADER_EXPORT___;
