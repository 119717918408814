/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getAllStores } from "../../../../actions/store";
import axios from "axios";
import { apiURL } from "../../../../config/default";
import {
  getPricingFile,
  generateAllProductsPricingFile,
  getAllProductsPricingFileInfo,
} from "../../../../services/download/download.service";
import Moment from "react-moment";

const Sku = (props) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setselectedBrand] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setloading] = useState(false);
  const [fileCreated, setFileCreated] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [allProductsPricingData, setAllProductsPricingData] = useState([]);
  const [productsFileCreated, setProductsFileCreated] = useState(false);
  const [pricingFileInfo, setPricingFileInfo] = useState({});
  useEffect(() => {
    (async () => {
      const res = await props.getAllStores();

      setBrands(res.data);
      const fileInfo = await getAllProductsPricingFileInfo();
      setPricingFileInfo(fileInfo?.data);
    })();
  }, []);

  const handleDownloadPricing = async (e) => {
    e.preventDefault();
    setloading(true);
    const fileData = await getPricingFile();
    setloading(false);
    setPricingData(fileData?.data ?? []);
    setFileCreated(true);
  };
  const handleDownloadAllPricing = async (e) => {
    e.preventDefault();
    setloading(true);
    const fileData = await generateAllProductsPricingFile();
    if (fileData.status === 200) {
      setloading(false);
      setProductsFileCreated(true);
    }
  };
  useEffect(() => {
    (async () => {
      if (!selectedBrand) return;
      setloading(true);
      const res = await axios.get(`${apiURL}admin/get-sku/${selectedBrand}`);
      if (res.status === 200) {
        let MyData = res?.data?.products.map((item, i) => {
          const { dimensions, boxId } = item.shipping;
          item = {
            ...item,
            boxId,
            modelNumber: item?.modelNumber?.toString(),
          };
          if (dimensions?.length > 0) {
            dimensions.forEach((dim, idx) => {
              const height = `package_height_${idx}`;
              const width = `package_width_${idx}`;
              const length = `package_length_${idx}`;
              const weight = `package_weight_${idx}`;

              item = {
                ...item,
                [height]: dim?.height,
                [width]: dim?.width,
                [length]: dim?.length,
                [weight]: dim?.weight,
              };
            });
          }
          item?.shipping && delete item.shipping;

          return item;
        });

        setData(MyData);
      }
      setloading(false);
    })();
  }, [selectedBrand]);

  return (
    <div className="container-scroller">
      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          Download
        </h3>
      </div>

      <div className="row">
        <div className="col-6 grid-margin stretch-card">
          <div className="card">
            <h4 className="p-3">SKU</h4>
            <div className="card-body">
              <form className="forms-sample">
                <div className="form-group">
                  <label
                    style={{ fontWeight: "bold", fontSize: 20 }}
                    htmlFor="brand"
                  >
                    Select Brand
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="brand"
                    onChange={(e) => {
                      setselectedBrand(e.target.value);
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <option>choose</option>
                    {brands &&
                      brands?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.fullName}
                        </option>
                      ))}
                  </select>
                </div>
                {data && !loading && selectedBrand ? (
                  <CSVLink filename="Sundial sku file" data={data}>
                    Download me
                  </CSVLink>
                ) : (
                  <>{loading && selectedBrand && "Loading..."}</>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="col-6 grid-margin stretch-card">
          <div className="card">
            <h4 className="p-3">PRICING</h4>
            <div className="card-body">
              <form className="forms-sample">
                <button
                  onClick={handleDownloadPricing}
                  className="p-2 background-transparent border-0 btn-primary"
                >
                  Create File
                </button>
                <div className="mt-2">
                  {" "}
                  {fileCreated && (
                    <CSVLink filename="Sundial sku file" data={pricingData}>
                      Click To Download
                    </CSVLink>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-6 grid-margin stretch-card">
          <div className="card">
            <h4 className="p-3">All Products with pricing</h4>
            <div className="card-body">
              <form className="forms-sample">
                <button
                  onClick={handleDownloadAllPricing}
                  className="p-2 background-transparent border-0 btn-danger"
                >
                  Generate New File
                </button>
                <div className="mt-2">
                  {" "}
                  <br />
                  <>
                    <a
                      className="p-2 background-transparent border-0 btn-primary"
                      href={pricingFileInfo?.url}
                      target="__blank"
                    >
                      Download Last Updated File
                    </a>
                    <br />{" "}
                    <h5 className="mt-4 text-success">
                      Last Updated at :{" "}
                      <Moment format="hh:mm a z DD/MM/YYYY">
                        {pricingFileInfo.lastUpdated}
                      </Moment>{" "}
                    </h5>
                  </>
                  <>{!productsFileCreated && loading && "Loading..."}</>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default connect(null, { getAllStores })(Sku);
