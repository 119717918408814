/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./styles.css";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  getAllBrand,
  getAllBrandCategories,
  updateMapData,
} from "../../../../actions/seo";
import { useDispatch } from "react-redux";

import SearchFilter from "../../../../components/CustomeFilter/SearchFilter";
import { Pagination } from "@mui/material";
import DashboardPagesLayout from "../../customerServiceDashboard/components/DashboardPagesLayout";
import TableLoader from "../../../../components/TableSkelton/TableLoader";
import CustomeTableBtn from "../../../../components/custome-table-button/CustomeTableBtn";
import { FcSynchronize } from "react-icons/fc";

const Category = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { brandId, brandName } = useParams();

  // local state
  const [loading, setLoading] = useState(false);
  const [isMount, setisMount] = useState(true);
  const [Data, setData] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  const fetchData = useCallback(
    async (brandId, currentPage, SearchQuery) => {
      setLoading(true);
      const res = await dispatch(
        getAllBrandCategories(brandId, currentPage, SearchQuery)
      );
      if (res) {
        setData(res?.data);
        setpagination({
          ...res?.pagination,
        });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(brandId, pagination.currentPage, SearchFillter.SearchQuery);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, SearchFillter.SearchQuery, isMount]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  const handleChangeMap = (e, index) => {
    const { name, value } = e.target;
    const tempArr = [...Data];
    tempArr[index][name] = value;
    setData(tempArr);
  };
  // update map and non map
  const handleUpdate = async (item) => {
    try {
      const res = await dispatch(updateMapData({ ...item }));
      if (res) {
        setisMount(!isMount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className=" d-flex  justify-content-between align-center flex-wrap gap-10">
        <Link to="/admin/title-and-description" className="link">
          <p className="h6 mt-3 ml-1 text-muted">
            <ArrowBackIosIcon fontSize="small" />
            Selected vendor
          </p>
        </Link>
        <SearchFilter
          type="text"
          placeholder={"Search Category"}
          value={SearchFillter?.SearchQuery}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchQuery: e.target.value,
            })
          }
        />
      </div>
      <div className=" my-5">
        <DashboardPagesLayout
          pageTitle={`${brandName} | Select Category`}
          subTitle="items"
          count={pagination?.count}
        >
          <div className="process-orders-table">
            <table className="m-0">
              <thead>
                <tr>
                  <th> Category</th>
                  <th>Title</th>
                  <th>Desc..</th>
                  <th>Title Preview</th>
                  <th>Percentage..</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={6} />}

                {!loading &&
                  Data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.categoryName}</td>
                      <td>
                        <span
                          className={
                            item.isTitleOptimized
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {item.isTitleOptimized ? "Yes" : "No"}
                        </span>{" "}
                      </td>
                      <td>
                        <span
                          className={
                            item.isDescriptionOptimized
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {item.isDescriptionOptimized ? "Yes" : "No"}
                        </span>{" "}
                      </td>

                      <td>{item?.isCustom ? "Show" : ""}</td>
                      <td>
                        <div className=" d-flex justify-content-center gap-10">
                          <div className=" d-flex justify-content-center align-center gap-10">
                            <span>Map</span>
                            <input
                              style={{ width: "80px" }}
                              className=" border-radius-10 p-2 border-grey "
                              type="number"
                              min={0}
                              name="mapPercentage"
                              value={item?.mapPercentage}
                              placeholder="Map"
                              onChange={(e) => handleChangeMap(e, index)}
                            />
                          </div>{" "}
                          <div className=" d-flex justify-content-center align-center gap-10">
                            <span>No Map</span>
                            <input
                              style={{ width: "80px" }}
                              className=" border-radius-10 p-2 border-grey "
                              type="number"
                              required
                              min={0}
                              name="nonMapPercentage"
                              value={item?.nonMapPercentage}
                              placeholder="No Map"
                              onChange={(e) => handleChangeMap(e, index)}
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex justify-content-center align-center gap-15">
                          <CustomeTableBtn
                            color="danger"
                            width={"10px"}
                            className={"p-2 m-0"}
                            onClick={() => handleUpdate(item)}
                          >
                            <FcSynchronize className="text-success" />
                          </CustomeTableBtn>
                          <CustomeTableBtn
                            color="success"
                            width={"100"}
                            className={"p-2 m-0"}
                            onClick={() =>
                              navigate(
                                `/admin/title-and-description/${brandName}/${brandId}/${item.categoryName}/${item.categoryId}`
                              )
                            }
                          >
                            <ArrowForwardIosIcon
                              className="text-body"
                              fontSize="small"
                            />
                          </CustomeTableBtn>
                        </div>
                      </td>
                    </tr>
                  ))}
                {!Data?.length && !loading && (
                  <tr>
                    <td colSpan={19} className=" text-center">
                      "No record Found"
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span className=" font-weight-bold" style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
          </div>
        </DashboardPagesLayout>
      </div>
    </>
  );
};

export default Category;
