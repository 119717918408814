import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import BannerCarousel from "./components/carousel/Carousel";

const HeroBanner = ({
  section,
  removeSection,
  index,
  handleClose,
  show,
  selectedIndex,
  handleFileChange,
  mainIndex,
  handleChangeText,
  handleShow,
  handleParent,
  handleAddValueAuto,
  handleRemoveSection,
  toggleLoading,
  handleRightBannerTextChange,
  handleRightBannerFileChange,
  handleCarouselAutoPlay,
}) => {
  const [textStatus, setTextStatus] = React.useState([false, false]);
  const handleEdit = (index) => {
    let tempArr = [...textStatus];
    tempArr[index] = !tempArr[index];
    setTextStatus(tempArr);
  };

  const [innerIndex, setInnerIndex] = React.useState(null);
  const [showRightModal, setShowRightModal] = React.useState(false);

  const handleShowModal = (index) => {
    setInnerIndex(index);
    setShowRightModal(true);
  };
  const handleRightModalClose = () => {
    setInnerIndex(null);
    setShowRightModal(false);
  };
  return (
    <div className="d-flex">
      <div className="container-fluid">
        <div className="d-flex">
          <div className="container-fluid">
            <div className="row">
              <BannerCarousel
                handleCarouselAutoPlay={handleCarouselAutoPlay}
                section={section}
                handleShow={handleShow}
                selectedIndex={selectedIndex}
                show={show}
                handleClose={handleClose}
                handleFileChange={handleFileChange}
                index={index}
                handleChangeText={handleChangeText}
                mainIndex={mainIndex}
                removeSection={handleRemoveSection}
                handleAddValueAuto={handleAddValueAuto}
                toggleLoading={toggleLoading}
                handleParent={handleParent}
              />

              <div className="col-md-6">
                {section?.rightHandBanners?.map((item, _index) => (
                  <div className="border border-secondary my-2">
                    <div className="hero-card-img border-radius-8 text-center position-relative">
                      <img
                        style={{ backgroundColor: item?.bgColor ?? "" }}
                        src={item?.url}
                        width="100%"
                        alt=""
                      />
                      <div className="herocardPencil position-absolute">
                        <i
                          className="icon-pencil"
                          onClick={() => handleShowModal(_index)}
                        ></i>
                      </div>
                      <div
                        className="p-2 position-absolute border-radius-8"
                        style={{ top: "47px", right: 5 }}
                      >
                        <input
                          type="color"
                          value={item?.bgColor ?? ""}
                          onChange={(e) =>
                            handleRightBannerTextChange(e, index, _index)
                          }
                          name="bgColor"
                          style={{
                            width: "42px",
                            height: "30px",
                          }}
                          id=""
                        />
                      </div>
                    </div>
                    {textStatus[_index] ? (
                      <div className="d-flex justify-content-center align-items-center px-2">
                        <input
                          type="text"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEdit(_index);
                            }
                          }}
                          name="desc"
                          defaultValue={item.desc || ""}
                          onBlur={(e) => {
                            handleEdit(_index);
                          }}
                          autoFocus={true}
                          onChange={(e) =>
                            handleRightBannerTextChange(e, index, _index)
                          }
                          placeholder="Title"
                        />
                      </div>
                    ) : (
                      <div className="card-text d-flex ">
                        <p className="m-0">{item?.desc || "Text"}</p>
                        <i
                          onClick={() => handleEdit(_index)}
                          className="icon-pencil p-2"
                          title="Edit"
                        ></i>
                      </div>
                    )}

                    <Modal
                      show={showRightModal && innerIndex === _index}
                      onHide={handleRightModalClose}
                    >
                      <Modal.Header closeButton centered>
                        <Modal.Title>Change Feature Image</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {item?.url && (
                          <div className="d-flex justify-content-center mb-3">
                            <img
                              src={item?.url}
                              alt="Features Banner"
                              width="400px"
                            />
                          </div>
                        )}
                        <Form.Group controlId="formBasicimage" className="m-2">
                          <Form.Label>Upload File </Form.Label>
                          <span className="ml-2 dimension__text">
                            ( 700 × 215 px)
                          </span>
                          <Form.File
                            id={_index}
                            label="Add image"
                            type="file"
                            name="url"
                            accept="image/*"
                            onChange={(e) => {
                              handleRightBannerFileChange(e, index, _index);
                            }}
                            custom
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasiclink" className="m-2">
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="paste link"
                            name="link"
                            defaultValue={item?.link ?? ""}
                            onBlur={(e) =>
                              handleRightBannerTextChange(e, index, _index)
                            }
                          />
                          <Form.Text className="text-muted">
                            This Link will redirect to product page or category
                            page
                          </Form.Text>
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className={`btn-danger`}
                          variant="secondary"
                          onClick={handleRightModalClose}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <i
          className="hoverWarning d-block mb-2 icon-close"
          onClick={() => handleRemoveSection(index)}
        ></i>
      </div>
    </div>
  );
};

export default HeroBanner;
