import React from "react";
import { useNavigate } from "react-router";

const DashboardCard = ({ title, subTitle, count, link, children }) => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white broder-radius-10 overflow-hidden "
      style={{ border: "1px solid #e5e5e5" }}
    >
      <div className="px-4 py-3 d-flex justify-content-between align-center">
        <div className="">
          <h4 className=" font-weight-bold m-0">{title}</h4>
          {title !== "My Cashback" && (
            <p className=" text-muted">
              {count} {subTitle}
            </p>
          )}
        </div>
        {link && (
          <div className="see-all-btn" onClick={() => navigate(link)}>
            See All
          </div>
        )}
      </div>
      <hr className=" m-0" />
      <div>{children}</div>
    </div>
  );
};

export default DashboardCard;
