import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import Drawer from "@mui/material/Drawer";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
const closeStyle = {
  fontSize: "2.3rem",
  display: "inline",
  width: "fit-content",
  padding: "5px",
  cursor: "pointer",
  background: "transparent",
  border: "none",
};
export default function BodyModal({
  open,
  closeDrawer,
  editorState,
  handleImageChange,
  onEditorStateChange,
  handleLinkTextChange,
  data,
  handleRemoveBodyLink,
  handleAddDataToBody,
  handleAddColumns,
  handleRemoveBodySection,
}) {
  const getContent = (item, index1, index2) => {
    switch (item.type) {
      case "LINK":
        return (
          <div className="d-flex justify-content-center my-2">
            <DropdownButton id="dropdown-item-button" title="Link">
              <Dropdown.ItemText>Add Link(optional) and text</Dropdown.ItemText>
              <div className="text-center m-2">
                <input
                  className="form-group p-2"
                  value={item.text}
                  name="text"
                  onChange={(e) => handleLinkTextChange(e, index1, index2)}
                  placeholder="Text"
                />
              </div>
              <div className="text-center m-2">
                <input
                  className="form-group p-2"
                  name="link"
                  value={item?.link ?? ""}
                  onChange={(e) => handleLinkTextChange(e, index1, index2)}
                  placeholder="Link"
                />
              </div>
            </DropdownButton>
          </div>
        );
      case "IMAGE":
        return (
          <div className="my-2 d-flex justify-content-center">
            <div className="d-flex">
              <button className="border-0 h-100">
                <label
                  htmlFor={`bodyP_img-${index1}${index2}`}
                  title="Upload Image"
                  className="d-flex align-items-center h-100 c-pointer"
                >
                  <span className="icon-cloud-upload"></span>
                </label>

                <input
                  type="file"
                  id={`bodyP_img-${index1}${index2}`}
                  name="image"
                  className="d-none"
                  // value={item?.url ?? ""}
                  onChange={(e) => handleImageChange(e, index1, index2)}
                />
              </button>

              <img src={item?.url} width="100px" height="100px" alt="" />
            </div>
            {/* <button
              className="border-0 "
              onClick={() => handleRemoveBodyLink(index1, index2)}
            >
              <span className="icon-minus"></span>
            </button> */}
          </div>
        );
      default:
    }
  };

  const renderSection = (items, index1) => {
    const isArray = Array.isArray(items);
    return (
      isArray && (
        <div className="d-flex border p-2 position-relative my-3 bg-secondary">
          <button
            className="border-0"
            style={{ top: 0, position: "absolute", right: 0 }}
            onClick={() => handleRemoveBodySection(index1)}
          >
            <span className="icon-minus"></span>
          </button>
          {isArray &&
            items?.map((item, index2) => (
              <div className="w-100">
                <div>
                  <button
                    onClick={() =>
                      handleAddDataToBody(index1, index2, { type: "IMAGE" })
                    }
                    className="btn btn-info p-1 m-2"
                    title="Add Image"
                  >
                    <i className="icon-picture text-white"></i>
                  </button>
                  <button
                    onClick={() =>
                      handleAddDataToBody(index1, index2, { type: "LINK" })
                    }
                    className="btn btn-info p-1 m-2"
                    title="Add Link"
                  >
                    <i className="icon-link text-white"></i>
                  </button>
                </div>
                <div className="w-100 border " style={{ minHeight: "100px" }}>
                  {getContent(item, index1, index2)}
                </div>
              </div>
            ))}
        </div>
      )
    );
  };

  const options = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "emoji",
      "history",
    ],
  };

  return (
    <Drawer
      style={{ width: "100%" }}
      anchor="right"
      open={open}
      onClose={closeDrawer}
    >
      <div style={{ width: "100%" }}>
        <button onClick={closeDrawer} title="Close" style={closeStyle}>
          &times;
        </button>
        <small className="d-inline-block mb-3 px-2">
          Here you can only structure an email and give it a bit of style..
          we'll give the actuall design later on when sending this email
        </small>
        <Editor
          toolbar={options}
          editorState={editorState}
          placeholder="CHANGE ME"
          initialContentState="Lore ipsum dollar"
          onEditorStateChange={onEditorStateChange}
        />

        <div className="d-flex mt-4">
          <p className="ml-2">Click to add section</p>
          <div
            onClick={() => handleAddColumns(1)}
            className="ml-3 border c-pointer border-info p-1"
            style={{ width: "30px", height: "20px" }}
          ></div>
          <div
            onClick={() => handleAddColumns(2)}
            className="d-flex ml-3 c-pointer"
          >
            <div
              className="border border-info p-1"
              style={{ width: "30px", height: "20px" }}
            ></div>
            <div
              className="border border-info p-1"
              style={{ width: "30px", height: "20px" }}
            ></div>
          </div>
        </div>

        <div>
          <div className="m-4 rounded">
            {data?.map((item, index) => {
              return renderSection(item, index);
            })}
          </div>

          <div>
            <button
              onClick={() => handleAddDataToBody({ type: "IMAGE" })}
              className="btn btn-info p-1 m-2"
              title="Add Image"
            >
              <i className="icon-picture text-white"></i>
            </button>
            <button
              onClick={() => handleAddDataToBody({ type: "LINK" })}
              className="btn btn-info p-1 m-2"
              title="Add Link"
            >
              <i className="icon-link text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
