import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create =
  ({ title, description }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    const body = JSON.stringify({
      title,
      description,
    });

    try {
      const res = await axios.post(`${apiURL}admin/create-term`, body, _config);
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };

export const retreive = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${apiURL}admin/term/${id}`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const edit =
  ({ title, description, id }) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }
    try {
      const res = await axios.post(`${apiURL}admin/edit-term`, {
        id,
        title,
        description,
      });
      console.log(res, " res");
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };

export const getAllTerms = (e) => async (dispatch) => {
  try {
    const res = await axios.get(`${apiURL}admin/terms`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const deleteTerm = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/delete-term/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
