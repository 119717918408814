import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import { deleteProduct, products } from "../../../actions/product";
import SelectSearchFilter from "../../../components/CustomeFilter/SelectSearchFilter";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import { getMarginOverride } from "../../../services/vendor/vendor.service";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { FcSynchronize } from "react-icons/fc";
import { addAdditionalPrice } from "../../../actions/vendorSetting";
import { getAllStores } from "../../../actions/store";
import { getTotalCubicUnits } from "../../../utils/Formula";

const Products = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const modelNumber = queryParams.get("modelNumber");
  const brand = queryParams.get("brand");
  const categoryId = queryParams.get("categoryId");
  const brandName = queryParams.get("brandName");
  const CategoryName = queryParams.get("CategoryName");
  const sku = queryParams.get("sku");

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(false);
  const [Data, setData] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [Categories, setCategories] = useState([]);
  const [Brand, setBrand] = useState([]);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });

  const [SearchFillter, setSearchFillter] = useState({
    title: "",
    modelNumber: modelNumber ?? "",
    sku: sku ?? "",
    price: "",
    filter: "",
    status: "",
    brand: brand ?? "",
    brandName: brandName ?? "",
    categoryId: categoryId ?? "",
    categoryName: CategoryName ?? "",
  });

  // Api calls for get Order
  const getProducts = useCallback(
    async (
      pageNo,
      title,
      modelNumber,
      sku,
      price,
      filter,
      status,
      brand,
      categoryId
    ) => {
      setloading(true);
      const res = await dispatch(
        products(
          pageNo,
          title,
          modelNumber,
          sku,
          price,
          filter,
          status,
          brand,
          categoryId
        )
      );
      if (res) {
        setData(res?.products);
        setpagination({
          ...res?.pagination,
        });
        setloading(false);
      }
    },
    [dispatch]
  );

  const fetchCategories = useCallback(
    async (id) => {
      // setLoading(true);
      const res = await getMarginOverride(id);
      if (res) {
        const newCat = res?.map((item) => {
          return {
            id: item?.categoryId,
            value: item?.categoryName,
            label: item?.categoryName,
          };
        });
        setCategories(newCat);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [SearchFillter.brand]
  );
  const fetchBrand = useCallback(
    async () => {
      const res = await dispatch(getAllStores());
      if (res) {
        const brand = res?.data?.map((item) => {
          return {
            id: item?._id,
            value: item?.fullName,
            label: item?.fullName,
          };
        });
        setBrand(brand);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data]
  );

  useEffect(() => {
    const { title, modelNumber, sku, price, filter, status, brand } =
      SearchFillter;
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getProducts(
          pagination.currentPage,
          title,
          modelNumber,
          sku,
          price,
          filter,
          status,
          brand,
          SearchFillter.categoryId
          // categoryId
        );
        fetchBrand();
        brand && fetchCategories(brand);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    SearchFillter.title,
    SearchFillter.modelNumber,
    SearchFillter.sku,
    SearchFillter.price,
    SearchFillter.filter,
    SearchFillter.status,
    SearchFillter.categoryId,
    isMount,
    getProducts,
    SearchFillter,
    brand,
    categoryId,
  ]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({
        // brand: brand,
        // categoryId: categoryId,
        // CategoryName: CategoryName,
        // brandName: brandName,
        page: value,
      })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };
  //Delete Product
  const deleteProductByID = async (id) => {
    setloading(true);
    const res = await dispatch(deleteProduct(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };

  // add addtional price
  const handleAdditionalPrice = (e, index) => {
    const tempArr = [...Data];
    tempArr[index].additionalPrice = Number(e.target.value);
    setData(tempArr);
  };

  const handleSubmitAddPrice = async (id, index) => {
    try {
      const res = await dispatch(
        addAdditionalPrice({
          id,
          additionalPrice: Data[index].additionalPrice,
        })
      );
      if (res) {
        setisMount(!isMount);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // calculate profit
  const profitCalculation = (item) => {
    const {
      dropshipFeeOne,
      dropshipFeeTwo,
      shippingCost,
      onSale,
      promoCost,
      cost,
      algoPrice,
      additionalPrice,
    } = item;

    let addPrice = additionalPrice ? additionalPrice : 0;
    let dsFee = dropshipFeeOne
      ? dropshipFeeOne
      : 0 + dropshipFeeTwo
      ? dropshipFeeTwo
      : 0;
    let price = onSale ? promoCost : cost;
    let totalCost = dsFee + shippingCost + price;
    let sellingPrice = algoPrice + addPrice;
    let profit = sellingPrice - totalCost;

    return {
      profit: profit.toFixed(2),
      profitPercentage: ((profit / sellingPrice) * 100).toFixed(2),
    };
  };

  return (
    <>
      <div
        className=" d-flex  justify-content-end align-center flex-wrap"
        style={{ gap: "20px" }}
      >
        <SearchFilter
          type="text"
          placeholder={"Search Product Name"}
          value={SearchFillter?.title}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              title: e.target.value,
            })
          }
        />
        <SearchFilter
          type="text"
          placeholder={"Search Model Number"}
          value={SearchFillter?.modelNumber}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              modelNumber: e.target.value,
            })
          }
        />
        <SearchFilter
          type="text"
          placeholder={"Search SKU"}
          value={SearchFillter?.sku}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              sku: e.target.value,
            })
          }
        />
        <SelectSearchFilter
          title={"Brand"}
          placeholder="Select"
          width={"200px"}
          value={SearchFillter?.brandName}
          options={Brand}
          onChange={(value) => {
            setSearchFillter({
              ...SearchFillter,
              brand: Brand.find((item) => item.value === value).id,
              brandName: Brand.find((item) => item.value === value).value,
              categoryName: "",
              categoryId: "",
            });
          }}
        />
        <SelectSearchFilter
          title={"Category"}
          placeholder="Select"
          width={"200px"}
          value={SearchFillter?.categoryName}
          options={Categories}
          onChange={(value) => {
            setSearchFillter({
              ...SearchFillter,
              categoryId: Categories.find((item) => item.value === value).id,
              categoryName: Categories.find((item) => item.value === value)
                .value,
            });
          }}
        />{" "}
        <SelectSearchFilter
          title={"Over Ride Etl"}
          placeholder="Select"
          options={[
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, overrideEtl: value })
          }
        />
        <SelectSearchFilter
          title={"Status"}
          placeholder="Select"
          options={[
            { value: "true", label: "Enable" },
            { value: "false", label: "Disable" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, status: value })
          }
        />{" "}
        <SelectSearchFilter
          title={"Sort By"}
          placeholder="Select"
          width={"150px"}
          options={[
            { value: "Has Additional Price", label: "Has Additional Price" },
            { value: "No Additional Price", label: "No Additional Price" },
            { value: "On Promo", label: "On Promo" },
          ]}
          onChange={(value) =>
            setSearchFillter({ ...SearchFillter, filter: value })
          }
        />
      </div>
      <div className=" my-5">
        <DashboardPagesLayout
          pageTitle={`${
            SearchFillter.brandName
              ? `${SearchFillter.brandName}  | Edit SKU`
              : "Products"
          }`}
          subTitle={`${brand ? "items" : "items"}`}
          count={pagination?.count}
        >
          <div className="dashboard-pages-table">
            <table>
              <thead>
                <tr>
                  <th>Sn#</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>sku</th>
                  <th>Inventory</th>
                  <th>Category</th>
                  <th>Promo Cost</th>
                  <th>Cost</th>
                  <th>Shipping</th>
                  <th>Total Cube</th>
                  <th>Box ID</th>
                  <th>DS Fees</th>
                  <th>Profit</th>
                  <th>ALgo Price</th>
                  <th>Adjust Price</th>
                  <th>Key Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={17} />}
                {!loading &&
                  Data?.map((item, i) => (
                    <tr key={item?._id}>
                      <td className="order-item-number">{i + 1}</td>
                      <td>{item.title}</td>

                      <td>
                        <img
                          className="border-grey border-radius-10"
                          src={item.mainImage}
                          height="50px"
                          width="50px"
                          alt="item"
                        />
                      </td>
                      <td>
                        <a
                          href={
                            process.env.REACT_APP_ENVIROMENT === "DEV"
                              ? `https://qasundial.sundialhome.com/product/${item?.slug}`
                              : `https://www.sundialhome.com/product/${item?.slug}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.sku}
                        </a>
                      </td>
                      <td>{item?.quantity}</td>
                      <td>
                        {item?.category?.length ? item?.category[0]?.name : ""}
                      </td>
                      <td>
                        {item?.onSale ? (
                          <span className={item?.onSale ? "text-success" : ""}>
                            Promo
                          </span>
                        ) : (
                          <></>
                        )}
                        <br />
                        {item?.promoCostStartDate
                          ? moment(item?.promoCostStartDate).format("l")
                          : ""}
                        <br />
                        {item?.promoCostEndDate
                          ? moment(item?.promoCostEndDate).format("l")
                          : ""}
                      </td>
                      <td className={item?.onSale ? "text-success" : ""}>
                        ${item?.onSale ? item?.promoCost : item?.cost}
                      </td>
                      <td>
                        ${item?.shippingCost}
                        {/* <br />
                        {item?.shippingCost} */}
                      </td>
                      <td>{getTotalCubicUnits(item?.shipping?.dimensions)}</td>
                      <td>
                        {item?.shipping?.boxId.split("-").map((segment, i) => (
                          <span key={i} className=" d-block">
                            {segment}
                          </span>
                        ))}
                      </td>
                      <td>
                        $
                        {item?.dropshipFeeOne
                          ? item?.dropshipFeeOne
                          : 0 + item?.dropshipFeeTwo
                          ? item?.dropshipFeeTwo
                          : 0}
                      </td>
                      <td>
                        ${profitCalculation(item)?.profit}
                        <br />
                        {profitCalculation(item)?.profitPercentage}%
                      </td>
                      <td>
                        {item?.algoPrice +
                          Number(
                            item?.additionalPrice ? item?.additionalPrice : 0
                          )}
                      </td>
                      <td>
                        <input
                          className=" border-radius-10 p-3 border-grey w-100"
                          type="number"
                          required
                          value={
                            item?.additionalPrice ? item?.additionalPrice : 0
                          }
                          placeholder="Additional Price"
                          onChange={(e) => handleAdditionalPrice(e, i)}
                        />
                      </td>

                      <td>${`${Number(item?.salePrice)}`}</td>
                      <td>
                        <div className="d-flex justify-content-between align-center gap-5">
                          <CustomeTableBtn
                            color="success"
                            width={"10px"}
                            className={"p-2"}
                            onClick={() => handleSubmitAddPrice(item?._id, i)}
                          >
                            <FcSynchronize className="text-success" />
                          </CustomeTableBtn>
                          <CustomeTableBtn
                            color="secondary"
                            width={"10px"}
                            className={"p-2"}
                            onClick={() =>
                              navigate(`/admin/edit-product/?id=${item._id}`)
                            }
                          >
                            <MdModeEditOutline />
                          </CustomeTableBtn>

                          <CustomeTableBtn
                            color="danger"
                            width={"10px"}
                            className={"p-2"}
                            onClick={() =>
                              setdeleteConfirm({
                                open: true,
                                id: item?._id,
                              })
                            }
                          >
                            <MdDeleteOutline />
                          </CustomeTableBtn>
                        </div>

                        {deleteConfirm.open && (
                          <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes, delete it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                            onConfirm={(e) => {
                              deleteProductByID(deleteConfirm.id);
                            }}
                            onCancel={() =>
                              setdeleteConfirm({
                                open: false,
                                id: null,
                              })
                            }
                            focusCancelBtn
                          >
                            Are you sure you want to delete?
                          </SweetAlert>
                        )}
                      </td>
                    </tr>
                  ))}

                {!loading && !Data?.length && (
                  <tr>
                    <td colSpan={17} className=" text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span className=" font-weight-bold" style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
          </div>
        </DashboardPagesLayout>
      </div>
    </>
  );
};

export default Products;
