import React, { useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";

const SelectMultipleFilter = ({
  onChange,
  placeholder,
  name,
  className,
  value,
  title,
  options,
  width,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setdata] = useState(options ?? []);
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value ?? []);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (value) => {
    const isSelected = selectedItem.includes(value);
    if (isSelected) {
      setSelectedItem(selectedItem.filter((item) => item !== value));
      onChange(selectedItem.filter((item) => item !== value));
    } else {
      setSelectedItem([...selectedItem, value]);
      onChange([...selectedItem, value]);
    }
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ minWidth: width }} className="select-dropdown ">
          <div className="select-dropdown-header" onClick={toggleDropdown}>
            <div>
              <p className=" font-weight-bold">{title}</p>
              <p className="select-value">All</p>
            </div>

            <RiArrowDownSFill
              className={`${isOpen ? "icon" : ""}`}
              style={{ fontSize: "25px" }}
            />
          </div>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`dropdown-body  ${isOpen && "open"}`}
          >
            {data.map((item, i) => (
              <div
                key={i}
                value={item.value}
                style={
                  selectedItem.includes(item.value)
                    ? { background: "#c0fa66" }
                    : {}
                }
                className={`select-dropdown-item`}
                onClick={() => handleItemClick(item.value)}
                id={item.value}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectMultipleFilter;
