import React from "react";
import style from "./rating-card-sidebar.module.css";
import RatingStars from "../rating-stars/rating-stars";
import RatingDetails from "../rating-details/rating-details";

const RatingCardSidebar = ({ filterReviewData }) => {
  const formatData = (stringDate) => {
    const data = new Date(stringDate);
    const options = { year: "numeric" , month: "2-digit" , day: "2-digit" };
    return  data.toLocaleDateString("en-US", options);
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.priceTitleBox}>
        <h3 className={style.priceTitleBoxHeading}>Rating</h3>
        <RatingStars totalStars={filterReviewData?.review} />
      </div>

      <RatingDetails ratingType={"Rating Details"} ratingValue={""} />
      <RatingDetails
        ratingType={"Review Date:"}
        ratingValue={formatData(filterReviewData?.reviewDate)}
      />
      <RatingDetails
        ratingType={"Rating:"}
        ratingValue={`${filterReviewData?.review} Star`}
      />
      <RatingDetails
        ratingType={"Customer Feedback:"}
        ratingValue={`${filterReviewData?.feedback ?? ""}`}
      />
    </div>
  );
};

export default RatingCardSidebar;
