import Dialog from "@mui/material/Dialog";
import styles from "./tagName.module.css";
import { useState } from "react";

const TagName = ({ text, onDelete, onUpdate, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editValue, setEditValue] = useState("");

  const onUpdateValue = () => {
    setIsOpen(true);
    setEditValue(text);
  };

  const updateTagName = () => {
    onUpdate(editValue, id);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.tagNameContainer}>
        <p>{text}</p>
        <div>
          <button className={styles.tagNameButton} onClick={onUpdateValue}>
            Update
          </button>
          <button className={styles.tagNameButton} onClick={() => onDelete(id)}>
            Delete
          </button>
        </div>
      </div>
      <Dialog
        className={styles.dialogBox}
        fullWidth={true}
        open={isOpen}
        onClose={handleClose}
      >
        <h3 className={styles.dialogBoxTitle}>Update Blog Tag Name</h3>
        <input
          type="text"
          value={editValue}
          name="blogTag"
          placeholder="Please Update Value"
          className={styles.dialogBoxInput}
          onChange={(e) => setEditValue(e.target.value)}
        />
        <button className={styles.updateButton} onClick={updateTagName}>
          Update Value
        </button>
      </Dialog>
    </>
  );
};

export default TagName;
