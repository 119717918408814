/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiURL } from "../../../config/default";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import styles from "./layouts.module.css";
import { ColorPicker } from "material-ui-color";
import { CircularProgress } from "@mui/material";
const Layouts = () => {
  const [loading, setLoading] = React.useState(true);
  const [topBarSetting, setTopBarSetting] = useState({
    isTopBarEditable: false,
  });

  const [topBar, setTopBar] = useState({
    isTopBarBackgroundPrimary: true,
    text: "Home Improvement Projects, Done Your Way | Shop Updates",
    backgroundColor: "red",
    color: "#fff",
  });

  const [shipping, setShipping] = useState({
    leadTime: 3,
    minSmallParcelTransit: 2,
    maxSmallParcelTransit: 4,
    minLtlTransit: 3,
    maxLtlTransit: 7,
  });
  const [returns, setReturns] = useState({
    brReturn: true,
    brclaimCutoff: 7,
    brRestockFee: 30,
    globalClaimCutoff: 7,
  });
  const [layout, setLayout] = React.useState({
    topBar: {
      isTopBarBackgroundPrimary: true,
      text: "Home Improvement Projects, Done Your Way | Shop Updates",
      backgroundColor: "red",
      color: "#fff",
      fontSize: 16,
    },
    shipping: {
      leadTime: 3,
      minSmallParcelTransit: 2,
      maxSmallParcelTransit: 4,
      minLtlTransit: 3,
      maxLtlTransit: 7,
    },
    returns: {
      brReturn: true,
      brclaimCutoff: 7,
      brRestockFee: 30,
      globalClaimCutoff: 7,
    },
    name: "listing page",
    layouts: [
      {
        name: "withSidebar",
        isActive: false,
      },
      {
        name: "withoutSidebar",
        isActive: true,
      },
    ],
    target: "_self",
    setting: {
      primaryColor: "#17a2b8",
      primaryHoverColor: "#2fb9cc",
      secondaryColor: "#ebba00",
      secondaryHoverColor: "#f5d150",
    },
    discountPercentage: 0,
    creditCardFee: 0,
    paymentMethod: {
      paypal: false
    }
  });
  const [showPaypal, setShowPaypal] = useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiURL}admin/layout`);
        setLayout({ ...res.data[0] });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // topbar
  const handleTopBarBackgroundColor = () => {
    setLayout({
      ...layout,

      topBar: {
        ...layout.topBar,
        isTopBarBackgroundPrimary: !layout.topBar.isTopBarBackgroundPrimary,
      },
    });
  };

  useEffect(() => {
    if (layout.topBar.isTopBarBackgroundPrimary) {
      setLayout({
        ...layout,
        topBar: {
          ...layout.topBar,
          backgroundColor: layout.setting.primaryColor,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.topBar.isTopBarBackgroundPrimary]);

  const handleTopBarBackgroundColorChange = (e) => {
    setLayout({
      ...layout,
      topBar: { ...layout.topBar, backgroundColor: e.target.value },
    });
  };

  const handleToggleTopBarEdit = () => {
    setTopBarSetting({
      isTopBarEditable: !topBarSetting.isTopBarEditable,
    });
  };

  const handleTopbarTextChange = (e) => {
    setLayout({
      ...layout,
      topBar: { ...layout.topBar, text: e.target.value },
    });
  };
  const handleShippingChange = (e) => {
    const { name, value } = e.target;

    setLayout({
      ...layout,
      shippingAndReturns: {
        ...layout.shippingAndReturns,
        [name]: value,
      },
    });
  };

  // end topbar

  const handleChange = async (e) => {
    const updatedLayout = {
      ...layout,
      layouts: layout.layouts.map((layout) => ({
        ...layout,
        isActive: e.target.value === layout.name,
        
      })),
    };
    
    setLayout(updatedLayout);
    await handleUpdate(updatedLayout);
  };
  
  const handlePaymentChange = (value) => {
    

    setLayout({
      ...layout,
      paymentMethod: {
        ...layout.paymentMethod,
        paypal: value,
      },
    });
  };

  const handleUpdate = async (_layout) => {
    setLoading(true);
    try {
      await axios.post(`${apiURL}admin/layout`, _layout);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const changeColor = (color, prop) => {
    let isObj = typeof color === "object";

    if (layout.topBar.isTopBarBackgroundPrimary) {
      setLayout({
        ...layout,
        topBar: {
          ...layout.topBar,
          backgroundColor: color?.css?.backgroundColor,
        },
        setting: {
          ...layout.setting,
          [prop]: color?.css?.backgroundColor,
        },
      });
    } else {
      setLayout({
        ...layout,
        setting: {
          ...layout.setting,
          [prop]: color?.css?.backgroundColor,
        },
      });
    }
  };

  const publish = () => {
    handleUpdate(layout);
  };

  const handleDiscountPercentage = (e) => {
    const { value, name } = e.target;

    if (value >= 0 && value <= 20) {
      const _layout = {
        ...layout,
        [name]: value,
      };

      setLayout(_layout);
    }
  };

  const handleChangeColor = (e) => {
    const { name, value } = e.target;
    if (value[0] !== "#") alert("Please Enter Hex Color Code only");
    else {
      setLayout({
        ...layout,
        setting: {
          ...layout.setting,
          [name]: value,
        },
      });
    }
  };
  const handleTopBarFontSize = (e) => {
    if (e.target.value < 16 || e.target.value > 22) return;
    setLayout({
      ...layout,
      topBar: {
        ...layout.topBar,
        fontSize: e.target.value,
      },
    });
  };

  console.log("sss", layout);
  return (
    <div className="container-scroller">
      {loading ? (
        <div className="text-center mt-5 mb-5">
          <CircularProgress size={40} />
        </div>
      ) : (
        <>
          <div className="page-header">
            <div className="d-flex align-items-center justify-content-between w-50">
              <h3 className="page-title" style={{ fontSize: 30 }}>
                Set Layouts
              </h3>
              <div className="text-right my-2">
                <button
                  disabled={loading}
                  onClick={publish}
                  className="btn btn-primary"
                >
                  Publish
                </button>
              </div>
            </div>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Settings</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  layouts
                </li>
              </ol>
            </nav>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body table-responsive">
                  <h4 className="card-title">TopBar</h4>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="m-1 border p-1 d-flex align-items-center">
                        <button
                          className="c-pointer bg-transparent border-0"
                          type="button"
                          onClick={handleToggleTopBarEdit}
                        >
                          <div
                            style={{
                              border: topBarSetting.isTopBarEditable
                                ? "1px solid var(--gray-dark)"
                                : "none",
                            }}
                          >
                            <i className="icon icon-pencil"></i>
                          </div>
                        </button>
                        <div className="ml-3 d-flex">
                          Background-color: &nbsp;
                          <div
                            className="d-flex align-items-center"
                            style={{
                              opacity: layout?.topBar?.isTopBarBackgroundPrimary
                                ? 1
                                : 0.7,
                            }}
                          >
                            <label
                              className="ml-2 mb-1 c-pointer"
                              htmlFor="isPrimareyColorChecked"
                            >
                              Primary
                            </label>
                            <input
                              type="checkbox"
                              className="ml-2"
                              defaultChecked={
                                layout?.topBar?.isTopBarBackgroundPrimary
                              }
                              onChange={handleTopBarBackgroundColor}
                              id="isPrimareyColorChecked"
                            />
                          </div>
                          {!layout?.topBar?.isTopBarBackgroundPrimary && (
                            <div className="d-flex align-items-center">
                              <label className="mb-0 ml-3">
                                Select Background color:
                                <input
                                  onChange={handleTopBarBackgroundColorChange}
                                  value={layout?.topBar?.backgroundColor}
                                  type="color"
                                />
                              </label>
                            </div>
                          )}
                          <div className="ml-5">
                            <input
                              type="number"
                              name="topBarfontSize"
                              style={{ width: "40px" }}
                              value={layout?.topBar?.fontSize ?? "16"}
                              id="topBarfontSize"
                              onChange={handleTopBarFontSize}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottomRightRadius: 22,
                          backgroundColor: layout?.topBar
                            ?.isTopBarBackgroundPrimary
                            ? layout?.setting?.primaryColor ??
                              layout?.topBar?.backgroundColor
                            : layout?.topBar?.backgroundColor,
                          color: layout?.topBar?.color,
                          fontSize: `${layout?.topBar?.fontSize}px` ?? "16px",
                        }}
                        className="p-2"
                      >
                        {topBarSetting.isTopBarEditable ? (
                          <input
                            type="Text"
                            onChange={handleTopbarTextChange}
                            value={layout?.topBar?.text}
                            autoFocus
                            style={{ color: topBar?.color }}
                            className="border-top-0 border-bottom border-light w-100 bg-transparent"
                          />
                        ) : (
                          layout?.topBar?.text
                        )}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body table-responsive">
                  <h4 className="card-title">Pages</h4>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> # </th>
                        <th> Name </th>
                        <th>View Style Settings</th>
                        <th>Open Link Settings</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> 1 </td>
                        <td>Listing Page</td>

                        <td>
                          <select
                            name="layout"
                            value={
                              layout?.layouts?.find(
                                (layout) => layout?.isActive
                              )?.name
                            }
                            onChange={handleChange}
                          >
                            <option value="withSidebar">With Sidebar</option>
                            <option value="withoutSidebar">
                              without Sidebar
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            name="target"
                            value={layout.target}
                            onChange={async (e) => {
                              const _layout = {
                                ...layout,
                                target: e.target.value,
                              };

                              setLayout(_layout);
                              await handleUpdate(_layout);
                            }}
                          >
                            <option value="_blank">Open in new tab</option>
                            <option value="_self">Open in current tab</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <h4 className="card-title ml-3">Pricing</h4>
            <div className="col-md-12">
              <div className="card px-2 bg-white">
                <div className="row mb-5">
                  <div className="col-md-2 mt-4">
                    <h3 className="page-title">Set Discount</h3>
                    <div>
                      <input
                        className="form-control"
                        value={layout.discountPercentage}
                        type="number"
                        name="discountPercentage"
                        onChange={(e) => handleDiscountPercentage(e)}
                        placeholder="Enter value from 0-20"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <h3 className="page-title">Set Credit Card Fee</h3>
                    <input
                      className="form-control"
                      value={layout.creditCardFee}
                      type="number"
                      name="creditCardFee"
                      onChange={(e) => handleDiscountPercentage(e)}
                      placeholder="Enter value from 0-20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <h3 className="page-title">Set Colors</h3>
              <hr className="my-3" />
            </div>
          </div>

          {/* primary */}

          <div className="row">
            <div className="col-md-6">
              <div className="card-body bg-white">
                <div
                  className={`d-flex justify-content-center ${styles.colorPickerBtn}`}
                >
                  <div className={styles.primaryColorSelection}>
                    <h3>Primary Color</h3>
                    <input
                      onChange={handleChangeColor}
                      value={layout?.setting?.primaryColor ?? ""}
                      type="color"
                      name="primaryColor"
                      id="primaryColor"
                    />
                    <input
                      onChange={handleChangeColor}
                      value={layout?.setting?.primaryColor ?? ""}
                      type="text"
                      name="primaryColor"
                      id="primaryColor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-body bg-white">
                <div
                  className={`d-flex justify-content-center ${styles.colorPickerBtn}`}
                >
                  <div className={styles.primaryColorSelection}>
                    <h3>Primary Hover Color</h3>
                    <ColorPicker
                      name="primaryHoverColor"
                      onChange={(color) =>
                        changeColor(color, "primaryHoverColor")
                      }
                      value={layout?.setting?.primaryHoverColor || " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* secondary */}
          <div className="row">
            <div className="col-md-6">
              <div className="card-body bg-white">
                <div
                  className={`d-flex justify-content-center ${styles.colorPickerBtn}`}
                >
                  <div className={styles.primaryColorSelection}>
                    <h3>Secondary Color</h3>
                    <ColorPicker
                      name="secondaryColor"
                      onChange={(color) => changeColor(color, "secondaryColor")}
                      value={layout?.setting?.secondaryColor || " "}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-body bg-white">
                <div
                  className={`d-flex justify-content-center ${styles.colorPickerBtn}`}
                >
                  <div className={styles.primaryColorSelection}>
                    <h3>Secondary Hover Color</h3>
                    <ColorPicker
                      name="secondaryHoverColor"
                      onChange={(color) =>
                        changeColor(color, "secondaryHoverColor")
                      }
                      value={layout?.setting?.secondaryHoverColor || " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row bg-white border-radius-8 mt-3 p-3">
            <div className=" d-flex justify-content-between align-center w-100 mb-3">
              <h4 className="card-title ml-3">Estimated Delivery Times</h4>
              <button
                disabled={loading}
                onClick={publish}
                className="btn  btn-success"
              >
                Update
              </button>
            </div>
            <div className="col-md-12">
              <div className="card px-2 ">
                <div className="row mb-2">
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="load-time"
                        >
                          Lead Time Small Parcel
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="load-time"
                        name="leadTime"
                        value={layout?.shippingAndReturns?.leadTimeSmall}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="min-parcel"
                        >
                          Min Ship Time Small Parcel
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="min-parcel"
                        name="minShipTimeSmall"
                        value={layout?.shippingAndReturns?.minShipTimeSmall}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-parcel"
                        >
                          Max Ship Time Small Parcel
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-parcel"
                        name="maxShipTimeSmall"
                        value={layout?.shippingAndReturns?.maxShipTimeSmall}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="min-ltl"
                        >
                          Lead Time LTL
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="min-ltl"
                        name="leadTimeLTL"
                        value={layout?.shippingAndReturns?.leadTimeLTL}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-ltl"
                        >
                          Min Ship Time LTL
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-ltl"
                        name="minShipTimeLTL"
                        value={layout?.shippingAndReturns?.minShipTimeLTL}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-ltl"
                        >
                          Max Ship Time LTL
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-ltl"
                        name="maxShipTimeLTL"
                        value={layout?.shippingAndReturns?.maxShipTimeLTL}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-white border-radius-8 mt-3 p-3">
            <div className=" d-flex justify-content-between align-center w-100 mb-3">
              <h4 className="card-title ml-3">Returns Policy</h4>
              <button
                disabled={loading}
                onClick={publish}
                className="btn  btn-success"
              >
                Update
              </button>
            </div>
            <div className="col-md-12">
              <div className="card px-2 ">
                <div className="row mb-2">
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="load-time"
                        >
                          Damage/Defective Return Policy
                        </span>
                      </div>
                      <textarea
                        type="text"
                        className={`form-control ${styles.backgroundGray}`}
                        name="defectivePolicy"
                        value={layout?.shippingAndReturns?.defectivePolicy}
                        onChange={(e) => handleShippingChange(e)}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="min-parcel"
                        >
                          Damage/Defective Claims Period
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        name="defectiveClaimsCutoff"
                        value={
                          layout?.shippingAndReturns?.defectiveClaimsCutoff
                        }
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-parcel"
                        >
                          Damage/Defective Fee
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-parcel"
                        name="defectiveRestockFee"
                        value={layout?.shippingAndReturns?.defectiveRestockFee}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="min-ltl"
                        >
                          Buyers Remorse Return Policy
                        </span>
                      </div>
                      <textarea
                        type="text"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="min-ltl"
                        name="returnPolicy"
                        value={layout?.shippingAndReturns?.returnPolicy}
                        onChange={(e) => handleShippingChange(e)}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-ltl"
                        >
                          Buyers Remorse Claims Period
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-ltl"
                        name="brClaimsCutoff"
                        value={layout?.shippingAndReturns?.brClaimsCutoff}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-ltl"
                        >
                          Buyers Remorse Fee
                        </span>
                      </div>
                      <input
                        type="number"
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-ltl"
                        name="brRestockFee"
                        value={layout?.shippingAndReturns?.brRestockFee}
                        onChange={(e) => handleShippingChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="max-ltl"
                        >
                          Cancellation Policy
                        </span>
                      </div>
                      <textarea
                        className={`form-control ${styles.backgroundGray}`}
                        aria-describedby="max-ltl"
                        name="cancellationPolicy"
                        value={layout?.shippingAndReturns?.cancellationPolicy}
                        onChange={(e) => handleShippingChange(e)}
                        rows={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row bg-white border-radius-8 mt-3 p-3">
            <div className=" d-flex justify-content-between align-center w-100 mb-3">
              <h4 className="card-title ml-3">Payment Methods</h4>
              <button
                disabled={loading}
                onClick={publish}
                className="btn  btn-success"
              >
                Update
              </button>
            </div>
            <div className="col-md-12">
              <div className="card px-2 ">
                <div className="row mb-2">
                  <div className="col-md-12 w-100">
                    <div className="input-group  mb-2">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text ${styles.inputGroupTextHeading}`}
                          id="load-time"
                        >
                          Paypal
                        </span>
                      </div>
                      <div className="status-input-container ml-5" style={{ flexDirection: 'row' }}>
        <div
          onClick={(e) =>
            handlePaymentChange(true)
          }
          className={
            layout.paymentMethod.paypal
              ? "status-radio-select c-pointer"
              : "status-radio-button  c-pointer"
          }
        >
          <input
            type="radio"
            name="Available"
            value="Available"
            checked={layout.paymentMethod.paypal}
            onChange={(e) =>
              handlePaymentChange(true)
            }
          />{" "}
          <span>Show</span>
        </div>
        <div
          onClick={(e) =>
            handlePaymentChange(false)
          }
          className={
            !layout.paymentMethod.paypal
              ? "status-radio-select c-pointer"
              : "status-radio-button c-pointer"
          }
        >
          <input
            type="radio"
            name="Not Available"
            value="Not Available"
            checked={!layout.paymentMethod.paypal}
            onChange={(e) =>
              handlePaymentChange(false)
            }
          />{" "}
          <span>Hide</span>
        </div>
         
      </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Layouts;
