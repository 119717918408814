import React, { Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import { getTextEditorState } from "../../actions/textEditor";
import "../TextEditor/TextEditor.style.css";
import PropTypes from "prop-types";
import { ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

class EditorConvertUpdateToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  async componentDidMount() {
    const blocksFromHtml = htmlToDraft(this.props.htmlValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
    });
  }

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}
getTextEditorState.propTypes = {
  getTextEditorState: PropTypes.func.isRequired,
};

export default connect(null, {
  getTextEditorState,
})(EditorConvertUpdateToHTML);
