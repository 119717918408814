import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";
import UpdateButton from "./UpdateButton";
import { setVendorInventory } from "../../../../services/vendor/vendor.service";

const ManageVendorInventory = ({
  selectedItem,
  isRender,
  setIsRender,
  setSelectedItem,
}) => {
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await setVendorInventory({
        id,
        exportInFile: selectedItem?.exportInFile,
      });
      if (res?.data?.message) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="  d-flex flex-column gap-10">
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Vendor Status: {selectedItem?.exportInFile ? "On" : "Off"}</span>
        <span>
          <ToggleSwitch
            checked={selectedItem?.exportInFile}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                exportInFile: e.target.checked,
              });
            }}
            Name={"exportInFile"}
          />
        </span>
      </div>
      <hr />
      <UpdateButton
        position="fixed"
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManageVendorInventory;
