import React, { useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer } from "react-toastify";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { apiURL } from "../../../config/default";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import ListItemText from "@mui/material/ListItemText";
import "./filters.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  textColorPrimary: {
    color: "black",
    fontWeight: "bold",
  },
  colTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45px",
    backgroundColor: "#ecf0f4",
  },
}));

function CategoriesMain(props) {
  const navigate = useNavigate();
  useEffect(() => {
    const start = async () => await getData();
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const values = JSON.parse(localStorage.getItem("menuIndex")) ?? [];
    let mainCategory = [];
    let firstLevel = [];
    let secondLevel = [];
    let thirdLevel = [];
    try {
      mainCategory = await axios.get(`${apiURL}admin/main-categories`);
      firstLevel = await getFirstLevelCategory(
        mainCategory.data[values[0] ?? 0]._id
      );
      secondLevel = await getSecondLevelCategory(
        firstLevel[values[1] ?? 0]._id
      );
      thirdLevel = await getThirdLevelCategory(
        secondLevel.data[values[2] ?? 0]._id
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingChild(false);
      setLoadingSubChild(false);
      setLoadingSubChildCat(false);
    }
    setValue(values[0] ?? 0);
    setChildValue(values[1] ?? 0);
    setSubChildValue(values[2] ?? 0);
    setThirdLevelCategories(thirdLevel);
    setSecondLevelCategories(secondLevel.data);
    setFirstLevelCategories(firstLevel);
    setCategories(mainCategory.data);
    setselectedId(firstLevel[0]._id);

    localStorage.setItem(
      "selectedFilters",
      JSON.stringify([
        mainCategory.data[values[0] ?? 0]?.name,
        firstLevel[values[1] ?? 0]?.name,
        secondLevel.data[values[2] ?? 0]?.name,
        null,
      ])
    );
  };

  const [loading, setLoading] = useState(true);
  const [loadingChild, setLoadingChild] = useState(true);
  const [loadingSubChild, setLoadingSubChild] = useState(true);
  const [loadingSubChildCat, setLoadingSubChildCat] = useState(true);
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [value, setValue] = React.useState(0);
  const [childValue, setChildValue] = React.useState(0);
  const [subChildValue, setSubChildValue] = React.useState(0);
  const [firstLevelCategories, setFirstLevelCategories] = React.useState([]);
  const [secondLevelCategories, setSecondLevelCategories] = React.useState([]);
  const [thirdLevelCategories, setThirdLevelCategories] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setselectedId] = useState(null);

  const handleChangeParent = async (event, newValue) => {
    let secondlevelCategories = [];
    const values = JSON.parse(localStorage.getItem("menuIndex")) ?? [];
    values[0] = newValue;
    localStorage.setItem("menuIndex", JSON.stringify([values[0]]));
    setLoadingChild(true);
    setLoadingSubChild(true);
    setLoadingSubChildCat(true);
    setValue(newValue);
    const firstlevelCategories = await getFirstLevelCategory(
      categories[newValue]._id
    );
    if (firstlevelCategories.length > 0) {
      secondlevelCategories = await getSecondLevelCategory(
        firstlevelCategories[0]._id
      );
      let thirdLevel = [];
      if (secondlevelCategories?.data[0]?._id)
        thirdLevel = await getThirdLevelCategory(
          secondlevelCategories.data[0]._id
        );
      setThirdLevelCategories(thirdLevel);
      setSecondLevelCategories(secondlevelCategories.data);
    } else {
      setSecondLevelCategories([]);
    }

    setFirstLevelCategories(firstlevelCategories);
    setLoadingChild(false);
    setLoadingSubChild(false);
    setLoadingSubChildCat(false);
    setChildValue(0);
    setSubChildValue(0);
    const selectedFilters =
      JSON.parse(localStorage.getItem("selectedFilters")) ?? [];

    selectedFilters[0] = categories?.[newValue]?.name;
    selectedFilters[1] = firstlevelCategories?.[0]?.name;
    selectedFilters[2] = secondlevelCategories.data?.[0]?.name;
    selectedFilters[3] = null;

    localStorage.setItem(
      "selectedFilters",
      JSON.stringify([...selectedFilters])
    );
  };

  const handleChangeChild = async (event, newValue) => {
    const values = JSON.parse(localStorage.getItem("menuIndex")) ?? [];
    values[1] = newValue;
    localStorage.setItem("menuIndex", JSON.stringify([values[0], values[1]]));
    setLoadingSubChildCat(true);
    setLoadingSubChild(true);
    setChildValue(newValue);
    const secondLevelCategories = await getSecondLevelCategory(
      firstLevelCategories[newValue]._id
    );
    let thirdLevel = [];
    if (secondLevelCategories?.data[0]?._id)
      thirdLevel = await getThirdLevelCategory(
        secondLevelCategories.data[0]._id
      );
    setThirdLevelCategories(thirdLevel);
    setSecondLevelCategories(secondLevelCategories.data);
    setLoadingSubChild(false);
    setLoadingSubChildCat(false);
    setSubChildValue(0);
    const selectedFilters =
      JSON.parse(localStorage.getItem("selectedFilters")) ?? [];

    selectedFilters[1] = firstLevelCategories?.[newValue].name;
    selectedFilters[2] = secondLevelCategories?.data?.[0]?.name;
    selectedFilters[3] = null;

    localStorage.setItem(
      "selectedFilters",
      JSON.stringify([...selectedFilters])
    );
  };

  const getFirstLevelCategory = async (id) => {
    const { data: firstLevelCategory } = await axios.get(
      `${apiURL}admin/get-categories/${id}`
    );
    setLoading(false);

    return firstLevelCategory;
  };

  const getSecondLevelCategory = async (id) => {
    const data = await axios.get(`${apiURL}get-subcategory-by-parent/${id}`);
    setselectedId(id);
    setLoading(false);
    return data;
  };

  const handleChangeSubChild = async (e, newValue) => {
    const values = JSON.parse(localStorage.getItem("menuIndex")) ?? [];
    values[2] = newValue;
    localStorage.setItem("menuIndex", JSON.stringify([...values]));
    setLoadingSubChildCat(true);
    const id = secondLevelCategories[newValue]._id;
    setSubChildValue(newValue);
    const res = await getThirdLevelCategory(id);
    setLoadingSubChildCat(false);
    const selectedFilters =
      JSON.parse(localStorage.getItem("selectedFilters")) ?? [];
    selectedFilters[2] = secondLevelCategories[newValue].name;

    localStorage.setItem(
      "selectedFilters",
      JSON.stringify([...selectedFilters])
    );
    if (res.length === 0) return navigate(`/admin/create-filter/?id=${id}`);
    setThirdLevelCategories(res);
  };

  const getThirdLevelCategory = async (id) => {
    const data = await axios.get(`${apiURL}get-child-category/${id}`);
    setLoading(false);
    return data.data;
  };

  return (
    <>
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Select Filters
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Categories
              </li>
            </ol>
          </nav>
        </div>
        <div className={classes.root}>
          <div className="container p-2">
            <div className="row">
              <div className="col-md-3">
                <div className={classes.colTitle}>
                  {" "}
                  <h4 className="p-1">Parent Filters</h4>
                </div>
                {loading ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                    <Skeleton
                      key={id}
                      variant="rect"
                      width={250}
                      height={40}
                      className="mt-1"
                    />
                  ))
                ) : (
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChangeParent}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {/* {categories?.map((cat, index) => {
                      return (
                        <Tab
                          value={index}
                          label={cat.name}
                          key={cat._id}
                          className={classes.textColorPrimary}
                        />
                      );
                    })} */}
                  </Tabs>
                )}
              </div>
              <div className="col-md-3">
                <div className={classes.colTitle}>
                  {" "}
                  <h4 className="p-1">Child Filters</h4>
                </div>
                {loadingChild ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                    <Skeleton
                      key={id}
                      variant="rect"
                      width={250}
                      height={40}
                      className="mt-1"
                    />
                  ))
                ) : (
                  <>
                    {firstLevelCategories?.length > 0 ? (
                      <Tabs
                        orientation="vertical"
                        value={childValue}
                        onChange={handleChangeChild}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        {firstLevelCategories?.map((cat, index) => {
                          return (
                            <Tab
                              value={index}
                              label={cat.name}
                              key={cat._id}
                              className={classes.textColorPrimary}
                            />
                          );
                        })}
                      </Tabs>
                    ) : (
                      <h4>Not Available</h4>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-3">
                <div className={classes.colTitle}>
                  {" "}
                  <h4 className="p-1">Child categories</h4>
                </div>
                {loadingSubChild ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                    <Skeleton
                      key={id}
                      variant="rect"
                      width={250}
                      height={40}
                      className="mt-1"
                    />
                  ))
                ) : secondLevelCategories?.length > 0 ? (
                  <Tabs
                    orientation="vertical"
                    value={subChildValue}
                    onChange={handleChangeSubChild}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {secondLevelCategories?.map((cat, index) => {
                      return (
                        <Tab
                          value={index}
                          label={
                            <span>
                              <p className="m-0">{cat.name}</p>
                              <small className="m-0 text-muted">
                                Child: {cat.childAvilabe.length}
                              </small>
                            </span>
                          }
                          key={cat._id}
                          className={classes.textColorPrimary}
                        />
                      );
                    })}
                  </Tabs>
                ) : (
                  <h4>Not Available</h4>
                )}
              </div>
              <div className="col-md-3">
                <div className={classes.colTitle}>
                  <h4 className="p-1">Child sub categories</h4>
                </div>
                {loadingSubChildCat ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                    <Skeleton
                      key={id}
                      variant="rect"
                      width={250}
                      height={40}
                      className="mt-1"
                    />
                  ))
                ) : thirdLevelCategories?.length > 0 ? (
                  <List>
                    {thirdLevelCategories?.map((item) => {
                      return (
                        <ListItem
                          key={item._id}
                          component={NavLink}
                          to={`/admin/create-filter/?id=${item._id}`}
                        >
                          <ListItemText primary={item.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <h4>Not Available</h4>
                )}
                {}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesMain;
