/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route } from "react-router-dom";
import SnackBar from "./components/snackbar/Snackbar";
//Redux
import { Provider } from "react-redux";
import store from "./store";
import { auth } from "./actions/auth";

import AdminDashboard from "./pages/admin/dashboard";
import Permissions from "./pages/admin/permissions";

import Login from "./pages/admin/login";
import Register from "./pages/admin/register";
import ForgetPassword from "./pages/admin/forgetPassword/forgetPassword";
import ResetPassword from "./pages/admin/resetPassword/resetPassword";

import Homepage from "./pages/admin/WPHomePage/Homepage";

// Main Category
import MainCategoryCreate from "./pages/admin/main-category/create";
import MainCategoryEdit from "./pages/admin/main-category/edit";

// Child Category
import ChildCategoryCreate from "./pages/admin/childCategory/create";
import ChildCategoryEdit from "./pages/admin/childCategory/edit";
import ChildCategories from "./pages/admin/childCategory/childCategories";

// SubCategory
import SubCategoryCreate from "./pages/admin/sub-category/create";
import SubCategoryEdit from "./pages/admin/sub-category/edit";
import SubCategories from "./pages/admin/sub-category/SubCategories";

//test category
import ChildSubCategoryCreate from "./pages/admin/child-sub-category/create";
import ChildSubCategoryEdit from "./pages/admin/child-sub-category/edit";
import ChildSubCategories from "./pages/admin/child-sub-category/ChildSubCategories";

// Slides
import SlideCreate from "./pages/admin/slide/create";
import SlideEdit from "./pages/admin/slide/edit";
import Slides from "./pages/admin/slide/slides";

// Term
import TermCreate from "./pages/admin/termsOfUse/create";
import Terms from "./pages/admin/termsOfUse/terms";

// Setting
import UserSetting from "./pages/admin/setting/userSetting";
import PaymentSetting from "./pages/admin/setting/paymentSetting";

// Blog
import BlogCreate from "./pages/admin/blog/create";
import BlogEdit from "./pages/admin/blog/edit";

// Coupon
// import CouponCreate from "./pages/admin/coupon/create";
import CouponEdit from "./pages/admin/coupon/edit";

//Fitlers

import FilterGroupingCreate from "./pages/admin/Filters/filtersGroupingCreate";
import ViewFilterGrouping from "./pages/admin/Filters/filtersGrouping";
import FilterGroupingUpdate from "./pages/admin/Filters/filtersGroupingUpdate";
import FilterItemCreate from "./pages/admin/Filters/FilterItemCreate";
import FilterItemUpdate from "./pages/admin/Filters/UpdateFilterItem";
import ViewFilterItem from "./pages/admin/Filters/ViewFilterItem";
import BulkFilter from "./pages/admin/Filters/filterFromFile";
import CreateFilter from "./pages/admin/CreateFilters/CreateFilter";
import CustomSettings from "./pages/admin/CreateFilters/CustomSettings";
import AutoSettings from "./pages/admin/CreateFilters/AutoSettings";
import FiltersCategory from "./pages/admin/Filters/FiltersCategory";
import GlobalFilters from "./pages/admin/Filters/GlobalFilters";
import CustomGlobalFilters from "./pages/admin/Filters/GlobalCustomFilters";
import CustomizeHeader from "./pages/admin/customizeHeader";

import FiltersCustomSettings from "./pages/admin/brandFilters/FiltersCustomSettings";
import FiltersAutoSettings from "./pages/admin/brandFilters/FiltersAutoSettings";
import FiltersBrand from "./pages/admin/brandFilters/FiltersBrand";
import CreateBrandFilter from "./pages/admin/brandFilters/CreateBrandFilter";
// TabsForm
import TabsForm from "./components/TabComponent/TabsForm";

// test
import Test from "./components/test";

import My404Component from "./pages/admin/404";

// Brand
import CreateBrand from "./pages/admin/brand/create";
import Brands from "./pages/admin/brand/brands";
import EditBrand from "./pages/admin/brand/edit";

// Term
import CommunityInfluencerCreate from "./pages/admin/communityInfluence/create";
import EditCommunityInfluencer from "./pages/admin/communityInfluence/edit";
import UploadFile from "./pages/admin/uploadFile/UploadFile";
// Product
import ProductCreate from "./pages/admin/product/create";
import EditProduct from "./pages/admin/product/edit";
import Products from "./pages/admin/product/Products";
import AddBulkProducts from "./pages/admin/product/AddBulkProducts";
import HyperProductModel from "./pages/admin/product/hyperProductModel";
import Attributes from "./pages/admin/product/Attributes/Attributes";
import AttributeGroups from "./pages/admin/product/AttributeGroup/AttributeGroups";
import EditHPM from "./pages/admin/product/editHPM";
import ViewHPM from "./pages/admin/product/ViewHPM";

import DiscountProducts from "./pages/admin/product/saleproducts";

// Routing
import PrivateRoute from "./routing/PrivateRoute";

// Bulk Mail
import BulkMail from "./pages/admin/bulkMail/bulkMail";

//customer Service
import CustomerService from "./pages/admin/customerService/customerService";
import AddUser from "./pages/admin/addUser/addUser";
// Store
import Store from "./pages/admin/stores/store";
import EditStore from "./pages/admin/stores/edit";

// Seller
import Seller from "./pages/admin/sellers/seller";
import EditSeller from "./pages/admin/sellers/edit";

// Banner
import Banner from "./pages/admin/banner/banners";
import CreateBanner from "./pages/admin/banner/create";
import EditBanner from "./pages/admin/banner/edit";

// HomeAds
import HomeAds from "./pages/admin/homeAds/homeAds";
import CreateHomeAds from "./pages/admin/homeAds/create";
import EditHomeAds from "./pages/admin/homeAds/edit";

// vendor routes
import VendorSetting from "./pages/admin/vendor/settings";

// vendor
import CreateVendor from "./pages/admin/vendors/create";
import EditVendor from "./pages/admin/vendors/edit";
import EditVendor1 from "./pages/admin/vendors/EditVendor";

//vendor products
import EditSellerProduct from "./pages/admin/sellerProduct/edit";
import sellerProducts from "./pages/admin/sellerProduct/products";

// general routes
import Sku from "./pages/admin/downloads/sku/Sku";
import GeneralSetting from "./pages/admin/general/settings";
// test edit product
import editProduct from "./pages/admin/product/ProductEdit";

// paid users
import PaidUsers from "./pages/admin/paidUsers/paid";
import Users from "./pages/admin/user/Users";
import UsersDetails from "./pages/admin/user/details";
import VendorOrders from "./pages/admin/multivendor/vendor-orders";
import VendorProducts from "./pages/admin/multivendor/products";

import ReferralUsers from "./pages/admin/referrals/referral";
import ReferralUserDetails from "./pages/admin/referrals/details";
// bulk products
import BulkProducts from "./pages/admin/product/bulkProducts";
import BulkInventory from "./pages/admin/product/bulkInventory";

//tickets
// import Tickets from "./pages/admin/tickets/tickets";
import ViewTicket from "./pages/admin/tickets/viewTicket";
import CategoriesMain from "./pages/admin/Filters/CategoriesMain";
import Layouts from "./pages/admin/layouts/Layouts";
import Inspiration from "./pages/admin/inspirationTags/inspiration/Inspiration";
import Inspirations from "./pages/admin/inspirationTags/inspirations/Inspirations";
import InspirationTag from "./pages/admin/inspirationTags/inspirationTag/InspirationTag";
import InspirationTags from "./pages/admin/inspirationTags/tags/Tags";

// SEO Content
import TitleAndDescription from "./pages/admin/seoManagement/titleAndDescription/TitleAndDescription";
import KeywordList from "./pages/admin/seoManagement/keyWordList/KeywordList";
import Category from "./pages/admin/seoManagement/titleAndDescription/Category";
import TitleAndDescriptionUpdate from "./pages/admin/seoManagement/titleAndDescription/TitleAndDescriptionUpdate";

//Components
import Navbar from "./components/navbar/navbar.component";
import Sidebar from "./components/sidebar/sidebar.component";

//React Redux
import { useSelector } from "react-redux";
import Emails from "./pages/admin/email";
import Intercepter from "./services/interceptor.services";
import CreateUser from "./pages/admin/user/create-user/index";
import ProcessOrders from "./pages/admin/order/process-orders/ProcessOrders";
import { Fragment } from "react";
import PendingOrders from "./pages/admin/order/pending-orders/PendingOrders";

// shipping
import AddShippingCompany from "./pages/admin/shipping/shipping-companies/AddShippingCompany";
import ListsCompanies from "./pages/admin/shipping/shipping-companies/ListsCompanies";
import EditShippingCompany from "./pages/admin/shipping/shipping-companies/EditShippingCompany";
import MainCategories from "./pages/admin/main-category/MainCategories";

import CommunityInfluencer from "./pages/admin/communityInfluence/CommunityInfluencer";
import Articles from "./pages/admin/blog/articles";
import QuotesOrders from "./pages/admin/order/quotes-orders/QuotesOrders";
import CSDashboard from "./pages/admin/customerServiceDashboard/CSDashboard";
import Tickets from "./pages/admin/customerServiceDashboard/Tickets";
import SaleAnalysis from "./pages/admin/order/sale-analysis/SaleAnalysis";
import VendorsNew from "./pages/admin/multivendor/VendorsNew";
import VendorProductCatagory from "./pages/admin/multivendor/VendorProductCatagory";
import EditVendorNew from "./pages/admin/vendors/EditVendorNew";
import EditPages from "./pages/admin/termsOfUse/EditPages";
import FAQ from "./pages/admin/frequently-asked-questions/FAQ";
import ViewOrder from "./pages/admin/order/ViewOrder";
import TablesComp from "./pages/admin/test-page/TablesComp";
import NewCoupons from "./pages/admin/coupon/NewCoupons";
import VendorInventory from "./pages/admin/multivendor/VendorInventory";
import ErrorLog from "./pages/admin/errorLogs/ErrorLog";
import EtlErrorLog from "./pages/admin/etlErrorLogs/EtlErrorLog";
import DynamicPricing from "./pages/admin/product/DynamicPricing/dynamic-pricing";
// import PriceCard from "./pages/admin/pricing/price-card/price-card";
import DiscountCode from "./pages/admin/product/discount-code/discount-code";
import PriceCard from "./components/price-card/price-card";
import CreateBlog from "./pages/admin/blog/create-blog/createBlog";
import EditBlog from "./pages/admin/blog/edit-blog/editBlog";
import ManageReviews from "./pages/admin/reviews/manage-reviews";
import ReviewSummary from "./pages/admin/reviews/review-summary/review-summary";
import UpdateModelNumber from "./pages/admin/product/UpdateModelNumber/UpdateModelNumber";
import DynamicPriceHistory from "./pages/admin/product/DynamicPriceHistory/DynamicPriceHistory";

Intercepter.setupInterceptors(store);
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    store.dispatch(auth());
  }

  publicRoutes = [
    {
      path: "/",

      component: <Login />,
    },
    {
      path: "/admin/login",

      component: <Login />,
    },
    {
      path: "/admin/register",

      component: <Register />,
    },
    {
      path: "/admin/forgot",

      component: <ForgetPassword />,
    },
    {
      path: "/admin/reset/:id",

      component: <ResetPassword />,
    },
    // {
    //   path: "*",
    //   component: My404Component,
    // },
  ];

  privateRoutesData = [
    {
      path: "/admin/dashboard",

      component: <AdminDashboard />,
    },
    {
      path: "/admin/create-user",

      component: <CreateUser />,
    },
    {
      path: "/admin/permissions",

      component: <Permissions />,
    },
    {
      path: "/admin/emails",

      component: <Emails />,
    },

    {
      path: "/admin/header",

      component: <CustomizeHeader />,
    },

    {
      path: "/admin/wp-homepage",

      component: <Homepage />,
    },

    {
      path: "/admin/layouts",

      component: <Layouts />,
    },

    {
      path: "/admin/inspiration",

      component: <Inspiration />,
    },

    {
      path: "/admin/inspiration-tag",

      component: <InspirationTag />,
    },

    {
      path: "/admin/inspiration-tags",

      component: <InspirationTags />,
    },

    {
      path: "/admin/inspirations",

      component: <Inspirations />,
    },

    {
      path: "/admin/create-main-category",

      component: <MainCategoryCreate />,
    },

    {
      path: "/admin/edit-main-category",

      component: <MainCategoryEdit />,
    },

    {
      path: "/admin/main-categories",

      component: <MainCategories />,
    },
    {
      path: "/admin/create-brand",

      component: <CreateBrand />,
    },

    {
      path: "/admin/brands",

      component: <Brands />,
    },

    {
      path: "/admin/edit-brand",

      component: <EditBrand />,
    },

    {
      path: "/admin/bulk-products",

      component: <BulkProducts />,
    },

    {
      path: "/admin/bulk-inventory",

      component: <BulkInventory />,
    },

    {
      path: "/admin/update-model-number",

      component: <UpdateModelNumber />,
    },

    {
      path: "/admin/paid-users",

      component: <PaidUsers />,
    },

    {
      path: "/admin/users",

      component: <Users />,
    },

    {
      path: "/admin/upload-file",

      component: <UploadFile />,
    },

    {
      path: "/admin/referral-users",

      component: <ReferralUsers />,
    },

    {
      path: "/admin/referral-user-details/:id",

      component: <ReferralUserDetails />,
    },

    {
      path: "/admin/user-details/:id",

      component: <UsersDetails />,
    },

    {
      path: "/admin/vendor-orders/:id",

      component: <VendorOrders />,
    },
    {
      path: "/admin/create-discount-products",

      component: <discountProductsCreate />,
    },

    {
      path: "/admin/vendor-products/:brand",

      component: <VendorProducts />,
    },

    {
      path: "/admin/create-child-category",

      component: <ChildCategoryCreate />,
    },

    {
      path: "/admin/edit-child-category",

      component: <ChildCategoryEdit />,
    },

    {
      path: "/admin/child-categories",

      component: <ChildCategories />,
    },

    {
      path: "/admin/create-term",

      component: <TermCreate />,
    },

    {
      path: "/admin/:page",

      // component: <EditTerm />,
      component: <EditPages />,
    },

    {
      path: "/admin/terms",

      component: <Terms />,
    },

    {
      path: "/admin/user-setting",

      component: <UserSetting />,
    },

    // {
    //   path: "/admin/tickets",

    //   component: <Tickets />,
    // },

    {
      path: "/admin/create-sub-category",

      component: <SubCategoryCreate />,
    },

    {
      path: "/admin/edit-sub-category",

      component: <SubCategoryEdit />,
    },

    {
      path: "/admin/sub-categories",

      component: <SubCategories />,
    },

    {
      path: "/admin/create-child-subcategory",

      component: <ChildSubCategoryCreate />,
    },

    {
      path: "/admin/edit-child-subcategory",

      component: <ChildSubCategoryEdit />,
    },

    {
      path: "/admin/child-subcategories",

      component: <ChildSubCategories />,
    },

    {
      path: "/admin/create-community-influencer",

      component: <CommunityInfluencerCreate />,
    },

    {
      path: "/admin/edit-community-influencer",

      component: <EditCommunityInfluencer />,
    },

    {
      path: "/admin/community-influencers",

      component: <CommunityInfluencer />,
    },

    {
      path: "/admin/create-slide",

      component: <SlideCreate />,
    },

    {
      path: "/admin/edit-slide",

      component: <SlideEdit />,
    },

    // {
    //   path: "/admin/slides",

    //   component: <Slides/>,
    // },

    {
      path: "/admin/create-product",

      component: <ProductCreate />,
    },

    {
      path: "/admin/TabsForm",

      component: <TabsForm />,
    },

    {
      path: "/admin/edit-product",

      component: <EditProduct />,
    },

    {
      path: "/admin/products/",

      component: <Products />,
    },

    {
      path: "/admin/add-product-cost/",

      component: <AddBulkProducts />,
    },

    {
      path: "/admin/discount-products",

      component: <DiscountProducts />,
    },

    {
      path: "/admin/testEdit",

      component: <editProduct />,
    },

    // {
    //   path: "/admin/create-coupon",

    //   component: <CouponCreate />,
    // },

    {
      path: "/admin/edit-coupon",

      component: <CouponEdit />,
    },

    {
      path: "/admin/coupons",

      component: <NewCoupons />,
    },

    {
      path: "/admin/dynamic-price-history",

      component: <DynamicPriceHistory />,
    },

    {
      path: "/admin/create-blog",

      component: <BlogCreate />,
    },

    {
      path: "/admin/blogs/edit-blog",

      component: <EditBlog />,
    },

    {
      path: "/admin/edit-blog",

      component: <BlogEdit />,
    },

    {
      path: "/admin/blogs",

      component: <Articles />,
    },

    {
      path: "/admin/blogs/create-blog",

      component: <CreateBlog />,
    },

    {
      path: "/admin/bulk-mail",

      component: <BulkMail />,
    },
    {
      path: "/admin/add-users",

      component: <AddUser />,
    },

    {
      path: "/admin/customer-service",

      component: <CustomerService />,
    },
    {
      path: "/admin/all-tickets",

      component: <Tickets />,
    },
    {
      path: "/admin/cs-dashboard",

      component: <CSDashboard />,
    },

    {
      path: "/admin/stores",

      component: <Store />,
    },

    {
      path: "/admin/edit-store",

      component: <EditStore />,
    },

    {
      path: "/admin/sellers",

      component: <Seller />,
    },

    {
      path: "/admin/edit-seller",

      component: <EditSeller />,
    },

    {
      path: "/admin/vendors",

      component: <VendorsNew />,
    },
    {
      path: "/admin/vendors/:brandId/:categoryId/:categoryName",

      component: <VendorProductCatagory />,
    },
    {
      path: "/admin/vendors-inventory",

      component: <VendorInventory />,
    },

    {
      path: "/admin/create-vendor",

      component: <CreateVendor />,
    },

    {
      path: "/admin/create-filter-group",

      component: <FilterGroupingCreate />,
    },

    {
      path: "/admin/filter-groups",

      component: <ViewFilterGrouping />,
    },

    {
      path: "/admin/edit-filter-groups",

      component: <FilterGroupingUpdate />,
    },

    {
      path: "/admin/create-filter-item",

      component: <FilterItemCreate />,
    },

    {
      path: "/admin/update-filter-item",

      component: <FilterItemUpdate />,
    },

    {
      path: "/admin/view-filter-item/:page",

      component: <ViewFilterItem />,
    },

    {
      path: "/admin/bulk-filter",

      component: <BulkFilter />,
    },

    {
      path: "/admin/create-filter",

      component: <CreateFilter />,
    },

    {
      path: "/admin/filter-category",

      component: <CategoriesMain />,
    },

    {
      path: "/admin/custom-settings",

      component: <CustomSettings />,
    },

    {
      path: "/admin/auto-settings",

      component: <AutoSettings />,
    },

    {
      path: "/admin/brand/create-filter",

      component: <CreateBrandFilter />,
    },

    {
      path: "/admin/brand/filter-category",

      component: <FiltersBrand />,
    },

    {
      path: "/admin/filter-category/child",

      component: <FiltersCategory />,
    },

    {
      path: "/admin/global-filters",

      component: <GlobalFilters />,
    },

    {
      path: "/admin/custom-global-filters",

      component: <CustomGlobalFilters />,
    },

    {
      path: "/admin/brand/custom-settings",

      component: <FiltersCustomSettings />,
    },

    {
      path: "/admin/brand/auto-settings",

      component: <FiltersAutoSettings />,
    },

    {
      path: "/admin/edit-vendor",

      component: <EditVendor />,
    },
    {
      path: "/admin/vendor/edit-vendor-new/:id",

      component: <EditVendorNew />,
    },

    {
      path: "/admin/vendor/edit-vendor/:id/:vendorName",

      component: <EditVendor1 />,
    },

    {
      path: "/admin/vendor-setting",

      component: <VendorSetting />,
    },

    {
      path: "/admin/general-setting",

      component: <GeneralSetting />,
    },

    {
      path: "/admin/banners",

      component: <Banner />,
    },

    {
      path: "/admin/create-banner",

      component: <CreateBanner />,
    },

    {
      path: "/admin/hyper-product-model",

      component: <HyperProductModel />,
    },

    {
      path: "/admin/attributes",

      component: <Attributes />,
    },

    {
      path: "/admin/attribute-groups",

      component: <AttributeGroups />,
    },

    {
      path: "/admin/edit-hpm",

      component: <EditHPM />,
    },
    {
      path: "/admin/view-hpm",

      component: <ViewHPM />,
    },

    {
      path: "/admin/edit-banner",

      component: <EditBanner />,
    },

    {
      path: "/admin/home-adss",

      component: <HomeAds />,
    },

    {
      path: "/admin/create-home-ads",

      component: <CreateHomeAds />,
    },

    {
      path: "/admin/edit-home-ads",

      component: <EditHomeAds />,
    },

    {
      path: "/admin/edit-seller-product",

      component: <EditSellerProduct />,
    },

    {
      path: "/admin/seller-products",

      component: <sellerProducts />,
    },

    {
      path: "/admin/process-orders",

      component: <ProcessOrders />,
    },
    {
      path: "/admin/pending-orders",

      component: <PendingOrders />,
    },
    {
      path: "/admin/quote-orders",

      component: <QuotesOrders />,
    },

    {
      path: "/admin/manage-reviews",

      component: <ManageReviews />,
    },

    {
      path: "/admin/review-summary",

      component: <ReviewSummary />,
    },

    {
      path: "/admin/view-ticket/:ticketID",

      component: <ViewTicket />,
    },

    {
      path: "/admin/error-log",

      component: <ErrorLog />,
    },

    {
      path: "/admin/etl-error-log",

      component: <EtlErrorLog />,
    },

    {
      path: "/admin/downloads/sku",

      component: <Sku />,
    },

    {
      path: "/admin/tables-comp",

      component: <TablesComp />,
    },
    {
      path: "/admin/test",

      component: <Test />,
    },
    {
      path: "/admin/title-and-description",

      component: <TitleAndDescription />,
    },
    {
      path: "/admin/keyword-list",

      component: <KeywordList />,
    },
    {
      path: "/admin/title-and-description/:brandName/:brandId/category",

      component: <Category />,
    },
    {
      path: "/admin/title-and-description/:brandName/:brandId/:categoryName/:categoryId",

      component: <TitleAndDescriptionUpdate />,
    },
    {
      path: "/admin/sale-analysis",

      component: <SaleAnalysis />,
    },
    {
      path: "/admin/view-order/:id",

      component: <ViewOrder />,
    },
    {
      path: "/admin/shipping/add-shipping-company",

      component: <AddShippingCompany />,
    },
    {
      path: "/admin/shipping/companies-lists",

      component: <ListsCompanies />,
    },
    {
      path: "/admin/shipping/edit-shipping-company/:id",

      component: <EditShippingCompany />,
    },
    {
      path: "/admin/frequently-asked-questions",

      component: <FAQ />,
    },
    {
      path: "/admin/dynamic-pricing",

      component: <DynamicPricing />,
    },

    {
      path: "/admin/discount-code",

      component: <DiscountCode />,
    },
    {
      path: "/components/price-card/price-card",

      component: <PriceCard />,
    },
  ];

  render() {
    return (
      <Provider store={store}>
        <MainLayout store={store}>
          <Routes>
            <>
              {this.publicRoutes.map((r, index) => {
                return (
                  <Route
                    key={index}
                    exact
                    path={r.path}
                    element={r.component}
                  />
                );
              })}

              {this.privateRoutesData.map((r, index) => {
                return (
                  <Fragment key={index}>
                    <Route exact path="/" element={<PrivateRoute />}>
                      <Route exact path={r.path} element={r.component} />
                    </Route>
                  </Fragment>
                );
              })}
            </>
          </Routes>
        </MainLayout>
      </Provider>
    );
  }
}

export default App;

const MainLayout = ({ children, store }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  return (
    <div className="container-scroller">
      {isAuth ? <PrivateLayout>{children}</PrivateLayout> : children}
    </div>
  );
};

const PrivateLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar show="dashboard" />
        <SnackBar />
        <div className="main-panel">
          <div className="content-wrapper">{children}</div>
        </div>
      </div>
    </>
  );
};
