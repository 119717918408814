import React from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";


// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create, retreive } from "../../../actions/generalSetting";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class GeneralSetting extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orderEmail: "",
      returnEmail: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const _res = await this.props.retreive();
    const { orderEmail, returnEmail } = _res.data;
    if (this._isMounted) {
      this.setState({
        loading: false,
        orderEmail,
        returnEmail,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { orderEmail, returnEmail } = this.state;

    this.setState({ loading: true });

    const res = await this.props.create({
      orderEmail,
      returnEmail,
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const { orderEmail, returnEmail } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> General Setting </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                General Setting
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit General Setting</h4>

                <Alert />

                <form className="form-sample">
                  <p className="card-description"> General Settings </p>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Order Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="orderEmail"
                        value={orderEmail && orderEmail}
                        onChange={(e) =>
                          this.setState({
                            orderEmail: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Return Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="returnEmail"
                        value={returnEmail && returnEmail}
                        onChange={(e) =>
                          this.setState({
                            returnEmail: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2 "
                  >
                    Update
                  </button>
                  <Link to={"/admin/general-setting"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

export default connect(null, { create, retreive })(GeneralSetting);
