import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";
import UpdateButton from "./UpdateButton";
import { updateReturnPolicy } from "../../../../services/vendor/vendor.service";

const ManageReturnPolicy = ({
  selectedItem,
  setSelectedItem,
  isRender,
  setIsRender,
}) => {
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await updateReturnPolicy({
        id: selectedItem._id,
        shippingAndReturns: { ...selectedItem?.shippingAndReturns },
      });
      if (res) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="  d-flex flex-column gap-10">
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Use Vendor Return Policy</span>
        <span>
          <ToggleSwitch
            checked={
              selectedItem?.shippingAndReturns?.policyType === "vendor"
                ? true
                : false
            }
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  policyType: e.target.checked === true ? "vendor" : "sundial",
                },
              });
            }}
            Name={"policyType"}
          />
        </span>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-10">
        <span>Damage/Defective Return Policy</span>
        <textarea
          className="bg-grey "
          name="defectivePolicy"
          value={selectedItem?.shippingAndReturns?.defectivePolicy}
          onChange={(e) =>
            setSelectedItem({
              ...selectedItem,
              shippingAndReturns: {
                ...selectedItem?.shippingAndReturns,
                [e.target.name]: e.target.value,
              },
            })
          }
          cols="40"
          rows="5"
        />
      </div>
      <hr />

      <div className=" d-flex  justify-content-between align-center px-3 gap-10">
        <span>Damage/Defective Claims Period</span>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-50"
            type="number"
            name="defectiveClaimsCutoff"
            value={selectedItem?.shippingAndReturns?.defectiveClaimsCutoff}
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  [e.target.name]: Number(e.target.value),
                },
              })
            }
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex  justify-content-between align-center px-3 gap-10">
        <span>Damage/Defective Fee</span>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-50"
            type="number"
            name="defectiveRestockFee"
            value={selectedItem?.shippingAndReturns?.defectiveRestockFee}
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  [e.target.name]: Number(e.target.value),
                },
              })
            }
          />
          %
        </div>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-10">
        <span>Buyers Remorse Return Policy</span>
        <textarea
          className="bg-grey "
          cols="40"
          rows="5"
          name="returnPolicy"
          value={selectedItem?.shippingAndReturns?.returnPolicy}
          onChange={(e) =>
            setSelectedItem({
              ...selectedItem,
              shippingAndReturns: {
                ...selectedItem?.shippingAndReturns,
                [e.target.name]: e.target.value,
              },
            })
          }
        />
      </div>
      <hr />
      <div className=" d-flex  justify-content-between align-center px-3 gap-10">
        <span>Buyers Remorse Claims Period</span>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-50"
            type="number"
            name="brClaimsCutoff"
            value={selectedItem?.shippingAndReturns?.brClaimsCutoff}
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  [e.target.name]: Number(e.target.value),
                },
              })
            }
          />
          Days
        </div>
      </div>
      <hr />
      <div className=" d-flex  justify-content-between align-center px-3 gap-10">
        <span>Buyers Remorse Fee</span>
        <div>
          <input
            className=" p-2 border-radius-10  border-grey  w-50"
            type="number"
            name="brRestockFee"
            value={selectedItem?.shippingAndReturns?.brRestockFee}
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                shippingAndReturns: {
                  ...selectedItem?.shippingAndReturns,
                  [e.target.name]: Number(e.target.value),
                },
              })
            }
          />
          %
        </div>
      </div>
      <hr />
      <div className=" px-3 d-flex justify-content-between align-center gap-10">
        <span>Cancellation Policy</span>
        <textarea
          className="bg-grey "
          cols="40"
          rows="5"
          name="cancellationPolicy"
          value={selectedItem?.shippingAndReturns?.cancellationPolicy}
          onChange={(e) =>
            setSelectedItem({
              ...selectedItem,
              shippingAndReturns: {
                ...selectedItem?.shippingAndReturns,
                [e.target.name]: e.target.value,
              },
            })
          }
        />
      </div>
      <UpdateButton
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManageReturnPolicy;
