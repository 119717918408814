import axios from "axios";
import { apiURL } from "../config/default";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  AUTH_SUCCESS,
  LOGOUT_SUCCESS,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
} from "./types";
import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const register =
  ({ adminSecretKey, email, fullName, userName, password, role }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      adminSecretKey,
      email,
      fullName,
      userName,
      password,
      role,
    });

    try {
      const res = await axios.post(`${apiURL}admin/register`, body, _config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
        });
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });

    try {
      const res = await axios.post(`${apiURL}admin/login`, body, _config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (err) {
      return err;
    }
  };

export const auth = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  try {
    const res = await axios.get(`${apiURL}auth`);
    const brand = await axios.get(`${apiURL}admin/all-stores`);
    localStorage.setItem(
      "Brand",
      JSON.stringify(
        brand.data.map((item) => {
          return {
            id: item?._id,
            value: item?.fullName,
            label: item?.fullName,
          };
        })
      )
    );
    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const logout = () => async (dispatch) => {
  delete axios.defaults.headers.common["x-auth-token"];
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const forgot =
  ({ email }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
    });

    try {
      const res = await axios.post(`${apiURL}forgot`, body, _config);
      dispatch({
        type: FORGOT_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (err) {
      const errors = err.response.data.error;
      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
        });
      }

      dispatch({
        type: FORGOT_FAIL,
      });
    }
  };

export const reset =
  ({ id, password }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setAuthHeader(id);

    const body = JSON.stringify({
      id,
      password,
    });

    try {
      const res = await axios.post(`${apiURL}reset`, body, _config);

      delete axios.defaults.headers.common["x-auth-token"];

      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
        });
      }
    }
  };
