import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { withRouter } from "../../utils/withRouter";
import PageTitle from "../page-title/PageTitle";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sidebarToggle() {
    $(".sidebar-offcanvas").toggleClass("active");
  }

  _logout = async () => {
    await this.props.logout();
    this.props.navigate("/admin/login");
  };

  getPageName = () => {
    const pageRoutes = this.props.location.pathname;
    const lastString = pageRoutes.substring(pageRoutes.lastIndexOf("/") + 1);
    const result = lastString
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return result ? result : "Welcome";
  };
  render() {
    const { user } = this.props.auth;

    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex align-items-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img
              src="/assets/images/sundial-logo.svg"
              alt="logo"
              className="logo-dark"
              draggable={false}
            />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img src="/assets/images/logo-mini.svg" alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">
          <PageTitle pageName={this.getPageName()} />

          <ul className="navbar-nav navbar-nav-right ml-auto">
            <li className="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="UserDropdown"
                href="!#"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="img-xs rounded-circle ml-2"
                  alt="user avatar"
                  src={`https://ui-avatars.com/api/?background=38ce3c&color=fff&name=${"Admin"}`}
                />

                <span className="font-weight-normal">
                  {" "}
                  {user && user.fullName}{" "}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="UserDropdown"
              >
                <div className="dropdown-header text-center">
                  <img
                    className="img-md rounded-circle"
                    alt="user avatar"
                    src={`https://ui-avatars.com/api/?background=38ce3c&color=fff&name=${"Admin"}`}
                    width={100}
                  />
                  <p className="mb-1 mt-3">{user && user.fullName}</p>
                  <p className="font-weight-light text-muted mb-0">
                    {user && user.email}
                  </p>
                </div>

                <a
                  className="dropdown-item"
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    this._logout();
                  }}
                >
                  <i className="dropdown-item-icon icon-power text-primary" />
                  Sign Out
                </a>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            data-toggle="offcanvas"
            onClick={() => {
              this.sidebarToggle();
            }}
          >
            <span className="icon-menu" />
          </button>
        </div>
      </nav>
    );
  }
}

logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logout })(Navbar));
