import React from "react";

const ConversationCard = ({ selectedItem }) => {
  return (
    <div className=" d-flex flex-column gap-20 border-grey bg-half-white my-3 p-3 border-radius-10 ">
      <div>
        <h6>Topic</h6>
        <p>{selectedItem?.topic}</p>
      </div>
      <div>
        <h6>Creator</h6>
        <p>{selectedItem?.creatorName}</p>
      </div>
      <div>
        <h6>Refrence Link</h6>
        <a
          href={selectedItem?.referenceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {selectedItem?.referenceLink}
        </a>
      </div>
      <div>
        <h6>Question</h6>
        <p>{selectedItem?.question}</p>
      </div>
    </div>
  );
};

export default ConversationCard;
