import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = ({ title, description }) => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  const body = JSON.stringify({
    title,
    description
  });

  try {
    const res = await axios.post(
      `${apiURL}admin/create-community-influencer`,
      body,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = id => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.get(
      `${apiURL}admin/community-influencer/${id}`,
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const edit = ({ title, description, id }) => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  const body = JSON.stringify({
    id,
    title,
    description
  });

  try {
    const res = await axios.post(
      `${apiURL}admin/edit-community-influencer`,
      body,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const communityInfluencers = e => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.get(
      `${apiURL}admin/community-influencers`,
      _config
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const deleteCommunityInfluencer = id => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/delete-community-influencer/${id}`,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
