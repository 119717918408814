import React, { useCallback, useEffect, useState } from "react";
import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import { useLocation, useNavigate } from "react-router";
import OrderStatusButton from "../order/components/OrderStatusButton";
import { CircularProgress, Pagination } from "@mui/material";
import { createSearchParams } from "react-router-dom";
import { getAllVenderList } from "../../../services/vendor/vendor.service";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";
import ManagePricingStrategy from "./components/ManagePricingStrategy";
import ManageLeadTime from "./components/ManageLeadTime";
import ManageVendorStatus from "./components/ManageVendorStatus";
import ManageGlobalMargin from "./components/ManageGlobalMargin";
import ManageCashback from "./components/ManageCashback";
import ProductsStatistics from "./components/ProductsStatistics";
import ProductActions from "./components/ProductActions";
import ManageMarginOverride from "./components/ManageMarginOverride";
import ManageReturnPolicy from "./components/ManageReturnPolicy";
import TableLoader from "../../../components/TableSkelton/TableLoader";
import { BiSolidCircle } from "react-icons/bi";
import DashboardPagesLayout from "../customerServiceDashboard/components/DashboardPagesLayout";

const VendorsNew = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // local state
  const [loading, setLoading] = useState(false);
  const [InnderLoader, setInnderLoader] = useState(false);

  const [Data, setData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
    sort: "",
  });

  const fetchData = useCallback(
    async (
      currentPage,
      SearchQuery,
      SearchByCustomer,
      ticketStatus,
      isEscalated,
      attention,
      priority,
      ticketType
    ) => {
      setLoading(true);
      const res = await getAllVenderList(
        currentPage,
        SearchQuery,
        SearchByCustomer,
        ticketStatus,
        isEscalated,
        attention,
        priority,
        ticketType
      );

      if (res) {
        if (!selectedItem) {
          setSelectedItem({});
        } else {
          const newSelected = res?.data?.find(
            (item) => item._id === selectedItem._id
          );
          setSelectedItem(newSelected);
        }

        setData(res?.data);
        setpagination({
          ...res?.pagination,
        });
        setLoading(false);
        setInnderLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data, selectedItem]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      fetchData(pagination.currentPage, SearchFillter.SearchQuery);
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, SearchFillter.SearchQuery, isRender]);

  // handle page change query
  const handleChange = (e, value) => {
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };
  // handle pricetype
  const handlePricingStrategy = (item) => {
    setEdit({
      open: true,
      edit: "pricingStrategy",
      title: ` ${item.fullName} / Pricing Strategy`,
    });
    setSelectedItem({ ...item });
  };

  // handle pricetype
  const handleLeadTime = (item) => {
    setEdit({
      open: true,
      edit: "leadTime",
      title: ` ${item.fullName} | Estimated Delivery Times`,
    });
    setSelectedItem({ ...item });
  };

  const handleVendorStatus = (item) => {
    setEdit({
      open: true,
      edit: "vendorStatus",
      title: ` ${item.fullName} | Vendor Status`,
    });
    setSelectedItem({ ...item });
  };

  const handleGlobalMargin = (item) => {
    setEdit({
      open: true,
      edit: "globalMargin",
      title: ` ${item.fullName} | Global Margin`,
    });
    setSelectedItem({ ...item });
  };
  const handleCashback = (item) => {
    setEdit({
      open: true,
      edit: "cashback",
      title: ` ${item.fullName} | Cashback Settings`,
    });
    setSelectedItem({ ...item });
  };
  const handleStats = (item) => {
    setEdit({
      open: true,
      edit: "stats",
      title: ` ${item.fullName} | Product Statistics`,
    });
    setSelectedItem({ ...item });
  };

  const handleActions = (item) => {
    setEdit({
      open: true,
      edit: "actions",
      title: ` ${item.fullName} | Actions`,
    });
    setSelectedItem({ ...item });
  };

  const handleMarginOverride = (item) => {
    setEdit({
      open: true,
      edit: "marginOverride",
      title: ` ${item.fullName} | Margins`,
    });
    setSelectedItem({ ...item });
  };
  const handleReturnPolicy = (item) => {
    setEdit({
      open: true,
      edit: "returnPolicy",
      title: ` ${item.fullName} | Return Policy`,
    });
    setSelectedItem({ ...item });
  };
  return (
    <>
      <div className=" d-flex  justify-content-end align-center flex-wrap gap-10">
        <SearchFilter
          type="text"
          placeholder={"Search Vendor"}
          value={SearchFillter?.SearchQuery}
          onChange={(e) =>
            setSearchFillter({
              ...SearchFillter,
              SearchQuery: e.target.value,
            })
          }
        />
      </div>
      <div className=" my-5">
        <DashboardPagesLayout
          pageTitle="All Vendors"
          subTitle="items"
          count={pagination?.count}
        >
          <div className="process-orders-table">
            <table className="m-0">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Vendor</th>
                  <th>
                    Pricing <br /> Strategy
                  </th>
                  <th>
                    Global <br /> Margin
                  </th>
                  <th>
                    Margin <br /> Override
                  </th>
                  <th>Feeds</th>
                  <th>
                    Cashback <br /> Percentage
                  </th>
                  <th>
                    Returns
                    <br /> Policy
                  </th>
                  <th>
                    Estimated
                    <br /> Delivery Times
                  </th>
                  <th>Stats</th>
                  <th>Edit</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && <TableLoader rowsNum={8} cellNum={12} />}

                {!loading &&
                  Data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className=" c-pointer"
                          onClick={() => handleVendorStatus(item)}
                        >
                          <BiSolidCircle
                            className={
                              !item?.disabled ? "text-success" : "text-muted"
                            }
                          />
                          <span className=" ml-1">
                            {item?.disabled ? "Off" : "Live"}
                          </span>
                        </div>
                      </td>
                      <td className="font-weight-bold">
                        {!item?.disabled ? (
                          <a
                            href={
                              process.env.REACT_APP_ENVIROMENT === "DEV"
                                ? `https://qasundial.sundialhome.com/brand/${item?.slug}/1`
                                : `https://www.sundialhome.com/brand/${item?.slug}/1`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.fullName}
                          </a>
                        ) : (
                          item?.fullName
                        )}
                      </td>

                      <td>
                        <OrderStatusButton
                          onClick={() => handlePricingStrategy(item)}
                          width={190}
                          status={item?.pricingType}
                        >
                          {item?.pricingType === "smart"
                            ? "Smart Map"
                            : item?.pricingType === "break"
                            ? "Break Map"
                            : item?.pricingType === "standardCoupon"
                            ? "Standard Coupon"
                            : item?.pricingType === "coupon"
                            ? "Flash Coupon "
                            : item?.pricingType === "quote"
                            ? `Quote (${item?.showCoupon ? "Show" : "Hide"}) `
                            : " "}
                        </OrderStatusButton>
                      </td>
                      <td>
                        <div
                          className=" c-pointer font-weight-bold"
                          onClick={() => handleGlobalMargin(item)}
                        >
                          Map: <span> {item?.mapPercentage}%</span>
                          <br />
                          N-MAP: <span>{item?.nonMapPercentage}%</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className=" c-pointer font-weight-bold"
                          onClick={() => handleMarginOverride(item)}
                        >
                          <span>Override</span> <br /> <span>10/30</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className=" c-pointer"
                          onClick={() => handleVendorStatus(item)}
                        >
                          <BiSolidCircle
                            className={
                              item?.googleShopping
                                ? "text-success"
                                : "text-muted"
                            }
                          />

                          <span className=" ml-1">
                            {item?.googleShopping ? "live" : "off"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className=" c-pointer font-weight-bold"
                          onClick={() => handleCashback(item)}
                        >
                          Cashback: {item?.cashbackPercentage}%
                        </div>
                      </td>
                      <td>
                        <div
                          className=" c-pointer font-weight-bold"
                          onClick={() => handleReturnPolicy(item)}
                        >
                          <BiSolidCircle
                            className={
                              item?.shippingAndReturns?.policyType === "vendor"
                                ? "text-success"
                                : "text-muted"
                            }
                          />
                          <br />
                          DD: {
                            item?.shippingAndReturns?.defectiveClaimsCutoff
                          }{" "}
                          days / {item?.shippingAndReturns?.defectiveRestockFee}
                          %
                          <br />
                          BR: {item?.shippingAndReturns?.brClaimsCutoff} days /{" "}
                          {item?.shippingAndReturns?.brRestockFee}%
                        </div>
                      </td>
                      <td>
                        <div
                          className=" c-pointer font-weight-bold"
                          onClick={() => handleLeadTime(item)}
                        >
                          <BiSolidCircle
                            className={
                              item?.shippingAndReturns?.policyType === "vendor"
                                ? "text-success"
                                : "text-muted"
                            }
                          />
                          <br />
                          SP:{" "}
                          {item?.shippingAndReturns?.leadTimeSmall +
                            item?.shippingAndReturns?.minShipTimeSmall}
                          -
                          {item?.shippingAndReturns?.leadTimeSmall +
                            item?.shippingAndReturns?.maxShipTimeSmall}{" "}
                          days
                          <br />
                          LTL:{" "}
                          {item?.shippingAndReturns?.leadTimeLTL +
                            item?.shippingAndReturns?.minShipTimeLTL}
                          -
                          {item?.shippingAndReturns?.leadTimeLTL +
                            item?.shippingAndReturns?.maxShipTimeLTL}{" "}
                          days
                        </div>
                      </td>
                      <td>
                        <OrderStatusButton
                          onClick={() => handleStats(item)}
                          width={180}
                          status={"+add"}
                        >
                          <span className="ml-2">Stats ({item?.products})</span>
                        </OrderStatusButton>
                      </td>
                      <td>
                        <OrderStatusButton
                          onClick={() =>
                            navigate(
                              `/admin/vendor/edit-vendor/${
                                item._id
                              }/${item?.fullName.split(" ").join("-")}`
                            )
                          }
                          width={150}
                          status={"+add"}
                        >
                          <span className="ml-2">Edit Vendor</span>
                        </OrderStatusButton>
                      </td>
                      <td>
                        <div
                          className=" c-pointer underlined"
                          onClick={() => handleActions(item)}
                        >
                          Actions
                        </div>
                      </td>
                    </tr>
                  ))}
                {!Data?.length && !loading && (
                  <tr>
                    <td colSpan={19} className=" text-center">
                      "No record Found"
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center align-center p-2">
              <div className=" mx-auto">
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleChange}
                />
              </div>

              <span className=" font-weight-bold" style={{ color: "#9f9f9f" }}>
                {Data?.length} of {pagination?.count} Items
              </span>
            </div>
            <EditTabelDrawer Edit={Edit} setEdit={setEdit}>
              {InnderLoader ? (
                <div className=" d-flex align-center justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {Edit.edit === "pricingStrategy" && (
                    <ManagePricingStrategy
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )}
                  {Edit.edit === "leadTime" && (
                    <ManageLeadTime
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )}
                  {Edit.edit === "vendorStatus" && (
                    <ManageVendorStatus
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )}
                  {Edit.edit === "globalMargin" && (
                    <ManageGlobalMargin
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                    />
                  )}
                  {Edit.edit === "cashback" && (
                    <ManageCashback
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                    />
                  )}
                  {Edit.edit === "stats" && (
                    <ProductsStatistics selectedItem={selectedItem} />
                  )}
                  {Edit.edit === "actions" && (
                    <ProductActions
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                    />
                  )}
                  {Edit.edit === "marginOverride" && (
                    <ManageMarginOverride selectedItem={selectedItem} />
                  )}
                  {Edit.edit === "returnPolicy" && (
                    <ManageReturnPolicy
                      isRender={isRender}
                      setIsRender={setIsRender}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )}
                </>
              )}
            </EditTabelDrawer>
          </div>
        </DashboardPagesLayout>
      </div>
    </>
  );
};

export default VendorsNew;
