import axios from "axios";
import { apiURL } from "../../../../config/default";

export const getAllBlogTags = async () => {
  try {
    const response = await axios.get(`${apiURL}admin/blog-tags`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getSingleBlogTag = async (id) => {
  try {
    const response = await axios.get(`${apiURL}admin/blog-tags/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const addNewBlogTag = async (blogName) => {
  try {
    const response = await axios.post(`${apiURL}admin/blog-tags`, {
      name: blogName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSingleBlogTag = async (id) => {
  try {
    const response = await axios.delete(`${apiURL}admin/blog-tags/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user data:", error);
    throw error;
  }
};

export const updateBlogTag = async (blogName, id) => {
  try {
    const response = await axios.put(`${apiURL}admin/blog-tags/${id}`, {
      name: blogName,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};
