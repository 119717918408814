import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const getTickets =
  (
    currentPage,
    SearchQuery,
    SearchByCustomer,
    ticketStatus,
    isEscalated,
    attention,
    priority,
    ticketType
  ) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }
    try {
      const res = await axios.get(
        `${apiURL}all-tickets?page=${currentPage ? currentPage : 1}&search=${
          SearchQuery ? SearchQuery : ""
        }&name=${SearchByCustomer ? SearchByCustomer : ""}&status=${
          ticketStatus ? ticketStatus : ""
        }&attention=${attention ? attention : ""}&isEscalated=${
          isEscalated ? isEscalated : ""
        }&priority=${priority ? priority : ""}&reason=${
          ticketType ? ticketType : ""
        }`
      );
      return res.data;
    } catch (err) {
      const errors = err.response.data.error;
      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
        });
      }
    }
  };

export const getTicketbyId = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}ticket/${id}`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

// get cs dashboard data
export const getDashboardData = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}/admin/get-ticket-status`);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

// send message by admin
export const SendNewMessage = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}ticket/${id}`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

// update ticket status
export const updateTicketStatus = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}update-ticket-status`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

// send message by admin
export const resolvedTicket = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}ticket/${id}`, data);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const ticketNotesUpdate = (id, data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}update-ticket-notes/${id}`,
      {
        ...data,
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
