import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import CustomeTableBtn from "../../../components/custome-table-button/CustomeTableBtn";
import SweetAlert from "react-bootstrap-sweetalert";

// import SearchFilter from "../../../components/CustomeFilter/SearchFilter";
import {
  customerServices,
  editCustomerService,
} from "../../../actions/customerService";

const CustomerService = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [isMount, setisMount] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  // eslint-disable-next-line no-unused-vars
  const [SearchFillter, setSearchFillter] = useState({
    SearchQuery: "",
  });

  // Api calls for get Order
  const getData = useCallback(
    async (pageNo, search) => {
      const res = await dispatch(customerServices(pageNo, search));
      setData(res?.customerServices);
      setpagination({
        ...res?.pagination,
      });
      setloading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    var isSubscribe = true;
    if (isSubscribe) {
      var timer = setTimeout(() => {
        getData(pagination.currentPage, SearchFillter?.SearchQuery);
      }, 1000);
    }

    return () => {
      isSubscribe = false;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date, pagination.currentPage, SearchFillter?.SearchQuery, isMount]);

  // handle page change
  const handleChange = (e, value) => {
    const options = {
      pathname: "/admin/customer-service",
      search: `?${createSearchParams({ page: value })}`,
    };
    navigate(options, { replace: true });
    setpagination({ ...pagination, currentPage: value });
  };

  //Delete Category
  const deleteData = async (id) => {
    setloading(true);
    const res = await dispatch(editCustomerService(id));
    if (res) {
      setdeleteConfirm({
        open: false,
        id: null,
      });
      setisMount(!isMount);
      setloading(false);
    }
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          {/* <div className=" d-flex  justify-content-end align-center">
            <SearchFilter
              type="text"
              placeholder={"Search User ..."}
              value={SearchFillter?.SearchQuery}
              onChange={(e) =>
                setSearchFillter({
                  ...SearchFillter,
                  SearchQuery: e.target.value,
                })
              }
            />
          </div> */}
          <div className="process-orders-table">
            <table className="mt-5">
              <thead>
                <tr>
                  <th>Sn#</th>
                  <th>Name</th>
                  <th> Email</th>
                  <th> Topic</th>
                  <th> Message</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Data?.length ? (
                  <>
                    {Data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td className="order-item-number">{i + 1}</td>
                        <td> {item.name} </td>
                        <td>
                          {" "}
                          <a href={`mailto:${item.email}`} target="_top">
                            {item.email}
                          </a>
                        </td>

                        <td>{item?.topic}</td>
                        <td>
                          {
                            item?.message
                            // .substring(0, 50) + "..."
                          }
                        </td>

                        <td>{moment(item?.createdAt).format("l")}</td>
                        <td>
                          <CustomeTableBtn
                            color={item?.status ? "success" : "danger"}
                            width={"180"}
                            onClick={() =>
                              !item?.status
                                ? setdeleteConfirm({
                                    open: true,
                                    id: item?._id,
                                  })
                                : null
                            }
                          >
                            {item?.status ? "Resolved" : "Mark As Resolve"}
                          </CustomeTableBtn>

                          {deleteConfirm.open && (
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, sure!"
                              confirmBtnBsStyle="danger"
                              title="Are you sure?"
                              onConfirm={() => {
                                deleteData(deleteConfirm.id);
                              }}
                              onCancel={() =>
                                setdeleteConfirm({
                                  open: false,
                                  id: null,
                                })
                              }
                              focusCancelBtn
                            >
                              Are you sure you want to change the status ?
                            </SweetAlert>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={9} className=" text-center">
                        {" "}
                        No Record Found
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className=" d-flex justify-content-center my-4">
            <Pagination
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CustomerService;
