import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verifyCogs } from "../../../../../actions/saleAnalysis";

const DsFeesTable = ({ SelectItem, IsMount, setIsMount }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [updateValue, setupdateValue] = useState({
    dsFeesOne: SelectItem?.orDsFees?.dsFeesOne ?? "",
    dsFeesTwo: SelectItem?.orDsFees?.dsFeesTwo ?? "",
    quantity1: SelectItem?.orDsFees?.dsFeesQuantityOne ?? 1,
    quantity2: SelectItem?.orDsFees?.dsFeesQuantityTwo ?? 1,
  });
  const updateVerifyDsFee = async () => {
    setloading(true);
    try {
      const res = await dispatch(
        verifyCogs(SelectItem?._id, {
          orDsFees: {
            dsFeesOne: Number(updateValue?.dsFeesOne),
            dsFeesTwo: Number(updateValue?.dsFeesTwo),
            dsFeesQuantityOne: Number(updateValue?.quantity1),
            dsFeesQuantityTwo: Number(updateValue?.quantity2),
            status: true,
          },
        })
      );
      if (res) {
        setloading(false);
        setIsMount(!IsMount);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="my-3 px-3">
        <table className="cogs-table">
          <tbody>
            <tr>
              <th>Variable</th>
              <th>Default</th>
              <th>Overide</th>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">DS Fee 1</span>
              </td>
              <td>
                $
                {SelectItem?.dsFeesOne
                  ? SelectItem.dsFeesOne.toFixed(2)
                  : "0.00"}
              </td>
              <td>
                <input
                  type="number"
                  name="dsFeesOne"
                  className=" border-0"
                  placeholder="DS FeeOne"
                  value={updateValue?.dsFeesOne}
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">DS Fee 1 Qty</span>
              </td>
              <td>{SelectItem?.quantity}</td>
              <td>
                <select
                  name="quantity1"
                  value={updateValue.quantity1}
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  {[...Array(Number(SelectItem?.quantity))].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">DS Fee 2</span>
              </td>
              <td>
                $
                {SelectItem?.dsFeesTwo
                  ? SelectItem?.dsFeesTwo.toFixed(2)
                  : "0.00"}
              </td>
              <td>
                <input
                  type="number"
                  name="dsFeesTwo"
                  className=" border-0"
                  placeholder="DS FeesTwo"
                  value={updateValue?.dsFeesTwo}
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">DS Fee 2 Qty</span>
              </td>

              <td>{SelectItem?.quantity}</td>
              <td>
                <select
                  name="quantity2"
                  value={updateValue.quantity2}
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  {[...Array(Number(SelectItem?.quantity))].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <span className=" font-weight-bold">Total</span>
              </td>

              <td>
                $
                {(
                  SelectItem?.dsFeesOne * SelectItem?.quantity +
                  SelectItem?.dsFeesTwo * SelectItem?.quantity
                ).toFixed(2)}
              </td>
              <td>
                $
                {(
                  updateValue?.dsFeesOne * updateValue?.quantity1 +
                  updateValue?.dsFeesTwo * updateValue?.quantity2
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <button
          onClick={updateVerifyDsFee}
          className="update-Order-button text-white bg-success mt-3 "
        >
          {loading ? "Loading..." : "Verify DS Fees Override"}
        </button>
      </div>
    </>
  );
};

export default DsFeesTable;
