import {
  GET_TEXT_EDITOR,
  CAREATE_ATTRIBUTE,
  CAREATE_ATTRIBUTE_GROUP,
} from "../actions/types";

const initialState = {
  createAttributes: "",
  createAttributesGroup: "",
};

const textEditor = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TEXT_EDITOR:
      return { ...state, textEditor: payload };
    case CAREATE_ATTRIBUTE:
      return { ...state, createAttributes: payload };
    case CAREATE_ATTRIBUTE_GROUP:
      return { ...state, createAttributesGroup: payload };
    default:
      return state;
  }
}

export default textEditor