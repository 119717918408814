/* eslint-disable array-callback-return */
import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
// Redux
import { connect } from "react-redux";
import ImageUploader from "react-images-upload";
import { getStores } from "../../../actions/store";

import "./filters.css";
import PropTypes from "prop-types";
import {
  getAttributeText,
  getFilterGrouping,
  createFilterItem,
} from "../../../actions/filters";
import { Spinner } from "react-bootstrap";

import { subCategories } from "../../../actions/subCategory";

import { getAttribute } from "../../../actions/product";

import { ToastContainer, toast } from "react-toastify";

import Alert from "../../../components/alert/alert.component";
import { withRouter } from "../../../utils/withRouter";

class FilterItemCreate extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: false,
      pictures: [],
      attributeId: "",
      getAttributes: [],
      getAttributeText: [],
      attributeText: "",
      getFilterGroups: [],
      groupId: "",
      width: "",
      categoryData: [{ categoryId: "", base64Image: "", imageName: "" }],
      height: "",
      getCategory: [],
      loading: false,
      attrLoading: false,
      submitLoading: false,
      brandData: [{ brandId: "", base64Image: "", imageName: "" }],
      getBrand: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const res = await this.props.getAttribute();
    this.setState({ getAttributes: res.data.sort() });
    const resFilters = await this.props.getFilterGrouping();
    this.setState({ getFilterGroups: resFilters.data.sort() });
    const mainCat = await this.props.subCategories();
    const getStores = await this.props.getStores();
    this.setState({ getBrand: getStores.data.sort() });
    console.log(this.state.getBrand);
    console.log(mainCat);
    this.setState({ getCategory: mainCat });
    this.setState({ loading: false });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  //change in value  click event
  async handleAttributeChange(e) {
    const { value } = e.target;
    if (value) {
      this.setState({ attrLoading: true });
      const resText = await this.props.getAttributeText(value);

      if (resText.data[0]) {
        this.setState({ getAttributeText: resText.data[0].text.sort() });
      } else {
        this.setState({
          getAttributeText: ["No value Available in this Attribute"],
        });
        this.setState({ attrLoading: false });
      }
    }
    this.setState({ attributeId: value });
    this.setState({ attrLoading: false });

    console.log(this.state.attributeId);
  }

  textToggler = () => {
    this.setState({
      status: !this.state.status,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  //change in value catagoryData click event
  handleCategoryDataChange = async (e, index) => {
    const list = [...this.state.categoryData];

    if (e[0]) {
      list[index]["imageName"] = e[0].name;

      const base64Image = await this.toBase64(e[0]);
      console.log(e[0].name);
      list[index]["base64Image"] = base64Image;

      this.setState({ categoryData: list });
    } else if (e.length !== 0 && e.target.name === "categoryId") {
      const { name, value } = e.target;
      list[index][name] = value;

      this.setState({ categoryData: list });
    } else if (e.length === 0) {
      list[index]["base64Image"] = "";
      list[index]["imageName"] = "";

      this.setState({ categoryData: list });
    }
  };

  // handle categoryData remove click event
  handleCategoryDataRemove = (index) => {
    const list = [...this.state.categoryData];
    list.splice(index, 1);
    this.setState({ categoryData: list });
  };

  // handle categoryData add
  handleCategoryDataAdd = () => {
    this.setState({
      categoryData: [
        ...this.state.categoryData,
        { categoryId: "", base64Image: "", imageName: "" },
      ],
    });
  };

  _submitHandler = async () => {
    this.setState({
      submitLoading: true,
    });
    const {
      title,
      status,
      attributeId,
      attributeText,
      width,
      height,
      groupId,
      categoryData,
      // eslint-disable-next-line no-unused-vars
      pictures,
      brandData,
    } = this.state;
    console.log(this.state);
    let imgCount = 0;
    categoryData.map((item) => {
      if (item.base64Image) {
        imgCount = imgCount + 1;
      }
    });
    brandData.map((item) => {
      if (item.base64Image) {
        imgCount = imgCount + 1;
      }
    });
    const dataToSend = {
      title,
      status,
      pictureWidth: width,
      pictureHeight: height,
      attributeId: attributeId,
      selectedAttribute: attributeText,
      filterGroupId: groupId,
      categoryData,
      imgCount,
      brandData,
    };

    if (groupId === "" && attributeId === "" && attributeText === "") {
      toast(`please select all options`, { containerId: "B" });
    } else {
      const res = await this.props.createFilterItem(JSON.stringify(dataToSend));
      console.log(res);
      this.setState({
        submitLoading: false,
      });

      if (res) {
        this.setState({});
        toast(`${res.data.message}`, { containerId: "B" });
      } else {
        toast(`Some Error Occur`, { containerId: "B" });
      }
      if (res) {
        this.props.navigate("/admin/view-filter-item/1");
      }
    }
  };

  //change in value brandData click event
  handleBrandDataChange = async (e, index) => {
    const list = [...this.state.brandData];

    if (e[0]) {
      list[index]["imageName"] = e[0].name;

      const base64Image = await this.toBase64(e[0]);
      console.log(e[0].name);
      list[index]["base64Image"] = base64Image;

      this.setState({ brandData: list });
    } else if (e.length !== 0 && e.target.name === "brandId") {
      const { name, value } = e.target;
      list[index][name] = value;

      this.setState({ brandData: list });
    } else if (e.length === 0) {
      list[index]["base64Image"] = "";
      list[index]["imageName"] = "";

      this.setState({ brandData: list });
    }
  };

  // handle BrandData remove click event
  handleBrandDataRemove = (index) => {
    const list = [...this.state.brandData];
    list.splice(index, 1);
    this.setState({ brandData: list });
  };

  // handle brandData add
  handleBrandDataAdd = () => {
    this.setState({
      brandData: [
        ...this.state.brandData,
        { brandId: "", base64Image: "", imageName: "" },
      ],
    });
  };

  render() {
    const {
      title,
      status,
      attributeId,
      attributeText,
      width,
      height,
      categoryData,
      groupId,
    } = this.state;
    console.log(this.state.categoryData);

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Create Filter Item</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create Filter Item{" "}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Alert />
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-md-3">
                  <p>Title</p>
                </div>
                <div className="col-md-3">
                  <p>Status</p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control   "
                    id="title"
                    placeholder="Enter Title"
                    name="title"
                    value={title}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 ">
                  <div className="connection-all" onClick={this.textToggler}>
                    <span className={status ? "allcenter" : "allLeft "}>
                      ON
                    </span>

                    <span className={status ? "editRight" : "editcenter "}>
                      OFF
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-4 mb-4">
              <div className="row">
                <div className="col-md-4">Assign a filter group</div>
                <div className="col-md-4">Select a Attribute</div>
                <div className="col-md-4">Select a value for attribute</div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  {!this.state.loading ? (
                    <select
                      name="groupId"
                      className="form-control"
                      value={groupId}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="default">Choose</option>
                      {this.state.getFilterGroups &&
                        this.state.getFilterGroups.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </div>
                <div className="col-md-4">
                  {!this.state.loading ? (
                    <select
                      name="attributeId"
                      className="form-control"
                      value={attributeId}
                      onChange={(e) => this.handleAttributeChange(e)}
                      required
                    >
                      <option value="default">Choose</option>
                      {this.state.getAttributes &&
                        this.state.getAttributes.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </div>
                {!this.state.attrLoading ? (
                  <div className="col-md-4">
                    {attributeId ? (
                      <select
                        name="attributeText"
                        className="form-control"
                        value={attributeText}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="default">Choose</option>
                        {this.state.getAttributeText &&
                          this.state.getAttributeText.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <p>Select a attribute first</p>
                    )}
                  </div>
                ) : (
                  <Spinner animation="border" variant="primary" />
                )}
              </div>
            </div>
            <div className="container-fluid mb-4 mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4>Pictures Configuration</h4>
                </div>
                <div className="col-md-4">
                  {" "}
                  <input
                    type="number"
                    className="form-control   "
                    id="width"
                    placeholder="width"
                    name="width"
                    value={width}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    className="form-control   "
                    id="height"
                    placeholder="height"
                    name="height"
                    value={height}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          className=" col-form-label   bold-text labelColor"
                          style={{ marginLeft: "12px" }}
                        >
                          Select Category
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label
                          className=" col-form-label   bold-text labelColor"
                          style={{ marginLeft: "12px" }}
                        >
                          image
                        </label>
                      </div>
                    </div>
                  </div>
                  {categoryData.map((x, i) => (
                    <div className="container-fluid mt-2" key={i}>
                      <div className="row">
                        <div className="col-md-4">
                          {!this.state.loading ? (
                            <select
                              name="categoryId"
                              className="form-control"
                              value={x.categoryId}
                              onChange={(e) =>
                                this.handleCategoryDataChange(e, i)
                              }
                              placeholder="enter category name"
                            >
                              <option value="default">choose</option>
                              {this.state.getCategory &&
                                this.state.getCategory.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <Spinner animation="border" variant="primary" />
                          )}
                        </div>
                        <div className="col-md-4">
                          <ImageUploader
                            buttonText="Choose images"
                            onChange={(e) =>
                              this.handleCategoryDataChange(e, i)
                            }
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                            name="image"
                          />
                        </div>
                        <div className="col-md-2 ">
                          <div className="d-flex">
                            {categoryData.length !== 1 && (
                              <button
                                type="button"
                                className="minusBtn actionBtn "
                                onClick={() => this.handleCategoryDataRemove(i)}
                              >
                                <div class="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {categoryData.length - 1 === i && (
                              <button
                                type="button"
                                className="plusBtn actionBtn ml-2"
                                onClick={this.handleCategoryDataAdd}
                              >
                                <div className="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-6">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          className=" col-form-label   bold-text labelColor"
                          style={{ marginLeft: "12px" }}
                        >
                          Select Brand
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label
                          className=" col-form-label   bold-text labelColor"
                          style={{ marginLeft: "12px" }}
                        >
                          image
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.brandData.map((x, i) => (
                    <div className="container-fluid mt-2" key={i}>
                      <div className="row">
                        <div className="col-md-4">
                          {!this.state.loading ? (
                            <select
                              name="brandId"
                              className="form-control"
                              value={x.brandId}
                              onChange={(e) => this.handleBrandDataChange(e, i)}
                              placeholder="enter brand name"
                            >
                              <option value="default">choose</option>
                              {this.state.getBrand &&
                                this.state.getBrand.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.fullName}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <Spinner animation="border" variant="primary" />
                          )}
                        </div>
                        <div className="col-md-4">
                          <ImageUploader
                            buttonText="Choose images"
                            onChange={(e) => this.handleBrandDataChange(e, i)}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                            name="image"
                          />
                        </div>
                        <div className="col-md-2 ">
                          <div className="d-flex">
                            {this.state.brandData.length !== 1 && (
                              <button
                                type="button"
                                className="minusBtn actionBtn "
                                onClick={() => this.handleBrandDataRemove(i)}
                              >
                                <div class="icon-minus actionBtn-icon minus">
                                  {" "}
                                </div>
                              </button>
                            )}
                            {this.state.brandData.length - 1 === i && (
                              <button
                                type="button"
                                className="plusBtn actionBtn ml-2"
                                onClick={this.handleBrandDataAdd}
                              >
                                <div className="icon-plus actionBtn-icon plus">
                                  {" "}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-md-12 text-center mt-3 mb-3">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      this._submitHandler();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Spinner animation="border" variant="danger" />
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

getAttributeText.propTypes = {
  getAttributeText: PropTypes.func.isRequired,
};

getAttribute.propTypes = {
  getAttribute: PropTypes.func.isRequired,
};
getFilterGrouping.propTypes = {
  getFilterGrouping: PropTypes.func.isRequired,
};
createFilterItem.propTypes = {
  createFilterItem: PropTypes.func.isRequired,
};
subCategories.propTypes = {
  subCategories: PropTypes.func.isRequired,
};
getStores.propTypes = {
  getStores: PropTypes.func.isRequired,
};

export default withRouter(connect(null, {
  getAttributeText,
  getAttribute,
  getFilterGrouping,
  createFilterItem,
  subCategories,
  getStores,
})(FilterItemCreate));
