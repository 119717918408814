export default function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }
  return string;
}

export const stringConvertToLength = (str, n) => {
  if (str?.length > n) {
    return str.substring(0, n - 1) + "...";
  } else {
    return str;
  }
};
