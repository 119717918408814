import style from "../price-card.module.css";

export const InfoItemComponent = ({ label, value }) => {
  return (
    <div className={style.infoItem}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};

export const CrawlType = ({ crawlType, CrawlDescription }) => {
  return (
    <div className={style.crawlType}>
      <p className={style.crawlContent}>{crawlType}</p>
      <p className={style.crawlContent}>{CrawlDescription}</p>
    </div>
  );
};
