import axios from "axios";
import { apiURL } from "../../config/default";

export const getChildSubCategories = async () => {
  try {
    const res = await axios.get(`${apiURL}admin/get-all-child-subcategories`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
