import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

const VendorRow = ({
  children,
  name,
  className,
  editShow,
  subTitle,
  handleEdit,
  deleteBtn,
  handleDelete,
}) => {
  return (
    <div className="row-container">
      <div className="title text-muted">
        {name}
        {subTitle && (
          <>
            <br />
            <p className=" text-black-50  font-weight-medium">{subTitle}</p>
          </>
        )}
      </div>
      <div className={`feild ${className}`}>{children}</div>
      {editShow && (
        <div
          onClick={() => handleEdit}
          style={{ borderRadius: "68px", padding: "8px 12px" }}
          className=" border-grey c-pointer"
        >
          <FaPencilAlt />
        </div>
      )}
      {deleteBtn && (
        <div
          onClick={() => handleDelete()}
          style={{ borderRadius: "68px", padding: "8px 12px" }}
          className=" border-grey c-pointer bg-danger"
        >
          <AiOutlineDelete className=" text-white" />
        </div>
      )}
    </div>
  );
};

export default VendorRow;
