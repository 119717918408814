import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create =
  ({ title, pricePercentage, code, from, to, brand, category, product }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    const body = JSON.stringify({
      title,
      pricePercentage,
      code,
      from,
      to,
      brand,
      category,
      product,
    });

    try {
      const res = await axios.post(
        `${apiURL}admin/create-coupon`,
        body,
        _config
      );
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };

export const retreive = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/coupon/${id}`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const edit =
  ({ title, pricePercentage, code, from, to, brand, category, product, id }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = localStorage.getItem("token");
    if (token) {
      setAuthHeader(token);
    }

    const body = JSON.stringify({
      couponId: id,
      title,
      pricePercentage,
      code,
      from,
      to,
      brand,
      category,
      product,
    });

    try {
      const res = await axios.post(`${apiURL}admin/edit-coupon`, body, _config);
      return res;
    } catch (err) {
      const errors = err.response.data.error;

      if (errors) {
        errors.forEach((error) => {
          dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
        });
      }
    }
  };

export const coupons = (e) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${apiURL}admin/coupons`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const deleteCoupon = (id) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(`${apiURL}admin/delete-coupon/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
export const getAllCoupon = (pageNumber, search) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.get(
      `${apiURL}admin/coupons?page=${pageNumber ? pageNumber : ""}`
    );
    return res?.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
