import { SwipeableDrawer } from "@mui/material";
import React from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

const EditTabelDrawer = ({ Edit, setEdit, children, shippingId }) => {
  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={Edit.open}
        onClose={() =>
          setEdit({
            open: false,
            edit: "",
            title: "",
          })
        }
        onOpen={() => {}}
      >
        <div className=" p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className=" font-weight-bold">{Edit?.title}</h4>
            <h4
              onClick={() =>
                setEdit({
                  open: false,
                  edit: "",
                  title: "",
                })
              }
            >
              <IoCloseCircleSharp
                style={{
                  fontSize: "22px",
                  color: "#fb2a3d",
                  cursor: "pointer",
                }}
              />
            </h4>
          </div>
          {Edit.edit !== "notes" && <p>{shippingId}</p>}
          {Edit.edit === "orderStatus" && (
            <span style={{ fontSize: "12px" }}>
              (Please confirm{" "}
              <strong>Shipping & Tracking, Fraud, & sale Analysis</strong>{" "}
              before making available)
            </span>
          )}
        </div>
        <hr />
        {children}
      </SwipeableDrawer>
    </>
  );
};

export default EditTabelDrawer;
