import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ios-radio-button/ToggleSwitch";
import UpdateButton from "./UpdateButton";
import { setVendorCashback } from "../../../../services/vendor/vendor.service";

const ManageCashback = ({ selectedItem, isRender, setIsRender }) => {
  const [formData, setFormData] = useState({
    cashbackPercentage: selectedItem?.cashbackPercentage,
  });
  const [isGlobal, setIsGlobal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (id) => {
    setLoading(true);
    try {
      const res = await setVendorCashback({
        id,
        ...formData,
      });
      if (res) {
        setLoading(false);
        setIsRender(!isRender);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    if (Number(e.target.value) === 0) {
      setIsGlobal(true);
      setFormData({
        ...formData,
        cashbackPercentage: 0,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: Number(e.target.value),
      });
      setIsGlobal(false);
    }
  };

  return (
    <div className="  d-flex flex-column gap-10">
      <div className=" px-3 d-flex justify-content-between align-center gap-5">
        <span>Use Global cashback Percentage</span>
        <span>
          <ToggleSwitch
            checked={isGlobal}
            onChange={(e) => {
              setIsGlobal(
                e.target.checked === true
                  ? setFormData({ ...formData, cashbackPercentage: 0 })
                  : setFormData({
                      ...formData,
                      cashbackPercentage: selectedItem?.cashbackPercentage,
                    })
              );
            }}
            Name={"cashbackPercentage"}
          />
        </span>
      </div>
      <hr />
      <div className="d-flex justify-content-between align-center px-3">
        <div>Vendor Cashback Percentage</div>
        <div>
          <input
            className=" p-2 border-radius-10 border-grey  w-50"
            type="number"
            min={0}
            name="cashbackPercentage"
            value={formData?.cashbackPercentage}
            onChange={(e) => handleChange(e)}
          />
          %
        </div>
      </div>
      <UpdateButton
        position="fixed"
        brand={selectedItem?.fullName}
        loading={loading}
        onClick={() => handleUpdate(selectedItem?._id)}
      />
    </div>
  );
};

export default ManageCashback;
