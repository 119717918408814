/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { toast } from 'react-toastify';
import styles from './carouselSection.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import { apiURL } from '../../../../../config/default';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CarouselSection({
  section,
  handleShow,
  selectedIndex,
  show,
  handleClose,
  handleFileChange,
  index,
  handleChangeText,
  mainIndex,
  removeSection,
  handleAddValueAuto,
  toggleLoading,
  handleParent,
}) {
  const [_value, setValue] = useState(0);
  const [products, setproducts] = useState([]);
  const [customSlide, setCustomSlide] = useState('');
  const [pastedUrl, setPastedUrl] = useState('');

  const [indexToChange, setindexToChange] = useState(null);
  const [edit, setEdit] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = (text_position) => {
    setAnchorEl(null);
    handleParent(index, 'textPosition', text_position);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => setEdit(!edit);

  const getProducts = async (e) => {
    if (!section.shopNowLink)
      return toast.warning('Link is required', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    toggleLoading(true);

    try {
      let { data: products } = await axios.get(
        `${apiURL}header/header-scrap?url=${section.shopNowLink}&limit=${
          section.limit
        }&random=${!!section.autoPopulate}`
      );

      products = products.map((product) => ({
        link: product.href,
        url: product.image,
        desc: product.text,
      }));

      handleAddValueAuto(products, index);
    } catch (error) {
      toast.warning('Something went wrong, please try again', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      toggleLoading(false);
    }
  };

  const handleAutoPopulate = (e) => {
    handleParent(index, 'autoPopulate', e.target.checked);
  };

  return (
    <div className={`d-flex ${section.values.length > 0 ? 'w-100' : ''}`}>
      <div>
        <div
          className={`d-flex align-items-center justify-content-${section?.textPosition}`}
        >
          {edit ? (
            <div className="d-flex justify-content-center align-items-center px-2">
              <input
                type="text"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleEdit();
                    handleParent(index, 'title', e.target.value);
                  }
                }}
                name="title"
                defaultValue={section?.values[1]?.title || ''}
                onBlur={(e) => {
                  handleEdit();
                  handleParent(index, 'title', e.target.value);
                }}
                className={styles.titleInput}
                autoFocus={true}
                placeholder="Title"
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <h2 className="text-center">
                {section?.title ? (
                  <span>
                    {section?.title} <a href={section?.shopNowLink}>Shop now</a>
                  </span>
                ) : (
                  <span style={{ opacity: 0.7 }}> Title (optional) </span>
                )}
              </h2>
              <i
                className={`${styles.actionBtn} ${styles.hoverPrimary} p-2  icon-pencil`}
                onClick={() => handleEdit()}
                title="edit"
              ></i>
            </div>
          )}
        </div>
        <Carousel
          centerMode={section.isMultiSlide}
          centerSlidePercentage={section.isMultiSlide ? 20 : 100}
          className={styles.homePCarousel}
          showThumbs={false}
          swipeable
          useKeyboardArrows
        >
          {section.values.map((value, _index) => (
            <div
              className={`d-flex align-items-center w-100 h-100 ${styles.CarMainImgContainer}`}
              style={{ position: 'relative' }}
            >
              <img
                src={value.url}
                width="100%"
                className={section.isMultiSlide && styles.carouselImg}
                alt="carousel"
              />
              <div
                style={{ position: 'absolute', top: '5px', left: '5px' }}
                className={`bg-white d-flex align-items-center ${styles.h92}`}
              >
                <i
                  className={`${styles.actionBtn} ${styles.hoverPrimary} p-2  icon-pencil`}
                  onClick={() => handleShow(_index, index)}
                ></i>
                <i
                  className={`${styles.actionBtn} ${styles.hoverWarning} p-2  icon-close`}
                  onClick={() => removeSection(index, _index)}
                ></i>
              </div>

              <Modal
                show={show && _index === selectedIndex && mainIndex === index}
                onHide={handleClose}
              >
                <Modal.Header closeButton centered>
                  <Modal.Title>Edit Product Slide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Tabs
                    value={_value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Custom" />
                  </Tabs>

                  <TabPanel value={_value} index={0}>
                    <div className="d-flex flex-column justify-content-center mb-3">
                      <>
                        <label>Main Image</label>
                        <img src={value.url} alt="preview" width="400px" />
                      </>
                      <br />
                      <>
                        <label>Tablet Image</label>
                        <img
                          src={value.mediumUrl || value.url}
                          alt="preview"
                          width="400px"
                        />
                      </>
                      <>
                        <label>Mobile Image</label>
                        <img
                          src={value.smallUrl || value.mediumUrl || value.url}
                          alt="preview"
                          width="400px"
                        />
                      </>
                    </div>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>
                        Upload Image
                        <span className="ml-2 dimension__text">
                          (1600 × 400 px)
                        </span>
                      </Form.Label>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index);
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Main Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>
                        Upload Medium Screen Image{' '}
                        {/* <span className="ml-2 dimension__text">
                          (1600 × 400 px)
                        </span> */}
                      </Form.Label>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index, 'mediumUrl');
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Medium Screen Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicimage" className="m-2">
                      <Form.Label>
                        Upload Small Screen Image{' '}
                        {/* <span className="ml-2 dimension__text">
                          (1600 × 400 px)
                        </span> */}
                      </Form.Label>
                      <Form.File
                        id="custom-file"
                        label="Add image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e, index, _index, 'smallUrl');
                        }}
                        custom
                      />
                      <Form.Text className="text-muted">
                        Small Screen Carousel Image
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicdesc" className="m-2">
                      <Form.Label>Image Alt</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type Image Alt"
                        defaultValue={value.alt || ""}
                        name="alt"
                        onChange={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        Description related to product
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasiclink" className="m-2">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="paste link"
                        defaultValue={value.link}
                        name="link"
                        onBlur={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        This Link will redirect to product page or category page
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicdesc" className="m-2">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        defaultValue={value.desc}
                        name="desc"
                        onBlur={(e) => handleChangeText(e, index, _index)}
                      />
                      <Form.Text className="text-muted">
                        Description related to product
                      </Form.Text>
                    </Form.Group>
                  </TabPanel>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ))}
        </Carousel>
      </div>
      <>
        <div className={`flex flex-column bg-white text-center ${styles.h93}`}>
          <i
            className={`${styles.actionBtn} ${styles.hoverWarning} d-block mb-2 icon-close`}
            onClick={() => removeSection(index)}
            title="delete"
            style={{ height: 'fit-content' }}
          ></i>
          <i
            className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-plus`}
            onClick={() => {
              setindexToChange(section.values.length);
              setCustomSlide('custom');
              handleShow(-1, index);
            }}
            title="Add"
            style={{ height: 'fit-content' }}
          ></i>
          <i
            className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-layers`}
            onClick={() => {
              if (!section.values?.length) {
                toast.error('Add Slide to toggle this option', {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                return;
              }
              handleParent(index, 'isMultiSlide', !section?.isMultiSlide);
            }}
            title="Multi slide Mode"
            style={{
              height: 'fit-content',
              color: section.isMultiSlide && '#17a2b8',
              transform: 'rotate(90deg)',
            }}
          ></i>
          <i
            onClick={() => {
              setCustomSlide('auto');
              handleShow(-1, index);
            }}
            className={`${styles.actionBtn} ${styles.hoverPrimary} d-block mb-2 icon-magic-wand`}
          ></i>
          <div className={styles.p0Imp}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              title="Set title alignment"
            >
              <i className="icon-options-vertical"></i>
            </IconButton>
          </div>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => _handleClose(section.textPosition || 'left')}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
            className="text-center"
          >
            <span>Title alignment</span>
            <MenuItem
              onClick={() => _handleClose('left')}
              className="text-center"
            >
              <span className="mx-auto">Left</span>
            </MenuItem>
            <MenuItem
              onClick={() => _handleClose('center')}
              className="text-center"
            >
              <span className="mx-auto">center</span>
            </MenuItem>
            <MenuItem
              onClick={() => _handleClose('end')}
              className="text-center"
            >
              <span className="mx-auto">Right</span>
            </MenuItem>
          </Menu>
        </div>
        <Modal
          show={show && mainIndex === index && selectedIndex === -1}
          onHide={handleClose}
        >
          <Modal.Header closeButton centered>
            <Modal.Title>Add Product Slide</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              value={_value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {customSlide === 'auto' ? (
                <Tab label="Auto" />
              ) : (
                <Tab label="Custom" />
              )}
            </Tabs>
            {customSlide === 'auto' && (
              <TabPanel value={_value} index={0}>
                <Form.Group
                  controlId="formBasiclink"
                  className={`m-2 ${styles.popUpCarousel} my-2`}
                >
                  <label>Paste link</label>
                  <input
                    label="paste link"
                    className={styles.numberInp}
                    value={section.shopNowLink}
                    onChange={(e) =>
                      handleParent(index, 'shopNowLink', e.target.value)
                    }
                  />
                  <div className="mt-3">
                    <label>Limit</label>
                    <input
                      className={styles.numberInp}
                      type="number"
                      min="3"
                      max="10"
                      value={section.limit}
                      onChange={(e) =>
                        handleParent(index, 'limit', e.target.value)
                      }
                    />
                  </div>
                  <div className="mt-3">
                    <label> Auto Populate </label>
                    <div className="auto_populate_carousel">
                      <div
                        className={`d-flex align-items-center ${styles.numberInp}`}
                      >
                        <input
                          type="checkbox"
                          className={styles.autoPopInp}
                          id="autoPopulate"
                          checked={section.autoPopulate}
                          onClick={(e) => handleAutoPopulate(e)}
                        />
                        <label className="mb-0 pl-3" htmlFor="autoPopulate">
                          Randomize
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label>
                      Time to refresh (Hours(0-24) / Minutes(0-60) / Days(0-30))
                    </label>
                    <div class="d-flex">
                      <input
                        className={styles.numberInp}
                        type="number"
                        min="1"
                        max="24"
                        value={section?.timeToRefresh?.hours}
                        onChange={(e) => {
                          if (e.target.value > 24) return;
                          handleParent(index, 'timeToRefresh', {
                            ...section.timeToRefresh,
                            hours: Number(e.target.value),
                          });
                        }}
                        style={{ borderRadius: '2px 0 0 2px' }}
                      />
                      <input
                        className={styles.numberInp}
                        type="number"
                        min="1"
                        max="24"
                        value={section?.timeToRefresh?.minutes}
                        onChange={(e) => {
                          if (e.target.value > 60) return;
                          handleParent(index, 'timeToRefresh', {
                            ...section.timeToRefresh,
                            minutes: Number(e.target.value),
                          });
                        }}
                        style={{ borderRadius: '0' }}
                      />
                      <input
                        className={styles.numberInp}
                        type="number"
                        min="1"
                        max="24"
                        value={section?.timeToRefresh?.days}
                        onChange={(e) => {
                          if (e.target.value > 30) return;
                          handleParent(index, 'timeToRefresh', {
                            ...section.timeToRefresh,
                            days: Number(e.target.value),
                          });
                        }}
                        style={{ borderRadius: '0 2px 2px 0' }}
                      />
                    </div>
                  </div>
                  <div className="my-3 text-center">
                    <button onClick={getProducts} className="btn btn-primary">
                      Get Data
                    </button>
                  </div>

                  <Form.Text className="text-muted">
                    <h5>Slides</h5>
                    {section.values.map((value, _index) => (
                      <div className="d-flex align-items-center">
                        <div>
                          <img src={value.url} alt="" width="124px" />
                        </div>
                        <div>{value.slug || value.name || value.link}</div>
                        <i
                          onClick={() => removeSection(index, _index)}
                          title="Remove"
                          className={`${styles.actionBtn} ${styles.hoverWarning}  d-block mb-2 icon-trash`}
                        ></i>
                      </div>
                    ))}
                  </Form.Text>
                </Form.Group>
              </TabPanel>
            )}
            {customSlide === 'custom' && (
              <TabPanel value={_value} index={0}>
                {section.values?.[indexToChange]?.url && (
                  <div className="d-flex flex-column justify-content-center mb-3">
                    <>
                      <label>Main Image</label>

                      <img
                        src={section.values[indexToChange]?.url}
                        alt="preview"
                        width="400px"
                      />
                    </>
                    <br />
                    <>
                      <label>Tablet Image</label>
                      <img
                        src={
                          section.values[indexToChange]?.mediumUrl ||
                          section.values[indexToChange]?.url
                        }
                        alt="preview"
                        width="400px"
                      />
                    </>
                    <>
                      <label>Mobile Image</label>
                      <img
                        src={
                          section.values[indexToChange]?.smallUrl ||
                          section.values[indexToChange]?.mediumUrl ||
                          section.values[indexToChange]?.url
                        }
                        alt="preview"
                        width="400px"
                      />
                    </>
                  </div>
                )}
                <Form.Group controlId="formBasicimage" className="m-2">
                  <Form.Label>Upload Image </Form.Label>
                  <Form.File
                    id={index}
                    label="Add image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleFileChange(e, index, indexToChange);
                    }}
                    custom
                  />
                </Form.Group>
                <Form.Group controlId="formBasicimage" className="m-2">
                  <Form.Label>Upload Medium Screen Image </Form.Label>
                  <Form.File
                    id="custom-file"
                    label="Add image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleFileChange(e, index, indexToChange, 'mediumUrl');
                    }}
                    custom
                  />
                  <Form.Text className="text-muted">
                    Medium Carousel Image
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicimage" className="m-2">
                  <Form.Label>Upload Small Screen Image </Form.Label>
                  <Form.File
                    id="custom-file"
                    label="Add image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleFileChange(e, index, indexToChange, 'smallUrl');
                    }}
                    custom
                  />
                  <Form.Text className="text-muted">
                    Small Carousel Image
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasiclink" className="m-2">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="paste link"
                    defaultValue={''}
                    name="link"
                    onBlur={(e) => handleChangeText(e, index, indexToChange)}
                  />
                  <Form.Text className="text-muted">
                    This Link will redirect to product page or category page
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicdesc" className="m-2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    defaultValue={''}
                    name="desc"
                    onBlur={(e) => handleChangeText(e, index, indexToChange)}
                  />
                  <Form.Text className="text-muted">
                    Description related to product
                  </Form.Text>
                </Form.Group>
              </TabPanel>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default CarouselSection;
