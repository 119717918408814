import React, { useState } from "react";
import { verifyCogs } from "../../../../../actions/saleAnalysis";
import { useDispatch } from "react-redux";

const COGsTable = ({ SelectItem, IsMount, setIsMount }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [updateValue, setupdateValue] = useState({
    itemPrice: SelectItem?.orCogs?.itemPrice ?? "",
    quantity: SelectItem?.orCogs?.quantity ?? "",
    type: SelectItem?.orCogs?.type ?? "",
    status: SelectItem?.orCogs?.status,
  });

  const updateVerifyCogs = async () => {
    setloading(true);
    try {
      const res = await dispatch(
        verifyCogs(SelectItem?._id, {
          orCogs: {
            itemPrice: updateValue?.itemPrice,
            quantity: updateValue?.quantity,
            type: updateValue?.type,
            status: true,
          },
        })
      );
      if (res) {
        setIsMount(!IsMount);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="my-3 px-3">
        <table className="cogs-table">
          <tbody>
            <tr>
              <th>Variable</th>
              <th>Default</th>
              <th>Overide</th>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">Unit Cost</span>
              </td>
              <td
                className={`${
                  SelectItem?.costType === "promo" ? "text-success" : ""
                }`}
              >
                ${SelectItem?.cost.toFixed(2)}
              </td>
              <td>
                <input
                  type="number"
                  name="itemPrice"
                  className={`border-0 no-spinners `}
                  value={updateValue?.itemPrice}
                  placeholder="Enter Price"
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">Cost Type</span>
              </td>
              <td>{SelectItem?.costType}</td>
              <td>
                <div>
                  <select
                    name="type"
                    value={updateValue?.type}
                    onChange={(e) =>
                      setupdateValue({
                        ...updateValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="standard">Standard</option>
                    <option value="promo">Promo</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span className=" font-weight-bold">Quantity</span>
              </td>

              <td>{SelectItem?.quantity}</td>
              <td>
                <input
                  className=" border-0"
                  type="text"
                  name="quantity"
                  max={SelectItem?.quantity}
                  min={1}
                  placeholder="Enter item Qty"
                  value={updateValue?.quantity}
                  required
                  // readOnly
                  onChange={(e) =>
                    setupdateValue({
                      ...updateValue,
                      [e.target.name]:
                        e.target.value > -1 &&
                        e.target.value <= SelectItem?.quantity
                          ? e.target.value
                          : "",
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <span className=" font-weight-bold">Total COGS</span>
              </td>

              <td>${(SelectItem?.cost * SelectItem?.quantity).toFixed(2)}</td>
              <td>
                ${(updateValue?.itemPrice * updateValue?.quantity).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <button
          onClick={() => updateVerifyCogs()}
          className="update-Order-button text-white bg-success mt-3 "
        >
          {loading ? "Loading " : "Verify COGS Override"}
        </button>
      </div>
    </>
  );
};

export default COGsTable;
