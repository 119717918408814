import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          Copyright © 2020{" "}
          <a
            href="https://sundialhomeproducts.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SunDial
          </a>
          . All rights reserved.
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          <a
            href="https://ooptechnologies.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            OOP Technologies{" "}
          </a>{" "}
          &amp; made with <i className="icon-heart text-danger" />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
