import React from "react";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { forgot } from "../../../actions/auth";

import TopBarProgress from "react-topbar-progress-indicator";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, email: "" };
  }

  async componentDidMount() {
    setTimeout(async () => {
      await this.setState({ loading: false });
    }, 1000);
  }
  _submitHandler = async () => {
    const { email } = this.state;
    this.setState({ loading: true });
    const res = await this.props.forgot({ email });
    this.setState({ loading: false });

    if (res) {
      this.setState({
        email: ""
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });

    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <img src="/assets/images/logo.svg" alt="logo" />
                  </div>
                  <h4>Hello! let's get account back</h4>
                  <h6 className="font-weight-light">
                    Enter Email to continue.
                  </h6>

                  <Alert />

                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={() => {
                          this._submitHandler();
                        }}
                        type="button"
                      >
                        Send E-mail
                      </button>
                    </div>

                    <div className="text-center mt-4 font-weight-light">
                      {" "}
                      Don't have an account?{" "}
                      <Link to={"/admin/register"} className="text-primary">
                        Register
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

forgot.propTypes = {
  forgot: PropTypes.func.isRequired
};

export default connect(null, { forgot })(Login);
