import { useState } from "react";

const SearchDateFilter = ({ placeholder, onChange, value, styles }) => {
  // eslint-disable-next-line no-unused-vars
  const [Date, setDate] = useState(value ?? "");

  const handleChange = (e) => {
    const date = e.target.value;
    const parts = date.split("-");
    const formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
    setDate(formattedDate);
    onChange(formattedDate);
  };

  return (
    <div className="search-date-filter-container" style={{ ...styles }}>
      <div className="d-flex justify-content-between align-center  ">
        <div>
          <p className="font-weight-bold">{placeholder}</p>
          <input
            type="date"
            id="date-icon-hide"
            // value={Date}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="search-date-body"></div>
    </div>
  );
};

export default SearchDateFilter;
