import React from "react";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import ImageUploader from "react-images-upload";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { retreive, edit } from "../../../actions/slide";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import { withRouter } from "../../../utils/withRouter";

class Edit extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      image: "",
      slideUrl: "",
      description: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.search.substr(4);
    const res = await this.props.retreive(id);
    this.setState({
      loading: false,
      name: res.name,
      slideUrl: res.slideUrl,
      image: res.image,
      description: res.description,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { name, image, description, slideUrl } = this.state;

    this.setState({ loading: true });

    const id = this.props.location.search.substr(4);

    let formData = new FormData();
    formData.append("image", image);
    formData.append("name", name);
    formData.append("slideId", id);
    formData.append("slideUrl", slideUrl);
    formData.append("description", description);

    const res = await this.props.edit(formData);

    this.setState({ loading: false });

    if (res) {
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  _handleImageChange = async (e) => {
    // e.preventDefault();
    console.log(e);
    const base64Image = await this.toBase64(e[0]);
    console.log(base64Image);
    this.setState({
      image: base64Image,
    });
  };
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  render() {
    const { name, description, slideUrl, image } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title"> Edit Slide </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/slides">Slides</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Slide
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit slide</h4>

                <Alert />

                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>

                  <div className="form-group">
                    <label>Image upload</label>

                    <div className="input-group col-xs-12">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Choose images"
                        onChange={(e) => this._handleImageChange(e)}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                        withPreview={true}
                      />
                    </div>
                    <div className="input-group col-xs-12">
                      <img
                        src={image && image}
                        alt=""
                        width="100"
                        height="50"
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Slide Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slideUrl"
                      placeholder="slide url"
                      name="slideUrl"
                      value={slideUrl}
                      onChange={(e) =>
                        this.setState({ slideUrl: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows={4}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </div>

                  <button
                    onClick={() => {
                      this._submitHandler();
                    }}
                    type="button"
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </button>
                  <Link to={"/admin/slides"}>
                  <button
                    className="btn btn-light"

                  >
                    Cancel
                  </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

edit.propTypes = {
  edit: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { retreive, edit })(Edit));
