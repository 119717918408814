import React from "react";
import { Link, Navigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../../store";
import { reset } from "../../../actions/auth";
import { alert } from "../../../actions/alert";

import TopBarProgress from "react-topbar-progress-indicator";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";

class ResetPassword extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { loading: true, password: "", confirmPassword: "" };
  }

  async componentDidMount() {
    this._isMounted = true;

    setTimeout(async () => {
      if (this._isMounted) {
        this.setState({
          loading: false,
          password: "",
          confirmPassword: ""
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const { password, confirmPassword } = this.state;

    this.setState({ loading: true });

    if (
      password === "" ||
      confirmPassword === "" ||
      password !== confirmPassword
    ) {
      this.setState({ loading: false });
      store.dispatch(
        alert("Password not match!", "danger", { marginTop: "3em" })
      );
      return;
    }

    const id = this.props.location.pathname.substr(13);

    const res = await this.props.reset({ id, password });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        password: "",
        confirmPassword: ""
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const { password, confirmPassword } = this.state;

    const { isAuth } = this.props.auth;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        "0": "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)"
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)"
    });

    return isAuth && !this.props.auth.loading ? (
      <Navigate exact to="/admin/dashboard" />
    ) : (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <img src="/assets/images/logo.svg" alt="logo" />
                  </div>
                  <h4>Hello! let's get account back</h4>
                  <h6 className="font-weight-light">
                    Enter new password below.
                  </h6>

                  <Alert />

                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Password Confirm"
                        value={confirmPassword}
                        onChange={e =>
                          this.setState({ confirmPassword: e.target.value })
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={() => {
                          this._submitHandler();
                        }}
                        type="button"
                      >
                        Change Password
                      </button>
                    </div>

                    <div className="text-center mt-4 font-weight-light">
                      {" "}
                      Already have an account?{" "}
                      <Link to={"/admin/login"} className="text-primary">
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

reset.propTypes = {
  reset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { reset })(ResetPassword);
