// this is for profit
export const Calculation = (order, returnValue) => {
  const OrderSubTotal =
    parseFloat(order?.itemPrice * Number(order?.quantity)) +
    order?.couponDiscount;
  const OrderTotal =
    OrderSubTotal + order?.shippingFee + order?.itemTax - order?.couponDiscount;

  const CCFee = parseFloat(OrderTotal).toFixed(2) * 0.03;
  const ShippingCost = parseFloat(
    order?.orShipping?.shippingFee || order?.orShipping?.shippingFee === 0
      ? order?.orShipping?.shippingFee * order?.orShipping?.quantity
      : Number(order?.shippingCost) * Number(order?.quantity) +
        order?.shippingFee
      ? (
          Number(order?.shippingCost) * Number(order?.quantity) +
          order?.shippingFee
        ).toFixed(2)
      : 0
  );
  const COGS = parseFloat(
    order?.orCogs?.itemPrice || order?.orCogs?.itemPrice === 0
      ? order?.orCogs?.itemPrice * order?.orCogs?.quantity
      : order?.cost
      ? order.cost * order?.quantity
      : 0
  );

  // cost breakDown Total free calcuation
  const shippingTotal =
    order?.orShipping?.shippingFee || order?.orShipping?.shippingFee === 0
      ? order?.orShipping?.shippingFee * order?.orShipping?.quantity
      : Number(order?.shippingFee) +
        Number(order?.shippingCost) * Number(order?.quantity);

  const dsFeeOneTotal =
    order?.orDsFees?.dsFeesOne || order?.orDsFees?.dsFeesOne === 0
      ? parseFloat(order?.orDsFees?.dsFeesOne) *
        parseFloat(order?.orDsFees?.dsFeesQuantityOne)
      : order?.dsFeesOne * Number(order?.quantity);

  const dsFeeTwoTotal =
    order?.orDsFees?.dsFeesTwo || order?.orDsFees?.dsFeesTwo === 0
      ? parseFloat(order?.orDsFees?.dsFeesTwo) *
        parseFloat(order?.orDsFees?.dsFeesQuantityTwo)
      : order?.dsFeesTwo * Number(order?.quantity);

  const DsFee = dsFeeOneTotal + dsFeeTwoTotal;
  // Tax calcuation
  const Tax = parseFloat(order?.itemTax ? order?.itemTax : 0);
  // For profit calculate
  const Profit =
    OrderTotal -
    CCFee -
    DsFee -
    COGS -
    ShippingCost -
    Tax -
    Number(order?.cashbackUsedPerItem);
  // For Profit Percent Calculation
  const ProfitPercentage = (Profit / OrderTotal) * 100;

  if (returnValue === "profit") {
    return parseFloat(Profit).toFixed(2);
  } else if (returnValue === "profitPercentage") {
    return ProfitPercentage.toFixed(2);
  } else if (returnValue === "COGS") {
    return parseFloat(COGS).toFixed(2);
  } else if (returnValue === "dsFee") {
    return parseFloat(DsFee).toFixed();
  } else if (returnValue === "ShippingCost") {
    return parseFloat(ShippingCost).toFixed(2);
  } else if (returnValue === "dsFeeOneTotal") {
    return parseFloat(dsFeeOneTotal).toFixed(2);
  } else if (returnValue === "dsFeeTwoTotal") {
    return parseFloat(dsFeeTwoTotal).toFixed(2);
  } else if (returnValue === "CC") {
    return parseFloat(CCFee).toFixed(2);
  } else if (returnValue === "shippingTotal") {
    return parseFloat(shippingTotal).toFixed(2);
  } else if (returnValue === "Tax") {
    return parseFloat(Tax).toFixed(2);
  } else if (returnValue === "OrderTotal") {
    return parseFloat(OrderTotal).toFixed(2);
  } else if (returnValue === "OrderSubTotal") {
    return parseFloat(OrderSubTotal).toFixed(2);
  }
};
