import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = (formData) => async (dispatch) => {
  const _config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(`${apiURL}admin/bulk-mail`, formData, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach((error) => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
