import React from "react";

const TicketsTable = ({ Data, type }) => {
  return (
    <div className="recently-view-table">
      <table className=" w-100">
        <thead>
          <tr>
            <th>Ticket#</th>
            <th>Item</th>
            <th>Type</th>
            <th>Status</th>
            <th>Notification</th>
          </tr>
        </thead>
        <tbody>
          {!Data?.length && (
            <tr>
              <td colSpan={5}> No Tickets Found</td>
            </tr>
          )}
          {Data?.map((item, index) => (
            <tr key={index}>
              <td>{item?.ticketId}</td>

              <td>
                <div className="table-image-container bg-cream ">
                  <img
                    src={item?.orderItemId?.mainImage}
                    alt="Product imaghe"
                  />
                </div>
              </td>
              <td>{item?.reason}</td>
              <td
                className={
                  item?.status === "open" ? "text-success" : " text-muted"
                }
              >
                {item?.status}
                {type === "EscalatedTickets" ? (
                  <>
                    <br /> <span style={{ color: "#ff6a49" }}>Escalated</span>
                  </>
                ) : (
                  ""
                )}
              </td>
              <td>
                {item?.lastMessage === "user" ? (
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      border: "1px solid #ffc946",
                      background: "#fff8e6",
                      color: "#ffc946",
                    }}
                    className=" mx-auto  border-radius-5 d-flex justify-content-center align-center "
                  >
                    !
                  </div>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TicketsTable;
