import axios from "axios";
import { apiURL } from "../config/default";

import { alert } from "./alert";
import setAuthHeader from "../utils/setAuthHeader";

export const create = formData => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/create-slide`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const retreive = id => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.get(`${apiURL}admin/slide/${id}`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const edit = formData => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    setAuthHeader(token);
  }

  try {
    const res = await axios.post(
      `${apiURL}admin/edit-slide`,
      formData,
      _config
    );
    return res;
  } catch (err) {
    const errors = err.response.data.error;

    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};

export const slides = e => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.get(`${apiURL}admin/slides`, _config);
    return res.data;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "3em" }));
      });
    }
  }
};

export const deleteSlide = id => async dispatch => {
  const _config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.get(`${apiURL}admin/delete-slide/${id}`, _config);
    return res;
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      errors.forEach(error => {
        dispatch(alert(error.msg, "danger", { marginTop: "2em" }));
      });
    }
  }
};
