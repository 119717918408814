import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { banners, deleteBanner } from "../../../actions/banner";

import TopBarProgress from "react-topbar-progress-indicator";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class Banner extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      banners: null,
      deleteConfirm: false,
    };
  }

  async componentDidMount() {
    const res = await this.props.banners();
    this.setState({ banners: res, loading: false });
  }

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteBanner = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteBanner(id);
    const res = await this.props.banners();
    this.setState({ loading: false, deleteConfirm: false, banners: res });
  };

  render() {
    const { loading, banners } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <Sidebar show={"banner"} />
          {banners && (
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title"> View All Banners</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/banners">Banners</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        View
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body table-responsive">
                        <h4 className="card-title">Banners List</h4>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th> # </th>
                              <th> Image</th>
                              <th> Banner Url </th>
                              <th> Created At </th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {banners && banners.length === 0 ? (
                              <tr className="text-center">
                                <td colSpan="6">No banners found</td>
                              </tr>
                            ) : (
                              banners.map((item, index) => (
                                <tr key={index}>
                                  <td> {index + 1} </td>
                                  <td>
                                    <img src={item.images[0]} alt="product " />{" "}
                                  </td>
                                  <td>{item.bannerUrl}</td>
                                  <td>
                                    {" "}
                                    <Moment format="DD/MM/YYYY">
                                      {item.createdAt}
                                    </Moment>{" "}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin/edit-banner/?id=${item._id}`}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm mr-4"
                                        style={{ width: 78 }}
                                      >
                                        Edit
                                      </button>
                                    </Link>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      type="button"
                                      onClick={() => {
                                        this.setState({ deleteConfirm: true });
                                      }}
                                    >
                                      Delete
                                    </button>

                                    {this.state.deleteConfirm && (
                                      <SweetAlert
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, delete it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => {
                                          this._deleteBanner(item._id);
                                        }}
                                        onCancel={this._onCancel}
                                        focusCancelBtn
                                      >
                                        Are you sure you want to delete?
                                      </SweetAlert>
                                    )}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          )}
        </div>
      </div>
    );
  }
}

banners.propTypes = {
  banners: PropTypes.func.isRequired,
};

deleteBanner.propTypes = {
  deleteBanner: PropTypes.func.isRequired,
};

export default connect(null, { banners, deleteBanner })(Banner);
