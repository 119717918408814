const types = {
  HEROBANNER: "Hero Banner",
  CAROUSEL: "carousel",
  RECENTLYVIEW: "Recently View",
  SHOPBYDEPARTMENT: "shop by department",
  "12XBANNER": "Banners",
  EXPLOREBRANDS: "Explore brands",
  "6X6XBANNER": "Single Room",
  "6X6XHEROBANNER": "Two Cards",
  FEATURES: "Features",
  CONTENT: "CONTENT",
};
export default types;
