import React from 'react'
import { PropTypes } from "prop-types";

const Search = (props) => {


    const { placeholder, title, handleChange } = props
    return (
        <div >
            <p className="h4 mt-3 font-weight-bold">{title}</p>
            <div className="input-group mt-4 ">

                <input type="text" placeholder={placeholder} onChange={(e) => {
                    e.preventDefault();
                    handleChange(e.target.value)
                }}
                    className="form-control  bg-light text-center"
                    style={{ borderRadius: "200px" }} />

            </div>
        </div>
    )
}

Search.propTypes = {
    placeholder: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
}

export default Search
