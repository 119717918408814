/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import TopBarProgress from "react-topbar-progress-indicator";

import Navbar from "../../../components/navbar/navbar.component";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Footer from "../../../components/footer/footer.component";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { create, retreive } from "../../../actions/vendorSetting";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "../../../components/alert/alert.component";
import { BOLD } from "draft-js/lib/DefaultDraftInlineStyle";

class VendorSetting extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      commissionCalculateType: "",
      refundCalculate: "",
      commission: "",
      shippingCommission: "",
      manageOrderByVendor: true,
      sellerApproval: true,
      productApprovel: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const _res = await this.props.retreive();
    const {
      commissionCalculateType,
      refundCalculate,
      commission,
      shippingCommission,
      manageOrderByVendor,
      sellerApproval,
      productApprovel,
    } = _res.data;
    if (this._isMounted) {
      this.setState({
        loading: false,
        commissionCalculateType,
        refundCalculate,
        commission,
        shippingCommission,
        manageOrderByVendor,
        sellerApproval,
        productApprovel,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitHandler = async () => {
    const {
      commissionCalculateType,
      refundCalculate,
      commission,
      shippingCommission,
      manageOrderByVendor,
      sellerApproval,
      productApprovel,
    } = this.state;

    this.setState({ loading: true });

    const res = await this.props.create({
      commissionCalculateType,
      refundCalculate,
      commission,
      shippingCommission,
      manageOrderByVendor,
      sellerApproval,
      productApprovel,
    });

    this.setState({ loading: false });

    if (res) {
      this.setState({
        loading: false,
      });
      toast(`${res.data}`, { containerId: "B" });
    }
  };

  render() {
    const {
      commissionCalculateType,
      refundCalculate,
      commission,
      shippingCommission,
      manageOrderByVendor,
      sellerApproval,
      productApprovel,
    } = this.state;

    TopBarProgress.config({
      barThickness: 4,
      barColors: {
        0: "rgb(51, 201, 45, .7)",
        ".3": "rgb(255, 77, 107, .7)",
        "1.0": "rgb(200, 125, 255, .7)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(0, 0, 0, .5)",
    });
    return (
      <div className="container-scroller">
        {this.state.loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        <div className="page-header">
          <h3 className="page-title" style={{ fontSize: 30 }}>
            {" "}
            Vendor Setting{" "}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Vendor Setting
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div
              className="card p0"
              style={{
                border: "1px solid #dcdcdc",
              }}
            >
              <div className="card-body p-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#4c4d5a",
                    borderBottom: "1px solid #dcdcdc",
                    background: "#f6f6f6",
                    textShadow: "0 -1px 0 rgba(50,50,50,0)",
                    padding: 12,
                  }}
                >
                  Edit Vendor Setting
                </h4>

                <Alert />
                <div className="p-3">
                  <form className="form-sample">
                    <p
                      className="card-description"
                      style={{
                        color: "#343a40",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        fontSize: "1.125rem",
                      }}
                    >
                      {" "}
                      Seller levels{" "}
                    </p>

                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Admin Status for which Commission will calculate
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-sm"
                          id="commissionCalculateType"
                          value={commissionCalculateType}
                          name="commissionCalculateType"
                          onChange={(e) => {
                            this.setState({
                              commissionCalculateType: e.target.value,
                            });
                          }}
                        >
                          <option value="complete">Complete</option>
                          <option value="fraud">Fraud</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Intermediate
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-sm"
                          id="refundCalculate"
                          value={refundCalculate}
                          name="refundCalculate"
                          onChange={(e) => {
                            this.setState({
                              refundCalculate: e.target.value,
                            });
                          }}
                        >
                          <option value="refunded">Refunded</option>
                          <option value="creditIssue">Credit Issue</option>
                          <option value="replacement">Replacement Send</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control"
                          name="commission"
                          value={commission && commission}
                          onChange={(e) =>
                            this.setState({
                              commission: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Shipping Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control"
                          name="shippingCommission"
                          value={shippingCommission && shippingCommission}
                          onChange={(e) =>
                            this.setState({
                              shippingCommission: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Manage Order By Vendor
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-sm"
                          id="manageOrderByVendor"
                          name="manageOrderByVendor"
                          value={manageOrderByVendor}
                          onChange={(e) => {
                            this.setState({
                              manageOrderByVendor: e.target.value,
                            });
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Seller approval required
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-sm"
                          id="sellerApproval"
                          value={sellerApproval}
                          name="sellerApproval"
                          onChange={(e) => {
                            this.setState({
                              sellerApproval: e.target.value,
                            });
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        style={{ fontWeight: "bold" }}
                        className="col-sm-3 col-form-label"
                      >
                        Product Approval
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-sm"
                          id="productApprovel"
                          value={productApprovel}
                          name="productApprovel"
                          onChange={(e) => {
                            this.setState({
                              productApprovel: e.target.value,
                            });
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        this._submitHandler();
                      }}
                      type="button"
                      className="btn btn-primary mr-2 "
                    >
                      Update
                    </button>
                    <Link to={"/admin/dashboard"}>
                    <button
                      className="btn btn-light"

                    >
                      Cancel
                    </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

create.propTypes = {
  create: PropTypes.func.isRequired,
};

retreive.propTypes = {
  retreive: PropTypes.func.isRequired,
};

export default connect(null, { create, retreive })(VendorSetting);
