import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { products, deleteProduct } from "../../../actions/product";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Moment from "react-moment";

import SweetAlert from "react-bootstrap-sweetalert";

class Products extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: null,
      productsClone: null,
      deleteConfirm: false,
    };
  }

  async componentDidMount() {
    const res = await this.props.products();
    this.setState({ products: res, productsClone: res, loading: false });
  }

  _onCancel = () => {
    this.setState({ deleteConfirm: false });
  };

  _deleteProduct = async (id) => {
    this.setState({ loading: true });
    await this.props.deleteProduct(id);
    const res = await this.props.products();
    this.setState({ loading: false, deleteConfirm: false, products: res });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e.target.value);
      const filteredArr = [];
      this.state.products.forEach((element) => {
        if (
          element.title.toUpperCase().includes(e.target.value.toUpperCase()) ===
          true
        ) {
          filteredArr.push(element);
        }
      });
      console.log("filteredArr: ", filteredArr);
      this.setState({ products: filteredArr });
    }
  };

  _onChangeHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ products: this.state.productsClone });
    }
  };

  render() {
    const { loading, products } = this.state;
    return (
      <div className="container-scroller">
        {loading && <TopBarProgress />}

        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP_RIGHT}
        />

        {products && (
          <>
            <div className="page-header">
              <h3 className="page-title"> View All Seller Products </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/products">Seller Products</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">Seller Products List</h4>
                    <span
                      className="twitter-typeahead"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      <input
                        className="typeahead form-control tt-input"
                        type="text"
                        placeholder="Search..."
                        autoComplete="off"
                        spellCheck="false"
                        dir="auto"
                        style={{
                          position: "relative",
                          verticalAlign: "top",
                          backgroundColor: "transparent",
                          borderColor: "green",
                          color: "black",
                        }}
                        onKeyDown={this._handleKeyDown}
                        onChange={this._onChangeHandler}
                      />
                    </span>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Name </th>
                          <th> Image</th>
                          <th> Quantity </th>
                          <th> Model Number</th>
                          <th> Status </th>
                          <th> Created At </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products && products.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="6">No main products found</td>
                          </tr>
                        ) : (
                          products.products.map((item, index) => (
                            <tr key={index}>
                              <td> {index + 1} </td>
                              <td> {item.title} </td>
                              <td>
                                <img
                                  src={item.images && item.images[0]}
                                  alt={item.title}
                                />
                              </td>
                              <td> {item.quantity} </td>
                              <td> {item.modelNumber} </td>
                              <td>
                                {item.status === true ? "Enabled" : "Disabled"}
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {item.createdAt}
                                </Moment>
                              </td>
                              <td>
                                <Link to={`/admin/edit-seller-product/?id=${item._id}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mr-4"
                                  style={{ width: 78 }}

                                >
                                  Edit
                                </button>
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                  onClick={() => {
                                    this.setState({ deleteConfirm: true });
                                  }}
                                >
                                  Delete
                                </button>

                                {this.state.deleteConfirm && (
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes, delete it!"
                                    confirmBtnBsStyle="danger"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      this._deleteProduct(item._id);
                                    }}
                                    onCancel={this._onCancel}
                                    focusCancelBtn
                                  >
                                    Are you sure you want to delete?
                                  </SweetAlert>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

products.propTypes = {
  products: PropTypes.func.isRequired,
};

deleteProduct.propTypes = {
  deleteProduct: PropTypes.func.isRequired,
};

export default connect(null, { products, deleteProduct })(Products);
