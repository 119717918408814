import React, { useState, useEffect } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
} from "react-sortable-tree";
import {
  Button,
  Spinner,
  OverlayTrigger,
  Popover,
  Form,
} from "react-bootstrap";
import { SketchPicker } from "react-color";
import heart from "./heart-431.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import "react-sortable-tree/style.css";

const getNodeKey = ({ treeIndex }) => treeIndex;

const getOptions = (categoryTomap) => {
  return categoryTomap.map((category) => (
    <>
      <option
        value={category.name}
        id={category.name}
        data-category={JSON.stringify(category)}
      >
        {category.name}
      </option>
      {category.categories && getOTPGroup(category, category.categories)}
    </>
  ));
};

const getOTPGroup = (cat, list) => {
  return (
    <optgroup label={cat.name}>
      {list.map((category) => (
        <>
          <option
            value={category.name}
            id={category.name}
            data-category={JSON.stringify(category)}
          >
            {category.name}
          </option>

          {category.subCategories?.map((cat_) => (
            <>
              <option
                value={cat_.name}
                id={cat_.name}
                data-category={JSON.stringify(cat_)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp; {cat_.name}
              </option>
              {cat_.children?.map((cat__) => (
                <option
                  value={cat__.name}
                  id={cat__.name}
                  data-category={JSON.stringify(cat__)}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {cat__.name}
                </option>
              ))}
            </>
          ))}
        </>
      ))}
    </optgroup>
  );
};

let options = [];

function TreeHeader({
  treeData,
  loading,
  secondLevelCategories,
  handleSubmit,
}) {
  useEffect(() => {
    setstate({ ...state, treeData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    options = getOptions(secondLevelCategories);
  }, [secondLevelCategories]);

  const [state, setstate] = useState({ treeData });

  if (loading && secondLevelCategories.length === 0)
    return (
      <h2 className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </h2>
    );

  return (
    <div style={{ pointerEvents: loading && "none" }}>
      <div>
        <Button
          className="btn btn-primary m-2"
          onClick={() => handleSubmit(state.treeData)}
        >
          Save Mega Menu
        </Button>
        <Button
          className="btn btn-primary m-2"
          onClick={() =>
            setstate((state) => ({
              treeData: state.treeData.concat({
                name: "",
                slug: "",
                haveSubMenu: false,
                showInCategory: true,
                showInHeader: true,
                children: [],
              }),
            }))
          }
        >
          Add Parent category
        </Button>
        <Button
          className="btn btn-primary m-2"
          onClick={() =>
            setstate((state) => ({
              treeData: state.treeData.concat({
                name: "",
                slug: "",
                haveSubMenu: false,
                showInHeader: true,
                showInCategory: true,
                children: [],
                isCustomLink: true,
                link: {
                  link: "",
                  color: "#221924",
                  fontWeight: "normal",
                  showArrow: false,
                  target: "_self",
                },
              }),
            }))
          }
        >
          Add Custom LInk
        </Button>
      </div>
      <div style={{ height: "90vh" }}>
        <SortableTree
          treeData={state.treeData}
          onChange={(treeData) => setstate({ ...state, treeData })}
          maxDepth={4}
          generateNodeProps={({ node, path }) => {
            return {
              title: node.isCustomLink ? (
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  defaultValue={node.name ?? ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setstate((state) => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: {
                          ...node,
                          name: value,
                        },
                      }),
                    }));
                  }}
                  style={{ height: 40 }}
                />
              ) : (
                <select
                  className="form-control p-0 pl-2"
                  name="category"
                  value={node.name ?? ""}
                  style={{ height: 34, fontSize: 18 }}
                  onChange={(event) => {
                    const name = event.target.value;
                    const el = document.getElementById(name);
                    let category = {};
                    if (el) category = JSON.parse(el.dataset.category);
                    delete category.categories;
                    delete category.subCategories;
                    delete category.children;
                    setstate((state) => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, ...category, name },
                      }),
                    }));
                  }}
                >
                  <>
                    <option value="" disabled>
                      Select Category
                    </option>
                    {options}
                  </>
                </select>
              ),
              buttons: [
                <div
                  className="icon-plus actionBtn-icon plus"
                  title="Add default"
                  style={{
                    margin: "10px 4px",
                    display: (!node.name || path.length === 4) && "none",
                  }}
                  onClick={() => {
                    let newTreeData = changeNodeAtPath({
                      treeData: state.treeData,
                      path,
                      getNodeKey,
                      newNode: { ...node, haveSubMenu: true },
                    });

                    newTreeData = addNodeUnderParent({
                      treeData: newTreeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        name: "",
                        slug: "",
                        haveSubMenu: false,
                        showInHeader: true,
                        showInCategory: true,
                        children: [],
                      },
                      addAsFirstChild: true,
                    }).treeData;

                    setstate((state) => ({
                      treeData: newTreeData,
                    }));
                  }}
                ></div>,
                <div
                  className="icon-settings actionBtn-icon plus"
                  title="Add Custom Link"
                  style={{
                    margin: "10px 4px",
                    display: (!node.name || path.length === 4) && "none",
                  }}
                  onClick={() => {
                    let newTreeData = changeNodeAtPath({
                      treeData: state.treeData,
                      path,
                      getNodeKey,
                      newNode: { ...node, haveSubMenu: true },
                    });

                    newTreeData = addNodeUnderParent({
                      treeData: newTreeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        name: "",
                        slug: "",
                        haveSubMenu: false,
                        showInHeader: true,
                        showInCategory: true,
                        children: [],
                        isCustomLink: true,
                        link: {
                          link: "",
                          color: "#221924",
                          fontWeight: "normal",
                          showArrow: false,
                        },
                      },
                      addAsFirstChild: true,
                    }).treeData;

                    setstate((state) => ({
                      treeData: newTreeData,
                    }));
                  }}
                ></div>,
                <div
                  className="icon-minus actionBtn-icon minus"
                  style={{ margin: "10px 4px" }}
                  title="Remove Link"
                  onClick={() => {
                    setstate((state) => ({
                      treeData: removeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                      }),
                    }));
                  }}
                ></div>,
                <div
                  className="actionBtn-icon"
                  title="Show in Category"
                  onClick={() => {
                    setstate((state) => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: {
                          ...node,
                          showInCategory: !node.showInCategory,
                        },
                      }),
                    }));
                  }}
                >
                  {node.showInCategory ? (
                    <VisibilityOutlinedIcon
                      style={{ margin: "6px 4px" }}
                      width="16px"
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "6px 4px" }}
                      width="16px"
                    />
                  )}
                </div>,

                <div
                  className={!node.showInHeader && "icon-heart"}
                  style={{
                    margin: `${!node.showInHeader ? "10px 4px" : "5px 4px"}`,
                  }}
                  title="Show in header"
                  onClick={() => {
                    setstate((state) => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, showInHeader: !node.showInHeader },
                      }),
                    }));
                  }}
                >
                  {node.showInHeader && <img src={heart} alt="" width="16px" />}
                </div>,

                <OverlayTrigger
                  trigger="click"
                  key="left"
                  placement="left"
                  style={{ width: "800px" }}
                  rootClose
                  overlay={
                    <Popover id={`popover-positioned-${"left"}`}>
                      <Popover.Title as="h3">
                        Add Link
                        <footer class="blockquote-footer">
                          For default keep it empty.
                        </footer>
                        <footer class="blockquote-footer">
                          For Sundial Links don't include Domain name in link!
                        </footer>
                        <footer class="blockquote-footer">
                          For External Links add full link with "https"
                        </footer>
                      </Popover.Title>
                      <Popover.Content>
                        <Form.Control
                          className="my-2"
                          type="text"
                          placeholder="Enter Link"
                          defaultValue={node.link?.link ?? ""}
                          onBlur={(e) => {
                            const value = e.target.value;
                            setstate((state) => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                                newNode: {
                                  ...node,
                                  link: {
                                    ...node.link,
                                    link: value,
                                  },
                                },
                              }),
                            }));
                          }}
                        />
                        <select
                          className="form-control p-0 pl-3"
                          name="category"
                          value={node.link?.fontWeight ?? ""}
                          style={{ height: 34 }}
                          onChange={(e) => {
                            const value = e.target.value;
                            setstate((state) => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                                newNode: {
                                  ...node,
                                  link: {
                                    ...node.link,
                                    fontWeight: value,
                                  },
                                },
                              }),
                            }));
                          }}
                        >
                          <>
                            <option value="" disabled>
                              Select Font Weight
                            </option>
                            <option value="lighter">lighter</option>
                            <option value="normal">normal</option>
                            <option value="bold">bold</option>
                            <option value="bolder">bolder</option>
                          </>
                        </select>

                        <label className="d-flex align-items-center">
                          Show Arrow
                          <Form.Control
                            type="checkbox"
                            placeholder="Show Arrow"
                            checked={node.link?.showArrow ?? ""}
                            onChange={(e) => {
                              const value = e.target.checked;
                              setstate((state) => ({
                                treeData: changeNodeAtPath({
                                  treeData: state.treeData,
                                  path,
                                  getNodeKey,
                                  newNode: {
                                    ...node,
                                    link: {
                                      ...node.link,

                                      showArrow: value,
                                    },
                                  },
                                }),
                              }));
                            }}
                            style={{
                              width: 14,
                              marginLeft: 4,
                            }}
                          />
                        </label>
                        <label className="d-flex align-items-center">
                          Open in new tab
                          <Form.Control
                            type="checkbox"
                            placeholder="Open in new tab"
                            checked={node.link?.target === "_blank"}
                            onChange={(e) => {
                              const value = e.target.checked;
                              setstate((state) => ({
                                treeData: changeNodeAtPath({
                                  treeData: state.treeData,
                                  path,
                                  getNodeKey,
                                  newNode: {
                                    ...node,
                                    link: {
                                      ...node.link,
                                      target: value ? "_blank" : "_self",
                                    },
                                  },
                                }),
                              }));
                            }}
                            style={{
                              width: 14,
                              marginLeft: 4,
                            }}
                          />
                        </label>
                        <SketchPicker
                          color={node.link?.color ?? "#221924"}
                          triangle="hide"
                          onChangeComplete={({ hex }) => {
                            setstate((state) => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                                newNode: {
                                  ...node,
                                  link: {
                                    ...node.link,
                                    color: hex,
                                  },
                                },
                              }),
                            }));
                          }}
                          className="my-2 mx-2"
                        />
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div
                    className="icon-link"
                    style={{
                      margin: "10px 4px",
                      color: node.link?.link ? "#38ce3c" : undefined,
                      // display: !node.isCustomLink && 'none',
                    }}
                    title="Link to page"
                  ></div>
                </OverlayTrigger>,
              ],
            };
          }}
        />
      </div>
    </div>
  );
}

export default TreeHeader;
