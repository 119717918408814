/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-rename */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { apiURL } from "../../../config/default";
import axios from "axios";
import { Button } from "react-bootstrap";
import CheckBoxComponent from "../CreateFilters/Components/CheckBoxComponent";
// Redux
import { connect } from "react-redux";
import { getAttribute } from "../../../actions/product";

function CustomSettings({ getAttribute }) {

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const { data: attributes } = await axios.get(
        `${apiURL}admin/get-attribute`
      );
      setattributes(attributes);

      setloading(false);
    })();
  }, []);

  const getSettings = async (id) => {
    setDefaultAttribute(id);
    setstate((s) => ({
      ...s,
      values: s.values.map(($s) => ({ ...$s, attributeId: id })),
    }));
    const {
      data: {
        globalCustomFilter: globalCustomFilter_,
        productAttribute: productAttribute,
      },
    } = await axios.get(`${apiURL}filters/global-custom-filter/${id}`);
    const g =
      globalCustomFilter_?.map((g) => ({
        ...g,
        values:
          g?.values?.map((v) => ({
            ...v,
            productAttribute:
              v.productAttribute?.map((p) => ({
                label: p?.text,
                value: p?._id,
              })) || [],
          })) || [],
      })) || [];

    if (g.length) {
      setstate({ values: g[0].values });
      settitle(g[0].title);
    }
    setSecondLevelAttribute(productAttribute);
  };

  const [state, setstate] = useState({
    values: [
      {
        productAttribute: [],
        attributeId: "",
        title: "",
        showImage: false,
        imageURL: "",
        description: "",
      },
    ],
  });

  console.log(state);

  const [title, settitle] = useState("");

  const [loading, setloading] = useState(true);
  const [attributes, setattributes] = useState([]);
  const [defaultAttribute, setDefaultAttribute] = useState("");
  const [defaultSubAttribute, setDefaultSubAttribute] = useState([]);
  const [SecondLevelAttribute, setSecondLevelAttribute] = useState([[]]);
  const [minMax, setminMax] = useState({});

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddField = () => {
    setstate({
      ...state,
      values: [
        ...state.values,
        {
          productAttribute: [],
          attributeId: defaultAttribute,
          title: "",
          showImage: false,
          imageURL: "",
          description: "",
        },
      ],
    });
  };

  const handleAddRange = () => {
    setstate({
      ...state,
      range: [
        ...state.range,
        { title: "", rangeMin: "", rangeMax: "", text: "", isFillable: false },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const newState = { ...state };

    newState.values.splice(index, 1);
    setstate(newState);
  };

  const handleRemoveRange = (index) => {
    const newState = { ...state };
    newState.range.splice(index, 1);
    setstate(newState);
  };

  const handleGetAttributes = async (index, id) => {
    const { data: res } = await axios.get(`${apiURL}filters/get-colors`);

    const attr = [...SecondLevelAttribute];
    attr[index] = res || [];
    setSecondLevelAttribute(attr);
  };

  const getRangeMinMax = async (title, index) => {
    if (minMax[title]) return;
    const category = state.filter.category;
    const dataToSend = { category, title };
    const { data: res } = await axios.post(
      `${apiURL}filters/get-dimension-data`,
      {
        ...dataToSend,
      }
    );
    const newState = { ...state };
    newState.range[index] = {
      ...newState.range[index],
      rangeMin: res.min,
      rangeMax: res.max,
    };
    setstate(newState);
    setminMax({ [title]: res });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title)
      return toast.warning("title is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    const values1 = [];

    state.values.forEach((value, index) => {
      let attribute = [];
      value.productAttribute.forEach((attr) => {
        attribute.push(attr.value);
      });
      values1[index] = {
        ...state.values[index],
        attributeId: defaultAttribute,
        productAttribute: attribute,
      };
    });

    const dataToSend = {
      title,
      values: values1,
      attributeId: defaultAttribute,
    };

    setloading(true);

    try {
      await axios.post(`${apiURL}filters/create-global-customFilter`, {
        ...dataToSend,
      });
      toast.success("Update Successful", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Something went wrong 😟", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      // await getSettings();
      setloading(false);
    }
  };

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    const image = await convertImgToBase64(e.target.files[0]);

    if (state._id) setstate({ ...state, imageURL: image, isbase64: true });
    else setstate({ ...state, imageURL: image });
  };

  const renderToggleButton = (key, title) => (
    <>
      <label>{title}</label>
      <div
        className="connection-all"
        onClick={() => setstate({ ...state, [key]: !state[key] })}
      >
        <span className={state[key] ? "allcenter" : "allLeft "}>ON</span>

        <span className={state[key] ? "editRight" : "editcenter "}>OFF</span>
      </div>
    </>
  );

  return (
    <div className="container-scroller">
      <ToastContainer />

      {loading && <TopBarProgress />}

      <div className="page-header">
        <h3 className="page-title" style={{ fontSize: 30 }}>
          {" "}
          Custom Settings{" "}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link onClick={() => navigate(-1)}>Filters</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Settings
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="col-md-6">
              <input
                type="text"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={attributes.map((a) => ({
                  value: a._id,
                  label: a.name,
                }))}
                onChange={(e) => getSettings(e.value)}
              />
            </div>
            <div className="col-md-12">
              <div className="row my-2">
                {state.values.map((value, index) => (
                  <CheckBoxComponent
                    title={value.title}
                    showImage={value.showImage}
                    state={state}
                    value={value}
                    handleRemoveField={handleRemoveField}
                    index={index}
                    setstate={setstate}
                    handleGetAttributes={handleGetAttributes}
                    attributes={attributes}
                    SecondLevelAttribute={SecondLevelAttribute}
                    convertImgToBase64={convertImgToBase64}
                    productAttribute={value.productAttribute}
                    attributeId={value.attributeId}
                    description={value.description}
                    isAuto={false}
                  />
                ))}
                <div className="col-md-3 mb-3 mt-3 d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Button
                      className="btn btn-primary"
                      variant="outline-primary"
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="btn btn-primary"
          variant="outline-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? "loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
}

export default connect(null, { getAttribute })(CustomSettings);
