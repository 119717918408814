/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { apiURL } from "../../../config/default";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarProgress from "react-topbar-progress-indicator";
import arrayMove from "array-move";

import "./helper.css";

let cancelName;
let checkBoxCancelToken;

function FilterRow() {
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const id = location.search.split("=")[1];
      const { data: res } = await axios.get(
        `${apiURL}filters/get-filters-id/${id}`
      );
      const filters = res.filters;
      const filterOptions = [];
      res.category.filter.forEach((catFilter) => {
        filters.forEach((filter) => {
          if (filter._id === catFilter) {
            filterOptions.push({ _id: catFilter, title: filter.title });
          }
        });
      });
      setincluded({
        ids: res.category.filter,
        categoryId: res.category._id,
        filterOptions,
      });
      setfilters(filters);
      setcategoryName(res.category.name);
      setloading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [categoryName, setcategoryName] = useState("");
  const [filters, setfilters] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [included, setincluded] = useState({
    ids: [],
    categoryId: "",
    filterOptions: [],
  });
  const [loading, setloading] = useState(true);
  const [selectAll, setselectAll] = useState([]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const data = {
      ...included,
      ids: arrayMove(included.ids, oldIndex, newIndex),
      filterOptions: arrayMove(included.filterOptions, oldIndex, newIndex),
    };
    setincluded(data);
    if (typeof checkBoxCancelToken != typeof undefined) {
      checkBoxCancelToken.cancel("Operation canceled due to new request.");
    }
    checkBoxCancelToken = axios.CancelToken.source();
    const dataToSend = { ...data };
    delete dataToSend.filterOptions;
    const _config = {
      cancelToken: checkBoxCancelToken.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${apiURL}filters/include`,
        dataToSend,
        _config
      );
    } catch (err) {
      // const errors = err.response.data.error;
    }
  };

  const open = () => setshowModal(true);

  const handleClose = () => setshowModal(false);

  const hanldeChangeName = async (e, index, _id) => {
    const newState = [...filters];
    newState[index].title = e.target.value;
    setfilters(newState);

    if (typeof cancelName != typeof undefined) {
      cancelName.cancel("Operation canceled due to new request.");
    }

    cancelName = axios.CancelToken.source();

    const data = { name: e.target.value, _id };
    const _config = {
      cancelToken: cancelName.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${apiURL}filters/update-name`,
        data,
        _config
      );
    } catch (err) {
      // const errors = err.response.data.error;
    }
  };
  const handleCheckBox = async (_id, title) => {
    const isIncluded = included.ids.includes(_id);
    let data = {};
    if (isIncluded) {
      const newState = {
        ...included,
        ids: included.ids.filter((ind) => ind !== _id),
        filterOptions: included.filterOptions.filter(
          (filter) => filter._id !== _id
        ),
      };
      setincluded(newState);
      data = newState;
    } else {
      const newState = {
        ...included,
        ids: [...included.ids, _id],
        filterOptions: [...included.filterOptions, { _id, title }],
      };
      setincluded(newState);
      data = newState;
    }
    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const dataToSend = { ...data };
    delete dataToSend.filterOptions;

    try {
      const res = await axios.post(
        `${apiURL}filters/include`,
        dataToSend,
        _config
      );
    } catch (err) {
      // const errors = err.response.data.error;
    }
  };

  const selectAllFilters = async () => {
    let newState = {};

    if (included.ids.length === filters.length) {
      newState = {
        ...included,
        ids: [],
        filterOptions: [],
      };
    } else {
      newState = {
        ...included,
        ids: filters.map((filter) => filter._id),
        filterOptions: [...filters],
      };
    }

    setincluded(newState);

    const _config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const dataToSend = { ...newState };
    delete dataToSend.filterOptions;

    try {
      const res = await axios.post(
        `${apiURL}filters/include`,
        dataToSend,
        _config
      );
    } catch (err) {
      // const errors = err.response.data.error;
    }
  };

  const handleAutoFilters = async (id) => {
    setloading(true);
    const { data: res } = await axios.get(
      `${apiURL}filters/update-filter-attribute/${id}`
    );
    if (res)
      toast.success("Successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    setloading(false);
  };

  const SortableItem = SortableElement(({ item }) => (
    <div className="__item">
      <div className="__inner-item">
        <p>{item.title}</p>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className="__container">
      {items.map((item, index) => (
        <SortableItem key={`${item._id}`} index={index} item={item} />
      ))}
    </div>
  ));

  return (
    <div className="table-responsive">
      {loading && <TopBarProgress />}
      <ToastContainer />
      <h3 className="pb-4">{categoryName}</h3>
      <Button variant="outline-primary" size="sm" onClick={open}>
        Sort Top Filters
      </Button>
      <table className="table" id="sortableTable_">
        <thead>
          <tr>
            <th className="text-center" scope="col">
              <input
                className="mr-2"
                type="checkbox"
                checked={included.ids.length === filters.length}
                onChange={selectAllFilters}
              />
              Include
            </th>

            <th className="text-center" scope="col">
              Name (Editable)
            </th>
            <th className="text-center" scope="col">
              Settings
            </th>
          </tr>
        </thead>
        <tbody>
          {filters.length === 0 && !loading && <h5>No Filters Found</h5>}
          {filters.map((filter, id) => (
            <tr key={filter._id}>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={included.ids.includes(filter._id)}
                  onChange={() => handleCheckBox(filter._id, filter.title)}
                />
              </td>
              <td className="text-center">
                <Form.Group
                  controlId={id}
                  style={{ width: "60%", margin: "0 auto" }}
                  // onClick={() => seteditableIndex(id)}
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    // autoFocus
                    // onBlur={() => seteditableIndex(null)}
                    value={filter.title}
                    onChange={(e) => hanldeChangeName(e, id, filter._id)}
                  />
                </Form.Group>
              </td>
              <td className="text-center">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => handleAutoFilters(filter._id)}
                >
                  Auto
                </Button>
                <Link to={`/admin/brand/auto-settings/?id=${filter._id}`}>
                  <Button className="ml-3" variant="outline-primary" size="sm">
                    View Auto Filters
                  </Button>
                </Link>
                <Link to={`/admin/brand/custom-settings/?id=${filter._id}`}>
                  <Button className="ml-2" variant="outline-primary" size="sm">
                    Custom
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>

        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Filter Sort</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SortableList
              items={included.filterOptions}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass="__SortableHelper"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </table>
    </div>
  );
}

export default FilterRow;
