import React, { Fragment, useCallback, useEffect, useState } from "react";
import VendorCard from "./components/VendorCard";
import Grid from "@mui/material/Grid";
import "./styles.css";
import VendorRow from "./components/VendorRow";
import { useParams } from "react-router";
import { getVendorDetails, updateVendor } from "../../../actions/user";
import { useDispatch } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import { ConvertImagesIntoBase64 } from "../../../utils/ConvertImagesIntoBase64";
import VendorImageUploader from "./components/VendorImageUploader";
import EditTabelDrawer from "../../../components/Edit-tabel-drawer/EditTabelDrawer";
import AddWareHouse from "./components/AddWareHouse";
import AddNewContact from "./components/AddNewContact";
import ToggleSwitch from "../../../components/ios-radio-button/ToggleSwitch";
import ReturnPolicyRow from "./components/ReturnPolicyRow";

const EditVendor = () => {
  // hooks
  const { id } = useParams();
  const dispatch = useDispatch();

  // localSatate
  const [isMount, setIsMount] = useState(false);
  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(true);
  const [Loading2, setLoading2] = useState(false);
  const [user, setuser] = useState({});

  const [Passoword, setPassoword] = useState("");

  const [Edit, setEdit] = useState({
    open: false,
    edit: "",
    title: "",
  });

  const getVendor = useCallback(
    async (id) => {
      const res = await dispatch(getVendorDetails(id));
      const { user, profile } = res;

      setprofile(profile);
      setuser(user);
      setloading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, profile]
  );

  useEffect(() => {
    if (id) {
      getVendor(id);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMount]);

  const HandleChangeUser = (e) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      [name]: value,
    });
  };
  const HandleChangeProfile = (e) => {
    const { name, value } = e.target;
    setprofile({
      ...profile,
      [name]: value,
    });
  };

  const handleShippingAndReturns = (e, type) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      shippingAndReturns: {
        ...user.shippingAndReturns,
        [name]: type === "number" ? Number(value) : value,
      },
    });
  };

  const handleChangeFile = async (e) => {
    e.persist();

    let imagesBase64 = await ConvertImagesIntoBase64([
      {
        file: e.target.files[0],
        name: e.target.files[0].name,
        size: e.target.files[0]?.size,
        src: URL.createObjectURL(e.target.files[0]),
      },
    ]);
    setprofile({
      ...profile,
      [e.target.name]: imagesBase64[0],
    });
  };

  // handle submit edit vendor
  const handleSubmit = async () => {
    setLoading2(true);
    const data = {
      ...user,
      ...profile,
      password: Passoword,
    };

    const res = await dispatch(updateVendor(data, id));
    if (res) {
      setLoading2(false);
    }
  };

  const handleAddNewAddress = () => {
    setEdit({
      open: false,
      edit: "addNewAddress",
      title: `Add New Address`,
    });
  };

  const handleNewContact = () => {
    setEdit({
      open: true,
      edit: "addNewContact",
      title: `Add new Contact`,
    });
  };
  const deleteWarehouse = async (index) => {
    const newData = [...user.wareHouses];
    newData.splice(index, 1);

    const data = {
      ...user,
      wareHouses: newData,
      ...profile,
      password: Passoword,
    };
    const res = await dispatch(updateVendor(data, id));
    if (res) {
      setIsMount(!isMount);
    }
  };

  const deleteDepartment = async (index) => {
    const newData = [...profile.departments];
    newData.splice(index, 1);
    const data = {
      ...user,
      ...profile,
      departments: newData,
      password: Passoword,
    };
    const res = await dispatch(updateVendor(data, id));
    if (res) {
      setIsMount(!isMount);
    }
  };
  return (
    <>
      {loading ? (
        <TopBarProgress />
      ) : (
        <>
          <div className="edit-vender-container">
            <header>
              <h3>Edit Vendor</h3>
            </header>

            <Grid container spacing={3} className="my-4">
              <Grid lg={7} md={7} item>
                <VendorCard
                  title={"General Information"}
                  submitBtn={true}
                  Loading2={Loading2}
                  handleSubmit={() => handleSubmit()}
                >
                  <div>
                    <VendorRow name={"Vendor Name"} editShow={true}>
                      <input
                        type="text"
                        value={user?.fullName}
                        name="fullName"
                        onChange={HandleChangeUser}
                      />
                    </VendorRow>
                    <VendorRow
                      className={"h-auto"}
                      name={"Tagline"}
                      editShow={true}
                    >
                      <textarea
                        name="tagline"
                        value={profile?.tagline}
                        onChange={HandleChangeProfile}
                        className="w-100 border-0 bg-transparent py-3 "
                        cols="30"
                        rows="7"
                      />
                    </VendorRow>
                    <VendorRow name={"storeDescription"} editShow={true}>
                      <input
                        type="text"
                        name={"storeDescription"}
                        value={profile?.storeDescription}
                        onChange={HandleChangeProfile}
                      />
                    </VendorRow>
                    <VendorRow name={"Orders Email"} editShow={true}>
                      <input
                        type="text"
                        value={user?.email}
                        name="email"
                        onChange={HandleChangeUser}
                      />
                    </VendorRow>
                    <VendorRow name={"Slug"} editShow={true}>
                      <input
                        type="text"
                        value={user?.slug}
                        name="slug"
                        onChange={HandleChangeUser}
                      />
                    </VendorRow>
                    <VendorRow name={"Phone"} editShow={true}>
                      <input
                        type="number"
                        value={user?.phone}
                        name="phone"
                        onChange={HandleChangeUser}
                      />
                    </VendorRow>
                    <VendorRow name={"Password"} editShow={true}>
                      <input
                        type="text"
                        value={Passoword}
                        onChange={(e) => setPassoword(e.target.value)}
                      />
                    </VendorRow>

                    <VendorImageUploader
                      name={"Store Logo"}
                      id={"storeLogo"}
                      handleChangeFile={handleChangeFile}
                    >
                      <div
                        style={{ width: "50px", height: "50px" }}
                        className=" border-grey border-radius-10 d-flex justify-content-center align-center mx-auto"
                      >
                        <img
                          src={profile?.storeLogo}
                          alt=""
                          className=" w-100"
                        />
                      </div>
                    </VendorImageUploader>
                    <VendorImageUploader
                      name={"Store Banner"}
                      id={"storeBanner"}
                      handleChangeFile={handleChangeFile}
                    >
                      <div
                        style={{ width: "50px", height: "50px" }}
                        className=" border-grey border-radius-10 d-flex justify-content-center align-center mx-auto"
                      >
                        <img
                          src={profile?.storeBanner}
                          alt=""
                          className=" w-100"
                        />
                      </div>
                    </VendorImageUploader>
                  </div>
                </VendorCard>

                <VendorCard
                  title={"Settings"}
                  submitBtn={true}
                  Loading2={Loading2}
                  handleSubmit={() => handleSubmit()}
                >
                  <div>
                    <VendorRow name={"Newsletter"}>
                      <select
                        className=" w-100 border-grey bg-grey border-radius-5 py-2 px-2"
                        name="isSubscribe"
                        defaultValue={user?.isSubscribe}
                        // value={user?.isSubscribe}
                        onChange={(e) => {
                          setuser({
                            ...user,
                            [e.target.name]:
                              e.target.value === "true" ? true : false,
                          });
                        }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </VendorRow>
                    <VendorRow name={"Status"} subTitle="Edit in Vendor List">
                      <p>{user?.disabled ? "off" : "Live"}</p>
                    </VendorRow>
                    <VendorRow
                      name={"Google Shopping"}
                      value={user?.googleShopping}
                      subTitle="Edit in Vendor List"
                    >
                      <p>{user?.googleShopping ? "Live" : "off"}</p>
                    </VendorRow>
                    <VendorRow
                      name={"Pricing"}
                      value={user?.googleShopping}
                      subTitle="Edit in Vendor List"
                    >
                      <p>{user?.pricingType}</p>
                    </VendorRow>
                    <VendorRow name={"Shipping Party"}>
                      <select
                        className=" w-100 border-grey bg-grey border-radius-5 py-2 px-2"
                        name="isSundialShipping"
                        defaultValue={
                          profile?.isSundialShipping ? "Sundial" : "Vendor"
                        }
                        onChange={(e) =>
                          setprofile({
                            ...profile,
                            [e.target.name]:
                              e.target.value === "true" ? true : false,
                          })
                        }
                      >
                        <option value={"true"}>Sundial</option>
                        <option value={"false"}>Vendor</option>
                      </select>
                    </VendorRow>
                  </div>
                </VendorCard>

                <VendorCard
                  title={"Addressbooks"}
                  btnShow={true}
                  addNewAddress={() => handleAddNewAddress()}
                  handeNewContact={() => handleNewContact()}
                >
                  <>
                    {user?.wareHouses.map((item, index) => (
                      <Fragment key={index}>
                        <VendorRow
                          className={"h-auto"}
                          name={"address"}
                          editShow={true}
                          handleEdit={() => {}}
                          deleteBtn={true}
                          handleDelete={() => deleteWarehouse(index)}
                        >
                          <div>
                            <p className=" font-weight-normal">
                              Distribution Center
                            </p>
                            <p className=" font-weight-normal">
                              {`${item?.company}, ${item?.address} ${item?.country} ${item?.region} ${item?.city}`}
                            </p>
                          </div>
                        </VendorRow>
                      </Fragment>
                    ))}
                    {profile?.departments.map((item, index) => (
                      <Fragment key={index}>
                        <VendorRow
                          className={"h-auto"}
                          name={"contact"}
                          deleteBtn={true}
                          handleDelete={() => deleteDepartment(index)}
                        >
                          <div>
                            <p className=" font-weight-normal">
                              {item?.department}
                            </p>
                            <p className=" font-weight-normal">{item?.POC}</p>
                            <p className=" font-weight-normal">
                              {item?.pocEmail}
                            </p>
                            <p className=" font-weight-normal">{item?.phone}</p>
                          </div>
                        </VendorRow>
                      </Fragment>
                    ))}
                  </>
                </VendorCard>

                {Edit.edit === "addNewAddress" && (
                  <VendorCard
                    title={"Add New Address"}
                    closeBtn={true}
                    hanldeClose={() =>
                      setEdit({
                        open: false,
                        edit: "",
                        title: ``,
                      })
                    }
                  >
                    <AddWareHouse
                      isMount={isMount}
                      setIsMount={setIsMount}
                      Data={{ ...user, ...profile, Passoword }}
                      Edit={Edit}
                      setEdit={setEdit}
                    />
                  </VendorCard>
                )}
                {/* when Add new Address of ware house */}
              </Grid>
              <Grid lg={5} md={5} item>
                <VendorCard
                  title={"Use Vendor Return Policy"}
                  totgleShow={true}
                  user={user}
                  setuser={setuser}
                >
                  <div>
                    <VendorRow
                      name={"Damage/Defective Return Policy"}
                      editShow={true}
                      className={"h-auto"}
                    >
                      <textarea
                        className="w-100 border-0 bg-transparent py-3 "
                        cols="30"
                        rows="5"
                        type="text"
                        value={user?.shippingAndReturns?.defectivePolicy}
                        name="defectivePolicy"
                        onChange={(e) => handleShippingAndReturns(e, "text")}
                      />
                    </VendorRow>
                    <VendorRow
                      className={"h-auto"}
                      name={"Damage/ Defective Claims Period"}
                      editShow={true}
                    >
                      <input
                        type="number"
                        className="w-100 border-0 bg-transparent py-3 "
                        name="defectiveClaimsCutoff"
                        value={user?.shippingAndReturns?.defectiveClaimsCutoff}
                        onChange={(e) => handleShippingAndReturns(e, "number")}
                      />
                    </VendorRow>
                    <VendorRow name={"Damage/ Defective Fee"} editShow={true}>
                      <input
                        type="number"
                        value={user?.shippingAndReturns?.defectiveRestockFee}
                        name="defectiveRestockFee"
                        onChange={(e) => handleShippingAndReturns(e, "number")}
                      />
                    </VendorRow>
                    <VendorRow
                      name={"Buyers Remorse Return Policy"}
                      className={"h-auto"}
                      editShow={true}
                    >
                      <textarea
                        className="w-100 border-0 bg-transparent py-3 "
                        cols="30"
                        rows="14"
                        type="text"
                        value={user?.shippingAndReturns?.returnPolicy}
                        name="returnPolicy"
                        onChange={(e) => handleShippingAndReturns(e, "text")}
                      />
                    </VendorRow>
                    <VendorRow
                      name={"Buyers Remorse Claims Period"}
                      editShow={true}
                    >
                      <input
                        type="number"
                        value={user?.shippingAndReturns?.brClaimsCutoff}
                        name="brClaimsCutoff"
                        onChange={(e) => handleShippingAndReturns(e, "number")}
                      />
                    </VendorRow>
                    <VendorRow name={"Buyers Remorse Fee"} editShow={true}>
                      <input
                        type="number"
                        value={user?.shippingAndReturns?.brRestockFee}
                        name="brRestockFee"
                        onChange={(e) => handleShippingAndReturns(e, "number")}
                      />
                    </VendorRow>
                  </div>
                </VendorCard>
                {/* {Top Not Return policy} */}
                <VendorCard title={`${user?.fullName} Return Policy`}>
                  <div>
                    <div className=" d-flex justify-content-between align-center px-3 py-3">
                      <p className=" text-muted">Use Vendor Lead Time</p>
                      <ToggleSwitch
                        checked={
                          user?.shippingAndReturns?.policyType === "vendor"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setuser({
                            ...user,
                            shippingAndReturns: {
                              ...user?.shippingAndReturns,
                              policyType:
                                e.target.checked === true
                                  ? "vendor"
                                  : "sundial",
                            },
                          });
                        }}
                        Name={"policyType"}
                      />
                    </div>
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Lead Time"
                      subTitle="Small Parcel"
                      value={user?.shippingAndReturns?.leadTimeSmall}
                    />
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Min Ship Time"
                      subTitle="Small Parcel"
                      value={user?.shippingAndReturns?.minShipTimeSmall}
                    />{" "}
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Max Ship Time"
                      subTitle="Small Parcel"
                      value={user?.shippingAndReturns?.maxShipTimeSmall}
                    />{" "}
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Lead Time"
                      subTitle="LTL Parcel"
                      value={user?.shippingAndReturns?.leadTimeLTL}
                    />{" "}
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Min Ship Time"
                      subTitle="LTL Parcel"
                      value={user?.shippingAndReturns?.minShipTimeLTL}
                    />{" "}
                    <hr className="m-0" />
                    <ReturnPolicyRow
                      name="Max Ship Time"
                      subTitle="LTL Parcel"
                      value={user?.shippingAndReturns?.maxShipTimeLTL}
                    />{" "}
                    <hr className="m-0" />
                    <div className="  px-3 font-weight-normal py-3">
                      <div>
                        SP Estimated Delivery:{" "}
                        {user?.shippingAndReturns?.leadTimeSmall +
                          user?.shippingAndReturns?.minShipTimeSmall}
                        -
                        {user?.shippingAndReturns?.leadTimeSmall +
                          user?.shippingAndReturns?.maxShipTimeSmall}
                        days
                      </div>
                      <div>
                        LTL Estimated Delivery:{" "}
                        {user?.shippingAndReturns?.leadTimeLTL +
                          user?.shippingAndReturns?.minShipTimeLTL}
                        -
                        {user?.shippingAndReturns?.leadTimeLTL +
                          user?.shippingAndReturns?.maxShipTimeLTL}
                        days
                      </div>
                    </div>
                  </div>
                </VendorCard>
              </Grid>
              <EditTabelDrawer
                Edit={Edit}
                setEdit={setEdit}
                //   shippingId={selectedItem?.itemOrderNumber}
              >
                {Edit.edit === "addNewContact" && (
                  <AddNewContact
                    isMount={isMount}
                    setIsMount={setIsMount}
                    OldData={{ ...user, ...profile, Passoword }}
                    Edit={Edit}
                    setEdit={setEdit}
                  />
                )}
              </EditTabelDrawer>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default EditVendor;
