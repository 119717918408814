import React, { Fragment, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import CutomeDatePicker from "../../../../components/DatePicker/CutomeDatePicker";
import { useDispatch } from "react-redux";
import { UpdateOrderTrackingStatus } from "../../../../actions/order";
import moment from "moment";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import { BsCalendar2WeekFill } from "react-icons/bs";

const getStyles = (key) => {
  switch (key) {
    case "Pending":
      return {
        background: "#fafafa",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };
    case "Processing":
      return {
        background: "#fff8e6",
        color: "#ffb706",
        borderColor: "#ffb706",
      };
    case "Preparing To Ship":
      return {
        background: "#e7f6ee",
        color: "#005a52",
        borderColor: "#005a52",
      };
    case "In Transit":
      return {
        background: "#e8f6fd",
        color: "#19aeee",
        borderColor: "#19aeee",
      };
    case "Arriving Soon":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delayed":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delivered":
      return {
        background: "#e1e5e8",
        color: "#234b5f",
        borderColor: "#234b5f",
      };
    case "Backordered":
      return {
        background: "#efe9ec",
        color: "#652a47",
        borderColor: "#652a47",
      };

    case "Not Available":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };
    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {
        background: "#ffffff",
        color: "#a1a1a1",
        borderColor: "#a1a1a1",
      };
  }
};

const getButtonStyles = (key) => {
  switch (key) {
    case "Pending":
    case "Processing":
      return {
        background: "#ffffff",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };

    case "Preparing To Ship":
      return {
        background: "#005a52",
        color: "#ffffff",
        borderColor: "#005a52",
      };
    case "In Transit":
      return {
        background: "#19aeee",
        color: "#ffffff",
        borderColor: "#19aeee",
      };

    case "Delayed":
      return {
        background: "#ffefeb",
        color: "#ff5e38",
        borderColor: "#ff5e38",
      };
    case "Delivered":
      return {
        background: "#023047",
        color: "#fff",
        borderColor: "#234b5f",
      };
    case "Backordered":
      return {
        background: "#652a47",
        color: "#fff",
        borderColor: "#652a47",
      };

    case "Not Available":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };
    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {
        background: "#ffffff",
        color: "#a1a1a1",
        borderColor: "#a1a1a1",
      };
  }
};

const CompleteButton = (key) => {
  switch (key) {
    case "Pending":
    case "Processing":
    case "Preparing To Ship":
    case "In Transit":
    case "Delivered":
    case "Not Available":
      return {
        background: "#ffffff",
        color: "#7d7d7d",
        borderColor: "#cdcdcd",
      };
    case "Backordered":
      return {
        background: "#652a47",
        color: "#ffffff",
        borderColor: "#652a47",
      };

    case "Cancelled":
      return {
        background: "#fee9eb",
        color: "#fb2e41",
        borderColor: "#fb2e41",
      };

    default:
      return {};
  }
};

const UpdateOrderTracking = ({
  status,
  Date,
  textNote,
  isComplete,
  SelectItem,
  statusName,
  DateLable,
  setIsMount,
  IsMount,
  buttonText,
  isActive,
  DateTitle,
  backorderStatus,
  ShipStartDate,
  ShipEndDate,
}) => {
  const dispatch = useDispatch();
  const [Open, setOpen] = useState(false);
  const [TrackingDate, setTrackingDate] = useState("TBD");
  const [sendMail, setSendMail] = useState(
    SelectItem?.status !== "pending" ? true : false
  );
  const [Loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setTrackingDate(e.target.value);
  };
  const updateTracking = async (e, status) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await dispatch(
        UpdateOrderTrackingStatus(SelectItem?._id, {
          orderStatus: status,
          estimateDate: TrackingDate,
          sendMail: sendMail,
        })
      );
      if (res) {
        setLoader(false);
        setIsMount(!IsMount);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {}, [SelectItem]);

  return (
    <div
      className={`status-radio-button p-0 ${isComplete ? "bg-muted" : ""}`}
      style={isActive === status ? getStyles(status) : {}}
    >
      {console.log(isActive, status, "dsdsdsdsd")}
      <div className=" status-inner-container">
        <div
          className={` d-flex justify-content-between align-center  font-weight-bold ${
            isComplete ? "text-muted" : ""
          }`}
          onClick={() => setOpen(!Open)}
        >
          <div>
            <p>
              {status}{" "}
              {status === "Backordered"
                ? backorderStatus
                  ? backorderStatus
                  : ""
                : ""}
            </p>
          </div>
          <div>
            <MdArrowDropDown
              style={Open ? { rotate: "180deg" } : {}}
              className="h2 mb-0"
            />
          </div>
        </div>
        {Open && (
          <>
            {!isComplete ? (
              <>
                <form onSubmit={(e) => updateTracking(e, statusName)}>
                  {textNote && (
                    <p
                      className="font-weight-normal mt-3 "
                      style={{ lineHeight: "1rem" }}
                    >
                      {textNote}
                    </p>
                  )}

                  <>
                    {status === "In Transit" ||
                      (status === "Delivered" && (
                        <div className="mt-2">
                          <CutomeDatePicker
                            label={DateLable}
                            value={TrackingDate}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      ))}

                    {status === "Preparing to Ship" ||
                    status === "In Transit" ||
                    status === "Delivered" ? (
                      <div className="d-flex align-center">
                        <Checkbox
                          checked={sendMail}
                          onChange={(e) => setSendMail(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ padding: "5px" }}
                        />
                        <span className=" text-black">Email Send</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {status === "Backordered" && (
                      <>
                        <p className="">Customer Action Date: mm/dd/yyyy</p>
                      </>
                    )}

                    <div className="mt-3">
                      <button
                        type="submit"
                        className="update-Order-button "
                        style={getButtonStyles(status)}
                      >
                        {Loader ? "Loading ... " : `Update Status To ${status}`}
                      </button>
                    </div>
                  </>
                </form>
              </>
            ) : (
              <div>
                <p className=" mt-3 text-black font-weight-bold">{textNote}</p>
                {status === "Backordered" && (
                  <>
                    <p className=" text-black font-weight-bold">
                      Est. Restocking Date: In 35 days,{" "}
                      {moment(SelectItem?.backorderStatus?.actionDate).format(
                        "l"
                      )}
                    </p>
                    <p className="text-black font-weight-bold">
                      Customer Action Date: 1 day ago, 5/15/2023
                    </p>
                  </>
                )}
                {DateTitle && (
                  <div className=" d-flex justify-content-between">
                    <p>{DateTitle}</p>
                    <div className=" d-flex justify-content-between align-center gap-10 ">
                      {/* <span>{moment(Date?.estimateDate).format("l")} </span> */}
                      <span>
                        {ShipStartDate && ShipEndDate
                          ? `${moment(ShipStartDate.substring(0, 10)).format(
                              "l"
                            )} - ${moment(ShipEndDate.substring(0, 10)).format(
                              "l"
                            )}`
                          : ""}
                      </span>
                      <span>
                        <BsCalendar2WeekFill size={20} />{" "}
                      </span>
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  className=" w-100 px-2 py-3 border-radius-5 mt-4 "
                  style={CompleteButton(status)}
                >
                  {buttonText} {moment(Date?.date).format("l")}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateOrderTracking;
