import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createNewAttributes } from "../../../../../actions/product";

const AddNewAttributes = ({ groupAttributes, setEdit }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [formData, setformData] = useState({
    name: "",
    attributeGroupId: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      const res = await dispatch(
        createNewAttributes({ ...formData, sortOrder: 1 })
      );
      if (res) {
        setloading(false);
        setEdit({
          open: false,
          edit: "",
          title: "",
        });
      }
    } catch (error) {
      setloading(true);
    }
  };
  return (
    <div>
      <div className="px-4 ">
        <form onSubmit={handleSubmit}>
          <div className=" mt-3">
            <input
              type="text"
              className="add-button mt-3"
              required
              placeholder="Name"
              name="name"
              autoComplete="off"
              value={formData.name}
              onChange={(e) =>
                setformData({ ...formData, [e.target.name]: e.target.value })
              }
            />
            <select
              className="add-button mt-2"
              name="attributeGroupId"
              value={formData.attributeGroupId}
              onChange={(e) =>
                setformData({ ...formData, [e.target.name]: e.target.value })
              }
            >
              <option disabled>Select</option>
              {groupAttributes?.map((opt) => (
                <option key={opt?._id} value={opt?._id}>
                  {opt?.name}
                </option>
              ))}
            </select>

            <div className=" mt-3 ">
              <button type="submit" className="update-Order-button">
                {loading ? "Loading..." : "Add New Attribute"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewAttributes;
